const theme = [
  {
    "id": "001",
    "name": "Standard",
    "backgroundColor": "#96c6fa",
    "primaryColor": "#0c3b5e",
    "secondaryColor": "#ebf3fc",
    "accentColor1": "#0f6cbd",
  },
  {
    "id": "002",
    "name": "Beach",
    "backgroundColor": "#F9E4B7",
    "primaryColor": "#2E8BC0",
    "secondaryColor": "#56C9D8",
    "accentColor1": "#82E0AA",
  },
  {
    "id": "003",
    "name": "Forest",
    "backgroundColor": "#B5EAAA",
    "primaryColor": "#4f3e28",
    "secondaryColor": "#437C43",
    "accentColor1": "#5FAD56",
  },
  {
    "id": "004",
    "name": "High-Tech",
    "backgroundColor": "#EEEEEE",
    "primaryColor": "#8E8E8E",
    "secondaryColor": "#00b3b3",
    "accentColor1": "#0080ff",
  },
  {
    "id": "005",
    "name": "Halloween",
    "backgroundColor": "#1F1F1F",
    "primaryColor": "#FFA500",
    "secondaryColor": "#8A2BE2",
    "accentColor1": "#FF6347",
  },
  {
    "id": "006",
    "name": "Winter",
    "backgroundColor": "#D3E5E8",
    "primaryColor": "#0f6cbd",
    "secondaryColor": "#0f6cbd",
    "accentColor1": "#2992e8",
  },
  {
    "id": "007",
    "name": "Christmas",
    "backgroundColor": "#eaeaea",
    "primaryColor": "#C8102E",
    "secondaryColor": "#034638",
    "accentColor1": "#D4AF37",
  },
  {
    "id": "008",
    "name": "Ocean",
    "backgroundColor": "#81D4FA",
    "primaryColor": "#01579B",
    "secondaryColor": "#00ACC1",
    "accentColor1": "#0288D1",
  },
  {
    "id": "009",
    "name": "Bee Garden",
    "backgroundColor": "#F0F4C3",
    "primaryColor": "#FFC107",
    "secondaryColor": "#8BC34A",
    "accentColor1": "#795548",
  },
  {
    "id": "010",
    "name": "Sunset",
    "backgroundColor": "#faa06b",
    "primaryColor": "#fde300",
    "secondaryColor": "#f600f6",
    "accentColor1": "#5b5fc7",
  },
  {
    "id": "011",
    "name": "Astronomy",
    "backgroundColor": "#212121",
    "primaryColor": "#03DAC5",
    "secondaryColor": "#FFD54F",
    "accentColor1": "#FF4081",
  },
  {
    "id": "012",
    "name": "Valentine's Day",
    "backgroundColor": "#FFC0CB",
    "primaryColor": "#FF69B4",
    "secondaryColor": "#FFB6C1",
    "accentColor1": "#FF1493",
  },
  {
    "id": "013",
    "name": "Lavender",
    "backgroundColor": "#E6E6FA",
    "primaryColor": "#6A5ACD",
    "secondaryColor": "#8A2BE2",
    "accentColor1": "#9370DB",
  },
  {
    "id": "014",
    "name": "Dawn",
    "backgroundColor": "#FFD180",
    "primaryColor": "#FF5722",
    "secondaryColor": "#d53300",
    "accentColor1": "#62abf5",
  },
  {
    "id": "015",
    "name": "Zoo",
    "backgroundColor": "#C8E6C9",
    "primaryColor": "#4CAF50",
    "secondaryColor": "#388E3C",
    "accentColor1": "#FFEB3B",
  },
  {
    "id": "016",
    "name": "Songkran",
    "backgroundColor": "#FFEA00",
    "primaryColor": "#00C853",
    "secondaryColor": "#4A148C",
    "accentColor1": "#FF6F00",
  },
];

const themeQR = [
  {
    "id": "001",
    "name": "Standard",
    "firstshade": "#3174C2",
    "secondshade": "#3174c2",
    "thirdshade": "#c6d6e7",
    "fourthshade": "#c6d6e7",
  },
  {
    "id": "002",
    "name": "Beach",
    "firstshade": "#F9D976",
    "secondshade": "#F7D358",
    "thirdshade": "#F4D03F",
    "fourthshade": "#F1C40F",
  },
  {
    "id": "003",
    "name": "Forest Green to White",
    "firstshade": "#228B22",
    "secondshade": "#228B22",
    "thirdshade": "#7FFF00",
    "fourthshade": "#FFFFFF",
  },
  {
    "id": "004",
    "name": "High-Tech",
    "firstshade": "#B0BEC5",
    "secondshade": "#90A4AE",
    "thirdshade": "#78909C",
    "fourthshade": "#607D8B",
  },
  {
    "id": "005",
    "name": "Bee Garden",
    "firstshade": "#FFEB3B",
    "secondshade": "#FFD600",
    "thirdshade": "#FFC107",
    "fourthshade": "#FFB300",
  },
  {
    "id": "006",
    "name": "Valentine's Day",
    "firstshade": "#FFC0CB", // Pink
    "secondshade": "#FF69B4", // Hot Pink
    "thirdshade": "#FFB6C1", // Light Pink
    "fourthshade": "#FF1493", // Deep Pink
  },
  {
    "id": "007",
    "name": "Christmas",
    "firstshade": "#8B0000", // Dark Red
    "secondshade": "#FF4500", // Orange Red
    "thirdshade": "#228B22", // Forest Green
    "fourthshade": "#32CD32", // Lime Green
  },
  {
    "id": "008",
    "name": "Ocean",
    "firstshade": "#81D4FA",
    "secondshade": "#01579B",
    "thirdshade": "#00ACC1",
    "fourthshade": "#0288D1",
  },
  {
    "id": "009",
    "name": "Sunset",
    "firstshade": "#FF4500", // Orange Red
    "secondshade": "#FF6347", // Tomato
    "thirdshade": "#FF7F50", // Coral
    "fourthshade": "#FF8C00"  // Dark Orange
},
{
    "id": "010",
    "name": "Mountain",
    "firstshade": "#4B5320", // Army Green
    "secondshade": "#556B2F", // Dark Olive Green
    "thirdshade": "#6B8E23", // Olive Drab
    "fourthshade": "#8FBC8F"  // Dark Sea Green
},
{
    "id": "011",
    "name": "Desert",
    "firstshade": "#EDC9AF", // Desert Sand
    "secondshade": "#C19A6B", // Camel
    "thirdshade": "#8B4513", // Saddle Brown
    "fourthshade": "#D2B48C"  // Tan
},
{
    "id": "012",
    "name": "Candy",
    "firstshade": "#FF1493", // Deep Pink
    "secondshade": "#FF69B4", // Hot Pink
    "thirdshade": "#FFB6C1", // Light Pink
    "fourthshade": "#FFC0CB"  // Pink
},
{
    "id": "013",
    "name": "Galaxy",
    "firstshade": "#0D1B2A", // Space Cadet
    "secondshade": "#1B263B", // Gunmetal
    "thirdshade": "#415A77", // Independence
    "fourthshade": "#778DA9"  // Shadow Blue
},
{
    "id": "014",
    "name": "Autumn",
    "firstshade": "#D2691E", // Chocolate
    "secondshade": "#FF7F50", // Coral
    "thirdshade": "#FFA07A", // Light Salmon
    "fourthshade": "#FFD700"  // Gold
},
{
    "id": "015",
    "name": "Ice",
    "firstshade": "#B0E0E6", // Powder Blue
    "secondshade": "#AFEEEE", // Pale Turquoise
    "thirdshade": "#E0FFFF", // Light Cyan
    "fourthshade": "#F0F8FF"  // Alice Blue
},
{
    "id": "016",
    "name": "Lavender Fields",
    "firstshade": "#E6E6FA", // Lavender
    "secondshade": "#D8BFD8", // Thistle
    "thirdshade": "#DDA0DD", // Plum
    "fourthshade": "#EE82EE"  // Violet
},
];

module.exports = { theme, themeQR };
