import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import paginate from 'jw-paginate';

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  Selection,
  SelectionMode,
  Icon,
  Calendar,
  DateRangeType,
  Spinner,
  SpinnerSize,
  SearchBox,
  CommandBar,
  Stack,
  PrimaryButton,
  DefaultButton,
  TextField,
  ContextualMenu,
  CheckboxVisibility,
  Dialog,
  DialogType,
  DialogFooter,
} from '@fluentui/react';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

const API_URL = process.env.REACT_APP_API_URL;

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

const Shipnity = () => {
  console.log('Home render!!!');

  const history = useHistory();

  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];

  const { t, i18n } = useTranslation();

  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(true);

  const [pageData, setPageData] = useState({});
  const [pageSize, setPageSize] = useState(sessionStorage.getItem('shipnity-pageSize') * 1 || 10);
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('shipnity-currentPage') * 1 || 1);
  const [pageDropdown, setPageDropdown] = useState([]);
  //CommandBars Items
  const [documentType, setDocumentType] = useState(
    sessionStorage.getItem('shipnity-documentType-key')
      ? {
          key: sessionStorage.getItem('shipnity-documentType-key'),
          text: sessionStorage.getItem('shipnity-documentType-text'),
        }
      : {
          key: 'CLOSED',
          text: t('shipnity.closed'),
        }
  );

  const [documentDateType, setDocumentDateType] = useState(sessionStorage.getItem('shipnity-documentDateType-key')
  ? {
      key: sessionStorage.getItem('shipnity-documentDateType-key'),
      text: sessionStorage.getItem('shipnity-documentDateType-text'),
    }
  : {
    key: 'created',
    text: "วันที่สร้างออเดอร์"
  })

  const [company, setCompany] = useState('');
  const [search, setSearch] = useState('');

  const percentVat = 7;
  const zeroVat = 0;
  const nonVat = -1;

  const percentVatText = '7%';
  const zeroVatText = '0%';
  // const nonVatText = '-';

  const includeVat = true;
  const registerVat = true;

  const [isLoading, setIsLoading] = useState(false);

  const isCompactMode = false;

  const [isCreateDocument, setIsCreateDocument] = useState(false);
  const [isCreateDocumentComplete, setIsCreateDocumentComplete] = useState(false);

  const companyId = sessionStorage.getItem('companyId') || '';

  const [selectedItems, setSelectedItems] = useState([]);

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        console.log('isCreateDocument: ', isCreateDocument);
        console.log('isCreateDocumentComplete: ', isCreateDocumentComplete);
        let _selectedItems = selection.getSelection();

        setSelectedItems(_selectedItems);
      },
    })
  );

  const [dialogConfirm, setDialogConfirm] = useState({
    isClick: false,
    header: '',
    message: '',
    onConfirm: null,
    onCancel: null,
  });

  const [createdBy, setCreatedBy] = useState('');

  const [primarySort, setPrimarySort] = useState(sessionStorage.getItem("shipnity-primarySort") || "number");
  const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(() => {
    if (sessionStorage.getItem("shipnity-sort-number") === "false") {
      return false;
    } else {
      return true;
    }
  });

  const [totalPage, setTotalPage] = useState(1)

  useEffect(() => {
    let _pageDropdown = [];

    for (let i = 1; i <= totalPage; i++) {
      _pageDropdown.push({
          key: i,
          text: getPageText(i),
          iconProps: { iconName: "Page" },
          onClick: () => {
            console.log("Selected page: " + i);
            setCurrentPage(i);
          },
      });
    }

    setPageDropdown(_pageDropdown);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPage, i18n.language])
  

  const DayPickerStrings = {
    months: [
      t('shipnity.months01'),
      t('shipnity.months02'),
      t('shipnity.months03'),
      t('shipnity.months04'),
      t('shipnity.months05'),
      t('shipnity.months06'),
      t('shipnity.months07'),
      t('shipnity.months08'),
      t('shipnity.months09'),
      t('shipnity.months10'),
      t('shipnity.months11'),
      t('shipnity.months12'),
    ],

    shortMonths: [
      t('shipnity.shortMonths01'),
      t('shipnity.shortMonths02'),
      t('shipnity.shortMonths03'),
      t('shipnity.shortMonths04'),
      t('shipnity.shortMonths05'),
      t('shipnity.shortMonths06'),
      t('shipnity.shortMonths07'),
      t('shipnity.shortMonths08'),
      t('shipnity.shortMonths09'),
      t('shipnity.shortMonths10'),
      t('shipnity.shortMonths11'),
      t('shipnity.shortMonths12'),
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: t('shipnity.goToToday'),
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: t('shipnity.isRequiredErrorMessage'),

    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };

  // Processing dialog style
  const spinnerStyles = {
    circle: {
      height: 56,
      width: 56,
      borderWidth: 4,
    },
  };

  const getMonth = (goBack) => {
    let monthNames = [
      t('shipnity.months01'),
      t('shipnity.months02'),
      t('shipnity.months03'),
      t('shipnity.months04'),
      t('shipnity.months05'),
      t('shipnity.months06'),
      t('shipnity.months07'),
      t('shipnity.months08'),
      t('shipnity.months09'),
      t('shipnity.months10'),
      t('shipnity.months11'),
      t('shipnity.months12'),
    ];

    let d = new Date();

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + ' ' + year,
    };
  };

  const [queryMonth, setQueryMonth] = useState(
    (sessionStorage.getItem('shipnity-queryMonth-key') && {
      key: sessionStorage.getItem('shipnity-queryMonth-key'),
      text: sessionStorage.getItem('shipnity-queryMonth-text'),
    }) || { key: getMonth(0).year + '' + getMonth(0).key, text: t('shipnity.thisMonth') }
  );

  const [date, setDate] = useState(sessionStorage.getItem('shipnity-date') ? new Date(sessionStorage.getItem('shipnity-date')) : new Date());

  const getFrom = (month, year) => {
    let _firstDay = new Date(year, month, 1);
    console.log('First day: ', formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getTo = (month, year) => {
    let _lastDay = new Date(year, month + 1, 1);
    console.log('Last day: ', formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const formatDate = (d) => {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const [from, setFrom] = useState(sessionStorage.getItem('shipnity-from') || getFrom(getMonth(0).month, getMonth(0).year));
  const [to, setTo] = useState(sessionStorage.getItem('shipnity-to') || getTo(getMonth(0).month, getMonth(0).year));

  function getDocumentTypeText(key) {
    if (key === 'UNCONFIRMED') return t('shipnity.UNCONFIRMED');
    if (key === 'CHECK_TRANSFER') return t('shipnity.CHECK_TRANSFER');
    if (key === 'NOT_FULLY_PAY') return t('shipnity.NOT_FULLY_PAY');
    if (key === 'NOT_READY_TO_SHIP') return t('shipnity.NOT_READY_TO_SHIP');
    if (key === 'PRINT_ORDER') return t('shipnity.PRINT_ORDER');
    if (key === 'PACKING') return t('shipnity.PACKING');
    if (key === 'PACKED') return t('shipnity.PACKED');
    if (key === 'PAY_LATER') return t('shipnity.PAY_LATER');
    if (key === 'PAY_LATER_CLOSED') return t('shipnity.PAY_LATER_CLOSED');
    if (key === 'CONFIRM_SHIP') return t('shipnity.CONFIRM_SHIP');
    if (key === 'HOLDING') return t('shipnity.HOLDING');
    if (key === 'PREORDER') return t('shipnity.PREORDER');
    if (key === 'EXPIRED') return t('shipnity.EXPIRED');
    if (key === 'CLOSED') return t('shipnity.closed');
  }

  function getDocumentDateTypeText(key) {
    if (key === 'created') return t('shipnity.created');
    if (key === 'closed') return t('shipnity.closedDate');
    if (key === 'transferred') return t('shipnity.transferred');
    if (key === 'mark_transferred') return t('shipnity.mark_transferred');
    if (key === 'printed') return t('shipnity.printed');
  }

  function getPageText(pageNumber) {
    return t("shipnity.page") + " " + pageNumber
  }

  function getDateText(monthObject) {
    if (monthObject.text === 'เดือนนี้' || monthObject.text === 'This Month') return t('shipnity.thisMonth');

    let monthNames = [
      t('shipnity.months01'),
      t('shipnity.months02'),
      t('shipnity.months03'),
      t('shipnity.months04'),
      t('shipnity.months05'),
      t('shipnity.months06'),
      t('shipnity.months07'),
      t('shipnity.months08'),
      t('shipnity.months09'),
      t('shipnity.months10'),
      t('shipnity.months11'),
      t('shipnity.months12'),
    ];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    if (i18n.language === 'th') {
      _year = _year + 543;
    }

    return monthNames[_monthIndex] + ' ' + _year;
  }

  const commandBarItems = [
    {
      key: documentType.key,
      text: getDocumentTypeText(documentType.key),
      iconProps: { iconName: 'ProductList' },
      disabled: !loadDataComplete || isCreateDocument,
      subMenuProps: {
        items: [
          {
            key: 'CLOSED',
            text: t('shipnity.closed'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('ส่งของแล้ว');
              setDocumentType({
                key: 'CLOSED',
                text: t('shipnity.closed'),
              });

              sessionStorage.setItem('shipnity-documentType-key', 'CLOSED');
              sessionStorage.setItem('shipnity-documentType-text', t('shipnity.closed'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'OPEN',
            text: t('shipnity.open'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('ออเดอร์ใหม่');
              setDocumentType({
                key: 'OPEN',
                text: t('shipnity.open'),
              });

              sessionStorage.setItem('shipnity-documentType-key', 'OPEN');
              sessionStorage.setItem('shipnity-documentType-text', t('shipnity.open'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'UNCONFIRMED',
            text: t('shipnity.UNCONFIRMED'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('ตรวจหลักฐานการโอนเงิน');
              setDocumentType({
                key: 'UNCONFIRMED',
                text: t('shipnity.UNCONFIRMED'),
              });

              sessionStorage.setItem('shipnity-documentType-key', 'UNCONFIRMED');
              sessionStorage.setItem('shipnity-documentType-text', t('shipnity.UNCONFIRMED'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'CHECK_TRANSFER',
            text: t('shipnity.CHECK_TRANSFER'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('รอพิมพ์ชื่อที่อยู่');
              setDocumentType({
                key: 'CHECK_TRANSFER',
                text: t('shipnity.CHECK_TRANSFER'),
              });

              sessionStorage.setItem('shipnity-documentType-key', 'CHECK_TRANSFER');
              sessionStorage.setItem('shipnity-documentType-text', t('shipnity.CHECK_TRANSFER'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'NOT_FULLY_PAY',
            text: t('shipnity.NOT_FULLY_PAY'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('รอเลขพัสดุ');
              setDocumentType({
                key: 'NOT_FULLY_PAY',
                text: t('shipnity.NOT_FULLY_PAY'),
              });

              sessionStorage.setItem('shipnity-documentType-key', 'NOT_FULLY_PAY');
              sessionStorage.setItem('shipnity-documentType-text', t('shipnity.NOT_FULLY_PAY'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'NOT_READY_TO_SHIP',
            text: t('shipnity.NOT_READY_TO_SHIP'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('จ่ายเงินย้อนหลัง');
              setDocumentType({
                key: 'NOT_READY_TO_SHIP',
                text: t('shipnity.NOT_READY_TO_SHIP'),
              });

              sessionStorage.setItem('shipnity-documentType-key', 'NOT_READY_TO_SHIP');
              sessionStorage.setItem('shipnity-documentType-text', t('shipnity.NOT_READY_TO_SHIP'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'PRINT_ORDER',
            text: t('shipnity.PRINT_ORDER'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('พรีออเดอร์');
              setDocumentType({
                key: 'PRINT_ORDER',
                text: t('shipnity.PRINT_ORDER'),
              });

              sessionStorage.setItem('shipnity-documentType-key', 'PRINT_ORDER');
              sessionStorage.setItem('shipnity-documentType-text', t('shipnity.PRINT_ORDER'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'PACKING',
            text: t('shipnity.PACKING'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('เกินกำหนดชำระ');
              setDocumentType({
                key: 'PACKING',
                text: t('shipnity.PACKING'),
              });

              sessionStorage.setItem('shipnity-documentType-key', 'PACKING');
              sessionStorage.setItem('shipnity-documentType-text', t('shipnity.PACKING'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'PACKED',
            text: t('shipnity.PACKED'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('เกินกำหนดชำระ');
              setDocumentType({
                key: 'PACKED',
                text: t('shipnity.PACKED'),
              });

              sessionStorage.setItem('shipnity-documentType-key', 'PACKED');
              sessionStorage.setItem('shipnity-documentType-text', t('shipnity.PACKED'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'PAY_LATER',
            text: t('shipnity.PAY_LATER'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('เกินกำหนดชำระ');
              setDocumentType({
                key: 'PAY_LATER',
                text: t('shipnity.PAY_LATER'),
              });

              sessionStorage.setItem('shipnity-documentType-key', 'PAY_LATER');
              sessionStorage.setItem('shipnity-documentType-text', t('shipnity.PAY_LATER'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'PAY_LATER_CLOSED',
            text: t('shipnity.PAY_LATER_CLOSED'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('เกินกำหนดชำระ');
              setDocumentType({
                key: 'PAY_LATER_CLOSED',
                text: t('shipnity.PAY_LATER_CLOSED'),
              });

              sessionStorage.setItem('shipnity-documentType-key', 'PAY_LATER_CLOSED');
              sessionStorage.setItem('shipnity-documentType-text', t('shipnity.PAY_LATER_CLOSED'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'CONFIRM_SHIP',
            text: t('shipnity.CONFIRM_SHIP'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('เกินกำหนดชำระ');
              setDocumentType({
                key: 'CONFIRM_SHIP',
                text: t('shipnity.CONFIRM_SHIP'),
              });

              sessionStorage.setItem('shipnity-documentType-key', 'CONFIRM_SHIP');
              sessionStorage.setItem('shipnity-documentType-text', t('shipnity.CONFIRM_SHIP'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'HOLDING',
            text: t('shipnity.HOLDING'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('เกินกำหนดชำระ');
              setDocumentType({
                key: 'HOLDING',
                text: t('shipnity.HOLDING'),
              });

              sessionStorage.setItem('shipnity-documentType-key', 'HOLDING');
              sessionStorage.setItem('shipnity-documentType-text', t('shipnity.HOLDING'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'PREORDER',
            text: t('shipnity.PREORDER'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('เกินกำหนดชำระ');
              setDocumentType({
                key: 'PREORDER',
                text: t('shipnity.PREORDER'),
              });

              sessionStorage.setItem('shipnity-documentType-key', 'PREORDER');
              sessionStorage.setItem('shipnity-documentType-text', t('shipnity.PREORDER'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'EXPIRED',
            text: t('shipnity.EXPIRED'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('เกินกำหนดชำระ');
              setDocumentType({
                key: 'EXPIRED',
                text: t('shipnity.EXPIRED'),
              });

              sessionStorage.setItem('shipnity-documentType-key', 'EXPIRED');
              sessionStorage.setItem('shipnity-documentType-text', t('shipnity.EXPIRED'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          }
        ],
      },
    },
    {
      key: documentDateType.key,
      text: getDocumentDateTypeText(documentDateType.key),
      iconProps: { iconName: 'ProductList' },
      disabled: !loadDataComplete || isCreateDocument,
      subMenuProps: {
        items: [
          {
            key: 'created',
            text: t('shipnity.created'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('ส่งของแล้ว');
              setDocumentDateType({
                key: 'created',
                text: t('shipnity.created'),
              });

              sessionStorage.setItem('shipnity-documentDateType-key', 'created');
              sessionStorage.setItem('shipnity-documentDateType-text', t('shipnity.created'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'closed',
            text: t('shipnity.closedDate'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('ส่งของแล้ว');
              setDocumentDateType({
                key: 'closed',
                text: t('shipnity.closedDate'),
              });

              sessionStorage.setItem('shipnity-documentDateType-key', 'closed');
              sessionStorage.setItem('shipnity-documentDateType-text', t('shipnity.closedDate'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'transferred',
            text: t('shipnity.transferred'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('ส่งของแล้ว');
              setDocumentDateType({
                key: 'transferred',
                text: t('shipnity.transferred'),
              });

              sessionStorage.setItem('shipnity-documentDateType-key', 'transferred');
              sessionStorage.setItem('shipnity-documentDateType-text', t('shipnity.transferred'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'mark_transferred',
            text: t('shipnity.mark_transferred'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('ส่งของแล้ว');
              setDocumentDateType({
                key: 'mark_transferred',
                text: t('shipnity.mark_transferred'),
              });

              sessionStorage.setItem('shipnity-documentDateType-key', 'mark_transferred');
              sessionStorage.setItem('shipnity-documentDateType-text', t('shipnity.mark_transferred'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'printed',
            text: t('shipnity.printed'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('ส่งของแล้ว');
              setDocumentDateType({
                key: 'printed',
                text: t('shipnity.printed'),
              });

              sessionStorage.setItem('shipnity-documentDateType-key', 'printed');
              sessionStorage.setItem('shipnity-documentDateType-text', t('shipnity.printed'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
        ],
      },
    },
    {
      key: queryMonth.key,
      text: getDateText(queryMonth),
      iconProps: { iconName: 'Calendar' },
      disabled: !loadDataComplete || isCreateDocument,
      subMenuProps: {
        items: [
          {
            key: 'queryMonth',
          },
        ],
        onRenderMenuList: () => {
          return (
            <div style={{ width: 220 }}>
              <Calendar
                onSelectDate={(_date) => {
                  let monthNames = [
                    t('shipnity.months01'),
                    t('shipnity.months02'),
                    t('shipnity.months03'),
                    t('shipnity.months04'),
                    t('shipnity.months05'),
                    t('shipnity.months06'),
                    t('shipnity.months07'),
                    t('shipnity.months08'),
                    t('shipnity.months09'),
                    t('shipnity.months10'),
                    t('shipnity.months11'),
                    t('shipnity.months12'),
                  ];

                  setDate(_date);

                  sessionStorage.setItem('shipnity-date', _date.toISOString());

                  let adjustDate = _date;

                  let year = adjustDate.getFullYear() + 543;

                  setQueryMonth({
                    key: adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'),
                    text: monthNames[adjustDate.getMonth()] + ' ' + year,
                  });

                  sessionStorage.setItem(
                    'shipnity-queryMonth-key',
                    adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0')
                  );
                  sessionStorage.setItem('shipnity-queryMonth-text', monthNames[adjustDate.getMonth()] + ' ' + year);

                  setFrom(getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  setTo(getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  sessionStorage.setItem('shipnity-from', getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  sessionStorage.setItem('shipnity-to', getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  setLoadDataComplete(false);
                  setItems([]);
                  setGetNewDocuments(true);

                  setCurrentPage(1);

                  setSearch('');
                }}
                isMonthPickerVisible={true}
                highlightSelectedMonth={true}
                isDayPickerVisible={false}
                dateRangeType={DateRangeType.Day}
                autoNavigateOnSelection={false}
                showGoToToday={false}
                value={date}
                strings={DayPickerStrings}
                showSixWeeksByDefault={true}
              />
            </div>
          );
        },
      },
    },
    {
      key: 'pageSize ' + pageSize,
      text: pageSize + ' ' + t('shipnity.documentPerPage'),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'NumberedList' },
      disabled: !loadDataComplete || isCreateDocument,
      subMenuProps: {
        items: [
          {
            key: 'pageSize ' + 10,
            text: '10 ' + t('shipnity.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('10 ออเดอร์/หน้า');
              setPageSize(10);
              sessionStorage.setItem('shipnity-pageSize', 10);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'pageSize ' + 20,
            text: '20 ' + t('shipnity.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('20 ออเดอร์/หน้า');
              setPageSize(20);
              sessionStorage.setItem('shipnity-pageSize', 20);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'pageSize ' + 30,
            text: '30 ' + t('shipnity.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('30 ออเดอร์/หน้า');
              setPageSize(30);
              sessionStorage.setItem('shipnity-pageSize', 30);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'pageSize ' + 40,
            text: '40 ' + t('shipnity.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('40 ออเดอร์/หน้า');
              setPageSize(40);
              sessionStorage.setItem('shipnity-pageSize', 40);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'pageSize ' + 50,
            text: '50 ' + t('shipnity.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('50 ออเดอร์/หน้า');
              setPageSize(50);
              sessionStorage.setItem('shipnity-pageSize', 50);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
        ],
      },
    },
    {
      key: currentPage,
      text: t('shipnity.page') + ' ' + currentPage,
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete || isCreateDocument,
      subMenuProps: {
        items: pageDropdown,
      },
    },
    {
      key: 'refresh',
      text: t('shipnity.refresh'),
      iconProps: { iconName: 'Refresh' },
      disabled: !loadDataComplete || isCreateDocument,
      onClick: () => {
        console.log('Refresh');

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);
      },
    },
  ];

  const overflowItems = [
    {
      key: 'disconnect',
      text: t('shipnity.disconnect'),
      onClick: () => {
        console.log('Shipnity disconnect!');
        setDialogConfirm((prev) => ({
          ...prev,
          isClick: true,
          header: t('shipnity.disconnect'),
          message: t('shipnity.disconnectConfirm'),
          onConfirm: () => {
            disconnect(true);
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            }));
          },
          onCancel: () =>
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            })),
        }));
      },
      iconProps: { iconName: 'PlugDisconnected' },
      disabled: !loadDataComplete || isCreateDocument,
    },
  ];

  const toThaiDateString = (isoDateTime) => {
    let date = '';
    date = new Date(isoDateTime);

    let year = date.getFullYear() + 543;
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let numOfDay = date.getDate().toString().padStart(2, '0');

    let hour = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');
    let second = date.getSeconds().toString().padStart(2, '0');

    return `${numOfDay}/${month}/${year} ${hour}:${minutes}:${second} น.`;
  };

  const createDocument = (documentType, item) => {
    let documentPath = '/etax/documents/receipts';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        documentPath = '/etax/documents/receipts-taxinvoices';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        documentPath = '/etax/documents/receipts-taxinvoices-abb';
        break;
      case 'RECEIPT':
        documentPath = '/etax/documents/receipts';
        break;
      case 'INVOICE-TAXINVOICE':
        documentPath = '/etax/documents/invoices-taxinvoices';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        documentPath = '/etax/documents/deliveryorders-taxinvoices';
        break;
      case 'TAXINVOICE':
        documentPath = '/etax/documents/taxinvoices';
        break;
      default:
        break;
    }

    let _date = new Date(item.date);

    let _dateBE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + (_date.getFullYear() + 543);
    let _dateCE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + _date.getFullYear();

    let _taxId = '';
    let _taxNumberType = '';
    let _branchNumber = '';
    let _branchText = '';

    if (item.customerTaxId && item.customerTaxId.length === 13) {
      if (
        item.customerName.includes('บริษัท') ||
        item.customerName.includes('ห้างหุ้นส่วน') ||
        item.customerName.includes('Company') ||
        item.customerName.includes('company') ||
        item.customerName.includes('ltd.') ||
        item.customerName.includes('Ltd.') ||
        item.customerName.includes('Limited') ||
        item.customerName.includes('limited') ||
        item.customerAddress.includes('สำนักงานใหญ่') ||
        /Head Office/i.test(item.customerAddress) ||
        item.customerAddress.includes('สาขา') ||
        /Branch/i.test(item.customerAddress)
      ) {
        if (
          item.customerName.includes('สำนักงานใหญ่') ||
          item.customerAddress.includes('สำนักงานใหญ่') ||
          /Head Office/i.test(item.customerAddress)
        ) {
          _taxNumberType = 'TXID';
          _branchNumber = '00000';
          _branchText = 'สำนักงานใหญ่';
          _taxId = item.customerTaxId;
        } else if (item.customerName.includes('สาขา') || item.customerAddress.includes('สาขา') || /Branch/i.test(item.customerAddress)) {
          _taxNumberType = 'TXID';
          _branchNumber = '00000';
          _branchText = 'สาขาที่';
          _taxId = item.customerTaxId;
        } else {
          _taxId = 'N/A';
          _taxNumberType = 'OTHR';
        }
      } else {
        _taxId = item.customerTaxId;
        _taxNumberType = 'NIDN';
      }
    } else {
      _taxId = 'N/A';
      _taxNumberType = 'OTHR';
    }

    let _customer = {
      addressLineOne: item.customerAddress ? item.customerAddress : '-',
      addressLineTwo: '',
      branchNumber: _branchNumber,
      branchText: _branchText,
      email: item.customerEmail,
      name: item.customerName,
      phone: item.customerPhone,
      phoneCountryCode: '+66',
      postcode: item.customerPostcode ? item.customerPostcode : '00000',
      taxId: _taxId,
      taxNumberType: _taxNumberType,
      language: 'th',
      unstructure: true,
    };

    let _items = [];

    for (let i = 0; i < item.items.length; i++) {
      let _quantity = item.items[i].quantity * 1;
      let _price = Math.round(item.items[i].price * 100) / 100;
      let _discount = item.items[i].discount * 1;
      let _total = Math.round((_price - _discount) * _quantity * 100) / 100;

      _items.push({
        number: i + 1 + '',
        description: item.items[i].name,
        quantity: item.items[i].quantity,
        unitCode: '',
        unitName: '-',
        price: _price,
        discount: _discount,
        total: _total,
      });
    }

    _items.push({
      number: item.items.length + 1 + '',
      description: 'ค่าขนส่ง',
      quantity: 1,
      unitCode: '',
      unitName: '-',
      price: item.shippingFee * 1,
      discount: 0,
      percentVat: percentVat,
      percentVatText: percentVatText,
      total: item.shippingFee * 1,
    });

    history.push({
      pathname: documentPath,
      state: {
        command: 'EDIT',
        amountTotal: item.amountTotal,
        company: {
          ...company,
          includeVat: includeVat,
        },
        customer: _customer,
        date: _date,
        dateBE: _dateBE,
        dateCE: _dateCE,
        discount: item.discount,
        grandTotal: item.grandTotal,
        items: _items,
        note: item.note,
        number: item.number,
        percentVat: percentVat,
        receivedBy: '',
        vatTotal: item.vatTotal,
        reIssue: false,
        backUrl: '/etax/sources/shipnity',
        includeVat: includeVat,
      },
    });
  };

  useEffect(() => {
    console.log('userEffect initial company call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data[0]) {
                if (companyId && response.data.find(_company => _company.id === companyId)) {
                  const _company = response.data.find(_company => _company.id === companyId)
                
                  if (!_company.includeVat) {
                    _company.includeVat = true;
                  }
  
                  setCompany(_company);
                } else {
                  if (!response.data[0].includeVat) {
                    response.data[0].includeVat = true;
                  }
  
                  setCompany(response.data[0]);

                }

              } else {
                history.push({
                  pathname: '/etax/companies/profiles',
                  state: {},
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    
  if (getNewDocuments) {
    console.log('userEffect getNewDocuments Call!');

    const _sort = isSortedDescendingNumber ? "id_DESC" : "id_ASC"

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        const [response] = await Promise.all([
          axios
            .get(
              // API_URL + '/sources/shipnity/orders/?filter=' + documentType.key + '&from=' + from + '&to=' + to + '&page=' + currentPage
              `${API_URL}/sources/shipnity/orders-v3?from=${from}&to=${to}&perPage=${pageSize}&page=${currentPage}&dateType=${documentDateType.key}${documentType.key ? `&orderStatus=${documentType.key}` : ""}${search ? `&search=${search}` : ""}&sort=${_sort}`
              , {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId
              },
            })
            .catch((error) => console.log(error)),
        ]);

        console.log("response", response);
        // console.log('Docs number: ', response.data.length);

        // setPageData(paginate(525, currentPage, pageSize, 10));

        const orders = response.data.order_list
        const _totalPage = response.data.pages_count

        setTotalPage(_totalPage)

        // let _pageDropdown = [];

        // for (let i = 1; i <= _totalPage; i++) {
        //     _pageDropdown.push({
        //         key: i,
        //         text: getPageText(i),
        //         iconProps: { iconName: "Page" },
        //         onClick: () => {
        //           console.log("Selected page: " + i);
        //           setCurrentPage(i);
        //         },
        //     });
        // }

        // setPageDropdown(_pageDropdown);

        if (currentPage > _totalPage) {
          setCurrentPage(1);
        }

        setPageData(paginate(_totalPage * pageSize, currentPage, pageSize, 10));

        const _numberList = orders.map(_order => {
          return String(_order.tax_invoice)
        }).join(",")

        const documentsResponse = await axios.get(`${API_URL}/documents/?numberList=${_numberList}`, {
                                      headers: {
                                          Authorization: "Bearer " + tokenResponse.accessToken,
                                          "cid": companyId
                                      },
                                  })
                                  .catch((error) => console.log(error))
                       
        const _docs = [];

        if (orders.length > 0) {
          for (let i = 0; i < Math.min(pageSize, orders.length); i++) {
            let createdTime = toThaiDateString(orders[i].created_at);
            let createdTimeValue = orders[i].created_at
            if (documentDateType.key === "closed") {
              createdTime = toThaiDateString(orders[i].closed_at);
              createdTimeValue = orders[i].closed_at
            } else if (documentDateType.key === "transferred") {
              createdTime = toThaiDateString(orders[i].transferred_at);
              createdTimeValue = orders[i].transferred_at
            } else if (documentDateType.key === "mark_transferred") {
              createdTime = toThaiDateString(orders[i].transferred_at);
              createdTimeValue = orders[i].transferred_at
            } else if (documentDateType.key === "printed") {
              createdTime = toThaiDateString(orders[i].transferred_at);
              createdTimeValue = orders[i].transferred_at
            }

            let _customerEmail = '';
            let _customerPhone = '';
            let _amountTotal = 0;
            let _vatTotal = 0;
            let _grandTotal = 0;

            if (orders[i].email && orders[i].email !== null && orders[i].email !== undefined) {
              _customerEmail = orders[i].email;
            }

            if (orders[i].tel && orders[i].tel !== null && orders[i].tel !== undefined) {
              _customerPhone = orders[i].tel;
            }

            if (orders[i].price) {
              if (includeVat) {
                _grandTotal = Math.round(orders[i].price * 100) / 100;

                _vatTotal = Math.round(((_grandTotal * percentVat) / (100 + percentVat)) * 100) / 100;

                _amountTotal = _grandTotal - _vatTotal;
              }
            }

            _docs.push({
              key: orders[i].id,
              createdTime: createdTime,
              createdTimeValue: createdTimeValue,
              date: orders[i].transferred_at,
              orderNumber: orders[i].invoice_number,
              number: orders[i].tax_invoice,
              customerName: orders[i].name,
              customerAddress: orders[i].address_without_zipcode,
              customerEmail: _customerEmail,
              customerPhone: _customerPhone,
              customerPostcode: orders[i].postcode,
              customerTaxId: orders[i].tax_id,
              items: orders[i].purchases,
              amountTotal: _amountTotal,
              vatTotal: _vatTotal,
              grandTotal: _grandTotal,
              note: orders[i].annotation,
              discount: orders[i].order_discount * 1,
              shippingFee: orders[i].shipping_fee,
              // etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.number === orders[i].tax_invoice),
              etaxCreated: orders[i].tax_invoice ? documentsResponse.data.some(_doc => _doc.data.number === String(orders[i].tax_invoice)) : false,
            });
          }
        }

        console.log('Docs data', _docs);
        setItems(_docs);

        setLoadDataComplete(true);
        setGetNewDocuments(false);
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments]);

  useEffect(() => {
    setLoadDataComplete(false);

    setItems([]);
    setGetNewDocuments(true);

    sessionStorage.setItem('shipnity-currentPage', currentPage);
  }, [currentPage]);

  useEffect(() => {
    console.log('isCreateDocumentComplete useEffect: ', isCreateDocumentComplete);

    if (loadDataComplete && !isCreateDocument) {
      let _items = selection.getItems();
      console.log('_items: ', _items);

      let _selectedItems = selection.getSelection();

      if (isCreateDocumentComplete) {
        setIsCreateDocumentComplete(false);

        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status !== '') {
            _items[i].status = '';
          }
        }
      } else {
        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status !== '') {
            _items[i].status = '';
          }
        }

        for (let i = 0; i < _selectedItems.length; i++) {
          console.log('select item key: ', _selectedItems[i].key);

          for (let j = 0; j < _items.length; j++) {
            if (_items[j].key === _selectedItems[i].key) {
              console.log('set pending item key: ', _items[j].key);
              _items[j].status = 'pending';
              console.log('_items: ', _items);
            }
          }
        }
      }

      setItems(_items);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  const columns = [
    {
      key: 'column1',
      name: getDocumentDateTypeText(documentDateType.key),
      fieldName: 'createdTime',
      minWidth: 70,
      maxWidth: 125,
      isResizable: true,
      data: 'number',
      onRender: (item) => {
        return <span>{item.createdTime}</span>;
      },
      // isPadded: true,
    },
    {
      key: 'column2',
      name: t('shipnity.orderNumber'),
      fieldName: 'orderNumber',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'number' ? true : false,
      isSortedDescending: isSortedDescendingNumber,
      isSortedAscending: !isSortedDescendingNumber,
      onColumnClick: (item) => {
        console.log('Sort document createTime!');
        setPrimarySort('number');
        setIsSortedDescendingNumber(!isSortedDescendingNumber);

        sessionStorage.setItem('shipnity-primarySort', 'number');
        sessionStorage.setItem('shipnity-sort-number', !isSortedDescendingNumber === false ? 'false' : 'true');

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setCurrentPage(1);

        setSearch('');
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.orderNumber}</span>;
      },
      // isPadded: true,
    },
    {
      key: 'column3',
      name: t('shipnity.number'),
      fieldName: 'number',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.number}</span>;
      },
      // isPadded: true,
    },
    {
      key: 'column4',
      name: t('shipnity.customerName'),
      fieldName: 'customerName',
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerName}</span>;
      },
      // isPadded: true,
    },

    {
      key: 'column5',
      name: t('shipnity.customerAddress'),
      fieldName: 'name',
      minWidth: 100,
      maxWidth: 270,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: '',
      isCollapsible: true,
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerAddress}</span>;
      },
      // isPadded: true,
    },

    {
      key: 'column6',
      name: t('shipnity.phone'),
      fieldName: 'name',
      minWidth: 80,
      maxWidth: 80,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerPhone}</span>;
      },
      // isPadded: true,
    },
    {
      key: 'column7',
      name: t('shipnity.vatTotal'),
      fieldName: 'vatTotal',
      minWidth: 60,
      maxWidth: 100,
      isResizable: true,
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {item.vatTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
      // isPadded: true,
    },
    {
      key: 'column8',
      name: t('shipnity.grandTotal'),
      fieldName: 'grandTotal',
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {item.grandTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
      // isPadded: true,
    },
    {
      key: 'column9',
      name: 'Leceipt',
      fieldName: 'etaxCreated',
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        if (item.etaxCreated) {
          return (
            <div style={{ textAlign: 'center' }}>
              <Icon iconName="SkypeCircleCheck" style={{ fontSize: '20px', color: 'green' }} />
            </div>
          );
        }
      },
    },
    {
      key: 'column10',
      name: '',
      fieldName: 'command',
      minWidth: 120,
      maxWidth: 120,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        if (isCreateDocument || isCreateDocumentComplete) {
          if (item.status === 'pending') {
            return <span style={{ color: '#106EBE' }}>{t('shipnity.pending')}</span>;
          } else if (item.status === 'processing') {
            return <Spinner label={t('shipnity.processing')} labelPosition="right" style={{ marginLeft: '0px' }} />;
          } else if (item.status === 'successful') {
            return <span style={{ color: 'green' }}>{t('shipnity.success')}</span>;
          } else if (item.status === 'fail') {
            return <span style={{ color: 'red' }}>{t('shipnity.fail')}</span>;
          }
        } else {
          return (
            <span data-selection-disabled={true}>
              <DefaultButton
                text={t('shipnity.create')}
                iconProps={{ iconName: 'Add', style: { color: '#106ebe' } }}
                menuProps={{
                  items: [
                    {
                      key: 'RECEIPT-TAXINVOICE',
                      text: t('shipnity.receipt-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('RECEIPT-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'RECEIPT-TAXINVOICE-ABB',
                      text: t('shipnity.receipt-taxinvoice-abb'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('RECEIPT-TAXINVOICE-ABB', item);
                      },
                    },
                    {
                      key: 'RECEIPT',
                      text: t('shipnity.receipt'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('RECEIPT', item);
                      },
                    },
                    {
                      key: 'INVOICE-TAXINVOICE',
                      text: t('shipnity.invoice-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('INVOICE-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'DELIVERYORDER-TAXINVOICE',
                      text: t('shipnity.deliveryorder-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('DELIVERYORDER-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'TAXINVOICE',
                      text: t('shipnity.taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('TAXINVOICE', item);
                      },
                    },
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
            </span>
          );
        }
      },
    },
  ];

  const disconnect = (_confirm) => {
    let confirm = _confirm;

    if (confirm) {
      setIsLoading(true);

      instance.acquireTokenSilent({ ...silentRequest, account: account }).then(
        (tokenResponse) => {
          axios
            .patch(
              API_URL + '/sources',
              {
                source: 'shipnity',
                replace: '/disabled',
                value: true,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId
                },
              }
            )
            .then((response) => {
              console.log(response);

              history.push({
                pathname: '/etax/sources/shipnity/connect',
                state: {},
              });

              setIsLoading(false);
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
              instance.acquireTokenRedirect(silentRequest);
            });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  const createSelectedDocuments = async (documentType) => {
    let _selectionItems = selection.getItems();

    let postUrl = '';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/receipts-taxinvoices/pdf-xml';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        postUrl = API_URL + '/etax/documents/receipts-taxinvoices-abb/pdf';
        break;
      case 'RECEIPT':
        postUrl = API_URL + '/etax/documents/receipts/pdf-xml';
        break;
      case 'INVOICE-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/invoices-taxinvoices/pdf-xml';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/deliveryorders-taxinvoices/pdf-xml';
        break;
      case 'TAXINVOICE':
        postUrl = API_URL + '/etax/documents/taxinvoices/pdf-xml';
        break;
      default:
        break;
    }

    if (postUrl) {
      await instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          for (let i = 0; i < selectedItems.length; i++) {
            console.log('create selected document: ', selectedItems[i]);

            for (let j = 0; j < _selectionItems.length; j++) {
              if (_selectionItems[j].key === selectedItems[i].key) {
                console.log('item key: ', _selectionItems[j].key);
                _selectionItems[j].status = 'processing';

                console.log('items: ', _selectionItems);
                setItems([..._selectionItems]);

                console.log('item: ', _selectionItems[j]);

                let _date = new Date(selectedItems[i].date);

                let _dateBE =
                  `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + (_date.getFullYear() + 543);
                let _dateCE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + _date.getFullYear();

                let _taxId = '';
                let _taxNumberType = '';
                let _branchNumber = '';
                let _branchText = '';

                if (selectedItems[i].customerTaxId && selectedItems[i].customerTaxId.length === 13) {
                  if (
                    selectedItems[i].customerName.includes('บริษัท') ||
                    selectedItems[i].customerName.includes('ห้างหุ้นส่วน') ||
                    selectedItems[i].customerName.includes('Company') ||
                    selectedItems[i].customerName.includes('company') ||
                    selectedItems[i].customerName.includes('ltd.') ||
                    selectedItems[i].customerName.includes('Ltd.') ||
                    selectedItems[i].customerName.includes('Limited') ||
                    selectedItems[i].customerName.includes('limited') ||
                    selectedItems[i].customerAddress.includes('สำนักงานใหญ่') ||
                    /Head Office/i.test(selectedItems[i].customerAddress) ||
                    selectedItems[i].customerAddress.includes('สาขา') ||
                    /Branch/i.test(selectedItems[i].customerAddress)
                  ) {
                    if (
                      selectedItems[i].customerName.includes('สำนักงานใหญ่') ||
                      selectedItems[i].customerAddress.includes('สำนักงานใหญ่') ||
                      /Head Office/i.test(selectedItems[i].customerAddress)
                    ) {
                      _taxNumberType = 'TXID';
                      _branchNumber = '00000';
                      _branchText = 'สำนักงานใหญ่';
                      _taxId = selectedItems[i].customerTaxId;
                    } else if (
                      selectedItems[i].customerName.includes('สาขา') ||
                      selectedItems[i].customerAddress.includes('สาขา') ||
                      /Branch/i.test(selectedItems[i].customerAddress)
                    ) {
                      _taxNumberType = 'TXID';
                      _branchNumber = '00000';
                      _branchText = 'สาขาที่';
                      _taxId = selectedItems[i].customerTaxId;
                    } else {
                      _taxId = 'N/A';
                      _taxNumberType = 'OTHR';
                    }
                  } else {
                    _taxId = selectedItems[i].customerTaxId;
                    _taxNumberType = 'NIDN';
                  }
                } else {
                  _taxId = 'N/A';
                  _taxNumberType = 'OTHR';
                }

                let _customer = {
                  addressLineOne: selectedItems[i].customerAddress ? selectedItems[i].customerAddress : '-',
                  addressLineTwo: '',
                  branchNumber: _branchNumber,
                  branchText: _branchText,
                  email: selectedItems[i].customerEmail,
                  name: selectedItems[i].customerName,
                  phone: selectedItems[i].customerPhone,
                  phoneCountryCode: '+66',
                  postcode: selectedItems[i].customerPostcode ? selectedItems[i].customerPostcode : '00000',
                  taxId: _taxId,
                  taxNumberType: _taxNumberType,
                  language: 'th',
                  unstructure: true,
                };

                let _items = [];

                for (let k = 0; k < selectedItems[i].items.length; k++) {
                  let _quantity = selectedItems[i].items[k].quantity * 1;
                  let _price = Math.round(selectedItems[i].items[k].price * 100) / 100;
                  let _discount = selectedItems[i].items[k].discount * 1;
                  let _total = Math.round((_price - _discount) * _quantity * 100) / 100;

                  if (registerVat) {
                    _items.push({
                      number: k + 1 + '',
                      description: selectedItems[i].items[k].name,
                      quantity: selectedItems[i].items[k].quantity,
                      unitCode: '',
                      unitName: '-',
                      price: _price,
                      discount: _discount,
                      percentVat: percentVat,
                      percentVatText: percentVatText,
                      total: _total,
                    });
                  } else {
                    _items.push({
                      number: k + 1 + '',
                      description: selectedItems[i].items[k].name,
                      quantity: selectedItems[i].items[k].quantity,
                      unitCode: '',
                      unitName: '-',
                      price: _price,
                      discount: _discount,
                      percentVat: zeroVat,
                      percentVatText: zeroVatText,
                      total: _total,
                    });
                  }
                }

                if (registerVat) {
                  _items.push({
                    number: selectedItems[i].items.length + 1 + '',
                    description: 'ค่าขนส่ง',
                    quantity: 1,
                    unitCode: '',
                    unitName: '-',
                    price: selectedItems[i].shippingFee * 1,
                    discount: 0,
                    percentVat: percentVat,
                    percentVatText: percentVatText,
                    total: selectedItems[i].shippingFee * 1,
                  });
                } else {
                  _items.push({
                    number: selectedItems[i].items.length + 1 + '',
                    description: 'ค่าขนส่ง',
                    quantity: 1,
                    unitCode: '',
                    unitName: '-',
                    price: selectedItems[i].shippingFee * 1,
                    discount: 0,
                    percentVat: zeroVat,
                    percentVatText: zeroVatText,
                    total: selectedItems[i].shippingFee * 1,
                  });
                }

                let _vatPriceTotal = 0;
                let _zeroVatPriceTotal = 0;
                let _nonVatPriceTotal = 0;

                _items.forEach((item) => {
                  if (item.percentVat === percentVat) {
                    _vatPriceTotal = _vatPriceTotal + item.total;
                  } else if (item.percentVat === zeroVat) {
                    _zeroVatPriceTotal = _zeroVatPriceTotal + item.total;
                  } else if (item.percentVat === nonVat) {
                    _nonVatPriceTotal = _nonVatPriceTotal + item.total;
                  }
                });

                const promise_PDF_XML = await new Promise(async (resolve) => {
                  if (selectedItems[i].number) {
                    await axios
                    .post(
                      postUrl,
                      {
                        amountTotal: selectedItems[i].amountTotal,
                        company: {
                          ...company,
                          includeVat: includeVat,
                        },
                        customer: _customer,
                        date: _date,
                        dateBE: _dateBE,
                        dateCE: _dateCE,
                        discount: selectedItems[i].discount,
                        grandTotal: selectedItems[i].grandTotal,
                        nonVatPriceTotal: _nonVatPriceTotal,
                        zeroVatPriceTotal: _zeroVatPriceTotal,
                        vatPriceTotal: _vatPriceTotal,
                        items: _items,
                        percentVat: registerVat ? percentVat : 0,
                        receivedBy: createdBy,
                        createdBy: createdBy,
                        vatTotal: selectedItems[i].vatTotal,
                        reIssue: false,
                        includeVat: includeVat,
                        number: selectedItems[i].number,
                        note: selectedItems[i].note,
                      },
                      {
                        headers: {
                          Authorization: 'Bearer ' + tokenResponse.accessToken,
                          'cid': companyId
                        },
                      }
                    )
                    .then(
                      async (response) => {
                        console.log('Create document response: ', response);

                        let count = 0;

                        let interval = setInterval(async () => {
                          await axios
                            .get(`${API_URL}/etax/jobs/${response.data.id}`, {
                              headers: {
                                Authorization: 'Bearer ' + tokenResponse.accessToken,
                                'Content-Type': 'application/json',
                                'cid': companyId
                              },
                            })
                            .then((jobsResponse) => {
                              count += 1;
                              console.log('Jobs response: ', j, jobsResponse.data.status, count);

                              if (jobsResponse.data.status === 'complete') {
                                _selectionItems[j].status = 'successful';
                                _selectionItems[j].etaxCreated = true;

                                setItems([..._selectionItems]);

                                clearInterval(interval);

                                resolve(true);
                              } else if (count >= 300) {
                                _selectionItems[j].status = 'fail';

                                setItems([..._selectionItems]);

                                clearInterval(interval);

                                resolve(false);
                              }
                            });
                        }, 1000);
                      },
                      (error) => {
                        console.log('Error Create document response: ', error);
                        _selectionItems[j].status = 'fail';
                        setItems([..._selectionItems]);
                        resolve(false);
                      }
                    );
                  } else {
                    _selectionItems[j].status = 'fail';
                    setItems([..._selectionItems]);
                    resolve(false);
                  }
                }); //promise

                Promise.all([promise_PDF_XML]).then((value) => {
                  console.log(i, value);
                });
              } //if
            } //for j
          } //for i
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });

      setIsCreateDocumentComplete(true);
      setIsCreateDocument(false);
    }

    setIsCreateDocumentComplete(true);
    setIsCreateDocument(false);
  };

  return (
    <div style={homeStyles}>
      {!isLoading && (
        <div>
          <Stack>
            <center>
              <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('shipnity.header')}</h2>
              {!loadDataComplete && (
                <div>
                  <Spinner
                    label={t('shipnity.loading')}
                    labelPosition="right"
                    style={{
                      marginTop: '21px',
                      marginBottom: '2px',
                    }}
                  />
                  <br />
                </div>
              )}
            </center>
          </Stack>

          {(selectedItems.length > 0 || isCreateDocument || isCreateDocumentComplete) && (
            <Stack vertical tokens={{ childrenGap: '10px' }}>
              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                <TextField
                  name="createdBy"
                  label={t('shipnity.createdBy')}
                  description={t('shipnity.createdByDesc')}
                  styles={{ root: { width: 200 } }}
                  onChange={(e) => {
                    setCreatedBy(e.target.value);
                  }}
                  value={createdBy}
                  disabled={isCreateDocument}
                />
              </Stack>
              <br />
            </Stack>
          )}

          {selectedItems.length > 0 && !isCreateDocument && (
            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
              <PrimaryButton
                text={t('shipnity.createDocument')}
                iconProps={{ iconName: 'Add' }}
                menuProps={{
                  items: [
                    {
                      key: 'RECEIPT-TAXINVOICE',
                      text: t('shipnity.receipt-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('RECEIPT-TAXINVOICE');
                      },
                    },
                    {
                      key: 'RECEIPT-TAXINVOICE-ABB',
                      text: t('shipnity.receipt-taxinvoice-abb'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('RECEIPT-TAXINVOICE-ABB');
                      },
                    },
                    {
                      key: 'RECEIPT',
                      text: t('shipnity.receipt'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('RECEIPT');
                      },
                    },
                    {
                      key: 'INVOICE-TAXINVOICE',
                      text: t('shipnity.invoice-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('INVOICE-TAXINVOICE');
                      },
                    },
                    {
                      key: 'DELIVERYORDER-TAXINVOICE',
                      text: t('shipnity.deliveryorder-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('DELIVERYORDER-TAXINVOICE');
                      },
                    },
                    {
                      key: 'TAXINVOICE',
                      text: t('shipnity.taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('TAXINVOICE');
                      },
                    },
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
              <DefaultButton
                text={t('shipnity.cancel')}
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  let _items = selection.getItems();
                  let _selectedItems = selection.getSelection();
                  console.log('selection: ', selection.getSelection());

                  for (let i = 0; i < _selectedItems.length; i++) {
                    console.log('select item key: ', _selectedItems[i].key);
                    _selectedItems[i].status = '';

                    for (let j = 0; j < _items.length; j++) {
                      if (_items[j].key === _selectedItems[i].key) {
                        console.log('item key: ', _items[j].key);
                        _items[j].status = '';
                        _selectedItems[i].status = '';
                      }
                    }
                  }

                  selection.setAllSelected(false);

                  setItems(_items);
                  console.log('selection: ', selection.getSelection());
                }}
              />
              <br />
            </Stack>
          )}

          {isCreateDocument && (
            <div>
              <Spinner label={t('shipnity.signProcessing')} size={SpinnerSize.large} />
            </div>
          )}

          {isCreateDocumentComplete && (
            <center>
              <PrimaryButton
                text={t('shipnity.createdDocuments')}
                iconProps={{ iconName: 'Documentation' }}
                onClick={async () => {
                  console.log(selectedItems);
                  history.push({
                    pathname: '/etax/documents/all',
                    state: {},
                  });
                }}
              />
            </center>
          )}

          <br />

          <Stack horizontal horizontalAlign="center">
            <Stack
              vertical
              style={{
                width: '98%',
                minWidth: '800px',
                maxWidth: '1350px',
              }}
            >
              <Stack
                horizontal
                // className="ms-bgColor-white"
                horizontalAlign="space-between"
                styles={{
                  root: {
                    // width: 'calc(100vw - 700px)',
                    minWidth: '800px',
                    maxWidth: '1350px',
                    backgroundColor: 'white',
                  },
                }}
              >
                <Stack
                  styles={{
                    root: {
                      width: 'calc(100vw - 600px)',
                      minWidth: '600px',
                      maxWidth: '650px',
                    },
                  }}
                >
                  <CommandBar items={commandBarItems} overflowItems={overflowItems} />
                </Stack>
                <Stack
                  horizontal
                  styles={{
                    root: {
                      height: 44,
                    },
                  }}
                  tokens={{ childrenGap: '10px' }}
                >
                  <SearchBox
                    className="ms-borderColor-themePrimary"
                    styles={{
                      root: {
                        marginTop: 6,
                        width: 270,
                        fontSize: 13,
                        fontWeight: 0,
                      },
                    }}
                    disabled={!loadDataComplete || isCreateDocument}
                    placeholder={t('shipnity.searchBoxPlaceholder')}
                    onChange={(e) => {
                      setSearch(e ? e.target.value : '');
                    }}
                    onClear={() => {
                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                    onSearch={() => {
                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                    value={search}
                  />
                  <PrimaryButton
                    disabled={!loadDataComplete || !search || isCreateDocument}
                    styles={{
                      root: {
                        marginTop: 6,
                        marginRight: 18,
                        width: 90,
                      },
                    }}
                    text={t('shipnity.search')}
                    onClick={() => {
                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                  />
                </Stack>
              </Stack>
              <ShimmeredDetailsList
                items={items || []}
                enableShimmer={!loadDataComplete}
                compact={isCompactMode}
                columns={columns}
                selection={selection}
                selectionMode={!loadDataComplete || isCreateDocument ? SelectionMode.none : SelectionMode.multiple}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                checkboxVisibility={CheckboxVisibility.always}
                selectionPreservedOnEmptyClick={true}
              />
            </Stack>
          </Stack>
          <br />
          <center>
            {!(currentPage === 1 && items.length < pageSize) && !(!loadDataComplete || isCreateDocument) && (
              <Pagination
                selectedPageIndex={currentPage - 1}
                pageCount={pageData.totalPages}
                onPageChange={onPageChange}
                format
                firstPageIconProps={{
                  iconName: 'DoubleChevronLeft',
                }}
                previousPageIconProps={{
                  iconName: 'ChevronLeft',
                }}
                nextPageIconProps={{
                  iconName: 'ChevronRight',
                }}
                lastPageIconProps={{
                  iconName: 'DoubleChevronRight',
                }}
              />
            )}

            <br />
            <br />
          </center>
        </div>
      )}

      {isLoading && (
        <div>
          <Stack>
            <center>
              <h2
                style={{
                  marginLeft: '0px',
                  marginTop: '25px',
                  marginBottom: '50px',
                }}
              >
                {t('shipnity.footer')}
              </h2>
            </center>
            <Stack horizontal horizontalAlign="center">
              <br />
              <br />
              <div>
                <Spinner label={t('shipnity.processing2')} labelPosition="down" styles={spinnerStyles} />
              </div>
              <br />
            </Stack>
          </Stack>
        </div>
      )}
      <Dialog
        hidden={!dialogConfirm.isClick}
        onDismiss={dialogConfirm.onCancel}
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: dialogConfirm.header,
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 }, subText: { textAlign: 'center' } },
          subText: dialogConfirm.message,
        }}
      >
        <DialogFooter styles={{ actionsRight: { textAlign: 'center' } }}>
          <PrimaryButton onClick={dialogConfirm.onConfirm} text={t('documents.confirm')} />
          <DefaultButton onClick={dialogConfirm.onCancel} text={t('documents.cancel')} />
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default Shipnity;
