import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { silentRequest } from '../../../../authConfig';

import * as signalR from '@microsoft/signalr';

import paginate from 'jw-paginate';

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  Calendar,
  DateRangeType,
  Selection,
  SelectionMode,
  CheckboxVisibility,
  mergeStyleSets,
  ActionButton,
  Spinner,
  SpinnerSize,
  CommandBar,
  SearchBox,
  Stack,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  mergeStyles,
  MessageBar,
  MessageBarType,
  DocumentCard,
  DocumentCardTitle,
  DocumentCardDetails,
  DocumentCardLogo,
  DocumentCardType,
  Text,
  ContextualMenu,
  ContextualMenuItemType,
  IconButton,
  Checkbox,
  DatePicker,
} from '@fluentui/react';

// import { getFileTypeIconProps } from '@uifabric/file-type-icons';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

import CountUp from 'react-countup';

import PdfAllPages from '../../../etax/documents/pdf/view/PdfAllPages';

import html2canvas from 'html2canvas';
import { TextFieldEmail } from '../../../../helpers/customComponents/TextFieldEmail';
import { checkEmail } from '../../../../helpers/CheckEmail';
var JSZip = require('jszip');

const API_URL = process.env.REACT_APP_API_URL;

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  minWidth: 'calc(100vw - 310px)',
  background: '#FAF9F8',
  position: 'relative',
};

//Dialog Style
// Success dialog style
const iconClass = mergeStyles({
  fontSize: 120,
  height: 120,
  width: 120,
  margin: '0 60px',
});

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px',
  },
  fileIconCell: {
    textAlign: 'center',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden',
      },
    },
  },
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
  },
  controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  exampleToggle: {
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '30px',
  },
  selectionDetails: {
    marginBottom: '20px',
  },
  blue: [
    {
      color: '#0078D4',
    },
    iconClass,
  ],
});

const cardStyles = {
  cardStyles: {
    root: {
      background: 'white',
      borderLeft: '5px solid #0078d4',
      width: '27%',
      minWidth: '332px',
      maxWidth: '424px',
      height: '130px',
    },
  },
  header: {
    root: {
      fontSize: 20,
      fontWeight: 'bold',
      paddingTop: '20px',
      textAlign: 'center',
    },
  },
  amount: {
    root: {
      fontSize: 30,
      paddingBottom: '25px',
      textAlign: 'center',
    },
  },
};

function formatDateTime(date) {
  let d = new Date(date.toLocaleString('en-GB', { timeZone: 'Asia/Bangkok' })),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hour = '' + d.getHours(),
    minute = '' + d.getMinutes(),
    second = '' + d.getSeconds();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hour.length < 2) hour = '0' + hour;
  if (minute.length < 2) minute = '0' + minute;
  if (second.length < 2) second = '0' + second;

  return [year, month, day, hour, minute, second].join('');
}

const onFormatDate = (date, format) => {
  if (format === "CE") {
    return `${date.getDate()}`.padStart(2, "0") + "/" + `${date.getMonth() + 1}`.padStart(2, "0") + "/" + date.getFullYear();
  } else {
    return `${date.getDate()}`.padStart(2, "0") + "/" + `${date.getMonth() + 1}`.padStart(2, "0") + "/" + (date.getFullYear() + 543);
  }
};

const WithholdingTaxDocuments = () => {
  console.log('Home render!!!');

  const { t, i18n } = useTranslation();

  const history = useHistory();

  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];

  const printRef = useRef();
  const [url, setUrl] = useState('');
  const [fileName, setFileName] = useState('');

  const [documents, setDocuments] = useState([]);
  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(true);
  const [totalProcessingDocuments, setTotalProcessingDocuments] = useState(0);
  const [pageData, setPageData] = useState({});
  const [pageSize, setPageSize] = useState(sessionStorage.getItem('withholdingtaxs-pageSize') * 1 || 10);
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('withholdingtaxs-currentPage') * 1 || 1);

  const [search, setSearch] = useState('');
  const [previousSearch, setPreviousSearch] = useState('');
  const [filterDocuments, setFilterDocuments] = useState([]);

  //CommandBars Items
  const [documentType, setDocumentType] = useState({
    key: 'WITHHOLDING-TAX',
    text: 'หนังสือรับรองการหักภาษี ณ ที่จ่าย',
  });

  const [isClickEmail, setIsClickEmail] = useState(false);
  const [isClickCopyEmail, setIsClickCopyEmail] = useState(false);
  const [fileIdEmail, setFileIdEmail] = useState('');
  const [fileNameEmail, setFileNameEmail] = useState('');
  const [fileDisplayName, setFileDisplayName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [emailList, setEmailList] = useState([]);
  const [copyEmailList, setCopyEmailList] = useState([])
  const [copyEmail, setCopyEmail] = useState('');
  const [showEmailSuccess, setShowEmailSuccess] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);

  const [showSmsSuccess, setShowSmsSuccess] = useState(false);
  const [showSmsError, setShowSmsError] = useState(false);

  //Display Summary
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [totalVat, setTotalVat] = useState(0);
  const [totalGrand, setTotalGrand] = useState(0);

  const [isProcessing, setIsProcessing] = useState(false);
  const [isEmailSetting, setIsEmailSetting] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        let _selectedItems = selection.getSelection();

        setSelectedItems(_selectedItems);
      },
    })
  );

  const companyId = sessionStorage.getItem('companyId') || '';

  const [dialogConfirm, setDialogConfirm] = useState({
    isClick: false,
    header: '',
    message: '',
    onConfirm: null,
  });

  const [primarySort, setPrimarySort] = useState(sessionStorage.getItem('withholdingtaxs-primarySort') || 'createdTime');
  const [isSortedDescendingCreatedTime, setIsSortedDescendingCreatedTime] = useState(() => {
    if (sessionStorage.getItem('withholdingtaxs-sort-createdTime') && sessionStorage.getItem('withholdingtaxs-sort-createdTime') === 'false') {
      return false;
    } else {
      return true;
    }
  });
  const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(() => {
    if (sessionStorage.getItem('withholdingtaxs-sort-number') && sessionStorage.getItem('withholdingtaxs-sort-number') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingDocumentDate, setIsSortedDescendingDocumentDate] = useState(() => {
    if (sessionStorage.getItem('withholdingtaxs-sort-documentDate') && sessionStorage.getItem('withholdingtaxs-sort-documentDate') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingCustomerName, setIsSortedDescendingCustomerName] = useState(() => {
    if (sessionStorage.getItem('withholdingtaxs-sort-customerName') && sessionStorage.getItem('withholdingtaxs-sort-customerName') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingCustomerPhone, setIsSortedDescendingCustomerPhone] = useState(() => {
    if (sessionStorage.getItem('withholdingtaxs-sort-customerPhone') && sessionStorage.getItem('withholdingtaxs-sort-customerPhone') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingVatTotal, setIsSortedDescendingVatTotal] = useState(() => {
    if (sessionStorage.getItem('withholdingtaxs-sort-vatTotal') && sessionStorage.getItem('withholdingtaxs-sort-vatTotal') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingGrandTotal, setIsSortedDescendingGrandTotal] = useState(() => {
    if (sessionStorage.getItem('withholdingtaxs-sort-grandTotal') && sessionStorage.getItem('withholdingtaxs-sort-grandTotal') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingName, setIsSortedDescendingName] = useState(() => {
    if (sessionStorage.getItem('withholdingtaxs-sort-name') && sessionStorage.getItem('withholdingtaxs-sort-name') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingEmailReceiver, setIsSortedDescendingEmailReceiver] = useState(() => {
    if (sessionStorage.getItem('withholdingtaxs-sort-emailReceiver') && sessionStorage.getItem('withholdingtaxs-sort-emailReceiver') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingEmailTime, setIsSortedDescendingEmailTime] = useState(() => {
    if (sessionStorage.getItem('withholdingtaxs-sort-emailTime') && sessionStorage.getItem('withholdingtaxs-sort-emailTime') === 'true') {
      return true;
    } else {
      return false;
    }
  });

  const DayPickerStrings = {
    months: [
      t('documents.months01'),
      t('documents.months02'),
      t('documents.months03'),
      t('documents.months04'),
      t('documents.months05'),
      t('documents.months06'),
      t('documents.months07'),
      t('documents.months08'),
      t('documents.months09'),
      t('documents.months10'),
      t('documents.months11'),
      t('documents.months12'),
    ],

    shortMonths: [
      t('documents.shortMonths01'),
      t('documents.shortMonths02'),
      t('documents.shortMonths03'),
      t('documents.shortMonths04'),
      t('documents.shortMonths05'),
      t('documents.shortMonths06'),
      t('documents.shortMonths07'),
      t('documents.shortMonths08'),
      t('documents.shortMonths09'),
      t('documents.shortMonths10'),
      t('documents.shortMonths11'),
      t('documents.shortMonths12'),
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: t('dynamicFo.goToToday'),
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: t('dynamicFo.isRequiredErrorMessage'),

    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };

  const getMonth = (goBack) => {
    let monthNames = [
      t('documents.months01'),
      t('documents.months02'),
      t('documents.months03'),
      t('documents.months04'),
      t('documents.months05'),
      t('documents.months06'),
      t('documents.months07'),
      t('documents.months08'),
      t('documents.months09'),
      t('documents.months10'),
      t('documents.months11'),
      t('documents.months12'),
    ];

    let d = new Date();

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + ' ' + year,
    };
  };

  const [queryMonth, setQueryMonth] = useState(
    (sessionStorage.getItem("withholdingtaxs-queryMonth-key") && {
      key: sessionStorage.getItem("withholdingtaxs-queryMonth-key"),
      text: sessionStorage.getItem("withholdingtaxs-queryMonth-text"),
      ...(sessionStorage.getItem("withholdingtaxs-queryMonth-type") ? { type: sessionStorage.getItem("withholdingtaxs-queryMonth-type") } : {}),
    }) || { key: getMonth(0).year + '' + getMonth(0).key, text: t('documents.thisMonth') }
  );

  const [timeRangeDate, setTimeRangeDate] = useState(sessionStorage.getItem("withholdingtaxs-queryMonth-type") ? true : false);
  const [fromDate, setFromDate] = useState(sessionStorage.getItem("withholdingtaxs-fromDate") ? new Date(sessionStorage.getItem("withholdingtaxs-fromDate")) : null);
  const [toDate, setToDate] = useState(sessionStorage.getItem("withholdingtaxs-toDate") ? new Date(sessionStorage.getItem("withholdingtaxs-toDate")) : null);
  const [timeRangeError, setTimeRangeError] = useState(false);

  const getFrom = (month, year) => {
    let _firstDay = new Date(year, month, 1);
    console.log('First day: ', formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getTo = (month, year) => {
    let _lastDay = new Date(year, month + 1, 1);
    console.log('Last day: ', formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const getFromDMY = (day, month, year) => {
    let _firstDay = new Date(year, month, day);
    console.log("First day: ", formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getToDMY = (day, month, year) => {
    let _lastDay = new Date(year, month, day + 1);
    console.log("Last day: ", formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const formatDate = (d) => {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const [from, setFrom] = useState(sessionStorage.getItem('withholdingtaxs-from') || getFrom(getMonth(0).month, getMonth(0).year));
  const [to, setTo] = useState(sessionStorage.getItem('withholdingtaxs-to') || getTo(getMonth(0).month, getMonth(0).year));

  const [date, setDate] = useState(
    sessionStorage.getItem('withholdingtaxs-date') ? new Date(sessionStorage.getItem('withholdingtaxs-date')) : new Date()
  );

  const [pageDropdown, setPageDropdown] = useState([]);
  const [dateType, setDateType] = useState(
    (sessionStorage.getItem('withholdingtaxs-dateType-key') && {
      key: sessionStorage.getItem('withholdingtaxs-dateType-key'),
      text: sessionStorage.getItem('withholdingtaxs-dateType-text'),
    }) || { key: 'created', text: t('documents.createdDate') }
  );

  const resetSort = () => {
    if (dateType.key === 'document') {
      setPrimarySort('documentDate');
      setIsSortedDescendingDocumentDate(true);
      setIsSortedDescendingCreatedTime(false);

      sessionStorage.setItem('withholdingtaxs-primarySort', 'documentDate');
      sessionStorage.setItem('withholdingtaxs-sort-documentDate', 'true');
      sessionStorage.setItem('withholdingtaxs-sort-createdTime', 'false');
    } else {
      setPrimarySort('createdTime');
      setIsSortedDescendingCreatedTime(true);
      setIsSortedDescendingDocumentDate(false);

      sessionStorage.setItem('withholdingtaxs-primarySort', 'createdTime');
      sessionStorage.setItem('withholdingtaxs-sort-createdTime', 'true');
      sessionStorage.setItem('withholdingtaxs-sort-documentDate', 'false');
    }

    setIsSortedDescendingNumber(false);
    setIsSortedDescendingCustomerName(false);
    setIsSortedDescendingCustomerPhone(false);
    setIsSortedDescendingVatTotal(false);
    setIsSortedDescendingGrandTotal(false);
    setIsSortedDescendingName(false);
    setIsSortedDescendingEmailReceiver(false);
    setIsSortedDescendingEmailTime(false);

    sessionStorage.setItem('withholdingtaxs-sort-number', 'false');
    sessionStorage.setItem('withholdingtaxs-sort-customerName', 'false');
    sessionStorage.setItem('withholdingtaxs-sort-customerPhone', 'false');
    sessionStorage.setItem('withholdingtaxs-sort-vatTotal', 'false');
    sessionStorage.setItem('withholdingtaxs-sort-grandTotal', 'false');
    sessionStorage.setItem('withholdingtaxs-sort-name', 'false');
    sessionStorage.setItem('withholdingtaxs-sort-emailReceiver', 'false');
    sessionStorage.setItem('withholdingtaxs-sort-emailTime', 'false');
  };

  function getDocumentTypeText(key) {
    if (key === 'ALL') return t('documents.allDocumentsType');
    if (key === 'RECEIPT-TAXINVOICE') return t('documents.receipt-taxinvoice');
    if (key === 'RECEIPT-TAXINVOICE-ABB') return t('documents.receipt-taxinvoice-abb');
    if (key === 'RECEIPT') return t('documents.receipt');
    if (key === 'INVOICE-TAXINVOICE') return t('documents.invoice-taxinvoice');
    if (key === 'DELIVERYORDER-TAXINVOICE') return t('documents.deliveryorder-taxinvoice');
    if (key === 'TAXINVOICE') return t('documents.taxinvoice');
    if (key === 'DEBIT-NOTE') return t('documents.debit-note');
    if (key === 'CREDIT-NOTE') return t('documents.credit-note');
    if (key === 'QUOTATION') return t('documents.quotation');
    if (key === 'INVOICE') return t('documents.invoice');
    if (key === 'BILLING-NOTE') return t('documents.billing-note');

    if (key === 'created') return t('documents.createdDate');
    if (key === 'document') return t('documents.documentDate');
  }

  function getDateText(monthObject) {
    if (monthObject.type === 'dateRange') {
      if (i18n.language === 'th') {
        return onFormatDate(fromDate, 'BE') + ' - ' + onFormatDate(toDate, 'BE');
      }
      return onFormatDate(fromDate, 'CE') + ' - ' + onFormatDate(toDate, 'CE');
    }
    if (monthObject.text === 'เดือนนี้' || monthObject.text === 'This Month') return t('documents.thisMonth');

    let monthNames = [
      t('documents.months01'),
      t('documents.months02'),
      t('documents.months03'),
      t('documents.months04'),
      t('documents.months05'),
      t('documents.months06'),
      t('documents.months07'),
      t('documents.months08'),
      t('documents.months09'),
      t('documents.months10'),
      t('documents.months11'),
      t('documents.months12'),
    ];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    if (i18n.language === 'th') {
      _year = _year + 543;
    }

    return monthNames[_monthIndex] + ' ' + _year;
  }

  const commandBarItems = [
    {
      key: dateType.key,
      text: getDocumentTypeText(dateType.key),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'CalendarSettings' },
      disabled: !loadDataComplete || isProcessing,
      subMenuProps: {
        items: [
          {
            key: 'created',
            text: t('documents.createdDate'),
            iconProps: { iconName: 'CalendarSettings' },
            onClick: () => {
              console.log(t('documents.createdDate'));
              setDateType({ key: 'created', text: t('documents.createdDate') });
              sessionStorage.setItem('withholdingtaxs-dateType-key', 'created');
              sessionStorage.setItem('withholdingtaxs-dateType-text', t('documents.createdDate'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              setPrimarySort('createdTime');
              setIsSortedDescendingCreatedTime(true);

              setIsSortedDescendingNumber(false);
              setIsSortedDescendingDocumentDate(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingCustomerPhone(false);
              setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);
              setIsSortedDescendingName(false);
              setIsSortedDescendingEmailReceiver(false);
              setIsSortedDescendingEmailTime(false);
            },
          },
          {
            key: 'document',
            text: t('documents.documentDate'),
            iconProps: { iconName: 'CalendarSettings' },
            onClick: () => {
              console.log(t('documents.documentDate'));
              setDateType({ key: 'document', text: t('documents.documentDate') });
              sessionStorage.setItem('withholdingtaxs-dateType-key', 'document');
              sessionStorage.setItem('withholdingtaxs-dateType-text', t('documents.documentDate'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);

              setCurrentPage(1);

              setPrimarySort('documentDate');
              setIsSortedDescendingDocumentDate(true);

              setIsSortedDescendingCreatedTime(false);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingCustomerPhone(false);
              setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);
              setIsSortedDescendingName(false);
              setIsSortedDescendingEmailReceiver(false);
              setIsSortedDescendingEmailTime(false);
            },
          },
        ],
      },
    },
    {
      key: queryMonth.key,
      text: getDateText(queryMonth),
      iconProps: { iconName: 'Calendar' },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: [
          {
            key: 'queryMonth',
          },
        ],
        onRenderMenuList: () => {
          return (
            <div style={{ width: 220 }}>
              <Stack styles={{ root: { padding: '12px 12px 0px 12px' } }}>
                <Checkbox
                  label={t('dynamicFo.timeRange')}
                  checked={timeRangeDate}
                  onChange={(e) => {
                    setTimeRangeDate((prev) => !prev);
                  }}
                />
              </Stack>
              {timeRangeDate ? (
                <Stack>
                <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                  <Stack styles={{ root: { width: '20%', justifyContent: 'center', alignItems: 'center' } }}>
                    <Text>{t('dynamicFo.fromRange')}</Text>
                  </Stack>
                  <Stack styles={{ root: { width: '80%', justifyContent: 'center' /* alignItems: 'center' */ } }}>
                    <DatePicker
                      styles={{ root: { width: '90%' } }}
                      placeholder={t('deliveryOrderTaxInvoice.pleaseSelect')}
                      strings={DayPickerStrings}
                      value={fromDate}
                      maxDate={toDate}
                      formatDate={onFormatDate}
                      onSelectDate={(_date) => {
                        setFromDate(_date);

                        setTimeRangeError(false);
                        if (Math.ceil(Math.abs(_date - toDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                      }}
                    />
                  </Stack>
                </Stack>
                <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                  <Stack styles={{ root: { width: '20%', justifyContent: 'center', alignItems: 'center' } }}>
                    <Text>{t('dynamicFo.toRange')}</Text>
                  </Stack>
                  <Stack styles={{ root: { width: '80%' /* alignItems: 'center' */ } }}>
                    <DatePicker
                      styles={{ root: { width: '90%' } }}
                      placeholder={t('deliveryOrderTaxInvoice.pleaseSelect')}
                      strings={DayPickerStrings}
                      value={toDate}
                      minDate={fromDate}
                      formatDate={onFormatDate}
                      onSelectDate={(_date) => {
                        setToDate(_date);

                        setTimeRangeError(false);
                        if (Math.ceil(Math.abs(_date - fromDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                      }}
                    />
                  </Stack>
                </Stack>
                {timeRangeError ? (
                  <Stack styles={{ root: { padding: '12px 0px 0px 12px' } }}>
                    <Text styles={{ root: { fontSize: 12, color: '#A4262C' } }}>{t('dynamicFo.timeRangeError')}</Text>
                  </Stack>
                ) : null}
                <Stack horizontal horizontalAlign="end" styles={{ root: { width: '92%', height: 52, padding: '10px 0px 10px 0px' } }}>
                  <PrimaryButton
                    text={t('dynamicFo.search')}
                    onClick={() => {
                      if (!timeRangeError && fromDate && toDate) {
                        setQueryMonth({
                          type: 'dateRange',
                          key: formatDate(fromDate) + ':' + formatDate(toDate),
                          text: formatDate(fromDate) + ' - ' + formatDate(toDate),
                        });

                        sessionStorage.setItem("withholdingtaxs-queryMonth-type", "dateRange");
                        sessionStorage.setItem("withholdingtaxs-queryMonth-key", formatDate(fromDate) + ":" + formatDate(toDate));
                        sessionStorage.setItem("withholdingtaxs-queryMonth-text", formatDate(fromDate) + " - " + formatDate(toDate));

                        setFrom(getFromDMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                        setTo(getToDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                        sessionStorage.setItem("withholdingtaxs-from", getFromDMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                        sessionStorage.setItem("withholdingtaxs-to", getToDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                        sessionStorage.setItem("withholdingtaxs-fromDate", fromDate.toISOString());
                        sessionStorage.setItem("withholdingtaxs-toDate", toDate.toISOString());

                        setLoadDataComplete(false);
                        setItems([]);
                        setGetNewDocuments(true);
                        setPreviousSearch('');
                        setSearch('');

                        setTotalDocuments(0);
                        setTotalVat(0);
                        setTotalGrand(0);

                        setCurrentPage(1);

                        resetSort();
                      }
                    }}
                    style={{ width: '125px', letterSpacing: '1px' }}
                  />
                </Stack>
              </Stack>
              ) : (
              <Calendar
                onSelectDate={(_date) => {
                  let monthNames = [
                    t('documents.months01'),
                    t('documents.months02'),
                    t('documents.months03'),
                    t('documents.months04'),
                    t('documents.months05'),
                    t('documents.months06'),
                    t('documents.months07'),
                    t('documents.months08'),
                    t('documents.months09'),
                    t('documents.months10'),
                    t('documents.months11'),
                    t('documents.months12'),
                  ];

                  setDate(_date);

                  sessionStorage.setItem('withholdingtaxs-date', _date.toISOString());

                  let adjustDate = _date;

                  let year = adjustDate.getFullYear() + 543;

                  setQueryMonth({
                    key: adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'),
                    text: monthNames[adjustDate.getMonth()] + ' ' + year,
                  });

                  sessionStorage.setItem("withholdingtaxs-queryMonth-type", "");
                  sessionStorage.setItem(
                    'withholdingtaxs-queryMonth-key',
                    adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0')
                  );
                  sessionStorage.setItem('withholdingtaxs-queryMonth-text', monthNames[adjustDate.getMonth()] + ' ' + year);

                  setFrom(getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  setTo(getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  sessionStorage.setItem('withholdingtaxs-from', getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  sessionStorage.setItem('withholdingtaxs-to', getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  setLoadDataComplete(false);
                  setItems([]);
                  setGetNewDocuments(true);
                  setPreviousSearch('');
                  setSearch('');

                  setTotalDocuments(0);
                  setTotalVat(0);
                  setTotalGrand(0);

                  setCurrentPage(1);

                  resetSort();
                }}
                isMonthPickerVisible={true}
                highlightSelectedMonth={true}
                isDayPickerVisible={false}
                dateRangeType={DateRangeType.Day}
                autoNavigateOnSelection={false}
                showGoToToday={false}
                value={date}
                strings={DayPickerStrings}
                showSixWeeksByDefault={true}
              />
              )}
            </div>
          );
        },
      },
    },
    {
      key: pageSize,
      text: pageSize + ' ' + t('documents.documentPerPage'),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'NumberedList' },
      disabled: !loadDataComplete || isProcessing,
      subMenuProps: {
        items: [
          {
            key: 10,
            text: '10 ' + t('documents.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('10 ' + t('documents.documentPerPage'));
              setPageSize(10);
              sessionStorage.setItem('withholdingtaxs-pageSize', 10);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            },
          },
          {
            key: 20,
            text: '20 ' + t('documents.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('20 ' + t('documents.documentPerPage'));
              setPageSize(20);
              sessionStorage.setItem('withholdingtaxs-pageSize', 20);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            },
          },
          {
            key: 30,
            text: '30 ' + t('documents.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('30 ' + t('documents.documentPerPage'));
              setPageSize(30);
              sessionStorage.setItem('withholdingtaxs-pageSize', 30);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            },
          },
          {
            key: 40,
            text: '40 ' + t('documents.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('40 ' + t('documents.documentPerPage'));
              setPageSize(40);
              sessionStorage.setItem('withholdingtaxs-pageSize', 40);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            },
          },
          {
            key: 50,
            text: '50 ' + t('documents.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('50 ' + t('documents.documentPerPage'));
              setPageSize(50);
              sessionStorage.setItem('withholdingtaxs-pageSize', 50);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            },
          },
        ],
      },
    },
    {
      key: currentPage,
      text: t('documents.page') + ' ' + currentPage,
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete || isProcessing,
      subMenuProps: {
        items: pageDropdown,
      },
    },

    {
      key: 'refresh',
      text: t('documents.refresh'),
      iconProps: { iconName: 'Refresh' },
      disabled: !loadDataComplete || isProcessing,
      onClick: () => {
        console.log('Refresh');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setPreviousSearch('');
        setSearch('');

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        setCurrentPage(1);

        resetSort();
      },
    },
    {
      key: 'batch-send-email',
      text: t('documents.sendEmail'),
      iconProps: { iconName: 'MailForward' },
      disabled: !loadDataComplete || isProcessing || !selectedItems.length,
      onClick: async () => {
        console.log('Send batch email.');
        console.log('Selected items:', selectedItems);

        if (isEmailSetting) {
          if (selectedItems.length > 0) {
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: true,
              header: t('documents.sendEmail'),
              message: `${t('documents.confirmSendEmail')} ${selectedItems.length} ${t('documents.document')}?`,
              onConfirm: async () => {
                setDialogConfirm((prev) => ({
                  ...prev,
                  isClick: false,
                }));
                setIsProcessing(true);

                await batchSendEmail();
              },
            }));
          }
        } else {
          history.push({
            pathname: '/settings/emails',
            state: {},
          });
        }
      },
    },
    {
      key: 'upload',
      text: t('WithholdingTaxDocuments.upload'),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Upload' },
      disabled: !loadDataComplete || isProcessing,
      onClick: () => {
        history.push({
          pathname: '/withholding-taxs/excel/upload',
          state: {},
        });
      },
    },
    {
      key: 'download',
      text: t('documents.download'),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Download' },
      disabled: !loadDataComplete || isProcessing,
      onClick: () => {
        console.log('Download PDF');
        downloadZip('pdf');
      },
    },
  ];

  const toThaiDateString = (isoDateTime) => {
    let date = '';
    date = new Date(isoDateTime);
    let year = date.getFullYear() + 543;
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let numOfDay = date.getDate().toString().padStart(2, '0');

    let hour = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');
    let second = date.getSeconds().toString().padStart(2, '0');

    return `${numOfDay}/${month}/${year} ${hour}:${minutes}:${second} น.`;
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };
/* 
  const UploadPDF = (fileName, file, email) => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        let formData = new FormData();

        formData.append('file', file);
        formData.append('fileName', fileName);
        formData.append('to', email);
        formData.append('message', '');

        await axios
          .post(API_URL + '/sents', formData, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'Content-Type': 'multipart/form-data',
            },
            timeout: 60000,
          })
          .then(
            (response) => {
              console.log(true, response);
            },
            (error) => {
              console.log('Error sending document response: ', error);
            }
          );
      })
      .catch((error) => {
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };
 */
  const sendEmail = (fileName, fileId, email, copyEmail) => {
    if (email !== '' && email !== null && email !== undefined) {
      if ((isClickCopyEmail && copyEmail !== '' && copyEmail !== null && copyEmail !== undefined) || !isClickCopyEmail) {
        setIsClickEmail(false);

        setIsClickCopyEmail(false);

        instance
          .acquireTokenSilent({ ...silentRequest, account: account })
          .then((tokenResponse) => {
            axios
              .post(
                API_URL + '/emails/send',
                {
                  fileId: fileId,
                  email: email,
                  copyEmail: copyEmail,
                },
                {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    'cid': companyId,
                    'Content-Type': 'application/json',
                  },
                }
              )
              .then(
                (response) => {
                  console.log(response);
                  setIsClickEmail(false);
                  setShowEmailSuccess(true);
                  window.scrollTo(0, 0);
                },
                (error) => {
                  console.log(error);
                  setIsClickEmail(false);
                  setShowEmailError(true);
                  window.scrollTo(0, 0);
                }
              );
          })
          .catch((error) => {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);
          });
/* 
        let file = '';

        instance
          .acquireTokenSilent({ ...silentRequest, account: account })
          .then((tokenResponse) => {
            axios
              .get(API_URL + '/etax/files/' + fileId, {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
                responseType: 'blob',
              })
              .then(
                (response) => {
                  console.log(response);

                  file = response.data;

                  let allEmails = email.split(';');

                  allEmails.forEach((email) => {
                    console.log(email, true);

                    instance
                      .acquireTokenSilent({
                        ...silentRequest,
                        account: account,
                      })
                      .then(async (tokenResponse) => {
                        await axios
                          .get(API_URL + '/sents/check?email=' + email, {
                            headers: {
                              Authorization: 'Bearer ' + tokenResponse.accessToken,
                            },
                          })
                          .then(
                            (response) => {
                              console.log(response);
                              if (response.data.isExist) {
                                UploadPDF(fileName, file, email);
                              }
                            },
                            (error) => {
                              console.log(error);
                            }
                          );
                      });
                  });
                },
                (error) => {
                  console.log(error);
                }
              );
          })
          .catch((error) => {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);
          });
           */
      }
    }
  };

  const getTotalProcessingDocuments = () => {
    console.log('Get total processing document...');
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/jobs/processing/total', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId,
            },
          })
          .then(
            (response) => {
              console.log('Total processing jobs response: ', response);

              if (response.data[0]) {
                console.log('Total processing jobs: ', response.data[0]);

                setTotalProcessingDocuments(response.data[0] * 1);
              } else {
                setTotalProcessingDocuments(0);
              }
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  async function getPNGorZip() {
    setTimeout(async () => {
      try {
        const zip = new JSZip();

        console.log('download...');
        const pdfElements = printRef.current.getElementsByClassName('react-pdf__Page__canvas');

        if (pdfElements) {
          if (pdfElements.length > 1) {
            const promises = [];

            async function getCanvasBlobToPNG(elements, index) {
              const canvas = await html2canvas(elements[index]);

              return new Promise(function (resolve, reject) {
                canvas.toBlob(function (blob) {
                  resolve(zip.file(`${index + 1}.png`, blob));
                });
              });
            }

            for (let i = 0; i < pdfElements.length; i++) {
              promises.push(getCanvasBlobToPNG(pdfElements, i));
            }

            Promise.all(promises).then(async () => {
              const blob = await zip.generateAsync({
                type: 'blob',
              });

              const link = document.createElement('a');
              link.setAttribute('href', window.URL.createObjectURL(blob));
              link.setAttribute('download', fileName + '.zip');
              link.style.display = 'none';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setUrl('');
            });
          } else {
            const canvas = await html2canvas(pdfElements[0]);

            const data = canvas.toDataURL('image/png');

            const link = document.createElement('a');

            if (typeof link.download === 'string') {
              link.href = data;
              link.download = fileName + '.png';

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setUrl('');
            } else {
              window.open(data);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }, 1000);
  }

  const batchSendEmail = async () => {
    console.log('Call batchSendEmail function...');

    let _items = selection.getItems();

    for (let i = 0; i < selectedItems.length; i++) {
      console.log('batchSendEmail: ', selectedItems[i]);

      for (let j = 0; j < _items.length; j++) {
        if (_items[j].key === selectedItems[i].key) {
          console.log('item key: ', _items[j].key);

          if (_items[j].customerEmail) {
            _items[j].emailTime = 'processing';
            console.log('items: ', _items);
            setItems([..._items]);

            console.log('pdfFileName: ', _items[j].pdfFileName);
            console.log('key: ', _items[j].key);
            console.log('customerEmail: ', _items[j].customerEmail);

            await instance
              .acquireTokenSilent({ ...silentRequest, account: account })
              .then(async (tokenResponse) => {
                await axios
                  .post(
                    API_URL + '/emails/send',
                    {
                      fileId: _items[j].key,
                      email: _items[j].customerEmail,
                      copyEmail: '',
                    },
                    {
                      headers: {
                        Authorization: 'Bearer ' + tokenResponse.accessToken,
                        'cid': companyId,
                        'Content-Type': 'application/json',
                      },
                    }
                  )
                  .then(
                    (response) => {
                      console.log(response);

                      if (response?.status === 200) {
                        _items[j].emailTime = 'successful';
                      } else {
                        _items[j].emailTime = 'fail';
                      }

                      setItems([..._items]);
                    },
                    (error) => {
                      console.log(error);
                    }
                  );
              })
              .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
              });
/* 
            let file = '';

            instance
              .acquireTokenSilent({ ...silentRequest, account: account })
              .then((tokenResponse) => {
                axios
                  .get(API_URL + '/etax/files/' + _items[j].key, {
                    headers: {
                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                    },
                    responseType: 'blob',
                  })
                  .then(
                    (response) => {
                      console.log(response);

                      file = response.data;

                      let allEmails = _items[j].customerEmail.split(';');

                      allEmails.forEach((email) => {
                        console.log(email, true);

                        instance
                          .acquireTokenSilent({
                            ...silentRequest,
                            account: account,
                          })
                          .then(async (tokenResponse) => {
                            await axios
                              .get(API_URL + '/sents/check?email=' + email, {
                                headers: {
                                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                                },
                              })
                              .then(
                                (response) => {
                                  console.log(response);
                                  if (response.data.isExist) {
                                    UploadPDF(_items[j].pdfFileName, file, email);
                                  }
                                },
                                (error) => {
                                  console.log(error);
                                }
                              );
                          });
                      });
                    },
                    (error) => {
                      console.log(error);
                    }
                  );
              })
              .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
              });
               */
          } else {
            _items[j].emailTime = 'email not found';
          }

          console.log('item: ', _items[j]);
        }
      }
    }

    setIsProcessing(false);
  };

  useEffect(() => {
    console.log('userEffect Call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        console.log(tokenResponse);

        const documentsConnection = new signalR.HubConnectionBuilder()
          .withUrl('https://leceipt.azurewebsites.net/api/OnDocumentsChanged', {
            headers: {
                cid: companyId,
            //   oid: tokenResponse.idTokenClaims.oid,
            //   product: 'etax',
            //   fileExtension: 'pdf',
            },
          })
          .configureLogging(signalR.LogLevel.Information)
          .build();

        documentsConnection.on('documentUpdated', (data) => {
          console.log('SignalR push documentUpdated!!');
          console.log(data);

          if (data && data.createdTime) {
            let diffMilliSeconds = new Date() - new Date(data.createdTime);

            console.log('diffMilliSeconds:', diffMilliSeconds);

            // refresh only document create or update in last 15 sec.

            if (diffMilliSeconds < 15000) {
              setDocumentType({ key: 'WITHHOLDING-TAX', text: 'หนังสือรับรองการหักภาษี ณ ที่จ่าย' });
              setDateType({ key: 'created', text: t('documents.createdDate') });
              setQueryMonth({
                key: getMonth(0).year + '' + getMonth(0).key,
                text: t('documents.thisMonth'),
              });
              setFrom(getFrom(getMonth(0).month, getMonth(0).year));
              setTo(getTo(getMonth(0).month, getMonth(0).year));
              setDate(new Date())
              setPageSize(10);
              setCurrentPage(1);

              sessionStorage.setItem('withholdingtaxs-documentType-key', 'WITHHOLDING-TAX');
              sessionStorage.setItem('withholdingtaxs-documentType-text', 'หนังสือรับรองการหักภาษี ณ ที่จ่าย');
              sessionStorage.setItem('withholdingtaxs-dateType-key', 'created');
              sessionStorage.setItem('withholdingtaxs-dateType-text', t('documents.createdDate'));
              sessionStorage.setItem('queryMonth-key', getMonth(0).year + '' + getMonth(0).key);
              sessionStorage.setItem('withholdingtaxs-queryMonth-text', getMonth(0).text);
              sessionStorage.setItem('withholdingtaxs-from', getFrom(getMonth(0).month, getMonth(0).year));
              sessionStorage.setItem('withholdingtaxs-to', getTo(getMonth(0).month, getMonth(0).year));
              sessionStorage.setItem('withholdingtaxs-date', new Date());
              sessionStorage.setItem('withholdingtaxs-pageSize', 10);
              sessionStorage.setItem('withholdingtaxs-currentPage', 1);

              setPrimarySort('createdTime');
              setIsSortedDescendingCreatedTime(true);
              setIsSortedDescendingDocumentDate(false);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingCustomerPhone(false);
              setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);
              setIsSortedDescendingName(false);
              setIsSortedDescendingEmailReceiver(false);
              setIsSortedDescendingEmailTime(false);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            }
          }
        });

        async function documentsStart() {
          try {
            await documentsConnection.start();
            console.log('SignalR Documents Connected.');
          } catch (err) {
            console.log(err);
            setTimeout(documentsStart, 5000);
          }
        }

        documentsConnection.onclose(documentsStart);

        // Start the connection.
        documentsStart();

        const jobsConnection = new signalR.HubConnectionBuilder()
          .withUrl('https://leceipt.azurewebsites.net/api/OnJobsChanged', {
            headers: {
              cid: companyId,
            //   oid: tokenResponse.idTokenClaims.oid,
            //   product: 'etax',
            //   fileExtension: 'pdf',
            },
          })
          .configureLogging(signalR.LogLevel.Information)
          .build();

        jobsConnection.on('jobUpdated', (data) => {
          console.log('SignalR push jobUpdated!!');
          console.log(data);

          getTotalProcessingDocuments();
        });

        async function jobsStart() {
          try {
            await jobsConnection.start();
            console.log('SignalR Jobs Connected.');
          } catch (err) {
            console.log(err);
            setTimeout(jobsStart, 5000);
          }
        }

        jobsConnection.onclose(jobsStart);
        jobsStart();

        axios
          .get(API_URL + '/sources/emails', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId,
            },
          })
          .then(
            (response) => {
              console.log('Email sources: ', response);

              if (response.data.id) {
                if (!response.data.disabled) {
                  setIsEmailSetting(true);
                }
              } else {
                setIsEmailSetting(false);
              }
            },
            (error) => {
              console.log(error);

              setIsEmailSetting(false);
            }
          );
/* 
          axios
          .get(API_URL + '/accounts', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (responseAcc) => {

              if (responseAcc.data) {

                if (responseAcc.data.settings?.enableSms) {
                    setEnableSms(responseAcc.data.settings.enableSms);
                }
              }

            },
            (error) => {
              console.log(error);
            }
          );
           */

      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    getTotalProcessingDocuments();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('userEffect getNewDocuments Call!');

    if (getNewDocuments) {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          // Do something with the tokenResponse
          console.log('Token response: ', tokenResponse);

          const [pdf_response] = await Promise.all([
            axios
              .get(API_URL + '/etax/documents/' + documentType.key + '/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId,
                },
              })
              .catch((error) => console.log(error)),
          ]);

          console.log('response pdf', pdf_response);

          let pdf_xml = pdf_response.data;

          pdf_xml.forEach((doc) => {
            if (doc.type === 'CREDIT-NOTE') {
              doc.data.amountTotal = -Math.abs(doc.data.amountTotal);
              doc.data.grandTotal = -Math.abs(doc.data.grandTotal);
              doc.data.vatTotal = -Math.abs(doc.data.vatTotal);
            }
          });

          console.log('Docs number: ', pdf_xml.length);

          //Page dropdown
          let _pageDropdown = [];

          for (let i = 1; i <= Math.ceil(pdf_xml.length / pageSize); i++) {
            _pageDropdown.push({
              key: i,
              text: 'หน้า ' + i,
              iconProps: { iconName: 'Page' },
              onClick: () => {
                console.log('Selected page: ' + i);
                setCurrentPage(i);
              },
            });
          }

          setPageDropdown(_pageDropdown);

          if (currentPage > Math.ceil(pdf_xml.length / pageSize)) {
            setCurrentPage(1);
          }

          setTotalDocuments(pdf_xml.length);

          //Sorting
          if (pdf_xml && pdf_xml.length > 0) {
            if (primarySort === 'createdTime') {
              if (isSortedDescendingCreatedTime) {
                pdf_xml.sort(function (a, b) {
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return new Date(b.createdTime) - new Date(a.createdTime);
                });
              } else {
                pdf_xml.sort(function (a, b) {
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return new Date(a.createdTime) - new Date(b.createdTime);
                });
              }
            } else if (primarySort === 'number') {
              if (isSortedDescendingNumber) {
                pdf_xml.sort((a, b) => {
                  return (
                    /[A-Za-z]/.test(b.data.number) - /[A-Za-z]/.test(a.data.number) ||
                    (b.data.number.toUpperCase() < a.data.number.toUpperCase()
                      ? -1
                      : b.data.number.toUpperCase() > a.data.number.toUpperCase()
                      ? 1
                      : 0)
                  );
                });
              } else {
                pdf_xml.sort((a, b) => {
                  return (
                    /[A-Za-z]/.test(a.data.number) - /[A-Za-z]/.test(b.data.number) ||
                    (a.data.number.toUpperCase() < b.data.number.toUpperCase()
                      ? -1
                      : a.data.number.toUpperCase() > b.data.number.toUpperCase()
                      ? 1
                      : 0)
                  );
                });
              }
            } else if (primarySort === 'documentDate') {
              if (isSortedDescendingDocumentDate) {
                pdf_xml.sort(function (a, b) {
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return new Date(b.data.date) - new Date(a.data.date);
                });
              } else {
                pdf_xml.sort(function (a, b) {
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return new Date(a.data.date) - new Date(b.data.date);
                });
              }
            } else if (primarySort === 'customerName') {
              if (isSortedDescendingCustomerName) {
                pdf_xml.sort((a, b) => {
                  if (b.data.customer.name < a.data.customer.name) {
                    return -1;
                  }
                  if (b.data.customer.name > a.data.customer.name) {
                    return 1;
                  }
                  return 0;
                });
              } else {
                pdf_xml.sort((a, b) => {
                  if (a.data.customer.name < b.data.customer.name) {
                    return -1;
                  }
                  if (a.data.customer.name > b.data.customer.name) {
                    return 1;
                  }
                  return 0;
                });
              }
            } else if (primarySort === 'customerPhone') {
              if (isSortedDescendingCustomerPhone) {
                pdf_xml.sort((a, b) => {
                  if (b.data.customer.phone < a.data.customer.phone) {
                    return -1;
                  }
                  if (b.data.customer.phone > a.data.customer.phone) {
                    return 1;
                  }
                  return 0;
                });
              } else {
                pdf_xml.sort((a, b) => {
                  if (a.data.customer.phone < b.data.customer.phone) {
                    return -1;
                  }
                  if (a.data.customer.phone > b.data.customer.phone) {
                    return 1;
                  }
                  return 0;
                });
              }
            } else if (primarySort === 'vatTotal') {
              if (isSortedDescendingVatTotal) {
                pdf_xml.sort(function (a, b) {
                  return b.data.vatTotal - a.data.vatTotal;
                });
              } else {
                pdf_xml.sort(function (a, b) {
                  return a.data.vatTotal - b.data.vatTotal;
                });
              }
            } else if (primarySort === 'grandTotal') {
              if (isSortedDescendingGrandTotal) {
                pdf_xml.sort(function (a, b) {
                  return b.data.grandTotal - a.data.grandTotal;
                });
              } else {
                pdf_xml.sort(function (a, b) {
                  return a.data.grandTotal - b.data.grandTotal;
                });
              }
            } else if (primarySort === 'name') {
              if (isSortedDescendingName) {
                pdf_xml.sort((a, b) => {
                  if (b.data.name < a.data.name) {
                    return -1;
                  }
                  if (b.data.name > a.data.name) {
                    return 1;
                  }
                  return 0;
                });
              } else {
                pdf_xml.sort((a, b) => {
                  if (a.data.name < b.data.name) {
                    return -1;
                  }
                  if (a.data.name > b.data.name) {
                    return 1;
                  }
                  return 0;
                });
              }
            } else if (primarySort === 'emailReceiver') {
              if (isSortedDescendingEmailReceiver) {
                pdf_xml.sort((a, b) => {
                  if (b.data.customer.email < a.data.customer.email) {
                    return -1;
                  }
                  if (b.data.customer.email > a.data.customer.email) {
                    return 1;
                  }
                  return 0;
                });
              } else {
                pdf_xml.sort((a, b) => {
                  if (a.data.customer.email < b.data.customer.email) {
                    return -1;
                  }
                  if (a.data.customer.email > b.data.customer.email) {
                    return 1;
                  }
                  return 0;
                });
              }
            } else if (primarySort === 'emailTime') {
              if (isSortedDescendingEmailTime) {
                pdf_xml.sort(function (a, b) {
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return new Date(b.deliveries.email.time) - new Date(a.deliveries.email.time);
                });
              } else {
                pdf_xml.sort(function (a, b) {
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return new Date(a.deliveries.email.time) - new Date(b.deliveries.email.time);
                });
              }
            }
          }

          //set page
          setPageData(paginate(pdf_xml.length, currentPage, pageSize, 10));

          console.log('pdf_documents', pdf_xml);

          setDocuments(pdf_xml);

          const _docs = [];

          if (pdf_xml.length > 0) {
            for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, pdf_xml.length); i++) {
              let fileSize = formatBytes(pdf_xml[i].file.size);
              let createdTime = toThaiDateString(pdf_xml[i].createdTime);

              let _companyEmail = '';

              let _customerEmail = '';
              let _customerPhone = '';
              let _amountTotal = 0;
              let _vatTotal = 0;
              let _grandTotal = 0;

              if (
                pdf_xml[i].data.company &&
                pdf_xml[i].data.company.email &&
                pdf_xml[i].data.company.email !== null &&
                pdf_xml[i].data.company.email !== undefined
              ) {
                _companyEmail = pdf_xml[i].data.company.email;
              }

              if (
                pdf_xml[i].data.customer &&
                pdf_xml[i].data.customer.email &&
                pdf_xml[i].data.customer.email !== null &&
                pdf_xml[i].data.customer.email !== undefined
              ) {
                _customerEmail = pdf_xml[i].data.customer.email;
              }

              if (
                pdf_xml[i].data.customer &&
                pdf_xml[i].data.customer.phone &&
                pdf_xml[i].data.customer.phone !== null &&
                pdf_xml[i].data.customer.phone !== undefined
              ) {
                _customerPhone = pdf_xml[i].data.customer.phone;
              }

              let _isPdfXml = false;

              if (pdf_xml[i].xmlFile && pdf_xml[i].xmlFile.size) {
                _isPdfXml = true;
              }

              _docs.push({
                key: pdf_xml[i].id,
                pdfFileName: pdf_xml[i].file.displayName,
                name: pdf_xml[i].data.name,
                fileExtension: pdf_xml[i].file.extension,
                isPdfXml: _isPdfXml,
                createdBy: pdf_xml[i].createdBy,
                createdTime: createdTime,
                createdTimeValue: pdf_xml[i].createdTime,
                fileSize: fileSize,
                fileSizeRaw: pdf_xml[i].file.size,
                type: pdf_xml[i].type,
                signature: pdf_xml[i].signatures[0].certificateName,
                product: pdf_xml[i].product,
                number: pdf_xml[i].data.number,
                companyEmail: _companyEmail,
                customerName: pdf_xml[i].data.customer.name,
                customerEmail: _customerEmail,
                customerPhone: _customerPhone,
                amountTotal: _amountTotal,
                vatTotal: _vatTotal,
                grandTotal: _grandTotal,
                data: pdf_xml[i].data,
                documentDate: pdf_xml[i].data.dateBE,
                xmlData: pdf_xml[i].xmlFile,
                rdStatus: pdf_xml[i].deliveries.rd.status,
                digitalSign: pdf_xml[i].digitalSign,
                reIssue: pdf_xml[i].data.reIssue,
                emailReceiver: pdf_xml[i]?.deliveries?.email?.receiver ? pdf_xml[i].deliveries.email.receiver : pdf_xml[i].data.customer.email,
                emailTime: pdf_xml[i]?.deliveries?.email?.time ? toThaiDateString(pdf_xml[i].deliveries.email.time) : '',
              });
            }

            let _totalVat = 0;
            let _totalGrand = 0;

            for (let i = 0; i < pdf_xml.length; i++) {
              _totalVat = _totalVat + Number(String(pdf_xml[i].data.vatTotal).replaceAll(',', ''));
              _totalGrand = _totalGrand + Number(String(pdf_xml[i].data.amountTotal).replaceAll(',', ''));
            }

            setTotalVat(_totalVat);
            setTotalGrand(_totalGrand);
          }

          console.log('Docs data', _docs);
          setItems(_docs);

          setLoadDataComplete(true);
          setGetNewDocuments(false);
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments]);

  useEffect(() => {
    if (previousSearch) {
      if (filterDocuments.length > 0) {
        const _docs = [];

        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, filterDocuments.length); i++) {
          let fileSize = formatBytes(filterDocuments[i].file.size);
          let createdTime = toThaiDateString(filterDocuments[i].createdTime);

          let _customerEmail = '';
          let _customerPhone = '';
          let _amountTotal = 0;
          let _vatTotal = 0;
          let _grandTotal = 0;

          if (
            filterDocuments[i].data.customer &&
            filterDocuments[i].data.customer.email &&
            filterDocuments[i].data.customer.email !== null &&
            filterDocuments[i].data.customer.email !== undefined
          ) {
            _customerEmail = filterDocuments[i].data.customer.email;
          }

          if (
            filterDocuments[i].data.customer &&
            filterDocuments[i].data.customer.phone &&
            filterDocuments[i].data.customer.phone !== null &&
            filterDocuments[i].data.customer.phone !== undefined
          ) {
            _customerPhone = filterDocuments[i].data.customer.phone;
          }

          let _isPdfXml = false;

          if (filterDocuments[i].xmlFile && filterDocuments[i].xmlFile.size) {
            _isPdfXml = true;
          }

          _docs.push({
            key: filterDocuments[i].id,
            pdfFileName: filterDocuments[i].file.displayName,
            name: filterDocuments[i].data.name,
            fileExtension: filterDocuments[i].file.extension,
            isPdfXml: _isPdfXml,
            createdBy: filterDocuments[i].createdBy,
            createdTime: createdTime,
            createdTimeValue: filterDocuments[i].createdTime,
            fileSize: fileSize,
            fileSizeRaw: filterDocuments[i].file.size,
            type: filterDocuments[i].type,
            signature: filterDocuments[i].signatures[0].certificateName,
            product: filterDocuments[i].product,
            number: filterDocuments[i].data.number,
            customerName: filterDocuments[i].data.customer.name,
            customerEmail: _customerEmail,
            customerPhone: _customerPhone,
            amountTotal: _amountTotal,
            vatTotal: _vatTotal,
            grandTotal: _grandTotal,
            data: filterDocuments[i].data,
            documentDate: filterDocuments[i].data.dateBE,
            xmlData: filterDocuments[i].xmlFile,
            rdStatus: filterDocuments[i].deliveries.rd.status,
            digitalSign: filterDocuments[i].digitalSign,
            reIssue: filterDocuments[i].data.reIssue,
            emailReceiver: filterDocuments[i]?.deliveries?.email?.receiver
              ? filterDocuments[i].deliveries.email.receiver
              : filterDocuments[i].data.customer.email,
            emailTime: filterDocuments[i].deliveries.email.time ? toThaiDateString(filterDocuments[i].deliveries.email.time) : '',
          });
        }

        setItems(_docs);
      }
    } else {
      if (documents.length > 0) {
        const _docs = [];

        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
          let fileSize = formatBytes(documents[i].file.size);
          let createdTime = toThaiDateString(documents[i].createdTime);

          let _customerEmail = '';
          let _customerPhone = '';
          let _amountTotal = 0;
          let _vatTotal = 0;
          let _grandTotal = 0;

          if (
            documents[i].data.customer &&
            documents[i].data.customer.email &&
            documents[i].data.customer.email !== null &&
            documents[i].data.customer.email !== undefined
          ) {
            _customerEmail = documents[i].data.customer.email;
          }

          if (
            documents[i].data.customer &&
            documents[i].data.customer.phone &&
            documents[i].data.customer.phone !== null &&
            documents[i].data.customer.phone !== undefined
          ) {
            _customerPhone = documents[i].data.customer.phone;
          }

          if (documents[i].type === 'WITHHOLDING-TAX') {
            _amountTotal = '-';
            _vatTotal = '-';
            _grandTotal = '-';
          } else {
            if (documents[i].data.amountTotal) {
              _amountTotal = documents[i].data.amountTotal;
            }

            if (documents[i].data.vatTotal) {
              _vatTotal = documents[i].data.vatTotal;
            }

            if (documents[i].data.grandTotal) {
              _grandTotal = documents[i].data.grandTotal;
            }
          }

          let _isPdfXml = false;

          if (documents[i].xmlFile && documents[i].xmlFile.size) {
            _isPdfXml = true;
          }

          _docs.push({
            key: documents[i].id,
            pdfFileName: documents[i].file.displayName,
            name: documents[i].data.name,
            fileExtension: documents[i].file.extension,
            isPdfXml: _isPdfXml,
            createdBy: documents[i].createdBy,
            createdTime: createdTime,
            createdTimeValue: documents[i].createdTime,
            fileSize: fileSize,
            fileSizeRaw: documents[i].file.size,
            type: documents[i].type,
            signature: documents[i].signatures[0].certificateName,
            product: documents[i].product,
            number: documents[i].data.number,
            customerName: documents[i].data.customer.name,
            customerEmail: _customerEmail,
            customerPhone: _customerPhone,
            amountTotal: _amountTotal,
            vatTotal: _vatTotal,
            grandTotal: _grandTotal,
            data: documents[i].data,
            documentDate: documents[i].data.dateBE,
            xmlData: documents[i].xmlFile,
            rdStatus: documents[i].deliveries.rd.status,
            digitalSign: documents[i].digitalSign,
            reIssue: documents[i].data.reIssue,
            emailReceiver: documents[i]?.deliveries?.email?.receiver ? documents[i].deliveries.email.receiver : documents[i].data.customer.email,
            emailTime: documents[i]?.deliveries?.email?.time ? toThaiDateString(documents[i].deliveries.email.time) : '',
          });
        }
        console.log('Docs data', _docs);

        setItems(_docs);
      }
      sessionStorage.setItem('withholdingtaxs-currentPage', currentPage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const download = (item) => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/files/' + item.key, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId,
            },
            responseType: 'blob',
          })
          .then(
            (response) => {
              console.log(response.headers);

              const time = formatDateTime(item.createdTimeValue);

              let fileName = time + '-' + item.type + '-' + item.number.replace(/\//g, '') + '.' + item.fileExtension;

              console.log('file name: ', fileName);

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName); //or any other extension
              document.body.appendChild(link);
              link.click();
              link.remove();
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const downloadPNG = async (item) => {
    console.log('download PNG');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/files/' + item.key, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId,
            },
            responseType: 'blob',
          })
          .then(
            (response) => {
              console.log(response.headers);

              const time = formatDateTime(item.createdTimeValue);

              let fileName = time + '-' + item.type + '-' + item.number.replace(/\//g, '');

              setFileName(fileName);

              console.log('file name: ', fileName);

              const url = window.URL.createObjectURL(new Blob([response.data]));
              setUrl(url);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  useEffect(() => {
    if (url) {
      getPNGorZip();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const deleteFile = (item) => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .delete(API_URL + '/etax/documents/' + item.key, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId,
            },
          })
          .then(
            (response) => {
              console.log(response);
              setLoadDataComplete(false);
              setGetNewDocuments(true);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        // Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const openFile = (item) => {
    if (item.fileExtension === 'pdf') {
      openFile_pdf(item);
    } else if (item.fileExtension === 'xml') {
      openFile_xml(item);
    }
  };

  const openFile_pdf = (item) => {
    history.push({
      pathname: '/etax/documents/pdf/view',
      state: {
        item: item,
        backUrl: '/withholding-taxs/all',
      },
    });
  };

  const openFile_xml = (item) => {
    history.push({
      pathname: '/etax/documents/xml/view',
      state: {
        item: item,
      },
    });
  };

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  const downloadZip = (fileExt) => {
    if (documents.length > 0 && items.length > 0) {
      let _firstItemDate = new Date(documents[(currentPage - 1) * pageSize].createdTime);
      let _lastItemDate = new Date(documents[Math.min(currentPage * pageSize - 1, documents.length - 1)].createdTime);

      let _to = documents[(currentPage - 1) * pageSize].createdTime;
      let _from = documents[Math.min(currentPage * pageSize - 1, documents.length - 1)].createdTime;

      if (_firstItemDate < _lastItemDate) {
        _from = documents[(currentPage - 1) * pageSize].createdTime;
        _to = documents[Math.min(currentPage * pageSize - 1, documents.length - 1)].createdTime;
      }
      console.log('download from: ', documents[Math.min(currentPage * pageSize - 1, documents.length - 1)].createdTime);
      console.log('download to: ', documents[(currentPage - 1) * pageSize].createdTime);

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .get(API_URL + '/etax/files/' + documentType.key + '/' + fileExt + '?from=' + _from + '&to=' + _to, {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId,
              },
              responseType: 'blob',
            })
            .then(
              (response) => {
                console.log(response.headers);

                let zipFileName = from.substr(0, 4) + from.substr(5, 2) + '-' + documentType.key + '-' + fileExt.toUpperCase() + '-Leceipt.zip';

                console.log('Zip file name: ', zipFileName);

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', zipFileName); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }
  };

  const reCreateWithholdingTax = async (item) => {
    console.log(item);

    let documentPath = '/documents/withholding-taxs';

    history.push({
      pathname: documentPath,
      state: {
        command: 'reCreate',
        bookNumber: item.data.bookNumber,
        number: item.data.number,
        company: item.data.company,
        customer: item.data.customer,
        orderNumber: item.data.orderNumber,
        formType_1: item.data.formType_1,
        formType_2: item.data.formType_2,
        formType_3: item.data.formType_3,
        formType_4: item.data.formType_4,
        formType_5: item.data.formType_5,
        formType_6: item.data.formType_6,
        formType_7: item.data.formType_7,
        paymentType_1: item.data.paymentType_1,
        paymentType_2: item.data.paymentType_2,
        paymentType_3: item.data.paymentType_3,
        paymentType_4_A: item.data.paymentType_4_A,
        paymentType_4_B_1_1: item.data.paymentType_4_B_1_1,
        paymentType_4_B_1_2: item.data.paymentType_4_B_1_2,
        paymentType_4_B_1_3: item.data.paymentType_4_B_1_3,
        paymentType_4_B_1_4: item.data.paymentType_4_B_1_4,
        paymentType_4_B_2_1: item.data.paymentType_4_B_2_1,
        paymentType_4_B_2_2: item.data.paymentType_4_B_2_2,
        paymentType_4_B_2_3: item.data.paymentType_4_B_2_3,
        paymentType_4_B_2_4: item.data.paymentType_4_B_2_4,
        paymentType_4_B_2_5: item.data.paymentType_4_B_2_5,
        paymentType_5: item.data.paymentType_5,
        paymentType_6: item.data.paymentType_6,
        amountTotal: item.data.amountTotal,
        vatTotal: item.data.vatTotal,
        vatTotalText: item.data.vatTotalText,
        paymentAmount: item.data.paymentAmount,
        payment_type_1: item.data.payment_type_1,
        payment_type_2: item.data.payment_type_2,
        payment_type_3: item.data.payment_type_3,
        payment_type_4: item.data.payment_type_4,
        otherPayment: item.data.otherPayment,
        date: item.data.date,
        dateBE: item.data.dateBE,
        dateCE: item.data.dateCE,
        backUrl: '/etax/documents/all',
      },
    });
  };

  const columns = [
    {
      key: 'column1',
      name: t('documents.createdDate'),
      fieldName: 'createdTime',
      minWidth: 70,
      maxWidth: 125,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'createdTime' ? true : false,
      isSortedDescending: isSortedDescendingCreatedTime,
      isSortedAscending: !isSortedDescendingCreatedTime,
      onColumnClick: () => {
        console.log('Sort created time!');
        setPrimarySort('createdTime');
        setIsSortedDescendingCreatedTime(!isSortedDescendingCreatedTime);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setPreviousSearch('');
        setSearch('');

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        sessionStorage.setItem('withholdingtaxs-primarySort', 'createdTime');
        sessionStorage.setItem('withholdingtaxs-sort-createdTime', !isSortedDescendingCreatedTime === true ? 'true' : 'false');

        sessionStorage.setItem('withholdingtaxs-sort-number', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-documentDate', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-customerName', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-customerPhone', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-vatTotal', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-grandTotal', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-name', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-emailTime', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-emailReceiver', 'false');
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.createdTime}</span>;
      },
    },

    {
      key: 'column2',
      name: t('documents.number'),
      fieldName: 'number',
      minWidth: 80,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'number' ? true : false,
      isSortedDescending: isSortedDescendingNumber,
      isSortedAscending: !isSortedDescendingNumber,
      onColumnClick: () => {
        console.log('Sort document number!');
        setPrimarySort('number');
        setIsSortedDescendingNumber(!isSortedDescendingNumber);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setPreviousSearch('');
        setSearch('');

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        sessionStorage.setItem('withholdingtaxs-primarySort', 'number');
        sessionStorage.setItem('withholdingtaxs-sort-number', !isSortedDescendingNumber === true ? 'true' : 'false');

        sessionStorage.setItem('withholdingtaxs-sort-createdTime', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-documentDate', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-customerName', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-customerPhone', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-vatTotal', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-grandTotal', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-name', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-emailTime', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-emailReceiver', 'false');
      },
      data: 'string',
      onRender: (item) => {
        return (
          <ActionButton
            title={t('documents.download')}
            className={classNames.fileIconImg}
            onClick={() => {
              openFile(item);
            }}
          >
            {item.number}
          </ActionButton>
        );
      },
    },
    {
      key: 'column3',
      name: t('documents.documentDate'),
      fieldName: 'documentDate',
      minWidth: 80,
      maxWidth: 80,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'documentDate' ? true : false,
      isSortedDescending: isSortedDescendingDocumentDate,
      isSortedAscending: !isSortedDescendingDocumentDate,
      onColumnClick: () => {
        console.log('Sort documentDate!');
        setPrimarySort('documentDate');
        setIsSortedDescendingDocumentDate(!isSortedDescendingDocumentDate);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setPreviousSearch('');
        setSearch('');

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        sessionStorage.setItem('withholdingtaxs-primarySort', 'documentDate');
        sessionStorage.setItem('withholdingtaxs-sort-documentDate', !isSortedDescendingDocumentDate === true ? 'true' : 'false');

        sessionStorage.setItem('withholdingtaxs-sort-createdTime', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-number', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-customerName', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-customerPhone', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-vatTotal', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-grandTotal', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-name', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-emailTime', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-emailReceiver', 'false');
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.documentDate}</span>;
      },
    },
    {
      key: 'column4',
      name: t('documents.customerName'),
      fieldName: 'customerName',
      minWidth: 90,
      maxWidth: 220,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'customerName' ? true : false,
      isSortedDescending: isSortedDescendingCustomerName,
      isSortedAscending: !isSortedDescendingCustomerName,
      onColumnClick: () => {
        console.log('Sort customerName!');
        setPrimarySort('customerName');
        setIsSortedDescendingCustomerName(!isSortedDescendingCustomerName);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setPreviousSearch('');
        setSearch('');

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        sessionStorage.setItem('withholdingtaxs-primarySort', 'customerName');
        sessionStorage.setItem('withholdingtaxs-sort-customerName', !isSortedDescendingCustomerName === true ? 'true' : 'false');

        sessionStorage.setItem('withholdingtaxs-sort-createdTime', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-number', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-customerPhone', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-documentDate', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-vatTotal', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-grandTotal', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-name', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-emailTime', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-emailReceiver', 'false');
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerName}</span>;
      },
    },
    {
      key: 'column5',
      name: t('documents.email'),
      fieldName: 'emailReceiver',
      minWidth: 80,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'emailReceiver' ? true : false,
      isSortedDescending: isSortedDescendingEmailReceiver,
      isSortedAscending: !isSortedDescendingEmailReceiver,
      onColumnClick: () => {
        console.log('Sort emailReceiver!');
        setPrimarySort('emailReceiver');
        setIsSortedDescendingEmailReceiver(!isSortedDescendingEmailReceiver);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setPreviousSearch('');
        setSearch('');

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        sessionStorage.setItem('withholdingtaxs-primarySort', 'emailReceiver');
        sessionStorage.setItem('withholdingtaxs-sort-emailReceiver', !isSortedDescendingEmailReceiver === true ? 'true' : 'false');

        sessionStorage.setItem('withholdingtaxs-sort-createdTime', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-number', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-customerName', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-documentDate', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-vatTotal', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-grandTotal', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-name', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-emailTime', 'false');
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.emailReceiver}</span>;
      },
    },
    {
      key: 'column6',
      name: t('documents.emailDate'),
      fieldName: 'emailTime',
      minWidth: 90,
      maxWidth: 125,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'emailTime' ? true : false,
      isSortedDescending: isSortedDescendingEmailTime,
      isSortedAscending: !isSortedDescendingEmailTime,
      onColumnClick: () => {
        console.log('Sort emailTime!');
        setPrimarySort('emailTime');
        setIsSortedDescendingEmailTime(!isSortedDescendingEmailTime);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setPreviousSearch('');
        setSearch('');

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        sessionStorage.setItem('withholdingtaxs-primarySort', 'emailTime');
        sessionStorage.setItem('withholdingtaxs-sort-emailTime', !isSortedDescendingEmailTime === true ? 'true' : 'false');

        sessionStorage.setItem('withholdingtaxs-sort-number', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-documentDate', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-customerName', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-customerPhone', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-vatTotal', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-grandTotal', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-name', 'false');
        sessionStorage.setItem('withholdingtaxs-sort-emailReceiver', 'false');
      },
      data: 'string',
      onRender: (item) => {
        if (item.emailTime === 'processing') {
          return <Spinner label="กำลังส่งอีเมล..." labelPosition="right" style={{ marginLeft: '0px' }} />;
        } else if (item.emailTime === 'successful') {
          return <span style={{ color: 'green' }}>{t('documents.success')}</span>;
        } else if (item.emailTime === 'email not found') {
          return <span style={{ color: 'red' }}>{t('documents.emailNotFound')}</span>;
        } else if (item.emailTime === 'fail') {
          return <span style={{ color: 'red' }}>{t('documents.fail')}</span>;
        } else {
          return <span>{item.emailTime}</span>;
        }
      },
    },
    {
      key: 'column7',
      name: t('documents.file'),
      fieldName: 'file',
      minWidth: 100,
      maxWidth: 100,
      onColumnClick: '',
      onRender: (item) => (
        <Stack horizontal>
          <IconButton
            onClick={() => {
              openFile(item);
            }}
            iconProps={{
              ...getFileTypeIconProps({
                extension: item.fileExtension,
                size: 32,
                imageFileType: 'svg',
              }),
            }}
          />
          <IconButton
            onClick={() => {
              downloadPNG(item);
            }}
            iconProps={{
              ...getFileTypeIconProps({
                size: 32,
                imageFileType: 'svg',
              }),
            }}
          />
          {item.isPdfXml && (
            <IconButton
              onClick={() => openFile_xml(item)}
              iconProps={{
                ...getFileTypeIconProps({
                  extension: '.cpp',
                  size: 32,
                  imageFileType: 'svg',
                }),
              }}
            />
          )}
        </Stack>
      ),
    },
    {
      key: 'column8',
      name: '',
      fieldName: 'command',
      minWidth: 90,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        let options_WithholdingTax = [
          {
            key: 'open',
            text: t('documents.openDocument'),
            iconProps: { iconName: 'OpenFile' },
            onClick: () => {
              openFile_pdf(item);
            },
          },
          {
            key: 'download',
            text: t('documents.download'),
            iconProps: { iconName: 'DownloadDocument' },
            onClick: () => {
              download(item);
            },
          },
          {
            key: 'divider_1',
            itemType: ContextualMenuItemType.Divider,
          },
          {
            key: 'email',
            text: t('documents.email'),
            iconProps: { iconName: 'MailForward' },
            onClick: () => {              
              console.log('isEmailSetting:', isEmailSetting);

              if (isEmailSetting) {
                setIsClickEmail(true);
                setFileNameEmail(item.name);
                setFileDisplayName(item.pdfFileName);
                setCustomerEmail(item.customerEmail);
                setEmailList(item.customerEmail ? [item.customerEmail] : []);
                setCopyEmailList(item.companyEmail ? [item.companyEmail] : [])
                setCopyEmail(item.companyEmail);
                setFileIdEmail(item.key);
              } else {
                history.push({
                  pathname: '/settings/emails',
                  state: {},
                });
              }
            },
          },         
          {
            key: 'divider_2',
            itemType: ContextualMenuItemType.Divider,
          },
          {
            key: 'reCreate',
            text: t('documents.reCreateDocument'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateWithholdingTax(item);
            },
          },
          {
            key: 'divider_3',
            itemType: ContextualMenuItemType.Divider,
          },
          !item.rdStatus
            ? {
                key: 'delete',
                text: t('documents.delete'),
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                  let _itemType = item.name;
                  if (i18n.language === 'en') {
                    if (item.type === 'WITHHOLDING-TAX') _itemType = 'Withholding Tax';
                  }

                  setDialogConfirm((prev) => ({
                    ...prev,
                    isClick: true,
                    header: t('documents.deleteConfirmHeader'),
                    message: `${t('documents.deleteConfirm1')}${_itemType}${t('documents.deleteConfirm2')}${item.number}${t(
                      'documents.deleteConfirm3'
                    )}`,
                    onConfirm: () => {
                      setDialogConfirm((prev) => ({
                        ...prev,
                        isClick: false,
                      }));
                      deleteFile(item);
                    },
                  }));
                },
              }
            : {
                key: 'delete',
                text: t('documents.delete'),
                iconProps: { iconName: 'Delete' },
                disabled: true,
                onClick: () => {
                  let _itemType = item.name;
                  if (i18n.language === 'en') {
                    if (item.type === 'WITHHOLDING-TAX') _itemType = 'Withholding Tax';
                  }

                  setDialogConfirm((prev) => ({
                    ...prev,
                    isClick: true,
                    header: t('documents.deleteConfirmHeader'),
                    message: `${t('documents.deleteConfirm1')}${_itemType}${t('documents.deleteConfirm2')}${item.number}${t(
                      'documents.deleteConfirm3'
                    )}`,
                    onConfirm: () => {
                      setDialogConfirm((prev) => ({
                        ...prev,
                        isClick: false,
                      }));
                      deleteFile(item);
                    },
                  }));
                },
              },
        ];

        return (
          <span data-selection-disabled={true}>
            <DefaultButton
              text={t('documents.select')}
              menuProps={{
                items: options_WithholdingTax,

                shouldFocusOnMount: true,
                directionalHintFixed: true,
              }}
              // Optional callback to customize menu rendering
              menuAs={(props) => <ContextualMenu {...props} />}
            />
          </span>
        );
      },
    },
  ];

  const handleSearch = () => {
    let totalDocs = documents;

    let filterDocs = totalDocs.filter(
      (doc) =>
        doc.data.number.includes(search) ||
        (doc.data.customer.name && doc.data.customer.name.includes(search)) ||
        (doc.data.customer.taxId && doc.data.customer.taxId.includes(search)) ||
        String(doc.data.amountTotal).includes(search) ||
        String(doc.data.vatTotal).includes(search) ||
        String(doc.data.grandTotal).includes(search) ||
        (doc.type === 'CREDIT-NOTE' && String(-Math.abs(doc.data.refer.diffAmountTotal)).includes(search)) ||
        (doc.type === 'DEBIT-NOTE' && String(doc.data.refer.diffAmountTotal).includes(search))
    );

    setPageData(paginate(filterDocs.length, currentPage, pageSize, 10));
    setFilterDocuments(filterDocs);
    setCurrentPage(1);

    if (filterDocs.length > 0) {
      const _docs = [];

      if (filterDocs.length > 0) {
        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, filterDocs.length); i++) {
          let fileSize = formatBytes(filterDocs[i].file.size);
          let createdTime = toThaiDateString(filterDocs[i].createdTime);

          let _companyEmail = '';

          let _customerEmail = '';
          let _customerPhone = '';
          let _amountTotal = 0;
          let _vatTotal = 0;
          let _grandTotal = 0;

          if (
            filterDocs[i].data.company &&
            filterDocs[i].data.company.email &&
            filterDocs[i].data.company.email !== null &&
            filterDocs[i].data.company.email !== undefined
          ) {
            _companyEmail = filterDocs[i].data.company.email;
          }

          if (
            filterDocs[i].data.customer &&
            filterDocs[i].data.customer.email &&
            filterDocs[i].data.customer.email !== null &&
            filterDocs[i].data.customer.email !== undefined
          ) {
            _customerEmail = filterDocs[i].data.customer.email;
          }

          if (
            filterDocs[i].data.customer &&
            filterDocs[i].data.customer.phone &&
            filterDocs[i].data.customer.phone !== null &&
            filterDocs[i].data.customer.phone !== undefined
          ) {
            _customerPhone = filterDocs[i].data.customer.phone;
          }

          if (filterDocs[i].type === 'WITHHOLDING-TAX') {
            _amountTotal = '-';
            _vatTotal = '-';
            _grandTotal = '-';
          } else {
            if (filterDocs[i].data.amountTotal) {
              _amountTotal = filterDocs[i].data.amountTotal;
            }

            if (filterDocs[i].data.vatTotal) {
              _vatTotal = filterDocs[i].data.vatTotal;
            }

            if (filterDocs[i].data.grandTotal) {
              _grandTotal = filterDocs[i].data.grandTotal;
            }
          }

          let _isPdfXml = false;

          if (filterDocs[i].xmlFile && filterDocs[i].xmlFile.size) {
            _isPdfXml = true;
          }

          _docs.push({
            key: filterDocs[i].id,
            pdfFileName: filterDocs[i].file.displayName,
            name: filterDocs[i].data.name,
            fileExtension: filterDocs[i].file.extension,
            isPdfXml: _isPdfXml,
            createdBy: filterDocs[i].createdBy,
            createdTime: createdTime,
            createdTimeValue: filterDocs[i].createdTime,
            fileSize: fileSize,
            fileSizeRaw: filterDocs[i].file.size,
            type: filterDocs[i].type,
            signature: filterDocs[i].signatures[0].certificateName,
            product: filterDocs[i].product,
            number: filterDocs[i].data.number,
            companyEmail: _companyEmail,
            customerName: filterDocs[i].data.customer.name,
            customerEmail: _customerEmail,
            customerPhone: _customerPhone,
            amountTotal: _amountTotal,
            vatTotal: _vatTotal,
            grandTotal: _grandTotal,
            data: filterDocs[i].data,
            documentDate: filterDocs[i].data.dateBE,
            xmlData: filterDocs[i].xmlFile,
            rdStatus: filterDocs[i].deliveries.rd.status,
            digitalSign: filterDocs[i].digitalSign,
          });
        }
      }

      setItems(_docs);
    } else {
      setItems([]);
    }
  };

  const isCompactMode = false;

  function TextFieldEmailComponent(props) {
    return (
      <TextFieldEmail 
        selectedItems={[...emailList.map(_email => (
          { key: _email, text: _email }
        ))]}
        onItemsRemoved={(removeItems, removeItemIndex) => {
          const currentSelectedItemsCopy = [...emailList];
          currentSelectedItemsCopy.splice(removeItemIndex, 1);
          // this.setState({ currentSelectedItems: [...currentSelectedItemsCopy] });
          setEmailList([...currentSelectedItemsCopy])

          let multiEmail = '';

          currentSelectedItemsCopy.forEach((email, index) => {
            multiEmail = multiEmail + email;

            if (currentSelectedItemsCopy.length - 1 !== index) {
              multiEmail = multiEmail + ';';
            }
          });
          setCustomerEmail(multiEmail);
        }}
        onBlur={(e) => {
          if (checkEmail(e.target.value) && !emailList.includes(e.target.value)) {
              const currentSelectedItemsCopy = [...emailList, e.target.value];

              setEmailList(currentSelectedItemsCopy)

              let multiEmail = '';

              currentSelectedItemsCopy.forEach((email, index) => {
                multiEmail = multiEmail + email;

                if (currentSelectedItemsCopy.length - 1 !== index) {
                  multiEmail = multiEmail + ';';
                }
              });
              setCustomerEmail(multiEmail);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
              if (checkEmail(e.target.value) && !emailList.includes(e.target.value)) {
                  const currentSelectedItemsCopy = [...emailList, e.target.value];

                  setEmailList(currentSelectedItemsCopy)

                  let multiEmail = '';

                  currentSelectedItemsCopy.forEach((email, index) => {
                    multiEmail = multiEmail + email;

                    if (currentSelectedItemsCopy.length - 1 !== index) {
                      multiEmail = multiEmail + ';';
                    }
                  });
                  setCustomerEmail(multiEmail);

              }
          }
        }}
      />
    );
  }

  function TextFieldCopyEmailComponent(props) {
    return (
      <TextFieldEmail 
        selectedItems={[...copyEmailList.map(_email => (
          { key: _email, text: _email }
        ))]}
        onItemsRemoved={(removeItems, removeItemIndex) => {
          const currentSelectedItemsCopy = [...copyEmailList];
          currentSelectedItemsCopy.splice(removeItemIndex, 1);
          // this.setState({ currentSelectedItems: [...currentSelectedItemsCopy] });
          setCopyEmailList([...currentSelectedItemsCopy])

          let multiEmail = '';

          currentSelectedItemsCopy.forEach((email, index) => {
            multiEmail = multiEmail + email;

            if (currentSelectedItemsCopy.length - 1 !== index) {
              multiEmail = multiEmail + ';';
            }
          });
          setCopyEmail(multiEmail);
        }}
        onBlur={(e) => {
          if (checkEmail(e.target.value) && !copyEmailList.includes(e.target.value)) {
              const currentSelectedItemsCopy = [...copyEmailList, e.target.value];

              setCopyEmailList(currentSelectedItemsCopy)

              let multiEmail = '';

              currentSelectedItemsCopy.forEach((email, index) => {
                multiEmail = multiEmail + email;

                if (currentSelectedItemsCopy.length - 1 !== index) {
                  multiEmail = multiEmail + ';';
                }
              });
              setCopyEmail(multiEmail);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
              if (checkEmail(e.target.value) && !copyEmailList.includes(e.target.value)) {
                  const currentSelectedItemsCopy = [...copyEmailList, e.target.value];

                  setCopyEmailList(currentSelectedItemsCopy)

                  let multiEmail = '';

                  currentSelectedItemsCopy.forEach((email, index) => {
                    multiEmail = multiEmail + email;

                    if (currentSelectedItemsCopy.length - 1 !== index) {
                      multiEmail = multiEmail + ';';
                    }
                  });
                  setCopyEmail(multiEmail);

              }
          }
        }}
      />
    );
  }

  return (
    <>
      <div style={homeStyles}>
        {showEmailSuccess && (
          <MessageBar
            messageBarType={MessageBarType.success}
            isMultiline={false}
            onDismiss={() => setShowEmailSuccess(false)}
            dismissButtonAriaLabel="Close"
            style={{
              fontSize: 14,
            }}
          >
            {t('documents.sendEmailSuccess')}
          </MessageBar>
        )}
        {showEmailError && (
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={false}
            onDismiss={() => setShowEmailError(false)}
            dismissButtonAriaLabel="Close"
            style={{
              fontSize: 14,
            }}
          >
            {t('documents.sendEmailFail')}
          </MessageBar>
        )}

        {showSmsSuccess && (
          <MessageBar
            messageBarType={MessageBarType.success}
            isMultiline={false}
            onDismiss={() => setShowSmsSuccess(false)}
            dismissButtonAriaLabel="Close"
            style={{
              fontSize: 14,
            }}
          >
            {t('documents.sendSmsSuccess')}
          </MessageBar>
        )}

        {showSmsError && (
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={false}
            onDismiss={() => setShowSmsError(false)}
            dismissButtonAriaLabel="Close"
            style={{
              fontSize: 14,
            }}
          >
            {t('documents.sendSmsFail')}
          </MessageBar>
        )}

        {(showEmailSuccess || showEmailError || showSmsSuccess || showSmsError) && <br />}
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <h2 style={{ marginLeft: '0px', marginTop: '25px', marginBottom: '17px' }}>{t('WithholdingTaxDocuments.header')}</h2>
          </Stack>

          <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}>
            <DocumentCard aria-label={t('WithholdingTaxDocuments.totalDocuments')} styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
              <DocumentCardLogo {...{ logoIcon: 'Documentation' }} title={t('WithholdingTaxDocuments.totalDocuments')} />
              <DocumentCardDetails>
                <DocumentCardTitle title={t('WithholdingTaxDocuments.totalDocuments')} styles={cardStyles.header} />
                <Text styles={cardStyles.amount}>
                  <CountUp end={totalDocuments} separator="," duration={1} /> {t('WithholdingTaxDocuments.document')}
                </Text>
              </DocumentCardDetails>
            </DocumentCard>

            <DocumentCard aria-label={t('WithholdingTaxDocuments.total')} styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
              <DocumentCardLogo {...{ logoIcon: 'Money' }} title={t('WithholdingTaxDocuments.total')} />
              <DocumentCardDetails>
                <DocumentCardTitle title={t('WithholdingTaxDocuments.total')} styles={cardStyles.header} />
                <Text styles={cardStyles.amount}>
                  <CountUp end={totalGrand} separator="," decimals={2} decimal="." duration={1} /> {t('WithholdingTaxDocuments.thb')}
                </Text>
              </DocumentCardDetails>
            </DocumentCard>

            <DocumentCard aria-label={t('WithholdingTaxDocuments.taxTotal')} styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
              <DocumentCardLogo {...{ logoIcon: 'Bank' }} title={t('WithholdingTaxDocuments.taxTotal')} />
              <DocumentCardDetails>
                <DocumentCardTitle title={t('WithholdingTaxDocuments.taxTotal')} styles={cardStyles.header} />
                <Text styles={cardStyles.amount}>
                  <CountUp end={totalVat} separator="," decimals={2} decimal="." duration={1} /> {t('WithholdingTaxDocuments.thb')}
                </Text>
              </DocumentCardDetails>
            </DocumentCard>
          </Stack>
        </Stack>

        {totalProcessingDocuments > 0 && (
          <div>
            <br />
            <Spinner label={t('WithholdingTaxDocuments.signProcessing')} size={SpinnerSize.medium} labelPosition="left" />
          </div>
        )}
        <br />
        <Stack horizontal horizontalAlign="center">
          <Stack
            vertical
            style={{
              width: '98%',
              minWidth: '800px',
              maxWidth: '1350px',
            }}
          >
            <Stack 
              horizontal 
              // className="ms-bgColor-white" 
              styles={{
                root: {
                  backgroundColor: 'white',
                },
              }}
              horizontalAlign="space-between"
            >
              <Stack
                styles={{
                  root: {
                    width: 'calc(100vw - 700px)',
                    minWidth: '700px',
                    maxWidth: '900px',
                  },
                }}
              >
                <CommandBar items={commandBarItems} />
              </Stack>
              {loadDataComplete && (
                <Stack
                  horizontal
                  styles={{
                    root: {
                      height: 44,
                    },
                  }}
                  tokens={{ childrenGap: '10px' }}
                >
                  <SearchBox
                    className="ms-borderColor-themePrimary"
                    styles={{
                      root: {
                        marginTop: 6,
                        marginLeft: 6,
                        width: 200,
                        fontSize: 13,
                        fontWeight: 0,
                      },
                    }}
                    disabled={!loadDataComplete}
                    placeholder={t('WithholdingTaxDocuments.search')}
                    onChange={(e) => setSearch(e ? e.target.value.replace(/\s/g, '') : '')}
                    onBlur={(e) => {
                      if (!e.target.value && previousSearch) {
                        setLoadDataComplete(false);
                        setItems([]);
                        setGetNewDocuments(true);
                        setPreviousSearch('');

                        setCurrentPage(1);

                        setTotalDocuments(0);
                        setTotalVat(0);
                        setTotalGrand(0);

                        resetSort();
                      }
                    }}
                    onClear={() => {
                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);
                      setPreviousSearch('');

                      setCurrentPage(1);

                      setTotalDocuments(0);
                      setTotalVat(0);
                      setTotalGrand(0);

                      resetSort();
                    }}
                    onSearch={() => {
                      setPreviousSearch(search);
                      handleSearch();
                    }}
                    value={search}
                  />
                  <PrimaryButton
                    disabled={!loadDataComplete || !search}
                    styles={{
                      root: {
                        marginTop: 6,
                        marginRight: 8,
                        width: 90,
                      },
                    }}
                    text={t('WithholdingTaxDocuments.search')}
                    onClick={() => {
                      setPreviousSearch(search);
                      handleSearch();
                    }}
                  />
                </Stack>
              )}
            </Stack>
            {items.length ? (
              <ShimmeredDetailsList
                items={items || []}
                enableShimmer={!loadDataComplete}
                compact={isCompactMode}
                columns={columns}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                selection={selection}
                selectionMode={!loadDataComplete || isProcessing ? SelectionMode.none : SelectionMode.multiple}
                checkboxVisibility={CheckboxVisibility.always}
                selectionPreservedOnEmptyClick={true}
              />
            ) : (
              <ShimmeredDetailsList
                items={[{}]}
                enableShimmer={!loadDataComplete}
                compact={isCompactMode}
                columns={columns}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                selection={selection}
                selectionMode={!loadDataComplete || isProcessing ? SelectionMode.none : SelectionMode.multiple}
                checkboxVisibility={CheckboxVisibility.always}
                selectionPreservedOnEmptyClick={true}
                onRenderRow={() => {
                  return (
                    <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { backgroundColor: '#FFFFFF', height: 55 } }}>
                      <Text>{t('documents.noItemFound')}</Text>
                    </Stack>
                  );
                }}
              />
            )}
          </Stack>
        </Stack>
        <br />
        <center>
          {!(documents.length === 0 || documents.length < pageSize) && loadDataComplete && (
            <Pagination
              selectedPageIndex={currentPage - 1}
              pageCount={pageData.totalPages}
              onPageChange={onPageChange}
              format
              firstPageIconProps={{
                iconName: 'DoubleChevronLeft',
              }}
              previousPageIconProps={{
                iconName: 'ChevronLeft',
              }}
              nextPageIconProps={{ iconName: 'ChevronRight' }}
              lastPageIconProps={{
                iconName: 'DoubleChevronRight',
              }}
            />
          )}
          <br />
          <br />
        </center>

        <Dialog
          hidden={!isClickEmail}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: 'ส่งไฟล์ทางอีเมล',
            subText: fileNameEmail,
          }}
          minWidth={500}
        >
          <Stack vertical>
            <div>
              <label>{t('documents.sendToEmail')}</label>
            </div>
            <TextFieldEmailComponent />
            <br />
            <Checkbox
              label={t('documents.ccToEmail')}
              checked={isClickCopyEmail}
              onChange={(e) => {
                console.log('copy email checkbox: ', e.target.value);
                setIsClickCopyEmail((prev) => !prev);
              }}
            />
            {isClickCopyEmail && (
              <div style={{ marginTop: '8px' }}>
                <TextFieldCopyEmailComponent />
              </div>
            )}
            <br />
            <br />
          </Stack>
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                if (isClickCopyEmail) {
                  sendEmail(fileDisplayName, fileIdEmail, customerEmail, copyEmail);
                } else {
                  sendEmail(fileDisplayName, fileIdEmail, customerEmail, '');
                }
              }}
              text={t('documents.sendEmail')}
            />
            <DefaultButton onClick={() => setIsClickEmail(false)} text={t('documents.cancel')} />
          </DialogFooter>
        </Dialog>

        <Dialog
          hidden={!dialogConfirm.isClick}
          onDismiss={() =>
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            }))
          }
          styles={{ main: { minHeight: 0 } }}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: dialogConfirm.header,
            styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 }, subText: { textAlign: 'center' } },
            subText: dialogConfirm.message,
          }}
        >
          <DialogFooter styles={{ actionsRight: { textAlign: 'center' } }}>
            <PrimaryButton onClick={dialogConfirm.onConfirm} text={t('documents.confirm')} />
            <DefaultButton
              onClick={() =>
                setDialogConfirm((prev) => ({
                  ...prev,
                  isClick: false,
                }))
              }
              text={t('documents.cancel')}
            />
          </DialogFooter>
        </Dialog>
      </div>
      {url ? (
        <div ref={printRef} style={{ position: 'absolute', zIndex: -1 }}>
          <PdfAllPages pdf={url} />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default WithholdingTaxDocuments;
