import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Stack, Icon, PrimaryButton, FocusTrapZone, Spinner } from '@fluentui/react';

const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
const API_URL = process.env.REACT_APP_API_URL;

const client_id = process.env.REACT_APP_ZOHO_CLIENT_ID;
const redirect_uri = PUBLIC_URL + '/etax/sources/zoho/connect';

// Processing dialog style
const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const ZohoConnect = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const companyId = sessionStorage.getItem('companyId') || '';

  const { t } = useTranslation();

  const history = useHistory();

  const password = '';

  const [isLoading, setIsLoading] = useState(true);

  const [status, setStatus] = useState('');

  const callBackUrl = `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoInvoice.settings.READ,ZohoInvoice.invoices.READ&response_type=code&redirect_uri=${redirect_uri}&access_type=offline&client_id=${client_id}&prompt=consent`;

  useEffect(() => {
    console.log('userEffect retrieve users data!');

    const query = new URLSearchParams(window.location.search);
    const code = query.get('code');

    if (code) {
      console.log('userEffect retrieve access_token!');

      setIsLoading(true);

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .post(
              API_URL + '/sources/zoho',
              {
                code: code,
                redirect_uri: redirect_uri,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId,
                },
              }
            )
            .then(
              (response) => {
                console.log('Zoho sources response: ', response);

                if (response.data) {
                  if (response.data.canLogin) {
                    history.push({
                      pathname: '/etax/sources/zoho',
                      state: {},
                    });
                  } else {
                    setStatus('fail');
                  }
                }

                setIsLoading(false);
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    } else {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .get(API_URL + '/sources/zoho', {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId,
              },
            })
            .then(
              (response) => {
                console.log('Zoho sources: ', response);

                if (response.data) {
                  if (!response.data.disabled && response.data.canLogin) {
                    history.push({
                      pathname: '/etax/sources/zoho',
                      state: {},
                    });
                  }
                }

                setIsLoading(false);
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack>
      {!isLoading && (
        <Stack vertical>
          <br />

          <h1 style={{ marginLeft: '0px', marginTop: '27px' }}>
            <Icon iconName="CloudImportExport" style={{ color: '#106ebe' }} /> {t('zohoConnect.header')}
          </h1>
          <h3 style={{ marginTop: '0px', marginBottom: '10px' }}>{t('zohoConnect.subHeader1')}</h3>

          <br />
          <br />
        </Stack>
      )}
      {status === '' && !isLoading && (
        <FocusTrapZone disabled={true}>
          <Stack horizontalAlign="center">
            <Stack
              style={{
                border: '1px solid rgb(134, 134, 134)',
                width: '350px',
                backgroundColor: '#FFFFFF',
              }}
            >
              <br />

              <Stack horizontal horizontalAlign="center" styles={{ root: { marginBottom: 50 } }}>
                <img src="/images/Zoho-logo.png" width="200px" style={{ marginTop: 30 }} alt="zohologo" />
              </Stack>
              <br />
              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}>
                <PrimaryButton
                  style={{
                    fontSize: '20px',
                    height: '50px',
                    width: '255px',
                  }}
                  href={callBackUrl}
                >
                  {t('zohoConnect.connect')}
                </PrimaryButton>
              </Stack>
              <br />
              <br />
            </Stack>
          </Stack>
        </FocusTrapZone>
      )}

      {isLoading && (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="certificates-loading-center">
              {password !== '' ? (
                <Spinner label={t('zohoConnect.processing')} labelPosition="down" styles={spinnerStyles} />
              ) : (
                <Spinner label={t('zohoConnect.loading')} labelPosition="down" styles={spinnerStyles} />
              )}
            </div>
            <br />
          </Stack>
        </Stack>
      )}

      {status === 'success' && (
        <Stack horizontal horizontalAlign="center">
          <Stack
            style={{
              border: '1px solid rgb(134, 134, 134)',
              width: '300px',
              backgroundColor: '#FFFFFF',
            }}
          >
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1 style={{ margin: '0px', color: '#0078D4' }}>{t('zohoConnect.successMessage')}</h1>
            </Stack>
            <br />
            <Stack horizontal horizontalAlign="center">
              <img height="120" src="/images/success03.gif" alt="success03gif" />
            </Stack>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton
                style={{ fontSize: '20px', height: '50px' }}
                onClick={() => {
                  history.push({
                    pathname: '/etax/sources/shipnity',
                    state: {},
                  });
                }}
              >
                {t('zohoConnect.successButton')}
              </PrimaryButton>
            </Stack>
            <br />
            <br />
          </Stack>
        </Stack>
      )}

      {status === 'fail' && (
        <Stack horizontal horizontalAlign="center">
          <Stack
            style={{
              border: '1px solid rgb(134, 134, 134)',
              width: '300px',
              backgroundColor: '#FFFFFF',
            }}
          >
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1 style={{ margin: '0px', color: '#F25139' }}>{t('zohoConnect.failMessage')}</h1>
            </Stack>
            <br />
            <Stack horizontal horizontalAlign="center">
              <img height="120" src="/images/fail01.gif" alt="fail01gif" />
            </Stack>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton
                style={{ fontSize: '20px', height: '50px' }}
                onClick={() => {
                  setStatus('');
                }}
              >
                {t('zohoConnect.failButton')}
              </PrimaryButton>
            </Stack>
            <br />
            <br />
          </Stack>
        </Stack>
      )}

      <br />
      <br />
      {status === '' && !isLoading && (
        <center>
          <br />
          <br />
          <span style={{ color: 'gray' }}>{t('zohoConnect.footer1')}</span>
          <br />
          <br />
          <span style={{ color: 'gray', fontWeight: 'bold' }}>{t('zohoConnect.footer2')}</span>
        </center>
      )}
    </Stack>
  );
};

export default ZohoConnect;
