import { Button,
  TableCellLayout,
  makeStyles,
  Menu,
  MenuTrigger,
  MenuButton,
  MenuPopover,
  MenuList,
  MenuItem,
  DataGrid,
  DataGridHeader,
  DataGridRow,
  DataGridHeaderCell,
  DataGridBody,
  DataGridCell,
  TabList,
  Tab,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
  DialogActions,
  Field,
  Input,
  Spinner,
  ToolbarButton,
  Title2,
  Subtitle2Stronger,
  Link,
  Tooltip,
  ProgressBar,
  Popover,
  PopoverSurface,
  Divider,
  Dropdown,
  Option,
  shorthands,
  useId,
  Switch,
  } from '@fluentui/react-components';

  import { Alert } from "@fluentui/react-components/unstable";

  import { useMsal, useAccount } from '@azure/msal-react';
  import { silentRequest } from '../../../authConfig';

  import { useHistory, useLocation } from 'react-router-dom';

import {
    FolderRegular,
    DocumentRegular,
    DocumentPdfRegular,
    AddRegular,
    ArrowUploadRegular,
    ChevronRightRegular,
    DeleteRegular,
    ArrowDownloadRegular,
    ShareIosRegular,
    DismissCircleRegular,
    MoreHorizontalRegular,
    ImageRegular,
    CloudBidirectionalRegular,
    CheckmarkRegular,
    DismissRegular,
    ShareRegular,
    DocumentTableRegular,
    SearchRegular,
    ArrowClockwiseRegular,
    ShareScreenPersonRegular,
    VideoClipRegular,
    StorageRegular
    } from "@fluentui/react-icons";
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import JSZip from 'jszip';

const useStyles = makeStyles({
  stack: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: 'auto',
    height: 'auto',
    boxSizing: 'border-box',
    '> *': {
      textOverflow: 'ellipsis',
    },
    '> :not(:first-child)': {
      marginTop: '0px',
    },
    '> *:not(.ms-StackItem)': {
      flexShrink: 1,
    },
  },
  wrapper: {
    columnGap: "5px",
    display: "flex",
  },
  button: {
    // display: "flex", 
    // justifyContent: 'center', 
    // alignItems: 'center', 
    // fontWeight: 600,
    // ':hover': {
    //   color: tokens.colorNeutralForeground2BrandHover
    // }
  },
  root: {
    // Stack the label above the field with a gap
    display: "grid",
    // gridTemplateRows: "repeat(1fr)",
    // justifyItems: "start",
    ...shorthands.gap("2px"),
    width: '100%'
    // maxWidth: "400px",
  },
})

const toThaiDateString = (isoDateTime) => {
  let date = '';
  date = new Date(isoDateTime);
  let year = date.getFullYear() + 543;
  let month = (date.getMonth() + 1).toString().padStart(2, '0');
  let numOfDay = date.getDate().toString().padStart(2, '0');

  let hour = date.getHours().toString().padStart(2, '0');
  let minutes = date.getMinutes().toString().padStart(2, '0');

  return `${numOfDay}/${month}/${year} ${hour}:${minutes} น.`;
};

const twentyFiveMB = 25 * 1024 * 1024;

const getTypeFile = (content) => {
  let _file = null
  let _type = ''

  if (content.folder) {
    _file = <FolderRegular />
    _type = 'folder'
  } else {
    if (content.file?.mimeType === 'application/pdf') {
      _file = <DocumentPdfRegular />
    } else if (content.file?.mimeType === 'image/png' || 
               content.file?.mimeType === 'image/jpeg') {
      _file = <ImageRegular />
    } else if (content.file?.mimeType === 'application/vnd.ms-powerpoint') {
      _file = <ShareScreenPersonRegular />
    } else if (content.file?.mimeType.includes('video')) {
      _file = <VideoClipRegular />
    } else if (content.file?.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      _file = <DocumentRegular />
    } else if (content.file?.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      _file = <DocumentTableRegular />
    } else {
      _file = <DocumentRegular />
    }

    _type = 'file'
  }

  return { _file, _type }
}

function getTabIndex(path) {
  console.log('getTabIndex path', path)
  if (path.includes('/')) {
    const split = path.split('/')
    // split.shift()
    // console.log(split)

    if (!split[split.length - 1]) {
        return split.filter(value => value).length
    }
    return split.length - 1
  } 
  return 0
}

// const callBackUrlMSOneDrive = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=91f9f531-4187-4603-bb50-bbe13e18bc7c&response_type=code&redirect_uri=${redirectUriMSOneDrive}&response_mode=query&scope=https://graph.microsoft.com/files.readwrite https://graph.microsoft.com/User.Read offline_access`
// const redirectUriMSOneDrive = "http://localhost:3000/settings/storages"
const redirectUriMSOneDrive = "http://localhost:3000/storages/one-drive"
const callBackUrlMSOneDrive = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=91f9f531-4187-4603-bb50-bbe13e18bc7c&response_type=code&redirect_uri=${redirectUriMSOneDrive}&response_mode=query&scope=https://graph.microsoft.com/files.readwrite https://graph.microsoft.com/User.Read offline_access`

const redirectUriGoogleDrive = "http://localhost:3000/storages/google-drive"
const callBackUrlGoogleDrive = `https://accounts.google.com/o/oauth2/auth?client_id=276206940953-38dotjr1e7fe2mabt4sd1f098tq2lj23.apps.googleusercontent.com&response_type=code&redirect_uri=${redirectUriGoogleDrive}&response_mode=query&scope=https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.file`

function OneDriveStorages() {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();

  const styles = useStyles();

  const [items, setItems] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [selectedItemsKey, setSelectedItemsKey] = useState([])
  const [tabIndex, setTabIndex] = useState(0)
  const [tabListValue, setTabListValue] = useState(['Doc'])

  const [currentFolderId, setCurrentFolderId] = useState('')

  const [drives, setDrives] = useState([])

  // const { id, name, owner, accessToken } = location.state;
  const [selectedDriveId, setSelectedDriveId] = useState(location.state ? location.state.id : '')

  const [dialog, setDialog] = useState({
    open: false,
    loading: false,
    headers: '',
    value: '',
    onSubmit: null,
    onCancel: null,
    content: function () {
      return null
    },
    onSubmitLabel: '',
    onCancelLabel: '',
    dowloadingProgress: 0
  })

  const [backup, setBackup] = useState(false)

  const [dialogConnectNewDrive, setDialogConnectNewDrive] = useState({
    open: false,
    loading: false,
    status: '',
    storage: '',
    region: '',
    endpoint: '',
    accessKeyId: '',
    secretAccessKey: '',
    bucket: '',
    buckets: [],
    selectedBucket: '',
  })

  const dropdownId = useId("dropdown");

  const storageOptions = [
    { key: "amazon", text: "Amazon" },
    { key: "wasabi", text: "Wasabi" },
    { key: "storj", text: "Storj" },
    { key: "oneDrive", text: "Microsoft One Drive" },
    { key: "googleDrive", text: "Google Drive" },
  ]

  const endpointAmazonS3 = [
    { key: "s3.ap-southeast-1.amazonaws.com", text: "ap-southeast-1" },
    { key: "s3.ap-southeast-2.amazonaws.com", text: "ap-southeast-2" },
    { key: "s3.ap-southeast-3.amazonaws.com", text: "ap-southeast-3" },
    { key: "s3.ap-southeast-4.amazonaws.com", text: "ap-southeast-4" },
    { key: "s3.ap-northeast-1.amazonaws.com", text: "ap-northeast-1" },
    { key: "s3.ap-northeast-2.amazonaws.com", text: "ap-northeast-2" },
    { key: "s3.ap-northeast-3.amazonaws.com", text: "ap-northeast-3" },
    { key: "s3.ap-south-1.amazonaws.com", text: "ap-south-1" },
    { key: "s3.ap-south-2.amazonaws.com", text: "ap-south-2" },
    { key: "s3.ap-east-1.amazonaws.com", text: "ap-east-1" },
    { key: "s3.us-east-2.amazonaws.com", text: "us-east-2" },
    { key: "s3.us-east-1.amazonaws.com", text: "us-east-1" },
    { key: "s3.us-west-1.amazonaws.com", text: "us-west-1" },
    { key: "s3.us-west-2.amazonaws.com", text: "us-west-2" },
    { key: "s3.us-gov-east-1.amazonaws.com", text: "us-gov-east-1" },
    { key: "s3.us-gov-west-1.amazonaws.com", text: "us-gov-west-1" },
    { key: "s3.af-south-1.amazonaws.com", text: "af-south-1" },
    { key: "s3.ca-central-1.amazonaws.com", text: "ca-central-1" },
    { key: "s3.cn-north-1.amazonaws.com", text: "cn-north-1" },
    { key: "s3.cn-northwest-1.amazonaws.com", text: "cn-northwest-1" },
    { key: "s3.eu-central-1.amazonaws.com", text: "eu-central-1" },
    { key: "s3.eu-west-1.amazonaws.com", text: "eu-west-1" },
    { key: "s3.eu-west-2.amazonaws.com", text: "eu-west-2" },
    { key: "s3.eu-south-1.amazonaws.com", text: "eu-south-1" },
    { key: "s3.eu-west-3.amazonaws.com", text: "eu-west-3" },
    { key: "s3.eu-north-1.amazonaws.com", text: "eu-north-1" },
    { key: "s3.eu-south-2.amazonaws.com", text: "eu-south-2" },
    { key: "s3.eu-central-2.amazonaws.com", text: "eu-central-2" },
    { key: "s3.sa-east-1.amazonaws.com", text: "sa-east-1" },
    { key: "s3.me-south-1.amazonaws.com", text: "me-south-1" },
    { key: "s3.me-central-1.amazonaws.com", text: "me-central-1" }
  ];

  const endpointWasabiS3 = [
    { key: "s3.ap-southeast-1.wasabisys.com", text: "ap-southeast-1" },
    { key: "s3.ap-southeast-2.wasabisys.com", text: "ap-southeast-2" },
    { key: "s3.ap-northeast-1.wasabisys.com", text: "ap-northeast-1" },
    { key: "s3.ap-northeast-2.wasabisys.com", text: "ap-northeast-2" },
    { key: "s3.us-east-1.wasabisys.com", text: "us-east-1" },
    { key: "s3.us-east-2.wasabisys.com", text: "us-east-2" },
    { key: "s3.us-central-1.wasabisys.com", text: "us-central-1" },
    { key: "s3.us-west-1.wasabisys.com", text: "us-west-1" },
    { key: "s3.ca-central-1.wasabisys.com", text: "ca-central-1" },
    { key: "s3.eu-central-1.wasabisys.com", text: "eu-central-1" },
    { key: "s3.eu-central-2.wasabisys.com", text: "eu-central-2" },
    { key: "s3.eu-west-1.wasabisys.com", text: "eu-west-1" },
    { key: "s3.eu-west-2.wasabisys.com", text: "eu-west-2" }    
  ];

  // const [endpointWasabiS3, setEndpointWasabiS3] = useState([]);
  // const [endpointStorjS3, setEndpointStorjS3] = useState([]);
  const [optionsEndpointS3, setOptionsEndpointS3] = useState(() => {
    if (sessionStorage.getItem('storage-key') === 'amazon') {
      return endpointAmazonS3
    } else if (sessionStorage.getItem('storage-key') === 'wasabi') {
      return endpointWasabiS3
    } else if (sessionStorage.getItem('storage-key') === 'storj') {
      return [{key: "gateway.storjshare.io", text: "storjshare-gateway"}]
    } else {
      return endpointAmazonS3
    }
    
  });

  const [isLoading, setIsLoading] = useState(true)

  const fileInputRef = useRef();

  const [alert, setAlert] = useState({
    open: false,
    status: '',
    message: ''
  })

  const mainDiv = useRef({})

  const [loadingLink, setLoadingLink] = useState(false)

  const companyId = sessionStorage.getItem('companyId') || '';

  const columns = [
    { 
      columnId: "file", 
      label: "", 
      icon: <DocumentRegular />,
      compare: (a, b) => {
        return a.type.localeCompare(b.type);
      },
      renderHeaderCell: () => {
        return t("storages.file");
      },
      renderCell: (item) => {
        return (
          <TableCellLayout truncate media={item.file} />
        );
      } 
    },
    { 
      columnId: "name", 
      label: "Name", 
      width: 100,
      compare: (a, b) => {
        return a.name.localeCompare(b.name);
      },
      renderHeaderCell: () => {
        return t("storages.name");
      },
      renderCell: (item) => {
        return (
          <ActionCell onClickEvent={() => handleClickLink('name', item)} item={item} />
        );
      }
    },
    { 
      columnId: "modified", 
      label: "Modified", 
      width: 100,
      compare: (a, b) => {
        return new Date(a.fileSystemInfo.lastModifiedDateTime) - new Date(b.fileSystemInfo.lastModifiedDateTime);
      },
      renderHeaderCell: () => {
        return t("storages.modified");
      },
      renderCell: (item) => {
        return (
          <TableCellLayout truncate>{toThaiDateString(item.fileSystemInfo.lastModifiedDateTime)}</TableCellLayout>
        );
      }, 
    },
    { 
      columnId: "modified by", 
      label: "Modified By", 
      width: 100,
      compare: (a, b) => {
        return new Date(a.lastModifiedBy.user.displayName) - new Date(b.lastModifiedBy.user.displayName);
      },
      renderHeaderCell: () => {
        return t("storagesOneDrive.modifiedBy");
      },
      renderCell: (item) => {
        return (
          <TableCellLayout truncate>{item.lastModifiedBy.user.displayName}</TableCellLayout>
        );
      }, 
    },
  ];


  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const code = query.get('code');

    if (code) {
      getAccessToken(code)
    } else {
      getNewItemsInRootFolder(selectedDriveId);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getDriveList()

    if (selectedDriveId) {
      getNewItemsInRootFolder(selectedDriveId);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDriveId])

  
  async function getDriveList() {
    axios
      .get('http://localhost:7071/api/Storages', {
        headers: {
          // Authorization: 'Bearer ' + tokenResponse.accessToken,
          // 'cid': companyId
        },
      }).then(res => {
        console.log("response storage", res)

        setDrives(res.data)

        const selectedDrive = res.data.find(_drive => _drive.id === selectedDriveId)

        if (selectedDrive) {
          setBackup(selectedDrive.backup)

        }
      })
    
  }

  async function getAccessToken(code) {
    instance
    .acquireTokenSilent({ ...silentRequest, account: account })
    .then((tokenResponse) => {
      axios
        .post(
          'http://localhost:7071/api/OneDrive_getAccessToken',
          {
            code: code,
            redirect_uri: redirectUriMSOneDrive
          },
          {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId
            },
          }
        )
        .then(
          (response) => {
            console.log('OneDrive_getAccessToken response', response);

            axios
              .post('http://localhost:7071/api/Storages', {...response.data, type: 'oneDrive'}, {
                headers: {
                  // Authorization: 'Bearer ' + tokenResponse.accessToken,
                  // 'cid': companyId
                },
              }).then(res => {
                console.log("response storage", res)
                
                setSelectedDriveId(response.data.owner.user.id)
                getDriveList()
              })

            // if (response.data) {
            //   if (response.data.canLogin) {
            //     history.push({
            //       pathname: '/storages/one-drive',
            //       state: {},
            //     });
            //   } else {
            //     setStatus('fail');
            //   }
            // }

            return response.data
          },
          (error) => {
            console.log(error);
          }
        ).then((res) => {
          
          // getNewItemsInRootFolder(res);
        })
    })
    .catch((error) => {
      //Acquire token silent failure, and send an interactive request
      console.log(error);
      instance.acquireTokenRedirect(silentRequest);
    });
  }
    
  async function getNewItemsInRootFolder(_selectedDriveId) {
    // await checkStorageConnection();

    instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
      axios
      .get(
        'http://localhost:7071/api/OneDrive_getItemsInRootFolder',
        {
          headers: {
            Authorization: 'Bearer ' + tokenResponse.accessToken,
            cid: companyId
          },
          params: {
            // driveId: _selectedDriveId.id,
            id: _selectedDriveId
          }
        }
      )
      .then((response) => {
        
        console.log('OneDrive_getItemsInRootFolder response', response);

        // if (response.data.success) {
          const _value = response.data?.value || []

          setCurrentFolderId(_value[0].parentReference.id)

          if (_value.length) {
            const decodePath = decodeURIComponent(_value[0].parentReference.path.replace('/drive/root:', ''))
            setPathValue(decodePath)
            setTabIndex(getTabIndex(decodePath))

            // console.log(getTabIndex(_value[0].parentReference.path.replace('/drive/root:', '')))
          }
          // const _count = resData["@odata.context"]

          // setMoreContent(prev => 
          //   ({...prev, 
          //     IsTruncated: IsTruncated, 
          //     ContinuationToken: NextContinuationToken, 
          //     Prefix: Prefix,
          //     loading: false
          //   })
          // )

          // const _contents = Contents.map(_content => ({..._content, Key: _content.Key.replace('.file_placeholder', '')}))

          setItems(_value.map(_content => {
            const { _file, _type } = getTypeFile(_content)

            return {..._content, file: _file, type: _type, key: _content.id}
          }))

          
        
          setIsLoading(false)
          setSelectedItems([])
          setSelectedItemsKey([])
        // }
      }).catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
  
        setAlert(prev => ({...prev, open: true, status: 'error', message: t('storagesOneDrive.alertErrorOccured')}))
        // instance.acquireTokenRedirect(silentRequest);
      });
    }).catch((error) => {
      //Acquire token silent failure, and send an interactive request
      console.log(error);

      // setAlert(prev => ({...prev, open: true, status: 'error', message: 'Access Denied'}))
      // instance.acquireTokenRedirect(silentRequest);
    });
  }

  async function getNewItemsInFolder(folderId, path) {
    instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
      axios
        .get(
          'http://localhost:7071/api/OneDrive_getItemsInFolder',
          // 'http://localhost:7071/api/AwsObjectsList',
          // API_URL + '/storages/s3/objects',
          {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              cid: companyId
            },
            params: {
              folderId: folderId,
              // driveId: selectedDriveId.id,
              id: selectedDriveId
            }
          }          
        )
        .then((response) => {
          
          console.log('OneDrive_getItemsInFolder response', response);

          const _value = response.data?.value || []

          if (_value.length) {
            const decodePath = decodeURIComponent(_value[0].parentReference.path.replace('/drive/root:', ''))
            setPathValue(decodePath)
            setTabIndex(getTabIndex(decodePath))
            // console.log(getTabIndex(_value[0].parentReference.path.replace('/drive/root:', '')))

          } else {
            setPathValue(path)
            setTabIndex(getTabIndex(path))
          }

          setLoadingLink(false)

          // setCurrentFolderId(_value[0].parentReference.id)

          setItems(_value.map(_content => {
            const { _file, _type } = getTypeFile(_content)

            return {..._content, file: _file, type: _type, key: _content.id}
          }))

          setIsLoading(false)
          setSelectedItems([])
          setSelectedItemsKey([])
        }).catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
    
          setAlert(prev => ({...prev, open: true, status: 'error', message: t('storagesOneDrive.alertErrorOccured')}))
          // instance.acquireTokenRedirect(silentRequest);
        });
    });
  }

  async function getNewItemsInFolderByPath(path) {
    instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
      axios
        .get(
          'http://localhost:7071/api/OneDrive_getItemsInFolderByPath',
          // 'http://localhost:7071/api/AwsObjectsList',
          // API_URL + '/storages/s3/objects',
          {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              cid: companyId
            },
            params: {
              path: path,
              // driveId: selectedDriveId.id,
              id: selectedDriveId
            }
          }          
        )
        .then((response) => {
          
          console.log('OneDrive_getItemsInFolderByPath response', response);

          const _value = response.data?.value || []

          if (_value.length) {
            const decodePath = decodeURIComponent(_value[0].parentReference.path.replace('/drive/root:', ''))
            setPathValue(decodePath)
            setTabIndex(getTabIndex(decodePath))
          } else {
            setPathValue(path)
            setTabIndex(getTabIndex(path))
          }

          setLoadingLink(false)

          // setCurrentFolderId(_value[0].parentReference.id)

          setItems(_value.map(_content => {
            const { _file, _type } = getTypeFile(_content)

            return {..._content, file: _file, type: _type, key: _content.id}
          }))

          setIsLoading(false)
          setSelectedItems([])
          setSelectedItemsKey([])
        }).catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
    
          setAlert(prev => ({...prev, open: true, status: 'error', message: t('storagesOneDrive.alertErrorOccured')}))
          // instance.acquireTokenRedirect(silentRequest);
        });
    });
  }

  function setPathValue(path) {
    const _split = path.split('/')
    _split.shift()  
    setTabListValue(['Doc', ..._split])
  }

  function removePathValue(index) {
    const values = [...tabListValue];
    values.splice(index + 1, values.length);

    setTabListValue(values)
  }

  function handleClickLink(columnId, item) {
    // console.log('item', item)
    if (columnId === 'name') {
      if (item.type === 'folder') {
        setLoadingLink(true)

        // setCurrentFolderId(item.id)
        setPathValue(item.parentReference.path.replace('/drive/root:', ''))

        getNewItemsInFolder(item.id, item.parentReference.path.replace('/drive/root:', '') + `/${item.name}`)
        // console.log('tabListValue', tabListValue)
        // let _path = ''
        // tabListValue.forEach(_tab => _tab === "Doc" ? _path = _path + '' : _path = _path + '/' + _tab)

        // _path = _path + '/' + item.name

        // getNewItemsInFolderByPath(_path)
      } else {
        const link = document.createElement("a");
        link.href = item.webUrl
        link.target = "_blank"
        link.click();
      }
    }
  }

  function handleClickLinkBySearch(columnId, item) {
    console.log('item', item)
    if (columnId === 'name') {
      if (item.type === 'folder') {
        setLoadingLink(true)

        getNewItemsInFolder(item.id, item.parentReference.path?.replace('/drive/root:', '') || "")
      } else {
        const link = document.createElement("a");
        link.href = item.webUrl
        link.target = "_blank"
        link.click();
      }
    }
  }

  function upload(e, type, value) {
    e.preventDefault();

    setDialog(prev => ({
      ...prev,
      loading: true,
      onSubmitLabel: '',
      onCancelLabel: ''
    }))

    instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
        axios
        .post(
          'http://localhost:7071/api/OneDrive_createFolder',
          // 'http://localhost:7071/api/AwsFoldersCreate',
          // API_URL + '/storages/s3/folders', 
          {
              type: type, 
              folderName: value, 
              body: '', 
              pathFolderId: currentFolderId,
              // driveId: selectedDriveId.id,
              id: selectedDriveId
          }, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              cid: companyId
            },
          }
        )
         
        .then((response) => {
          
          console.log('OneDrive_createFolder response', response);

          // setTimeout(() => {
          //   setDialog({
          //     open: false,
          //     loading: false,
          //     headers: '',
          //     value: '',
          //     onSubmit: null,
          //     onCancel: null,
          //     content: function () {
          //       return null
          //     }
          //   })
          // }, 2000);

          setDialog(prev => 
            ({...prev, 
              loading: false,
              content: function () {
                return <div className={styles.stack} style={{ justifyContent: 'center', alignItems: 'center' }}>
                <CheckmarkRegular fontSize={32} />
                <Subtitle2Stronger style={{ paddingTop: 6 }}>{t('storages.createComplete')}</Subtitle2Stronger>
              </div>
              },
            }))

          setTimeout(() => {
            setDialog({
              open: false,
              loading: false,
              headers: '',
              value: '',
              onSubmit: null,
              onCancel: null,
              content: function () {
                return null
              }
            })
          }, 2000);

          let _path = ''
          tabListValue.forEach(_tab => _tab === "Doc" ? '' : '/' + _tab)
          // _path = _path + '/' + item.name

          getNewItemsInFolderByPath(_path)
        }).catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);

          setDialog({
            open: false,
            loading: false,
            headers: '',
            value: '',
            onSubmit: null,
            onCancel: null,
            content: function () {
              return null
            }
          })
    
          setAlert(prev => ({...prev, open: true, status: 'error', message: t('storagesOneDrive.alertErrorOccured')}))
          // instance.acquireTokenRedirect(silentRequest);
        });
    })
  }

  async function handleUploadFile(e) {
    e.preventDefault();

    if (e.target.files[0]) {
      // console.log(e.target.files[0])

      setDialog(prev => 
        ({...prev, 
          headers: ``,
          value: '',
          open: true,
          onSubmit: null,
          onCancel: () => setDialog({
            open: false,
            loading: false,
            headers: '',
            value: '',
            onSubmit: null,
            onCancel: null,
            content: function () {
              return null
            }
          }),
          loading: true,
          content: function () {
            return this.loading ? 
            <Spinner labelPosition='below' label={t('storages.uploading')} /> 
            : 
            <div className={styles.stack} style={{ justifyContent: 'center', alignItems: 'center' }}>
              <CheckmarkRegular fontSize={32} />
              <Subtitle2Stronger style={{ paddingTop: 6 }}>{t('storages.uploadComplete')}</Subtitle2Stronger>
            </div>
          },
        }))
  
      let formData = new FormData();

      let _path = ''
      tabListValue.forEach(_tab => _tab === "Doc" ? _path = _path + '' : _path = _path + '/' + _tab)
  
      const params = {
        fileName: e.target.files[0].name,
        pathFolder: _path,
        // driveId: selectedDriveId.id,
        id: selectedDriveId
      }

      formData.append('file', e.target.files[0]);
  
      formData.append("query", JSON.stringify(params));
  
      instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
        axios
          .post(
            'http://localhost:7071/api/OneDrive_uploadFile', formData, {
            // 'http://localhost:7071/api/AwsObjectsUpload', formData, {
            // API_URL + '/storages/s3/objects',  formData, {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'Content-Type': 'multipart/form-data',
                cid: companyId
              },
              timeout: 60000,
            }
          )
          .then((response) => {
            
            console.log('OneDrive_uploadFile response', response);

            // setTimeout(() => {
            //   setDialog({
            //     open: false,
            //     loading: false,
            //     headers: '',
            //     value: '',
            //     onSubmit: null,
            //     onCancel: null,
            //     content: function () {
            //       return null
            //     }
            //   })
            // }, 2000);

            setDialog(prev => ({...prev, loading: false}))  

            setTimeout(() => {
              setDialog({
                open: false,
                loading: false,
                headers: '',
                value: '',
                onSubmit: null,
                onCancel: null,
                content: function () {
                  return null
                }
              })
            }, 2000);

            let _path = ''
            tabListValue.forEach(_tab => _tab === "Doc" ? _path = _path + '' : _path = _path + '/' + _tab)

            getNewItemsInFolderByPath(_path)
          }).catch((error) => {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
  
            setDialog({
              open: false,
              loading: false,
              headers: '',
              value: '',
              onSubmit: null,
              onCancel: null,
              content: function () {
                return null
              }
            })
      
            setAlert(prev => ({...prev, open: true, status: 'error', message: t('storagesOneDrive.alertErrorOccured')}))
            // instance.acquireTokenRedirect(silentRequest);
          });
      })
    }
  }
  async function handleDeleteConfirm(e, idArray) {
    console.log('idArray', idArray)
    setDialog(prev => 
      ({...prev, 
        headers: ``,
        value: '',
        open: true,
        onSubmit: () => handleDelete(e, idArray),
        onCancel: () => {
          setDialog({
          open: false,
          loading: false,
          headers: '',
          value: '',
          onSubmit: null,
          onCancel: null,
          content: function () {
            return null
          },
          onCancelLabel: 'Cancel'
        })},
        loading: false,
        content: function () {
          return <div className={styles.stack} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <DeleteRegular fontSize={32} />
            <Subtitle2Stronger style={{ paddingTop: 6 }}>{t('storages.deleteConfirmMessage')}</Subtitle2Stronger>
          </div>
        },
        onCancelLabel: 'Cancel',
        onSubmitLabel: 'Delete'
      }))
  }

  async function handleDelete(e, idArray) {
    e.preventDefault();
  

    
    // const _accessKeyId = 'jxr7uir6nxqcwiow226b6vf3drxa'
    // const _secretAccessKey = 'jytnnngkjtsthnek7fy2ahod5sqx2a6c6fnzjlt7wembwfwf7apcm'
    // const _storage = 'storj'
    // const _region = 'ap-southeast-1'
    // const _bucket = 'firststorj0001'

    // const source = axios.CancelToken.source()

    const params = {
      idArray: JSON.stringify(idArray)
    }

    console.log('params', params)

    setDialog(prev => 
      ({...prev, 
        headers: ``,
        value: '',
        open: true,
        onSubmit: null,
        onCancel: () => {
          // source.cancel()
          setDialog({
          open: false,
          loading: false,
          headers: '',
          value: '',
          onSubmit: null,
          onCancel: null,
          content: function () {
            return null
          },
          onCancelLabel: ''
        })},
        loading: true,
        content: function () {
          return this.loading ? 
          <Spinner labelPosition='below' label={t('storages.deleting')} /> 
          : 
          <div className={styles.stack} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <CheckmarkRegular fontSize={32} />
            <Subtitle2Stronger style={{ paddingTop: 6 }}>{t('storages.deleteComplete')}</Subtitle2Stronger>
          </div>
        },
        onCancelLabel: '',
        onSubmitLabel: null
      }))

    instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
      axios
        .post(
          'http://localhost:7071/api/OneDrive_deleteItem', {
            idArray: params.idArray,
            // driveId: selectedDriveId.id,
            id: selectedDriveId
          }, {
          // API_URL + '/storages/s3/objects', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              cid: companyId
            },
            // data : {
            //   idArray: params.idArray,
            //   // cancelToken: source.token
            // }
          }
        )
        .then((response) => {
          
          console.log('response', response);
          
          setDialog(prev => 
            ({...prev, 
              loading: false
            }))
        

          setTimeout(() => {
            setDialog({
              open: false,
              loading: false,
              headers: '',
              value: '',
              onSubmit: null,
              onCancel: null,
              content: function () {
                return null
              }
            })
          }, 2000);

          let _path = ''
          tabListValue.forEach(_tab => _tab === "Doc" ? '' : '/' + _tab)
          // _path = _path + '/' + item.name

          getNewItemsInFolderByPath(_path)

        }).catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);

          setDialog({
            open: false,
            loading: false,
            headers: '',
            value: '',
            onSubmit: null,
            onCancel: null,
            content: function () {
              return null
            }
          })
    
          setAlert(prev => ({...prev, open: true, status: 'error', message: t('storagesOneDrive.alertErrorOccured')}))
          // instance.acquireTokenRedirect(silentRequest);
        });
    })
  }

  async function handleDownload(e, idArray) {
    e.preventDefault();

    const foundFolder = items.some(_item => idArray.find(_id => _id === _item.id && _item.type === 'folder'))
    if (foundFolder) return setAlert(prev => ({...prev, open: true, status: 'error', message: t('storages.alertMessageDownloadFolder')}))

    if (idArray.length > 100) return setAlert(prev => ({...prev, open: true, status: 'error', message: t('storages.alertMessageDownloadManyFiles')}))

    const foundLargeFile = items.some(_item => idArray.find(_id => _id === _item.id && _item.size >= twentyFiveMB))

    if (foundLargeFile) {
      // console.log("keyArray", idArray)
      if (idArray.length > 1) return setAlert(prev => ({...prev, open: true, status: 'error', message: t('storages.alertMessageDownloadManyLargeFiles')}))

      setDialog(prev => 
        ({...prev, 
          headers: ``,
          value: '',
          open: true,
          onSubmit: null,
          onCancel: () => setDialog({
            open: false,
            loading: false,
            headers: '',
            value: '',
            onSubmit: null,
            onCancel: null,
            content: function () {
              return null
            }
          }),
          loading: true,
          dowloadingProgress: 0,
          content: function () {
            return this.loading ? 
            <>
              <Spinner labelPosition='below' label={`${t('storages.downloading')} ${this.dowloadingProgress}%`} /> 
              <br />
              <Field validationMessage="" validationState="none">
                <ProgressBar value={this.dowloadingProgress / 100} thickness="large" />
              </Field>
            </>
            : 
            <div className={styles.stack} style={{ justifyContent: 'center', alignItems: 'center' }}>
              <CheckmarkRegular fontSize={32} />
              <Subtitle2Stronger style={{ paddingTop: 6 }}>{t('storages.downloadComplete')}</Subtitle2Stronger>
            </div>
          },
        }))

      const params = {
        idArray: JSON.stringify(idArray)
      }

      instance.acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios.get(
            'http://localhost:7071/api/OneDrive_getDownloadUrl',
            // 'http://localhost:7071/api/AwsObjectsList',
            // API_URL + '/storages/s3/objects',
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                cid: companyId
              },
              params: {
                idArray: params.idArray,
                // driveId: selectedDriveId.id,
                id: selectedDriveId
              }
            }          
          ).then(responseGetDownloadUrl => {
            console.log('OneDrive_getDownloadUrl response', responseGetDownloadUrl)
          
            axios.get(responseGetDownloadUrl.data.urls[0], {
              responseType: 'arraybuffer',
              onDownloadProgress: (progressEvent) => {
                const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                // setProgress(percentage);
                setDialog(prev => ({...prev, dowloadingProgress: percentage}))
        
              },
            }).then(response => {
              const link = document.createElement("a");
              link.href = URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
              link.download = items.find(_item => _item.key === idArray[0]).name || "file"
              link.click();
        
              setDialog(prev => ({...prev, loading: false}))
        
              setTimeout(() => {
                setDialog({
                  open: false,
                  loading: false,
                  headers: '',
                  value: '',
                  onSubmit: null,
                  onCancel: null,
                  content: function () {
                    return null
                  }
                })
              }, 2000);
            })
          
        })
        .catch(err => {
          console.log("err downloadLargeFile", err)

          setDialog({
            open: false,
            loading: false,
            headers: '',
            value: '',
            onSubmit: null,
            onCancel: null,
            content: function () {
              return null
            }
          })
          
          setAlert(prev => ({...prev, open: true, status: 'error', message: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'}))
        })

        })

      return;
    }

    setDialog(prev => 
      ({...prev, 
        headers: ``,
        value: '',
        open: true,
        onSubmit: null,
        onCancel: () => setDialog({
          open: false,
          loading: false,
          headers: '',
          value: '',
          onSubmit: null,
          onCancel: null,
          content: function () {
            return null
          }
        }),
        loading: true,
        content: function () {
          return this.loading ? 
          <Spinner labelPosition='below' label={t('storages.downloading')} /> 
          : 
          <div className={styles.stack} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <CheckmarkRegular fontSize={32} />
            <Subtitle2Stronger style={{ paddingTop: 6 }}>{t('storages.downloadComplete')}</Subtitle2Stronger>
          </div>
        },
      })
    )

      
      const params = {
        idArray: JSON.stringify(idArray)
      }

      if (idArray.length > 1) {
        instance.acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          axios.get(
            'http://localhost:7071/api/OneDrive_getDownloadUrl',
            // 'http://localhost:7071/api/AwsObjectsList',
            // API_URL + '/storages/s3/objects',
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                cid: companyId
              },
              params: {
                idArray: params.idArray,
                // driveId: selectedDriveId.id,
                id: selectedDriveId
              }
            }          
          ).then(async (responseGetDownloadUrl) => {
            console.log('OneDrive_getDownloadUrl response', responseGetDownloadUrl)
          
            const promises = [];

            for (let i = 0; i < responseGetDownloadUrl.data.urls.length; i++) {
              promises.push(new Promise(async function (resolve, reject) {
                await axios.get(responseGetDownloadUrl.data.urls[i], {
                  responseType: 'arraybuffer'
                })
                  .then(async (responseFile) => {
                    resolve(new Blob([responseFile.data], { type: 'application/octet-stream' }))                
                  }).catch(err => console.log("err", err))
              }));
            }
    
            await Promise.all(promises).then(async (res) => {
              
    
              const zip = new JSZip();
    
              res.forEach((_buffer, index) => {
                zip.file(items.find(_item => _item.key === idArray[index]).name, _buffer);
              })
    
              const blob = await zip.generateAsync({
                type: 'blob',
              });
    
              const link = document.createElement('a');
              link.setAttribute('href', window.URL.createObjectURL(blob));
              link.setAttribute('download', `Leceipt-Storage-${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate()).padStart(2, '0')}-${String(new Date().getHours()).padStart(2, '0')}-${String(new Date().getMinutes()).padStart(2, '0')}-${String(new Date().getSeconds()).padStart(2, '0')}.zip`);
              link.style.display = 'none';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
    
              setDialog(prev => ({...prev, loading: false}))
    
              setTimeout(() => {
                setDialog({
                  open: false,
                  loading: false,
                  headers: '',
                  value: '',
                  onSubmit: null,
                  onCancel: null,
                  content: function () {
                    return null
                  }
                })
              }, 2000);
            }).catch(err => {
              console.log("err downloadLargeFile", err)
    
              setDialog({
                open: false,
                loading: false,
                headers: '',
                value: '',
                onSubmit: null,
                onCancel: null,
                content: function () {
                  return null
                }
              })
              
              setAlert(prev => ({...prev, open: true, status: 'error', message: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'}))
            })

        })
        .catch(err => {
          console.log("err downloadLargeFile", err)

          setDialog({
            open: false,
            loading: false,
            headers: '',
            value: '',
            onSubmit: null,
            onCancel: null,
            content: function () {
              return null
            }
          })
          
          setAlert(prev => ({...prev, open: true, status: 'error', message: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'}))
        })
        })
      } else {
        instance.acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          // axios.get(items.find(_item => _item.key === idArray[0]).webUrl, {
          axios.get(
              'http://localhost:7071/api/OneDrive_getDownloadUrl',
              // 'http://localhost:7071/api/AwsObjectsList',
              // API_URL + '/storages/s3/objects',
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  cid: companyId
                },
                params: {
                  idArray: params.idArray,
                  // driveId: selectedDriveId.id,
                  id: selectedDriveId
                }
              }          
            ).then(responseGetDownloadUrl => {
              console.log('OneDrive_getDownloadUrl response', responseGetDownloadUrl)
            
              axios.get(responseGetDownloadUrl.data.urls[0], {
                responseType: 'arraybuffer',
                onDownloadProgress: (progressEvent) => {
                  const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                  // setProgress(percentage);
                  setDialog(prev => ({...prev, dowloadingProgress: percentage}))
          
                },
              }).then(response => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                link.download = items.find(_item => _item.key === idArray[0]).name || "file"
                link.click();
          
                setDialog(prev => ({...prev, loading: false}))
          
                setTimeout(() => {
                  setDialog({
                    open: false,
                    loading: false,
                    headers: '',
                    value: '',
                    onSubmit: null,
                    onCancel: null,
                    content: function () {
                      return null
                    }
                  })
                }, 2000);
              })
            
          })
          .catch(err => {
            console.log("err downloadLargeFile", err)

            setDialog({
              open: false,
              loading: false,
              headers: '',
              value: '',
              onSubmit: null,
              onCancel: null,
              content: function () {
                return null
              }
            })
            
            setAlert(prev => ({...prev, open: true, status: 'error', message: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'}))
          })
          
  
          
          // axios
          // .post(
          //   'http://localhost:7071/api/OneDrive_downloadItem', {
          //     idArray: params.idArray,
          //   },
          //   {
          //     headers: {
          //       Authorization: 'Bearer ' + tokenResponse.accessToken,
          //       cid: companyId
          //     },
          //     // responseType: 'blob'
          //   }
          // ).then(async (response) => {
          //   console.log('idArray', idArray)
          //   console.log('OneDrive_downloadItem response', response)

          //   // console.log(response.data)

          //   const base64Data = response.data; // Assuming your Azure Function returns the Base64 data

          //   // Create a Blob from the Base64 data
          //   const byteCharacters = atob(base64Data);
          //   const byteNumbers = new Array(byteCharacters.length);
          //   for (let i = 0; i < byteCharacters.length; i++) {
          //       byteNumbers[i] = byteCharacters.charCodeAt(i);
          //   }
          //   const byteArray = new Uint8Array(byteNumbers);
          //   const blob = new Blob([byteArray], { type: 'application/octet-stream' });

          //   // Create a URL for the Blob
          //   const url = window.URL.createObjectURL(blob);

          //   // Create a temporary <a> element to trigger the download
          //   const a = document.createElement('a');
          //   a.style.display = 'none';
          //   a.href = url;
          //   a.download = items.find(_item => _item.key === idArray[0]).name || 'file'; // Specify the desired filename

          //   // Trigger the click event to start the download
          //   document.body.appendChild(a);
          //   a.click();

          //   // Clean up
          //   window.URL.revokeObjectURL(url);


          //     // const url = window.URL.createObjectURL(new Blob([response.data], { type: "text/plain; charset=utf-8"}));
  
          //     // // Create a temporary anchor element and click it to trigger the download
          //     // const a = document.createElement('a');
          //     // a.href = url;
          //     // a.download = 'test.txt'; // Specify the desired filename
          //     // document.body.appendChild(a);
          //     // a.click();
  
          //     // // Clean up by revoking the URL object to free up memory
          //     // window.URL.revokeObjectURL(url);
  
          //     setTimeout(() => {
          //       setDialog({
          //         open: false,
          //         loading: false,
          //         headers: '',
          //         value: '',
          //         onSubmit: null,
          //         onCancel: null,
          //         content: function () {
          //           return null
          //         }
          //       })
          //     }, 2000);
          // })
  



        }).catch(error => {
          console.log(error);

          setDialog({
            open: false,
            loading: false,
            headers: '',
            value: '',
            onSubmit: null,
            onCancel: null,
            content: function () {
              return null
            }
          })
          
          setAlert(prev => ({...prev, open: true, status: 'error', message: 'Access Denied'}))
        })
      }
  }
  
  async function handleShare(e, item) {
    e.preventDefault();

    // if (item.some(_key => !re.test(_key.Key))) {
    //   return;
    // }
  
    setDialog(prev => 
      ({...prev, 
        headers: `Share ${item.name}`,
        value: item.webUrl,
        open: true,
        onSubmit: () => {
          setDialog(prev => ({...prev, copyLink: true}))
          navigator.clipboard.writeText(item.webUrl)},
        onCancel: () => setDialog({
          open: false,
          loading: false,
          headers: '',
          value: '',
          onSubmit: null,
          onCancel: null,
          content: function () {
            return null
          }
        }),
        loading: false,
        copyLink: false,
        content: function () {
          return this.loading ? 
          <Spinner labelPosition='below' label={t('storages.loading')} /> 
          : 
          <Field
            label="Link"
            validationState={this.copyLink ? "success" : ""}
            validationMessage={this.copyLink ? t('storages.copied') : ""}
          >
            <Input value={this.value} disabled />
          </Field>
        },
        onSubmitLabel: 'Copy link',
        onCancelLabel: 'Close',
      }))
  }

  async function handleDisconnect(e) {
    e.preventDefault();

    setDialog(prev => 
      ({...prev, 
        headers: t('storages.disconnect'),
        value: '',
        loading: true,
        open: true,
        onSubmit: () => {
          axios
            .post('http://localhost:7071/api/Storages_disconnect', { id: selectedDriveId }, {
              headers: {
                // Authorization: 'Bearer ' + tokenResponse.accessToken,
                // 'cid': companyId
              },
              
            }).then(res => {
              if (res.data.length) {
                const firstDrive = res.data[0]

                if (firstDrive.type === 'oneDrive') {
                  setSelectedDriveId(firstDrive.id)

                  setDialog({
                    open: false,
                    loading: false,
                    headers: '',
                    value: '',
                    onSubmit: null,
                    onCancel: null,
                    content: function () {
                      return null
                    }
                  })
                } else if (firstDrive.type === 'googleDrive') {
                  history.push({
                    pathname: '/storages/google-drive',
                    state: { 
                      id: firstDrive.id,
                    },
                  });
                } else if (firstDrive.type === 's3') {
                  history.push({
                    pathname: '/storages',
                    state: { 
                      id: firstDrive.id,
                    },
                  });
                }
              } else {
                history.push({
                  pathname: '/settings/storages',
                  state: {},
                })
              }
              
            })
        },
        onCancel: () => setDialog({
          open: false,
          loading: false,
          headers: '',
          value: '',
          onSubmit: null,
          onCancel: null,
          content: function () {
            return null
          }
        }),
        content: function () {
          return t('storages.disconnectConfirm')
        },
        onSubmitLabel: "Confirm",
        onCancelLabel: "Cancel"
      }))
  }

  async function handleEnableBackup(e) {
    e.preventDefault();

    setDialog(prev => 
      ({...prev, 
        headers: t('storages.backupConfirm'),
        value: '',
        loading: true,
        open: true,
        onSubmit: () => {
          axios
            .post('http://localhost:7071/api/Storages_backup', { id: selectedDriveId }, {
              headers: {
                // Authorization: 'Bearer ' + tokenResponse.accessToken,
                // 'cid': companyId
              },
              
            }).then(res => {
              setBackup(res.data.result)
              
              getDriveList()

              setDialog({
                open: false,
                loading: false,
                headers: '',
                value: '',
                onSubmit: null,
                onCancel: null,
                content: function () {
                  return null
                }
              })
            })
        },
        onCancel: () => setDialog({
          open: false,
          loading: false,
          headers: '',
          value: '',
          onSubmit: null,
          onCancel: null,
          content: function () {
            return null
          }
        }),
        content: function () {
          return <div>
            {backup ? (
              <>
                {t('storages.backupDisableDescription1')}
              </>
            ) : (
              <>
                {t('storages.backupEnableDescription1')}
                {' '}
                {t('storages.backupEnableDescription2')}
              </>
            )}
          </div>
        },
        onSubmitLabel: "Confirm",
        onCancelLabel: "Cancel"
      }))
  
    
  }

  function ActionCell({item, onClickEvent}) {
    const [hovered, setHovered] = useState(false)
  
    return (<div 
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)} 
              style={{ display: "flex", flexDirection: "row", width: "100%"}}
    >
      
        <TableCellLayout
          truncate
        >
          <Tooltip content={item.name} relationship="description" showDelay={1000} positioning={'below'}>
            <Link appearance="subtle" onClick={onClickEvent}>
              {item.name}
            </Link>
          </Tooltip>
        </TableCellLayout>
        {hovered && (
          <div style={{ display: "flex", flexDirection: "row"}}>
            {item.type !== 'folder' && (
              <ToolbarButton onClick={(e) => handleShare(e, item)} aria-label="Share" icon={<ShareRegular />} />
            )}
            <Menu>
              <MenuTrigger>
                <ToolbarButton aria-label="More" icon={<MoreHorizontalRegular />} />
              </MenuTrigger>
    
              <MenuPopover>
                <MenuList>
                  {/* {item.type !== 'folder' ? (
                    <> */}
                      <MenuItem onClick={onClickEvent}>{t('storages.open')}</MenuItem>
                      <MenuItem onClick={(e) => handleShare(e, item)}>{t('storages.share')}</MenuItem>
                      {item.type !== 'folder' ? (
                        <MenuItem onClick={(e) => handleDownload(e, [item.id])}>{t('storages.download')}</MenuItem>
                      ) : null}
                    {/* </>
                  ) : null} */}
                  <MenuItem onClick={(e) => handleDeleteConfirm(e, [item.id])}>{t('storages.delete')}</MenuItem>
                </MenuList>
              </MenuPopover>
            </Menu>
          </div>
        )}
      
    </div>)
  }

  function TextFieldSearch() {
    const [search, setSearch] = useState('')
    const [data, setData] = useState([])
    const [isLoadData, setIsLoadData] = useState(false)

    const [cancelTokenSource, setCancelTokenSource] = useState(null);

    useEffect(() => {
      if (!search) return;

      // const source = axios.CancelToken.source()
      getOptions(search)

      // return () => source.cancel()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    async function getOptions(value) {
      setIsLoadData(true)

      if (cancelTokenSource) {
        cancelTokenSource.cancel('New request initiated');
      }
  
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      try {
        const tokenResponse = await instance.acquireTokenSilent({ ...silentRequest, account: account })

        const response = await axios
        .get(
          'http://localhost:7071/api/OneDrive_searchItem',
          // 'http://localhost:7071/api/AwsObjectsAllList',
          // API_URL + '/storages/s3/objects/all', 
          {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              cid: companyId
            },
            params: {
              search: value,
              // driveId: selectedDriveId.id,
              id: selectedDriveId
            },
            // cancelToken: source.token
            cancelToken: newCancelTokenSource.token,
          }
        );

        console.log('OneDrive_searchItem response', response);

        const _value = response.data?.value || []
        
        setData(_value.map(_content => {
          const { _file, _type } = getTypeFile(_content)

          return {..._content, file: _file, type: _type}
        }))

        // const { contents } = response.data

        // const _contents = contents.map(_content => ({..._content, Key: _content.Key.replace('.file_placeholder', '')}))
        // const _filterContents = _contents.filter(_content => _content.Key.includes(value))

        // const _data = _filterContents.map(_content => {
        //   if (_content.Key.includes('/')) {
        //     const _split = _content.Key.split('/')
        //     if (_split[_split.length - 1]) {
        //       return {
        //         type: getTypeFile(_split[_split.length - 1], _content.Size),
        //         Key: _split[_split.length - 1],
        //         key: _split[_split.length - 1],
        //         path: _content.Key
        //       }
        //     } else {
        //       return {
        //         type: getTypeFile(_split[_split.length - 2], _content.Size),
        //         Key: _split[_split.length - 2],
        //         key: _split[_split.length - 2],
        //         path: _content.Key
        //       }
        //     }
        //   } else {
        //     return {
        //       type: getTypeFile(_content.Key, _content.Size),
        //       Key: _content.Key,
        //       key: _content.Key,
        //       path: _content.Key
        //     }
        //   }
        // })

        // const _filterData = _data.filter(_content => _content.Key.includes(value))

        // setData(_filterData)

        setIsLoadData(false)
        
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else {
          console.log("err", error)
  
          setDialog({
            open: false,
            loading: false,
            headers: '',
            value: '',
            onSubmit: null,
            onCancel: null,
            content: function () {
              return null
            }
          })
          
          setAlert(prev => ({...prev, open: true, status: 'error', message: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'}))
        }

        setIsLoadData(false)
      }
      
    }

    function ActionOption({data}) {
      const [hovered, setHovered] = useState(false)

      return <div style={{ 
          display: "flex", 
          flexDirection: "row", 
          width: "95%", 
          height: 44, 
          justifyContent: "space-between", 
          padding: "0px 8px",
          ...(hovered ? { backgroundColor: "#edebe9"} : {}),
          // marginRight: 10
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)} 
        key={data.id}
      >
        <div 
          style={{ display: "flex", flexDirection: "row" }} 
          onClick={() => {
            handleClickLinkBySearch('name', data)
            setSearch("")
          }}
        >
          <TableCellLayout truncate media={data.file} />
          <div>
            <Link appearance="subtle" style={{ padding: "12px 8px", width: 250, whiteSpace: 'nowrap',  overflow: 'hidden', display: 'inline-block', textOverflow: "ellipsis" }}>
              {data.name}
              {/* <label style={{ textOverflow: "ellipsis", width: 200, whiteSpace: 'nowrap',  overflow: 'hidden' }}>

              </label> */}
            </Link>
          </div>
        </div>
        {hovered && (
        <div style={{ display: "flex", flexDirection: "row"}}>
          {data.type !== 'folder' && (
            <ToolbarButton onClick={(e) => handleShare(e, data)} aria-label="Share" icon={<ShareRegular />} />
          )}
          <Menu>
            <MenuTrigger>
              <ToolbarButton aria-label="More" icon={<MoreHorizontalRegular />} />
            </MenuTrigger>
  
            <MenuPopover>
              <MenuList>
                {/* {data.type._type !== 'folder' ? (
                  <> */}
                    <MenuItem onClick={() => handleClickLinkBySearch('name', data)}>{t('storages.open')}</MenuItem>
                    <MenuItem onClick={(e) => handleShare(e, data)}>{t('storages.share')}</MenuItem>
                    {data.type !== 'folder' ? (
                      <MenuItem onClick={(e) => handleDownload(e, [data.id])}>{t('storages.download')}</MenuItem>
                    ) : null}
                  {/* </>
                ) : null} */}
                <MenuItem onClick={(e) => handleDeleteConfirm(e, [data.id])}>{t('storages.delete')}</MenuItem>
              </MenuList>
            </MenuPopover>
          </Menu>
        </div>
      )}
      </div>
    }
  
  const buttonRef = useRef(null);
  const positioningRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current) {
      positioningRef.current?.setTarget(buttonRef.current);
    }
  }, [buttonRef, positioningRef]);

  // const [focused, setFocused] = useState(false)
  // const onFocus = () => setFocused(true)
  // const onBlur = () => setFocused(false)


  return <Field /* onFocus={onFocus} onBlur={onBlur} */ ref={buttonRef} onChange={e => setSearch(e.target.value)} style={{ width: mainDiv.current.offsetWidth * 0.3}}>
    <Input contentBefore={<SearchRegular />} contentAfter={search ? <DismissRegular onClick={() => setSearch("")} /> : null } value={search} placeholder='ค้นหา' />
    <Popover open={search !== ""} positioning={{ positioningRef, position: "below" }} >
      <PopoverSurface style={{ width: mainDiv.current.offsetWidth * 0.3, padding: 0}}>
        {isLoadData ? <Spinner labelPosition="below" style={{ padding: "12px 0px"}} /* label={t('storages.loading')} */ /> : (
          data.map((_data, index) => (
            <ActionOption data={_data} key={`option-${index}`} />
          ))
        )}
      </PopoverSurface>
    </Popover>
  </Field>
  }

  function getDriveName(driveId) {
    let name = null

    if (driveId) {
      const drive = drives.find(_drive => _drive.id === driveId)
      if (drive) {
        if (drive.type === 'oneDrive') {
          if (drive.name && drive.owner.user.email) {
            name = `${drive.name} (${drive.owner.user.email})`
          } else {
            name = `${drive.owner.user.displayName}`
          }
    
        } else if (drive.type === 'googleDrive') {
          if (drive.user) {
            name = `${drive.user.displayName} (${drive.user.emailAddress})`
          }
    
        } else if (drive.type === 's3') {
          name = drive.bucket
        }

      }
    }

    return name
  }
  
    return <div ref={mainDiv} style={{ padding: 30, width: "100%", height: window.screen.height, backgroundColor: "white", borderLeft: "1px solid #e0e0e0"}}>
      {alert.open ? (
        <>
          <Alert intent={alert.status} action={{ onClick: () => { setAlert(prev => ({...prev, open: false})) }, icon: <DismissCircleRegular />,
          "aria-label": "dismiss message", }}>{alert.message}</Alert>
          <br />
        </>
      ) : null}
      <div>
          <Title2 as='h2'>
            <CloudBidirectionalRegular /> {t('storages.header')}
            <Switch label={t('storages.automaticBackup')} checked={backup} onClick={handleEnableBackup} /* onChange={(e, data) => console.log(data)} */ />
            <div style={{ float: 'right'}}>
              <Menu>
                <MenuTrigger disableButtonEnhancement>
                  <MenuButton appearance="transparent" icon={<StorageRegular />}>
                    {/* {selectedDrive.name} */}
                    {getDriveName(selectedDriveId)}
                  </MenuButton>
                </MenuTrigger>

                <MenuPopover>
                  <MenuList>
                    {drives.map((_drive, index) => (
                      <MenuItem key={'drive' + (index + 1)} icon={<StorageRegular />} onClick={() => {
                        setIsLoading(true)

                        if (_drive.type === 'oneDrive') {
                          setSelectedDriveId(_drive.id)

                        } else if (_drive.type === 'googleDrive') {
                          history.push({
                            pathname: '/storages/google-drive',
                            state: { 
                              id: _drive.id,
                            },
                          });
                        } else if (_drive.type === 's3') {
                          history.push({
                            pathname: '/storages',
                            state: { 
                              id: _drive.id,
                            },
                          });
                        }
                    }}>{getDriveName(_drive.id)}</MenuItem>
                    ))}
                    {drives.length ? (
                      <Divider />

                    ) : null}
                    <MenuItem key={'newDrive'} icon={<AddRegular />} onClick={() => setDialogConnectNewDrive(prev => ({...prev, open: true}))}>{t('storagesConnect.ConnectAnotherDrive')}</MenuItem>
                    {/* <MenuItem>Item b</MenuItem> */}
                  </MenuList>
                </MenuPopover>
              </Menu>
              
            </div>
          </Title2>
      </div>
      <br />
      
      {isLoading ? (
        <>
          <br />
          <br />
          <Spinner labelPosition="below" label={t('storages.loading')} />
        </>
      ) : (
        <>
        <Divider />
        {/* <br /> */}
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "6px 0px"}}>
          <div style={{ display: "flex"}}>

          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <MenuButton appearance="primary" icon={<ArrowUploadRegular />}>
                {t('storages.upload')}
              </MenuButton>
            </MenuTrigger>

            <MenuPopover>
              <MenuList>
                <MenuItem onClick={() => fileInputRef.current.click()}>{t('storages.file')}</MenuItem>
                {/* <MenuItem>Item b</MenuItem> */}
              </MenuList>
            </MenuPopover>
          </Menu>
        

          {selectedItemsKey.length ? (
            <>
              {selectedItemsKey.length === 1 ? (
                <Button appearance="transparent" icon={<ShareIosRegular />} onClick={(e) => handleShare(e, items.find(item => item.key === selectedItemsKey[0]))} >
                  {t('storages.share')}
                </Button>
              ) : null}
                <Button appearance="transparent" icon={<ArrowDownloadRegular />} onClick={(e) => handleDownload(e, selectedItemsKey)} >
                  {t('storages.download')}
                </Button>
                <Button appearance="transparent" icon={<DeleteRegular />} onClick={(e) => handleDeleteConfirm(e, selectedItemsKey)} >
                  {t('storages.delete')}
                </Button>
              </>
            ) : (
            <>
              <Menu>
                <MenuTrigger disableButtonEnhancement>
                  <MenuButton appearance="subtle" icon={<AddRegular />}>
                    {t('storages.new')}
                  </MenuButton>
                </MenuTrigger>

                <MenuPopover>
                  <MenuList>
                    <MenuItem 
                      onClick={() => 
                        setDialog(prev => 
                          ({...prev, 
                            headers: t('storages.newFolder'),
                            value: '',
                            open: true,
                            onSubmit: (e, type, value) => upload(e, type, value),
                            onCancel: () => setDialog({
                              open: false,
                              headers: '',
                              value: '',
                              onSubmit: null,
                              onCancel: null,
                              content: function () { return null }
                            }),
                            content: function () { 
                              return this.loading ? 
                                      (<Spinner labelPosition='below' label={t('storages.creating')} /> ) 
                                      : 
                                      (<Field
                                        label={t('storages.creating')}
                                        // validationState="success"
                                        // validationMessage="This is a success message."
                                      >
                                        <Input value={this.value} onChange={(e, data) => {
                                          setDialog(prev => ({...prev, value: data.value}))
                                        }} />
                                      </Field>)
                            }, 
                            onSubmitLabel: t('storages.create'),
                            onCancelLabel: t('storages.cancel')
                          }))
                      }>{t('storages.folder')}</MenuItem>
                    {/* <MenuItem>Item b</MenuItem> */}
                  </MenuList>
                </MenuPopover>
              </Menu>
              {/* <Button appearance="transparent" icon={<ShareRegular />} onClick={(e) => {
                  // setTabListValue(['Doc'])
                  // setIsLoading(true)
                  // setItems([])
                  // setContents([])
                  // setSelectedItems([])
                  // setSelectedItemsKey([])
                  // setTabIndex(0)
                  // getNewItemsInRootFolder()
                }}>
                {t('storages.share')}
              </Button> */}
              <Button appearance="transparent" icon={<ArrowClockwiseRegular />} onClick={(e) => {
                  setTabListValue(['Doc'])
                  setIsLoading(true)
                  setItems([])
                  setSelectedItems([])
                  setSelectedItemsKey([])
                  setTabIndex(0)
                  getNewItemsInRootFolder()
                }}>
                {t('documents.refresh')}
              </Button>
              <Menu>
                <MenuTrigger>
                  <ToolbarButton aria-label="More" icon={<MoreHorizontalRegular />} />
                </MenuTrigger>

                <MenuPopover>
                  <MenuList>
                    <MenuItem onClick={handleDisconnect}>{t('storages.disconnect')}</MenuItem>
                  </MenuList>
                </MenuPopover>
              </Menu>
              </>
              
            )}
          </div>
          <TextFieldSearch />
        </div>
        {/* <br /> */}
        <Divider />
        {/* <br /> */}
        <div className={styles.wrapper}>
          <TabList onTabSelect={(e, data) => {
            console.log('tab list data', data)
            setTabIndex(data.value)
            removePathValue(data.value)

            setLoadingLink(true)

            let _prefix = ''
            for (let i = 0; i <= data.value; i++) {
              // console.log('tabListValue[i]', i, tabListValue[i])
              _prefix = _prefix + (tabListValue[i] === "Doc" ? '' : '/' + tabListValue[i])
              // if (i !== 0 && i !== data.value) {
              //   _prefix = '/' + _prefix
              // }
            }

            console.log('_prefix', _prefix)

            getNewItemsInFolderByPath(_prefix)
          }}
            selectedValue={tabIndex}
          >
            {tabListValue.map((_str, index) => (
              <Fragment key={_str + index}>
                <Tab value={index}>{_str === "Doc" ? t("storages.documents") : _str}</Tab>
                {index !== tabListValue.length - 1 && (
                  <div style={{ paddingTop: 15}}>
                    <ChevronRightRegular />
                  </div>
                )}
              </Fragment>
            ))}
          </TabList>
        </div>
        <br />
        {loadingLink ? (
          <>
            <br />
            <br />
            <Spinner labelPosition="below" label={t('storages.loading')} />
          </>
        ) : (
        <InfiniteScroll
          style={{ padding: 10}}
          dataLength={items.length}
          // next={() => {
          //   if (moreContent.loading) return;
          //   return getNewContentsNext(moreContent)
          // }}
          // hasMore={moreContent.loading ? false : moreContent.IsTruncated}
          // loader={
          //   <>
          //     <br />
          //     <Spinner labelPosition='below' label={t('storages.downloading')} /> 
          //     <br />
          //   </>
          // }
        >
          <DataGrid
            items={items}
            columns={columns}
            sortable
            focusMode='none'
            getRowId={(item) => item.key}
            selectionMode="multiselect"
            onSelectionChange={(e, data) => {
              // console.log(data)
              // data.selectedItems.forEach(value => console.log(value))
              if (e.target.localName === 'input') {
                const _selectedItemsKey = []

                // console.log('data', data)
                data.selectedItems.forEach(_value => _selectedItemsKey.push(_value))
                setSelectedItemsKey(_selectedItemsKey)
                setSelectedItems(data.selectedItems)
              }
            }}
            selectedItems={selectedItems}
            resizableColumns
            columnSizingOptions={{
              file: {
                minWidth: 20,
                defaultWidth: 20,
              },
              name: {
                defaultWidth: mainDiv.current.offsetWidth * 0.4,
                minWidth: 320,
                // idealWidth: 320,
              },
              modified: {
                defaultWidth: mainDiv.current.offsetWidth * 0.2,
                minWidth: 180,
                // idealWidth: 180,
              },
              size: {
                defaultWidth: mainDiv.current.offsetWidth * 0.2,
                minWidth: 180,
                // idealWidth: 180,
              },
            }}
            onColumnResize={(event, { columnId, width }) => {
              if (event) {
                console.log(event.offsetX, event.offsetY, columnId, width);
              }
            }}
          >
            <DataGridHeader>
              <DataGridRow selectionCell={{ "aria-label": "Select all rows" }}>
                {({ renderHeaderCell }) => (
                  <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                )}
              </DataGridRow>
            </DataGridHeader>
            <DataGridBody>
              {({ item, rowId }) => (
                <DataGridRow
                  key={rowId}
                  selectionCell={{ "aria-label": "Select row" }}
                
                >
                  {({ renderCell, columnId }) => (
                    <DataGridCell key={rowId + columnId}>{renderCell(item)}</DataGridCell>
                  )}
                </DataGridRow>
              )}
            </DataGridBody>
          </DataGrid>
        </InfiniteScroll>
        )}
        <Dialog open={dialog.open}>
          <DialogSurface>
            <DialogBody>
              <DialogTitle>{dialog.headers}</DialogTitle>
              <DialogContent>
                {dialog.content()}
              </DialogContent>
              <DialogActions>
                {dialog.onSubmitLabel ? (
                  <Button appearance="primary" onClick={(e) => dialog.onSubmit(e, 'Folder', dialog.value)}>{dialog.onSubmitLabel}</Button>
                  ) : null}
                {dialog.onCancelLabel ? (
                  <DialogTrigger disableButtonEnhancement>
                    <Button appearance="secondary" onClick={() => 
                      dialog.onCancel()
                      }>{dialog.onCancelLabel}</Button>
                  </DialogTrigger>
                ) : null}
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
        
        <input onChange={handleUploadFile} multiple={false} ref={fileInputRef} type='file' hidden/>
        </>
      )}
      <Dialog open={dialogConnectNewDrive.open}>
          <DialogSurface style={{ width: 350 }}>
            <DialogBody>
              <DialogTitle>{t('storagesConnect.ConnectAnotherBucket')}</DialogTitle>
              <DialogContent>
                {dialogConnectNewDrive.loading ? (
                  <Spinner labelPosition='below' label={t('storages.uploading')} /> 
                ) : (
                  dialogConnectNewDrive.status === "buckets" ? (
                    <>
                      <div className={styles.root}>
                        <label id={`${dropdownId}-selectBucket`}>{t('storagesConnect.selectBucket')}</label>
                        <Dropdown
                          aria-labelledby={`${dropdownId}-selectBucket`}
                          placeholder={t('storagesConnect.selectBucket')}
                        >
                          {dialogConnectNewDrive.buckets.map((option) => (
                            <Option key={option.Name} onClick={e => {
                              setDialogConnectNewDrive(prev => ({ ...prev, selectedBucket: option.Name}))
                              
                            }}>
                              {option.Name}
                            </Option>
                          ))}
                        </Dropdown>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={styles.root}>
                        <label id={`${dropdownId}-storage`}>Storage</label>
                        <Dropdown
                          aria-labelledby={`${dropdownId}-storage`}
                          placeholder={t('storagesConnect.selectStorage')}
                        >
                          {storageOptions.map((option) => (
                            <Option key={option.key} onClick={e => {
                              setDialogConnectNewDrive(prev => ({ ...prev, storage: option.key}))
                              
                              if (option.key === 'amazon') {
                                setOptionsEndpointS3(endpointAmazonS3); 
                                setDialogConnectNewDrive(prev => ({ ...prev, endpoint: 's3.ap-southeast-1.amazonaws.com'}))
                              } else if (option.key === 'wasabi') {
                                setOptionsEndpointS3(endpointWasabiS3); 
                                setDialogConnectNewDrive(prev => ({ ...prev, endpoint: 's3.ap-southeast-1.wasabisys.com'}))
                              } else if (option.key === 'storj') {
                                setOptionsEndpointS3([{key: "gateway.storjshare.io", text: "storjshare-gateway"}]); 
                                setDialogConnectNewDrive(prev => ({ ...prev, endpoint: 'gateway.storjshare.io'}))
                              }
                            }}>
                              {option.text}
                            </Option>
                          ))}
                        </Dropdown>
                      </div>
                      {dialogConnectNewDrive.storage !== 'googleDrive' && dialogConnectNewDrive.storage !== 'oneDrive' ? (
                        <>
                          <div className={styles.root}>
                            <label id={`${dropdownId}-region`}>Region</label>
                            <Dropdown
                              aria-labelledby={`${dropdownId}-region`}
                              placeholder={t('storagesConnect.selectRegion')}
                            >
                              {optionsEndpointS3.map((option) => (
                                <Option key={option.key} onClick={e => {
                                  setDialogConnectNewDrive(prev => ({ ...prev, region: option.text, endpoint: option.key}))
                                  
                                }}>
                                  {option.text}
                                </Option>
                              ))}
                            </Dropdown>
                          </div>
                          <Field
                            label="Access Key Id"
                          >
                            <Input value={dialogConnectNewDrive.accessKeyId} onChange={(e, data) => setDialogConnectNewDrive(prev => ({ ...prev, accessKeyId: data.value}))} />
                          </Field>
                          <Field
                            label="Secret Access Key"
                          >
                            <Input value={dialogConnectNewDrive.secretAccessKey} onChange={(e, data) => setDialogConnectNewDrive(prev => ({ ...prev, secretAccessKey: data.value}))} />
                          </Field>
                        </>
                      ) : null}
                    </>
                  )
                  
                )}
              </DialogContent>
              <DialogActions>
                {dialogConnectNewDrive.loading ? null : (
                  dialogConnectNewDrive.status === "buckets" ? (
                     
                      <Button appearance="primary" onClick={(e) => {
                        setDialogConnectNewDrive(prev => ({...prev, loading: true}))
      
                        instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
                          axios
                            .get(
                              'http://localhost:7071/api/AWS_listObjects',
                              {
                                headers: {
                                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                                  cid: companyId
                                },
                                params: {
                                  accessKeyId: dialogConnectNewDrive.accessKeyId,
                                  secretAccessKey: dialogConnectNewDrive.secretAccessKey,
                                  storage: dialogConnectNewDrive.storage,
                                  region: dialogConnectNewDrive.region,
                                  bucket: dialogConnectNewDrive.selectedBucket,
                    
                                  // ContinuationToken: moreContent.ContinuationToken,
                                  // StartAfter: moreContent.StartAfter
                                }
                              }
                            )
                            .then((response) => {
                              
                              console.log('response', response);
                    
                              if (response.data.success) {
                    
                                axios
                                  .post('http://localhost:7071/api/Storages', {
                                    type: 's3',
                                    accessKeyId: dialogConnectNewDrive.accessKeyId,
                                    secretAccessKey: dialogConnectNewDrive.secretAccessKey,
                                    storage: dialogConnectNewDrive.storage,
                                    region: dialogConnectNewDrive.region,
                                    bucket: dialogConnectNewDrive.selectedBucket
                                  }, {
                                    headers: {
                                      // Authorization: 'Bearer ' + tokenResponse.accessToken,
                                      // 'cid': companyId
                                    },
                                  }).then(res => {
                                    history.push({
                                      pathname: '/storages',
                                      state: {
                                        id: dialogConnectNewDrive.selectedBucket
                                      },
                                    });

                                    
                                  })
                              }
                    
                              setDialogConnectNewDrive(prev => ({...prev, loading: false}))
                            });
                            
                        })
                          .catch((error) => {
                            //Acquire token silent failure, and send an interactive request
                            console.log(error);
                            instance.acquireTokenRedirect(silentRequest);
                          });
                      }}>{t('storagesConnect.connect')}</Button>
                    
                    
                  ) : (
                    dialogConnectNewDrive.storage === 'googleDrive' ? (
                      <Button appearance="primary" onClick={() => window.location.replace(callBackUrlGoogleDrive)}>{t('storagesConnect.connect')}</Button>
                    ) : dialogConnectNewDrive.storage === 'oneDrive' ? (
                      <Button appearance="primary" onClick={() => window.location.replace(callBackUrlMSOneDrive)}>{t('storagesConnect.connect')}</Button>
                    ) :
                    <Button appearance="primary" onClick={(e) => {
                      setDialogConnectNewDrive(prev => ({...prev, loading: true}))
    
                      instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
                        axios
                          .post(
                              'http://localhost:7071/api/AWS_connect',
                            // 'http://localhost:7071/api/AwsConnectionsCheck',
                            // API_URL + '/storages/s3/connections/check',
                            {
                              accessKeyId: dialogConnectNewDrive.accessKeyId,
                              secretAccessKey: dialogConnectNewDrive.secretAccessKey,
                              // storage: storage,
                              region: dialogConnectNewDrive.region,
                              endpoint: dialogConnectNewDrive.endpoint
                            },
                            {
                              headers: {
                                Authorization: 'Bearer ' + tokenResponse.accessToken,
                                cid: companyId
                              },
                            }
                          )
                          .then((response) => {
                  
                            console.log('response', response);
                  
                            if (response.data.success) {
                              if (response.data.Buckets.length) {
                                // setStatus('buckets')
                                // setBuckets(response.data.Buckets)
                                // setSelectedBucket(response.data.Buckets[0].Name)
    
                                setDialogConnectNewDrive(prev => ({...prev, 
                                  loading: false, 
                                  status: 'buckets',
                                  buckets: response.data.Buckets,
                                  selectedBucket: response.data.Buckets[0].Name
                                }))
                              }
                              // setStatus("success");
                            } else {
                              // setStatus("fail");
    
                              setDialogConnectNewDrive(prev => ({...prev, 
                                loading: false, 
                                status: 'fail',
                                // buckets: response.data.Buckets,
                                // selectedBucket: response.data.Buckets[0].Name
                              }))
                            }
                  
                            // setIsLoading(false);
                          });
                      }).catch((error) => {
                        //Acquire token silent failure, and send an interactive request
                        console.log(error);
                        instance.acquireTokenRedirect(silentRequest);
                      });
                    }}>{t('storagesConnect.connect')}</Button>
                  )

                )}
                
                
                <DialogTrigger disableButtonEnhancement>
                  <Button appearance="secondary" onClick={() => 
                    setDialogConnectNewDrive({
                      open: false,
                      loading: false,
                      status: '',
                      storage: '',
                      region: '',
                      endpoint: '',
                      accessKeyId: '',
                      secretAccessKey: '',
                      bucket: '',
                      buckets: [],
                      selectedBucket: '',
                    })
                  }>{t('storagesConnect.cancel')}</Button>
                </DialogTrigger>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
    </div>
}

export default OneDriveStorages;