import { useAccount, useMsal } from '@azure/msal-react';
import { silentRequest } from '../../../../authConfig';
import { Icon, Image, Spinner, SpinnerSize, Stack, TextField, getTheme, mergeStyleSets } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const theme = getTheme();

const controlClass = mergeStyleSets({
  control: {
    margin: '0 0 15px 0',
    maxWidth: '300px',
  },
  hover: {
    selectors: {
      '&:hover': { background: theme.palette.neutralLight },
    },
  },
});

const contactListStyle = {
  background: 'white',
  border: '1px solid #eee',
  boxShadow: '0 2px 3px #ccc',
  boxSizing: 'border-box',
  maxHeight: 200,
  width: "100%",
  position: 'absolute',
  overflow: 'auto',
  zIndex: 2,
  top: '100%'
};

const contactOptionStyle = {
  padding: '0px 8px',
  paddingTop: '8px',
  height: 26,
  cursor: 'pointer',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

// const autoCompleteOptions = [
//   { key: 'apple', text: 'Apple' },
//   { key: 'banana', text: 'Banana' },
//   { key: 'cherry', text: 'Cherry' },
//   { key: 'date', text: 'Date' },
//   // Add more options as needed
// ];

const API_URL = process.env.REACT_APP_API_URL;
const REACT_APP_IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const AutocompleteTextField = (props) => {
  const { name, label, componentRef, autoComplete, styles, maxLength, 
    value, onGetErrorMessage, validateOnLoad, onChangeFunction, 
    index, description, items, setState } = props

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const { t } = useTranslation();

  const companyId = sessionStorage.getItem('companyId') || '';

  const [isLoading, setIsLoading] = useState(false);

  const [showSuggestions, setShowSuggestions] = useState(false)
  const [dropdownList, setDropdownList] = useState([])

  const [autoCompleteOptions, setAutoCompleteOptions] = useState([])

  async function handleAutoComplete(value) {
    if (!value) {
      setShowSuggestions(false);

      setDropdownList(autoCompleteOptions);

      setIsLoading(false)
    } else {
      setShowSuggestions(true);

      setIsLoading(true)

      // const activeProducts = autoCompleteOptions
      //                           .filter(_product => _product.data.name.includes(value) || 
      //                           _product.data.skus.some(_variant => _variant.sellerSku.includes(value)))

      // setDropdownList(activeProducts);

      const tokenResponse = await instance.acquireTokenSilent({ ...silentRequest, account: account })
  
      const response = await axios.get(`${API_URL}/products?search=${value}`, {
                                          headers: {
                                            Authorization: 'Bearer ' + tokenResponse.accessToken,
                                            'Content-Type': 'application/json',
                                            'cid': companyId
                                          }
                                        });

      console.log("response product", response.data)
  
      const { products } = response.data.data

      const activeProducts = products.filter(_product => _product.data.status === "active")

      setDropdownList(activeProducts);
      setIsLoading(false)
    }
  }

  function handleSelect(e, product, variantIndex, index) {
    const {
      name,
      skus,
    } = product.data

    const {
      sellerSku,
      variation,
      price,
      unitCode,
      unitName
    } = skus[variantIndex]

    const values = [...items];

    values[index].number = index + 1 + ""
    values[index].description = name + " " + (variation[0] ? "(" + variation[0].value : "") + (variation[1] ? ", " + variation[1].value + ")" : (variation[0] ? ")" : ""))
    values[index].quantity = 1
    values[index].unitCode = unitCode
    values[index].unitName = unitName
    values[index].price = Number(price)
    values[index].total = Number(price)
    values[index].sku = sellerSku

    setState(values);

    setDropdownList(autoCompleteOptions)
  }

  // useEffect(() => {
  //   async function fetchDataLoop() {
  //     setIsLoading(true)
  
  //     let offset = 0;
  //     let accumulatedData = [];
      
  //     while (true) {
  //       const tokenResponse = await instance.acquireTokenSilent({ ...silentRequest, account: account })
  
  //       const response = await axios.get(`${API_URL}/products?offset=${offset}&limit=${100}&status=${"active"}`, {
  //                                         headers: {
  //                                           Authorization: 'Bearer ' + tokenResponse.accessToken,
  //                                           'Content-Type': 'application/json',
  //                                           'cid': companyId
  //                                         }
  //                                       });
  
  //       const { count, countTotal, products } = response.data.data

  //       // console.log("product", response.data.data)
  
  //       setAutoCompleteOptions(prev => [...prev, ...products])
  
  //       setDropdownList(prev => {
  //         const updatedPrev = [...prev, ...products];
  
  //         const activeProducts = [...updatedPrev]
  //         if (value) {
  //           activeProducts = updatedPrev.filter(_product => 
  //                                                 _product.data.name.includes(value) || 
  //                                                 _product.data.skus.some(_variant => _variant.sellerSku.includes(value))
  //                                               )
  //         }
  
  //         return activeProducts;
  //       });
  
  //       setIsLoading(false)
        
  //       accumulatedData = accumulatedData.concat(products);
  //       offset += count;
        
  //       if (offset >= countTotal) {
  //         // If there's no next page, we've reached the end of available data
  //         break;
  //       }
        
  //     }
      
  //     return {
  //       count: offset,
  //       data: accumulatedData
  //     };
  //   }

  //   fetchDataLoop()
  // }, [])

  useEffect(() => {
    setIsLoading(true)

    instance
    .acquireTokenSilent({ ...silentRequest, account: account })
    .then((tokenResponse) => {
      axios
      .get(
        `${API_URL}/products?offset=${0}&limit=${100}&status=${"active"}`, 
        {
          headers: {
            Authorization: 'Bearer ' + tokenResponse.accessToken,
            'Content-Type': 'application/json',
            'cid': companyId
          },
          // cancelToken: source.token
        }
      ).then(response => {
        console.log("product", response.data.data)
        const { products } = response.data.data

        setAutoCompleteOptions(products)

        setDropdownList(products);

        setIsLoading(false)
      })
    }).catch((error) => {
      //Acquire token silent failure, and send an interactive request
      console.log(error);
      instance.acquireTokenRedirect(silentRequest);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  const handleTextFieldBlur = () => {
    setShowSuggestions(false);
  };

  const iconStyles = {
    root: {
      cursor: 'pointer',
      color: showSuggestions ? 'blue' : 'black', // Example: Change color based on list visibility
      // Add more custom styles as needed
    },
  };

  const autocompleteRef = useRef(null);

  const handleOutsideClick = event => {
    // Close the list when clicking outside the AutocompleteTextField
    if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      // Detach the event listener when the component unmounts
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div style={{ position: 'relative' }} ref={autocompleteRef}>
      <TextField
        name={name}
        label={label}
        componentRef={componentRef}
        styles={styles}
        maxLength={maxLength}
        onChange={(event) => {
          onChangeFunction(index, event)
          if (event.target.value) {
            setShowSuggestions(true);
          } else {
            setShowSuggestions(false);
          }

          handleAutoComplete(event.target.value)
        }}
        autoComplete={autoComplete}
        value={value}
        description={showSuggestions ? '' : description ? description : ''}
        onGetErrorMessage={onGetErrorMessage}
        validateOnLoad={validateOnLoad}
        validateOnFocusOut
        required
        onBlur={handleTextFieldBlur}
        suffix={
          <Icon
            iconName="ChevronDown"
            styles={iconStyles}
            onClick={() => setShowSuggestions(prev => !prev)}
            onBlur={handleTextFieldBlur}
          />
        }
        placeholder={showSuggestions ? t("autocompleteTextField.placeholder") : ""}
      />
      {showSuggestions && dropdownList[0] !== undefined && (
        <div style={contactListStyle}>
          {isLoading ? (
            <Stack verticalAlign="center" styles={{ root: { height: 30.4 }}}>
              <Spinner size={SpinnerSize.medium} />
            </Stack>
          ) : (
            dropdownList.map((_product, indexList) => {
              return _product.data.skus.map((_variant, indexVariant) => (
                (!items.some(_item => _item.sku && _item.sku === _variant.sellerSku) && _variant.status === "active") ? 
                  <div
                      key={_product.id + "" + indexVariant}
                      id={_product.id + "" + indexVariant}
                      className={controlClass.hover}
                      style={contactOptionStyle}
                      onMouseDown={(e) => {
                          handleSelect(e, _product, indexVariant, index)
                          setShowSuggestions(false)
                      }}
                  >
                    <Stack horizontal tokens={{childrenGap: 10}}>
                      {_product.data.variations.length ? (
                        _variant.image ? (
                          <Image src={REACT_APP_IMAGE_URL + '/products/' + _variant.image} height={26} width={26} styles={{ root: { marginRight: 10 }}} />
                        ) : null
                      ) : (
                        _product.data.images.length ? (
                          <Image src={REACT_APP_IMAGE_URL + '/products/' + _product.data.images[0]} height={26} width={26} styles={{ root: { marginRight: 10 }}} />
                        ) : null
                      )}
                    
                    {/* <span> */}
                      {_product.data.name} {(_variant.variation[0] ? "(" + _variant.variation[0].value : "") + (_variant.variation[1] ? ", " + _variant.variation[1].value + ")" : (_variant.variation[0] ? ")" : ""))} {_variant.sellerSku}

                    {/* </span> */}

                    </Stack>
                  </div>
                : null
              ))
            })

          )}
        </div>
      )}
      {/* {showSuggestions && !dropdownList.length && (
        <div style={contactListStyle}>
          {isLoading ? (
            <Stack verticalAlign="center" styles={{ root: { height: 30.4 }}}>
              <Spinner size={SpinnerSize.medium} />
            </Stack>
          ) : (
            <div
                key={"notfound"}
                id={"notfound"}
                className={controlClass.hover}
                style={contactOptionStyle}
            >
              <Stack horizontal tokens={{childrenGap: 10}}>
              
                ไม่พบสินค้า

              </Stack>
            </div>

          )}
        </div>
      )} */}
    </div>
  );
};

export default AutocompleteTextField;
