import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import paginate from 'jw-paginate';

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  Selection,
  SelectionMode,
  Calendar,
  Icon,
  DateRangeType,
  Spinner,
  SpinnerSize,
  SearchBox,
  CommandBar,
  Stack,
  PrimaryButton,
  DefaultButton,
  TextField,
  ContextualMenu,
  CheckboxVisibility,
  Dialog,
  DialogType,
  DialogFooter,
} from '@fluentui/react';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

const API_URL = process.env.REACT_APP_API_URL;

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

const SAPBusinessOne = () => {
  console.log('Home render!!!');

  const history = useHistory();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const companyId = sessionStorage.getItem('companyId') || '';

  const { t, i18n } = useTranslation();

  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(false);
  const [pageData, setPageData] = useState({});
  const [pageSize, setPageSize] = useState(sessionStorage.getItem('sap-b1-pageSize') * 1 || 100);
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('sap-b1-currentPage') * 1 || 1);
  const [invoiceType, setInvoiceType] = useState(
    (sessionStorage.getItem('sap-b1-invoiceType-key') && {
      key: sessionStorage.getItem('sap-b1-invoiceType-key'),
      text: sessionStorage.getItem('sap-b1-invoiceType-text'),
    }) || {
      key: 'all',
      text: t('sapB1.allType'),
    }
  );

  const [company, setCompany] = useState('');
  const [search, setSearch] = useState('');

  const percentVat = 7;
  const zeroVat = 0;
  const nonVat = -1;

  const percentVatText = '7%';
  const zeroVatText = '0%';
  // const nonVatText = '-';

  const dateType = { key: 'document', text: 'วันที่บนเอกสาร' };

  const [isLoading, setIsLoading] = useState(false);

  const isCompactMode = false;

  const [isCreateDocument, setIsCreateDocument] = useState(false);
  const [isCreateDocumentComplete, setIsCreateDocumentComplete] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        console.log('isCreateDocument: ', isCreateDocument);
        console.log('isCreateDocumentComplete: ', isCreateDocumentComplete);
        let _selectedItems = selection.getSelection();

        setSelectedItems(_selectedItems);
      },
    })
  );

  const [dialogConfirm, setDialogConfirm] = useState({
    isClick: false,
    header: '',
    message: '',
    onConfirm: null,
    onCancel: null,
  });

  const [createdBy, setCreatedBy] = useState('');

  const [primarySort, setPrimarySort] = useState(sessionStorage.getItem('sap-b1-primarySort') || 'documentDate');
  const [isSortedDescendingDocumentDate, setIsSortedDescendingDocumentDate] = useState(() => {
    if (sessionStorage.getItem('sap-b1-sort-documentDate') === 'false') {
      return false;
    } else {
      return true;
    }
  });
  const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(() => {
    if (sessionStorage.getItem('sap-b1-sort-number')) {
      if (sessionStorage.getItem('sap-b1-sort-number') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingCustomerName, setIsSortedDescendingCustomerName] = useState(() => {
    if (sessionStorage.getItem('sap-b1-sort-customerName')) {
      if (sessionStorage.getItem('sap-b1-sort-customerName') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingVatTotal, setIsSortedDescendingVatTotal] = useState(() => {
    if (sessionStorage.getItem('sap-b1-sort-vatTotal')) {
      if (sessionStorage.getItem('sap-b1-sort-vatTotal') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingGrandTotal, setIsSortedDescendingGrandTotal] = useState(() => {
    if (sessionStorage.getItem('sap-b1-sort-grandTotal')) {
      if (sessionStorage.getItem('sap-b1-sort-grandTotal') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });

  const DayPickerStrings = {
    months: [
      t('sapB1.months01'),
      t('sapB1.months02'),
      t('sapB1.months03'),
      t('sapB1.months04'),
      t('sapB1.months05'),
      t('sapB1.months06'),
      t('sapB1.months07'),
      t('sapB1.months08'),
      t('sapB1.months09'),
      t('sapB1.months10'),
      t('sapB1.months11'),
      t('sapB1.months12'),
    ],

    shortMonths: [
      t('sapB1.shortMonths01'),
      t('sapB1.shortMonths02'),
      t('sapB1.shortMonths03'),
      t('sapB1.shortMonths04'),
      t('sapB1.shortMonths05'),
      t('sapB1.shortMonths06'),
      t('sapB1.shortMonths07'),
      t('sapB1.shortMonths08'),
      t('sapB1.shortMonths09'),
      t('sapB1.shortMonths10'),
      t('sapB1.shortMonths11'),
      t('sapB1.shortMonths12'),
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: t('sapB1.goToToday'),
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: t('sapB1.isRequiredErrorMessage'),

    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };

  // Processing dialog style
  const spinnerStyles = {
    circle: {
      height: 56,
      width: 56,
      borderWidth: 4,
    },
  };

  const getMonth = (goBack) => {
    let monthNames = [
      t('sapB1.months01'),
      t('sapB1.months02'),
      t('sapB1.months03'),
      t('sapB1.months04'),
      t('sapB1.months05'),
      t('sapB1.months06'),
      t('sapB1.months07'),
      t('sapB1.months08'),
      t('sapB1.months09'),
      t('sapB1.months10'),
      t('sapB1.months11'),
      t('sapB1.months12'),
    ];

    let d = new Date();

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + ' ' + year,
    };
  };

  const [queryMonth, setQueryMonth] = useState(
    (sessionStorage.getItem('sap-b1-queryMonth-key') && {
      key: sessionStorage.getItem('sap-b1-queryMonth-key'),
      text: sessionStorage.getItem('sap-b1-queryMonth-text'),
    }) || { key: getMonth(0).year + '' + getMonth(0).key, text: t('sapB1.thisMonth') }
  );

  const getFrom = (month, year) => {
    let _firstDay = new Date(year, month, 1);
    console.log('First day: ', formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getTo = (month, year) => {
    let _lastDay = new Date(year, month + 1, 1);
    console.log('Last day: ', formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const formatDate = (d) => {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const formatDateBE = (d) => {
    const _dateSplit = d.split('-');

    return [_dateSplit[2], _dateSplit[1], _dateSplit[0]].join('/');
  };

  const [from, setFrom] = useState(sessionStorage.getItem('sap-b1-from') || getFrom(getMonth(0).month, getMonth(0).year));
  const [to, setTo] = useState(sessionStorage.getItem('sap-b1-to') || getTo(getMonth(0).month, getMonth(0).year));

  const [date, setDate] = useState(sessionStorage.getItem('sap-b1-date') ? new Date(sessionStorage.getItem('sap-b1-date')) : new Date());

  let _pageDropdown = [];

  for (let i = 1; i <= 35; i++) {
    _pageDropdown.push({
      key: i,
      text: t('sapB1.page') + ' ' + i,
      iconProps: { iconName: 'Page' },
      onClick: () => {
        console.log('Selected page: ' + i);
        setCurrentPage(i);
      },
    });
  }

  const [pageDropdown, setPageDropdown] = useState(_pageDropdown);

  function getDocumentTypeText(key) {
    if (key === 'all') return t('sapB1.allType');
    if (key === 'bost_Paid') return t('sapB1.paidType');
    if (key === 'bost_Delivered') return t('sapB1.deliveredType');
    if (key === 'bost_Open') return t('sapB1.openType');
    if (key === 'bost_Close') return t('sapB1.closeType');
  }

  function getDateText(monthObject) {
    if (monthObject.text === 'เดือนนี้' || monthObject.text === 'This Month') return t('sapB1.thisMonth');

    let monthNames = [
      t('sapB1.months01'),
      t('sapB1.months02'),
      t('sapB1.months03'),
      t('sapB1.months04'),
      t('sapB1.months05'),
      t('sapB1.months06'),
      t('sapB1.months07'),
      t('sapB1.months08'),
      t('sapB1.months09'),
      t('sapB1.months10'),
      t('sapB1.months11'),
      t('sapB1.months12'),
    ];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    if (i18n.language === 'th') {
      _year = _year + 543;
    }

    return monthNames[_monthIndex] + ' ' + _year;
  }

  const commandBarItems = [
    {
      key: invoiceType.key,
      text: getDocumentTypeText(invoiceType.key),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: [
          {
            key: 'all',
            text: t('sapB1.allType'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('เอกสารทุกประเภท');
              setInvoiceType({ key: 'all', text: t('sapB1.allType') });

              sessionStorage.setItem('sap-b1-invoiceType-key', 'all');
              sessionStorage.setItem('sap-b1-invoiceType-text', t('sapB1.allType'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');

              setPrimarySort('documentDate');
              setIsSortedDescendingDocumentDate(true);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);

              sessionStorage.setItem('sap-b1-primarySort', 'documentDate');
              sessionStorage.setItem('sap-b1-sort-documentDate', 'true');
              sessionStorage.setItem('sap-b1-sort-number', 'false');
              sessionStorage.setItem('sap-b1-sort-customerName', 'false');
              sessionStorage.setItem('sap-b1-sort-vatTotal', 'false');
              sessionStorage.setItem('sap-b1-sort-grandTotal', 'false');
            },
          },
          {
            key: 'bost_Paid',
            text: t('sapB1.paidType'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('Paid');
              setInvoiceType({ key: 'bost_Paid', text: t('sapB1.paidType') });

              sessionStorage.setItem('sap-b1-invoiceType-key', 'bost_Paid');
              sessionStorage.setItem('sap-b1-invoiceType-text', t('sapB1.paidType'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');

              setPrimarySort('documentDate');
              setIsSortedDescendingDocumentDate(true);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);

              sessionStorage.setItem('sap-b1-primarySort', 'documentDate');
              sessionStorage.setItem('sap-b1-sort-documentDate', 'true');
              sessionStorage.setItem('sap-b1-sort-number', 'false');
              sessionStorage.setItem('sap-b1-sort-customerName', 'false');
              sessionStorage.setItem('sap-b1-sort-vatTotal', 'false');
              sessionStorage.setItem('sap-b1-sort-grandTotal', 'false');
            },
          },
          {
            key: 'bost_Delivered',
            text: t('sapB1.deliveredType'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('Delivered');
              setInvoiceType({ key: 'bost_Delivered', text: t('sapB1.deliveredType') });

              sessionStorage.setItem('sap-b1-invoiceType-key', 'bost_Delivered');
              sessionStorage.setItem('sap-b1-invoiceType-text', t('sapB1.deliveredType'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');

              setPrimarySort('documentDate');
              setIsSortedDescendingDocumentDate(true);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);

              sessionStorage.setItem('sap-b1-primarySort', 'documentDate');
              sessionStorage.setItem('sap-b1-sort-documentDate', 'true');
              sessionStorage.setItem('sap-b1-sort-number', 'false');
              sessionStorage.setItem('sap-b1-sort-customerName', 'false');
              sessionStorage.setItem('sap-b1-sort-vatTotal', 'false');
              sessionStorage.setItem('sap-b1-sort-grandTotal', 'false');
            },
          },
          {
            key: 'bost_Open',
            text: t('sapB1.openType'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('Open');
              setInvoiceType({ key: 'bost_Open', text: t('sapB1.openType') });

              sessionStorage.setItem('sap-b1-invoiceType-key', 'bost_Open');
              sessionStorage.setItem('sap-b1-invoiceType-text', t('sapB1.openType'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');

              setPrimarySort('documentDate');
              setIsSortedDescendingDocumentDate(true);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);

              sessionStorage.setItem('sap-b1-primarySort', 'documentDate');
              sessionStorage.setItem('sap-b1-sort-documentDate', 'true');
              sessionStorage.setItem('sap-b1-sort-number', 'false');
              sessionStorage.setItem('sap-b1-sort-customerName', 'false');
              sessionStorage.setItem('sap-b1-sort-vatTotal', 'false');
              sessionStorage.setItem('sap-b1-sort-grandTotal', 'false');
            },
          },
          {
            key: 'bost_Close',
            text: t('sapB1.closeType'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('Close');
              setInvoiceType({ key: 'bost_Close', text: t('sapB1.closeType') });

              sessionStorage.setItem('sap-b1-invoiceType-key', 'bost_Close');
              sessionStorage.setItem('sap-b1-invoiceType-text', t('sapB1.closeType'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');

              setPrimarySort('documentDate');
              setIsSortedDescendingDocumentDate(true);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);

              sessionStorage.setItem('sap-b1-primarySort', 'documentDate');
              sessionStorage.setItem('sap-b1-sort-documentDate', 'true');
              sessionStorage.setItem('sap-b1-sort-number', 'false');
              sessionStorage.setItem('sap-b1-sort-customerName', 'false');
              sessionStorage.setItem('sap-b1-sort-vatTotal', 'false');
              sessionStorage.setItem('sap-b1-sort-grandTotal', 'false');
            },
          },
        ],
      },
    },
    {
      key: queryMonth.key,
      text: getDateText(queryMonth),
      iconProps: { iconName: 'Calendar' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: [
          {
            key: 'queryMonth',
          },
        ],
        onRenderMenuList: () => {
          return (
            <div style={{ width: 220 }}>
              <Calendar
                onSelectDate={(_date) => {
                  let monthNames = [
                    t('sapB1.months01'),
                    t('sapB1.months02'),
                    t('sapB1.months03'),
                    t('sapB1.months04'),
                    t('sapB1.months05'),
                    t('sapB1.months06'),
                    t('sapB1.months07'),
                    t('sapB1.months08'),
                    t('sapB1.months09'),
                    t('sapB1.months10'),
                    t('sapB1.months11'),
                    t('sapB1.months12'),
                  ];

                  setDate(_date);

                  sessionStorage.setItem('sap-b1-date', _date.toISOString());

                  let adjustDate = _date;

                  let year = adjustDate.getFullYear() + 543;

                  setQueryMonth({
                    key: adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'),
                    text: monthNames[adjustDate.getMonth()] + ' ' + year,
                  });

                  sessionStorage.setItem('sap-b1-queryMonth-key', adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'));
                  sessionStorage.setItem('sap-b1-queryMonth-text', monthNames[adjustDate.getMonth()] + ' ' + year);

                  setFrom(getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  setTo(getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  sessionStorage.setItem('sap-b1-from', getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  sessionStorage.setItem('sap-b1-to', getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  setLoadDataComplete(false);
                  setItems([]);
                  setGetNewDocuments(true);

                  setCurrentPage(1);

                  setSearch('');

                  setPrimarySort('documentDate');
                  setIsSortedDescendingDocumentDate(true);
                  setIsSortedDescendingNumber(false);
                  setIsSortedDescendingCustomerName(false);
                  setIsSortedDescendingVatTotal(false);
                  setIsSortedDescendingGrandTotal(false);

                  sessionStorage.setItem('sap-b1-primarySort', 'documentDate');
                  sessionStorage.setItem('sap-b1-sort-documentDate', 'true');
                  sessionStorage.setItem('sap-b1-sort-number', 'false');
                  sessionStorage.setItem('sap-b1-sort-customerName', 'false');
                  sessionStorage.setItem('sap-b1-sort-vatTotal', 'false');
                  sessionStorage.setItem('sap-b1-sort-grandTotal', 'false');
                }}
                isMonthPickerVisible={true}
                highlightSelectedMonth={true}
                isDayPickerVisible={false}
                dateRangeType={DateRangeType.Day}
                autoNavigateOnSelection={false}
                showGoToToday={false}
                value={date}
                strings={DayPickerStrings}
                showSixWeeksByDefault={true}
              />
            </div>
          );
        },
      },
    },
    {
      key: pageSize,
      text: pageSize + ' ' + t('sapB1.documentPerPage'),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'NumberedList' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: [
          {
            key: 10,
            text: '10 ' + t('sapB1.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('10 เอกสาร/หน้า');
              setPageSize(10);
              sessionStorage.setItem('sap-b1-pageSize', 10);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch('');
            },
          },
          {
            key: 20,
            text: '20 ' + t('sapB1.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('20 เอกสาร/หน้า');
              setPageSize(20);
              sessionStorage.setItem('sap-b1-pageSize', 20);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch('');
            },
          },
          {
            key: 30,
            text: '30 ' + t('sapB1.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('30 เอกสาร/หน้า');
              setPageSize(30);
              sessionStorage.setItem('sap-b1-pageSize', 30);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch('');
            },
          },
          {
            key: 40,
            text: '40 ' + t('sapB1.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('40 เอกสาร/หน้า');
              setPageSize(40);
              sessionStorage.setItem('sap-b1-pageSize', 40);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch('');
            },
          },
          {
            key: 50,
            text: '50 ' + t('sapB1.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('50 เอกสาร/หน้า');
              setPageSize(50);
              sessionStorage.setItem('sap-b1-pageSize', 50);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch('');
            },
          },
          {
            key: 100,
            text: '100 ' + t('sapB1.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('100 เอกสาร/หน้า');
              setPageSize(100);
              sessionStorage.setItem('sap-b1-pageSize', 100);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch('');
            },
          },
        ],
      },
    },
    {
      key: currentPage,
      text: t('sapB1.page') + ' ' + currentPage,
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: pageDropdown,
      },
    },
    {
      key: 'refresh',
      text: t('sapB1.refresh'),
      iconProps: { iconName: 'Refresh' },
      disabled: !loadDataComplete || isCreateDocument || search,
      onClick: () => {
        console.log('Refresh');

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);
      },
    },
  ];

  const overflowItems = [
    {
      key: 'disconnect',
      text: t('sapB1.disconnect'),
      onClick: () => {
        console.log('SAP B1 disconnect!');
        setDialogConfirm((prev) => ({
          ...prev,
          isClick: true,
          header: t('sapB1.disconnect'),
          message: t('sapB1.disconnectConfirm'),
          onConfirm: () => {
            disconnect(true);
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            }));
          },
          onCancel: () =>
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            })),
        }));
      },
      iconProps: { iconName: 'PlugDisconnected' },
      disabled: !loadDataComplete || isCreateDocument || search,
    },
  ];

  const createDocument = async (documentType, item) => {
    setIsLoading(true);

    let documentPath = '/etax/documents/receipts/confirm';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        documentPath = '/etax/documents/receipts-taxinvoices/confirm';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        documentPath = '/etax/documents/receipts-taxinvoices-abb/confirm';
        break;
      case 'RECEIPT':
        documentPath = '/etax/documents/receipts/confirm';
        break;
      case 'INVOICE-TAXINVOICE':
        documentPath = '/etax/documents/invoices-taxinvoices/confirm';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        documentPath = '/etax/documents/deliveryorders-taxinvoices/confirm';
        break;
      case 'TAXINVOICE':
        documentPath = '/etax/documents/taxinvoices/confirm';
        break;
      default:
        break;
    }

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        const [responseCustomerAddress] = await Promise.all([
          axios
            .get(API_URL + '/sources/sap/b1/businesspartners', {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId,
              },
              params: {
                cardCode: item.customerCode,
              },
            })
            .catch((error) => console.log(error)),
        ]);

        let d = item.documentDate.split('/');

        let _date = new Date(d[1] + '/' + d[0] + '/' + d[2]);

        let _dateBE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + (_date.getFullYear() + 543);
        let _dateCE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + _date.getFullYear();

        let dueD = item.dueDate.split('/');

        let _dueDate = new Date(dueD[1] + '/' + dueD[0] + '/' + dueD[2]);

        let _dueDateBE =
          `${_dueDate.getDate()}`.padStart(2, '0') + '/' + `${_dueDate.getMonth() + 1}`.padStart(2, '0') + '/' + (_dueDate.getFullYear() + 543);
        let _dueDateCE =
          `${_dueDate.getDate()}`.padStart(2, '0') + '/' + `${_dueDate.getMonth() + 1}`.padStart(2, '0') + '/' + _dueDate.getFullYear();

        let _taxId = 'N/A';
        let _taxNumberType = 'OTHR';
        let _branchNumber = '';
        let _branchText = '';

        if (item.taxId) {
          if (isNaN(item.taxId)) {
            _taxNumberType = 'CCPT';
          } else {
            _taxNumberType = 'TXID';
          }
          _taxId = item.taxId;
          _branchNumber = '00000';
          _branchText = 'Head Office';
        }

        let _customerAddress = '';
        if (item.custonerAddressExtension.BillToAddress2) _customerAddress = _customerAddress + item.custonerAddressExtension.BillToAddress2;
        if (item.custonerAddressExtension.BillToAddress3) _customerAddress = _customerAddress + ' ' + item.custonerAddressExtension.BillToAddress3;
        _customerAddress = _customerAddress + ' ' + item.customerAddress.replaceAll('\r', ' ');

        let _postcode = '00000';
        if (responseCustomerAddress.data.ZipCode) {
          if (
            responseCustomerAddress.data.ZipCode.length === 5 &&
            !isNaN(responseCustomerAddress.data.ZipCode) &&
            _customerAddress.includes(responseCustomerAddress.data.ZipCode)
          ) {
            _postcode = responseCustomerAddress.data.ZipCode;
            _customerAddress = _customerAddress.replace(responseCustomerAddress.data.ZipCode + ' ', '');
          }
        }

        let _customerPhone = responseCustomerAddress.data.Cellular;
        let _customerEmail = responseCustomerAddress.data.EmailAddress;

        let _customer = {
          addressLineOne: _customerAddress,
          addressLineTwo: '',
          branchNumber: _branchNumber,
          branchText: _branchText,
          email: _customerEmail,
          name: item.customerName,
          phone: _customerPhone,
          phoneCountryCode: '+66',
          postcode: _postcode,
          taxId: _taxId,
          taxNumberType: _taxNumberType,
          language: 'th',
          unstructure: true,
        };

        let _items = [];

        const itemList = item.items;
        const _rate = item.rate;

        for (let i = 0; i < itemList.length; i++) {
          let _percentVat = zeroVat;
          let _percentVatText = zeroVatText;
          if (Number(itemList[i].TaxTotal) > 0) {
            _percentVat = percentVat;
            _percentVatText = percentVatText;
          }

          _items.push({
            number: i + 1 + '',
            description: itemList[i].ItemDescription,
            quantity: itemList[i].Quantity,
            unitCode: '',
            unitName: '-',
            price: itemList[i].UnitPrice * _rate,
            discount: itemList[i].UnitPrice * (itemList[i].DiscountPercent / 100) * _rate,
            total: itemList[i].OpenAmountFC,
            percentVat: _percentVat,
            percentVatText: _percentVatText,
          });
        }

        let _vatPriceTotal = 0;
        let _zeroVatPriceTotal = 0;
        let _nonVatPriceTotal = 0;

        _items.forEach((item) => {
          if (item.percentVat === percentVat) {
            _vatPriceTotal = _vatPriceTotal + item.total;
          } else if (item.percentVat === zeroVat) {
            _zeroVatPriceTotal = _zeroVatPriceTotal + item.total;
          } else if (item.percentVat === nonVat) {
            _nonVatPriceTotal = _nonVatPriceTotal + item.total;
          }
        });

        history.push({
          pathname: documentPath,
          state: {
            number: String(item.number),
            reference: '',
            company: {
              ...company,
            },
            customer: _customer,
            date: _date,
            dateBE: _dateBE,
            dateCE: _dateCE,
            dueDate: _dueDate,
            dueDateBE: _dueDateBE,
            dueDateCE: _dueDateCE,
            items: _items,
            discount: 0,
            amountTotal: item.grandTotal - item.vatTotal,
            vatTotal: item.vatTotal,
            grandTotal: item.grandTotal,
            nonVatPriceTotal: _nonVatPriceTotal,
            zeroVatPriceTotal: _zeroVatPriceTotal,
            vatPriceTotal: _vatPriceTotal,
            percentVat: percentVat,
            receivedBy: '',
            createdBy: '',
            includeVat: false,
            reIssue: false,
            note: item.note,
            backUrl: '/etax/sources/sap/business-one',
          },
        });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  useEffect(() => {
    console.log('userEffect initial call!');

    setIsLoading(true);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId,
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data[0]) {
                if (companyId && response.data.find(_company => _company.id === companyId)) {
                  const _company = response.data.find(_company => _company.id === companyId)
                
                  if (!_company.includeVat) {
                    _company.includeVat = true;
                  }
  
                  setCompany(_company);
                } else {
                  if (!response.data[0].includeVat) {
                    response.data[0].includeVat = true;
                  }
  
                  setCompany(response.data[0]);

                }


                setGetNewDocuments(true);
              } else {
                history.push({
                  pathname: '/etax/companies/profiles',
                  state: {},
                });
              }

              setIsLoading(false);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getNewDocuments) {
      if (search) {
        const searchInvoices = async () => {
          instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then(async (tokenResponse) => {
              let _orderby_desc = '';

              if (primarySort === 'documentDate') {
                if (isSortedDescendingDocumentDate) {
                  _orderby_desc = 'DocDate desc';
                } else {
                  _orderby_desc = 'DocDate';
                }
              } else if (primarySort === 'number') {
                if (isSortedDescendingNumber) {
                  _orderby_desc = 'DocNum desc';
                } else {
                  _orderby_desc = 'DocNum';
                }
              } else if (primarySort === 'customerName') {
                if (isSortedDescendingCustomerName) {
                  _orderby_desc = 'CardName desc';
                } else {
                  _orderby_desc = 'CardName';
                }
              } else if (primarySort === 'vatTotal') {
                if (isSortedDescendingVatTotal) {
                  _orderby_desc = 'VatSum desc';
                } else {
                  _orderby_desc = 'VatSum';
                }
              } else if (primarySort === 'grandTotal') {
                if (isSortedDescendingGrandTotal) {
                  _orderby_desc = 'DocTotal desc';
                } else {
                  _orderby_desc = 'DocTotal';
                }
              }

              const response = await axios.get(API_URL + '/sources/sap/b1/search', {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId,
                },
                params: {
                  page: currentPage,
                  pageSize: pageSize,
                  search: search,
                  orderby_desc: _orderby_desc,
                },
              });

              const { invoiceList, count } = response.data;

              if (invoiceList.length) {
                const _number = invoiceList[0].DocNum;

                await axios
                  .get(API_URL + `/documents?number=${_number}`, {
                    headers: {
                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                      'cid': companyId,
                      'Content-Type': 'application/json',
                    },
                  })
                  .then((responseAllDocs) => {
                    let _pageDropdown = [];

                    for (let i = 1; i <= Math.ceil(count / pageSize); i++) {
                      _pageDropdown.push({
                        key: i,
                        text: 'หน้า ' + i,
                        iconProps: { iconName: 'Page' },
                        onClick: () => {
                          console.log('Selected page: ' + i);
                          setCurrentPage(i);
                        },
                      });
                    }

                    setPageDropdown(_pageDropdown);

                    if (currentPage > Math.ceil(count / pageSize)) {
                      setCurrentPage(1);
                    }

                    setPageData(paginate(count, 1, pageSize, 10));

                    const _docs = [];

                    for (let i = 0; i < invoiceList.length; i++) {
                      const _docDate = formatDateBE(invoiceList[i].DocDate);
                      const _docDueDate = formatDateBE(invoiceList[i].DocDueDate);

                      _docs.push({
                        key: invoiceList[i].DocNum,
                        documentDate: _docDate,
                        dueDate: _docDueDate,
                        number: invoiceList[i].DocNum,
                        customerCode: invoiceList[i].CardCode,
                        customerName: invoiceList[i].CardName,
                        customerAddress: invoiceList[i].Address,
                        custonerAddressExtension: invoiceList[i].AddressExtension,
                        taxId: invoiceList[i].FederalTaxID,
                        items: invoiceList[i].DocumentLines,
                        discount: invoiceList[i].TotalDiscountFC,
                        vatTotal: invoiceList[i].VatSumFc,
                        grandTotal: invoiceList[i].DocTotalFc,
                        rate: invoiceList[i].DocRate,
                        note: invoiceList[i].Comments || '',
                        etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.number === String(invoiceList[i].DocNum)),
                      });
                    }
                    setItems(_docs);

                    setLoadDataComplete(true);
                    setGetNewDocuments(false);
                    setIsCreateDocumentComplete(false);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                setItems([]);

                setLoadDataComplete(true);
                setGetNewDocuments(false);
                setIsCreateDocumentComplete(false);
              }
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
              instance.acquireTokenRedirect(silentRequest);
            });
        };

        searchInvoices();
      } else {
        const listInvoices = async () => {
          instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then(async (tokenResponse) => {
              let _orderby_desc = '';

              if (primarySort === 'documentDate') {
                if (isSortedDescendingDocumentDate) {
                  _orderby_desc = 'DocDate desc';
                } else {
                  _orderby_desc = 'DocDate';
                }
              } else if (primarySort === 'number') {
                if (isSortedDescendingNumber) {
                  _orderby_desc = 'DocNum desc';
                } else {
                  _orderby_desc = 'DocNum';
                }
              } else if (primarySort === 'customerName') {
                if (isSortedDescendingCustomerName) {
                  _orderby_desc = 'CardName desc';
                } else {
                  _orderby_desc = 'CardName';
                }
              } else if (primarySort === 'vatTotal') {
                if (isSortedDescendingVatTotal) {
                  _orderby_desc = 'VatSum desc';
                } else {
                  _orderby_desc = 'VatSum';
                }
              } else if (primarySort === 'grandTotal') {
                if (isSortedDescendingGrandTotal) {
                  _orderby_desc = 'DocTotal desc';
                } else {
                  _orderby_desc = 'DocTotal';
                }
              }

              const [response, responseAllDocs] = await Promise.all([
                axios
                  .get(API_URL + '/sources/sap/b1/invoices', {
                    headers: {
                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                      'cid': companyId,
                    },
                    params: {
                      from: from,
                      to: to,
                      DocumentStatus: invoiceType.key,
                      page: currentPage,
                      pageSize: pageSize,
                      orderby_desc: _orderby_desc,
                    },
                  })
                  .catch((error) => console.log(error)),
                axios
                  .get(API_URL + '/etax/documents/ALL/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                    headers: {
                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                      'cid': companyId,
                    },
                  })
                  .catch((error) => console.log(error)),
              ]);

              const { invoiceList, count } = response.data;

              console.log('invoiceList', invoiceList);

              let _pageDropdown = [];

              for (let i = 1; i <= Math.ceil(count / pageSize); i++) {
                _pageDropdown.push({
                  key: i,
                  text: 'หน้า ' + i,
                  iconProps: { iconName: 'Page' },
                  onClick: () => {
                    console.log('Selected page: ' + i);
                    setCurrentPage(i);
                  },
                });
              }

              setPageDropdown(_pageDropdown);

              if (currentPage > Math.ceil(count / pageSize)) {
                setCurrentPage(1);
              }

              setPageData(paginate(count, 1, pageSize, 10));

              const _docs = [];

              for (let i = 0; i < invoiceList.length; i++) {
                const _docDate = formatDateBE(invoiceList[i].DocDate);
                const _docDueDate = formatDateBE(invoiceList[i].DocDueDate);

                _docs.push({
                  key: invoiceList[i].DocNum,
                  documentDate: _docDate,
                  dueDate: _docDueDate,
                  number: invoiceList[i].DocNum,
                  customerCode: invoiceList[i].CardCode,
                  customerName: invoiceList[i].CardName,
                  customerAddress: invoiceList[i].Address,
                  custonerAddressExtension: invoiceList[i].AddressExtension,
                  taxId: invoiceList[i].FederalTaxID,
                  items: invoiceList[i].DocumentLines,
                  discount: invoiceList[i].TotalDiscountFC,
                  vatTotal: invoiceList[i].VatSumFc,
                  grandTotal: invoiceList[i].DocTotalFc,
                  rate: invoiceList[i].DocRate,
                  note: invoiceList[i].Comments || '',
                  etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.number === String(invoiceList[i].DocNum)),
                });
              }

              setItems(_docs);

              setLoadDataComplete(true);
              setGetNewDocuments(false);
              setIsCreateDocumentComplete(false);
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
              instance.acquireTokenRedirect(silentRequest);
            });
        };

        listInvoices();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments]);

  useEffect(() => {
    console.log('isCreateDocumentComplete useEffect: ', isCreateDocumentComplete);

    if (loadDataComplete && !isCreateDocument) {
      let _items = selection.getItems();
      console.log('_items: ', _items);

      let _selectedItems = selection.getSelection();

      if (isCreateDocumentComplete) {
        setIsCreateDocumentComplete(false);

        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status !== '') {
            _items[i].status = '';
          }
        }
      } else {
        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status !== '') {
            _items[i].status = '';
          }
        }

        for (let i = 0; i < _selectedItems.length; i++) {
          console.log('select item key: ', _selectedItems[i].key);

          for (let j = 0; j < _items.length; j++) {
            if (_items[j].key === _selectedItems[i].key) {
              console.log('set pending item key: ', _items[j].key);
              _items[j].status = 'pending';
              console.log('_items: ', _items);
            }
          }
        }
      }

      setItems(_items);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);

    setLoadDataComplete(false);
    setItems([]);
    setGetNewDocuments(true);
  };

  const columns = [
    {
      key: 'column1',
      name: t('sapB1.documentDate'),
      fieldName: 'documentDate',
      minWidth: 70,
      maxWidth: 125,
      isResizable: true,
      isSorted: primarySort === 'documentDate' ? true : false,
      isSortedDescending: isSortedDescendingDocumentDate,
      isSortedAscending: !isSortedDescendingDocumentDate,
      onColumnClick: () => {
        console.log('Sort document createTime!');
        setPrimarySort('documentDate');
        setIsSortedDescendingDocumentDate(!isSortedDescendingDocumentDate);

        sessionStorage.setItem('sap-b1-primarySort', 'documentDate');
        sessionStorage.setItem('sap-b1-sort-documentDate', !isSortedDescendingDocumentDate === false ? 'false' : 'true');

        sessionStorage.removeItem('sap-b1-sort-createdTime');
        sessionStorage.removeItem('sap-b1-sort-number');
        sessionStorage.removeItem('sap-b1-sort-customerName');
        sessionStorage.removeItem('sap-b1-sort-vatTotal');
        sessionStorage.removeItem('sap-b1-sort-grandTotal');

        setCurrentPage(1);

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'number',
      onRender: (item) => {
        return <span>{item.documentDate}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column2',
      name: t('sapB1.number'),
      fieldName: 'number',
      minWidth: 100,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'number' ? true : false,
      isSortedDescending: isSortedDescendingNumber,
      isSortedAscending: !isSortedDescendingNumber,
      onColumnClick: () => {
        console.log('Sort document number!');
        setPrimarySort('number');
        setIsSortedDescendingNumber(!isSortedDescendingNumber);

        sessionStorage.setItem('sap-b1-primarySort', 'number');
        sessionStorage.setItem('sap-b1-sort-number', !isSortedDescendingNumber === false ? 'false' : 'true');

        sessionStorage.removeItem('sap-b1-sort-createdTime');
        sessionStorage.removeItem('sap-b1-sort-documentDate');
        sessionStorage.removeItem('sap-b1-sort-customerName');
        sessionStorage.removeItem('sap-b1-sort-vatTotal');
        sessionStorage.removeItem('sap-b1-sort-grandTotal');

        setCurrentPage(1);

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.number}</span>;
      },
      isPadded: true,
    },

    {
      key: 'column3',
      name: t('sapB1.customerName'),
      fieldName: 'customerName',
      minWidth: 100,
      maxWidth: 250,
      isResizable: true,
      isSorted: primarySort === 'customerName' ? true : false,
      isSortedDescending: isSortedDescendingCustomerName,
      isSortedAscending: !isSortedDescendingCustomerName,
      onColumnClick: () => {
        console.log('Sort document customerName!');
        setPrimarySort('customerName');
        setIsSortedDescendingCustomerName(!isSortedDescendingCustomerName);

        sessionStorage.setItem('sap-b1-primarySort', 'customerName');
        sessionStorage.setItem('sap-b1-sort-customerName', !isSortedDescendingCustomerName === false ? 'false' : 'true');

        sessionStorage.removeItem('sap-b1-sort-createdTime');
        sessionStorage.removeItem('sap-b1-sort-documentDate');
        sessionStorage.removeItem('sap-b1-sort-number');
        sessionStorage.removeItem('sap-b1-sort-vatTotal');
        sessionStorage.removeItem('sap-b1-sort-grandTotal');

        setCurrentPage(1);

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerName}</span>;
      },
      isPadded: true,
    },

    {
      key: 'column4',
      name: t('sapB1.taxId'),
      fieldName: 'taxId',
      minWidth: 150,
      maxWidth: 160,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        return <span>{item.taxId}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column5',
      name: t('sapB1.vatTotal'),
      fieldName: 'vatTotal',
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      isSorted: primarySort === 'vatTotal' ? true : false,
      isSortedDescending: isSortedDescendingVatTotal,
      isSortedAscending: !isSortedDescendingVatTotal,
      onColumnClick: () => {
        console.log('Sort document vatTotal!');
        setPrimarySort('vatTotal');
        setIsSortedDescendingVatTotal(!isSortedDescendingVatTotal);

        sessionStorage.setItem('sap-b1-primarySort', 'vatTotal');
        sessionStorage.setItem('sap-b1-sort-vatTotal', !isSortedDescendingVatTotal === false ? 'false' : 'true');

        sessionStorage.removeItem('sap-b1-sort-createdTime');
        sessionStorage.removeItem('sap-b1-sort-number');
        sessionStorage.removeItem('sap-b1-sort-customerName');
        sessionStorage.removeItem('sap-b1-sort-documentDate');
        sessionStorage.removeItem('sap-b1-sort-grandTotal');

        setCurrentPage(1);

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {Number(item.vatTotal).toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column6',
      name: t('sapB1.grandTotal'),
      fieldName: 'grandTotal',
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      isSorted: primarySort === 'grandTotal' ? true : false,
      isSortedDescending: isSortedDescendingGrandTotal,
      isSortedAscending: !isSortedDescendingGrandTotal,
      onColumnClick: () => {
        console.log('Sort document grandTotal!');
        setPrimarySort('grandTotal');
        setIsSortedDescendingGrandTotal(!isSortedDescendingGrandTotal);

        sessionStorage.setItem('sap-b1-primarySort', 'grandTotal');
        sessionStorage.setItem('sap-b1-sort-grandTotal', !isSortedDescendingGrandTotal === false ? 'false' : 'true');

        sessionStorage.removeItem('sap-b1-sort-createdTime');
        sessionStorage.removeItem('sap-b1-sort-customerName');
        sessionStorage.removeItem('sap-b1-sort-number');
        sessionStorage.removeItem('sap-b1-sort-vatTotal');
        sessionStorage.removeItem('sap-b1-sort-documentDate');

        setCurrentPage(1);

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {Number(item.grandTotal).toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column7',
      name: 'Leceipt',
      fieldName: 'etaxCreated',
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        if (item.etaxCreated) {
          return (
            <div style={{ textAlign: 'center' }}>
              <Icon iconName="SkypeCircleCheck" style={{ fontSize: '20px', color: 'green' }} />
            </div>
          );
        }
      },
    },
    {
      key: 'column8',
      name: '',
      fieldName: 'command',
      minWidth: 120,
      maxWidth: 120,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        if (isCreateDocument || isCreateDocumentComplete) {
          if (item.status === 'pending') {
            return <span style={{ color: '#106EBE' }}>{t('sapB1.pending')}</span>;
          } else if (item.status === 'processing') {
            return <Spinner label={t('sapB1.processing')} labelPosition="right" style={{ marginLeft: '0px' }} />;
          } else if (item.status === 'successful') {
            return <span style={{ color: 'green' }}>{t('sapB1.success')}</span>;
          } else if (item.status === 'fail') {
            return <span style={{ color: 'red' }}>{t('sapB1.fail')}</span>;
          }
        } else {
          return (
            <span data-selection-disabled={true}>
              <DefaultButton
                text={t('sapB1.create')}
                iconProps={{ iconName: 'Add', style: { color: '#106ebe' } }}
                menuProps={{
                  items: [
                    {
                      key: 'RECEIPT-TAXINVOICE',
                      text: t('sapB1.receipt-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('RECEIPT-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'RECEIPT-TAXINVOICE-ABB',
                      text: t('sapB1.receipt-taxinvoice-abb'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('RECEIPT-TAXINVOICE-ABB', item);
                      },
                    },
                    {
                      key: 'RECEIPT',
                      text: t('sapB1.receipt'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('RECEIPT', item);
                      },
                    },
                    {
                      key: 'INVOICE-TAXINVOICE',
                      text: t('sapB1.invoice-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('INVOICE-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'DELIVERYORDER-TAXINVOICE',
                      text: t('sapB1.deliveryorder-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('DELIVERYORDER-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'TAXINVOICE',
                      text: t('sapB1.taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('TAXINVOICE', item);
                      },
                    },
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
            </span>
          );
        }
      },
    },
  ];

  const disconnect = (_confirm) => {
    let confirm = _confirm;

    if (confirm) {
      setIsLoading(true);

      instance.acquireTokenSilent({ ...silentRequest, account: account }).then(
        (tokenResponse) => {
          axios
            .patch(
              API_URL + '/sources',
              {
                source: 'sap-b1',
                replace: '/disabled',
                value: true,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId,
                },
              }
            )
            .then((response) => {
              console.log(response);

              history.push({
                pathname: '/etax/sources/sap/business-one/connect',
                state: {},
              });

              setIsLoading(false);
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
              instance.acquireTokenRedirect(silentRequest);
            });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  const createSelectedDocuments = async (documentType) => {
    let _selectionItems = selection.getItems();

    let postUrl = '';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/receipts-taxinvoices/pdf-xml';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        postUrl = API_URL + '/etax/documents/receipts-taxinvoices-abb/pdf';
        break;
      case 'RECEIPT':
        postUrl = API_URL + '/etax/documents/receipts/pdf-xml';
        break;
      case 'INVOICE-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/invoices-taxinvoices/pdf-xml';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/deliveryorders-taxinvoices/pdf-xml';
        break;
      case 'TAXINVOICE':
        postUrl = API_URL + '/etax/documents/taxinvoices/pdf-xml';
        break;
      default:
        break;
    }

    if (postUrl) {
      await instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          for (let i = 0; i < selectedItems.length; i++) {
            console.log('create selected document: ', selectedItems[i]);

            for (let j = 0; j < _selectionItems.length; j++) {
              if (_selectionItems[j].key === selectedItems[i].key) {
                _selectionItems[j].status = 'processing';

                setItems([..._selectionItems]);

                const [responseCustomerAddress] = await Promise.all([
                  axios
                    .get(API_URL + '/sources/sap/b1/businesspartners', {
                      headers: {
                        Authorization: 'Bearer ' + tokenResponse.accessToken,
                        'cid': companyId,
                      },
                      params: {
                        cardCode: selectedItems[i].customerCode,
                      },
                    })
                    .catch((error) => console.log(error)),
                ]);

                console.log('selectedItems[i]', selectedItems[i]);

                let d = selectedItems[i].documentDate.split('/');

                let _date = new Date(d[1] + '/' + d[0] + '/' + d[2]);

                let _dateBE =
                  `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + (_date.getFullYear() + 543);
                let _dateCE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + _date.getFullYear();

                let dueD = selectedItems[i].dueDate.split('/');

                let _dueDate = new Date(dueD[1] + '/' + dueD[0] + '/' + dueD[2]);

                let _dueDateBE =
                  `${_dueDate.getDate()}`.padStart(2, '0') +
                  '/' +
                  `${_dueDate.getMonth() + 1}`.padStart(2, '0') +
                  '/' +
                  (_dueDate.getFullYear() + 543);
                let _dueDateCE =
                  `${_dueDate.getDate()}`.padStart(2, '0') + '/' + `${_dueDate.getMonth() + 1}`.padStart(2, '0') + '/' + _dueDate.getFullYear();

                let _taxId = 'N/A';
                let _taxNumberType = 'OTHR';
                let _branchNumber = '';
                let _branchText = '';

                if (selectedItems[i].taxId) {
                  if (isNaN(selectedItems[i].taxId)) {
                    _taxNumberType = 'CCPT';
                  } else {
                    _taxNumberType = 'TXID';
                  }
                  _taxId = selectedItems[i].taxId;
                  _branchNumber = '00000';
                  _branchText = 'Head Office';
                }

                let _customerAddress = '';
                if (selectedItems[i].custonerAddressExtension.BillToAddress2)
                  _customerAddress = _customerAddress + selectedItems[i].custonerAddressExtension.BillToAddress2;
                if (selectedItems[i].custonerAddressExtension.BillToAddress3)
                  _customerAddress = _customerAddress + ' ' + selectedItems[i].custonerAddressExtension.BillToAddress3;
                _customerAddress = _customerAddress + ' ' + selectedItems[i].customerAddress.replaceAll('\r', ' ');

                let _postcode = '00000';
                if (responseCustomerAddress.data.ZipCode) {
                  if (
                    responseCustomerAddress.data.ZipCode.length === 5 &&
                    !isNaN(responseCustomerAddress.data.ZipCode) &&
                    _customerAddress.includes(responseCustomerAddress.data.ZipCode)
                  ) {
                    _postcode = responseCustomerAddress.data.ZipCode;
                    _customerAddress = _customerAddress.replace(responseCustomerAddress.data.ZipCode + ' ', '');
                  }
                }

                let _customerPhone = responseCustomerAddress.data.Cellular;
                let _customerEmail = responseCustomerAddress.data.EmailAddress;

                let _customer = {
                  addressLineOne: _customerAddress,
                  addressLineTwo: '',
                  branchNumber: _branchNumber,
                  branchText: _branchText,
                  email: _customerEmail,
                  name: selectedItems[i].customerName,
                  phone: _customerPhone,
                  phoneCountryCode: '+66',
                  postcode: _postcode,
                  taxId: _taxId,
                  taxNumberType: _taxNumberType,
                  language: 'th',
                  unstructure: true,
                };

                let _items = [];

                const itemList = selectedItems[i].items;
                const _rate = selectedItems[i].rate;

                for (let i = 0; i < itemList.length; i++) {
                  let _percentVat = zeroVat;
                  let _percentVatText = zeroVatText;
                  if (Number(itemList[i].TaxTotal) > 0) {
                    _percentVat = percentVat;
                    _percentVatText = percentVatText;
                  }

                  _items.push({
                    number: i + 1 + '',
                    description: itemList[i].ItemDescription,
                    quantity: itemList[i].Quantity,
                    unitCode: '',
                    unitName: '-',
                    price: itemList[i].UnitPrice * _rate,
                    discount: itemList[i].UnitPrice * (itemList[i].DiscountPercent / 100) * _rate,
                    total: itemList[i].OpenAmountFC,
                    percentVat: _percentVat,
                    percentVatText: _percentVatText,
                  });
                }

                let _vatPriceTotal = 0;
                let _zeroVatPriceTotal = 0;
                let _nonVatPriceTotal = 0;

                _items.forEach((item) => {
                  if (item.percentVat === percentVat) {
                    _vatPriceTotal = _vatPriceTotal + item.total;
                  } else if (item.percentVat === zeroVat) {
                    _zeroVatPriceTotal = _zeroVatPriceTotal + item.total;
                  } else if (item.percentVat === nonVat) {
                    _nonVatPriceTotal = _nonVatPriceTotal + item.total;
                  }
                });

                const promise_PDF_XML = await new Promise(async (resolve) => {
                  await axios
                    .post(
                      postUrl,
                      {
                        number: String(selectedItems[i].number),
                        reference: '',
                        company: {
                          ...company,
                        },
                        customer: _customer,
                        date: _date,
                        dateBE: _dateBE,
                        dateCE: _dateCE,
                        dueDate: _dueDate,
                        dueDateBE: _dueDateBE,
                        dueDateCE: _dueDateCE,
                        items: _items,
                        discount: 0,
                        amountTotal: selectedItems[i].grandTotal - selectedItems[i].vatTotal,
                        vatTotal: selectedItems[i].vatTotal,
                        grandTotal: selectedItems[i].grandTotal,
                        nonVatPriceTotal: _nonVatPriceTotal,
                        zeroVatPriceTotal: _zeroVatPriceTotal,
                        vatPriceTotal: _vatPriceTotal,
                        percentVat: percentVat,
                        receivedBy: createdBy,
                        createdBy: createdBy,
                        includeVat: false,
                        note: selectedItems[i].note,
                        reIssue: false,
                      },
                      {
                        headers: {
                          Authorization: 'Bearer ' + tokenResponse.accessToken,
                          'cid': companyId,
                        },
                      }
                    )
                    .then(
                      async (response) => {
                        console.log('Create document response: ', response);

                        let count = 0;

                        let interval = setInterval(async () => {
                          await axios
                            .get(`${API_URL}/etax/jobs/${response.data.id}`, {
                              headers: {
                                Authorization: 'Bearer ' + tokenResponse.accessToken,
                                'cid': companyId,
                                'Content-Type': 'application/json',
                              },
                            })
                            .then((jobsResponse) => {
                              count += 1;
                              console.log('Jobs response: ', j, jobsResponse.data.status, count);

                              if (jobsResponse.data.status === 'complete') {
                                _selectionItems[j].status = 'successful';
                                _selectionItems[j].etaxCreated = true;

                                setItems([..._selectionItems]);

                                clearInterval(interval);

                                resolve(true);
                              } else if (count >= 300) {
                                _selectionItems[j].status = 'fail';

                                setItems([..._selectionItems]);

                                clearInterval(interval);

                                resolve(false);
                              }
                            });
                        }, 1000);
                      },
                      (error) => {
                        console.log('Error Create document response: ', error);
                        _selectionItems[j].status = 'fail';
                        setItems([..._selectionItems]);
                        resolve(false);
                      }
                    );
                }); //promise

                Promise.all([promise_PDF_XML]).then((value) => {
                  console.log(i, value);
                });
              } //if
            } //for j
          } //for i
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });

      setIsCreateDocumentComplete(true);
      setIsCreateDocument(false);
    }

    setIsCreateDocumentComplete(true);
    setIsCreateDocument(false);
  };

  return (
    <div style={homeStyles}>
      {!isLoading && (
        <div>
          <Stack>
            <center>
              <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('sapB1.header')}</h2>
              {!loadDataComplete && (
                <div>
                  <Spinner
                    label={t('sapB1.loading')}
                    labelPosition="right"
                    style={{
                      marginTop: '21px',
                      marginBottom: '2px',
                    }}
                  />
                  <br />
                </div>
              )}
            </center>
          </Stack>

          {(selectedItems.length > 0 || isCreateDocument || isCreateDocumentComplete) && (
            <Stack vertical tokens={{ childrenGap: '10px' }}>
              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                <TextField
                  name="createdBy"
                  label={t('sapB1.createdBy')}
                  description={t('sapB1.createdByDesc')}
                  styles={{ root: { width: 200 } }}
                  onChange={(e) => {
                    setCreatedBy(e.target.value);
                  }}
                  value={createdBy}
                  disabled={isCreateDocument}
                />
              </Stack>
              <br />
            </Stack>
          )}

          {selectedItems.length > 0 && !isCreateDocument && (
            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
              <PrimaryButton
                text={t('sapB1.createDocument')}
                iconProps={{ iconName: 'Add' }}
                menuProps={{
                  items: [
                    {
                      key: 'RECEIPT-TAXINVOICE',
                      text: t('sapB1.receipt-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('RECEIPT-TAXINVOICE');
                      },
                    },
                    {
                      key: 'RECEIPT-TAXINVOICE-ABB',
                      text: t('sapB1.receipt-taxinvoice-abb'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('RECEIPT-TAXINVOICE-ABB');
                      },
                    },
                    {
                      key: 'RECEIPT',
                      text: t('sapB1.receipt'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('RECEIPT');
                      },
                    },
                    {
                      key: 'INVOICE-TAXINVOICE',
                      text: t('sapB1.invoice-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('INVOICE-TAXINVOICE');
                      },
                    },
                    {
                      key: 'DELIVERYORDER-TAXINVOICE',
                      text: t('sapB1.deliveryorder-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('DELIVERYORDER-TAXINVOICE');
                      },
                    },
                    {
                      key: 'TAXINVOICE',
                      text: t('sapB1.taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('TAXINVOICE');
                      },
                    },
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
              <DefaultButton
                text={t('sapB1.cancel')}
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  let _items = selection.getItems();
                  let _selectedItems = selection.getSelection();
                  console.log('selection: ', selection.getSelection());

                  for (let i = 0; i < _selectedItems.length; i++) {
                    console.log('select item key: ', _selectedItems[i].key);
                    _selectedItems[i].status = '';

                    for (let j = 0; j < _items.length; j++) {
                      if (_items[j].key === _selectedItems[i].key) {
                        console.log('item key: ', _items[j].key);
                        _items[j].status = '';
                        _selectedItems[i].status = '';
                      }
                    }
                  }

                  selection.setAllSelected(false);

                  setItems(_items);
                  console.log('selection: ', selection.getSelection());
                }}
              />
              <br />
            </Stack>
          )}

          {isCreateDocument && (
            <div>
              <Spinner label={t('sapB1.signProcessing')} size={SpinnerSize.large} />
            </div>
          )}

          {isCreateDocumentComplete && (
            <center>
              <PrimaryButton
                text={t('sapB1.createdDocuments')}
                iconProps={{ iconName: 'Documentation' }}
                onClick={async () => {
                  console.log(selectedItems);
                  history.push({
                    pathname: '/etax/documents/all',
                    state: {},
                  });
                }}
              />
            </center>
          )}
          <br />
          <Stack horizontal horizontalAlign="center">
            <Stack
              vertical
              style={{
                width: '98%',
                minWidth: '800px',
                maxWidth: '1350px',
              }}
            >
              <Stack 
                horizontal 
                styles={{
                  root: {
                      backgroundColor: 'white',
                  },
                }} 
                horizontalAlign="space-between"
              >
                <CommandBar
                  items={commandBarItems}
                  overflowItems={overflowItems}
                  styles={{
                    root: {
                      width: 'calc(100vw - 700px)',
                      minWidth: '700px',
                      maxWidth: '900px',
                    },
                  }}
                />
                <Stack
                  horizontal
                  styles={{
                    root: {
                      height: 44,
                    },
                  }}
                  tokens={{ childrenGap: '10px' }}
                >
                  <SearchBox
                    className="ms-borderColor-themePrimary"
                    styles={{
                      root: {
                        marginTop: 6,
                        width: 270,
                        fontSize: 13,
                        fontWeight: 0,
                      },
                    }}
                    disabled={!loadDataComplete}
                    placeholder={t('sapB1.searchBoxPlaceholder')}
                    onChange={(e) => {
                      setSearch(e ? e.target.value : '');
                    }}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setLoadDataComplete(false);
                        setItems([]);
                        setGetNewDocuments(true);

                        setCurrentPage(1);
                      }
                    }}
                    onClear={() => {
                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                    onSearch={() => {
                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                    value={search}
                  />
                  <PrimaryButton
                    disabled={!loadDataComplete || !search}
                    styles={{
                      root: {
                        marginTop: 6,
                        marginRight: 18,
                        width: 90,
                      },
                    }}
                    text={t('sapB1.search')}
                    onClick={() => {
                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                  />
                </Stack>
              </Stack>
              <ShimmeredDetailsList
                items={items || []}
                enableShimmer={!loadDataComplete}
                compact={isCompactMode}
                columns={columns}
                selection={selection}
                selectionMode={!loadDataComplete || isCreateDocument ? SelectionMode.none : SelectionMode.multiple}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                checkboxVisibility={CheckboxVisibility.always}
                selectionPreservedOnEmptyClick={true}
              />
            </Stack>
          </Stack>
          <br />
          <center>
            {!(currentPage === 1 && items.length < pageSize) && !(!loadDataComplete || isCreateDocument) && (
              <Pagination
                selectedPageIndex={currentPage - 1}
                pageCount={pageData.totalPages}
                onPageChange={onPageChange}
                format
                firstPageIconProps={{
                  iconName: 'DoubleChevronLeft',
                }}
                previousPageIconProps={{
                  iconName: 'ChevronLeft',
                }}
                nextPageIconProps={{
                  iconName: 'ChevronRight',
                }}
                lastPageIconProps={{
                  iconName: 'DoubleChevronRight',
                }}
              />
            )}

            <br />
            <br />
          </center>
        </div>
      )}

      {isLoading && (
        <div>
          <br />
          <br />
          <br />
          <br />
          <Stack>
            <Stack horizontal horizontalAlign="center">
              <div>
                <Spinner label={t('sapB1.loading')} labelPosition="down" styles={spinnerStyles} />
              </div>
            </Stack>
          </Stack>
          <br />
        </div>
      )}
      <Dialog
        hidden={!dialogConfirm.isClick}
        onDismiss={dialogConfirm.onCancel}
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: dialogConfirm.header,
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 }, subText: { textAlign: 'center' } },
          subText: dialogConfirm.message,
        }}
      >
        <DialogFooter styles={{ actionsRight: { textAlign: 'center' } }}>
          <PrimaryButton onClick={dialogConfirm.onConfirm} text={t('documents.confirm')} />
          <DefaultButton onClick={dialogConfirm.onCancel} text={t('documents.cancel')} />
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default SAPBusinessOne;
