import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Stack, PrimaryButton, DefaultButton, ProgressIndicator, getTheme } from '@fluentui/react';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import PdfAllPages from '../documents/pdf/view/PdfAllPages';
import { updateProductQuantity } from '../../../helpers/fetchData/updateProductQuantity';

const API_URL = process.env.REACT_APP_API_URL;

//Progress Indicator Setting
const intervalDelay = 100;
const intervalIncrement = 0.01;

const ReceiptTaxInvoiceAbbConfirm = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const location = useLocation();
  const history = useHistory();

  const { t } = useTranslation();

  const data = location.state;
  console.log(data);

  if (data === undefined) {
    history.push('/etax/receipts-taxinvoices-abb');
  }

  const [percentComplete, setPercentComplete] = useState(0);

  const [url, setUrl] = useState('');

  const companyId = sessionStorage.getItem('companyId') || '';

  const centerItem = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const theme = getTheme();

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log('submit click');

    console.log(JSON.stringify(data));

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        if (!data.reIssue) {
          axios
            .post(
              API_URL + '/etax/documents/receipts-taxinvoices-abb/pdf',
              {
                amountTotal: data.amountTotal,
                company: data.company,
                customer: data.customer,
                date: data.date,
                dateBE: data.dateBE,
                dateCE: data.dateCE,
                discount: data.discount,
                grandTotal: data.grandTotal,
                grandTotalTH: data.grandTotalTH,
                grandTotalEN: data.grandTotalEN,
                nonVatPriceTotal: data.nonVatPriceTotal,
                zeroVatPriceTotal: data.zeroVatPriceTotal,
                vatPriceTotal: data.vatPriceTotal,
                items: data.items,
                note: data.note,
                number: data.number,
                percentVat: data.percentVat,
                receivedBy: data.receivedBy,
                vatTotal: data.vatTotal,
                reIssue: data.reIssue,
                includeVat: data.includeVat,
                reference: data.reference,
                ...(data.extraAttributes ? {extraAttributes: data.extraAttributes} : {})
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'Content-Type': 'application/json',
                  'cid': companyId
                },
              }
            )
            .then(
              (response) => {
                console.log(response);
              },
              (error) => {
                console.log(error);
              }
            );
        } else {
          axios
            .post(
              API_URL + '/etax/documents/receipts-taxinvoices-abb/pdf',
              {
                amountTotal: data.amountTotal,
                company: data.company,
                customer: data.customer,
                date: data.date,
                dateBE: data.dateBE,
                dateCE: data.dateCE,
                discount: data.discount,
                grandTotal: data.grandTotal,
                grandTotalTH: data.grandTotalTH,
                grandTotalEN: data.grandTotalEN,
                nonVatPriceTotal: data.nonVatPriceTotal,
                zeroVatPriceTotal: data.zeroVatPriceTotal,
                vatPriceTotal: data.vatPriceTotal,
                items: data.items,
                note: data.note,
                number: data.number,
                percentVat: data.percentVat,
                receivedBy: data.receivedBy,
                vatTotal: data.vatTotal,
                reIssue: data.reIssue,
                refer: data.refer,
                includeVat: data.includeVat,
                reference: data.reference,
                ...(data.extraAttributes ? {extraAttributes: data.extraAttributes} : {})
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'Content-Type': 'application/json',
                  'cid': companyId
                },
              }
            )
            .then(
              (response) => {
                console.log(response);
              },
              (error) => {
                console.log(error);
              }
            );
        }
        
        await updateProductQuantity(data.items, tokenResponse)
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    history.push({
      pathname: '/etax/documents/all',
      state: {},
    });
  };

  const handleEdit = (e) => {
    e.preventDefault();

    if (!data.reIssue) {
      history.push({
        pathname: '/etax/documents/receipts-taxinvoices-abb',
        state: {
          command: 'EDIT',
          amountTotal: data.amountTotal,
          company: data.company,
          customer: data.customer,
          date: data.date,
          dateBE: data.dateBE,
          dateCE: data.dateCE,
          discount: data.discount,
          grandTotal: data.grandTotal,
          grandTotalTH: data.grandTotalTH,
          grandTotalEN: data.grandTotalEN,
          nonVatPriceTotal: data.nonVatPriceTotal,
          zeroVatPriceTotal: data.zeroVatPriceTotal,
          vatPriceTotal: data.vatPriceTotal,
          items: data.items,
          note: data.note,
          number: data.number,
          percentVat: data.percentVat,
          receivedBy: data.receivedBy,
          vatTotal: data.vatTotal,
          reIssue: data.reIssue,
          backUrl: data.backUrl,
          includeVat: data.includeVat,
          reference: data.reference,
          ...(data.extraAttributes ? {extraAttributes: data.extraAttributes} : {})
        },
      });
    } else {
      history.push({
        pathname: '/etax/documents/receipts-taxinvoices-abb',
        state: {
          command: 'EDIT',
          amountTotal: data.amountTotal,
          company: data.company,
          customer: data.customer,
          date: data.date,
          dateBE: data.dateBE,
          dateCE: data.dateCE,
          discount: data.discount,
          grandTotal: data.grandTotal,
          grandTotalTH: data.grandTotalTH,
          grandTotalEN: data.grandTotalEN,
          nonVatPriceTotal: data.nonVatPriceTotal,
          zeroVatPriceTotal: data.zeroVatPriceTotal,
          vatPriceTotal: data.vatPriceTotal,
          items: data.items,
          note: data.note,
          number: data.number,
          percentVat: data.percentVat,
          receivedBy: data.receivedBy,
          vatTotal: data.vatTotal,
          reIssue: data.reIssue,
          refer: data.refer,
          backUrl: data.backUrl,
          includeVat: data.includeVat,
          reference: data.reference,
          ...(data.extraAttributes ? {extraAttributes: data.extraAttributes} : {})
        },
      });
    }
  };

  useEffect(() => {
    console.log('userEffect Call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        try {
          if (!data.reIssue) {
            axios
              .post(
                API_URL + '/etax/documents/receipts-taxinvoices-abb/pdf-preview',
                {
                  amountTotal: data.amountTotal,
                  company: data.company,
                  customer: data.customer,
                  date: data.date,
                  dateBE: data.dateBE,
                  dateCE: data.dateCE,
                  discount: data.discount,
                  grandTotal: data.grandTotal,
                  grandTotalTH: data.grandTotalTH,
                  grandTotalEN: data.grandTotalEN,
                  nonVatPriceTotal: data.nonVatPriceTotal,
                  zeroVatPriceTotal: data.zeroVatPriceTotal,
                  vatPriceTotal: data.vatPriceTotal,
                  items: data.items,
                  note: data.note,
                  number: data.number,
                  percentVat: data.percentVat,
                  receivedBy: data.receivedBy,
                  vatTotal: data.vatTotal,
                  reIssue: data.reIssue,
                  includeVat: data.includeVat,
                  reference: data.reference,
                  ...(data.extraAttributes ? {extraAttributes: data.extraAttributes} : {})
                },
                {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,                    
                    'Content-Type': 'application/json',
                    'cid': companyId
                  },
                  responseType: 'blob',
                }
              )
              .then((response) => {
                setUrl(window.URL.createObjectURL(response.data));

                setPercentComplete(100);
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            axios
              .post(
                API_URL + '/etax/documents/receipts-taxinvoices-abb/pdf-preview',
                {
                  amountTotal: data.amountTotal,
                  company: data.company,
                  customer: data.customer,
                  date: data.date,
                  dateBE: data.dateBE,
                  dateCE: data.dateCE,
                  discount: data.discount,
                  grandTotal: data.grandTotal,
                  grandTotalTH: data.grandTotalTH,
                  grandTotalEN: data.grandTotalEN,
                  nonVatPriceTotal: data.nonVatPriceTotal,
                  zeroVatPriceTotal: data.zeroVatPriceTotal,
                  vatPriceTotal: data.vatPriceTotal,
                  items: data.items,
                  note: data.note,
                  number: data.number,
                  percentVat: data.percentVat,
                  receivedBy: data.receivedBy,
                  vatTotal: data.vatTotal,
                  reIssue: data.reIssue,
                  refer: data.refer,
                  includeVat: data.includeVat,
                  reference: data.reference,
                  ...(data.extraAttributes ? {extraAttributes: data.extraAttributes} : {})
                },
                {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    'Content-Type': 'application/json',
                    'cid': companyId
                  },
                  responseType: 'blob',
                }
              )
              .then((response) => {
                setUrl(window.URL.createObjectURL(response.data));

                setPercentComplete(100);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        } catch (e) {
          console.log(e.response); // undefined
        }
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (url === '') {
      const id = setInterval(() => {
        setPercentComplete((intervalIncrement + percentComplete) % 1);
      }, intervalDelay);
      return () => {
        clearInterval(id);
      };
    }
  });

  return (
    <Stack vertical verticalAlign="start" style={{ background: 'rgb(204,204,204)', width: '100%', minHeight: '100vh' }}>
      <br />
      <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
        <PrimaryButton text={t('receiptTaxInvoiceAbbConfirm.confirm')} style={{ width: '125px', letterSpacing: '1px' }} onClick={handleSubmit} />
        <DefaultButton text={t('receiptTaxInvoiceAbbConfirm.edit')} onClick={handleEdit} />
      </Stack>

      <br />
      {!url ? (
        <Stack horizontal horizontalAlign="center">
          <div
            style={{
              width: '780px',
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 20,
              marginLeft: '0px',
              marginRight: '0px',
              background: '#FFFFFF',
              boxShadow: theme.effects.elevation4,
            }}
          >
            <ProgressIndicator
              label={t('receiptTaxInvoiceAbbConfirm.processing')}
              description={t('receiptTaxInvoiceAbbConfirm.processingMessage')}
              percentComplete={percentComplete}
              style={centerItem}
            />
            <br />
          </div>
          <br />
          <br />
        </Stack>
      ) : (
        <Stack horizontal horizontalAlign="center">
          <PdfAllPages pdf={url} />
        </Stack>
      )}

      <br />
      <br />
    </Stack>
  );
};

export default ReceiptTaxInvoiceAbbConfirm;
