import { UnifiedPeoplePicker } from '@fluentui/react-experiments/lib/UnifiedPeoplePicker';

export function TextFieldEmail({ selectedItems, onItemsRemoved, onKeyDown, onBlur }) {
    return (
      <div style={{ width: "100%"}}>
          <UnifiedPeoplePicker
              selectedItemsListProps={{
                  selectedItems: selectedItems,
                  removeButtonAriaLabel: 'Remove',
                  onItemsRemoved: onItemsRemoved
              }}
              floatingSuggestionProps={{
                  isSuggestionsVisible: false
              }}
              inputProps={{
                  'aria-label': 'Add people',
                  onBlur: (e) => onBlur(e),
                  
              }}
              // eslint-disable-next-line react/jsx-no-bind
              onKeyDown={onKeyDown} 
              // eslint-disable-next-line react/jsx-no-bind
              customClipboardType="recipients"
          />
      </div>
    );
}