import React, { useState, useEffect } from "react";

import {
  ChevronDoubleLeftFilled,
  ChevronDoubleRightFilled,
  ChevronRightRegular,
  ChevronLeftRegular,
} from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";
import {
  DataGridBody,
  DataGridRow,
  DataGrid,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridCell,
  TableCellLayout,
  createTableColumn,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  MenuDivider,
  Tab,
  TabList,
  Button,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  useRestoreFocusTarget,
  SkeletonItem,
} from "@fluentui/react-components";
import {
  makeStyles,
  tokens,
  shorthands,
  typographyStyles,
  Tooltip,
} from "@fluentui/react-components";
import {
  EditRegular,
  DocumentAddRegular,
  DeleteRegular,
} from "@fluentui/react-icons";

const useStyles = makeStyles({
  title: typographyStyles.body1Stronger,
  languagechange: typographyStyles.subtitle2,

  text: {
    ...shorthands.overflow("hidden"),
    width: "340px",
    display: "block",
  },
  shimmerSize: {
    ...typographyStyles.spacingVerticalXXL,
  },

  invertedWrapper: {
    backgroundColor: tokens.colorNeutralBackground1,
  },
  firstRow: {
    alignItems: "center",
    display: "grid",
    paddingBottom: "10px",
    position: "relative",

    gridTemplateColumns: "min-content 80%",
  },
  secondThirdRow: {
    alignItems: "center",
    display: "grid",
    paddingBottom: "10px",
    position: "relative",
    gridTemplateColumns: "min-content 20% 20% 15% 15%",
  },
});

const toThaiDateString = (isoDateTime) => {
  let date = "";
  date = new Date(isoDateTime);
  let year = date.getFullYear() + 543;
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let numOfDay = date.getDate().toString().padStart(2, "0");

  // let hour = date.getHours().toString().padStart(2, "0");
  // let minutes = date.getMinutes().toString().padStart(2, "0");
  // let second = date.getSeconds().toString().padStart(2, "0");

  return `${numOfDay}/${month}/${year} `;
};

export const GridContactList = ({
  filteredData,
  setFilteredData,
  filterData,
  handleSearch,
  data,
  setData,
  isLoadComplete,
  isSearchComplete,
  EditPersonal,
  deleteDataById,
  currentPage,
  setCurrentPage,
  itemsPerPage,
  setItemsPerPage,
  fetchData,
  countTotal,
  perPage,
  handleonClickCurrentPage,
  setGetnewContacts,
  sortDirection,
  setsortDirection,
  selectedOptions,
  setSelectedOptions,
  text,
  setText,
  getnewContacts,
  EditConfirm,
  EditUnsuccess,
  createDocumentfromContact,
}) => {
  const { t } = useTranslation();

  const totalContacts = countTotal;
  const contactsPerPage = perPage;
  const totalPages = Math.ceil(totalContacts / contactsPerPage);
  const [open, setOpen] = React.useState(false);
  const restoreFocusTargetAttribute = useRestoreFocusTarget();

  // const indexOfLastItem = currentPage * contactsPerPage;
  // const indexOfFirstItem = indexOfLastItem - contactsPerPage;
  // const currentItems = filteredData;

  const currentContacts = data;
  const Tabstyles = useStyles();
  // const cardStyles = useStyles();

  const generatePageButtons = () => {
    const pageButtons = [];
    const maxTabs = 5; // Maximum number of tabs to show at once
    let startPage, endPage;

    if (totalPages <= maxTabs) {
      // If total pages are less than or equal to maxTabs, show all pages
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage <= Math.ceil(maxTabs / 2)) {
      // If current page is near the start, show the first maxTabs pages
      startPage = 1;
      endPage = maxTabs;
    } else if (currentPage + Math.floor(maxTabs / 2) >= totalPages) {
      // If current page is near the end, show the last maxTabs pages
      startPage = totalPages - maxTabs + 1;
      endPage = totalPages;
    } else {
      // Otherwise, center the current page within the maxTabs pages
      startPage = currentPage - Math.floor(maxTabs / 2);
      endPage = currentPage + Math.floor(maxTabs / 2);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <Tab
          key={i}
          value={i}
          size="large"
          shape="square"
          onClick={() => handleGoToPage(i)}
        >
          {i}
        </Tab>
      );
    }

    return pageButtons;
  };

  const handleGoToPage = (page) => {
    setSelectedOptions([page]);
    setCurrentPage(page);
    setText(`หน้า ${page}`);
    setGetnewContacts(true);
  };

  const handleShiftTabList = (shift) => {
    const newActiveTab = Math.max(1, Math.min(currentPage + shift, totalPages));
    setCurrentPage(newActiveTab);
  };

  const [idItemDelete, setIdItemDelete] = useState(null);
  const [nametoDelete, setNametoDelete] = useState(null);

  const setReadyToDelete = (
    itemId,
    itemName,
    itemTaxId,
    itemEmail,
    itemPhone
  ) => {
    setIdItemDelete(itemId);

    setNametoDelete(itemName);
  };

  const columns = [
    createTableColumn({
      columnId: "date",
      compare: (a, b) => {
        return a.createdTime.localeCompare(b.createdTime);
      },
      renderHeaderCell: () => {
        return (
          <Text className={styles.title}>
            {t("requestFormContactsList.date")}
          </Text>
        );
      },
      renderCell: (item) => {
        return (
          <Tooltip
            content={
              item && item.createdTime ? toThaiDateString(item.createdTime) : ""
            }
          >
            <TableCellLayout>
              <Text>
                {item && item.createdTime
                  ? toThaiDateString(item.createdTime)
                  : ""}
              </Text>
            </TableCellLayout>
          </Tooltip>
        );
      },
    }),
    createTableColumn({
      columnId: "name",
      renderHeaderCell: (item) => {
        return (
          <Text className={styles.title}>
            {t("requestFormContactsList.name")}
          </Text>
        );
      },
      renderCell: (item) => {
        return (
          <Tooltip content={item?.data.name}>
            <Text truncate wrap={false} className={styles.text}>
              {item?.data.name}
            </Text>
          </Tooltip>
        );
      },
    }),
    createTableColumn({
      columnId: "taxID",
      renderHeaderCell: () => {
        return (
          <Text className={styles.title}>
            {t("requestFormContactsList.taxId")}
          </Text>
        );
      },
      renderCell: (item) => {
        return (
          <Tooltip content={item?.data.taxId}>
            <TableCellLayout>
              <Text>{item?.data.taxId}</Text>
            </TableCellLayout>
          </Tooltip>
        );
      },
    }),
    createTableColumn({
      columnId: "address",
      renderHeaderCell: () => {
        return (
          <Tooltip content={t("requestFormContactsList.address")}>
            <Text className={styles.title}>
              {t("requestFormContactsList.address")}
            </Text>
          </Tooltip>
        );
      },

      renderCell: (item) => {
        return (
          <Tooltip
            content={`${item?.data.buildingNumber} ${item?.data.address} 
             ${item?.data.streetName}
               ${item?.data.subDistrictName}
               ${item?.data.districtName}
              ${item?.data.provinceName} ${item?.data.postcode}`}
          >
            <Text truncate wrap={false} className={styles.text}>
              {item?.data.buildingNumber} {item?.data.address}{" "}
              {item?.data.streetName} {item?.data.subDistrictName}{" "}
              {item?.data.districtName} {item?.data.provinceName}{" "}
              {item?.data.postcode}
            </Text>
          </Tooltip>
        );
      },
    }),
    createTableColumn({
      columnId: "email",
      renderHeaderCell: () => {
        return (
          <Text className={styles.title}>
            {t("requestFormContactsList.email")}
          </Text>
        );
      },
      renderCell: (item) => {
        return (
          <Tooltip content={item?.data.email}>
            <Text truncate wrap={false}>
              {item?.data.email}
            </Text>
          </Tooltip>
        );
      },
    }),
    createTableColumn({
      columnId: "tel",
      renderHeaderCell: () => {
        return (
          <Text className={styles.title}>
            {t("requestFormContactsList.phoneNumber")}
          </Text>
        );
      },
      renderCell: (item) => {
        return (
          <Tooltip
            content={`${item?.data.phoneCountryCode === "+66" ? "0" : ""}${
              item?.data.phone
            }`}
          >
            <TableCellLayout>
              <Text>{`${item?.data.phoneCountryCode === "+66" ? "0" : ""}${
                item?.data.phone
              }`}</Text>
            </TableCellLayout>
          </Tooltip>
        );
      },
    }),
    createTableColumn({
      columnId: "select",
      renderHeaderCell: () => {
        return <Text className={styles.title}> </Text>;
      },
      renderCell: (item) => {
        return (
          <TableCellLayout>
            <Menu>
              <MenuTrigger disableButtonEnhancement on>
                <MenuButton>{t("requestFormContactsList.select")}</MenuButton>
              </MenuTrigger>

              <MenuPopover>
                <MenuList>
                  <MenuItem
                    icon={<EditRegular />}
                    onClick={() => EditPersonal(item.id)}
                  >
                    {t("requestFormContactsList.edit")}
                  </MenuItem>

                  <DialogTrigger>
                    <MenuItem
                      icon={<DeleteRegular />}
                      appearance="transparent"
                      {...restoreFocusTargetAttribute}
                      onClick={() => {
                        // it is the user responsibility to open the dialog
                        setOpen(true);
                        setReadyToDelete(
                          item.id,
                          item.data.name,
                          item.data.taxId,
                          item.data.email,
                          item.data.phone
                        );
                      }}
                    >
                      {t("requestFormContactsList.delete")}
                    </MenuItem>
                  </DialogTrigger>

                  <MenuDivider />
                  <MenuItem
                    icon={<DocumentAddRegular />}
                    onClick={() =>
                      createDocumentfromContact("RECEIPT-TAXINVOICE", item)
                    }
                  >
                    {t("requestFormContactsList.recieptTaxInvoice")}
                  </MenuItem>

                  <MenuItem
                    icon={<DocumentAddRegular />}
                    onClick={() => createDocumentfromContact("RECEIPT", item)}
                  >
                    {t("requestFormContactsList.reciept")}
                  </MenuItem>
                  <MenuItem
                    icon={<DocumentAddRegular />}
                    onClick={() =>
                      createDocumentfromContact("INVOICE-TAXINVOICE", item)
                    }
                  >
                    {t("requestFormContactsList.invoiceTaxInvoice")}
                  </MenuItem>
                  <MenuItem
                    icon={<DocumentAddRegular />}
                    onClick={() =>
                      createDocumentfromContact(
                        "DELIVERYORDER-TAXINVOICE",
                        item
                      )
                    }
                  >
                    {t("requestFormContactsList.deliveryOrderTaxInvoice")}
                  </MenuItem>
                  <MenuItem
                    icon={<DocumentAddRegular />}
                    onClick={() =>
                      createDocumentfromContact("TAXINVOICE", item)
                    }
                  >
                    {t("requestFormContactsList.taxInvoice")}
                  </MenuItem>
                  <MenuItem
                    icon={<DocumentAddRegular />}
                    onClick={() =>
                      createDocumentfromContact("DEBIT-NOTE", item)
                    }
                  >
                    {t("requestFormContactsList.debitNote")}
                  </MenuItem>
                  <MenuItem
                    icon={<DocumentAddRegular />}
                    onClick={() =>
                      createDocumentfromContact("CREDIT-NOTE", item)
                    }
                  >
                    {t("requestFormContactsList.creditNote")}
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem
                    icon={<DocumentAddRegular />}
                    onClick={() => createDocumentfromContact("QUOTATION", item)}
                  >
                    {t("requestFormContactsList.quotation")}
                  </MenuItem>
                  <MenuItem
                    icon={<DocumentAddRegular />}
                    onClick={() => createDocumentfromContact("INVOICE", item)}
                  >
                    {t("requestFormContactsList.invoice")}
                  </MenuItem>
                  <MenuItem
                    icon={<DocumentAddRegular />}
                    onClick={() =>
                      createDocumentfromContact("BILLING-NOTE", item)
                    }
                  >
                    {t("requestFormContactsList.billingNote")}
                  </MenuItem>
                  <MenuItem
                    icon={<DocumentAddRegular />}
                    onClick={() =>
                      createDocumentfromContact("DELIVERYORDER", item)
                    }
                  >
                    {t("requestFormContactsList.deliveryOrder")}
                  </MenuItem>
                  <MenuDivider />

                  <MenuItem
                    icon={<DocumentAddRegular />}
                    onClick={() =>
                      createDocumentfromContact("WITHHOLDING-TAX", item)
                    }
                  >
                     {t("requestFormContactsList.withholdingTax")}
                  </MenuItem>
                </MenuList>
              </MenuPopover>
            </Menu>
          </TableCellLayout>
        );
      },
    }),
  ];
  const styles = useStyles();
  const columnSizingOptions = {
    date: {
      minWidth: 80,
      defaultWidth: 80,
    },
    name: {
      minWidth: 90,
      defaultWidth: 100,
    },
    taxId: {
      minWidth: 80,
      defaultWidth: 80,
    },
    address: {
      minWidth: 300,
      defaultWidth: 300,
    },
    email: {
      minWidth: 190,
      defaultWidth: 190,
    },
    tel: {
      minWidth: 80,
      defaultWidth: 80,
    },
    select: {
      minWidth: 100,
      defaultWidth: 100,
    },
  };

  // const columnSizingOptions = {
  //   date: {
  //     minWidth: "8%",
  //     defaultWidth: "8%",
  //   },
  //   name: {
  //     minWidth: "11%",
  //     defaultWidth: "10%",
  //   },
  //   taxId: {
  //     minWidth: "8%",
  //     defaultWidth: "8%",
  //   },
  //   address: {
  //     minWidth: "22.5%",
  //     defaultWidth: "22%",
  //   },
  //   email: {
  //     minWidth: "19%",
  //     defaultWidth: "15%",
  //   },
  //   tel: {
  //     minWidth: "9%",
  //     defaultWidth: "10%",
  //   },
  //   select: {
  //     minWidth: "8%",
  //     defaultWidth: "5%",
  //   },
  // };

  useEffect(() => {
    if (getnewContacts) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDirection, totalContacts]);
  if (!isLoadComplete || !isSearchComplete) {
    return (
      // <div
      //   style={{
      //     position: "absolute",
      //     top: 100,
      //     left: 0,
      //     width: "100%",
      //     height: "100%",
      //     backgroundColor: "rgba(255, 255, 255, 0.7)",
      //     display: "flex",
      //     justifyContent: "center",
      //     alignItems: "flex-start",
      //     zIndex: 1000, // Ensure it's above the modal
      //   }}
      // >
      //   <Spinner label="กำลังโหลดข้อมูล" />
      // </div>
      <div
        style={{
          width: "100%",

          height: "40px",
        }}
      >
        <DataGrid
          bgcolor="red"
          items={[
            "formId",
            "formId",
            "formId",
            "formId",
            "formId",
            "formId",
            "formId",
            "formId",
            "formId",
            "formId",
            "formId",
            "formId",
          ]}
          columns={columns}
          // sortable
          // defaultSortState={{ sortColumn: "date", sortDirection: "descending" }}
          focusMode="composite"
          resizableColumns
          columnSizingOptions={columnSizingOptions}
          // onSortChange={(e, sortState) =>
          //   sortItems(currentContacts, sortState.sortColumn, sortDirection)
          // }
        >
          <DataGridHeader style={{ backgroundColor: "white" }}>
            <DataGridRow>
              {({ renderHeaderCell }) => (
                <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
              )}
            </DataGridRow>
          </DataGridHeader>

          <DataGridBody style={{ backgroundColor: "white" }}>
            {({ renderCell, item, rowId }) => (
              <DataGridRow key={rowId}>
                {({ renderCell }) => (
                  <DataGridCell>
                    <SkeletonItem size={16} />
                  </DataGridCell>
                )}
              </DataGridRow>
            )}
          </DataGridBody>
        </DataGrid>
      </div>
    );
  }

  // No data state
  if (currentContacts.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          color: "black",
          fontSize: "100px",

          width: "100%",
          backgroundColor: "white",
          flexDirection: "row",
          height: "50px",
          justifyItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",

            justifyContent: "center",
            alignContent: "center",
          }}
        >
          {" "}
          <Text size={400}>ไม่พบข้อมูล</Text>
        </div>
      </div>
    );
  }

  function sortItems(items, sortType, sortDirection) {
    if (items.length) {
      if (sortType === "date") {
        if (sortDirection === "DESC") {
          setsortDirection("ASC");
          setGetnewContacts(true);
        } else {
          setsortDirection("DESC");
          setGetnewContacts(true);
        }
      }
    }
  }

  return (
    <div
      style={{
        width: "100%",

        height: "40px",
      }}
    >
      {/* {isLoadComplete && isSearchComplete ? ( */}

      <Dialog
        modalType="modal"
        open={open}
        onOpenChange={(event, data) => {
          // it is the users responsibility to react accordingly to the open state change
          setOpen(data.open);
        }}
      >
        {/* <MenuItem icon={<DeleteRegular />}> */}

        <DialogSurface className="fluentui-dialog">
          <DialogBody className="dialog-body">
            <DialogTitle>
              {" "}
              {t("requestFormContactsList.confirmToDelete")}
            </DialogTitle>
            <DialogContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                {t("requestFormContactsList.areYouWantToDelete")} {nametoDelete}{" "}
                {t("requestFormContactsList.orNot")}
              </div>
              <br />
            </DialogContent>
            <DialogActions>
              <DialogTrigger>
                <Button
                  // onClick={handleSavePreviewFirst}
                  onClick={() => deleteDataById(idItemDelete)}
                  appearance="primary"
                  className="button-dialog"
                >
                  {t("requestFormAdminFormEditor.confirm")}
                </Button>
              </DialogTrigger>

              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary" className="button-dialog">
                  {t("requestFormAdminFormEditor.cancel")}
                </Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>

      <DataGrid
        items={currentContacts}
        columns={columns}
        sortable
        defaultSortState={{ sortColumn: "date", sortDirection: "descending" }}
        focusMode="composite"
        resizableColumns
        {...(isLoadComplete && isSearchComplete ? { columnSizingOptions } : {})}
        onSortChange={(e, sortState) =>
          sortItems(currentContacts, sortState.sortColumn, sortDirection)
        }
        style={{ backgroundColor: "white" }}
      >
        <DataGridHeader>
          <DataGridRow>
            {({ renderHeaderCell }) => (
              <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
            )}
          </DataGridRow>
        </DataGridHeader>

        <DataGridBody>
          {({ renderCell, item, rowId }) => (
            <DataGridRow key={rowId}>
              {({ renderCell }) => (
                <DataGridCell>{renderCell(item)}</DataGridCell>
              )}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>

      {totalContacts > 10 && isLoadComplete && isSearchComplete && (
        <div style={{ width: "100%", height: "50px", marginBottom: "50px" }}>
          <div
            style={{
              textAlign: "center",

              marginTop: "20px",
              height: "40px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className={Tabstyles.paginationContainer}>
                <TabList
                  style={{ height: "1px" }}
                  defaultSelectedValue={currentPage}
                  selectedValue={currentPage}
                >
                  {" "}
                  <Tab
                    style={{ height: "1px" }}
                    onClick={() => handleonClickCurrentPage(1)}
                  >
                    <ChevronDoubleLeftFilled />
                  </Tab>
                  <Tab
                    style={{ height: "1px" }}
                    onClick={() => handleShiftTabList(-1)}
                  >
                    <ChevronLeftRegular />
                  </Tab>
                  {generatePageButtons()}
                  <Tab
                    style={{ height: "1px" }}
                    onClick={() => handleShiftTabList(1)}
                  >
                    <ChevronRightRegular />
                  </Tab>
                  <Tab
                    style={{ height: "1px" }}
                    onClick={() => handleonClickCurrentPage(totalPages)}
                  >
                    <ChevronDoubleRightFilled />
                  </Tab>
                </TabList>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
