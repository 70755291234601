import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import paginate from 'jw-paginate';

import { CSVLink } from 'react-csv';

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  SelectionMode,
  mergeStyleSets,
  Calendar,
  DateRangeType,
  ActionButton,
  Spinner,
  SpinnerSize,
  CommandBar,
  Stack,
  DocumentCard,
  DocumentCardTitle,
  DocumentCardDetails,
  DocumentCardLogo,
  DocumentCardType,
  Text,
  TooltipHost,
} from '@fluentui/react';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

import CountUp from 'react-countup';

const API_URL = process.env.REACT_APP_API_URL;

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px',
  },
  fileIconCell: {
    textAlign: 'center',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden',
      },
    },
  },
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
  },
  controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  exampleToggle: {
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '30px',
  },
  selectionDetails: {
    marginBottom: '20px',
  },
});

const cardStyles = {
  cardStyles: {
    root: {
      background: 'white',
      borderLeft: '5px solid #0078d4',
      width: '30%',
      minWidth: '315px',
      maxWidth: '424px',
      height: '130px',
    },
  },
  header: {
    root: {
      fontSize: 20,
      fontWeight: 'bold',
      paddingTop: '20px',
      textAlign: 'center',
    },
  },
  amount: {
    root: {
      fontSize: 30,
      paddingBottom: '25px',
      textAlign: 'center',
    },
  },
};

function formatDateTime(date) {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hour = '' + d.getHours(),
    minute = '' + d.getMinutes(),
    second = '' + d.getSeconds();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hour.length < 2) hour = '0' + hour;
  if (minute.length < 2) minute = '0' + minute;
  if (second.length < 2) second = '0' + second;

  return [year, month, day, hour, minute, second].join('');
}

const XmlUpload = () => {
  console.log('Home render!!!');

  const { t, i18n } = useTranslation();

  const history = useHistory();

  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];

  const companyId = sessionStorage.getItem("companyId") || "";

  const [company, setCompany] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [companyTaxId, setCompanyTaxId] = useState('');
  const [companyBranchText, setCompanyBranchText] = useState('');
  const [companyAllAddress, setCompanyAllAddress] = useState('');

  const [filter_docs, setFilter_docs] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(true);
  const [totalProcessingDocuments] = useState(0);
  const [pageData, setPageData] = useState({});
  const [pageSize, setPageSize] = useState(sessionStorage.getItem('xmlUpload-pageSize') * 1 || 10);
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('xmlUpload-currentPage') * 1 || 1);

  //CommandBars Items
  const [documentType] = useState({
    key: 'ALL',
    text: 'เอกสารทุกประเภท',
  });

  //Display Summary
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [totalVat, setTotalVat] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [submitting, setSubmitting] = useState(0);
  const [waitSubmit, setWaitSubmit] = useState(0);
  const [incompleteSubmit, setIncompleteSubmit] = useState(0);
  const [completeSubmit, setCompleteSubmit] = useState(0);

  const [date, setDate] = useState(sessionStorage.getItem('xmlUpload-date') ? new Date(sessionStorage.getItem('xmlUpload-date')) : new Date());

  const [primarySort, setPrimarySort] = useState(sessionStorage.getItem('xmlUpload-primarySort') || 'documentDate');

  const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(() => {
    if (sessionStorage.getItem('xmlUpload-sort-number') && sessionStorage.getItem('xmlUpload-sort-number') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingDocumentDate, setIsSortedDescendingDocumentDate] = useState(() => {
    if (sessionStorage.getItem('xmlUpload-sort-documentDate')) {
      if (sessionStorage.getItem('xmlUpload-sort-documentDate') === 'false') {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingCustomerName, setIsSortedDescendingCustomerName] = useState(() => {
    if (sessionStorage.getItem('xmlUpload-sort-customerName') && sessionStorage.getItem('xmlUpload-sort-customerName') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingAmountTotal, setIsSortedDescendingAmountTotal] = useState(() => {
    if (sessionStorage.getItem('xmlUpload-sort-amountTotal') && sessionStorage.getItem('xmlUpload-sort-amountTotal') === 'true') {
      return true;
    } else {
      return false;
    }
  });

  const [isSortedDescendingVatTotal, setIsSortedDescendingVatTotal] = useState(() => {
    if (sessionStorage.getItem('xmlUpload-sort-vatTotal') && sessionStorage.getItem('xmlUpload-sort-vatTotal') === 'true') {
      return true;
    } else {
      return false;
    }
  });

  const [isSortedDescendingName, setIsSortedDescendingName] = useState(() => {
    if (sessionStorage.getItem('xmlUpload-sort-name') && sessionStorage.getItem('xmlUpload-sort-name') === 'true') {
      return true;
    } else {
      return false;
    }
  });

  const [isDownloading, setIsDownloading] = useState(false);

  const DayPickerStrings = {
    months: [
      t('xmlUpload.months01'),
      t('xmlUpload.months02'),
      t('xmlUpload.months03'),
      t('xmlUpload.months04'),
      t('xmlUpload.months05'),
      t('xmlUpload.months06'),
      t('xmlUpload.months07'),
      t('xmlUpload.months08'),
      t('xmlUpload.months09'),
      t('xmlUpload.months10'),
      t('xmlUpload.months11'),
      t('xmlUpload.months12'),
    ],

    shortMonths: [
      t('xmlUpload.shortMonths01'),
      t('xmlUpload.shortMonths02'),
      t('xmlUpload.shortMonths03'),
      t('xmlUpload.shortMonths04'),
      t('xmlUpload.shortMonths05'),
      t('xmlUpload.shortMonths06'),
      t('xmlUpload.shortMonths07'),
      t('xmlUpload.shortMonths08'),
      t('xmlUpload.shortMonths09'),
      t('xmlUpload.shortMonths10'),
      t('xmlUpload.shortMonths11'),
      t('xmlUpload.shortMonths12'),
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: 'ไปวันที่ปัจจุบัน',
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: t('xmlUpload.isRequiredErrorMessage'),

    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };

  function getDateTextKey(key) {
    let monthNames = [
      t('xmlUpload.months01'),
      t('xmlUpload.months02'),
      t('xmlUpload.months03'),
      t('xmlUpload.months04'),
      t('xmlUpload.months05'),
      t('xmlUpload.months06'),
      t('xmlUpload.months07'),
      t('xmlUpload.months08'),
      t('xmlUpload.months09'),
      t('xmlUpload.months10'),
      t('xmlUpload.months11'),
      t('xmlUpload.months12'),
    ];

    let _monthIndex = Number(key.substring(4, 6)) - 1;
    let _year = Number(key.substring(0, 4));
    if (i18n.language === 'th') {
      _year = _year + 543;
    }

    return monthNames[_monthIndex] + ' ' + _year;
  }

  function getDateText(monthObject) {
    if (monthObject.text === 'เดือนนี้' || monthObject.text === 'This Month') return t('xmlUpload.thisMonth');

    let monthNames = [
      t('xmlUpload.months01'),
      t('xmlUpload.months02'),
      t('xmlUpload.months03'),
      t('xmlUpload.months04'),
      t('xmlUpload.months05'),
      t('xmlUpload.months06'),
      t('xmlUpload.months07'),
      t('xmlUpload.months08'),
      t('xmlUpload.months09'),
      t('xmlUpload.months10'),
      t('xmlUpload.months11'),
      t('xmlUpload.months12'),
    ];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    if (i18n.language === 'th') {
      _year = _year + 543;
    }

    return monthNames[_monthIndex] + ' ' + _year;
  }

  const csvLink = useRef();

  const headerCSV = [
    { key: 'r1', label: '' },
    { key: 'r2', label: '' },
    { key: 'r3', label: '' },
    { key: 'r4', label: '' },
    { key: 'r5', label: '' },
    { key: 'r6', label: '' },
    { key: 'r7', label: '' },
    { key: 'r8', label: '' },
    { key: 'r9', label: '' },
  ];

  function getCSVData() {
    let month = getDateTextKey(queryMonth.key);

    const _createdTimeValue = new Date().toISOString();
    const _createdTime = toThaiDateString(_createdTimeValue);

    let csvData = [
      {
        r1: 'รายงานการนำส่ง XML',
        r2: '',
        r3: '',
        r4: '',
        r5: '',
        r6: '',
        r7: '',
        r8: '',
        r9: '',
      },
      {
        r1: 'เดือน ' + month,
        r2: '',
        r3: '',
        r4: '',
        r5: '',
        r6: '',
        r7: '',
        r8: '',
        r9: '',
      },
      {},
      {
        r1: 'ชื่อผู้ประกอบการ ' + companyName + (companyBranchText ? ' ' + companyBranchText : ''),
        r2: '',
        r3: '',
        r4: '',
        r5: '',
        r6: '',
        r7: '',
        r8: '',
        r9: '',
      },
      {
        r1: 'ที่อยู่ ' + companyAllAddress,
        r2: '',
        r3: '',
        r4: '',
        r5: '',
        r6: '',
        r7: '',
        r8: '',
        r9: '',
      },
      {
        r1: 'เลขประจำตัวผู้เสียภาษี ' + companyTaxId,
        r2: '',
        r3: '',
        r4: '',
        r5: '',
        r6: '',
        r7: '',
        r8: '',
        r9: '',
      },
      {},
      {
        r1: 'วันที่สร้างรายงาน: ' + _createdTime,
        r2: '',
        r3: '',
        r4: '',
        r5: '',
        r6: '',
        r7: '',
        r8: '',
        r9: '',
      },
      {},
      {
        r1: 'ลำดับ',
        r2: 'วันที่บนเอกสาร',
        r3: 'เลขที่เอกสาร',
        r4: 'ชื่อผู้ซื้อสินค้า/ผู้รับบริการ',
        r5: 'สถานะนำส่ง',
        r6: 'วันที่นำส่ง',
        r7: 'หมายเลขอ้างอิงนำส่งกรมสรรพากร',
        r8: 'มูลค่าสินค้า/บริการ (ไม่รวมภาษี)',
        r9: 'จำนวนเงินภาษี',
      },
    ];

    filter_docs.forEach((doc) => {
      let row = {
        r1: doc.row,
        r2: doc.documentDate,
        r3: '=""' + doc.number + '""',
        r4: doc.customerName ? '=""' + doc.customerName  + '""' : "",
        r5: doc.rdStatus,
        r6: doc.rdTime.split(' ')[0],
        r7: doc.rdRefNumber,
        r8: doc.amountTotal.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        r9: doc.vatTotal.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      };

      csvData.push(row);
    });

    return csvData;
  }

  const handleDownloadCSV = () => {
    setIsDownloading(true);
    csvLink.current.link.click();
    setIsDownloading(false);
  };

  const getfileName = () => {
    const time = formatDateTime(new Date().toISOString());
    let fileName = time + '-Report-XML-Deliveries.csv';
    return fileName;
  };

  const [fileName] = useState(getfileName);

  const getMonth = (goBack) => {
    let monthNames = [
      t('xmlUpload.months01'),
      t('xmlUpload.months02'),
      t('xmlUpload.months03'),
      t('xmlUpload.months04'),
      t('xmlUpload.months05'),
      t('xmlUpload.months06'),
      t('xmlUpload.months07'),
      t('xmlUpload.months08'),
      t('xmlUpload.months09'),
      t('xmlUpload.months10'),
      t('xmlUpload.months11'),
      t('xmlUpload.months12'),
    ];

    let d = new Date();

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + ' ' + year,
    };
  };

  const [queryMonth, setQueryMonth] = useState(
    (sessionStorage.getItem('xmlUpload-queryMonth-key') && {
      key: sessionStorage.getItem('xmlUpload-queryMonth-key'),
      text: sessionStorage.getItem('xmlUpload-queryMonth-text'),
    }) || { key: getMonth(0).year + '' + getMonth(0).key, text: t('xmlUpload.thisMonth') }
  );

  const getFrom = (month, year) => {
    let _firstDay = new Date(year, month, 1);
    console.log('First day: ', formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getTo = (month, year) => {
    let _lastDay = new Date(year, month + 1, 1);
    console.log('Last day: ', formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const formatDate = (d) => {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const [from, setFrom] = useState(sessionStorage.getItem('xmlUpload-from') || getFrom(getMonth(0).month, getMonth(0).year));
  const [to, setTo] = useState(sessionStorage.getItem('xmlUpload-to') || getTo(getMonth(0).month, getMonth(0).year));

  const [pageDropdown, setPageDropdown] = useState([]);

  const resetSort = () => {
    setPrimarySort('documentDate');
    setIsSortedDescendingDocumentDate(true);
    setIsSortedDescendingNumber(false);
    setIsSortedDescendingCustomerName(false);
    setIsSortedDescendingAmountTotal(false);
    setIsSortedDescendingVatTotal(false);
    setIsSortedDescendingName(false);

    sessionStorage.setItem('xmlUpload-primarySort', 'documentDate');
    sessionStorage.setItem('xmlUpload-sort-documentDate', 'true');
    sessionStorage.setItem('xmlUpload-sort-number', 'false');
    sessionStorage.setItem('xmlUpload-sort-customerName', 'false');
    sessionStorage.setItem('xmlUpload-sort-amountTotal', 'false');
    sessionStorage.setItem('xmlUpload-sort-vatTotal', 'false');
    sessionStorage.setItem('xmlUpload-sort-name', 'false');
    sessionStorage.setItem('xmlUpload-sort-rdStatus', 'false');
    sessionStorage.setItem('xmlUpload-sort-rdTime', 'false');
    sessionStorage.setItem('xmlUpload-sort-rdRefNumber', 'false');
  };

  const commandBarItems = [
    {
      key: 'xml',
      text: t('xmlUpload.report'),
      iconProps: { iconName: 'TextDocument' },
      disabled: !loadDataComplete,
      onClick: () => {
        console.log('xml');

        onSubmitHTMLcreate();
      },
    },
    {
      key: queryMonth.key,
      text: getDateText(queryMonth),
      iconProps: { iconName: 'Calendar' },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: [
          {
            key: 'queryMonth',
          },
        ],
        onRenderMenuList: () => {
          return (
            <div style={{ width: 220 }}>
              <Calendar
                onSelectDate={(_date) => {
                  let monthNames = [
                    t('xmlUpload.months01'),
                    t('xmlUpload.months02'),
                    t('xmlUpload.months03'),
                    t('xmlUpload.months04'),
                    t('xmlUpload.months05'),
                    t('xmlUpload.months06'),
                    t('xmlUpload.months07'),
                    t('xmlUpload.months08'),
                    t('xmlUpload.months09'),
                    t('xmlUpload.months10'),
                    t('xmlUpload.months11'),
                    t('xmlUpload.months12'),
                  ];

                  setDate(_date);

                  sessionStorage.setItem('xmlUpload-date', _date.toISOString());

                  let adjustDate = _date;

                  let year = adjustDate.getFullYear() + 543;

                  setQueryMonth({
                    key: adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'),
                    text: monthNames[adjustDate.getMonth()] + ' ' + year,
                  });

                  sessionStorage.setItem(
                    'xmlUpload-queryMonth-key',
                    adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0')
                  );
                  sessionStorage.setItem('xmlUpload-queryMonth-text', monthNames[adjustDate.getMonth()] + ' ' + year);

                  setFrom(getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  setTo(getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  sessionStorage.setItem('xmlUpload-from', getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  sessionStorage.setItem('xmlUpload-to', getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  setLoadDataComplete(false);
                  setItems([]);
                  setGetNewDocuments(true);

                  setTotalDocuments(0);
                  setTotalVat(0);
                  setTotalAmount(0);

                  setCurrentPage(1);

                  resetSort();
                }}
                isMonthPickerVisible={true}
                highlightSelectedMonth={true}
                isDayPickerVisible={false}
                dateRangeType={DateRangeType.Day}
                autoNavigateOnSelection={false}
                showGoToToday={false}
                value={date}
                strings={DayPickerStrings}
                showSixWeeksByDefault={true}
              />
            </div>
          );
        },
      },
    },
    {
      key: 'pageSize ' + pageSize,
      text: pageSize + ' ' + t('xmlUpload.documentPerPage'),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'NumberedList' },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: [
          {
            key: 'pageSize ' + 10,
            text: '10 ' + t('xmlUpload.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('10 ออเดอร์/หน้า');
              setPageSize(10);
              sessionStorage.setItem('xmlUpload-pageSize', 10);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalAmount(0);
            },
          },
          {
            key: 'pageSize ' + 20,
            text: '20 ' + t('xmlUpload.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('20 ออเดอร์/หน้า');
              setPageSize(20);
              sessionStorage.setItem('xmlUpload-pageSize', 20);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalAmount(0);
            },
          },
          {
            key: 'pageSize ' + 30,
            text: '30 ' + t('xmlUpload.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('30 ออเดอร์/หน้า');
              setPageSize(30);
              sessionStorage.setItem('xmlUpload-pageSize', 30);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalAmount(0);
            },
          },
          {
            key: 'pageSize ' + 40,
            text: '40 ' + t('xmlUpload.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('40 ออเดอร์/หน้า');
              setPageSize(40);
              sessionStorage.setItem('xmlUpload-pageSize', 40);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalAmount(0);
            },
          },
          {
            key: 'pageSize ' + 50,
            text: '50 ' + t('xmlUpload.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('50 ออเดอร์/หน้า');
              setPageSize(50);
              sessionStorage.setItem('xmlUpload-pageSize', 50);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalAmount(0);
            },
          },
        ],
      },
    },
    {
      key: currentPage,
      text: t('xmlUpload.page') + ' ' + currentPage,
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: pageDropdown,
      },
    },
    {
      key: 'download',
      text: t('xmlUpload.download'),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Download' },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: [
          {
            key: 'download-pdf',
            text: t('xmlUpload.downloadPdf'),
            iconProps: { iconName: 'PDF' },
            onClick: () => {
              console.log('Download PDF');
              handleDownload();
            },
          },
          {
            key: 'download-csv',
            text: t('xmlUpload.downloadCsv'),
            iconProps: { iconName: 'ExcelDocument' },
            onClick: () => {
              console.log('Download CSV');
              handleDownloadCSV();
            },
          },
        ],
      },
    },
    {
      key: 'refresh',
      text: t('xmlUpload.refresh'),
      iconProps: { iconName: 'Refresh' },
      disabled: !loadDataComplete,
      onClick: () => {
        console.log('Refresh');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalAmount(0);

        setCurrentPage(1);

        resetSort();
      },
    },
  ];

  function displayTextWidth(text, font) {
    let canvas = displayTextWidth.canvas || (displayTextWidth.canvas = document.createElement('canvas'));
    let context = canvas.getContext('2d');
    context.font = font;
    let metrics = context.measureText(text);
    return metrics.width;
  }

  const arrayTo2DArray2 = (arr, howMany) => {
    let num = howMany;
    let result = [[]];

    for (let i = 0; i < arr.length; i++) {
      let widthCustomerName = displayTextWidth(arr[i].customerName, '11px Sarabun, sans-serif');

      let widthNumber = displayTextWidth(arr[i].number, '12px Sarabun, sans-serif');

      if (widthCustomerName > 206 || widthNumber > 114) {
        if (Math.ceil(widthCustomerName / 206) >= Math.ceil(widthNumber / 114)) {
          num = num - (Math.ceil(widthCustomerName / 206) - 1);
        } else {
          num = num - (Math.ceil(widthNumber / 114) - 1);
        }
      }
      if (result[result.length - 1].length < num) {
        result[result.length - 1].push(arr[i]);
      } else {
        result.push([arr[i]]);
        if (widthCustomerName > 206 || widthNumber > 114) {
          if (Math.ceil(widthCustomerName / 206) >= Math.ceil(widthNumber / 114)) {
            num = howMany - (Math.ceil(widthCustomerName / 206) - 1);
          } else {
            num = howMany - (Math.ceil(widthNumber / 114) - 1);
          }
        } else {
          num = howMany;
        }
      }
    }

    return result;
  };

  const onSubmitHTMLcreate = () => {
    let all_docs = arrayTo2DArray2(filter_docs, 21);

    console.log('doc', all_docs);

    const _createdTimeValue = new Date().toISOString();
    const _createdTime = toThaiDateString(_createdTimeValue);

    console.log(_createdTime);

    history.push({
      pathname: '/reports/deliveries/xml/confirm',
      state: {
        // location state
        all_docs: all_docs,
        totalPages: all_docs.length,
        month: queryMonth.text === 'เดือนนี้' || queryMonth.text === 'This Month' ? getMonth(0).text : queryMonth.text,
        createdTime: _createdTime,
        createdTimeValue: _createdTimeValue,
        totalVat: totalVat,
        totalAmount: totalAmount,
        submitting: submitting,
        waitSubmit: waitSubmit,
        incompleteSubmit: incompleteSubmit,
        completeSubmit: completeSubmit,
        companyName: companyName,
        companyTaxId: companyTaxId,
        companyAllAddress: companyAllAddress,
        headOffice: companyBranchText ? (companyBranchText.includes('สำนักงานใหญ่') ? true : false) : false,
        branchOffice: companyBranchText ? (companyBranchText.includes('สำนักงานใหญ่') ? false : true) : false,
        companyBranchText: companyBranchText,
      },
    });
  };

  const handleDownload = () => {
    let all_docs = arrayTo2DArray2(filter_docs, 21);

    setIsDownloading(true);

    const time = formatDateTime(new Date().toISOString());

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .post(
            API_URL + '/reports/xml-deliveries/pdf',
            {
              all_docs: all_docs,
              totalPages: all_docs.length,
              month: queryMonth.text === 'เดือนนี้' || queryMonth.text === 'This Month' ? getMonth(0).text : queryMonth.text,
              time: time,
              totalVat: totalVat,
              totalAmount: totalAmount,
              submitting: submitting,
              waitSubmit: waitSubmit,
              incompleteSubmit: incompleteSubmit,
              completeSubmit: completeSubmit,
              companyName: companyName,
              companyTaxId: companyTaxId,
              companyAllAddress: companyAllAddress,
              headOffice: companyBranchText ? (companyBranchText.includes('สำนักงานใหญ่') ? true : false) : false,
              branchOffice: companyBranchText ? (companyBranchText.includes('สำนักงานใหญ่') ? false : true) : false,
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId,
                'Content-Type': 'application/json',
              },
              responseType: 'blob',
            }
          )
          .then((response) => {
            console.log(response);
            const time = formatDateTime(new Date().toISOString());

            let fileName = time + '-Report-XML-Deliveries.pdf';

            console.log('file name: ', fileName);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();

            setIsDownloading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const toThaiDateString = (isoDateTime) => {
    let date = '';
    date = new Date(isoDateTime);
    let year = date.getFullYear() + 543;
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let numOfDay = date.getDate().toString().padStart(2, '0');

    let hour = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');
    let second = date.getSeconds().toString().padStart(2, '0');

    return `${numOfDay}/${month}/${year} ${hour}:${minutes}:${second} น.`;
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  useEffect(() => {
    console.log('userEffect initial call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId,
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data[0]) {
                if (companyId && response.data.find(_company => _company.id === companyId)) {
                  const _company = response.data.find(_company => _company.id === companyId)
                  
                  setCompany(_company);
  
                  setCompanyName(_company.name);
                  setCompanyTaxId(_company.taxId);
                  setCompanyBranchText(_company.branchText);
                } else {
                  setCompany(response.data[0]);
  
                  setCompanyName(response.data[0].name);
                  setCompanyTaxId(response.data[0].taxId);
                  setCompanyBranchText(response.data[0].branchText);

                }
              } else {
                history.push({
                  pathname: '/etax/companies/profiles',
                  state: {},
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('Company state: ', company);

    if (company.taxId) {
      console.log('Company useEffect call!');
      console.log(company);
      console.log(company.id);

      setCompanyAllAddress(
        company.buildingNumber +
          ' ' +
          (!/^-*$/.test(company.address) && company.address.trim() && !/^-*$/.test(company.address.trim()) ? company.address + ' ' : '') +
          (!/^-*$/.test(company.streetName) && company.streetName.trim() && !/^-*$/.test(company.streetName.trim())
            ? company.streetPrefix + company.streetName + ' '
            : '') +
          company.subDistrictPrefix +
          company.subDistrictName +
          ' ' +
          company.districtPrefix +
          company.districtName +
          ' ' +
          company.provincePrefix +
          company.provinceName +
          ' ' +
          company.postcode
      );
    }
  }, [company]);

  useEffect(() => {
    console.log('userEffect getNewDocuments Call!');

    if (getNewDocuments) {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          console.log('Token response: ', tokenResponse);

          axios
            .get(API_URL + '/etax/documents/' + documentType.key + '/all?from=' + from + '&to=' + to + '&date=document', {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId,
              },
            })
            .then(
              (response) => {
                console.log(response);
                console.log('Docs number: ', response.data.length);

                let filter_documents = response.data.filter(
                  (doc) =>
                    doc.type !== 'RECEIPT-TAXINVOICE-ABB' &&
                    doc.type !== 'QUOTATION' &&
                    doc.type !== 'WITHHOLDING-TAX' &&
                    doc.type !== 'INVOICE' &&
                    doc.type !== 'BILLING-NOTE' &&
                    doc.type !== 'DELIVERYORDER' &&
                    (doc.file.extension === 'xml' || (doc.xmlFile && doc.xmlFile.extension === 'xml'))
                );

                filter_documents.forEach((doc) => {
                  if (doc.type === 'CREDIT-NOTE') {
                    doc.data.amountTotal = -Math.abs(doc.data.refer.diffAmountTotal);
                    doc.data.grandTotal = -Math.abs(doc.data.grandTotal);
                    doc.data.vatTotal = -Math.abs(doc.data.vatTotal);
                  } else if (doc.type === 'DEBIT-NOTE') {
                    doc.data.amountTotal = doc.data.refer.diffAmountTotal;
                  }

                  if (doc.data.reIssue) {
                    doc.data.amountTotal = 0;
                    doc.data.vatTotal = 0;
                    doc.data.grandTotal = 0;
                  }
                });

                //Page dropdown
                let _pageDropdown = [];

                for (let i = 1; i <= Math.ceil(filter_documents.length / pageSize); i++) {
                  _pageDropdown.push({
                    key: i,
                    text: 'หน้า ' + i,
                    iconProps: { iconName: 'Page' },
                    onClick: () => {
                      console.log('Selected page: ' + i);
                      setCurrentPage(i);
                    },
                  });
                }

                setPageDropdown(_pageDropdown);

                if (currentPage > Math.ceil(filter_documents.length / pageSize)) {
                  setCurrentPage(1);
                }

                setTotalDocuments(filter_documents.length);

                //Sorting
                if (filter_documents && filter_documents.length > 0) {
                  if (primarySort === 'number') {
                    if (isSortedDescendingNumber) {
                      filter_documents.sort((a, b) => {
                        return (
                          /[A-Za-z]/.test(b.data.number) - /[A-Za-z]/.test(a.data.number) ||
                          (b.data.number.toUpperCase() < a.data.number.toUpperCase()
                            ? -1
                            : b.data.number.toUpperCase() > a.data.number.toUpperCase()
                            ? 1
                            : 0)
                        );
                      });
                    } else {
                      filter_documents.sort((a, b) => {
                        return (
                          /[A-Za-z]/.test(a.data.number) - /[A-Za-z]/.test(b.data.number) ||
                          (a.data.number.toUpperCase() < b.data.number.toUpperCase()
                            ? -1
                            : a.data.number.toUpperCase() > b.data.number.toUpperCase()
                            ? 1
                            : 0)
                        );
                      });
                    }
                  } else if (primarySort === 'documentDate') {
                    if (isSortedDescendingDocumentDate) {
                      filter_documents.sort(function (a, b) {
                        // Turn your strings into dates, and then subtract them
                        // to get a value that is either negative, positive, or zero.
                        return new Date(b.data.date) - new Date(a.data.date);
                      });
                    } else {
                      filter_documents.sort(function (a, b) {
                        // Turn your strings into dates, and then subtract them
                        // to get a value that is either negative, positive, or zero.
                        return new Date(a.data.date) - new Date(b.data.date);
                      });
                    }
                  } else if (primarySort === 'customerName') {
                    if (isSortedDescendingCustomerName) {
                      filter_documents.sort((a, b) => {
                        if (b.data.customer.name < a.data.customer.name) {
                          return -1;
                        }
                        if (b.data.customer.name > a.data.customer.name) {
                          return 1;
                        }
                        return 0;
                      });
                    } else {
                      filter_documents.sort((a, b) => {
                        if (a.data.customer.name < b.data.customer.name) {
                          return -1;
                        }
                        if (a.data.customer.name > b.data.customer.name) {
                          return 1;
                        }
                        return 0;
                      });
                    }
                  } else if (primarySort === 'amountTotal') {
                    if (isSortedDescendingAmountTotal) {
                      filter_documents.sort(function (a, b) {
                        return b.data.amountTotal - a.data.amountTotal;
                      });
                    } else {
                      filter_documents.sort(function (a, b) {
                        return a.data.amountTotal - b.data.amountTotal;
                      });
                    }
                  }
                } else if (primarySort === 'vatTotal') {
                  if (isSortedDescendingVatTotal) {
                    filter_documents.sort(function (a, b) {
                      return b.data.vatTotal - a.data.vatTotal;
                    });
                  } else {
                    filter_documents.sort(function (a, b) {
                      return a.data.vatTotal - b.data.vatTotal;
                    });
                  }
                } else if (primarySort === 'name') {
                  if (isSortedDescendingName) {
                    filter_documents.sort((a, b) => {
                      if (b.data.name < a.data.name) {
                        return -1;
                      }
                      if (b.data.name > a.data.name) {
                        return 1;
                      }
                      return 0;
                    });
                  } else {
                    filter_documents.sort((a, b) => {
                      if (a.data.name < b.data.name) {
                        return -1;
                      }
                      if (a.data.name > b.data.name) {
                        return 1;
                      }
                      return 0;
                    });
                  }
                }

                //set page
                console.log('Page data: ', paginate(filter_documents.length, 1, 5, 10));
                setPageData(paginate(filter_documents.length, currentPage, pageSize, 10));

                setDocuments(filter_documents);

                const all_docs = [];

                let submitting = 0;
                let waitsubmit = 0;
                let incomplete = 0;
                let complete = 0;

                filter_documents.forEach((doc, index) => {
                  let createdTime = toThaiDateString(doc.createdTime);
                  let _branchText = '';
                  let _amountTotal = 0;
                  let _vatTotal = 0;
                  let _grandTotal = 0;

                  if (doc.data.amountTotal) {
                    _amountTotal = doc.data.amountTotal;
                  }

                  if (doc.data.vatTotal) {
                    _vatTotal = doc.data.vatTotal;
                  }

                  if (doc.data.grandTotal) {
                    _grandTotal = doc.data.grandTotal;
                  }

                  if (
                    doc.data.customer.branchText &&
                    doc.data.customer.branchText !== null &&
                    doc.data.customer.branchText !== undefined &&
                    doc.data.customer.branchText !== ''
                  ) {
                    if (
                      doc.data.customer.branchText !== 'สำนักงานใหญ่' &&
                      doc.data.customer.branchText !== '(สำนักงานใหญ่)' &&
                      doc.data.customer.branchText !== 'Head Office'
                    ) {
                      _branchText = doc.data.customer.branchNumber;
                    } else {
                      _branchText = 'สำนักงานใหญ่';
                    }
                  }

                  // let _rdStatus = 'ยังไม่นำส่ง';

                  // if (doc.deliveries.rd.status === 'pending') {
                  //   _rdStatus = 'รอส่งอัตโนมัติ';
                  //   waitsubmit += 1;
                  // } else if (doc.deliveries.rd.status === 'successful') {
                  //   _rdStatus = 'นำส่งสำเร็จ';
                  //   complete += 1;
                  // } else if (doc.deliveries.rd.status === 'fail') {
                  //   _rdStatus = 'นำส่งไม่สำเร็จ';
                  //   incomplete += 1;
                  // } else {
                  //   waitsubmit += 1;
                  // }

                  let _rdStatus = '-';

                  if (doc.deliveries.rd.status === "pending") {
                    _rdStatus = "-";
                    waitsubmit += 1;
                  } else if (doc.deliveries.rd.status === "") {
                    _rdStatus = "-";
                    waitsubmit += 1;
                  } else if (doc.deliveries.rd.status === null) {
                    _rdStatus = "-";
                    waitsubmit += 1;
                  } else if (doc.deliveries.rd.status === "sp-pending") {
                    _rdStatus = t("xmlDeliveries.spPendingRdStatusShort");
                    submitting += 1
                  } else if (doc.deliveries.rd.status === "successful") {
                    _rdStatus = t("xmlDeliveries.successfulRdStatus");
                    complete += 1;
                  } else if (doc.deliveries.rd.status === "fail") {
                    _rdStatus = t("xmlDeliveries.failRdStatus");
                    incomplete += 1;
                  }

                  let _rdTime = doc.deliveries.rd.time ? toThaiDateString(doc.deliveries.rd.time) : '-';
                  let _rdRefNumber = doc.deliveries.rd.refNumber || '-';

                  let _reason = '';

                  if (doc.data?.refer?.reasonName) {
                    if (doc.data.refer.reasonName.includes('เหตุอื่น')) {
                      _reason = doc.data.refer.specificReason;
                    } else if (doc.data.refer.reasonName.includes('(')) {
                      let reasonName = doc.data.refer.reasonName;
                      _reason = reasonName.split('(').pop().split(')')[0];
                    } else {
                      _reason = doc.data.refer.reasonName;
                    }
                  }

                  all_docs.push({
                    row: index + 1,
                    key: doc.id,
                    createdDate: createdTime.split(' ')[0],
                    createdBy: doc.createdBy,
                    createdTime: createdTime,
                    createdTimeValue: doc.createdTime,
                    number: doc.data.number,
                    customerName: doc.data.customer.name,
                    customerBranchText: _branchText,
                    amountTotal: _amountTotal,
                    vatTotal: _vatTotal,
                    grandTotal: _grandTotal,
                    rdStatus: _rdStatus,
                    rdTime: _rdTime,
                    rdRefNumber: _rdRefNumber,
                    documentDate: doc.data.dateBE,
                    reIssue: doc.data.reIssue,
                    reason: _reason,
                  });
                });

                setFilter_docs(all_docs);

                const _docs = [];

                if (filter_documents.length > 0) {
                  for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, filter_documents.length); i++) {
                    let fileSize = formatBytes(filter_documents[i].file.size);
                    let createdTime = toThaiDateString(filter_documents[i].createdTime);

                    let _amountTotal = 0;
                    let _vatTotal = 0;
                    let _grandTotal = 0;

                    if (filter_documents[i].data.amountTotal) {
                      _amountTotal = filter_documents[i].data.amountTotal;
                    }

                    if (filter_documents[i].data.vatTotal) {
                      _vatTotal = filter_documents[i].data.vatTotal;
                    }

                    if (filter_documents[i].data.grandTotal) {
                      _grandTotal = filter_documents[i].data.grandTotal;
                    }

                    let _rdStatus = '-';

                    if (filter_documents[i].deliveries.rd.status === "pending") {
                      _rdStatus = "-";
                    } else if (filter_documents[i].deliveries.rd.status === "") {
                      _rdStatus = "-";
                    } else if (filter_documents[i].deliveries.rd.status === null) {
                      _rdStatus = "-";
                    } else if (filter_documents[i].deliveries.rd.status === "sp-pending") {
                      _rdStatus = t("xmlDeliveries.spPendingRdStatusShort");
                    } else if (filter_documents[i].deliveries.rd.status === "successful") {
                      _rdStatus = t("xmlDeliveries.successfulRdStatus");
                    } else if (filter_documents[i].deliveries.rd.status === "fail") {
                      _rdStatus = t("xmlDeliveries.failRdStatus");
                    }

                    let _rdTime = filter_documents[i].deliveries.rd.time ? toThaiDateString(filter_documents[i].deliveries.rd.time) : '-';
                    let _rdRefNumber = filter_documents[i].deliveries.rd.refNumber || '-';

                    _docs.push({
                      key: filter_documents[i].id,
                      fileName: filter_documents[i].file.name + '.' + filter_documents[i].file.extension,
                      name: filter_documents[i].data.name,
                      number: filter_documents[i].data.number,
                      value: filter_documents[i].file.name,
                      fileExtension: filter_documents[i].file.extension,
                      createdBy: filter_documents[i].createdBy,
                      createdTime: createdTime,
                      createdTimeValue: filter_documents[i].createdTime,
                      fileSize: fileSize,
                      fileSizeRaw: filter_documents[i].file.size,
                      type: filter_documents[i].type,
                      signature: filter_documents[i].signatures[0].certificateName,
                      product: filter_documents[i].product,
                      customerName: filter_documents[i].data.customer.name,
                      amountTotal: _amountTotal,
                      vatTotal: _vatTotal,
                      grandTotal: _grandTotal,
                      rdStatus: _rdStatus,
                      rdStatusValue: filter_documents[i].deliveries.rd.status,
                      rdTime: _rdTime,
                      rdRefNumber: _rdRefNumber,
                      documentDate: filter_documents[i].data.dateBE,
                      reIssue: filter_documents[i].data.reIssue,
                      reason: filter_documents[i].data?.refer?.reasonName || '',
                    });
                  }

                  let _totalVat = 0;
                  let _totalAmount = 0;

                  for (let i = 0; i < filter_documents.length; i++) {
                    _totalVat = _totalVat + filter_documents[i].data.vatTotal;
                    _totalAmount = _totalAmount + filter_documents[i].data.amountTotal;
                  }

                  setTotalVat(_totalVat);
                  setTotalAmount(_totalAmount);

                  setSubmitting(submitting);
                  setWaitSubmit(waitsubmit);
                  setIncompleteSubmit(incomplete);
                  setCompleteSubmit(complete);
                }

                console.log('Docs data', _docs);
                setItems(_docs);

                setLoadDataComplete(true);
                setGetNewDocuments(false);
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments]);

  useEffect(() => {
    if (documents.length > 0) {
      const _docs = [];

      for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
        let fileSize = formatBytes(documents[i].file.size);
        let createdTime = toThaiDateString(documents[i].createdTime);

        let _amountTotal = 0;
        let _vatTotal = 0;
        let _grandTotal = 0;

        if (documents[i].data.amountTotal) {
          _amountTotal = documents[i].data.amountTotal;
        }

        if (documents[i].data.vatTotal) {
          _vatTotal = documents[i].data.vatTotal;
        }

        if (documents[i].data.grandTotal) {
          _grandTotal = documents[i].data.grandTotal;
        }

        let _rdStatus = '-';

        if (documents[i].deliveries.rd.status === "pending") {
          _rdStatus = "-";
        } else if (documents[i].deliveries.rd.status === "") {
          _rdStatus = "-";
        } else if (documents[i].deliveries.rd.status === null) {
          _rdStatus = "-";
        } else if (documents[i].deliveries.rd.status === "sp-pending") {
          _rdStatus = t("xmlDeliveries.spPendingRdStatusShort");
        } else if (documents[i].deliveries.rd.status === "successful") {
          _rdStatus = t("xmlDeliveries.successfulRdStatus");
        } else if (documents[i].deliveries.rd.status === "fail") {
          _rdStatus = t("xmlDeliveries.failRdStatus");
        }

        let _rdTime = documents[i].deliveries.rd.time ? toThaiDateString(documents[i].deliveries.rd.time) : '-';
        let _rdRefNumber = documents[i].deliveries.rd.refNumber || '-';

        _docs.push({
          key: documents[i].id,
          fileName: documents[i].file.name + '.' + documents[i].file.extension,
          name: documents[i].data.name,
          number: documents[i].data.number,
          value: documents[i].file.name,
          fileExtension: documents[i].file.extension,
          createdBy: documents[i].createdBy,
          createdTime: createdTime,
          createdTimeValue: documents[i].createdTime,
          fileSize: fileSize,
          fileSizeRaw: documents[i].file.size,
          type: documents[i].type,
          signature: documents[i].signatures[0].certificateName,
          product: documents[i].product,
          customerName: documents[i].data.customer.name,
          amountTotal: _amountTotal,
          vatTotal: _vatTotal,
          grandTotal: _grandTotal,
          rdStatus: _rdStatus,
          rdStatusValue: documents[i].deliveries.rd.status,
          rdTime: _rdTime,
          rdRefNumber: _rdRefNumber,
          documentDate: documents[i].data.dateBE,
          reIssue: documents[i].data.reIssue,
          reason: documents[i].data?.refer?.reasonName || '',
        });
      }
      console.log('Docs data', _docs);

      setItems(_docs);
    }

    sessionStorage.setItem('xmlUpload-currentPage', currentPage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const openFile = (item) => {
    history.push({
      pathname: '/etax/documents/xml/view',
      state: {
        item: item,
        backUrl: '/reports/deliveries/xml',
      },
    });
  };

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  const columns = [
    {
      key: 'column1',
      name: t('xmlUpload.documentDate'),
      fieldName: 'documentDate',
      minWidth: 80,
      maxWidth: 80,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'documentDate' ? true : false,
      isSortedDescending: isSortedDescendingDocumentDate,
      isSortedAscending: !isSortedDescendingDocumentDate,
      onColumnClick: () => {
        console.log('Sort documentDate!');
        setPrimarySort('documentDate');
        setIsSortedDescendingDocumentDate(!isSortedDescendingDocumentDate);

        sessionStorage.setItem('xmlUpload-primarySort', 'documentDate');
        sessionStorage.setItem('xmlUpload-sort-documentDate', !isSortedDescendingNumber === false ? 'false' : 'true');

        sessionStorage.setItem('xmlUpload-sort-number', 'false');
        sessionStorage.setItem('xmlUpload-sort-customerName', 'false');
        sessionStorage.setItem('xmlUpload-sort-amountTotal', 'false');
        sessionStorage.setItem('xmlUpload-sort-name', 'false');
        sessionStorage.setItem('xmlUpload-sort-rdStatus', 'false');
        sessionStorage.setItem('xmlUpload-sort-rdTime', 'false');
        sessionStorage.setItem('xmlUpload-sort-rdRefNumber', 'false');
        sessionStorage.setItem('xmlUpload-sort-vatTotal', 'false');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalAmount(0);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.documentDate}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column2',
      name: t('xmlUpload.number'),
      fieldName: 'number',
      minWidth: 80,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'number' ? true : false,
      isSortedDescending: isSortedDescendingNumber,
      isSortedAscending: !isSortedDescendingNumber,
      onColumnClick: () => {
        console.log('Sort document number!');
        setPrimarySort('number');
        setIsSortedDescendingNumber(!isSortedDescendingNumber);

        sessionStorage.setItem('xmlUpload-primarySort', 'number');
        sessionStorage.setItem('xmlUpload-sort-number', !isSortedDescendingNumber === false ? 'false' : 'true');

        sessionStorage.setItem('xmlUpload-sort-documentDate', 'false');
        sessionStorage.setItem('xmlUpload-sort-customerName', 'false');
        sessionStorage.setItem('xmlUpload-sort-amountTotal', 'false');
        sessionStorage.setItem('xmlUpload-sort-name', 'false');
        sessionStorage.setItem('xmlUpload-sort-rdStatus', 'false');
        sessionStorage.setItem('xmlUpload-sort-rdTime', 'false');
        sessionStorage.setItem('xmlUpload-sort-rdRefNumber', 'false');
        sessionStorage.setItem('xmlUpload-sort-vatTotal', 'false');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalAmount(0);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <ActionButton
            title={t('xmlUpload.openFile')}
            className={classNames.fileIconImg}
            onClick={() => {
              openFile(item);
            }}
          >
            {item.number}
          </ActionButton>
        );
      },
      isPadded: true,
    },
    {
      key: 'column3',
      name: t('xmlUpload.customerName'),
      fieldName: 'customerName',
      minWidth: 70,
      maxWidth: 120,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'customerName' ? true : false,
      isSortedDescending: isSortedDescendingCustomerName,
      isSortedAscending: !isSortedDescendingCustomerName,
      onColumnClick: () => {
        console.log('Sort customerName!');
        setPrimarySort('customerName');
        setIsSortedDescendingCustomerName(!isSortedDescendingCustomerName);

        sessionStorage.setItem('xmlUpload-primarySort', 'customerName');
        sessionStorage.setItem('xmlUpload-sort-customerName', !isSortedDescendingCustomerName === false ? 'false' : 'true');

        sessionStorage.setItem('xmlUpload-sort-number', 'false');
        sessionStorage.setItem('xmlUpload-sort-documentDate', 'false');
        sessionStorage.setItem('xmlUpload-sort-amountTotal', 'false');
        sessionStorage.setItem('xmlUpload-sort-name', 'false');
        sessionStorage.setItem('xmlUpload-sort-rdStatus', 'false');
        sessionStorage.setItem('xmlUpload-sort-rdTime', 'false');
        sessionStorage.setItem('xmlUpload-sort-rdRefNumber', 'false');
        sessionStorage.setItem('xmlUpload-sort-vatTotal', 'false');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalAmount(0);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerName}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column4',
      name: t('xmlUpload.amountTotal'),
      fieldName: 'amountTotal',
      minWidth: 70,
      maxWidth: 80,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'amountTotal' ? true : false,
      isSortedDescending: isSortedDescendingAmountTotal,
      isSortedAscending: !isSortedDescendingAmountTotal,
      onColumnClick: () => {
        console.log('Sort amountTotal!');
        setPrimarySort('amountTotal');
        setIsSortedDescendingAmountTotal(!isSortedDescendingAmountTotal);

        sessionStorage.setItem('xmlUpload-primarySort', 'amountTotal');
        sessionStorage.setItem('xmlUpload-sort-amountTotal', !isSortedDescendingAmountTotal === false ? 'false' : 'true');

        sessionStorage.setItem('xmlUpload-sort-number', 'false');
        sessionStorage.setItem('xmlUpload-sort-documentDate', 'false');
        sessionStorage.setItem('xmlUpload-sort-customerName', 'false');
        sessionStorage.setItem('xmlUpload-sort-name', 'false');
        sessionStorage.setItem('xmlUpload-sort-rdStatus', 'false');
        sessionStorage.setItem('xmlUpload-sort-rdTime', 'false');
        sessionStorage.setItem('xmlUpload-sort-rdRefNumber', 'false');
        sessionStorage.setItem('xmlUpload-sort-vatTotal', 'false');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalAmount(0);
      },
      data: 'number',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {!item.reIssue
              ? item.amountTotal.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              : ''}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column5',
      name: t('xmlUpload.vatTotal'),
      fieldName: 'vatTotal',
      minWidth: 70,
      maxWidth: 80,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'vatTotal' ? true : false,
      isSortedDescending: isSortedDescendingVatTotal,
      isSortedAscending: !isSortedDescendingVatTotal,
      onColumnClick: () => {
        console.log('Sort vatTotal!');
        setPrimarySort('vatTotal');
        setIsSortedDescendingVatTotal(!isSortedDescendingVatTotal);

        sessionStorage.setItem('xmlUpload-primarySort', 'vatTotal');
        sessionStorage.setItem('xmlUpload-sort-vatTotal', !isSortedDescendingVatTotal === false ? 'false' : 'true');

        sessionStorage.setItem('xmlUpload-sort-number', 'false');
        sessionStorage.setItem('xmlUpload-sort-documentDate', 'false');
        sessionStorage.setItem('xmlUpload-sort-customerName', 'false');
        sessionStorage.setItem('xmlUpload-sort-amountTotal', 'false');
        sessionStorage.setItem('xmlUpload-sort-name', 'false');
        sessionStorage.setItem('xmlUpload-sort-rdStatus', 'false');
        sessionStorage.setItem('xmlUpload-sort-rdTime', 'false');
        sessionStorage.setItem('xmlUpload-sort-rdRefNumber', 'false');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalAmount(0);
      },
      data: 'number',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {!item.reIssue
              ? item.vatTotal.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              : ''}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column6',
      name: t('xmlUpload.document'),
      fieldName: 'name',
      minWidth: 150,
      maxWidth: 180,
      isCollapsible: true,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'name' ? true : false,
      isSortedDescending: isSortedDescendingName,
      isSortedAscending: !isSortedDescendingName,
      onColumnClick: () => {
        console.log('Sort name!');
        setPrimarySort('name');
        setIsSortedDescendingName(!isSortedDescendingName);

        sessionStorage.setItem('xmlUpload-primarySort', 'name');
        sessionStorage.setItem('xmlUpload-sort-name', !isSortedDescendingName === false ? 'false' : 'true');

        sessionStorage.setItem('xmlUpload-sort-number', 'false');
        sessionStorage.setItem('xmlUpload-sort-documentDate', 'false');
        sessionStorage.setItem('xmlUpload-sort-customerName', 'false');
        sessionStorage.setItem('xmlUpload-sort-amountTotal', 'false');
        sessionStorage.setItem('xmlUpload-sort-rdStatus', 'false');
        sessionStorage.setItem('xmlUpload-sort-rdTime', 'false');
        sessionStorage.setItem('xmlUpload-sort-rdRefNumber', 'false');
        sessionStorage.setItem('xmlUpload-sort-vatTotal', 'false');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalAmount(0);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <ActionButton
            title={t('xmlUpload.openFile')}
            className={classNames.fileIconImg}
            onClick={() => {
              openFile(item);
            }}
          >
            {item.name}
          </ActionButton>
        );
      },
      isPadded: true,
    },
    {
      key: 'column7',
      name: t('xmlUpload.rdTime'),
      fieldName: 'rdTime',
      minWidth: 90,
      maxWidth: 120,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      data: 'string',
      onRender: (item) => {
        return <span style={{ display: 'block', textAlign: 'center' }}>{item.rdTime}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column8',
      name: t('xmlUpload.rdRefNumber'),
      fieldName: 'rdRefNumber',
      minWidth: 240,
      maxWidth: 240,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      data: 'string',
      onRender: (item) => {
        return <span style={{ display: 'block', textAlign: 'center' }}>{item.rdRefNumber}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column9',
      name: t('xmlUpload.rdStatus'),
      fieldName: 'rdStatus',
      minWidth: 90,
      maxWidth: 90,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      data: 'string',
      onRender: (item) => {
        let _status = "-";
        if ((item.rdStatusValue === "") || (item.rdStatusValue === null)) {
          _status = <span style={{ display: 'block', textAlign: 'center' }}>-</span>
        } else if (item.rdStatusValue === "successful") {
          _status = <div data-selection-disabled={true}><span style={{ color: "green" }}>{t("xmlDeliveries.successfulRdStatus")}</span></div>
        } else if (item.rdStatusValue === "sp-pending") {
          _status = <div data-selection-disabled={true}><TooltipHost content={t("xmlDeliveries.spPendingRdStatus")}><span style={{ color: "#106EBE" }}>{t("xmlDeliveries.spPendingRdStatusShort")}</span></TooltipHost></div>
        } else if (item.rdStatusValue === "fail") {
          _status = <div data-selection-disabled={true}><span style={{ color: "red" }}>{t("xmlDeliveries.failRdStatus")}</span></div>
        } else if (item.rdStatusValue === "uploading") {
          _status = <Spinner label={t("xmlDeliveries.uploading")} labelPosition="right" style={{ marginLeft: '0px' }} />
        } else if (item.rdStatusValue === "waiting") {
          _status = <span style={{ color: "#106EBE" }}>{t("xmlDeliveries.waiting")}</span>
        }

        return (<div style={{ display: "flex", alignItems: "start", flexDirection: "row" }}>{_status}</div>);
      },
      isPadded: true,
    },
  ];

  const isCompactMode = false;

  return (
    <div style={homeStyles}>
      <Stack>
        <center>
          <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('xmlUpload.header')}</h2>
        </center>

        <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}>
          <DocumentCard aria-label={t('xmlUpload.totalDocuments')} styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
            <DocumentCardLogo {...{ logoIcon: 'Documentation' }} title={t('xmlUpload.totalDocuments')} />
            <DocumentCardDetails>
              <DocumentCardTitle title={t('xmlUpload.totalDocuments')} styles={cardStyles.header} />
              <Text styles={cardStyles.amount}>
                <CountUp end={totalDocuments} separator="," duration={1} /> {t('xmlUpload.documentUnit')}
              </Text>
            </DocumentCardDetails>
          </DocumentCard>

          <DocumentCard aria-label={t('xmlUpload.saleTotal')} styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
            <DocumentCardLogo {...{ logoIcon: 'Money' }} title={t('xmlUpload.saleTotal')} />
            <DocumentCardDetails>
              <DocumentCardTitle title={t('xmlUpload.saleTotal')} styles={cardStyles.header} />
              <Text styles={cardStyles.amount}>
                <CountUp end={totalAmount} separator="," decimals={2} decimal="." duration={1} /> {t('xmlUpload.thb')}
              </Text>
            </DocumentCardDetails>
          </DocumentCard>

          <DocumentCard aria-label={t('xmlUpload.saleVat')} styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
            <DocumentCardLogo {...{ logoIcon: 'Bank' }} title={t('xmlUpload.saleVat')} />
            <DocumentCardDetails>
              <DocumentCardTitle title={t('xmlUpload.saleVat')} styles={cardStyles.header} />
              <Text styles={cardStyles.amount}>
                <CountUp end={totalVat} separator="," decimals={2} decimal="." duration={1} /> {t('xmlUpload.thb')}
              </Text>
            </DocumentCardDetails>
          </DocumentCard>
        </Stack>
      </Stack>

      {totalProcessingDocuments > 0 && (
        <div>
          <br />
          <Spinner label={t('xmlUpload.signProcessing')} size={SpinnerSize.large} />
        </div>
      )}

      {isDownloading && (
        <div>
          <br />
          <Spinner label={t('xmlUpload.loading')} labelPosition="right" />
        </div>
      )}

      <br />
      <Stack horizontal horizontalAlign="center">
        <Stack
          vertical
          style={{
            width: '98%',
            maxWidth: '1350px',
          }}
        >
          <CommandBar items={commandBarItems} />
          {items.length ? (
            <ShimmeredDetailsList
              items={items || []}
              enableShimmer={!loadDataComplete}
              compact={isCompactMode}
              columns={columns}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
            />
          ) : (
            <ShimmeredDetailsList
              items={[{}]}
              enableShimmer={!loadDataComplete}
              compact={isCompactMode}
              columns={columns}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onRenderRow={() => {
                return (
                  <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { backgroundColor: '#FFFFFF', height: 55 } }}>
                    <Text>{t('documents.noItemFound')}</Text>
                  </Stack>
                );
              }}
            />
          )}
        </Stack>
      </Stack>
      <br />
      <center>
        {!(documents.length === 0 || documents.length < pageSize) && loadDataComplete && (
          <Pagination
            selectedPageIndex={currentPage - 1}
            pageCount={pageData.totalPages}
            onPageChange={onPageChange}
            format
            firstPageIconProps={{
              iconName: 'DoubleChevronLeft',
            }}
            previousPageIconProps={{ iconName: 'ChevronLeft' }}
            nextPageIconProps={{ iconName: 'ChevronRight' }}
            lastPageIconProps={{
              iconName: 'DoubleChevronRight',
            }}
          />
        )}

        <br />
        <br />
      </center>
      <CSVLink style={{ display: 'none' }} ref={csvLink} filename={fileName} data={getCSVData()} headers={headerCSV}>
        Download csv
      </CSVLink>
    </div>
  );
};

export default XmlUpload;
