import React from "react";
import  "./ThemeSelector.css"; // Import CSS module
import { Checkmark24Filled } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";
import { ProgressBar, Text } from "@fluentui/react-components";

const ExampleFormThirdPage = () => {
  const { t } = useTranslation();
  return (
    <div className="wrap-example">
      <div style={{ marginBottom: "10px" }}>
        <Text
          as="h1"
          block
          style={{ fontSize: "15px" }}
          className="topic-header"
        >
          {t("requestFormAdminFormEditor.sentAlready")}
        </Text>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            width: 30,
            height: 30,
            borderRadius: "50%",
            border: "1px solid #000",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "5px",
            fontSize: "20px",
          }}
          className="circle-not-active-background"
        >
          <p>1</p>
        </div>
        <div
          style={{
            width: 30,
            height: 30,
            borderRadius: "50%",
            border: "1px solid #000",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "20px",
          }}
          className="circle-not-active-background"
        >
          <p>2</p>
        </div>
        <div
          style={{
            width: 30,
            height: 30,
            borderRadius: "50%",
            border: "1px solid #000",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "5px",
            fontSize: "20px",
          }}
          className="circle-active-background"
        >
          <p>3</p>
        </div>
      </div>
      {/* Step indicators */}

      {/* Progress bar */}
      <ProgressBar
        className="progressBar"
        shape="square"
        thickness="large"
        value={1.0}
      />

      <div
        style={{
          width: "100%",
          marginTop: "5px",
          height: "100px",
          marginBottom: "0.25rem",
          overflow: "hidden",
        }}
      >
        <Text className="accent-color-apply">
          {" "}
          {t("requestFormAdminFormEditor.thirdStep")}
        </Text>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              width: 25,
              height: 25,
              borderRadius: "50%",

              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(37, 206, 44, 0.8)",
              color: "white",
              marginBottom: "2px",
              border: "1px solid white",
              marginTop: "2px",
            }}
          >
            <Checkmark24Filled />
            {/* Display checkmark if step completed */}
          </div>
          <Text> {t("requestFormAdminFormEditor.done")}</Text>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "1px",
        }}
      >
        {" "}
        <button disabled>{t("requestFormAdminFormEditor.backHomePage")}</button>
      </div>
    </div>
  );
};

export default ExampleFormThirdPage ;
