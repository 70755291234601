import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Stack, Icon, PrimaryButton, TextField, FocusTrapZone, Spinner } from '@fluentui/react';

const API_URL = process.env.REACT_APP_API_URL;

// Processing dialog style
const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const TRCloudConnect = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const cid = sessionStorage.getItem('companyId') || '';

  const { t } = useTranslation();

  const history = useHistory();

  const [companyId, setCompanyId] = useState('');
  const [passkey, setPasskey] = useState('');
  const [encryptHead, setEncryptHead] = useState('');
  const [origin, setOrigin] = useState('https://my.leceipt.com');
  const [endpointServer, setEndpointServer] = useState('');

  const [isLoading, setIsLoading] = useState(true);

  const [status, setStatus] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    console.log('companyId: ', companyId);
    console.log('passkey: ', passkey);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .post(
            API_URL + '/sources/trcloud',
            {
              companyId: companyId,
              passkey: passkey,
              encryptHead: encryptHead,
              endpointServer: endpointServer,
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': cid,
              },
            }
          )
          .then(
            (response) => {
              console.log(response);

              if (response.data) {
                if (response.data.canLogin) {
                  history.push({
                    pathname: '/etax/sources/trcloud',
                    state: {},
                  });
                } else {
                  setStatus('fail');
                }
              }

              setCompanyId('');
              setPasskey('');
              setEncryptHead('');
              setEndpointServer('');
              setIsLoading(false);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  useEffect(() => {
    console.log('userEffect retrieve TRCloud connection data!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/sources/trcloud', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': cid,
            },
          })
          .then(
            (response) => {
              console.log('TR Cloud sources: ', response);

              if (response.data) {
                if (!response.data.disabled && response.data.canLogin) {
                  history.push({
                    pathname: '/etax/sources/trcloud',
                    state: {},
                  });
                }
              }

              setIsLoading(false);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack>
      {!isLoading && (
        <Stack>
          <br />

          <h1 style={{ marginLeft: '0px', marginTop: '27px' }}>
            <Icon iconName="CloudImportExport" style={{ color: '#106ebe' }} /> {t('trCloudConnect.header')}
          </h1>
          <h3 style={{ marginTop: '0px', marginBottom: '10px' }}>{t('trCloudConnect.subHeader1')}</h3>

          <br />
          <br />
        </Stack>
      )}
      {status === '' && !isLoading && (
        <FocusTrapZone disabled={true}>
          <Stack horizontalAlign="center">
            <Stack style={{ border: '1px solid rgb(134, 134, 134)', width: '350px', backgroundColor: '#FFFFFF' }}>
              <Stack horizontal horizontalAlign="center">
                <img src="/images/trcloud.png" width="200px" alt="trcloud" />
              </Stack>
              <form onSubmit={handleSubmit}>
                <Stack horizontal horizontalAlign="center">
                  <TextField
                    label="Company ID"
                    required
                    onChange={(e) => {
                      setCompanyId(e.target.value);
                    }}
                    value={companyId}
                    style={{ width: '250px' }}
                  />
                </Stack>

                <Stack horizontal horizontalAlign="center">
                  <TextField
                    label="Passkey"
                    required
                    onChange={(e) => {
                      setPasskey(e.target.value);
                    }}
                    value={passkey}
                    style={{ width: '250px' }}
                  />
                </Stack>

                <Stack horizontal horizontalAlign="center">
                  <TextField
                    label="Encrypt Head"
                    required
                    onChange={(e) => {
                      setEncryptHead(e.target.value);
                    }}
                    value={encryptHead}
                    style={{ width: '250px' }}
                  />
                </Stack>

                <Stack horizontal horizontalAlign="center">
                  <TextField
                    label="Endpoint Server"
                    required
                    onChange={(e) => {
                      setEndpointServer(e.target.value);
                    }}
                    value={endpointServer}
                    style={{ width: '250px' }}
                    description="ตัวอย่าง https://violet.trcloud.co"
                  />
                </Stack>

                <Stack horizontal horizontalAlign="center">
                  <TextField
                    label="Origin"
                    required
                    disabled
                    onChange={(e) => {
                      setOrigin(e.target.value);
                    }}
                    value={origin}
                    style={{ width: '250px' }}
                  />
                </Stack>

                <br />
                <br />
                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}>
                  <PrimaryButton style={{ fontSize: '20px', height: '50px', width: '255px' }} type="submit">
                    {t('trCloudConnect.connect')}
                  </PrimaryButton>
                </Stack>
              </form>
              <br />
              <br />
            </Stack>
          </Stack>
        </FocusTrapZone>
      )}

      {isLoading && (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="certificates-loading-center">
              {passkey !== '' ? (
                <Spinner label={t('trCloudConnect.processing')} labelPosition="down" styles={spinnerStyles} />
              ) : (
                <Spinner label={t('trCloudConnect.loading')} labelPosition="down" styles={spinnerStyles} />
              )}
            </div>
            <br />
          </Stack>
        </Stack>
      )}

      {status === 'success' && (
        <Stack horizontal horizontalAlign="center">
          <Stack style={{ border: '1px solid rgb(134, 134, 134)', width: '300px', backgroundColor: '#FFFFFF' }}>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1 style={{ margin: '0px', color: '#0078D4' }}>{t('trCloudConnect.successMessage')}</h1>
            </Stack>
            <br />
            <Stack horizontal horizontalAlign="center">
              <img height="120" src="/images/success03.gif" alt="success03gif" />
            </Stack>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton
                style={{ fontSize: '20px', height: '50px' }}
                onClick={() => {
                  history.push({
                    pathname: '/etax/sources/shipnity',
                    state: {},
                  });
                }}
              >
                {t('trCloudConnect.successButton')}
              </PrimaryButton>
            </Stack>
            <br />
            <br />
          </Stack>
        </Stack>
      )}

      {status === 'fail' && (
        <Stack horizontal horizontalAlign="center">
          <Stack style={{ border: '1px solid rgb(134, 134, 134)', width: '300px', backgroundColor: '#FFFFFF' }}>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1 style={{ margin: '0px', color: '#F25139' }}>{t('trCloudConnect.failMessage')}</h1>
            </Stack>
            <br />
            <Stack horizontal horizontalAlign="center">
              <img height="120" src="/images/fail01.gif" alt="fail01gif" />
            </Stack>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton
                style={{ fontSize: '20px', height: '50px' }}
                onClick={() => {
                  setStatus('');
                }}
              >
                {t('trCloudConnect.failButton')}
              </PrimaryButton>
            </Stack>
            <br />
            <br />
          </Stack>
        </Stack>
      )}

      <br />
      <br />
      {status === '' && !isLoading && (
        <center>
          <br />
          <br />
          <span style={{ color: 'gray' }}>{t('trCloudConnect.footer1')}</span>
          <br />
          <br />
          <span style={{ color: 'gray', fontWeight: 'bold' }}>{t('trCloudConnect.footer2')}</span>
        </center>
      )}
    </Stack>
  );
};

export default TRCloudConnect;
