import React from "react";
import { Button } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const NotSubscribed = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const handleSubscribe = () => {
    // Handle the subscription or payment navigation

    history.push({
      pathname: `/billings`,
    });
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>
        {" "}
        {t("requestFormAdminFormEditor.youNotSubscriber")}
      </h2>
      <p style={styles.message}>
        {t("requestFormAdminFormEditor.toAccessPlease")}
      </p>
      <Button appearance="primary" onClick={() => handleSubscribe()}>
        {t("requestFormAdminFormEditor.subscribeNow")}
      </Button>
    </div>
  );
};

const styles = {
  container: {
    width: "600px",
    height: "auto", // Auto height to balance
    margin: "0 auto",
    padding: "20px",
    border: "1px solid #ddd",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
    marginTop: "50px",
    backgroundColor: "white",
  },
  heading: {
    marginBottom: "20px",
  },
  message: {
    marginBottom: "30px",
    fontSize: "18px",
    color: "#555",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    color: "#fff",
    backgroundColor: "#007bff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default NotSubscribed;
