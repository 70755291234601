import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { silentRequest } from "../../../authConfig";

import { useMsal, useAccount } from "@azure/msal-react";

import paginate from "jw-paginate";

import {
    ShimmeredDetailsList,
    Selection,
    SelectionMode,
    Calendar,
    DateRangeType,
    Spinner,
    CommandBar,
    Stack,
    PrimaryButton,
    TextField,
    Checkbox,
    Text,
    DatePicker,
    TooltipHost,
} from "@fluentui/react";

// import { Pagination } from "@uifabric/experiments/lib/Pagination";
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

import { CSVLink } from "react-csv";
import { useDidMountEffect } from "../../../helpers/customHooks";
import axios from "axios";

const homeStyles = {
    border: 0,
    margin: 0,
    width: "100%",
    background: "#FAF9F8",
};

const spinnerStyles = {
    circle: {
        height: 56,
        width: 56,
        borderWidth: 4,
    },
};

const onFormatDate = (date, format) => {
    if (format === "CE") {
        return `${date.getDate()}`.padStart(2, "0") + "/" + `${date.getMonth() + 1}`.padStart(2, "0") + "/" + date.getFullYear();
    } else {
        return `${date.getDate()}`.padStart(2, "0") + "/" + `${date.getMonth() + 1}`.padStart(2, "0") + "/" + (date.getFullYear() + 543);
    }
};

const headerCSV = [
    { key: 'A', label: 'Transaction Date' },
    { key: 'B', label: 'Transaction Type' },
    { key: 'C', label: 'Fee Name' },
    { key: 'D', label: 'Transaction Number' },
    { key: 'E', label: 'Details' },
    { key: 'F', label: 'Seller SKU' },
    { key: 'G', label: 'Lazada SKU' },
    { key: 'H', label: 'Amount' },
    { key: 'I', label: 'VAT in Amount' },
    { key: 'J', label: 'WHT Amount' },
    { key: 'K', label: 'WHT included in Amount' },
    { key: 'L', label: 'Statement' },
    { key: 'M', label: 'Paid Status' },
    { key: 'N', label: 'Order No.' },
    { key: 'O', label: 'Order Item No.' },
    { key: 'P', label: 'Order Item Status' },
    { key: 'Q', label: 'Shipping Provider' },
    { key: 'R', label: 'Shipping Speed' },
    { key: 'S', label: 'Shipment Type' },
    { key: 'T', label: 'Reference' },
    { key: 'U', label: 'Comment' },
    { key: 'V', label: 'PaymentRefId' },
    { key: 'W', label: 'Tax Invoice Number' }
  ];

  function formatDateTime(date) {
    let d = new Date(date.toLocaleString('en-GB', { timeZone: 'Asia/Bangkok' })),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hour = '' + d.getHours(),
      minute = '' + d.getMinutes(),
      second = '' + d.getSeconds();
  
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    if (hour.length < 2) hour = '0' + hour;
    if (minute.length < 2) minute = '0' + minute;
    if (second.length < 2) second = '0' + second;
  
    return [year, month, day, hour, minute, second].join('');
  }

function LazadaFinance() {
    const API_URL = process.env.REACT_APP_API_URL;

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const { t, i18n } = useTranslation();

    const [company, setCompany] = useState("");
    const [items, setItems] = useState([]);

    const [loadDataComplete, setLoadDataComplete] = useState(false);
    const [getNewDocuments, setGetNewDocuments] = useState(false);
    const [pageData, setPageData] = useState({});
    const [pageSize, setPageSize] = useState(sessionStorage.getItem("lazadaFinance-pageSize") * 1 || 10);
    const [currentPage, setCurrentPage] = useState(sessionStorage.getItem("lazadaFinance-currentPage") * 1 || 1);

    const dateType = { key: "document", text: "วันที่บนเอกสาร" };

    const [search, setSearch] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const [csvData, setCsvData] = useState({ fileName: '', data: [], downloaded: false });

    const csvLink = useRef();

    const isCompactMode = false;

    const [isCreateDocumentComplete, setIsCreateDocumentComplete] = useState(false);

    const [selectedItems, setSelectedItems] = useState([]);

    const [selection] = useState(
        new Selection({
            onSelectionChanged: () => {
                console.log("isCreateDocumentComplete: ", isCreateDocumentComplete);
                let _selectedItems = selection.getSelection();

                setSelectedItems(_selectedItems);
            },
        })
    );

    const [prefixNumber, setPrefixNumber] = useState("");
    const [countingNumber, setCountingNumber] = useState("");

    const companyId = sessionStorage.getItem("companyId") || "";

    let _pageDropdown = [];

    for (let i = 1; i <= 35; i++) {
        _pageDropdown.push({
        key: i,
        text: t('wooCommerce.page') + ' ' + i,
        iconProps: { iconName: 'Page' },
        onClick: () => {
            console.log('Selected page: ' + i);
            setCurrentPage(i);
        },
        });
    }

    const pageDropdown = _pageDropdown;

    const DayPickerStrings = {
        months: [t("lazada.months01"), t("lazada.months02"), t("lazada.months03"), t("lazada.months04"), t("lazada.months05"), t("lazada.months06"), t("lazada.months07"), t("lazada.months08"), t("lazada.months09"), t("lazada.months10"), t("lazada.months11"), t("lazada.months12")],

        shortMonths: [
            t("lazada.shortMonths01"),
            t("lazada.shortMonths02"),
            t("lazada.shortMonths03"),
            t("lazada.shortMonths04"),
            t("lazada.shortMonths05"),
            t("lazada.shortMonths06"),
            t("lazada.shortMonths07"),
            t("lazada.shortMonths08"),
            t("lazada.shortMonths09"),
            t("lazada.shortMonths10"),
            t("lazada.shortMonths11"),
            t("lazada.shortMonths12"),
        ],

        days: ["อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัสบดี", "ศุกร์", "เสาร์"],

        shortDays: ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."],

        goToToday: t("lazada.goToToday"),
        prevMonthAriaLabel: "ไปเดือนที่แล้ว",
        nextMonthAriaLabel: "ไปเดือนถัดไป",
        prevYearAriaLabel: "ไปปีที่แล้ว",
        nextYearAriaLabel: "ไปปีถัดไป",
        closeButtonAriaLabel: "ปิด",
        monthPickerHeaderAriaLabel: "{0}, เลือกเพื่อเปลี่ยนปี",
        yearPickerHeaderAriaLabel: "{0}, เลือกเพื่อเปลี่ยนเดือน",

        isRequiredErrorMessage: t("lazada.isRequiredErrorMessage"),

        invalidInputErrorMessage: "รูปแบบวันที่ไม่ถูกต้อง",
    };

    const columns = [
        {
            key: "column1",
            name: t("lazadaFinance.transactionDate"),
            fieldName: "transactionDate",
            minWidth: 90,
            maxWidth: 90,
            isResizable: true,
            data: "string",
            onRender: (item) => {
                return <TooltipHost content={item.transactionDate}>{item.transactionDate}</TooltipHost>;
            },
            // isPadded: true,
        },
        {
            key: "column2",
            name: t("lazadaFinance.transactionType"),
            fieldName: "transactionType",
            minWidth: 130,
            maxWidth: 130,
            isRowHeader: true,
            isResizable: true,
            data: "string",
            onRender: (item) => {
                return <TooltipHost content={item.transactionType}>{item.transactionType}</TooltipHost>;
            },
            // isPadded: true,
        },
        {
            key: "column3",
            name: t("lazadaFinance.transactionNumber"),
            fieldName: "transactionNumber",
            minWidth: 125,
            maxWidth: 125,
            isResizable: true,
            data: "string",
            onRender: (item) => {
                return <TooltipHost content={item.transactionNumber}>{item.transactionNumber}</TooltipHost>;
            },
            // isPadded: true,
        },
        {
            key: "column4",
            name: t("lazadaFinance.orderNumber"),
            fieldName: "orderNumber",
            minWidth: 125,
            maxWidth: 125,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: true,
            isSorted: false,
            onColumnClick: "",
            data: "string",
            onRender: (item) => {
                return <TooltipHost content={item.orderNumber}>{item.orderNumber}</TooltipHost>;
            },
            // isPadded: true,
        },
        {
            key: "column5",
            name: t("lazadaFinance.orderItemID"),
            fieldName: "orderItemID",
            minWidth: 125,
            maxWidth: 125,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            onColumnClick: "",
            data: "string",
            onRender: (item) => {
                return <TooltipHost content={item.orderItemID}>{item.orderItemID}</TooltipHost>;
            },
            // isPadded: true,
        },
        {
            key: "column6",
            name: t("lazadaFinance.itemName"),
            fieldName: "itemName",
            minWidth: 30,
            maxWidth: 60,
            isResizable: true,
            isCollapsible: true,
            data: "string",
            onRender: (item) => {
                return <TooltipHost content={item.itemName}>{item.itemName}</TooltipHost>;
            },
            // isPadded: true,
        },
        {
            key: "column7",
            name: t("lazadaFinance.comment"),
            fieldName: "comment",
            minWidth: 80,
            maxWidth: 80,
            isResizable: true,
            isCollapsible: true,
            data: "string",
            onRender: (item) => {
                return <TooltipHost content={item.comment}>{item.comment}</TooltipHost>;
            },
            // isPadded: true,
        },
        {
            key: "column8",
            name: t("lazadaFinance.amount"),
            fieldName: "amount",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            data: "string",
            onRender: (item) => {
                return <TooltipHost content={item.amount}>{item.amount}</TooltipHost>;
            },
        },
        {
            key: "column9",
            name: t("lazadaFinance.statementPeriod"),
            fieldName: "statementPeriod",
            minWidth: 180,
            maxWidth: 180,
            isResizable: true,
            data: "string",
            onRender: (item) => {
                return <TooltipHost content={item.statementPeriod}>{item.statementPeriod}</TooltipHost>;
            },
        },
        {
            key: "column10",
            name: t("lazadaFinance.shipmentProvider"),
            fieldName: "shipmentProvider",
            minWidth: 120,
            maxWidth: 120,
            isResizable: true,

            data: "string",
            onRender: (item) => {
                return <TooltipHost content={item.shipmentProvider}>{item.shipmentProvider}</TooltipHost>;
            },
        },
        {
            key: "column11",
            name: t("lazadaFinance.taxInvoiceNumber"),
            fieldName: "taxInvoiceNumber",
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            data: "string",
            onRender: (item) => {
                return <TooltipHost content={item.taxInvoiceNumber}>{item.taxInvoiceNumber}</TooltipHost>;
            },
        }
    ];

    const getMonth = (goBack) => {
        let monthNames = [t("lazada.months01"), t("lazada.months02"), t("lazada.months03"), t("lazada.months04"), t("lazada.months05"), t("lazada.months06"), t("lazada.months07"), t("lazada.months08"), t("lazada.months09"), t("lazada.months10"), t("lazada.months11"), t("lazada.months12")];

        let d = new Date();

        let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

        let year = adjustDate.getFullYear() + 543;

        return {
            month: adjustDate.getMonth(),
            year: adjustDate.getFullYear(),
            key: adjustDate.getMonth() + 1,
            text: monthNames[adjustDate.getMonth()] + " " + year,
        };
    };

    const [queryMonth, setQueryMonth] = useState(
        (sessionStorage.getItem("lazadaFinance-queryMonth-key") && {
            key: sessionStorage.getItem("lazadaFinance-queryMonth-key"),
            text: sessionStorage.getItem("lazadaFinance-queryMonth-text"),
        }) || { key: getMonth(0).year + "" + getMonth(0).key, text: t("lazada.thisMonth") }
    );

    const [timeRangeDate, setTimeRangeDate] = useState(false);
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [timeRangeError, setTimeRangeError] = useState(false);

    const getFrom = (month, year) => {
        let _firstDay = new Date(year, month, 1);

        return formatDate(_firstDay);
    };

    const getTo = (month, year) => {
        let _lastDay = new Date(year, month + 1, 1);

        return formatDate(_lastDay);
    };

    const getFrom2 = (day, month, year) => {
        let _firstDay = new Date(year, month, day);

        return formatDate(_firstDay);
    };

    const getTo2 = (day, month, year) => {
        let _lastDay = new Date(year, month, day + 1);

        return formatDate(_lastDay);
    };

    const formatDate = (d) => {
        let month = "" + (d.getMonth() + 1);
        let day = "" + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };

    const [date, setDate] = useState(sessionStorage.getItem("lazadaFinance-date") ? new Date(sessionStorage.getItem("lazadaFinance-date")) : new Date());

    const [from, setFrom] = useState(sessionStorage.getItem("lazadaFinance-from") || getFrom(getMonth(0).month, getMonth(0).year));
    const [to, setTo] = useState(sessionStorage.getItem("lazadaFinance-to") || getTo(getMonth(0).month, getMonth(0).year));

    const history = useHistory();

    function getDateText(monthObject) {
        if (monthObject.type === "dateRange") {
            if (i18n.language === "th") {
                return onFormatDate(fromDate, "BE") + " - " + onFormatDate(toDate, "BE");
            }
            return onFormatDate(fromDate, "CE") + " - " + onFormatDate(toDate, "CE");
        }

        if (monthObject.text === "เดือนนี้" || monthObject.text === "This Month") return t("lazada.thisMonth");

        let monthNames = [t("lazada.months01"), t("lazada.months02"), t("lazada.months03"), t("lazada.months04"), t("lazada.months05"), t("lazada.months06"), t("lazada.months07"), t("lazada.months08"), t("lazada.months09"), t("lazada.months10"), t("lazada.months11"), t("lazada.months12")];

        let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
        let _year = Number(monthObject.key.substring(0, 4));
        if (i18n.language === "th") {
            _year = _year + 543;
        }

        return monthNames[_monthIndex] + " " + _year;
    }

    const commandBarItems = [
        {
            key: queryMonth.key,
            text: getDateText(queryMonth),
            iconProps: { iconName: "Calendar" },
            disabled: !loadDataComplete,
            subMenuProps: {
                items: [
                    {
                        key: "queryMonth",
                    },
                ],
                onRenderMenuList: () => {
                    return (
                        <div style={{ width: 220 }}>
                            <Stack styles={{ root: { padding: "12px 12px 0px 12px" } }}>
                                <Checkbox
                                    label={t("sellSummary.timeRange")}
                                    checked={timeRangeDate}
                                    onChange={(e) => {
                                        setTimeRangeDate((prev) => !prev);
                                    }}
                                />
                            </Stack>
                            {timeRangeDate ? (
                                <Stack>
                                    <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                                        <Stack styles={{ root: { width: "20%", justifyContent: "center", alignItems: "center" } }}>
                                            <Text>{t("sellSummary.fromRange")}</Text>
                                        </Stack>
                                        <Stack styles={{ root: { width: "80%", justifyContent: "center" /* alignItems: 'center' */ } }}>
                                            <DatePicker
                                                styles={{ root: { width: "90%" } }}
                                                placeholder={t("deliveryOrderTaxInvoice.pleaseSelect")}
                                                strings={DayPickerStrings}
                                                value={fromDate}
                                                maxDate={toDate}
                                                formatDate={onFormatDate}
                                                onSelectDate={(_date) => {
                                                    setFromDate(_date);

                                                    let adjustDate = _date;

                                                    setFrom(getFrom2(adjustDate.getDate(), adjustDate.getMonth(), adjustDate.getFullYear()));

                                                    setTimeRangeError(false);
                                                    if (Math.ceil(Math.abs(_date - toDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                                                }}
                                            />
                                        </Stack>
                                    </Stack>
                                    <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                                        <Stack styles={{ root: { width: "20%", justifyContent: "center", alignItems: "center" } }}>
                                            <Text>{t("sellSummary.toRange")}</Text>
                                        </Stack>
                                        <Stack styles={{ root: { width: "80%" /* alignItems: 'center' */ } }}>
                                            <DatePicker
                                                styles={{ root: { width: "90%" } }}
                                                placeholder={t("deliveryOrderTaxInvoice.pleaseSelect")}
                                                strings={DayPickerStrings}
                                                value={toDate}
                                                minDate={fromDate}
                                                formatDate={onFormatDate}
                                                onSelectDate={(_date) => {
                                                    setToDate(_date);

                                                    let adjustDate = _date;

                                                    setTo(getTo2(adjustDate.getDate(), adjustDate.getMonth(), adjustDate.getFullYear()));
                                                    setTimeRangeError(false);
                                                    if (Math.ceil(Math.abs(_date - fromDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                                                }}
                                            />
                                        </Stack>
                                    </Stack>
                                    {timeRangeError ? (
                                        <Stack styles={{ root: { padding: "12px 0px 0px 12px" } }}>
                                            <Text styles={{ root: { fontSize: 12, color: "#A4262C" } }}>{t("sellSummary.timeRangeError")}</Text>
                                        </Stack>
                                    ) : null}
                                    <Stack horizontal horizontalAlign="end" styles={{ root: { width: "92%", height: 52, padding: "10px 0px 10px 0px" } }}>
                                        <PrimaryButton
                                            text={t("sellSummary.search")}
                                            onClick={() => {
                                                if (!timeRangeError && fromDate && toDate) {
                                                    setQueryMonth({
                                                        type: "dateRange",
                                                        key: formatDate(fromDate) + ":" + formatDate(toDate),
                                                        text: formatDate(fromDate) + " - " + formatDate(toDate),
                                                    });

                                                    setSearch("");

                                                    setLoadDataComplete(false);
                                                    setItems([]);
                                                    setGetNewDocuments(true);

                                                    setCurrentPage(1);
                                                }
                                            }}
                                            style={{ width: "125px", letterSpacing: "1px" }}
                                        />
                                    </Stack>
                                </Stack>
                            ) : (
                                <Calendar
                                    onSelectDate={(_date) => {
                                        let monthNames = [
                                            t("lazada.months01"),
                                            t("lazada.months02"),
                                            t("lazada.months03"),
                                            t("lazada.months04"),
                                            t("lazada.months05"),
                                            t("lazada.months06"),
                                            t("lazada.months07"),
                                            t("lazada.months08"),
                                            t("lazada.months09"),
                                            t("lazada.months10"),
                                            t("lazada.months11"),
                                            t("lazada.months12"),
                                        ];

                                        setDate(_date);

                                        sessionStorage.setItem("lazadaFinance-date", _date.toISOString());

                                        let adjustDate = _date;

                                        let year = adjustDate.getFullYear() + 543;

                                        setQueryMonth({
                                            key: adjustDate.getFullYear() + "" + String(adjustDate.getMonth() + 1).padStart(2, "0"),
                                            text: monthNames[adjustDate.getMonth()] + " " + year,
                                        });

                                        sessionStorage.setItem("lazadaFinance-queryMonth-key", adjustDate.getFullYear() + "" + String(adjustDate.getMonth() + 1).padStart(2, "0"));
                                        sessionStorage.setItem("lazadaFinance-queryMonth-text", monthNames[adjustDate.getMonth()] + " " + year);

                                        setFrom(getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                                        setTo(getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                                        sessionStorage.setItem("lazadaFinance-from", getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                                        sessionStorage.setItem("lazadaFinance-to", getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                                        setSearch("");

                                        setLoadDataComplete(false);
                                        setItems([]);
                                        setGetNewDocuments(true);

                                        setCurrentPage(1);
                                    }}
                                    isMonthPickerVisible={true}
                                    highlightSelectedMonth={true}
                                    isDayPickerVisible={false}
                                    dateRangeType={DateRangeType.Day}
                                    autoNavigateOnSelection={false}
                                    showGoToToday={false}
                                    value={date}
                                    strings={DayPickerStrings}
                                    showSixWeeksByDefault={true}
                                />
                            )}
                        </div>
                    );
                },
            },
        },
        {
            key: "pageSize " + pageSize,
            text: pageSize + " " + t("lazada.documentPerPage"),
            cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
            iconProps: { iconName: "NumberedList" },
            disabled: !loadDataComplete || search,
            subMenuProps: {
                items: [
                    {
                        key: "pageSize " + 10,
                        text: "10 " + t("lazada.documentPerPage"),
                        iconProps: { iconName: "NumberedList" },
                        onClick: () => {
                            console.log("10 ออเดอร์/หน้า");
                            setPageSize(10);
                            sessionStorage.setItem("lazadaFinance-pageSize", 10);

                            setSearch("");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);
                        },
                    },
                    {
                        key: "pageSize " + 20,
                        text: "20 " + t("lazada.documentPerPage"),
                        iconProps: { iconName: "NumberedList" },
                        onClick: () => {
                            console.log("20 ออเดอร์/หน้า");
                            setPageSize(20);
                            sessionStorage.setItem("lazadaFinance-pageSize", 20);

                            setSearch("");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);
                        },
                    },
                    {
                        key: "pageSize " + 30,
                        text: "30 " + t("lazada.documentPerPage"),
                        iconProps: { iconName: "NumberedList" },
                        onClick: () => {
                            console.log("30 ออเดอร์/หน้า");
                            setPageSize(30);
                            sessionStorage.setItem("lazadaFinance-pageSize", 30);

                            setSearch("");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);
                        },
                    },
                    {
                        key: "pageSize " + 40,
                        text: "40 " + t("lazada.documentPerPage"),
                        iconProps: { iconName: "NumberedList" },
                        onClick: () => {
                            console.log("40 ออเดอร์/หน้า");
                            setPageSize(40);
                            sessionStorage.setItem("lazadaFinance-pageSize", 40);

                            setSearch("");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);
                        },
                    },
                    {
                        key: "pageSize " + 50,
                        text: "50 " + t("lazada.documentPerPage"),
                        iconProps: { iconName: "NumberedList" },
                        onClick: () => {
                            console.log("50 ออเดอร์/หน้า");
                            setPageSize(50);
                            sessionStorage.setItem("lazadaFinance-pageSize", 50);

                            setSearch("");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);
                        },
                    },
                ],
            },
        },
        {
            key: currentPage,
            text: t("lazada.page") + " " + currentPage,
            iconProps: { iconName: "Page" },
            disabled: !loadDataComplete || search,
            subMenuProps: {
                items: pageDropdown,
            },
        },
        {
            key: "refresh",
            text: t("lazada.refresh"),
            iconProps: { iconName: "Refresh" },
            disabled: !loadDataComplete || search,
            onClick: () => {
                console.log("Refresh");

                setSearch("");

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setCurrentPage(1);

                setIsCreateDocumentComplete(false);
                setSelectedItems([]);
            },
        },
        {
            key: "download-csv",
            text: t('documents.download') + ' CSV',
            iconProps: { iconName: "ExcelDocument" },
            disabled: !loadDataComplete || search,
            onClick: () => {
                handleDownloadCSV();
            },
        },
    ];

    const handleDownloadCSV = () => {
        console.log("download")

        if (items.length > 0) {
          const _csvData = [];
    
          items.forEach((doc) => {
            const { 
                order_no, 
                transaction_date, 
                amount, 
                paid_status, 
                shipping_provider, 
                WHT_included_in_amount, 
                payment_ref_id, 
                lazada_sku, 
                transaction_type, 
                orderItem_no, 
                orderItem_status, 
                reference, 
                fee_name, 
                shipping_speed, 
                WHT_amount, 
                transaction_number, 
                seller_sku, 
                statement, 
                details, 
                comment, 
                VAT_in_amount, 
                shipment_type} = doc.data

            _csvData.push({
                A: '=""' + transaction_date + '""',
                B: '=""' + transaction_type + '""',
                C: '=""' + fee_name + '""',
                D: '=""' + transaction_number + '""',
                E: details ? '=""' + details + '""' : '',
                F: seller_sku ? '=""' + seller_sku + '""' : '',
                G: lazada_sku ? '=""' + lazada_sku + '""' : '',
                H: '=""' + amount + '""',
                I: '=""' + VAT_in_amount + '""',
                J: '=""' + WHT_amount + '""',
                K: '=""' + WHT_included_in_amount + '""',
                L: '=""' + statement + '""',
                M: '=""' + paid_status + '""',
                N: order_no ? '=""' + order_no + '""' : '',
                O: orderItem_no ? '=""' + orderItem_no + '""' : '',
                P: orderItem_status ? '=""' + orderItem_status + '""' : '',
                Q: shipping_provider ? '=""' + shipping_provider + '""' : '',
                R: shipping_speed ? '=""' + shipping_speed + '""' : '',
                S: shipment_type ? '=""' + shipment_type + '""' : '',
                T: '=""' + reference + '""',
                U: comment ? '=""' + comment + '""' : '',
                V: payment_ref_id ? '=""' + payment_ref_id + '""' : '',
                W: '=""' + doc.taxInvoiceNumber + '""',
              });;
          });
          
          setCsvData((prev) => ({ ...prev, fileName: formatDateTime(new Date().toISOString()) + '-CSV-Transaction List', data: _csvData, downloaded: true }));
        }

      };

    useDidMountEffect(() => {
        if (csvData.downloaded) {
            csvLink.current.link.click();
            setCsvData({ fileName: '', data: [], downloaded: false });
        }
    }, [csvData]);

    useEffect(() => {
        console.log("userEffect initial call!");

        setIsLoading(true);

        instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then((tokenResponse) => {
                axios
                    .get(API_URL + "/etax/companies", {
                        headers: {
                            Authorization: "Bearer " + tokenResponse.accessToken,
                            "cid": companyId
                        },
                    })
                    .then(
                        (response) => {
                            console.log(response);

                            if (response.data[0]) {
                                console.log(response.data[0]);
                                setCompany(response.data[0]);

                                setGetNewDocuments(true);
                            } else {
                                history.push({
                                    pathname: "/etax/companies/profiles",
                                    state: {},
                                });
                            }

                            setIsLoading(false);
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
            })
            .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
            });

        instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then((tokenResponse) => {
                axios
                    .get(API_URL + "/sources/lazada", {
                        headers: {
                            Authorization: "Bearer " + tokenResponse.accessToken,
                            "cid": companyId
                        },
                    })
                    .then(
                        (response) => {
                            console.log("lazada source response", response);

                            if (response.data?.automation?.enable) {
                                // setTriggerStatus(_triggerStatus);
                                // setReceiptTaxInvoice(_receiptTaxInvoice);
                                // setSendEmail(_sendEmail);
                                // setShowShippingFee(_showShippingFee);
                                // setOnlyBillingAddress(_onlyBillingAddress);
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
            })
            .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (getNewDocuments) {
                console.log("getNewOrder Running!!!");
                instance
                    .acquireTokenSilent({ ...silentRequest, account: account })
                    .then(async (tokenResponse) => {
                        const [response, responseAllDocs] = await Promise.all([
                            axios
                                // .get("http://localhost:7071/api/Lazada_getTransactionDetail", {
                                .get(API_URL + "/sources/lazada/finances/transactions", {
                                    headers: {
                                        Authorization: "Bearer " + tokenResponse.accessToken,
                                        "cid": companyId
                                    },
                                    params: {
                                        start_time: from,
                                        end_time: to,
                                        limit: pageSize,
                                        offset: (currentPage - 1) * pageSize,
                                    },
                                })
                                .catch((error) => console.log(error)),

                            axios
                                .get(API_URL + "/etax/documents/ALL/all?from=" + from + "&to=" + to + "&date=" + dateType.key, {
                                    headers: {
                                        Authorization: "Bearer " + tokenResponse.accessToken,
                                        "cid": companyId
                                    },
                                })
                                .catch((error) => console.log(error)),
                        ]);

                        setPageData(paginate(525, currentPage, pageSize, 10));

                        console.log("response", response)
                        console.log("responseAllDocs", responseAllDocs)
                        const transactionList = response.data.data
                        // const transactionList = dataList
                        const _docs = [];

                        if (transactionList.length > 0) {
                            for (let i = 0; i < Math.min(pageSize, transactionList.length); i++) {
                                const { 
                                    order_no, 
                                    transaction_date, 
                                    amount, 
                                    shipping_provider, 
                                    transaction_type, 
                                    orderItem_no, 
                                    transaction_number, 
                                    statement, 
                                    details, 
                                    comment, 
                                } = transactionList[i]
                                
                                _docs.push({
                                    key: order_no + "-" + i,
                                    transactionDate: transaction_date,
                                    transactionType: transaction_type,
                                    transactionNumber: transaction_number,
                                    orderNumber: order_no,
                                    orderItemID: orderItem_no,
                                    itemName: details,
                                    comment: comment,
                                    amount: amount,
                                    statementPeriod: statement,
                                    shipmentProvider: shipping_provider,
                                    taxInvoiceNumber: responseAllDocs.data.some((_allDocs) => String(_allDocs.data.reference) === order_no) ? responseAllDocs.data.find((_allDocs) => String(_allDocs.data.reference) === order_no).data.number : "",
                                    data: transactionList[i]
                                });
                            }
                        }

                        setItems(_docs);
                        setLoadDataComplete(true);
                        setGetNewDocuments(false);
                    })
                    .catch((error) => {
                        //Acquire token silent failure, and send an interactive request
                        console.log(error);
                        // instance.acquireTokenRedirect(silentRequest);
                        // disconnect(true);
                    });
            
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getNewDocuments]);

    useEffect(() => {
        sessionStorage.setItem("lazadaFinance-currentPage", currentPage);

        if (company && company.name) {
            setLoadDataComplete(false);

            setItems([]);
            setGetNewDocuments(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    useEffect(() => {
        console.log("isCreateDocumentComplete useEffect: ", isCreateDocumentComplete);

        if (loadDataComplete) {
            let _items = selection.getItems();
            console.log("_items: ", _items);

            let _selectedItems = selection.getSelection();

            if (isCreateDocumentComplete) {
                setIsCreateDocumentComplete(false);

                for (let i = 0; i < _items.length; i++) {
                    if (_items[i].status !== "") {
                        _items[i].status = "";
                    }
                }
            } else {
                for (let i = 0; i < _items.length; i++) {
                    if (_items[i].status !== "") {
                        _items[i].status = "";
                    }
                }

                for (let i = 0; i < _selectedItems.length; i++) {
                    console.log("select item key: ", _selectedItems[i].key);

                    for (let j = 0; j < _items.length; j++) {
                        if (_items[j].key === _selectedItems[i].key) {
                            console.log("set pending item key: ", _items[j].key);
                            _items[j].status = "pending";
                            console.log("_items: ", _items);
                        }
                    }
                }
            }

            setItems(_items);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItems]);

    const onPageChange = (selectedPageIndex) => {
        console.log("selectedPageIndex: ", selectedPageIndex);
        setCurrentPage(selectedPageIndex + 1);
    };

    return (
        <div style={homeStyles}>
            {!isLoading ? (
                <div>
                    <Stack>
                        <center>
                            <h2 style={{ marginLeft: "0px", marginTop: "25px" }}>{t("lazadaFinance.header")}</h2>
                            {!loadDataComplete && (
                                <div>
                                    <Spinner
                                        label={t("lazada.loading")}
                                        labelPosition="right"
                                        style={{
                                            marginTop: "21px",
                                            marginBottom: "2px",
                                        }}
                                    />
                                    <br />
                                </div>
                            )}
                        </center>
                    </Stack>

                    {(selectedItems.length > 0 || isCreateDocumentComplete) && (
                        <Stack vertical tokens={{ childrenGap: "10px" }}>
                            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "10px" }}>
                                <h3>{t("lazada.subHeader")}</h3>
                            </Stack>
                            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "10px" }}>
                                <TextField
                                    name="prefixNumber"
                                    label={t("lazada.prefixNumber")}
                                    description={t("lazada.prefixNumberDescription")}
                                    styles={{ root: { width: 200 } }}
                                    onChange={(e) => {
                                        setPrefixNumber(e.target.value);
                                    }}
                                    value={prefixNumber}
                                />
                                <TextField
                                    name="countingNumber"
                                    label={t("lazada.countingNumber")}
                                    description={countingNumber.length === 0 ? "" : t("lazada.countingNumberDesciption")}
                                    styles={{ root: { width: 150 } }}
                                    onChange={(e) => {
                                        if (!/\D/.test(e.target.value)) {
                                            setCountingNumber(e.target.value);
                                        }
                                    }}
                                    value={countingNumber}
                                    errorMessage={countingNumber.length === 0 ? t("lazada.countingNumberErrorMessage") : ""}
                                    required
                                />
                            </Stack>
                            {countingNumber.length > 0 ? (
                                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "10px" }}>
                                    <h4>
                                        {t("lazada.startDocumentNumber")} {prefixNumber}
                                        {countingNumber}
                                    </h4>
                                </Stack>
                            ) : (
                                <br />
                            )}
                        </Stack>
                    )}

                    {isCreateDocumentComplete && (
                        <center>
                            <PrimaryButton
                                text={t("lazada.createdDocuments")}
                                iconProps={{ iconName: "Documentation" }}
                                onClick={async () => {
                                    console.log(selectedItems);
                                    history.push({
                                        pathname: "/etax/documents/all",
                                        state: {},
                                    });
                                }}
                            />
                        </center>
                    )}
                    <br />

                    <Stack horizontal horizontalAlign="center">
                        <Stack
                            vertical
                            style={{
                                width: "98%",
                                minWidth: "800px",
                                maxWidth: "1350px",
                            }}
                        >
                            <Stack 
                                horizontal 
                                styles={{
                                    root: {
                                        backgroundColor: 'white',
                                    },
                                }} 
                                horizontalAlign="space-between"
                            >
                                <Stack
                                    styles={{
                                        root: {
                                        width: 'calc(100vw - 700px)',
                                        minWidth: '700px',
                                        maxWidth: '900px',
                                        },
                                    }}
                                    >
                                    <CommandBar
                                        items={commandBarItems}
                                    />
                                </Stack>
                            </Stack>
                            <ShimmeredDetailsList
                                items={items || []}
                                enableShimmer={!loadDataComplete}
                                compact={isCompactMode}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                // selection={selection}
                                // selectionMode={!loadDataComplete || isCreateDocument ? SelectionMode.none : SelectionMode.multiple}
                                // layoutMode={DetailsListLayoutMode.justified}
                                isHeaderVisible={true}
                                // checkboxVisibility={CheckboxVisibility.always}
                                selectionPreservedOnEmptyClick={true}
                            />
                        </Stack>
                    </Stack>
                    <br />
                    <center>
                        {!(items.length === 0 || (currentPage === 1 && items.length < pageSize)) && !(!loadDataComplete) && (
                            <Pagination
                                selectedPageIndex={currentPage - 1}
                                pageCount={pageData.totalPages}
                                onPageChange={onPageChange}
                                format
                                firstPageIconProps={{
                                    iconName: "DoubleChevronLeft",
                                }}
                                previousPageIconProps={{
                                    iconName: "ChevronLeft",
                                }}
                                nextPageIconProps={{ iconName: "ChevronRight" }}
                                lastPageIconProps={{
                                    iconName: "DoubleChevronRight",
                                }}
                            />
                        )}

                        <br />
                        <br />
                    </center>
                </div>
            ) : (
                <Stack>
                    <Stack horizontal horizontalAlign="center">
                        <br />
                        <br />
                        <div className="certificates-loading-center">
                            <Spinner label={t("lazada.loading")} labelPosition="down" styles={spinnerStyles} />
                        </div>
                        <br />
                    </Stack>
                </Stack>
            )}
            <CSVLink style={{ display: 'none' }} ref={csvLink} filename={csvData.fileName} data={csvData.data} headers={headerCSV}>
                Download csv
            </CSVLink>
        </div>
    );
}

export default LazadaFinance;
