import { Button,
  TableCellLayout,
  makeStyles,
  Menu,
  MenuTrigger,
  MenuButton,
  MenuPopover,
  MenuList,
  MenuItem,
  DataGrid,
  DataGridHeader,
  DataGridRow,
  DataGridHeaderCell,
  DataGridBody,
  DataGridCell,
  TabList,
  Tab,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
  DialogActions,
  Field,
  Input,
  Spinner,
  ToolbarButton,
  Title2,
  Subtitle2Stronger,
  Link,
  Tooltip,
  Popover,
  PopoverSurface,
  Divider,
  Dropdown,
  Option,
  shorthands,
  useId,
  Switch,
  } from '@fluentui/react-components';

  import { Alert } from "@fluentui/react-components/unstable";

  import { useMsal, useAccount } from '@azure/msal-react';
  import { silentRequest } from '../../../authConfig';

  import { useHistory, useLocation } from 'react-router-dom';

import {
    FolderRegular,
    DocumentRegular,
    DocumentPdfRegular,
    AddRegular,
    ArrowUploadRegular,
    ChevronRightRegular,
    DeleteRegular,
    ArrowDownloadRegular,
    ShareIosRegular,
    DismissCircleRegular,
    MoreHorizontalRegular,
    ImageRegular,
    CloudBidirectionalRegular,
    CheckmarkRegular,
    DismissRegular,
    ShareRegular,
    VideoClipRegular,
    DocumentTableRegular,
    FolderZipRegular,
    SearchRegular,
    ArrowClockwiseRegular,
    ShareScreenPersonRegular,
    PlugDisconnectedRegular,
    StorageRegular
    } from "@fluentui/react-icons";
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { niceBytes } from '../../../helpers/ConvertFileSize';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';

// const API_URL = process.env.REACT_APP_API_URL;
const redirectUriMSOneDrive = "http://localhost:3000/storages/one-drive"
const callBackUrlMSOneDrive = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=91f9f531-4187-4603-bb50-bbe13e18bc7c&response_type=code&redirect_uri=${redirectUriMSOneDrive}&response_mode=query&scope=https://graph.microsoft.com/files.readwrite https://graph.microsoft.com/User.Read offline_access`

const redirectUriGoogleDrive = "http://localhost:3000/storages/google-drive"
const callBackUrlGoogleDrive = `https://accounts.google.com/o/oauth2/auth?client_id=276206940953-38dotjr1e7fe2mabt4sd1f098tq2lj23.apps.googleusercontent.com&response_type=code&redirect_uri=${redirectUriGoogleDrive}&response_mode=query&scope=https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.file`

const useStyles = makeStyles({
  stack: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: 'auto',
    height: 'auto',
    boxSizing: 'border-box',
    '> *': {
      textOverflow: 'ellipsis',
    },
    '> :not(:first-child)': {
      marginTop: '0px',
    },
    '> *:not(.ms-StackItem)': {
      flexShrink: 1,
    },
  },
  wrapper: {
    columnGap: "5px",
    display: "flex",
  },
  button: {
    // display: "flex", 
    // justifyContent: 'center', 
    // alignItems: 'center', 
    // fontWeight: 600,
    // ':hover': {
    //   color: tokens.colorNeutralForeground2BrandHover
    // }
  },
  root: {
    // Stack the label above the field with a gap
    display: "grid",
    // gridTemplateRows: "repeat(1fr)",
    // justifyItems: "start",
    ...shorthands.gap("2px"),
    width: '100%'
    // maxWidth: "400px",
  },
})

const toThaiDateString = (isoDateTime) => {
  let date = '';
  date = new Date(isoDateTime);
  let year = date.getFullYear() + 543;
  let month = (date.getMonth() + 1).toString().padStart(2, '0');
  let numOfDay = date.getDate().toString().padStart(2, '0');

  let hour = date.getHours().toString().padStart(2, '0');
  let minutes = date.getMinutes().toString().padStart(2, '0');

  return `${numOfDay}/${month}/${year} ${hour}:${minutes} น.`;
};

const twentyFiveMB = 25 * 1024 * 1024;

const getMedia = (mimeType) => {
  let media = null
  
  if (mimeType.includes('pdf')) {
    media = <DocumentPdfRegular />
  } else if (mimeType.includes('image')) {
    media = <ImageRegular />
  } else if (mimeType.includes('document')) {
    media = <DocumentRegular />
  } else if (mimeType.includes('zip')) {
    media = <FolderZipRegular />
  } else if (mimeType.includes('video')) {
    media = <VideoClipRegular />
  } else if (mimeType.includes('spreadsheet')) {
    media = <DocumentTableRegular />
  } else if (mimeType.includes('folder')) {
    media = <FolderRegular />
  } else if (mimeType.includes('presentation')) {
    media = <ShareScreenPersonRegular />
  } else {
    media = <DocumentRegular />
  }

  return media
}

function GoogleDriveStorages() {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();

  /* 
  const { accessKeyId,
    secretAccessKey,
    storage,
    region,
    bucket} = location.state
 */
  const styles = useStyles();

  const [items, setItems] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [selectedItemsKey, setSelectedItemsKey] = useState([])
  const [tabValue, setTabValue] = useState('')
  const [tabListValue, setTabListValue] = useState([
    {
      id: "",
      name: "Doc"
    }
  ])
  const [dialog, setDialog] = useState({
    open: false,
    loading: false,
    headers: '',
    value: '',
    onSubmit: null,
    onCancel: null,
    content: function () {
      return null
    },
    onSubmitLabel: '',
    onCancelLabel: '',
    dowloadingProgress: 0
  })

  const [backup, setBackup] = useState(false)

  const [dialogConnectNewDrive, setDialogConnectNewDrive] = useState({
    open: false,
    loading: false,
    status: '',
    storage: '',
    region: '',
    endpoint: '',
    accessKeyId: '',
    secretAccessKey: '',
    bucket: '',
    buckets: [],
    selectedBucket: '',
  })

  const dropdownId = useId("dropdown");

  const storageOptions = [
    { key: "amazon", text: "Amazon" },
    { key: "wasabi", text: "Wasabi" },
    { key: "storj", text: "Storj" },
    { key: "oneDrive", text: "Microsoft One Drive" },
    { key: "googleDrive", text: "Google Drive" },
  ]

  const endpointAmazonS3 = [
    { key: "s3.ap-southeast-1.amazonaws.com", text: "ap-southeast-1" },
    { key: "s3.ap-southeast-2.amazonaws.com", text: "ap-southeast-2" },
    { key: "s3.ap-southeast-3.amazonaws.com", text: "ap-southeast-3" },
    { key: "s3.ap-southeast-4.amazonaws.com", text: "ap-southeast-4" },
    { key: "s3.ap-northeast-1.amazonaws.com", text: "ap-northeast-1" },
    { key: "s3.ap-northeast-2.amazonaws.com", text: "ap-northeast-2" },
    { key: "s3.ap-northeast-3.amazonaws.com", text: "ap-northeast-3" },
    { key: "s3.ap-south-1.amazonaws.com", text: "ap-south-1" },
    { key: "s3.ap-south-2.amazonaws.com", text: "ap-south-2" },
    { key: "s3.ap-east-1.amazonaws.com", text: "ap-east-1" },
    { key: "s3.us-east-2.amazonaws.com", text: "us-east-2" },
    { key: "s3.us-east-1.amazonaws.com", text: "us-east-1" },
    { key: "s3.us-west-1.amazonaws.com", text: "us-west-1" },
    { key: "s3.us-west-2.amazonaws.com", text: "us-west-2" },
    { key: "s3.us-gov-east-1.amazonaws.com", text: "us-gov-east-1" },
    { key: "s3.us-gov-west-1.amazonaws.com", text: "us-gov-west-1" },
    { key: "s3.af-south-1.amazonaws.com", text: "af-south-1" },
    { key: "s3.ca-central-1.amazonaws.com", text: "ca-central-1" },
    { key: "s3.cn-north-1.amazonaws.com", text: "cn-north-1" },
    { key: "s3.cn-northwest-1.amazonaws.com", text: "cn-northwest-1" },
    { key: "s3.eu-central-1.amazonaws.com", text: "eu-central-1" },
    { key: "s3.eu-west-1.amazonaws.com", text: "eu-west-1" },
    { key: "s3.eu-west-2.amazonaws.com", text: "eu-west-2" },
    { key: "s3.eu-south-1.amazonaws.com", text: "eu-south-1" },
    { key: "s3.eu-west-3.amazonaws.com", text: "eu-west-3" },
    { key: "s3.eu-north-1.amazonaws.com", text: "eu-north-1" },
    { key: "s3.eu-south-2.amazonaws.com", text: "eu-south-2" },
    { key: "s3.eu-central-2.amazonaws.com", text: "eu-central-2" },
    { key: "s3.sa-east-1.amazonaws.com", text: "sa-east-1" },
    { key: "s3.me-south-1.amazonaws.com", text: "me-south-1" },
    { key: "s3.me-central-1.amazonaws.com", text: "me-central-1" }
  ];

  const endpointWasabiS3 = [
    { key: "s3.ap-southeast-1.wasabisys.com", text: "ap-southeast-1" },
    { key: "s3.ap-southeast-2.wasabisys.com", text: "ap-southeast-2" },
    { key: "s3.ap-northeast-1.wasabisys.com", text: "ap-northeast-1" },
    { key: "s3.ap-northeast-2.wasabisys.com", text: "ap-northeast-2" },
    { key: "s3.us-east-1.wasabisys.com", text: "us-east-1" },
    { key: "s3.us-east-2.wasabisys.com", text: "us-east-2" },
    { key: "s3.us-central-1.wasabisys.com", text: "us-central-1" },
    { key: "s3.us-west-1.wasabisys.com", text: "us-west-1" },
    { key: "s3.ca-central-1.wasabisys.com", text: "ca-central-1" },
    { key: "s3.eu-central-1.wasabisys.com", text: "eu-central-1" },
    { key: "s3.eu-central-2.wasabisys.com", text: "eu-central-2" },
    { key: "s3.eu-west-1.wasabisys.com", text: "eu-west-1" },
    { key: "s3.eu-west-2.wasabisys.com", text: "eu-west-2" }    
  ];

  // const [endpointWasabiS3, setEndpointWasabiS3] = useState([]);
  // const [endpointStorjS3, setEndpointStorjS3] = useState([]);
  const [optionsEndpointS3, setOptionsEndpointS3] = useState(() => {
    if (sessionStorage.getItem('storage-key') === 'amazon') {
      return endpointAmazonS3
    } else if (sessionStorage.getItem('storage-key') === 'wasabi') {
      return endpointWasabiS3
    } else if (sessionStorage.getItem('storage-key') === 'storj') {
      return [{key: "gateway.storjshare.io", text: "storjshare-gateway"}]
    } else {
      return endpointAmazonS3
    }
    
  });

  useEffect(() => {
    console.log('dialogConnectNewDrive', dialogConnectNewDrive)
  }, [dialogConnectNewDrive])
  

  const [isLoading, setIsLoading] = useState(true)
  const [getNewDocuments, setGetNewDocuments] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [nextPageTokenArray, setNextPageTokenArray] = useState([])

  const [currentFolderId, setCurrentFolderId] = useState('')

  const [drives, setDrives] = useState([])

  // const { id, name, owner, accessToken } = location.state;
  const [selectedDriveId, setSelectedDriveId] = useState(location.state ? location.state.id : '')

  const [pageDropdown, setPageDropdown] = useState([
    {
        key: 1,
        text: t("tiktokShop.page") + " " + 1,
        iconProps: { iconName: "Page" },
        onClick: () => {
            // console.log("Selected page: " + i);
            setItems([]);
            setIsLoading(true)
            setGetNewDocuments(true);
            setCurrentPage(1);
        },
    },
    // {
    //   key: 2,
    //   text: t("tiktokShop.page") + " " + 2,
    //   iconProps: { iconName: "Page" },
    //   onClick: () => {
    //       // console.log("Selected page: " + i);
    //       setCurrentPage(2);
    //   },
    // }
  ]);

  const fileInputRef = useRef();

  const [alert, setAlert] = useState({
    open: false,
    status: '',
    message: ''
  })

  const mainDiv = useRef({})

  const [loadingLink, setLoadingLink] = useState(false)

  const companyId = sessionStorage.getItem('companyId') || '';

  const columns = [
    { 
      columnId: "mimeType", 
      label: "", 
      icon: <DocumentRegular />,
      compare: (a, b) => {
        return a.type.localeCompare(b.type);
      },
      renderHeaderCell: () => {
        return t("storagesGoogleDrive.mimeType");
      },
      renderCell: (item) => {
        return (
          <TableCellLayout truncate media={getMedia(item.mimeType)} />
        );
      } 
    },
    { 
      columnId: "name", 
      label: "Name", 
      width: 100,
      compare: (a, b) => {
        return a.name.localeCompare(b.name);
      },
      renderHeaderCell: () => {
        return t("storages.name");
      },
      renderCell: (item) => {
        return (
          <ActionCell onClickEvent={() => handleClickLink('name', item)} item={item} />
        );
      }
    },
    { 
      columnId: "modifiedTime", 
      label: "ModifiedTime", 
      width: 100,
      compare: (a, b) => {
        return new Date(a.modifiedTime) - new Date(b.modifiedTime);
      },
      renderHeaderCell: () => {
        return t("storagesGoogleDrive.modifiedTime");
      },
      renderCell: (item) => {
        return (
          <TableCellLayout truncate>{toThaiDateString(item.modifiedTime)}</TableCellLayout>
        );
      }, 
    },
    { 
      columnId: "modifyingUser", 
      label: "ModifyingUser", 
      width: 100,
      compare: (a, b) => {
        return a.modifyingUser.localeCompare(b.modifyingUser);
      },
      renderHeaderCell: () => {
        return t("storagesGoogleDrive.modifyingUser");
      },
      renderCell: (item) => {
        return (
          <TableCellLayout truncate>{item.modifyingUser}</TableCellLayout>
        );
      }, 
    },
    { 
      columnId: "size", 
      label: "Size", 
      width: 100,
      compare: (a, b) => {
        return Number(a.size) - Number(b.size);
      },
      renderHeaderCell: () => {
        return t("storages.size");
      },
      renderCell: (item) => {
        return (
          <TableCellLayout truncate>{item.size ? niceBytes(item.size) : ''}</TableCellLayout>
        );
      }, 
    },
  ];

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const code = query.get('code');

    if (code) {
      getAccessToken(code)
    } else {
      getNewContents(selectedDriveId, nextPageTokenArray);
    }

    getDriveList()
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (getNewDocuments) {
      if (currentFolderId) {
        getNewContentsInFolder(currentFolderId, nextPageTokenArray)
      } else {
        getNewContents(selectedDriveId, nextPageTokenArray);
      }

    }
    // setIsLoading(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments])

  async function getDriveList() {
    axios
      .get('http://localhost:7071/api/Storages', {
        headers: {
          // Authorization: 'Bearer ' + tokenResponse.accessToken,
          // 'cid': companyId
        },
      }).then(res => {
        console.log("response storage", res)

        setDrives(res.data)

        const selectedDrive = res.data.find(_drive => _drive.id === selectedDriveId)

        if (selectedDrive) {
          setBackup(selectedDrive.backup)

        }
      })
  }

  async function getAccessToken(code) {
    instance
    .acquireTokenSilent({ ...silentRequest, account: account })
    .then((tokenResponse) => {
      axios
        .post(
          'http://localhost:7071/api/GoogleDrive_getAccessToken',
          {
            code: code,
            redirect_uri: redirectUriGoogleDrive
          },
          {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId
            },
          }
        )
        .then(
          (response) => {
            console.log('OneDrive_getAccessToken response', response);

            axios
              .post('http://localhost:7071/api/Storages', {...response.data, type: 'googleDrive'}, {
                headers: {
                  // Authorization: 'Bearer ' + tokenResponse.accessToken,
                  // 'cid': companyId
                },
              }).then(res => {
                console.log("response storage", res)

                setSelectedDriveId(response.data.user.emailAddress)

                getNewContents(response.data.user.emailAddress, []);
                
                getDriveList()
              })

            // if (response.data) {
            //   if (response.data.canLogin) {
            //     history.push({
            //       pathname: '/storages/one-drive',
            //       state: {},
            //     });
            //   } else {
            //     setStatus('fail');
            //   }
            // }

            return response.data
          },
          (error) => {
            console.log(error);
          }
        ).then((res) => {
          
          // getNewItemsInRootFolder(res);
        })
    })
    .catch((error) => {
      //Acquire token silent failure, and send an interactive request
      console.log(error);
      instance.acquireTokenRedirect(silentRequest);
    });
  }

  async function getNewContents(_selectedDriveId, nextPageTokenArr) {
    // await checkStorageConnection();

    let _nextPageToken = ""
    let _nextPageTokenArray = [...nextPageTokenArr]

    let _currentPage = currentPage

    if (currentFolderId) {
      _nextPageTokenArray = []

      // setCurrentPage(1)
      _currentPage = 1
      setPageDropdown([
        {
          key: 1,
          text: t("tiktokShop.page") + " " + 1,
          iconProps: { iconName: "Page" },
          onClick: () => {
              // console.log("Selected page: " + i);
              setItems([]);
              setIsLoading(true)
              setGetNewDocuments(true);
              setCurrentPage(1);
          },
        }
      ])
    } else {
      if (_currentPage !== 1) {
        _nextPageToken = _nextPageTokenArray[_currentPage - 2]
      }
    }


    instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
      axios
        .get(
          'http://localhost:7071/api/GoogleDrive_getFiles',
          // 'http://localhost:7071/api/AwsObjectsList',
          // API_URL + '/storages/s3/objects',
          {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              cid: companyId
            },
            params: {
              nextPageToken: _nextPageToken,
              id: _selectedDriveId
            }
          }
        )
        .then((response) => {
          
          console.log('response GoogleDrive_getFiles', response);

          if (response.data.nextPageToken && !_nextPageTokenArray[_currentPage - 1]) {
            const newNextPageTokenArray = [..._nextPageTokenArray];
            newNextPageTokenArray[_currentPage - 1] = response.data.nextPageToken
            setNextPageTokenArray(newNextPageTokenArray);

            let _pageDropdown = [];

            newNextPageTokenArray.forEach((_cursor, i) => 
                _pageDropdown.push({
                    key: i + 1,
                    text: t("tiktokShop.page") + " " + (i + 1),
                    iconProps: { iconName: "Page" },
                    onClick: () => {
                        console.log("Selected page: " + (i + 1));
                        setItems([]);
                        setIsLoading(true)
                        setGetNewDocuments(true);
                        setCurrentPage(i + 1);
                    },
                })
            )
        
            _pageDropdown.push({
                key: _currentPage + 1,
                text: t("tiktokShop.nextPage"),
                iconProps: { iconName: "Page" },
                onClick: () => {
                    console.log("Load More")
                    setItems([]);
                    setIsLoading(true)
                    setGetNewDocuments(true);
                    setCurrentPage(_currentPage + 1);
                },
            })
        
            setPageDropdown(_pageDropdown);

            console.log('_pageDropdown 1', _pageDropdown)
          } else if (!response.data.nextPageToken && response.data.files.length && pageDropdown.length <= _currentPage) {
            let _pageDropdown = [];

            _nextPageTokenArray.forEach((_cursor, i) => 
                _pageDropdown.push({
                    key: i + 1,
                    text: t("tiktokShop.page") + " " + (i + 1),
                    iconProps: { iconName: "Page" },
                    onClick: () => {
                        console.log("Selected page: " + (i + 1));
                        setItems([]);
                        setIsLoading(true)
                        setGetNewDocuments(true);
                        setCurrentPage(i + 1);
                    },
                })
            )
        
            if (_currentPage > 1) {
                _pageDropdown.push({
                    key: _currentPage,
                    text: t("tiktokShop.page") + " " + (_currentPage),
                    iconProps: { iconName: "Page" },
                    onClick: () => {
                        console.log("Selected page: " + (_currentPage));
                        setItems([]);
                        setIsLoading(true)
                        setGetNewDocuments(true);
                        setCurrentPage(_currentPage);
                    },
                })
            }
        
            setPageDropdown(_pageDropdown);

            console.log('_pageDropdown 2', _pageDropdown)
          }

          setCurrentPage(_currentPage)

          setItems(response.data.files.map(file => ({...file, key: file.id, modifyingUser: file.lastModifyingUser ? file.lastModifyingUser.displayName : ''})))
          setPathValue('')

          setTabValue('')

          setCurrentFolderId('')
          setGetNewDocuments(false);

          setIsLoading(false)
          setLoadingLink(false)
          setSelectedItems([])
          setSelectedItemsKey([])
          
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);

          setAlert(prev => ({...prev, open: true, status: 'error', message: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'}))
        });
    });
  }

  async function getNewContentsInFolder(folderId, nextPageTokenArr) {
    // if (!moreContent.IsTruncated) return
    console.log("folderId", folderId)
   
    let _nextPageToken = ""

    let _nextPageTokenArray = [...nextPageTokenArr]

    let _currentPage = currentPage
    if (folderId === currentFolderId) {
      console.log("condition 1")

      if (_currentPage !== 1) {
        _nextPageToken = _nextPageTokenArray[_currentPage - 2]
      }
    } else {
      console.log("condition 2")

      _nextPageTokenArray = []

      _currentPage = 1
      setPageDropdown([
        {
          key: 1,
          text: t("tiktokShop.page") + " " + 1,
          iconProps: { iconName: "Page" },
          onClick: () => {
              // console.log("Selected page: " + i);
              setItems([]);
              setIsLoading(true)
              setGetNewDocuments(true);
              setCurrentPage(1);
          },
        }
      ])
    }


    instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
      axios
        .get(
          'http://localhost:7071/api/GoogleDrive_getFilesInFolder',
          // 'http://localhost:7071/api/AwsObjectsList',
          // API_URL + '/storages/s3/objects',
          {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              cid: companyId
            },
            params: {
              pathFolderId: folderId,
              nextPageToken: _nextPageToken,
              id: selectedDriveId
            }
          }          
        )
        .then((response) => {
          
          console.log('response GoogleDrive_getFilesInFolder', response);

          console.log('nextPageTokenArray', _nextPageTokenArray)

          if (response.data.nextPageToken && !_nextPageTokenArray[_currentPage - 1]) {
            const newNextPageTokenArray = [..._nextPageTokenArray];
            newNextPageTokenArray[_currentPage - 1] = response.data.nextPageToken
            setNextPageTokenArray(newNextPageTokenArray);

            let _pageDropdown = [];

            newNextPageTokenArray.forEach((_cursor, i) => 
                _pageDropdown.push({
                    key: i + 1,
                    text: t("tiktokShop.page") + " " + (i + 1),
                    iconProps: { iconName: "Page" },
                    onClick: () => {
                        console.log("Selected page: " + (i + 1));
                        setItems([]);
                        setIsLoading(true)
                        setGetNewDocuments(true);
                        setCurrentPage(i + 1);
                    },
                })
            )
        
            _pageDropdown.push({
                key: _currentPage + 1,
                text: t("tiktokShop.nextPage"),
                iconProps: { iconName: "Page" },
                onClick: () => {
                    console.log("Load More")
                    setItems([]);
                    setIsLoading(true)
                    setGetNewDocuments(true);
                    setCurrentPage(_currentPage + 1);
                },
            })
        
            setPageDropdown(_pageDropdown);

            console.log('_pageDropdown 1', _pageDropdown)
          } else if (!response.data.nextPageToken && response.data.files.length && pageDropdown.length <= _currentPage) {
            let _pageDropdown = [];

            _nextPageTokenArray.forEach((_cursor, i) => 
                _pageDropdown.push({
                    key: i + 1,
                    text: t("tiktokShop.page") + " " + (i + 1),
                    iconProps: { iconName: "Page" },
                    onClick: () => {
                        console.log("Selected page: " + (i + 1));
                        setItems([]);
                        setIsLoading(true)
                        setGetNewDocuments(true);
                        setCurrentPage(i + 1);
                    },
                })
            )
        
            if (_currentPage > 1) {
                _pageDropdown.push({
                    key: _currentPage,
                    text: t("tiktokShop.page") + " " + (_currentPage),
                    iconProps: { iconName: "Page" },
                    onClick: () => {
                        console.log("Selected page: " + (_currentPage));
                        setItems([]);
                        setIsLoading(true)
                        setGetNewDocuments(true);
                        setCurrentPage(_currentPage);
                    },
                })
            }

            if (_pageDropdown.length) {
              setPageDropdown(_pageDropdown);
            } else {
              setPageDropdown([
                {
                  key: 1,
                  text: t("tiktokShop.page") + " " + 1,
                  iconProps: { iconName: "Page" },
                  onClick: () => {
                      // console.log("Selected page: " + i);
                      setItems([]);
                      setIsLoading(true)
                      setGetNewDocuments(true);
                      setCurrentPage(1);
                  },
                }
              ])
            }
        

            // console.log('_pageDropdown 2', _pageDropdown)
          }

          setCurrentPage(_currentPage)

          setItems(response.data.files.map(file => ({...file, key: file.id, modifyingUser: file.lastModifyingUser ? file.lastModifyingUser.displayName : ''})))
          setPathValue(response.data.path)

          setCurrentFolderId(folderId)

          setTabValue(folderId)

          setGetNewDocuments(false);

          setIsLoading(false)
          setLoadingLink(false)
          setSelectedItems([])
          setSelectedItemsKey([])
        });
    });
  }

  function setPathValue(path) {
    let pathArray = path ? [...path].reverse() : []

    setTabListValue([
      {
        id: "",
        name: "Doc"
      },
      ...pathArray
    ])
  }

  function handleClickLink(columnId, item) {
    // console.log('item', item)
    if (columnId === 'name') {
      if (item.mimeType.includes('folder')) {
        // setTabIndex(getTabIndex(item.key))
        // setPathValue(item.key)

        // setLoadingLink(true)
        setIsLoading(true)

        getNewContentsInFolder(item.key, nextPageTokenArray)
      } else {
        window.open(item.webViewLink, '_blank');
      }
    }
  }

  function createNewFolder(e, type, value) {
    e.preventDefault();

    console.log(type, value)

    instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
        axios
        .post(
          'http://localhost:7071/api/GoogleDrive_createFolder',
          // 'http://localhost:7071/api/AwsFoldersCreate',
          // API_URL + '/storages/s3/folders', 
          {
            folderName: value, 
            pathFolderId: currentFolderId ? currentFolderId : '',
            id: selectedDriveId
          }, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              cid: companyId
            },
          }
        )
         
        .then((response) => {
          
          console.log('response GoogleDrive_createFolder', response);

          if (currentFolderId) {
            getNewContentsInFolder(currentFolderId, nextPageTokenArray)
          } else {
            getNewContents(selectedDriveId, nextPageTokenArray)
          }
          
          setDialog(prev => 
            ({...prev, 
              loading: false,
              content: function () {
                return <div className={styles.stack} style={{ justifyContent: 'center', alignItems: 'center' }}>
                <CheckmarkRegular fontSize={32} />
                <Subtitle2Stronger style={{ paddingTop: 6 }}>{t('storages.createComplete')}</Subtitle2Stronger>
              </div>
              },
            }))
          

          setTimeout(() => {
            setDialog({
              open: false,
              loading: false,
              headers: '',
              value: '',
              onSubmit: null,
              onCancel: null,
              content: function () {
                return null
              }
            })
          }, 2000);

          // if (moreContent.Prefix) {
          //   getNewContentsNext({ Prefix: moreContent.Prefix, IsTruncated: true, clickLink: true })
          // } else {
          //   getNewContents()
          // }
        });
    })
  }

  async function handleUploadFile(e) {
    e.preventDefault();

    if (e.target.files[0]) {
      setDialog(prev => 
        ({...prev, 
          headers: ``,
          value: '',
          open: true,
          onSubmit: null,
          onCancel: () => setDialog({
            open: false,
            loading: false,
            headers: '',
            value: '',
            onSubmit: null,
            onCancel: null,
            content: function () {
              return null
            }
          }),
          loading: true,
          content: function () {
            return this.loading ? 
            <Spinner labelPosition='below' label={t('storages.uploading')} /> 
            : 
            <div className={styles.stack} style={{ justifyContent: 'center', alignItems: 'center' }}>
              <CheckmarkRegular fontSize={32} />
              <Subtitle2Stronger style={{ paddingTop: 6 }}>{t('storages.uploadComplete')}</Subtitle2Stronger>
            </div>
          },
        })
      )

      const name = e.target.files[0].name
      const type = e.target.files[0].type

      // console.log('name', name)
      // console.log('type', type)

      let formData = new FormData();

      const params = {
        name: name,
        type: type,
        pathFolderId: currentFolderId ? currentFolderId : '',
        id: selectedDriveId
      }

      formData.append('file', e.target.files[0]);
      formData.append("query", JSON.stringify(params));
  
      instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
        axios
          .post(
            'http://localhost:7071/api/GoogleDrive_uploadFile', formData, {
            // 'http://localhost:7071/api/AwsObjectsUpload', formData, {
            // API_URL + '/storages/s3/objects',  formData, {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'Content-Type': 'multipart/form-data',
                cid: companyId
              },
              timeout: 60000,
            }
          )
          .then((response) => {
            
            console.log('response GoogleDrive_uploadFile', response);

            if (currentFolderId) {
              getNewContentsInFolder(currentFolderId, nextPageTokenArray)
            } else {
              getNewContents(selectedDriveId, nextPageTokenArray)
            }
            
            setDialog(prev => ({...prev, loading: false}))  
            

            setTimeout(() => {
              setDialog({
                open: false,
                loading: false,
                headers: '',
                value: '',
                onSubmit: null,
                onCancel: null,
                content: function () {
                  return null
                }
              })
            }, 2000);

            // if (moreContent.Prefix) {
            //   getNewContentsNext({ Prefix: moreContent.Prefix, IsTruncated: true, clickLink: true })
            // } else {
            //   getNewContents()
            // }
          })
          .catch((error) => {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
  
            setDialog({
              open: false,
              loading: false,
              headers: '',
              value: '',
              onSubmit: null,
              onCancel: null,
              content: function () {
                return null
              }
            })
      
            setAlert(prev => ({...prev, open: true, status: 'error', message: t('storagesOneDrive.alertErrorOccured')}))
            // instance.acquireTokenRedirect(silentRequest);
          });
      })
    }
  }
  async function handleDeleteConfirm(e, keyArray) {
    setDialog(prev => 
      ({...prev, 
        headers: ``,
        value: '',
        open: true,
        onSubmit: () => handleDelete(e, keyArray),
        onCancel: () => {
          setDialog({
          open: false,
          loading: false,
          headers: '',
          value: '',
          onSubmit: null,
          onCancel: null,
          content: function () {
            return null
          },
          onCancelLabel: 'Cancel'
        })},
        loading: false,
        content: function () {
          return <div className={styles.stack} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <DeleteRegular fontSize={32} />
            <Subtitle2Stronger style={{ paddingTop: 6 }}>{t('storages.deleteConfirmMessage')}</Subtitle2Stronger>
          </div>
        },
        onCancelLabel: 'Cancel',
        onSubmitLabel: 'Delete'
      })
    )
  }

  async function handleDelete(e, keyArray) {
    e.preventDefault();

    const source = axios.CancelToken.source()

    const params = {
      idArray: JSON.stringify(keyArray)
    }

    setDialog(prev => 
      ({...prev, 
        headers: ``,
        value: '',
        open: true,
        onSubmit: null,
        onCancel: () => {
          source.cancel()
          setDialog({
          open: false,
          loading: false,
          headers: '',
          value: '',
          onSubmit: null,
          onCancel: null,
          content: function () {
            return null
          },
          onCancelLabel: ''
        })},
        loading: true,
        content: function () {
          return this.loading ? 
          <Spinner labelPosition='below' label={t('storages.deleting')} /> 
          : 
          <div className={styles.stack} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <CheckmarkRegular fontSize={32} />
            <Subtitle2Stronger style={{ paddingTop: 6 }}>{t('storages.deleteComplete')}</Subtitle2Stronger>
          </div>
        },
        onCancelLabel: '',
        onSubmitLabel: null
      }))

    instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
      axios
        .delete(
          'http://localhost:7071/api/GoogleDrive_deleteFile', {
          // API_URL + '/storages/s3/objects', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              cid: companyId
            },
            data : {
              idArray: params.idArray,
              id: selectedDriveId
              // cancelToken: source.token
            }
          }
        )
        .then((response) => {
          
          console.log('response GoogleDrive_deleteFile', response);
         
          setDialog(prev => 
            ({...prev, 
              loading: false
            }))
          

          setTimeout(() => {
            setDialog({
              open: false,
              loading: false,
              headers: '',
              value: '',
              onSubmit: null,
              onCancel: null,
              content: function () {
                return null
              }
            })
          }, 2000);

          return getNewContents(selectedDriveId, nextPageTokenArray)
          // if (moreContent.Prefix) {
          //   getNewContentsNext({ Prefix: moreContent.Prefix, IsTruncated: true, clickLink: true })
          // } else {
          //   getNewContents()
          // }
        });
    })
  }

  async function handleDownload(e, keyArray) {
    e.preventDefault();

    // const url = items.find(_item => _item.key === keyArray[0]).webContentLink
    // window.open(url, '_blank');

    const foundFolder = items.some(_item => keyArray.find(_id => _id === _item.id && _item.mimeType.includes('folder')))
    if (foundFolder) return setAlert(prev => ({...prev, open: true, status: 'error', message: t('storages.alertMessageDownloadFolder')}))
    
    if (keyArray.length > 100) return setAlert(prev => ({...prev, open: true, status: 'error', message: t('storages.alertMessageDownloadManyFiles')}))

    const foundLargeFile = items.some(_item => keyArray.find(_id => _id === _item.id && Number(_item.size) >= twentyFiveMB))
    if (foundLargeFile) {
      if (keyArray.length > 1) return setAlert(prev => ({...prev, open: true, status: 'error', message: t('storages.alertMessageDownloadManyLargeFiles')}))

      const url = items.find(_item => _item.key === keyArray[0]).webContentLink
      window.open(url, '_blank');

      return;
    }

    if (keyArray.length === 1) {
      const downloadUrl = items.find(_item => _item.key === keyArray[0]).webContentLink

      if (downloadUrl) {
        return window.open(downloadUrl, '_blank');
      } else {
        const _idArray = keyArray.map(_id => ({
          id: _id,
          downloadUrl: ''
        }))
        // keyArray.forEach(_key => idArray.push({
        //   id: _key,
        //   downloadUrl: ''
        // }))
        const params = {
          idArray: JSON.stringify(_idArray)
        }

        instance.acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios.get('http://localhost:7071/api/GoogleDrive_downloadFiles', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              cid: companyId
            },
            params: {
              idArray: params.idArray,
              id: selectedDriveId
            },
            responseType: 'arraybuffer',
            onDownloadProgress: (progressEvent) => {
              const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              // setProgress(percentage);
              setDialog(prev => ({...prev, dowloadingProgress: percentage}))
  
            },
          }).then(response => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
            link.download = items.find(_item => _item.key === keyArray[0]).name
            link.click();
  
            setDialog(prev => ({...prev, loading: false}))
  
            setTimeout(() => {
              setDialog({
                open: false,
                loading: false,
                headers: '',
                value: '',
                onSubmit: null,
                onCancel: null,
                content: function () {
                  return null
                }
              })
            }, 2000);

            setSelectedItemsKey([])
            setSelectedItems([])
          })
          .catch((error) => {
            console.log("err downloadLargeFile", (error))

            setAlert(prev => ({...prev, open: true, status: 'error', message: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'}))
          })

        }).catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);

          setAlert(prev => ({...prev, open: true, status: 'error', message: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'}))
        });
        
        return;
      }
    }

    if (keyArray.length > 1) {

      setDialog(prev => 
        ({...prev, 
          headers: ``,
          value: '',
          open: true,
          onSubmit: null,
          onCancel: () => setDialog({
            open: false,
            loading: false,
            headers: '',
            value: '',
            onSubmit: null,
            onCancel: null,
            content: function () {
              return null
            }
          }),
          loading: true,
          content: function () {
            return this.loading ? 
            <>
              <Spinner labelPosition='below' label={`${t('storages.downloading')}`} /> 
            </>
            : 
            <div className={styles.stack} style={{ justifyContent: 'center', alignItems: 'center' }}>
              <CheckmarkRegular fontSize={32} />
              <Subtitle2Stronger style={{ paddingTop: 6 }}>{t('storages.downloadComplete')}</Subtitle2Stronger>
            </div>
          },
        }))
      // console.log('keyArray', keyArray)


      const _idArray = keyArray.map(_id => {
        const _item = items.find(_item => _item.key === _id)

        // console.log('_id', _id)
        // console.log('items', items)

        return {
          id: _id,
          name: _item.name,
          downloadUrl: _item.webContentLink
        }
      })
      
      const params = {
        idArray: JSON.stringify(_idArray)
      }

      instance.acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios.get('http://localhost:7071/api/GoogleDrive_downloadFiles', {
          headers: {
            Authorization: 'Bearer ' + tokenResponse.accessToken,
            cid: companyId
          },
          params: {
            idArray: params.idArray,
            id: selectedDriveId
          },
          responseType: 'blob',
          onDownloadProgress: (progressEvent) => {
            const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            // setProgress(percentage);
            setDialog(prev => ({...prev, dowloadingProgress: percentage}))

          },
        }).then(response => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(new Blob([response.data]));
          link.download = `Leceipt-Storage-${new Date().getFullYear()}${String(new Date().getMonth() + 1).padStart(2, '0')}${String(new Date().getDate()).padStart(2, '0')}${String(new Date().getHours()).padStart(2, '0')}${String(new Date().getMinutes()).padStart(2, '0')}${String(new Date().getSeconds()).padStart(2, '0')}.zip`
          link.click();

          setDialog(prev => ({...prev, loading: false}))

          setTimeout(() => {
            setDialog({
              open: false,
              loading: false,
              headers: '',
              value: '',
              onSubmit: null,
              onCancel: null,
              content: function () {
                return null
              }
            })
          }, 2000);

          setSelectedItemsKey([])
          setSelectedItems([])
        })
        .catch((error) => {
          console.log("err downloadLargeFile", (error))

          setAlert(prev => ({...prev, open: true, status: 'error', message: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'}))
        })

      }).catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);

        setAlert(prev => ({...prev, open: true, status: 'error', message: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'}))
      });
      
      return;
    }
  }

  // async function downloadMultiFile(urlArray, keyArray) {

  //   setDialog(prev => 
  //     ({...prev, 
  //       headers: ``,
  //       value: '',
  //       open: true,
  //       onSubmit: null,
  //       onCancel: () => setDialog({
  //         open: false,
  //         loading: false,
  //         headers: '',
  //         value: '',
  //         onSubmit: null,
  //         onCancel: null,
  //         content: function () {
  //           return null
  //         }
  //       }),
  //       loading: true,
  //       content: function () {
  //         return this.loading ? 
  //         <>
  //           <Spinner labelPosition='below' label={`${t('storages.downloading')}`} /> 
  //         </>
  //         : 
  //         <div className={styles.stack} style={{ justifyContent: 'center', alignItems: 'center' }}>
  //           <CheckmarkRegular fontSize={32} />
  //           <Subtitle2Stronger style={{ paddingTop: 6 }}>{t('storages.downloadComplete')}</Subtitle2Stronger>
  //         </div>
  //       },
  //     }))

  //     const promises = [];

  //       for (let i = 0; i < urlArray.length; i++) {
  //         promises.push(new Promise(async function (resolve, reject) {
  //           await axios.get(urlArray[i], {
  //             responseType: 'arraybuffer'
  //           })
  //             .then(async (responseFile) => {
  //               resolve(new Blob([responseFile.data], { type: 'application/octet-stream' }))                
  //             }).catch(err => console.log("err", err))
  //         }));
  //       }

  //       await Promise.all(promises).then(async (res) => {
          

  //         const zip = new JSZip();

  //         res.forEach((_buffer, index) => {
  //           zip.file(keyArray[index].Key, _buffer);
  //         })

  //         const blob = await zip.generateAsync({
  //           type: 'blob',
  //         });

  //         const link = document.createElement('a');
  //         link.setAttribute('href', window.URL.createObjectURL(blob));
  //         link.setAttribute('download', 'Leceipt-Storage-' + new Date().toISOString() + '.zip');
  //         link.style.display = 'none';
  //         document.body.appendChild(link);
  //         link.click();
  //         document.body.removeChild(link);

  //         setDialog(prev => ({...prev, loading: false}))

  //         setTimeout(() => {
  //           setDialog({
  //             open: false,
  //             loading: false,
  //             headers: '',
  //             value: '',
  //             onSubmit: null,
  //             onCancel: null,
  //             content: function () {
  //               return null
  //             }
  //           })
  //         }, 2000);
  //       }).catch(err => {
  //         console.log("err", err)
  //       })
  // }

  // function handleStatus(res) {
  //   let result = false
  //   if (res.data.status && res.data.status === 403) {
  //     setAlert(prev => ({...prev, open: true, status: 'error', message: 'Access Denied'}))
  //     result = true
  //   }

  //   return result
  // }
  
  async function handleShare(e, file) {
    e.preventDefault();

    setDialog(prev => 
      ({...prev, 
        headers: `Share ${file.name}`,
        open: true,
        onCancel: () => setDialog({
          open: false,
          loading: false,
          headers: '',
          value: '',
          onSubmit: null,
          onCancel: null,
          content: function () {
            return null
          }
        }),
        copyLink: false,
        content: function () {
          return this.loading ? 
          <Spinner labelPosition='below' label={t('storages.loading')} /> 
          : 
          <Field
            label="Link"
            validationState={this.copyLink ? "success" : ""}
            validationMessage={this.copyLink ? t('storages.copied') : ""}
          >
            <Input value={this.value} disabled />
          </Field>
        },
        onSubmitLabel: 'Copy link',
        onCancelLabel: 'Close',
        loading: false,
        onSubmit: () => {
          setDialog(prev => ({...prev, copyLink: true}))
          navigator.clipboard.writeText(file.webViewLink)
        },
        value: file.webViewLink
      })
    )
  }

  async function handleDisconnect(e) {
    e.preventDefault();

    setDialog(prev => 
      ({...prev, 
        headers: t('storages.disconnect'),
        value: '',
        loading: true,
        open: true,
        onSubmit: () => {
          axios
            .post('http://localhost:7071/api/Storages_disconnect', { id: selectedDriveId }, {
              headers: {
                // Authorization: 'Bearer ' + tokenResponse.accessToken,
                // 'cid': companyId
              },
              
            }).then(res => {
              if (res.data.length) {
                const firstDrive = res.data[0]

                if (firstDrive.type === 'oneDrive') {
                  history.push({
                    pathname: '/storages/one-drive',
                    state: { 
                      id: firstDrive.id,
                    },
                  });
                  
                } else if (firstDrive.type === 'googleDrive') {
                  setSelectedDriveId(firstDrive.id)

                  setDialog({
                    open: false,
                    loading: false,
                    headers: '',
                    value: '',
                    onSubmit: null,
                    onCancel: null,
                    content: function () {
                      return null
                    }
                  })
                } else if (firstDrive.type === 's3') {
                  history.push({
                    pathname: '/storages',
                    state: { 
                      id: firstDrive.id,
                    },
                  });
                }
              } else {
                history.push({
                  pathname: '/settings/storages',
                  state: {},
                })
              }
              
            })
        },
        onCancel: () => setDialog({
          open: false,
          loading: false,
          headers: '',
          value: '',
          onSubmit: null,
          onCancel: null,
          content: function () {
            return null
          }
        }),
        content: function () {
          return t('storages.disconnectConfirm')
        },
        onSubmitLabel: "Confirm",
        onCancelLabel: "Cancel"
      }))
  
    
  }

  async function handleEnableBackup(e) {
    e.preventDefault();

    setDialog(prev => 
      ({...prev, 
        headers: t('storages.backupConfirm'),
        value: '',
        loading: true,
        open: true,
        onSubmit: () => {
          axios
            .post('http://localhost:7071/api/Storages_backup', { id: selectedDriveId }, {
              headers: {
                // Authorization: 'Bearer ' + tokenResponse.accessToken,
                // 'cid': companyId
              },
              
            }).then(res => {
              setBackup(res.data.result)
              
              getDriveList()

              setDialog({
                open: false,
                loading: false,
                headers: '',
                value: '',
                onSubmit: null,
                onCancel: null,
                content: function () {
                  return null
                }
              })
            })
        },
        onCancel: () => setDialog({
          open: false,
          loading: false,
          headers: '',
          value: '',
          onSubmit: null,
          onCancel: null,
          content: function () {
            return null
          }
        }),
        content: function () {
          return <div>
            {backup ? (
              <>
                {t('storages.backupDisableDescription1')}
              </>
            ) : (
              <>
                {t('storages.backupEnableDescription1')}
                {' '}
                {t('storages.backupEnableDescription2')}
              </>
            )}
          </div>
        },
        onSubmitLabel: "Confirm",
        onCancelLabel: "Cancel"
      }))
  
    
  }

  function ActionCell({item, onClickEvent}) {
    const [hovered, setHovered] = useState(false)
  
    return (
      <div 
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)} 
        style={{ display: "flex", flexDirection: "row", width: "100%"}}
      >
        <TableCellLayout
          truncate
        >
          <Tooltip content={item.name} relationship="description" showDelay={1000} positioning={'below'}>
            <Link appearance="subtle" onClick={onClickEvent}>
              {item.name}
            </Link>
          </Tooltip>
        </TableCellLayout>
        {hovered && (
          <div style={{ display: "flex", flexDirection: "row"}}>
            {item.webViewLink && (
              <ToolbarButton onClick={(e) => handleShare(e, item)} aria-label="Share" icon={<ShareRegular />} />
            )}
            <Menu>
              <MenuTrigger>
                <ToolbarButton aria-label="More" icon={<MoreHorizontalRegular />} />
              </MenuTrigger>
    
              <MenuPopover>
                <MenuList>
                  <MenuItem onClick={onClickEvent}>{t('storages.open')}</MenuItem>
                  {item.webViewLink && (
                    <MenuItem onClick={(e) => handleShare(e, item)}>{t('storages.share')}</MenuItem>
                  )}
                  {item.webContentLink && (
                    <MenuItem onClick={(e) => handleDownload(e, [item.key])}>{t('storages.download')}</MenuItem>
                  )}
                  <MenuItem onClick={(e) => handleDeleteConfirm(e, [item.key])}>{t('storages.delete')}</MenuItem>
                </MenuList>
              </MenuPopover>
            </Menu>
          </div>
        )}
      
    </div>)
  }

  function TextFieldSearch() {
    const [search, setSearch] = useState('')
    const [data, setData] = useState([])
    const [isLoadData, setIsLoadData] = useState(false)

    const [cancelTokenSource, setCancelTokenSource] = useState(null);

    useEffect(() => {
      if (!search) return;

      // const source = axios.CancelToken.source()
      getOptions(search)

      // return () => source.cancel()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    async function getOptions(value) {
      setIsLoadData(true)

      if (cancelTokenSource) {
        cancelTokenSource.cancel('New request initiated');
      }
  
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      try {
        const tokenResponse = await instance.acquireTokenSilent({ ...silentRequest, account: account })

        const response = await axios
        .get(
          'http://localhost:7071/api/GoogleDrive_searchFiles',
          // 'http://localhost:7071/api/AwsObjectsAllList',
          // API_URL + '/storages/s3/objects/all', 
          {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              cid: companyId
            },
            params: {
              search: value,
              id: selectedDriveId
            },
            // cancelToken: source.token
            cancelToken: newCancelTokenSource.token,
          }
        );

        console.log('response GoogleDrive_searchFiles', response);

        setData(response.data.files.map(file => ({...file, key: file.id, modifyingUser: file.lastModifyingUser ? file.lastModifyingUser.displayName : ''})))

        // setIsLoading(false)
        // setSelectedItems([])
        // setSelectedItemsKey([])

        // const _filterData = _data.filter(_content => _content.Key.includes(value))

        // setData(_filterData)

        setIsLoadData(false)
        
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else {
          console.error('Error:', error);
        }
      }
    }

    function ActionOption({data}) {
      const [hovered, setHovered] = useState(false)

      return <div style={{ 
          display: "flex", 
          flexDirection: "row", 
          width: "95%", 
          height: 44, 
          justifyContent: "space-between", 
          padding: "0px 8px",
          ...(hovered ? { backgroundColor: "#edebe9"} : {}),
          // marginRight: 10
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)} 
        key={data.key}
      >
        <div 
          style={{ display: "flex", flexDirection: "row" }} 
          onClick={() => {
            handleClickLink('name', data)
            setSearch("")
          }}
        >
          <TableCellLayout truncate media={getMedia(data.mimeType)} />
          <div>
            <Link appearance="subtle" style={{ padding: "12px 8px", width: 250, whiteSpace: 'nowrap',  overflow: 'hidden', display: 'inline-block', textOverflow: "ellipsis" }}>
              {data.name}
              {/* <label style={{ textOverflow: "ellipsis", width: 200, whiteSpace: 'nowrap',  overflow: 'hidden' }}>

              </label> */}
            </Link>
          </div>
        </div>
        {hovered && (
        <div style={{ display: "flex", flexDirection: "row"}}>
          {data.webViewLink && (
            <ToolbarButton onClick={(e) => handleShare(e, data)} aria-label="Share" icon={<ShareRegular />} />
          )}
          <Menu>
            <MenuTrigger>
              <ToolbarButton aria-label="More" icon={<MoreHorizontalRegular />} />
            </MenuTrigger>
  
            <MenuPopover>
              <MenuList>
                <MenuItem onClick={() => handleClickLink('name', data)}>{t('storages.open')}</MenuItem>
                {data.webViewLink && (
                  <MenuItem onClick={(e) => handleShare(e, data)}>{t('storages.share')}</MenuItem>
                )}
                {data.webContentLink && (
                  <MenuItem onClick={(e) => handleDownload(e, [data.key])}>{t('storages.download')}</MenuItem>
                )}
                <MenuItem onClick={(e) => handleDeleteConfirm(e, [data.key])}>{t('storages.delete')}</MenuItem>
              </MenuList>
            </MenuPopover>
          </Menu>
        </div>
      )}
      </div>
    }
  
  const buttonRef = useRef(null);
  const positioningRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current) {
      positioningRef.current?.setTarget(buttonRef.current);
    }
  }, [buttonRef, positioningRef]);

  // const [focused, setFocused] = useState(false)
  // const onFocus = () => setFocused(true)
  // const onBlur = () => setFocused(false)



  return <Field /* onFocus={onFocus} onBlur={onBlur} */ ref={buttonRef} onChange={e => setSearch(e.target.value)} style={{ width: mainDiv.current.offsetWidth * 0.3}}>
    <Input contentBefore={<SearchRegular />} contentAfter={search ? <DismissRegular onClick={() => setSearch("")} /> : null } value={search} placeholder='ค้นหา' />
    <Popover open={search !== ""} positioning={{ positioningRef, position: "below" }} >
      <PopoverSurface style={{ width: mainDiv.current.offsetWidth * 0.3, padding: 0}}>
        {isLoadData ? <Spinner labelPosition="below" style={{ padding: "12px 0px"}} /* label={t('storages.loading')} */ /> : (
          data.map((_data, index) => (
            <ActionOption data={_data} key={`option-${index}`} />
          ))
        )}
      </PopoverSurface>
    </Popover>
  </Field>
  }

  function getDriveName(driveId) {
    let name = null

    if (driveId) {
      const drive = drives.find(_drive => _drive.id === driveId)
      if (drive) {
        if (drive.type === 'oneDrive') {
          if (drive.name && drive.owner.user.email) {
            name = `${drive.name} (${drive.owner.user.email})`
          } else {
            name = `${drive.owner.user.displayName}`
          }
    
        } else if (drive.type === 'googleDrive') {
          if (drive.user) {
            name = `${drive.user.displayName} (${drive.user.emailAddress})`
          }
    
        } else if (drive.type === 's3') {
          name = drive.bucket
        }

      }
    }

    return name
  }

    return <div ref={mainDiv} style={{ padding: 30, width: "100%", height: window.screen.height, backgroundColor: "white", borderLeft: "1px solid #e0e0e0"}}>
      {alert.open ? (
        <>
          <Alert intent={alert.status} action={{ onClick: () => { setAlert(prev => ({...prev, open: false})) }, icon: <DismissCircleRegular />,
          "aria-label": "dismiss message", }}>{alert.message}</Alert>
          <br />
        </>
      ) : null}
      <div>
      <Title2 as='h2'>
            
            <CloudBidirectionalRegular /> {t('storages.header')}
            <Switch label={t('storages.automaticBackup')} checked={backup} onClick={handleEnableBackup} /* onChange={(e, data) => console.log(data)} */ />
            <div style={{ float: 'right'}}>
              <Menu>
                <MenuTrigger disableButtonEnhancement>
                  <MenuButton appearance="transparent" icon={<StorageRegular />}>
                    {/* {selectedDrive.name} */}
                    {getDriveName(selectedDriveId)}
                  </MenuButton>
                </MenuTrigger>

                <MenuPopover>
                  <MenuList>
                    {drives.map((_drive, index) => (
                      <MenuItem key={'drive' + (index + 1)}  icon={<StorageRegular />} onClick={() => {
                        setIsLoading(true)
                        
                        if (_drive.type === 'oneDrive') {
                          history.push({
                            pathname: '/storages/one-drive',
                            state: { 
                              id: _drive.id,
                            },
                          });

                        } else if (_drive.type === 'googleDrive') {
                          setSelectedDriveId(_drive.id)
  
                          getNewContents(_drive.id, []);
                        } else if (_drive.type === 's3') {
                          history.push({
                            pathname: '/storages',
                            state: { 
                              id: _drive.id,
                            },
                          });
                        }

                    }}>{getDriveName(_drive.id)}</MenuItem>
                    ))}
                    {drives.length ? (
                      <Divider />

                    ) : null}
                    <MenuItem key={'newDrive'} icon={<AddRegular />} onClick={() => setDialogConnectNewDrive(prev => ({...prev, open: true}))}>{t('storagesConnect.ConnectAnotherDrive')}</MenuItem>
                    {/* <MenuItem>Item b</MenuItem> */}
                  </MenuList>
                </MenuPopover>
              </Menu>
              
            </div>
          </Title2>

      </div>
      <br />
      
      {isLoading ? (
        <>
          <br />
          <br />
          <Spinner labelPosition="below" label={t('storages.loading')} />
        </>
      ) : (
        <>
        <Divider />
        {/* <br /> */}
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "6px 0px"}}>
          <div style={{ display: "flex"}}>

          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <MenuButton appearance="primary" icon={<ArrowUploadRegular />}>
                {t('storages.upload')}
              </MenuButton>
            </MenuTrigger>

            <MenuPopover>
              <MenuList>
                <MenuItem onClick={() => fileInputRef.current.click()}>{t('storages.file')}</MenuItem>
                {/* <MenuItem>Item b</MenuItem> */}
              </MenuList>
            </MenuPopover>
          </Menu>
        

          {selectedItemsKey.length ? (
            <>
              {selectedItemsKey.length === 1 ? (
                <Button appearance="transparent" icon={<ShareIosRegular />} onClick={(e) => handleShare(e, items.find(item => item.key === selectedItemsKey[0]))} >
                  {t('storages.share')}
                </Button>
              ) : null}
                <Button appearance="transparent" icon={<ArrowDownloadRegular />} onClick={(e) => handleDownload(e, selectedItemsKey)} >
                  {t('storages.download')}
                </Button>
                <Button appearance="transparent" icon={<DeleteRegular />} onClick={(e) => handleDeleteConfirm(e, selectedItemsKey)} >
                  {t('storages.delete')}
                </Button>
              </>
            ) : (
              <>
                <Menu>
                  <MenuTrigger disableButtonEnhancement>
                    <MenuButton appearance="subtle" icon={<AddRegular />}>
                      {t('storages.new')}
                    </MenuButton>
                  </MenuTrigger>

                  <MenuPopover>
                    <MenuList>
                      <MenuItem 
                        onClick={() => 
                          setDialog(prev => 
                            ({...prev, 
                              headers: t('storages.newFolder'),
                              value: '',
                              open: true,
                              onSubmit: (e, type, value) => createNewFolder(e, type, value),
                              onCancel: () => setDialog({
                                open: false,
                                headers: '',
                                value: '',
                                onSubmit: null,
                                onCancel: null,
                                content: function () { return null }
                              }),
                              content: function () { 
                                return this.loading ? 
                                        (<Spinner labelPosition='below' label={t('storages.creating')} /> ) 
                                        : 
                                        (<Field
                                          label={t('storages.creating')}
                                          // validationState="success"
                                          // validationMessage="This is a success message."
                                        >
                                          <Input value={this.value} onChange={(e, data) => {
                                            setDialog(prev => ({...prev, value: data.value}))
                                          }} />
                                        </Field>)
                              }, 
                              onSubmitLabel: t('storages.create'),
                              onCancelLabel: t('storages.cancel')
                            }))
                        }>{t('storages.folder')}</MenuItem>
                      {/* <MenuItem>Item b</MenuItem> */}
                    </MenuList>
                  </MenuPopover>
                </Menu>
                <Button appearance="transparent" icon={<ArrowClockwiseRegular />} onClick={(e) => {
                    setTabListValue(['Doc'])
                    setIsLoading(true)
                    setItems([])
                    setSelectedItems([])
                    setSelectedItemsKey([])
                    setTabValue(0)
                    getNewContents(selectedDriveId, nextPageTokenArray)
                  }}>
                  {t('documents.refresh')}
                </Button>
                <Menu>
                  <MenuTrigger disableButtonEnhancement>
                    <MenuButton appearance="transparent" icon={<DocumentRegular />}>
                      หน้า {currentPage}
                    </MenuButton>
                  </MenuTrigger>

                  <MenuPopover>
                    <MenuList>
                      {pageDropdown.map((_dropdown, index) => (
                        <MenuItem key={"page-" + _dropdown.key} onClick={_dropdown.onClick} icon={<DocumentRegular />}>{_dropdown.text}</MenuItem>
                      ))}
                    </MenuList>
                  </MenuPopover>
                </Menu>
                <Menu>
                  <MenuTrigger>
                    <ToolbarButton aria-label="More" icon={<MoreHorizontalRegular />} />
                  </MenuTrigger>

                  <MenuPopover>
                    <MenuList>
                      <MenuItem onClick={handleDisconnect} icon={<PlugDisconnectedRegular />}>{t('storages.disconnect')}</MenuItem>
                    </MenuList>
                  </MenuPopover>
                </Menu>
                
              </>
            )}
          </div>
          <TextFieldSearch />
        </div>
        {/* <br /> */}
        <Divider />
        {/* <br /> */}
        <div className={styles.wrapper}>
          <TabList onTabSelect={(e, data) => {
            // setTabIndex(data.value)
            // removePathValue(data.value)
            console.log('data.value', data.value)
            setIsLoading(true)

            // let _prefix = ''
            // for (let i = 0; i < data.value; i++) {
            //   _prefix = _prefix + (tabListValue[i] === "Doc" ? '' : tabListValue[i])
            //   if (i !== 0 && i !== data.value) {
            //     _prefix = _prefix + '/'
            //   }
            // }

            if (data.value) {
              getNewContentsInFolder(data.value, nextPageTokenArray)
            } else {
              getNewContents(selectedDriveId, nextPageTokenArray)
            }
          }}
            selectedValue={tabValue}
          >
            {tabListValue.map((path, index) => (
              <Fragment key={path.id + index}>
                <Tab value={path.id}>{!path.id ? t("storages.documents") : path.name}</Tab>
                {index !== tabListValue.length - 1 && (
                  <div style={{ paddingTop: 15}}>
                    <ChevronRightRegular />
                  </div>
                )}
              </Fragment>
            ))}
          </TabList>
        </div>
        <br />
        {loadingLink ? (
          <>
            <br />
            <br />
            <Spinner labelPosition="below" label={t('storages.loading')} />
          </>
        ) : (
        
          <DataGrid
            items={items}
            columns={columns}
            sortable
            focusMode='none'
            getRowId={(item) => item.key}
            selectionMode="multiselect"
            onSelectionChange={(e, data) => {
              // console.log(data)
              // data.selectedItems.forEach(value => console.log(value))
              if (e.target.localName === 'input') {
                const _selectedItemsKey = []
                data.selectedItems.forEach(_value => _selectedItemsKey.push(_value))
                setSelectedItemsKey(_selectedItemsKey)
                setSelectedItems(data.selectedItems)
              }
            }}
            selectedItems={selectedItems}
            resizableColumns
            columnSizingOptions={{
              mimeType: {
                minWidth: 20,
                defaultWidth: 20,
              },
              name: {
                defaultWidth: mainDiv.current.offsetWidth * 0.4,
                minWidth: 320,
                // idealWidth: 320,
              },
              modifiedTime: {
                defaultWidth: mainDiv.current.offsetWidth * 0.15,
                minWidth: 180,
                // idealWidth: 180,
              },
              modifyingUser: {
                defaultWidth: mainDiv.current.offsetWidth * 0.15,
                minWidth: 180,
                // idealWidth: 180,
              },
              size: {
                defaultWidth: mainDiv.current.offsetWidth * 0.1,
                minWidth: 180,
                // idealWidth: 180,
              },
            }}
            onColumnResize={(event, { columnId, width }) => {
              if (event) {
                console.log(event.offsetX, event.offsetY, columnId, width);
              }
            }}
          >
            <DataGridHeader>
              <DataGridRow selectionCell={{ "aria-label": "Select all rows" }}>
                {({ renderHeaderCell }) => (
                  <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                )}
              </DataGridRow>
            </DataGridHeader>
            <DataGridBody>
              {({ item, rowId }) => (
                <DataGridRow
                  key={rowId}
                  selectionCell={{ "aria-label": "Select row" }}
                
                >
                  {({ renderCell, columnId }) => (
                    <DataGridCell key={rowId + columnId}>{renderCell(item)}</DataGridCell>
                  )}
                </DataGridRow>
              )}
            </DataGridBody>
          </DataGrid>
        )}
        <Dialog open={dialog.open}>
          <DialogSurface>
            <DialogBody>
              <DialogTitle>{dialog.headers}</DialogTitle>
              <DialogContent>
                {dialog.content()}
              </DialogContent>
              <DialogActions>
                {dialog.onSubmitLabel ? (
                  <Button appearance="primary" onClick={(e) => dialog.onSubmit(e, 'Folder', dialog.value)}>{dialog.onSubmitLabel}</Button>
                  ) : null}
                {dialog.onCancelLabel ? (
                  <DialogTrigger disableButtonEnhancement>
                    <Button appearance="secondary" onClick={() => 
                      dialog.onCancel()
                      }>{dialog.onCancelLabel}</Button>
                  </DialogTrigger>
                ) : null}
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
        
        <input onChange={handleUploadFile} multiple={false} ref={fileInputRef} type='file' hidden/>
        </>
      )}
      <Dialog open={dialogConnectNewDrive.open}>
          <DialogSurface style={{ width: 350 }}>
            <DialogBody>
              <DialogTitle>{t('storagesConnect.ConnectAnotherBucket')}</DialogTitle>
              <DialogContent>
                {dialogConnectNewDrive.loading ? (
                  <Spinner labelPosition='below' label={t('storages.uploading')} /> 
                ) : (
                  dialogConnectNewDrive.status === "buckets" ? (
                    <>
                      <div className={styles.root}>
                        <label id={`${dropdownId}-selectBucket`}>{t('storagesConnect.selectBucket')}</label>
                        <Dropdown
                          aria-labelledby={`${dropdownId}-selectBucket`}
                          placeholder={t('storagesConnect.selectBucket')}
                        >
                          {dialogConnectNewDrive.buckets.map((option) => (
                            <Option key={option.Name} onClick={e => {
                              setDialogConnectNewDrive(prev => ({ ...prev, selectedBucket: option.Name}))
                              
                            }}>
                              {option.Name}
                            </Option>
                          ))}
                        </Dropdown>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={styles.root}>
                        <label id={`${dropdownId}-storage`}>Storage</label>
                        <Dropdown
                          aria-labelledby={`${dropdownId}-storage`}
                          placeholder={t('storagesConnect.selectStorage')}
                        >
                          {storageOptions.map((option) => (
                            <Option key={option.key} onClick={e => {
                              setDialogConnectNewDrive(prev => ({ ...prev, storage: option.key}))
                              
                              if (option.key === 'amazon') {
                                setOptionsEndpointS3(endpointAmazonS3); 
                                setDialogConnectNewDrive(prev => ({ ...prev, endpoint: 's3.ap-southeast-1.amazonaws.com'}))
                              } else if (option.key === 'wasabi') {
                                setOptionsEndpointS3(endpointWasabiS3); 
                                setDialogConnectNewDrive(prev => ({ ...prev, endpoint: 's3.ap-southeast-1.wasabisys.com'}))
                              } else if (option.key === 'storj') {
                                setOptionsEndpointS3([{key: "gateway.storjshare.io", text: "storjshare-gateway"}]); 
                                setDialogConnectNewDrive(prev => ({ ...prev, endpoint: 'gateway.storjshare.io'}))
                              }
                            }}>
                              {option.text}
                            </Option>
                          ))}
                        </Dropdown>
                      </div>
                      {dialogConnectNewDrive.storage !== 'googleDrive' && dialogConnectNewDrive.storage !== 'oneDrive' ? (
                        <>
                          <div className={styles.root}>
                            <label id={`${dropdownId}-region`}>Region</label>
                            <Dropdown
                              aria-labelledby={`${dropdownId}-region`}
                              placeholder={t('storagesConnect.selectRegion')}
                            >
                              {optionsEndpointS3.map((option) => (
                                <Option key={option.key} onClick={e => {
                                  setDialogConnectNewDrive(prev => ({ ...prev, region: option.text, endpoint: option.key}))
                                  
                                }}>
                                  {option.text}
                                </Option>
                              ))}
                            </Dropdown>
                          </div>
                          <Field
                            label="Access Key Id"
                          >
                            <Input value={dialogConnectNewDrive.accessKeyId} onChange={(e, data) => setDialogConnectNewDrive(prev => ({ ...prev, accessKeyId: data.value}))} />
                          </Field>
                          <Field
                            label="Secret Access Key"
                          >
                            <Input value={dialogConnectNewDrive.secretAccessKey} onChange={(e, data) => setDialogConnectNewDrive(prev => ({ ...prev, secretAccessKey: data.value}))} />
                          </Field>
                        </>
                      ) : null}
                    </>
                  )
                  
                )}
              </DialogContent>
              <DialogActions>
                {dialogConnectNewDrive.loading ? null : (
                  dialogConnectNewDrive.status === "buckets" ? (
                     
                      <Button appearance="primary" onClick={(e) => {
                        setDialogConnectNewDrive(prev => ({...prev, loading: true}))
      
                        instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
                          axios
                            .get(
                              'http://localhost:7071/api/AWS_listObjects',
                              {
                                headers: {
                                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                                  cid: companyId
                                },
                                params: {
                                  accessKeyId: dialogConnectNewDrive.accessKeyId,
                                  secretAccessKey: dialogConnectNewDrive.secretAccessKey,
                                  storage: dialogConnectNewDrive.storage,
                                  region: dialogConnectNewDrive.region,
                                  bucket: dialogConnectNewDrive.selectedBucket,
                    
                                  // ContinuationToken: moreContent.ContinuationToken,
                                  // StartAfter: moreContent.StartAfter
                                }
                              }
                            )
                            .then((response) => {
                              
                              console.log('response', response);
                    
                              if (response.data.success) {
                    
                                axios
                                  .post('http://localhost:7071/api/Storages', {
                                    type: 's3',
                                    accessKeyId: dialogConnectNewDrive.accessKeyId,
                                    secretAccessKey: dialogConnectNewDrive.secretAccessKey,
                                    storage: dialogConnectNewDrive.storage,
                                    region: dialogConnectNewDrive.region,
                                    bucket: dialogConnectNewDrive.selectedBucket
                                  }, {
                                    headers: {
                                      // Authorization: 'Bearer ' + tokenResponse.accessToken,
                                      // 'cid': companyId
                                    },
                                  }).then(res => {
                                    history.push({
                                      pathname: '/storages',
                                      state: {
                                        id: dialogConnectNewDrive.selectedBucket
                                      },
                                    });

                                    
                                  })
                              }
                    
                              setDialogConnectNewDrive(prev => ({...prev, loading: false}))
                            });
                            
                        })
                          .catch((error) => {
                            //Acquire token silent failure, and send an interactive request
                            console.log(error);
                            instance.acquireTokenRedirect(silentRequest);
                          });
                      }}>{t('storagesConnect.connect')}</Button>
                    
                    
                  ) : (
                    dialogConnectNewDrive.storage === 'googleDrive' ? (
                      <Button appearance="primary" onClick={() => window.location.replace(callBackUrlGoogleDrive)}>{t('storagesConnect.connect')}</Button>
                    ) : dialogConnectNewDrive.storage === 'oneDrive' ? (
                      <Button appearance="primary" onClick={() => window.location.replace(callBackUrlMSOneDrive)}>{t('storagesConnect.connect')}</Button>
                    ) :
                    <Button appearance="primary" onClick={(e) => {
                      setDialogConnectNewDrive(prev => ({...prev, loading: true}))
    
                      instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
                        axios
                          .post(
                              'http://localhost:7071/api/AWS_connect',
                            // 'http://localhost:7071/api/AwsConnectionsCheck',
                            // API_URL + '/storages/s3/connections/check',
                            {
                              accessKeyId: dialogConnectNewDrive.accessKeyId,
                              secretAccessKey: dialogConnectNewDrive.secretAccessKey,
                              // storage: storage,
                              region: dialogConnectNewDrive.region,
                              endpoint: dialogConnectNewDrive.endpoint
                            },
                            {
                              headers: {
                                Authorization: 'Bearer ' + tokenResponse.accessToken,
                                cid: companyId
                              },
                            }
                          )
                          .then((response) => {
                  
                            console.log('response', response);
                  
                            if (response.data.success) {
                              if (response.data.Buckets.length) {
                                // setStatus('buckets')
                                // setBuckets(response.data.Buckets)
                                // setSelectedBucket(response.data.Buckets[0].Name)
    
                                setDialogConnectNewDrive(prev => ({...prev, 
                                  loading: false, 
                                  status: 'buckets',
                                  buckets: response.data.Buckets,
                                  selectedBucket: response.data.Buckets[0].Name
                                }))
                              }
                              // setStatus("success");
                            } else {
                              // setStatus("fail");
    
                              setDialogConnectNewDrive(prev => ({...prev, 
                                loading: false, 
                                status: 'fail',
                                // buckets: response.data.Buckets,
                                // selectedBucket: response.data.Buckets[0].Name
                              }))
                            }
                  
                            // setIsLoading(false);
                          });
                      }).catch((error) => {
                        //Acquire token silent failure, and send an interactive request
                        console.log(error);
                        instance.acquireTokenRedirect(silentRequest);
                      });
                    }}>{t('storagesConnect.connect')}</Button>
                  )

                )}
                
                
                <DialogTrigger disableButtonEnhancement>
                  <Button appearance="secondary" onClick={() => 
                    setDialogConnectNewDrive({
                      open: false,
                      loading: false,
                      status: '',
                      storage: '',
                      region: '',
                      endpoint: '',
                      accessKeyId: '',
                      secretAccessKey: '',
                      bucket: '',
                      buckets: [],
                      selectedBucket: '',
                    })
                  }>{t('storagesConnect.cancel')}</Button>
                </DialogTrigger>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
    </div>
}

export default GoogleDriveStorages;