import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import { CSVLink } from 'react-csv';

import paginate from 'jw-paginate';

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  Selection,
  SelectionMode,
  CheckboxVisibility,
  mergeStyleSets,
  Calendar,
  DateRangeType,
  ActionButton,
  Spinner,
  SpinnerSize,
  CommandBar,
  SearchBox,
  Stack,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  mergeStyles,
  TextField,
  DocumentCard,
  DocumentCardTitle,
  DocumentCardDetails,
  DocumentCardLogo,
  DocumentCardType,
  Text,
  ContextualMenu,
  ContextualMenuItemType,
  DatePicker,
  Checkbox,
  TooltipHost,
  MessageBar,
  MessageBarType,
} from '@fluentui/react';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

import CountUp from 'react-countup';
import DialogEmail from '../../../helpers/customComponents/DialogEmail';

const API_URL = process.env.REACT_APP_API_URL;

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

//Dialog Style
// Success dialog style
const iconClass = mergeStyles({
  fontSize: 120,
  height: 120,
  width: 120,
  margin: '0 60px',
});

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px',
  },
  fileIconCell: {
    textAlign: 'center',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden',
      },
    },
  },
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
  },
  controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  exampleToggle: {
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '30px',
  },
  selectionDetails: {
    marginBottom: '20px',
  },
  blue: [
    {
      color: '#0078D4',
    },
    iconClass,
  ],
});

const cardStyles = {
  cardStyles: {
    root: {
      background: 'white',
      borderLeft: '5px solid #0078d4',
      width: '30%',
      minWidth: '315px',
      maxWidth: '424px',
      height: '130px',
    },
  },
  header: {
    root: {
      fontSize: 20,
      fontWeight: 'bold',
      paddingTop: '20px',
      textAlign: 'center',
    },
  },
  amount: {
    root: {
      fontSize: 30,
      paddingBottom: '25px',
      textAlign: 'center',
    },
  },
};

function formatDateTime(date) {
  let d = new Date(date.toLocaleString('en-GB', { timeZone: 'Asia/Bangkok' })),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hour = '' + d.getHours(),
    minute = '' + d.getMinutes(),
    second = '' + d.getSeconds();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hour.length < 2) hour = '0' + hour;
  if (minute.length < 2) minute = '0' + minute;
  if (second.length < 2) second = '0' + second;

  return [year, month, day, hour, minute, second].join('');
}

const onFormatDate = (date, format) => {
  if (format === 'CE') {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear();
  } else {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + (date.getFullYear() + 543);
  }
};

const SellSummary = () => {
  console.log('Home render!!!');

  const { t, i18n } = useTranslation();

  const history = useHistory();

  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];

  const companyId = sessionStorage.getItem("companyId") || "";

  const csvLink = useRef();

  const [company, setCompany] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [html] = useState('');
  const [companyTaxId, setCompanyTaxId] = useState('');
  const [companyBranchText, setCompanyBranchText] = useState('');
  const [companyAllAddress, setCompanyAllAddress] = useState('');
  const [branchNumber, setBranchNumber] = useState('');

  const [filter_docs, setFilter_docs] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(true);
  const [totalProcessingDocuments] = useState(0);
  const [pageData, setPageData] = useState({});
  const [pageSize, setPageSize] = useState(sessionStorage.getItem('sellSummary-pageSize') * 1 || 10);
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('sellSummary-currentPage') * 1 || 1);

  //CommandBars Items
  const [documentType] = useState({
    key: 'ALL',
    text: 'เอกสารทุกประเภท',
  });

  const [timeRangeDate, setTimeRangeDate] = useState(false);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [timeRangeError, setTimeRangeError] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        let _selectedItems = selection.getSelection();

        setSelectedItems(_selectedItems);
      },
    })
  );

  const [isEmailSetting, setIsEmailSetting] = useState(false);

  const [isClickEmail, setIsClickEmail] = useState(false);
  const [fileIdEmail, setFileIdEmail] = useState("");
  const [fileNameEmail, setFileNameEmail] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [isClickCopyEmail, setIsClickCopyEmail] = useState(false);
  const [fileDisplayName, setFileDisplayName] = useState('');
  const [emailList, setEmailList] = useState([]);
  const [copyEmailList, setCopyEmailList] = useState([])
  const [copyEmail, setCopyEmail] = useState('');
  const [showEmailSuccess, setShowEmailSuccess] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);

  const [isClickSms, setIsClickSms] = useState(false);
  const [fileIdSms, setFileIdSms] = useState('');
  const [fileNameSms, setFileNameSms] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [errorMessageSms, setErrorMessageSms] = useState('');

  //Display Summary
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [totalVat, setTotalVat] = useState(0);
  const [totalGrand, setTotalGrand] = useState(0);

  const [search, setSearch] = useState('');
  const [previousSearch, setPreviousSearch] = useState('');
  const [filterDocuments, setFilterDocuments] = useState([]);

  const [date, setDate] = useState(sessionStorage.getItem('sellSummary-date') ? new Date(sessionStorage.getItem('sellSummary-date')) : new Date());

  const [primarySort, setPrimarySort] = useState(sessionStorage.getItem('sellSummary-primarySort') || 'documentDate');
  const [isSortedDescendingDocumentDate, setIsSortedDescendingDocumentDate] = useState(() => {
    if (sessionStorage.getItem('sellSummary-sort-documentDate')) {
      if (sessionStorage.getItem('sellSummary-sort-documentDate') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  });
  const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(() => {
    if (sessionStorage.getItem('sellSummary-sort-number') && sessionStorage.getItem('sellSummary-sort-number') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingCustomerName, setIsSortedDescendingCustomerName] = useState(() => {
    if (sessionStorage.getItem('sellSummary-sort-customerName') && sessionStorage.getItem('sellSummary-sort-customerName') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingTaxId, setIsSortedDescendingTaxId] = useState(() => {
    if (sessionStorage.getItem('sellSummary-sort-taxId') && sessionStorage.getItem('sellSummary-sort-taxId') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingAmountTotal, setIsSortedDescendingAmountTotal] = useState(() => {
    if (sessionStorage.getItem('sellSummary-sort-amountTotal') && sessionStorage.getItem('sellSummary-sort-amountTotal') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingVatTotal, setIsSortedDescendingVatTotal] = useState(() => {
    if (sessionStorage.getItem('sellSummary-sort-vatTotal') && sessionStorage.getItem('sellSummary-sort-vatTotal') === 'true') {
      return true;
    } else {
      return false;
    }
  });

  const [isSortedDescendingGrandTotal, setIsSortedDescendingGrandTotal] = useState(() => {
    if (sessionStorage.getItem('sellSummary-sort-grandTotal') && sessionStorage.getItem('sellSummary-sort-grandTotal') === 'true') {
      return true;
    } else {
      return false;
    }
  });

  const [isSortedDescendingName, setIsSortedDescendingName] = useState(() => {
    if (sessionStorage.getItem('sellSummary-sort-name') && sessionStorage.getItem('sellSummary-sort-name') === 'true') {
      return true;
    } else {
      return false;
    }
  });

  const [isDownloading, setIsDownloading] = useState(false);

  const DayPickerStrings = {
    months: [
      t('sellSummary.months01'),
      t('sellSummary.months02'),
      t('sellSummary.months03'),
      t('sellSummary.months04'),
      t('sellSummary.months05'),
      t('sellSummary.months06'),
      t('sellSummary.months07'),
      t('sellSummary.months08'),
      t('sellSummary.months09'),
      t('sellSummary.months10'),
      t('sellSummary.months11'),
      t('sellSummary.months12'),
    ],

    shortMonths: [
      t('sellSummary.shortMonths01'),
      t('sellSummary.shortMonths02'),
      t('sellSummary.shortMonths03'),
      t('sellSummary.shortMonths04'),
      t('sellSummary.shortMonths05'),
      t('sellSummary.shortMonths06'),
      t('sellSummary.shortMonths07'),
      t('sellSummary.shortMonths08'),
      t('sellSummary.shortMonths09'),
      t('sellSummary.shortMonths10'),
      t('sellSummary.shortMonths11'),
      t('sellSummary.shortMonths12'),
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: 'ไปวันที่ปัจจุบัน',
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: t('sellSummary.isRequiredErrorMessage'),

    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };

  function getDateText(monthObject) {
    if (monthObject.type === 'dateRange') {
      if (i18n.language === 'th') {
        return onFormatDate(fromDate, 'BE') + ' - ' + onFormatDate(toDate, 'BE');
      }
      return onFormatDate(fromDate, 'CE') + ' - ' + onFormatDate(toDate, 'CE');
    }
    if (monthObject.text === 'เดือนนี้' || monthObject.text === 'This Month') return t('sellSummary.thisMonth');

    let monthNames = [
      t('sellSummary.months01'),
      t('sellSummary.months02'),
      t('sellSummary.months03'),
      t('sellSummary.months04'),
      t('sellSummary.months05'),
      t('sellSummary.months06'),
      t('sellSummary.months07'),
      t('sellSummary.months08'),
      t('sellSummary.months09'),
      t('sellSummary.months10'),
      t('sellSummary.months11'),
      t('sellSummary.months12'),
    ];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    if (i18n.language === 'th') {
      _year = _year + 543;
    }

    return monthNames[_monthIndex] + ' ' + _year;
  }

  const headerCSV = [
    { key: 'r1', label: '' },
    { key: 'r2', label: '' },
    { key: 'r3', label: '' },
    { key: 'r4', label: '' },
    { key: 'r5', label: '' },
    { key: 'r6', label: '' },
    { key: 'r7', label: '' },
    { key: 'r8', label: '' },
    { key: 'r9', label: '' },
    { key: 'r10', label: '' },
    { key: 'r11', label: '' },
  ];

  function getCSVData() {
    let month = queryMonth.text === 'เดือนนี้' || 'This Month' ? getMonth(0).text : queryMonth.text;

    let csvData = [
      {
        r1: 'รายงานยอดขาย',
        r2: '',
        r3: '',
        r4: '',
        r5: '',
        r6: '',
        r7: '',
        r8: '',
        r9: '',
        r10: '',
        r11: '',
      },
      {
        r1: 'เดือน ' + month,
        r2: '',
        r3: '',
        r4: '',
        r5: '',
        r6: '',
        r7: '',
        r8: '',
        r9: '',
        r10: '',
        r11: '',
      },
      {
        r1: 'ชื่อผู้ประกอบการ ' + companyName + (companyBranchText ? ' ' + companyBranchText : ''),
        r2: '',
        r3: '',
        r4: '',
        r5: '',
        r6: '',
        r7: '',
        r8: '',
        r9: '',
        r10: '',
        r11: '',
      },
      {
        r1: 'เลขประจำตัวผู้เสียภาษี ' + companyTaxId,
        r2: '',
        r3: '',
        r4: '',
        r5: '',
        r6: '',
        r7: '',
        r8: '',
        r9: '',
        r10: '',
        r11: '',
      },
      {
        r1: 'ที่อยู่ ' + companyAllAddress,
        r2: '',
        r3: '',
        r4: '',
        r5: '',
        r6: '',
        r7: '',
        r8: '',
        r9: '',
        r10: '',
        r11: '',
      },
      {},
      {
        r1: 'วันที่',
        r2: 'เลขที่เอกสาร',
        r3: 'ชื่อผู้ซื้อสินค้า/ผู้รับบริการ',
        r4: 'เลขประจำตัวผู้เสียภาษี',
        r5: 'มูลค่าสินค้า/บริการ',
        r6: 'ส่วนลด',
        r7: 'มูลค่าสินค้า/บริการ (หลังส่วนลด)',
        r8: 'จำนวนเงินภาษี',
        r9: 'มูลค่าสินค้า/บริการ (รวม Vat)',
        r10: 'หมายเหตุ',
        r11: 'อ้างอิง',
      },
    ];

    if (selectedItems.length) {
      selectedItems.forEach((doc) => {
        let row = {
          r1: '=""' + doc.documentDate + '""',
          r2: '=""' + doc.number + '""',
          r3: doc.customerName ? '=""' + doc.customerName  + '""' : "",
          r4: '=""' + (doc.taxId ? doc.taxId : "-") + '""',
          r5: !doc.reIssue
            ? doc.preAmountTotal.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : '',
          r6: !doc.reIssue
            ? doc.discount.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : '',
          r7: !doc.reIssue
            ? doc.amountTotal.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : '',
          r8: !doc.reIssue
            ? doc.vatTotal.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : '',
          r9: !doc.reIssue
            ? doc.grandTotal.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : '',
          r10: '=""' + (doc.note ? doc.note : '') + '""',
          r11: '=""' + (doc.reference ? doc.reference : '') + '""',
        };

        csvData.push(row);
      });

      const _amountTotal = selectedItems.reduce((accumulator, doc) => accumulator + (!doc.reIssue ? doc.amountTotal : 0), 0);
      const _vatTotal = selectedItems.reduce((accumulator, doc) => accumulator + (!doc.reIssue ? doc.vatTotal : 0), 0);
      const _grandTotal = selectedItems.reduce((accumulator, doc) => accumulator + (!doc.reIssue ? doc.grandTotal : 0), 0);

      csvData.push({
        r1: '',
        r2: '',
        r3: '',
        r4: '',
        r5: '',
        r6: '',
        r7: '',
        r8: '',
        r9: '',
        r10: '',
        r11: '',
      });

      csvData.push({
        r1: '',
        r2: '',
        r3: '',
        r4: '',
        r5: '',
        r6: 'รวม',
        r7: _amountTotal.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        r8: _vatTotal.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        r9: _grandTotal.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        r10: '',
        r11: '',
      });
    } else {
      filter_docs.forEach((doc) => {
        let row = {
          r1: '=""' + doc.documentDate + '""',
          r2: '=""' + doc.number + '""',
          r3: doc.customerName,
          r4: '=""' + (doc.taxId ? (doc.taxId === 'N/A' ? '-' : doc.taxId) : '-') + '""',
          r5: !doc.reIssue
            ? doc.preAmountTotal.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : '',
          r6: !doc.reIssue
            ? doc.discount.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : '',
          r7: !doc.reIssue
            ? doc.amountTotal.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : '',
          r8: !doc.reIssue
            ? doc.vatTotal.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : '',
          r9: !doc.reIssue
            ? doc.grandTotal.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : '',
          r10: '=""' + (doc.note ? doc.note : '') + '""',
          r11: '=""' + (doc.reference ? doc.reference : '') + '""',
        };

        csvData.push(row);
      });

      const _amountTotal = filter_docs.reduce((accumulator, doc) => accumulator + (!doc.reIssue ? doc.amountTotal : 0), 0);
      const _vatTotal = filter_docs.reduce((accumulator, doc) => accumulator + (!doc.reIssue ? doc.vatTotal : 0), 0);
      const _grandTotal = filter_docs.reduce((accumulator, doc) => accumulator + (!doc.reIssue ? doc.grandTotal : 0), 0);

      csvData.push({
        r1: '',
        r2: '',
        r3: '',
        r4: '',
        r5: '',
        r6: '',
        r7: '',
        r8: '',
        r9: '',
        r10: '',
        r11: '',
      });

      csvData.push({
        r1: '',
        r2: '',
        r3: '',
        r4: '',
        r5: '',
        r6: 'รวม',
        r7: _amountTotal.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        r8: _vatTotal.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        r9: _grandTotal.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        r10: '',
        r11: '',
      });
    }

    return csvData;
  }

  const getfileName = () => {
    const time = formatDateTime(new Date().toISOString());
    let fileName = time + '-Report-Sell-Summary.csv';
    return fileName;
  };

  const [fileName] = useState(getfileName);

  const getMonth = (goBack) => {
    let monthNames = [
      t('sellSummary.months01'),
      t('sellSummary.months02'),
      t('sellSummary.months03'),
      t('sellSummary.months04'),
      t('sellSummary.months05'),
      t('sellSummary.months06'),
      t('sellSummary.months07'),
      t('sellSummary.months08'),
      t('sellSummary.months09'),
      t('sellSummary.months10'),
      t('sellSummary.months11'),
      t('sellSummary.months12'),
    ];

    let d = new Date();

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + ' ' + year,
    };
  };

  const [queryMonth, setQueryMonth] = useState(
    (sessionStorage.getItem('sellSummary-queryMonth-key') && {
      key: sessionStorage.getItem('sellSummary-queryMonth-key'),
      text: sessionStorage.getItem('sellSummary-queryMonth-text'),
    }) || { key: getMonth(0).year + '' + getMonth(0).key, text: t('sellSummary.thisMonth') }
  );

  const getFrom = (month, year) => {
    let _firstDay = new Date(year, month, 1);
    console.log('First day: ', formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getTo = (month, year) => {
    let _lastDay = new Date(year, month + 1, 1);
    console.log('Last day: ', formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const getFrom2 = (day, month, year) => {
    let _firstDay = new Date(year, month, day);
    console.log('First day: ', formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getTo2 = (day, month, year) => {
    let _lastDay = new Date(year, month, day + 1);
    console.log('Last day: ', formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const formatDate = (d) => {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const [from, setFrom] = useState(sessionStorage.getItem('sellSummary-from') || getFrom(getMonth(0).month, getMonth(0).year));
  const [to, setTo] = useState(sessionStorage.getItem('sellSummary-to') || getTo(getMonth(0).month, getMonth(0).year));

  const [pageDropdown, setPageDropdown] = useState([]);

  const commandBarItems = [
    {
      key: 'pdf',
      text: t('sellSummary.report'),
      iconProps: { iconName: 'TextDocument' },
      disabled: !loadDataComplete,
      onClick: () => {
        console.log('pdf');

        setSearch('');
        onSubmitHTMLcreate();
      },
    },
    {
      key: queryMonth.key,
      text: getDateText(queryMonth),
      iconProps: { iconName: 'Calendar' },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: [
          {
            key: 'queryMonth',
          },
        ],
        onRenderMenuList: () => {
          return (
            <div style={{ width: 220 }}>
              <Stack styles={{ root: { padding: '12px 12px 0px 12px' } }}>
                <Checkbox
                  label={t('sellSummary.timeRange')}
                  checked={timeRangeDate}
                  onChange={(e) => {
                    setTimeRangeDate((prev) => !prev);
                  }}
                />
              </Stack>
              {timeRangeDate ? (
                <Stack>
                  <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                    <Stack styles={{ root: { width: '20%', justifyContent: 'center', alignItems: 'center' } }}>
                      <Text>{t('sellSummary.fromRange')}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '80%', justifyContent: 'center' /* alignItems: 'center' */ } }}>
                      <DatePicker
                        styles={{ root: { width: '90%' } }}
                        placeholder={t('deliveryOrderTaxInvoice.pleaseSelect')}
                        strings={DayPickerStrings}
                        value={fromDate}
                        maxDate={toDate}
                        formatDate={onFormatDate}
                        onSelectDate={(_date) => {
                          setFromDate(_date);

                          let adjustDate = _date;

                          //       let year = adjustDate.getFullYear() + 543;

                          // setQueryMonth({
                          //   type: 'dateRange',
                          //   key: adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'),
                          //   text: monthNames[adjustDate.getMonth()] + ' ' + year,
                          // });

                          setFrom(getFrom2(adjustDate.getDate(), adjustDate.getMonth(), adjustDate.getFullYear()));

                          setTimeRangeError(false);
                          if (Math.ceil(Math.abs(_date - toDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                        }}
                      />
                    </Stack>
                  </Stack>
                  <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                    <Stack styles={{ root: { width: '20%', justifyContent: 'center', alignItems: 'center' } }}>
                      <Text>{t('sellSummary.toRange')}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '80%' /* alignItems: 'center' */ } }}>
                      <DatePicker
                        styles={{ root: { width: '90%' } }}
                        placeholder={t('deliveryOrderTaxInvoice.pleaseSelect')}
                        strings={DayPickerStrings}
                        value={toDate}
                        minDate={fromDate}
                        formatDate={onFormatDate}
                        onSelectDate={(_date) => {
                          setToDate(_date);

                          let adjustDate = _date;

                          setTo(getTo2(adjustDate.getDate(), adjustDate.getMonth(), adjustDate.getFullYear()));
                          setTimeRangeError(false);
                          if (Math.ceil(Math.abs(_date - fromDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                        }}
                      />
                    </Stack>
                  </Stack>
                  {timeRangeError ? (
                    <Stack styles={{ root: { padding: '12px 0px 0px 12px' } }}>
                      <Text styles={{ root: { fontSize: 12, color: '#A4262C' } }}>{t('sellSummary.timeRangeError')}</Text>
                    </Stack>
                  ) : null}
                  <Stack horizontal horizontalAlign="end" styles={{ root: { width: '92%', height: 52, padding: '10px 0px 10px 0px' } }}>
                    <PrimaryButton
                      text={t('sellSummary.search')}
                      onClick={() => {
                        if (!timeRangeError && fromDate && toDate) {
                          setQueryMonth({
                            type: 'dateRange',
                            key: formatDate(fromDate) + ':' + formatDate(toDate),
                            text: formatDate(fromDate) + ' - ' + formatDate(toDate),
                          });

                          setLoadDataComplete(false);
                          setItems([]);
                          setGetNewDocuments(true);
                          setPreviousSearch('');

                          setTotalDocuments(0);
                          setTotalGrand(0);
                          setTotalVat(0);

                          setCurrentPage(1);

                          setSearch('');
                        }
                      }}
                      style={{ width: '125px', letterSpacing: '1px' }}
                    />
                  </Stack>
                </Stack>
              ) : (
                <Calendar
                  onSelectDate={(_date) => {
                    let monthNames = [
                      t('sellSummary.months01'),
                      t('sellSummary.months02'),
                      t('sellSummary.months03'),
                      t('sellSummary.months04'),
                      t('sellSummary.months05'),
                      t('sellSummary.months06'),
                      t('sellSummary.months07'),
                      t('sellSummary.months08'),
                      t('sellSummary.months09'),
                      t('sellSummary.months10'),
                      t('sellSummary.months11'),
                      t('sellSummary.months12'),
                    ];

                    setDate(_date);

                    sessionStorage.setItem('sellSummary-date', _date.toISOString());

                    let adjustDate = _date;

                    let year = adjustDate.getFullYear() + 543;

                    setQueryMonth({
                      key: adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'),
                      text: monthNames[adjustDate.getMonth()] + ' ' + year,
                    });

                    sessionStorage.setItem(
                      'sellSummary-queryMonth-key',
                      adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0')
                    );
                    sessionStorage.setItem('sellSummary-queryMonth-text', monthNames[adjustDate.getMonth()] + ' ' + year);

                    setFrom(getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                    setTo(getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                    sessionStorage.setItem('sellSummary-from', getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                    sessionStorage.setItem('sellSummary-to', getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                    setLoadDataComplete(false);
                    setItems([]);
                    setGetNewDocuments(true);
                    setPreviousSearch('');

                    setTotalDocuments(0);
                    setTotalGrand(0);
                    setTotalVat(0);

                    setCurrentPage(1);

                    setSearch('');

                    setPrimarySort('documentDate');
                    setIsSortedDescendingDocumentDate(true);
                    setIsSortedDescendingNumber(false);
                    setIsSortedDescendingCustomerName(false);
                    setIsSortedDescendingTaxId(false);
                    setIsSortedDescendingAmountTotal(false);
                    setIsSortedDescendingVatTotal(false);
                    setIsSortedDescendingGrandTotal(false);
                    setIsSortedDescendingName(false);

                    sessionStorage.setItem('sellSummary-primarySort', 'documentDate');
                    sessionStorage.setItem('sellSummary-sort-documentDate', 'true');
                    sessionStorage.setItem('sellSummary-sort-number', 'false');
                    sessionStorage.setItem('sellSummary-sort-customerName', 'false');
                    sessionStorage.setItem('sellSummary-sort-taxId', 'false');
                    sessionStorage.setItem('sellSummary-sort-amountTotal', 'false');
                    sessionStorage.setItem('sellSummary-sort-vatTotal', 'false');
                    sessionStorage.setItem('sellSummary-sort-grandTotal', 'false');
                    sessionStorage.setItem('sellSummary-sort-name', 'false');
                  }}
                  isMonthPickerVisible={true}
                  highlightSelectedMonth={true}
                  isDayPickerVisible={false}
                  dateRangeType={DateRangeType.Day}
                  autoNavigateOnSelection={false}
                  showGoToToday={false}
                  value={date}
                  strings={DayPickerStrings}
                  showSixWeeksByDefault={true}
                />
              )}
            </div>
          );
        },
      },
    },
    {
      key: 'pageSize ' + pageSize,
      text: pageSize + ' ' + t('sellSummary.documentPerPage'),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'NumberedList' },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: [
          {
            key: 'pageSize ' + 10,
            text: '10 ' + t('sellSummary.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('10 ออเดอร์/หน้า');
              setPageSize(10);
              sessionStorage.setItem('sellSummary-pageSize', 10);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');

              setSearch('');

              setTotalDocuments(0);
              setTotalGrand(0);
              setTotalVat(0);
            },
          },
          {
            key: 'pageSize ' + 20,
            text: '20 ' + t('sellSummary.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('20 ออเดอร์/หน้า');
              setPageSize(20);
              sessionStorage.setItem('sellSummary-pageSize', 20);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');

              setSearch('');

              setTotalDocuments(0);
              setTotalGrand(0);
              setTotalVat(0);
            },
          },
          {
            key: 'pageSize ' + 30,
            text: '30 ' + t('sellSummary.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('30 ออเดอร์/หน้า');
              setPageSize(30);
              sessionStorage.setItem('sellSummary-pageSize', 30);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');

              setSearch('');

              setTotalDocuments(0);
              setTotalGrand(0);
              setTotalVat(0);
            },
          },
          {
            key: 'pageSize ' + 40,
            text: '40 ' + t('sellSummary.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('40 ออเดอร์/หน้า');
              setPageSize(40);
              sessionStorage.setItem('sellSummary-pageSize', 40);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');

              setSearch('');

              setTotalDocuments(0);
              setTotalGrand(0);
              setTotalVat(0);
            },
          },
          {
            key: 'pageSize ' + 50,
            text: '50 ' + t('sellSummary.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('50 ออเดอร์/หน้า');
              setPageSize(50);
              sessionStorage.setItem('sellSummary-pageSize', 50);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');

              setSearch('');

              setTotalDocuments(0);
              setTotalGrand(0);
              setTotalVat(0);
            },
          },
        ],
      },
    },
    {
      key: currentPage,
      text: t('sellSummary.page') + ' ' + currentPage,
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: pageDropdown,
      },
    },
    {
      key: 'download',
      text: t('sellSummary.download'),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Download' },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: [
          {
            key: 'download-pdf',
            text: t('sellSummary.downloadPdf'),
            iconProps: { iconName: 'PDF' },
            onClick: () => {
              console.log('Download PDF');
              handleDownload();
            },
          },
          {
            key: 'download-csv',
            text: t('sellSummary.downloadCsv'),
            iconProps: { iconName: 'ExcelDocument' },
            onClick: () => {
              console.log('Download CSV');
              handleDownloadCSV();
            },
          },
        ],
      },
    },
    {
      key: 'refresh',
      text: t('sellSummary.refresh'),
      iconProps: { iconName: 'Refresh' },
      disabled: !loadDataComplete,
      onClick: () => {
        console.log('Refresh');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setPreviousSearch('');

        setTotalDocuments(0);
        setTotalGrand(0);
        setTotalVat(0);

        setCurrentPage(1);

        setSearch('');
      },
    },
  ];

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const sendEmail = (fileName, fileId, email, copyEmail) => {
    if (email !== '' && email !== null && email !== undefined) {
      if ((isClickCopyEmail && copyEmail !== '' && copyEmail !== null && copyEmail !== undefined) || !isClickCopyEmail) {
        setIsClickEmail(false);

        setIsClickCopyEmail(false);

        instance
          .acquireTokenSilent({ ...silentRequest, account: account })
          .then((tokenResponse) => {
            axios
              .post(
                API_URL + '/emails/send',
                {
                  fileId: fileId,
                  email: email,
                  copyEmail: copyEmail,
                },
                {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    'cid': companyId,
                    'Content-Type': 'application/json',
                },
                }
              )
              .then(
                (response) => {
                  console.log("response /emails/send", response);
                  setIsClickEmail(false);
                  setShowEmailSuccess(true);
                  window.scrollTo(0, 0);
                },
                (error) => {
                  console.log(error);
                  setIsClickEmail(false);
                  setShowEmailError(true);
                  window.scrollTo(0, 0);
                }
              );
          })
          .catch((error) => {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);
          });
      }
    }
  };

  const sendSms = (fileId, mobile) => {
    const re = /^0[1-9]{1}[0-9]{8}/;

    if (mobile !== '' && mobile !== null && mobile !== undefined && re.test(String(mobile))) {
      setIsClickSms(false);
      setErrorMessageSms('');

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .post(
              API_URL + '/etax/sms/',
              {
                fileId: fileId,
                mobile: mobile,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId,
                  'Content-Type': 'application/json',
                },
              }
            )
            .then(
              (response) => {
                console.log(response);
                setIsClickSms(false);
                window.scrollTo(0, 0);
              },
              (error) => {
                console.log(error);
                setIsClickSms(false);
                window.scrollTo(0, 0);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    } else {
      setErrorMessageSms('กรุณากรอกเบอร์มือถือที่ถูกต้อง');
    }
  };

  function displayTextWidth(text, font) {
    let canvas = displayTextWidth.canvas || (displayTextWidth.canvas = document.createElement('canvas'));
    let context = canvas.getContext('2d');
    context.font = font;
    let metrics = context.measureText(text);
    return metrics.width;
  }

  const arrayTo2DArray2 = (arr, howMany) => {
    let num = howMany;
    let result = [[]];

    for (let i = 0; i < arr.length; i++) {
      let widthCustomerName = displayTextWidth(arr[i].customerName, '11px Sarabun, sans-serif');

      let widthNumber = displayTextWidth(arr[i].number, '12px Sarabun, sans-serif');

      if (widthCustomerName > 209 || widthNumber > 108) {
        if (Math.ceil(widthCustomerName / 209) >= Math.ceil(widthNumber / 108)) {
          num = num - (Math.ceil(widthCustomerName / 209) - 1);
        } else {
          num = num - (Math.ceil(widthNumber / 108) - 1);
        }
      }
      if (result[result.length - 1].length < num) {
        result[result.length - 1].push(arr[i]);
      } else {
        result.push([arr[i]]);
        if (widthCustomerName > 209 || widthNumber > 108) {
          if (Math.ceil(widthCustomerName / 209) >= Math.ceil(widthNumber / 108)) {
            num = howMany - (Math.ceil(widthCustomerName / 209) - 1);
          } else {
            num = howMany - (Math.ceil(widthNumber / 108) - 1);
          }
        } else {
          num = howMany;
        }
      }
    }

    return result;
  };

  const onSubmitHTMLcreate = () => {
    let all_docs = [];

    let _preAmount = 0;
    let _discount = 0;
    let _amountTotal = 0;
    let _vatTotal = 0;
    let _grandTotal = 0;

    if (selectedItems.length) {
      all_docs = arrayTo2DArray2(selectedItems, 25);

      _preAmount = selectedItems.reduce((accumulator, doc) => accumulator + (!doc.reIssue ? doc.amountTotal + doc.discount : 0), 0);
      _discount = selectedItems.reduce((accumulator, doc) => accumulator + (!doc.reIssue ? doc.discount : 0), 0);
      _amountTotal = selectedItems.reduce((accumulator, doc) => accumulator + (!doc.reIssue ? doc.amountTotal : 0), 0);
      _vatTotal = selectedItems.reduce((accumulator, doc) => accumulator + (!doc.reIssue ? doc.vatTotal : 0), 0);
      _grandTotal = selectedItems.reduce((accumulator, doc) => accumulator + (!doc.reIssue ? doc.grandTotal : 0), 0);
    } else {
      all_docs = arrayTo2DArray2(filter_docs, 25);

      _preAmount = filter_docs.reduce((accumulator, doc) => accumulator + (!doc.reIssue ? doc.amountTotal + doc.discount : 0), 0);
      _discount = filter_docs.reduce((accumulator, doc) => accumulator + (!doc.reIssue ? doc.discount : 0), 0);
      _amountTotal = filter_docs.reduce((accumulator, doc) => accumulator + (!doc.reIssue ? doc.amountTotal : 0), 0);
      _vatTotal = filter_docs.reduce((accumulator, doc) => accumulator + (!doc.reIssue ? doc.vatTotal : 0), 0);
      _grandTotal = filter_docs.reduce((accumulator, doc) => accumulator + (!doc.reIssue ? doc.grandTotal : 0), 0);
    }

    history.push({
      pathname: '/reports/sell-summary/confirm',
      state: {
        // location state
        all_docs: all_docs,
        totalPages: all_docs.length,
        month: queryMonth.text === 'เดือนนี้' || queryMonth.text === 'This Month' ? getMonth(0).text : queryMonth.text,
        totalPreAmount: _preAmount,
        totalDiscount: _discount,
        totalVat: _vatTotal,
        totalAmount: _amountTotal,
        totalGrand: _grandTotal,
        companyName: companyName,
        companyTaxId: companyTaxId,
        companyAllAddress: companyAllAddress,
        headOffice: companyBranchText ? (companyBranchText.includes('สำนักงานใหญ่') ? true : false) : false,
        branchOffice: companyBranchText ? (companyBranchText.includes('สำนักงานใหญ่') ? false : true) : false,
        companyBranchText: companyBranchText,
        companyBranchNumber: branchNumber,
      },
    });
  };

  const handleDownload = () => {
    let all_docs = [];

    let _preAmount = 0;
    let _discount = 0;
    let _amountTotal = 0;
    let _vatTotal = 0;
    let _grandTotal = 0;

    if (selectedItems.length) {
      all_docs = arrayTo2DArray2(selectedItems, 25);

      _preAmount = selectedItems.reduce((accumulator, doc) => accumulator + (!doc.reIssue ? doc.amountTotal + doc.discount : 0), 0);
      _discount = selectedItems.reduce((accumulator, doc) => accumulator + (!doc.reIssue ? doc.discount : 0), 0);
      _amountTotal = selectedItems.reduce((accumulator, doc) => accumulator + (!doc.reIssue ? doc.amountTotal : 0), 0);
      _vatTotal = selectedItems.reduce((accumulator, doc) => accumulator + (!doc.reIssue ? doc.vatTotal : 0), 0);
      _grandTotal = selectedItems.reduce((accumulator, doc) => accumulator + (!doc.reIssue ? doc.grandTotal : 0), 0);
    } else {
      all_docs = arrayTo2DArray2(filter_docs, 25);

      _preAmount = filter_docs.reduce((accumulator, doc) => accumulator + (!doc.reIssue ? doc.amountTotal + doc.discount : 0), 0);
      _discount = filter_docs.reduce((accumulator, doc) => accumulator + (!doc.reIssue ? doc.discount : 0), 0);
      _amountTotal = filter_docs.reduce((accumulator, doc) => accumulator + (!doc.reIssue ? doc.amountTotal : 0), 0);
      _vatTotal = filter_docs.reduce((accumulator, doc) => accumulator + (!doc.reIssue ? doc.vatTotal : 0), 0);
      _grandTotal = filter_docs.reduce((accumulator, doc) => accumulator + (!doc.reIssue ? doc.grandTotal : 0), 0);
    }
    setIsDownloading(true);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .post(
            API_URL + '/reports/sell-summaries/pdf',
            {
              all_docs: all_docs,
              totalPages: all_docs.length,
              month: queryMonth.text === 'เดือนนี้' || queryMonth.text === 'This Month' ? getMonth(0).text : queryMonth.text,
              totalPreAmount: _preAmount,
              totalDiscount: _discount,
              totalVat: _vatTotal,
              totalAmount: _amountTotal,
              totalGrand: _grandTotal,
              companyName: companyName,
              companyTaxId: companyTaxId,
              companyAllAddress: companyAllAddress,
              headOffice: companyBranchText ? (companyBranchText.includes('สำนักงานใหญ่') ? true : false) : false,
              branchOffice: companyBranchText ? (companyBranchText.includes('สำนักงานใหญ่') ? false : true) : false,
              companyBranchNumber: branchNumber,
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId,
                'Content-Type': 'application/json',
              },
              responseType: 'blob',
            }
          )
          .then((response) => {
            console.log(response);

            const time = formatDateTime(new Date().toISOString());

            let fileName = time + '-Report-Sell-Summary.pdf';

            console.log('file name: ', fileName);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();

            setIsDownloading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const handleDownloadCSV = () => {
    setIsDownloading(true);
    csvLink.current.link.click();
    setIsDownloading(false);
  };

  useEffect(() => {
    console.log('userEffect initial call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId,
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data[0]) {
                if (companyId && response.data.find(_company => _company.id === companyId)) {
                  const _company = response.data.find(_company => _company.id === companyId)
                  
                  setCompany(_company);

                  setCompanyName(_company.name);
                  setCompanyTaxId(_company.taxId);
                  setCompanyBranchText(_company.branchText);
                  setBranchNumber(_company.branchNumber);
                } else {
                  setCompany(response.data[0]);

                  setCompanyName(response.data[0].name);
                  setCompanyTaxId(response.data[0].taxId);
                  setCompanyBranchText(response.data[0].branchText);
                  setBranchNumber(response.data[0].branchNumber);

                }
              } else {
                history.push({
                  pathname: '/etax/companies/profiles',
                  state: {},
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );

        axios
          .get(API_URL + '/sources/emails', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId
            },
          })
          .then(
            (response) => {
              console.log('Email sources: ', response);

              if (response.data.id) {
                if (!response.data.disabled) {
                  setIsEmailSetting(true);
                }
              } else {
                setIsEmailSetting(false);
              }
            },
            (error) => {
              console.log(error);

              setIsEmailSetting(false);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('Company state: ', company);

    if (company.taxId) {
      console.log('Company useEffect call!');
      console.log(company);
      console.log(company.id);

      setCompanyAllAddress(
        company.buildingNumber +
          ' ' +
          (!/^-*$/.test(company.address) && company.address.trim() && !/^-*$/.test(company.address.trim()) ? company.address + ' ' : '') +
          (!/^-*$/.test(company.streetName) && company.streetName.trim() && !/^-*$/.test(company.streetName.trim())
            ? company.streetPrefix + company.streetName + ' '
            : '') +
          company.subDistrictPrefix +
          company.subDistrictName +
          ' ' +
          company.districtPrefix +
          company.districtName +
          ' ' +
          company.provincePrefix +
          company.provinceName +
          ' ' +
          company.postcode
      );
    }
  }, [company]);

  useEffect(() => {
    console.log('userEffect getNewDocuments Call!');

    if (getNewDocuments) {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          // Do something with the tokenResponse
          console.log('Token response: ', tokenResponse);

          axios
            .get(API_URL + '/etax/documents/' + documentType.key + '/all?from=' + from + '&to=' + to + '&date=document', {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId,
              },
            })
            .then(
              (response) => {
                console.log('getNewDocument response: ', response);

                let filter_documents = response.data.filter(
                  (doc) =>
                    doc.type === 'RECEIPT' ||
                    doc.type === 'RECEIPT-TAXINVOICE' ||
                    doc.type === 'RECEIPT-TAXINVOICE-ABB' ||
                    doc.type === 'DEBIT-NOTE' ||
                    doc.type === 'CREDIT-NOTE'
                );

                filter_documents.forEach((doc) => {
                  if (doc.type === 'CREDIT-NOTE') {
                    doc.data.amountTotal = -Math.abs(doc.data.refer.diffAmountTotal);
                    doc.data.grandTotal = -Math.abs(doc.data.grandTotal);
                    doc.data.vatTotal = -Math.abs(doc.data.vatTotal);
                  } else if (doc.type === 'DEBIT-NOTE') {
                    doc.data.amountTotal = doc.data.refer.diffAmountTotal;
                  }

                  if (doc.data.reIssue) {
                    doc.data.amountTotal = 0;
                    doc.data.vatTotal = 0;
                    doc.data.grandTotal = 0;
                  }
                });
                console.log('filter_documents', filter_documents);

                console.log('Docs number: ', filter_documents.length);

                //Page dropdown
                let _pageDropdown = [];

                for (let i = 1; i <= Math.ceil(filter_documents.length / pageSize); i++) {
                  _pageDropdown.push({
                    key: i,
                    text: 'หน้า ' + i,
                    iconProps: { iconName: 'Page' },
                    onClick: () => {
                      console.log('Selected page: ' + i);
                      setCurrentPage(i);
                    },
                  });
                }

                setPageDropdown(_pageDropdown);

                if (currentPage > Math.ceil(filter_documents.length / pageSize)) {
                  setCurrentPage(1);
                }

                setTotalDocuments(filter_documents.length);

                //Sorting
                if (filter_documents && filter_documents.length > 0) {
                  if (primarySort === 'documentDate') {
                    if (isSortedDescendingDocumentDate) {
                      filter_documents.sort(function (a, b) {
                        // Turn your strings into dates, and then subtract them
                        // to get a value that is either negative, positive, or zero.
                        return new Date(b.data.date) - new Date(a.data.date);
                      });
                    } else {
                      filter_documents.sort(function (a, b) {
                        // Turn your strings into dates, and then subtract them
                        // to get a value that is either negative, positive, or zero.
                        return new Date(a.data.date) - new Date(b.data.date);
                      });
                    }
                  } else if (primarySort === 'number') {
                    if (isSortedDescendingNumber) {
                      filter_documents.sort((a, b) => {
                        return (
                          /[A-Za-z]/.test(b.data.number) - /[A-Za-z]/.test(a.data.number) ||
                          (b.data.number.toUpperCase() < a.data.number.toUpperCase()
                            ? -1
                            : b.data.number.toUpperCase() > a.data.number.toUpperCase()
                            ? 1
                            : 0)
                        );
                      });
                    } else {
                      filter_documents.sort((a, b) => {
                        return (
                          /[A-Za-z]/.test(a.data.number) - /[A-Za-z]/.test(b.data.number) ||
                          (a.data.number.toUpperCase() < b.data.number.toUpperCase()
                            ? -1
                            : a.data.number.toUpperCase() > b.data.number.toUpperCase()
                            ? 1
                            : 0)
                        );
                      });
                    }
                  } else if (primarySort === 'customerName') {
                    if (isSortedDescendingCustomerName) {
                      filter_documents.sort((a, b) => {
                        if (b.data.customer.name < a.data.customer.name) {
                          return -1;
                        }
                        if (b.data.customer.name > a.data.customer.name) {
                          return 1;
                        }
                        return 0;
                      });
                    } else {
                      filter_documents.sort((a, b) => {
                        if (a.data.customer.name < b.data.customer.name) {
                          return -1;
                        }
                        if (a.data.customer.name > b.data.customer.name) {
                          return 1;
                        }
                        return 0;
                      });
                    }
                  } else if (primarySort === 'taxId') {
                    if (isSortedDescendingTaxId) {
                      filter_documents.sort((a, b) => {
                        if (b.data.customer.taxId < a.data.customer.taxId) {
                          return -1;
                        }
                        if (b.data.customer.taxId > a.data.customer.taxId) {
                          return 1;
                        }
                        return 0;
                      });
                    } else {
                      filter_documents.sort((a, b) => {
                        if (a.data.customer.taxId < b.data.customer.taxId) {
                          return -1;
                        }
                        if (a.data.customer.taxId > b.data.customer.taxId) {
                          return 1;
                        }
                        return 0;
                      });
                    }
                  } else if (primarySort === 'amountTotal') {
                    if (isSortedDescendingAmountTotal) {
                      filter_documents.sort(function (a, b) {
                        return b.data.amountTotal - a.data.amountTotal;
                      });
                    } else {
                      filter_documents.sort(function (a, b) {
                        return a.data.amountTotal - b.data.amountTotal;
                      });
                    }
                  } else if (primarySort === 'vatTotal') {
                    if (isSortedDescendingVatTotal) {
                      filter_documents.sort(function (a, b) {
                        return b.data.vatTotal - a.data.vatTotal;
                      });
                    } else {
                      filter_documents.sort(function (a, b) {
                        return a.data.vatTotal - b.data.vatTotal;
                      });
                    }
                  } else if (primarySort === 'grandTotal') {
                    if (isSortedDescendingGrandTotal) {
                      filter_documents.sort(function (a, b) {
                        return b.data.grandTotal - a.data.grandTotal;
                      });
                    } else {
                      filter_documents.sort(function (a, b) {
                        return a.data.grandTotal - b.data.grandTotal;
                      });
                    }
                  } else if (primarySort === 'name') {
                    if (isSortedDescendingName) {
                      filter_documents.sort((a, b) => {
                        if (b.data.name < a.data.name) {
                          return -1;
                        }
                        if (b.data.name > a.data.name) {
                          return 1;
                        }
                        return 0;
                      });
                    } else {
                      filter_documents.sort((a, b) => {
                        if (a.data.name < b.data.name) {
                          return -1;
                        }
                        if (a.data.name > b.data.name) {
                          return 1;
                        }
                        return 0;
                      });
                    }
                  }
                }

                //set page
                console.log('Page data: ', paginate(filter_documents.length, currentPage, pageSize, 10));
                setPageData(paginate(filter_documents.length, currentPage, pageSize, 10));

                const all_docs = [];

                filter_documents.forEach((doc, index) => {
                  let _branchText = '';
                  let _preAmountotal = 0;
                  let _amountTotal = 0;
                  let _discount = 0;
                  let _vatTotal = 0;
                  let _grandTotal = 0;
                  let _taxId = '-';

                  if (doc.data.amountTotal) {
                    _amountTotal = doc.data.amountTotal;
                    _discount = doc.data.discount;
                    _preAmountotal = doc.data.amountTotal + doc.data.discount;
                  }

                  if (doc.data.vatTotal) {
                    _vatTotal = doc.data.vatTotal;
                  }

                  if (doc.data.grandTotal) {
                    _grandTotal = doc.data.grandTotal;
                  }

                  if (
                    doc.data.customer.branchText &&
                    doc.data.customer.branchText !== null &&
                    doc.data.customer.branchText !== undefined &&
                    doc.data.customer.branchText !== ''
                  ) {
                    if (
                      doc.data.customer.branchText !== 'สำนักงานใหญ่' &&
                      doc.data.customer.branchText !== '(สำนักงานใหญ่)' &&
                      doc.data.customer.branchText !== 'Head Office'
                    ) {
                      _branchText = doc.data.customer.branchNumber;
                    } else {
                      _branchText = 'สำนักงานใหญ่';
                    }
                  }

                  if (doc.data.customer.taxId && doc.data.customer.taxId.length > 10) {
                    _taxId = doc.data.customer.taxId;
                  }

                  let _reason = '';

                  if (doc.data?.refer?.reasonName) {
                    if (doc.data.refer.reasonName.includes('เหตุอื่น')) {
                      _reason = doc.data.refer.specificReason;
                    } else if (doc.data.refer.reasonName.includes('(')) {
                      let reasonName = doc.data.refer.reasonName;
                      _reason = reasonName.split('(').pop().split(')')[0];
                    } else {
                      _reason = doc.data.refer.reasonName;
                    }
                  }

                  all_docs.push({
                    row: index + 1,
                    key: doc.id,
                    createdBy: doc.createdBy,
                    documentDate: doc.data.dateBE,
                    documentDateValue: doc.data.date,
                    number: doc.data.number,
                    customerName: doc.data.customer.name || '-',
                    customerBranchText: _branchText,
                    customerBranchNumber: doc.data.customer.branchNumber,
                    taxId: _taxId,
                    preAmountTotal: _preAmountotal,
                    amountTotal: _amountTotal,
                    discount: _discount,
                    vatTotal: _vatTotal,
                    grandTotal: _grandTotal,
                    reIssue: doc.data.reIssue,
                    reason: _reason,
                    note: doc.data.note,
                    reference: doc.data.reference || ""
                  });
                });

                console.log("all_docs", all_docs)
                setFilter_docs(all_docs);

                setDocuments(filter_documents);

                let _docs = [];

                console.log('filter_documents: ', filter_documents);

                if (filter_documents.length > 0) {
                  for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, filter_documents.length); i++) {
                    let fileSize = formatBytes(filter_documents[i].file.size);

                    let _customerEmail = '';
                    let _customerPhone = '';
                    let _branchText = '-';
                    let _amountTotal = 0;
                    let _vatTotal = 0;
                    let _grandTotal = 0;
                    let _taxId = '-';

                    let _preAmountotal = 0;
                    let _discount = 0;

                    let _companyEmail = '';

                    if (
                      filter_documents[i].data.company &&
                      filter_documents[i].data.company.email &&
                      filter_documents[i].data.company.email !== null &&
                      filter_documents[i].data.company.email !== undefined
                    ) {
                      _companyEmail = filter_documents[i].data.company.email;
                    }

                    if (
                      filter_documents[i].data.customer.email &&
                      filter_documents[i].data.customer.email !== null &&
                      filter_documents[i].data.customer.email !== undefined
                    ) {
                      _customerEmail = filter_documents[i].data.customer.email;
                    }

                    if (
                      filter_documents[i].data.customer.phone &&
                      filter_documents[i].data.customer.phone !== null &&
                      filter_documents[i].data.customer.phone !== undefined
                    ) {
                      _customerPhone = '0' + filter_documents[i].data.customer.phone;
                    }

                    if (filter_documents[i].data.amountTotal) {
                      _amountTotal = filter_documents[i].data.amountTotal;
                    }

                    if (filter_documents[i].data.vatTotal) {
                      _vatTotal = filter_documents[i].data.vatTotal;
                    }

                    if (filter_documents[i].data.grandTotal) {
                      _grandTotal = filter_documents[i].data.grandTotal;
                    }

                    if (
                      filter_documents[i].data.customer.branchText &&
                      filter_documents[i].data.customer.branchText !== null &&
                      filter_documents[i].data.customer.branchText !== undefined &&
                      filter_documents[i].data.customer.branchText !== ''
                    ) {
                      if (
                        filter_documents[i].data.customer.branchText !== 'สำนักงานใหญ่' &&
                        filter_documents[i].data.customer.branchText !== '(สำนักงานใหญ่)' &&
                        filter_documents[i].data.customer.branchText !== 'Head Office'
                      ) {
                        _branchText = filter_documents[i].data.customer.branchNumber;
                      } else {
                        _branchText = 'สำนักงานใหญ่';
                      }
                    }

                    if (filter_documents[i].data.customer.branchNumber && filter_documents[i].data.customer.branchNumber.length === 5) {
                      _branchText = filter_documents[i].data.customer.branchNumber;
                    }

                    if (filter_documents[i].data.customer.taxId && filter_documents[i].data.customer.taxId.length > 10) {
                      _taxId = filter_documents[i].data.customer.taxId;
                    }

                    if (filter_documents[i].data.amountTotal) {
                      // _amountTotal = doc.data.amountTotal;
                      _discount = filter_documents[i].data.discount;
                      _preAmountotal = filter_documents[i].data.amountTotal + filter_documents[i].data.discount;
                    }

                    console.log('filter_document.customerName: ', filter_documents[i].data.customer.name);

                    _docs.push({
                      row: i + 1,
                      key: filter_documents[i].id,
                      fileName: filter_documents[i].file.displayName,
                      name: filter_documents[i].data.name,
                      value: filter_documents[i].file.name,
                      fileExtension: filter_documents[i].file.extension,
                      createdBy: filter_documents[i].createdBy,
                      createdTimeValue: filter_documents[i].createdTime,
                      documentDate: filter_documents[i].data.dateBE,
                      documentDateValue: filter_documents[i].data.date,
                      fileSize: fileSize,
                      fileSizeRaw: filter_documents[i].file.size,
                      type: filter_documents[i].type,
                      signature: filter_documents[i].signatures[0].certificateName,
                      product: filter_documents[i].product,
                      number: filter_documents[i].data.number,
                      customerName: filter_documents[i].data.customer.name || '-',
                      customerEmail: _customerEmail,
                      customerPhone: _customerPhone,
                      customerBranchText: _branchText,
                      customerBranchNumber: filter_documents[i].data.customer.branchNumber,
                      taxId: _taxId,
                      amountTotal: _amountTotal,
                      vatTotal: _vatTotal,
                      grandTotal: _grandTotal,
                      reIssue: filter_documents[i].data.reIssue,
                      reason: filter_documents[i].data?.refer?.reasonName || '',
                      note: filter_documents[i].data.note,
                      preAmountTotal: _preAmountotal,
                      discount: _discount,
                      reference: filter_documents[i].data.reference || "",
                      pdfFileName: filter_documents[i].file.displayName,
                      companyEmail: _companyEmail,
                    });
                  }

                  let _totalPreAmount = 0;
                  let _totalDiscount = 0;
                  let _totalVat = 0;
                  let _totalAmount = 0;
                  let _totalGrand = 0;

                  for (let i = 0; i < filter_documents.length; i++) {
                    _totalPreAmount = _totalPreAmount + (Number(filter_documents[i].data.amountTotal) + Number(filter_documents[i].data.discount));
                    _totalDiscount = _totalDiscount + Number(filter_documents[i].data.discount);
                    _totalVat = _totalVat + Number(filter_documents[i].data.vatTotal);
                    _totalAmount = _totalAmount + Number(filter_documents[i].data.amountTotal);
                    _totalGrand = _totalGrand + Number(filter_documents[i].data.grandTotal);
                  }

                  setTotalVat(_totalVat);
                  setTotalGrand(_totalGrand);
                }

                console.log('Docs data', _docs);
                setItems(_docs);

                setLoadDataComplete(true);
                setGetNewDocuments(false);
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments]);

  useEffect(() => {
    if (previousSearch) {
      if (filterDocuments.length > 0) {
        console.log('filterDocuments data', filterDocuments);

        const _docs = [];

        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, filterDocuments.length); i++) {
          let fileSize = formatBytes(filterDocuments[i].file.size);

          let _customerEmail = '';
          let _customerPhone = '';
          let _amountTotal = 0;
          let _vatTotal = 0;
          let _grandTotal = 0;
          let _branchText = '-';
          let _taxId = '-';

          let _preAmountotal = 0;
          let _discount = 0;

          let _companyEmail = '';

          if (
            filterDocuments[i].data.company &&
            filterDocuments[i].data.company.email &&
            filterDocuments[i].data.company.email !== null &&
            filterDocuments[i].data.company.email !== undefined
          ) {
            _companyEmail = filterDocuments[i].data.company.email;
          }

          if (
            filterDocuments[i].data.customer.email &&
            filterDocuments[i].data.customer.email !== null &&
            filterDocuments[i].data.customer.email !== undefined
          ) {
            _customerEmail = filterDocuments[i].data.customer.email;
          }

          if (
            filterDocuments[i].data.customer.phone &&
            filterDocuments[i].data.customer.phone !== null &&
            filterDocuments[i].data.customer.phone !== undefined
          ) {
            _customerPhone = '0' + filterDocuments[i].data.customer.phone;
          }

          if (filterDocuments[i].data.amountTotal) {
            _amountTotal = filterDocuments[i].data.amountTotal;
          }

          if (filterDocuments[i].data.vatTotal) {
            _vatTotal = filterDocuments[i].data.vatTotal;
          }

          if (filterDocuments[i].data.grandTotal) {
            _grandTotal = filterDocuments[i].data.grandTotal;
          }

          if (filterDocuments[i].data.customer.branchNumber && filterDocuments[i].data.customer.branchNumber.length === 5) {
            _branchText = filterDocuments[i].data.customer.branchNumber;
          }

          if (filterDocuments[i].data.customer.taxId && filterDocuments[i].data.customer.taxId.length > 10) {
            _taxId = filterDocuments[i].data.customer.taxId;
          }

          if (filterDocuments[i].data.amountTotal) {
            // _amountTotal = doc.data.amountTotal;
            _discount = filterDocuments[i].data.discount;
            _preAmountotal = filterDocuments[i].data.amountTotal + filterDocuments[i].data.discount;
          }

          _docs.push({
            row: i + 1,
            key: filterDocuments[i].id,
            fileName: filterDocuments[i].file.displayName,
            name: filterDocuments[i].data.name,
            value: filterDocuments[i].file.name,
            fileExtension: filterDocuments[i].file.extension,
            createdBy: filterDocuments[i].createdBy,
            createdTimeValue: filterDocuments[i].createdTime,
            documentDate: filterDocuments[i].data.dateBE,
            documentDateValue: filterDocuments[i].data.date,
            fileSize: fileSize,
            fileSizeRaw: filterDocuments[i].file.size,
            type: filterDocuments[i].type,
            signature: filterDocuments[i].signatures[0].certificateName,
            product: filterDocuments[i].product,
            number: filterDocuments[i].data.number,
            customerName: filterDocuments[i].data.customer.name || '-',
            customerEmail: _customerEmail,
            customerPhone: _customerPhone,
            customerBranchText: _branchText,
            customerBranchNumber: filterDocuments[i].data.customer.branchNumber,
            taxId: _taxId,
            amountTotal: _amountTotal,
            vatTotal: _vatTotal,
            grandTotal: _grandTotal,
            reIssue: filterDocuments[i].data.reIssue,
            reason: filterDocuments[i].data?.refer?.reasonName || '',
            note: filterDocuments[i].data.note,
            preAmountTotal: _preAmountotal,
            discount: _discount,
            reference: filterDocuments[i].data.reference || "",
            pdfFileName: filterDocuments[i].file.displayName,
            companyEmail: _companyEmail,
          });
        }
        console.log('Docs data', _docs);

        setItems(_docs);
      }
    } else {
      if (documents.length > 0) {
        console.log('documents data', documents);

        const _docs = [];

        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
          let fileSize = formatBytes(documents[i].file.size);

          let _customerEmail = '';
          let _customerPhone = '';
          let _amountTotal = 0;
          let _vatTotal = 0;
          let _grandTotal = 0;
          let _branchText = '-';
          let _taxId = '-';

          let _preAmountotal = 0;
          let _discount = 0;

          let _companyEmail = '';

          if (
            documents[i].data.company &&
            documents[i].data.company.email &&
            documents[i].data.company.email !== null &&
            documents[i].data.company.email !== undefined
          ) {
            _companyEmail = documents[i].data.company.email;
          }

          if (documents[i].data.customer.email && documents[i].data.customer.email !== null && documents[i].data.customer.email !== undefined) {
            _customerEmail = documents[i].data.customer.email;
          }

          if (documents[i].data.customer.phone && documents[i].data.customer.phone !== null && documents[i].data.customer.phone !== undefined) {
            _customerPhone = '0' + documents[i].data.customer.phone;
          }

          if (documents[i].data.amountTotal) {
            _amountTotal = documents[i].data.amountTotal;
          }

          if (documents[i].data.vatTotal) {
            _vatTotal = documents[i].data.vatTotal;
          }

          if (documents[i].data.grandTotal) {
            _grandTotal = documents[i].data.grandTotal;
          }

          if (documents[i].data.customer.branchNumber && documents[i].data.customer.branchNumber.length === 5) {
            _branchText = documents[i].data.customer.branchNumber;
          }

          if (documents[i].data.customer.taxId && documents[i].data.customer.taxId.length > 10) {
            _taxId = documents[i].data.customer.taxId;
          }

          if (documents[i].data.amountTotal) {
            // _amountTotal = doc.data.amountTotal;
            _discount = documents[i].data.discount;
            _preAmountotal = documents[i].data.amountTotal + documents[i].data.discount;
          }

          _docs.push({
            row: i + 1,
            key: documents[i].id,
            fileName: documents[i].file.displayName,
            name: documents[i].data.name,
            value: documents[i].file.name,
            fileExtension: documents[i].file.extension,
            createdBy: documents[i].createdBy,
            createdTimeValue: documents[i].createdTime,
            documentDate: documents[i].data.dateBE,
            documentDateValue: documents[i].data.date,
            fileSize: fileSize,
            fileSizeRaw: documents[i].file.size,
            type: documents[i].type,
            signature: documents[i].signatures[0].certificateName,
            product: documents[i].product,
            number: documents[i].data.number,
            customerName: documents[i].data.customer.name || '-',
            customerEmail: _customerEmail,
            customerPhone: _customerPhone,
            customerBranchText: _branchText,
            customerBranchNumber: documents[i].data.customer.branchNumber,
            taxId: _taxId,
            amountTotal: _amountTotal,
            vatTotal: _vatTotal,
            grandTotal: _grandTotal,
            reIssue: documents[i].data.reIssue,
            reason: documents[i].data?.refer?.reasonName || '',
            note: documents[i].data.note,
            preAmountTotal: _preAmountotal,
            discount: _discount,
            reference: documents[i].data.reference || "",
            pdfFileName: documents[i].file.displayName,
            companyEmail: _companyEmail,
          });
        }
        console.log('Docs data', _docs);

        setItems(_docs);
      }

      sessionStorage.setItem('sellSummary-currentPage', currentPage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleSearch = async () => {
    let filter_documents = documents.filter(
      (doc) =>
        doc.data.number.includes(search) ||
        (doc.data.reference && doc.data.reference.includes(search)) ||
        (doc.data.customer.name && doc.data.customer.name.includes(search)) ||
        (doc.data.customer.taxId && doc.data.customer.taxId.includes(search)) ||
        String(doc.data.amountTotal).includes(search) ||
        String(doc.data.vatTotal).includes(search) ||
        String(doc.data.grandTotal).includes(search) ||
        (doc.type === 'CREDIT-NOTE' && String(-Math.abs(doc.data.refer.diffAmountTotal)).includes(search)) ||
        (doc.type === 'DEBIT-NOTE' && String(doc.data.refer.diffAmountTotal).includes(search))
    );

    setPageData(paginate(filter_documents.length, 1, pageSize, 10));
    setFilterDocuments(filter_documents);
    setCurrentPage(1);

    const _docs = [];

    if (filter_documents.length > 0) {
      for (let i = (1 - 1) * pageSize; i < Math.min(1 * pageSize, filter_documents.length); i++) {
        let fileSize = formatBytes(filter_documents[i].file.size);

        let _customerEmail = '';
        let _customerPhone = '';
        let _branchText = '-';
        let _amountTotal = 0;
        let _vatTotal = 0;
        let _grandTotal = 0;
        let _taxId = '-';

        let _preAmountotal = 0;
        let _discount = 0;

        let _companyEmail = '';

        if (
          filter_documents[i].data.company &&
          filter_documents[i].data.company.email &&
          filter_documents[i].data.company.email !== null &&
          filter_documents[i].data.company.email !== undefined
        ) {
          _companyEmail = filter_documents[i].data.company.email;
        }

        if (
          filter_documents[i].data.customer.email &&
          filter_documents[i].data.customer.email !== null &&
          filter_documents[i].data.customer.email !== undefined
        ) {
          _customerEmail = filter_documents[i].data.customer.email;
        }

        if (
          filter_documents[i].data.customer.phone &&
          filter_documents[i].data.customer.phone !== null &&
          filter_documents[i].data.customer.phone !== undefined
        ) {
          _customerPhone = '0' + filter_documents[i].data.customer.phone;
        }

        if (filter_documents[i].data.amountTotal) {
          _amountTotal = filter_documents[i].data.amountTotal;
        }

        if (filter_documents[i].data.vatTotal) {
          _vatTotal = filter_documents[i].data.vatTotal;
        }

        if (filter_documents[i].data.grandTotal) {
          _grandTotal = filter_documents[i].data.grandTotal;
        }

        if (
          filter_documents[i].data.customer.branchText &&
          filter_documents[i].data.customer.branchText !== null &&
          filter_documents[i].data.customer.branchText !== undefined &&
          filter_documents[i].data.customer.branchText !== ''
        ) {
          if (
            filter_documents[i].data.customer.branchText !== 'สำนักงานใหญ่' &&
            filter_documents[i].data.customer.branchText !== '(สำนักงานใหญ่)' &&
            filter_documents[i].data.customer.branchText !== 'Head Office'
          ) {
            _branchText = filter_documents[i].data.customer.branchNumber;
          } else {
            _branchText = 'สำนักงานใหญ่';
          }
        }

        if (filter_documents[i].data.customer.branchNumber && filter_documents[i].data.customer.branchNumber.length === 5) {
          _branchText = filter_documents[i].data.customer.branchNumber;
        }

        if (filter_documents[i].data.customer.taxId && filter_documents[i].data.customer.taxId.length > 10) {
          _taxId = filter_documents[i].data.customer.taxId;
        }

        if (filter_documents[i].data.amountTotal) {
          // _amountTotal = doc.data.amountTotal;
          _discount = filter_documents[i].data.discount;
          _preAmountotal = filter_documents[i].data.amountTotal + filter_documents[i].data.discount;
        }

        _docs.push({
          row: i + 1,
          key: filter_documents[i].id,
          fileName: filter_documents[i].fileName + '.' + filter_documents[i].file.extension,
          name: filter_documents[i].data.name,
          value: filter_documents[i].file.name,
          fileExtension: filter_documents[i].file.extension,
          createdTimeValue: filter_documents[i].createdTime,
          documentDate: filter_documents[i].data.dateBE,
          documentDateValue: filter_documents[i].data.date,
          fileSize: fileSize,
          fileSizeRaw: filter_documents[i].file.size,
          type: filter_documents[i].type,
          signature: filter_documents[i].signatures[0].certificateName,
          product: filter_documents[i].product,
          number: filter_documents[i].data.number,
          customerName: filter_documents[i].data.customer.name || '-',
          customerEmail: _customerEmail,
          customerPhone: _customerPhone,
          customerBranchText: _branchText,
          customerBranchNumber: filter_documents[i].data.customer.branchNumber,
          taxId: _taxId,
          amountTotal: _amountTotal,
          vatTotal: _vatTotal,
          grandTotal: _grandTotal,
          reIssue: filter_documents[i].data.reIssue,
          reason: filter_documents[i].data.refer?.reasonName || "",
          note: filter_documents[i].data.note,
          preAmountTotal: _preAmountotal,
          discount: _discount,
          reference: filter_documents[i].data.reference || "",
          pdfFileName: filter_documents[i].file.displayName,
          companyEmail: _companyEmail,
        });
      }

      console.log('Docs data', _docs);
      setItems(_docs);
    } else {
      setItems([]);
    }

    let _totalVat = 0;
    let _totalGrand = 0;

    for (let i = 0; i < filter_documents.length; i++) {
      _totalVat = _totalVat + Number(filter_documents[i].data.vatTotal);
      _totalGrand = _totalGrand + Number(filter_documents[i].data.grandTotal);
    }

    setTotalDocuments(filter_documents.length);
    setTotalVat(_totalVat);
    setTotalGrand(_totalGrand);
  };

  const download = (item) => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/files/' + item.key, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId,
            },
            responseType: 'blob',
          })
          .then(
            (response) => {
              console.log(response.headers);

              const time = formatDateTime(item.createdTimeValue);

              let fileName = time + '-' + item.type + '-' + item.number.replace(/\//g, '') + '.' + item.fileExtension;

              console.log('file name: ', fileName);

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName); //or any other extension
              document.body.appendChild(link);
              link.click();
              link.remove();
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const openFile = (item) => {
    history.push({
      pathname: '/etax/documents/pdf/view',
      state: {
        item: item,
        backUrl: '/reports/output-taxs',
      },
    });
  };

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  const columns = [
    {
      key: 'column1',
      name: t('sellSummary.documentDate'),
      fieldName: 'documentDate',
      minWidth: 80,
      maxWidth: 80,
      isResizable: true,
      isSorted: primarySort === 'documentDate' ? true : false,
      isSortedDescending: isSortedDescendingDocumentDate,
      isSortedAscending: !isSortedDescendingDocumentDate,
      onColumnClick: () => {
        console.log('Sort document date!');
        setPrimarySort('documentDate');
        setIsSortedDescendingDocumentDate(!isSortedDescendingDocumentDate);

        sessionStorage.setItem('sellSummary-primarySort', 'documentDate');
        sessionStorage.setItem('sellSummary-sort-documentDate', !isSortedDescendingDocumentDate === true ? 'true' : 'false');

        sessionStorage.setItem('sellSummary-sort-number', 'false');
        sessionStorage.setItem('sellSummary-sort-customerName', 'false');
        sessionStorage.setItem('sellSummary-sort-taxId', 'false');
        sessionStorage.setItem('sellSummary-sort-amountTotal', 'false');
        sessionStorage.setItem('sellSummary-sort-vatTotal', 'false');
        sessionStorage.setItem('sellSummary-sort-grandTotal', 'false');
        sessionStorage.setItem('sellSummary-sort-name', 'false');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setPreviousSearch('');
        setSearch('');

        setTotalDocuments(0);
        setTotalGrand(0);
        setTotalVat(0);
      },
      data: 'number',
      onRender: (item) => {
        return <span>{item.documentDate}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column2',
      name: t('sellSummary.number'),
      fieldName: 'number',
      minWidth: 80,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'number' ? true : false,
      isSortedDescending: isSortedDescendingNumber,
      isSortedAscending: !isSortedDescendingNumber,
      onColumnClick: () => {
        console.log('Sort document number!');
        setPrimarySort('number');
        setIsSortedDescendingNumber(!isSortedDescendingNumber);

        sessionStorage.setItem('sellSummary-primarySort', 'number');
        sessionStorage.setItem('sellSummary-sort-number', !isSortedDescendingNumber === true ? 'true' : 'false');

        sessionStorage.setItem('sellSummary-sort-documentDate', 'false');
        sessionStorage.setItem('sellSummary-sort-customerName', 'false');
        sessionStorage.setItem('sellSummary-sort-taxId', 'false');
        sessionStorage.setItem('sellSummary-sort-amountTotal', 'false');
        sessionStorage.setItem('sellSummary-sort-vatTotal', 'false');
        sessionStorage.setItem('sellSummary-sort-grandTotal', 'false');
        sessionStorage.setItem('sellSummary-sort-name', 'false');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setPreviousSearch('');
        setSearch('');

        setTotalDocuments(0);
        setTotalGrand(0);
        setTotalVat(0);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <ActionButton
            title={t('sellSummary.download')}
            className={classNames.fileIconImg}
            onClick={() => {
              openFile(item);
            }}
          >
            {item.number}
          </ActionButton>
        );
      },
      isPadded: true,
    },
    {
      key: 'column3',
      name: t('sellSummary.customerName'),
      fieldName: 'customerName',
      minWidth: 100,
      maxWidth: 220,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'customerName' ? true : false,
      isSortedDescending: isSortedDescendingCustomerName,
      isSortedAscending: !isSortedDescendingCustomerName,
      onColumnClick: () => {
        console.log('Sort customerName!');
        setPrimarySort('customerName');
        setIsSortedDescendingCustomerName(!isSortedDescendingCustomerName);

        sessionStorage.setItem('sellSummary-primarySort', 'customerName');
        sessionStorage.setItem('sellSummary-sort-customerName', !isSortedDescendingCustomerName === true ? 'true' : 'false');

        sessionStorage.setItem('sellSummary-sort-documentDate', 'false');
        sessionStorage.setItem('sellSummary-sort-number', 'false');
        sessionStorage.setItem('sellSummary-sort-taxId', 'false');
        sessionStorage.setItem('sellSummary-sort-amountTotal', 'false');
        sessionStorage.setItem('sellSummary-sort-vatTotal', 'false');
        sessionStorage.setItem('sellSummary-sort-grandTotal', 'false');
        sessionStorage.setItem('sellSummary-sort-name', 'false');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setPreviousSearch('');
        setSearch('');

        setTotalDocuments(0);
        setTotalGrand(0);
        setTotalVat(0);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerName}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column4',
      name: t('sellSummary.reference'),
      fieldName: 'reference',
      minWidth: 130,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        return (<TooltipHost content={item.reference}>{item.reference}</TooltipHost>);
      },
      // isPadded: true,
    },
    // {
    //   key: 'column4',
    //   name: t('sellSummary.taxId'),
    //   fieldName: 'taxId',
    //   minWidth: 110,
    //   maxWidth: 120,
    //   isRowHeader: true,
    //   isResizable: true,
    //   isSorted: primarySort === 'taxId' ? true : false,
    //   isSortedDescending: isSortedDescendingTaxId,
    //   isSortedAscending: !isSortedDescendingTaxId,
    //   onColumnClick: () => {
    //     console.log('Sort taxId!');
    //     setPrimarySort('taxId');
    //     setIsSortedDescendingTaxId(!isSortedDescendingTaxId);

    //     sessionStorage.setItem('sellSummary-primarySort', 'taxId');
    //     sessionStorage.setItem('sellSummary-sort-taxId', !isSortedDescendingTaxId === true ? 'true' : 'false');

    //     sessionStorage.setItem('sellSummary-sort-documentDate', 'false');
    //     sessionStorage.setItem('sellSummary-sort-number', 'false');
    //     sessionStorage.setItem('sellSummary-sort-customerName', 'false');
    //     sessionStorage.setItem('sellSummary-sort-amountTotal', 'false');
    //     sessionStorage.setItem('sellSummary-sort-vatTotal', 'false');
    //     sessionStorage.setItem('sellSummary-sort-grandTotal', 'false');
    //     sessionStorage.setItem('sellSummary-sort-name', 'false');

    //     setLoadDataComplete(false);
    //     setDocuments([]);
    //     setItems([]);
    //     setGetNewDocuments(true);
    //     setPreviousSearch('');
    //     setSearch('');

    //     setTotalDocuments(0);
    //     setTotalGrand(0);
    //     setTotalVat(0);
    //   },
    //   data: 'string',
    //   onRender: (item) => {
    //     return <span>{item.taxId}</span>;
    //   },
    //   isPadded: true,
    // },
    // {
    //   key: 'column5',
    //   name: t('sellSummary.branchNumber'),
    //   fieldName: 'branchNumber',
    //   minWidth: 40,
    //   maxWidth: 90,
    //   isResizable: true,
    //   onColumnClick: '',
    //   data: 'string',
    //   onRender: (item) => {
    //     return <span>{item.customerBranchText}</span>;
    //   },
    //   isPadded: true,
    // },
    // {
    //   key: 'column6',
    //   name: t('sellSummary.amountTotal'),
    //   fieldName: 'amountTotal',
    //   minWidth: 70,
    //   maxWidth: 100,
    //   isRowHeader: true,
    //   isResizable: true,
    //   isSorted: primarySort === 'amountTotal' ? true : false,
    //   isSortedDescending: isSortedDescendingAmountTotal,
    //   isSortedAscending: !isSortedDescendingAmountTotal,
    //   onColumnClick: () => {
    //     console.log('Sort amountTotal!');
    //     setPrimarySort('amountTotal');
    //     setIsSortedDescendingAmountTotal(!isSortedDescendingAmountTotal);

    //     sessionStorage.setItem('sellSummary-primarySort', 'amountTotal');
    //     sessionStorage.setItem('sellSummary-sort-amountTotal', !isSortedDescendingAmountTotal === true ? 'true' : 'false');

    //     sessionStorage.setItem('sellSummary-sort-documentDate', 'false');
    //     sessionStorage.setItem('sellSummary-sort-number', 'false');
    //     sessionStorage.setItem('sellSummary-sort-customerName', 'false');
    //     sessionStorage.setItem('sellSummary-sort-taxId', 'false');
    //     sessionStorage.setItem('sellSummary-sort-vatTotal', 'false');
    //     sessionStorage.setItem('sellSummary-sort-grandTotal', 'false');
    //     sessionStorage.setItem('sellSummary-sort-name', 'false');

    //     setLoadDataComplete(false);
    //     setDocuments([]);
    //     setItems([]);
    //     setGetNewDocuments(true);
    //     setPreviousSearch('');
    //     setSearch('');

    //     setTotalDocuments(0);
    //     setTotalGrand(0);
    //     setTotalVat(0);
    //   },
    //   data: 'string',
    //   onRender: (item) => {
    //     return (
    //       <span style={{ display: 'block', textAlign: 'right' }}>
    //         {!item.reIssue
    //           ? item.amountTotal.toLocaleString(undefined, {
    //               maximumFractionDigits: 2,
    //               minimumFractionDigits: 2,
    //             })
    //           : ''}
    //       </span>
    //     );
    //   },
    //   isPadded: true,
    // },
    {
      key: 'column6',
      name: t('sellSummary.grandTotal'),
      fieldName: 'grandTotal',
      minWidth: 70,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'grandTotal' ? true : false,
      isSortedDescending: isSortedDescendingGrandTotal,
      isSortedAscending: !isSortedDescendingGrandTotal,
      onColumnClick: () => {
        console.log('Sort grandTotal!');
        setPrimarySort('grandTotal');
        setIsSortedDescendingGrandTotal(!isSortedDescendingGrandTotal);

        sessionStorage.setItem('sellSummary-primarySort', 'grandTotal');
        sessionStorage.setItem('sellSummary-sort-grandTotal', !isSortedDescendingGrandTotal === true ? 'true' : 'false');
        
        sessionStorage.setItem('sellSummary-sort-documentDate', 'false');
        sessionStorage.setItem('sellSummary-sort-number', 'false');
        sessionStorage.setItem('sellSummary-sort-customerName', 'false');
        sessionStorage.setItem('sellSummary-sort-taxId', 'false');
        sessionStorage.setItem('sellSummary-sort-amountTotal', 'false');
        sessionStorage.setItem('sellSummary-sort-vatTotal', 'false');
        sessionStorage.setItem('sellSummary-sort-name', 'false');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setPreviousSearch('');
        setSearch('');

        setTotalDocuments(0);
        setTotalGrand(0);
        setTotalVat(0);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {!item.reIssue
              ? item.grandTotal.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              : ''}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column7',
      name: t('sellSummary.vatTotal'),
      fieldName: 'vatTotal',
      minWidth: 60,
      maxWidth: 60,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'vatTotal' ? true : false,
      isSortedDescending: isSortedDescendingVatTotal,
      isSortedAscending: !isSortedDescendingVatTotal,
      onColumnClick: () => {
        console.log('Sort vatTotal!');
        setPrimarySort('vatTotal');
        setIsSortedDescendingVatTotal(!isSortedDescendingVatTotal);

        sessionStorage.setItem('sellSummary-primarySort', 'vatTotal');
        sessionStorage.setItem('sellSummary-sort-vatTotal', !isSortedDescendingVatTotal === true ? 'true' : 'false');

        sessionStorage.setItem('sellSummary-sort-documentDate', 'false');
        sessionStorage.setItem('sellSummary-sort-number', 'false');
        sessionStorage.setItem('sellSummary-sort-customerName', 'false');
        sessionStorage.setItem('sellSummary-sort-taxId', 'false');
        sessionStorage.setItem('sellSummary-sort-amountTotal', 'false');
        sessionStorage.setItem('sellSummary-sort-grandTotal', 'false');
        sessionStorage.setItem('sellSummary-sort-name', 'false');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setPreviousSearch('');
        setSearch('');

        setTotalDocuments(0);
        setTotalGrand(0);
        setTotalVat(0);
      },
      data: 'number',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {!item.reIssue
              ? item.vatTotal.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              : ''}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column8',
      name: t('sellSummary.document'),
      fieldName: 'name',
      minWidth: 150,
      maxWidth: 180,
      isRowHeader: true,
      isResizable: true,
      isCollapsible: true,
      isSorted: primarySort === 'name' ? true : false,
      isSortedDescending: isSortedDescendingName,
      isSortedAscending: !isSortedDescendingName,
      onColumnClick: () => {
        console.log('Sort name!');
        setPrimarySort('name');
        setIsSortedDescendingName(!isSortedDescendingName);

        sessionStorage.setItem('sellSummary-primarySort', 'name');
        sessionStorage.setItem('sellSummary-sort-name', !isSortedDescendingName === true ? 'true' : 'false');

        sessionStorage.setItem('sellSummary-sort-documentDate', 'false');
        sessionStorage.setItem('sellSummary-sort-number', 'false');
        sessionStorage.setItem('sellSummary-sort-customerName', 'false');
        sessionStorage.setItem('sellSummary-sort-taxId', 'false');
        sessionStorage.setItem('sellSummary-sort-amountTotal', 'false');
        sessionStorage.setItem('sellSummary-sort-vatTotal', 'false');
        sessionStorage.setItem('sellSummary-sort-grandTotal', 'false');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setPreviousSearch('');
        setSearch('');

        setTotalDocuments(0);
        setTotalGrand(0);
        setTotalVat(0);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <ActionButton
            title={t('sellSummary.openFile')}
            className={classNames.fileIconImg}
            onClick={() => {
              openFile(item);
            }}
          >
            {item.name}
          </ActionButton>
        );
      },
      isPadded: true,
    },
    {
      key: 'column9',
      name: '',
      fieldName: 'download',
      minWidth: 90,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        return (
          <DefaultButton
            text={t('sellSummary.select')}
            menuProps={{
              items: [
                {
                  key: 'open',
                  text: t('sellSummary.openDocument'),
                  iconProps: { iconName: 'OpenFile' },
                  onClick: () => {
                    openFile(item);
                  },
                },
                {
                  key: 'download',
                  text: t('sellSummary.download'),
                  iconProps: { iconName: 'DownloadDocument' },
                  onClick: () => {
                    download(item);
                  },
                },
                {
                  key: 'divider_1',
                  itemType: ContextualMenuItemType.Divider,
                },
                {
                  key: 'email',
                  text: t('sellSummary.email'),
                  iconProps: { iconName: 'MailForward' },
                  onClick: (event) => {
                    if (isEmailSetting) {
                      setIsClickEmail(true);
                      setFileNameEmail(item.name);
                      setFileDisplayName(item.pdfFileName);
                      setCustomerEmail(item.customerEmail);
                      setEmailList(item.customerEmail ? [item.customerEmail] : []);
                      setCopyEmailList(item.companyEmail ? [item.companyEmail] : [])
                      setCopyEmail(item.companyEmail);
                      setFileIdEmail(item.key);
                    } else {
                      history.push({
                        pathname: '/settings/emails',
                        state: {},
                      });
                    }
                  },
                },
                {
                  key: 'sms',
                  text: 'SMS',
                  iconProps: { iconName: 'Message' },
                  onClick: (event) => {
                    setIsClickSms(true);
                    setFileNameSms(item.name);
                    setCustomerPhone(item.customerPhone);
                    setFileIdSms(item.key);
                  },
                },
              ],
              shouldFocusOnMount: true,
              directionalHintFixed: true,
            }}
            // Optional callback to customize menu rendering
            menuAs={(props) => <ContextualMenu {...props} />}
          />
        );
      },
    },
  ];

  const isCompactMode = false;

  return (
    <div style={homeStyles}>
      {showEmailSuccess && (
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={false}
          onDismiss={() => setShowEmailSuccess(false)}
          dismissButtonAriaLabel={t('documents.close')}
          style={{
            fontSize: 14,
          }}
        >
          {t('documents.sendEmailSuccess')}
        </MessageBar>
      )}

      {showEmailError && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowEmailError(false)}
          dismissButtonAriaLabel={t('documents.close')}
          style={{
            fontSize: 14,
          }}
        >
          {t('documents.sendEmailFail')}
        </MessageBar>
      )}

      <Stack>
        <center>
          <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('sellSummary.header')}</h2>
        </center>
        <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}>
          <DocumentCard aria-label={t('sellSummary.totalDocuments')} styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
            <DocumentCardLogo {...{ logoIcon: 'Documentation' }} title={t('sellSummary.totalDocuments')} />
            <DocumentCardDetails>
              <DocumentCardTitle title={t('sellSummary.totalDocuments')} styles={cardStyles.header} />
              <Text styles={cardStyles.amount}>
                <CountUp end={totalDocuments} separator="," duration={1} /> {t('sellSummary.documentUnit')}
              </Text>
            </DocumentCardDetails>
          </DocumentCard>

          <DocumentCard aria-label={t('sellSummary.saleVat')} styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
            <DocumentCardLogo {...{ logoIcon: 'Bank' }} title={t('sellSummary.saleVat')} />
            <DocumentCardDetails>
              <DocumentCardTitle title={t('sellSummary.saleVat')} styles={cardStyles.header} />
              <Text styles={cardStyles.amount}>
                <CountUp end={totalVat} separator="," decimals={2} decimal="." duration={1} /> {t('sellSummary.thb')}
              </Text>
            </DocumentCardDetails>
          </DocumentCard>

          <DocumentCard aria-label={t('sellSummary.saleTotalWithVat')} styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
            <DocumentCardLogo {...{ logoIcon: 'Money' }} title={t('sellSummary.saleTotalWithVat')} />
            <DocumentCardDetails>
              <DocumentCardTitle title={t('sellSummary.saleTotalWithVat')} styles={cardStyles.header} />
              <Text styles={cardStyles.amount}>
                <CountUp end={totalGrand} separator="," decimals={2} decimal="." duration={1} /> {t('sellSummary.thb')}
              </Text>
            </DocumentCardDetails>
          </DocumentCard>
        </Stack>
      </Stack>

      {totalProcessingDocuments > 0 && (
        <div>
          <br />
          <Spinner label={t('sellSummary.signProcessing')} size={SpinnerSize.large} />
        </div>
      )}

      {isDownloading && (
        <div>
          <br />
          <Spinner label={t('sellSummary.loading')} labelPosition="right" />
        </div>
      )}
      <br />
      <Stack horizontal horizontalAlign="center">
        <Stack
          vertical
          style={{
            width: '98%',
            maxWidth: '1350px',
          }}
        >
          <Stack 
            horizontal 
            styles={{
              root: {
                backgroundColor: 'white',
              },
            }} 
            horizontalAlign="space-between"
          >
            <CommandBar
              items={commandBarItems}
              styles={{
                root: {
                  width: 750,
                },
              }}
            />
            <Stack
              horizontal
              styles={{
                root: {
                  height: 44,
                },
              }}
              tokens={{ childrenGap: '10px' }}
            >
              <SearchBox
                className="ms-borderColor-themePrimary"
                styles={{
                  root: {
                    marginTop: 6,
                    marginLeft: 6,
                    width: 200,
                    fontSize: 13,
                    fontWeight: 0,
                  },
                }}
                disabled={!loadDataComplete}
                placeholder={t('sellSummary.searchPlaceholder')}
                onChange={(e) => setSearch(e ? e.target.value.replace(/\s/g, '') : '')}
                onClear={() => {
                  setLoadDataComplete(false);
                  setItems([]);
                  setGetNewDocuments(true);

                  setPreviousSearch('');
                  setCurrentPage(1);

                  setTotalDocuments(0);
                  setTotalGrand(0);
                  setTotalVat(0);
                }}
                onBlur={(e) => {
                  if (!e.target.value && previousSearch) {
                    setLoadDataComplete(false);
                    setItems([]);
                    setGetNewDocuments(true);
                    setPreviousSearch('');

                    setCurrentPage(1);

                    setTotalDocuments(0);
                    setTotalGrand(0);
                    setTotalVat(0);
                  }
                }}
                onSearch={() => {
                  setPreviousSearch(search);
                  handleSearch();
                }}
                value={search}
              />
              <PrimaryButton
                disabled={!loadDataComplete || !search}
                styles={{
                  root: {
                    marginTop: 6,
                    marginRight: 8,
                    width: 90,
                  },
                }}
                text={t('sellSummary.search')}
                onClick={() => {
                  setPreviousSearch(search);
                  handleSearch();
                }}
              />
            </Stack>
          </Stack>
          {items.length ? (
            <ShimmeredDetailsList
              items={items || []}
              enableShimmer={!loadDataComplete}
              compact={isCompactMode}
              columns={columns}
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              selection={selection}
              selectionMode={!loadDataComplete ? SelectionMode.none : SelectionMode.multiple}
              checkboxVisibility={CheckboxVisibility.always}
              selectionPreservedOnEmptyClick={true}
            />
          ) : (
            <ShimmeredDetailsList
              items={[{}]}
              enableShimmer={!loadDataComplete}
              compact={isCompactMode}
              columns={columns}
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              selection={selection}
              selectionMode={!loadDataComplete ? SelectionMode.none : SelectionMode.multiple}
              checkboxVisibility={CheckboxVisibility.always}
              selectionPreservedOnEmptyClick={true}
              onRenderRow={() => {
                return (
                  <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { backgroundColor: '#FFFFFF', height: 55 } }}>
                    <Text>{t('documents.noItemFound')}</Text>
                  </Stack>
                );
              }}
            />
          )}
        </Stack>
      </Stack>
      <br />
      <center>
        {!(documents.length === 0 || documents.length < pageSize) && loadDataComplete && (
          <Pagination
            selectedPageIndex={currentPage - 1}
            pageCount={pageData.totalPages}
            onPageChange={onPageChange}
            format
            firstPageIconProps={{
              iconName: 'DoubleChevronLeft',
            }}
            previousPageIconProps={{ iconName: 'ChevronLeft' }}
            nextPageIconProps={{ iconName: 'ChevronRight' }}
            lastPageIconProps={{
              iconName: 'DoubleChevronRight',
            }}
          />
        )}
        <br />
        <br />
      </center>

      <DialogEmail 
        isClickEmail={isClickEmail}
        setIsClickEmail={setIsClickEmail}
        fileNameEmail={fileNameEmail}
        emailList={emailList}
        setEmailList={setEmailList}
        customerEmail={customerEmail}
        setCustomerEmail={setCustomerEmail}
        isClickCopyEmail={isClickCopyEmail}
        setIsClickCopyEmail={setIsClickCopyEmail}
        copyEmailList={copyEmailList}
        setCopyEmailList={setCopyEmailList}
        copyEmail={copyEmail}
        setCopyEmail={setCopyEmail}
        fileDisplayName={fileDisplayName}
        fileIdEmail={fileIdEmail}
        sendEmail={sendEmail}
      />

      <Dialog
        hidden={!isClickSms}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: t('sellSummary.sendFileBySms'),
          subText: fileNameSms,
        }}
      >
        <TextField
          label={t('sellSummary.mobileNumer')}
          value={customerPhone}
          required
          onChange={(e) => {
            setCustomerPhone(e.target.value);
            setErrorMessageSms('');
          }}
          errorMessage={errorMessageSms}
          description={t('sellSummary.exampleNumber')}
        />
        <br />
        <br />
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              sendSms(fileIdSms, customerPhone);
            }}
            text={t('sellSummary.sendSms')}
          />
          <DefaultButton onClick={() => setIsClickSms(false)} text={t('sellSummary.cancel')} />
        </DialogFooter>
      </Dialog>

      {html !== '' ? <div id="printableArea" style={{ display: 'none' }} dangerouslySetInnerHTML={{ __html: html }} /> : null}

      <CSVLink style={{ display: 'none' }} ref={csvLink} filename={fileName} data={getCSVData()} headers={headerCSV}>
        Download csv
      </CSVLink>
    </div>
  );
};

export default SellSummary;
