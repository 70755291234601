export const documentTypes = [
  {
    typeCode: '388',
    typeNameTh: 'ใบกำกับภาษี',
    typeNameEn: 'TAXINVOICE',
  },
  {
    typeCode: 'T02',
    typeNameTh: 'ใบแจ้งหนี้/ใบกำกับภาษี',
    typeNameEn: 'INVOICE-TAXINVOICE',
  },
  {
    typeCode: 'T03',
    typeNameTh: 'ใบเสร็จรับเงิน/ใบกำกับภาษี',
    typeNameEn: 'RECEIPT-TAXINVOICE',
  },
  {
    typeCode: 'T04',
    typeNameTh: 'ใบส่งของ/ใบกำกับภาษี',
    typeNameEn: 'DELIVERYORDER-TAXINVOICE',
  },
  {
    typeCode: 'T05',
    typeNameTh: 'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ',
    typeNameEn: 'RECEIPT-TAXINVOICE-ABB',
  },
];

export const reasonsTaxinvoice = [
  {
    reasonCode: 'TIVC01',
    reasonNameTh: 'ชื่อผิด',
    reasonNameEn: 'Invalid name',
  },
  {
    reasonCode: 'TIVC02',
    reasonNameTh: 'ที่อยู่ผิด',
    reasonNameEn: 'Invalid address',
  },
  {
    reasonCode: 'TIVC99',
    reasonNameTh: 'เหตุอื่น (ระบุสาเหตุ)',
    reasonNameEn: 'Other reason',
  },
];

export const reasonsReceipt = [
  {
    reasonCode: 'RCTC01',
    reasonNameTh: 'ชื่อผิด',
    reasonNameEn: 'Invalid name',
  },
  {
    reasonCode: 'RCTC02',
    reasonNameTh: 'ที่อยู่ผิด',
    reasonNameEn: 'Invalid address',
  },
  {
    reasonCode: 'RCTC03',
    reasonNameTh: 'รับคืนสินค้า/ยกเลิกบริการ ทั้งจำนวน (ระบุจำนวนเงิน) บาท',
    reasonNameEn: 'Product return/service cancellation (full refund)',
  },
  {
    reasonCode: 'RCTC04',
    reasonNameTh: 'รับคืนสินค้า/ยกเลิกบริการ บางส่วน (ระบุจำนวนเงิน) บาท',
    reasonNameEn: 'Product return/service cancellation (partial refund)',
  },
  {
    reasonCode: 'RCTC99',
    reasonNameTh: 'เหตุอื่น (ระบุสาเหตุ)',
    reasonNameEn: 'Other reason',
  },
];

export const reasonsDebit = [
  {
    reasonCode: 'DBNG01',
    reasonNameTh: 'มีการเพิ่มราคาค่าสินค้า (สินค้าเกินกว่าจำนวนที่ตกลงกัน)',
    reasonNameEn: `Increasing in product's price (higher than the agreed amount)`,
  },
  {
    reasonCode: 'DBNG02',
    reasonNameTh: 'คำนวณราคาสินค้า ผิดพลาดต่ำกว่าที่เป็นจริง',
    reasonNameEn: `Invalid product's price calculation (lower than it should be)`,
  },
  {
    reasonCode: 'DBNG99',
    reasonNameTh: 'เหตุอื่น (ระบุสาเหตุ กรณีขายสินค้า)',
    reasonNameEn: 'Other reason (sale case)',
  },
  {
    reasonCode: 'DBNS01',
    reasonNameTh: 'มีการเพิ่มราคาค่าบริการ (บริการเกินกว่าข้อกำหนดที่ตกลงกัน)',
    reasonNameEn: `Increasing in service's price (higher than the agreed amount)`,
  },
  {
    reasonCode: 'DBNS02',
    reasonNameTh: 'คำนวณราคาค่าบริการ ผิดพลาดต่ำกว่าที่เป็นจริง',
    reasonNameEn: `Invalid service's price calculation (lower than it should be)`,
  },
  {
    reasonCode: 'DBNS99',
    reasonNameTh: 'เหตุอื่น (ระบุสาเหตุ กรณีบริการ)',
    reasonNameEn: `Other reason (service case)`,
  },
];

export const reasonsCredit = [
  {
    reasonCode: 'CDNG01',
    reasonNameTh: 'ลดราคาสินค้าที่ขาย (สินค้าผิดข้อกำหนดที่ตกลงกัน)',
    reasonNameEn: `Decreasing in product's price (lower than the agreed amount)`,
  },
  {
    reasonCode: 'CDNG02',
    reasonNameTh: 'สินค้าชำรุดเสียหาย',
    reasonNameEn: 'Product defect',
  },
  {
    reasonCode: 'CDNG03',
    reasonNameTh: 'สินค้าขาดจำนวนตามที่ตกลงซื้อขาย',
    reasonNameEn: `Invalid product's quantity`,
  },
  {
    reasonCode: 'CDNG04',
    reasonNameTh: 'คำนวณราคาสินค้าผิดพลาดสูงกว่าราคาที่เป็นจริง',
    reasonNameEn: `Invalid product's price calculation (higher than it should be)`,
  },
  {
    reasonCode: 'CDNG05',
    reasonNameTh: 'รับคืนสินค้า (ไม่ตรงตามคำพรรณนา)',
    reasonNameEn: 'Product return (not as advertised)',
  },
  {
    reasonCode: 'CDNG99',
    reasonNameTh: 'เหตุอื่น (ระบุสาเหตุ กรณีขายสินค้า)',
    reasonNameEn: `Other reason (sale case)`,
  },
  {
    reasonCode: 'CDNS01',
    reasonNameTh: 'ลดราคาค่าบริการ (บริการผิดข้อกำหนดที่ตกลงกัน)',
    reasonNameEn: `Decreasing in service's price (lower than the agreed amount)`,
  },
  {
    reasonCode: 'CDNS02',
    reasonNameTh: 'ค่าบริการขาดจำนวน',
    reasonNameEn: `Invalid service's quantity`,
  },
  {
    reasonCode: 'CDNS03',
    reasonNameTh: 'คำนวณราคาค่าบริการผิดพลาดสูงกว่าที่เป็นจริง',
    reasonNameEn: `Invalid service's price calculation (higher than it should be)`,
  },
  {
    reasonCode: 'CDNS04',
    reasonNameTh: 'บอกเลิกสัญญาบริการ',
    reasonNameEn: `Service cancellation`,
  },
  {
    reasonCode: 'CDNS99',
    reasonNameTh: 'เหตุอื่น (ระบุสาเหตุ กรณีบริการ)',
    reasonNameEn: `Other reason (service case)`,
  },
];

export const getReasonList = (reasonType, language) => {
  let list = [];
  for (let i = 0; i < reasonType.length; i++) {
    if (language === 'en') {
      list.push({
        key: reasonType[i].reasonCode,
        text: reasonType[i].reasonNameEn,
      });
    } else {
      list.push({
        key: reasonType[i].reasonCode,
        text: reasonType[i].reasonNameTh,
      });
    }
  }

  return list;
};

export const getReason = (reasonType, reasonCode, language) => {
  let list = {};
  for (let i = 0; i < reasonType.length; i++) {
    if (reasonType[i].reasonCode === reasonCode) {
      if (language === 'en') {
        list = {
          key: reasonType[i].reasonCode,
          text: reasonType[i].reasonNameEn,
        };
      } else {
        list = {
          key: reasonType[i].reasonCode,
          text: reasonType[i].reasonNameTh,
        };
      }
    }
  }

  return list;
};

export const getTypeList = (language) => {
  let list = [];
  for (let i = 0; i < documentTypes.length; i++) {
    if (language === 'en') {
      list.push({
        key: documentTypes[i].typeCode,
        text: documentTypes[i].typeNameEn,
      });
    } else {
      list.push({
        key: documentTypes[i].typeCode,
        text: documentTypes[i].typeNameTh,
      });
    }
  }

  return list;
};
