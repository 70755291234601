import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CSVLink } from 'react-csv';

import { Stack, DefaultButton, ProgressIndicator, Spinner, getTheme } from '@fluentui/react';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import PdfAllPages from '../pdf/view/PdfAllPages';

const API_URL = process.env.REACT_APP_API_URL;

//Progress Indicator Setting
const intervalDelay = 100;
const intervalIncrement = 0.01;

const InputTaxConfirm = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const companyId = sessionStorage.getItem('companyId') || '';

  const theme = getTheme();

  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();
  const data = location.state;
  console.log(data);

  if (data === undefined) {
    history.push('/reports/output-taxs');
  }

  const [percentComplete, setPercentComplete] = useState(0);
  const [url, setUrl] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);

  const centerItem = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const csvLink = useRef();

  const headerCSV = [
    { key: 'r1', label: '' },
    { key: 'r2', label: '' },
    { key: 'r3', label: '' },
    { key: 'r4', label: '' },
    { key: 'r5', label: '' },
    { key: 'r6', label: '' },
    { key: 'r7', label: '' },
    { key: 'r8', label: '' },
    { key: "r9", label: "" },
    { key: "r10", label: "" },
  ];

  function formatDateTime(date) {
    let d = new Date(date.toLocaleString('en-GB', { timeZone: 'Asia/Bangkok' })),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hour = '' + d.getHours(),
      minute = '' + d.getMinutes(),
      second = '' + d.getSeconds();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    if (hour.length < 2) hour = '0' + hour;
    if (minute.length < 2) minute = '0' + minute;
    if (second.length < 2) second = '0' + second;

    return [year, month, day, hour, minute, second].join('');
  }

  function getCSVData() {
    let csvData = [
      {
        r1: 'รายงานภาษีซื้อ',
        r2: '',
        r3: '',
        r4: '',
        r5: '',
        r6: '',
        r7: '',
        r8: '',
        r9: "",
        r10: "",
      },
      {
        r1: 'เดือนภาษี ' + data.month,
        r2: '',
        r3: '',
        r4: '',
        r5: '',
        r6: '',
        r7: '',
        r8: '',
        r9: "",
        r10: "",
      },
      {
        r1: 'ชื่อผู้ประกอบการ ' + data.companyName + (data.companyBranchText ? ' ' + data.companyBranchText : ''),
        r2: '',
        r3: '',
        r4: '',
        r5: '',
        r6: '',
        r7: '',
        r8: '',
        r9: "",
        r10: "",
      },
      {
        r1: 'เลขประจำตัวผู้เสียภาษี ' + data.companyTaxId,
        r2: '',
        r3: '',
        r4: '',
        r5: '',
        r6: '',
        r7: '',
        r8: '',
        r9: "",
        r10: "",
      },
      {
        r1: 'ที่อยู่ ' + data.companyAllAddress,
        r2: '',
        r3: '',
        r4: '',
        r5: '',
        r6: '',
        r7: '',
        r8: '',
        r9: "",
        r10: "",
      },
      {},
      {
        r1: 'ลำดับ',
        r2: 'วันที่',
        r3: 'เลขที่เอกสาร',
        r4: 'ชื่อผู้ขายสินค้า/ผู้ให้บริการ',
        r5: 'เลขประจำตัวผู้เสียภาษี',
        r6: 'สถานประกอบการ',
        r7: 'มูลค่าสินค้า/บริการ (ไม่รวมภาษี)',
        r8: 'จำนวนเงินภาษี',
        r9: "หมายเหตุ",
        r10: "เลขที่อ้างอิง",
      },
    ];

    data.all_docs.forEach((chunk) => {
      chunk.forEach((doc) => {
        let row = {
          r1: doc.row,
          r2: '=""' + doc.documentDate + '""',
          r3: '=""' + doc.number + '""',
          r4: doc.customerName ? '=""' + doc.customerName  + '""' : "",
          r5: '=""' + (doc.taxId ? doc.taxId : '-') + '""',
          r6: '=""' + (doc.customerBranchNumber ? doc.customerBranchNumber : '-') + '""',
          r7: !doc.reIssue
            ? doc.amountTotal.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : '',
          r8: !doc.reIssue
            ? doc.vatTotal.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : '',
          r9: doc.note,
          r10: doc.reference ? '=""' + doc.reference + '""' : "",
        };

        csvData.push(row);
      });
    });

    csvData.push({
      r1: '',
      r2: '',
      r3: '',
      r4: '',
      r5: '',
      r6: '',
      r7: '',
      r8: '',
      r9: '',
      r10: '',
    });

    csvData.push({
      r1: '',
      r2: '',
      r3: '',
      r4: '',
      r5: '',
      r6: 'รวม',
      r7: data.totalAmount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      r8: data.totalVat.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      r9: '',
      r10: '',
    });
    
    return csvData;
  }

  const handleDownloadCSV = () => {
    setIsDownloading(true);
    csvLink.current.link.click();
    setIsDownloading(false);
  };

  const getfileName = () => {
    const time = formatDateTime(new Date().toISOString());
    let fileName = time + '-Report-Input-Tax.csv';
    return fileName;
  };

  const [fileName] = useState(getfileName);

  const handleDownload = () => {
    setIsDownloading(true);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .post(
            // 'http://localhost:7071/api/ExpenseTax_Pdf',
            // 'http://localhost:7071/api/InputTaxPDFACreate',
            API_URL + '/reports/input-taxs/pdf',
            {
              all_docs: data.all_docs,
              totalPages: data.totalPages,
              month: data.month,
              totalVat: data.totalVat,
              totalAmount: data.totalAmount,
              companyName: data.companyName,
              companyTaxId: data.companyTaxId,
              companyAllAddress: data.companyAllAddress,
              headOffice: data.headOffice,
              branchOffice: data.branchOffice,
              companyBranchNumber: data.companyBranchNumber,
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId,
                'Content-Type': 'application/json',
              },
              responseType: 'blob',
            }
          )
          .then((response) => {
            console.log(response);

            const time = formatDateTime(new Date().toISOString());

            let fileName = time + '-Report-Input-Tax.pdf';

            console.log('file name: ', fileName);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();

            setIsDownloading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const goBack = () => {
    history.push({
      pathname: '/reports/input-taxs',
      state: {},
    });
  };

  useEffect(() => {
    console.log('userEffect Call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        try {
          axios
            .post(
              //   'http://localhost:7071/api/ExpenseTax_Pdf',
              // 'http://localhost:7071/api/InputTaxPDFACreate',
              API_URL + '/reports/input-taxs/pdf-preview',
              {
                all_docs: data.all_docs,
                totalPages: data.totalPages,
                month: data.month,
                totalVat: data.totalVat,
                totalAmount: data.totalAmount,
                companyName: data.companyName,
                companyTaxId: data.companyTaxId,
                companyAllAddress: data.companyAllAddress,
                headOffice: data.headOffice,
                branchOffice: data.branchOffice,
                companyBranchNumber: data.companyBranchNumber,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId,
                  'Content-Type': 'application/json',
                },
                responseType: 'blob',
              }
            )
            .then((response) => {
              setUrl(window.URL.createObjectURL(response.data));
              setPercentComplete(100);
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (e) {
          console.log(e.response); // undefined
        }
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (url === '') {
      const id = setInterval(() => {
        setPercentComplete((intervalIncrement + percentComplete) % 1);
      }, intervalDelay);
      return () => {
        clearInterval(id);
      };
    }
  });

  return (
    <Stack
      vertical
      verticalAlign="start"
      style={{
        background: 'rgb(204,204,204)',
        width: '100%',
        minHeight: '100vh',
      }}
    >
      <br />
      {!isDownloading && (
        <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
          <DefaultButton text={t('inputTaxConfirm.downloadPdf')} iconProps={{ iconName: 'PDF' }} onClick={handleDownload} />
          <CSVLink style={{ display: 'none' }} ref={csvLink} filename={fileName} data={getCSVData()} headers={headerCSV}>
            Download csv
          </CSVLink>
          <DefaultButton iconProps={{ iconName: 'ExcelDocument' }} text={t('inputTaxConfirm.downloadCsv')} onClick={() => handleDownloadCSV()} />
          <DefaultButton text={t('inputTaxConfirm.back')} onClick={goBack}></DefaultButton>
        </Stack>
      )}

      {isDownloading && <Spinner label={t('inputTaxConfirm.loading')} labelPosition="right" style={centerItem} />}

      <br />
      {!url ? (
        <Stack horizontal horizontalAlign="center">
          <div
            style={{
              width: '780px',
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 20,
              marginLeft: '0px',
              marginRight: '0px',
              background: '#FFFFFF',
              boxShadow: theme.effects.elevation4,
            }}
          >
            <ProgressIndicator
              label={t('inputTaxConfirm.processing')}
              description={t('inputTaxConfirm.processingMessage')}
              percentComplete={percentComplete}
              style={centerItem}
            />
            <br />
          </div>
          <br />
          <br />
        </Stack>
      ) : (
        <Stack horizontal horizontalAlign="center">
          <PdfAllPages pdf={url} scale={1.2} />
        </Stack>
      )}
      <br />
      <br />
    </Stack>
  );
};

export default InputTaxConfirm;
