import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CSVLink } from 'react-csv';

import { Stack, DefaultButton, ProgressIndicator, Spinner, getTheme } from '@fluentui/react';

import PdfAllPages from '../pdf/view/PdfAllPages';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

const API_URL = process.env.REACT_APP_API_URL;

//Progress Indicator Setting
const intervalDelay = 100;
const intervalIncrement = 0.01;

function formatDateTime(date) {
  let d = new Date(date.toLocaleString('en-GB', { timeZone: 'Asia/Bangkok' })),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hour = '' + d.getHours(),
    minute = '' + d.getMinutes(),
    second = '' + d.getSeconds();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hour.length < 2) hour = '0' + hour;
  if (minute.length < 2) minute = '0' + minute;
  if (second.length < 2) second = '0' + second;

  return [year, month, day, hour, minute, second].join('');
}

const XmlUploadConfirm = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const companyId = sessionStorage.getItem("companyId") || "";

  const theme = getTheme();

  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();
  const data = location.state;
  console.log(data);

  if (data === undefined) {
    history.push('/reports/deliveries/xml');
  }

  const [percentComplete, setPercentComplete] = useState(0);
  const [url, setUrl] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);

  const centerItem = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const csvLink = useRef();

  const headerCSV = [
    { key: 'r1', label: '' },
    { key: 'r2', label: '' },
    { key: 'r3', label: '' },
    { key: 'r4', label: '' },
    { key: 'r5', label: '' },
    { key: 'r6', label: '' },
    { key: 'r7', label: '' },
    { key: 'r8', label: '' },
    { key: 'r9', label: '' },
  ];

  function getCSVData() {
    let csvData = [
      {
        r1: 'รายงานการนำส่ง XML',
        r2: '',
        r3: '',
        r4: '',
        r5: '',
        r6: '',
        r7: '',
        r8: '',
        r9: '',
      },
      {
        r1: 'เดือน ' + data.month,
        r2: '',
        r3: '',
        r4: '',
        r5: '',
        r6: '',
        r7: '',
        r8: '',
        r9: '',
      },
      {},
      {
        r1: 'ชื่อผู้ประกอบการ ' + data.companyName + (data.companyBranchText ? ' ' + data.companyBranchText : ''),
        r2: '',
        r3: '',
        r4: '',
        r5: '',
        r6: '',
        r7: '',
        r8: '',
        r9: '',
      },
      {
        r1: 'ที่อยู่ ' + data.companyAllAddress,
        r2: '',
        r3: '',
        r4: '',
        r5: '',
        r6: '',
        r7: '',
        r8: '',
        r9: '',
      },
      {
        r1: 'เลขประจำตัวผู้เสียภาษี ' + data.companyTaxId,
        r2: '',
        r3: '',
        r4: '',
        r5: '',
        r6: '',
        r7: '',
        r8: '',
        r9: '',
      },
      {},
      {
        r1: 'วันที่สร้างรายงาน: ' + data.createdTime,
        r2: '',
        r3: '',
        r4: '',
        r5: '',
        r6: '',
        r7: '',
        r8: '',
        r9: '',
      },
      {},
      {
        r1: 'ลำดับ',
        r2: 'วันที่บนเอกสาร',
        r3: 'เลขที่เอกสาร',
        r4: 'ชื่อผู้ซื้อสินค้า/ผู้รับบริการ',
        r5: 'สถานะนำส่ง',
        r6: 'วันที่นำส่ง',
        r7: 'หมายเลขอ้างอิงนำส่งกรมสรรพากร',
        r8: 'มูลค่าสินค้า/บริการ (ไม่รวมภาษี)',
        r9: 'จำนวนเงินภาษี',
      },
    ];

    data.all_docs.forEach((chunk) => {
      chunk.forEach((doc) => {
        let row = {
          r1: doc.row,
          r2: doc.documentDate,
          r3: '=""' + doc.number + '""',
          r4: doc.customerName ? '=""' + doc.customerName  + '""' : "",
          r5: doc.rdStatus,
          r6: doc.rdTime.split(' ')[0],
          r7: doc.rdRefNumber,
          r8: doc.amountTotal.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          r9: doc.vatTotal.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        };

        csvData.push(row);
      });
    });
    return csvData;
  }

  const handleDownloadCSV = () => {
    setIsDownloading(true);
    csvLink.current.link.click();
    setIsDownloading(false);
  };

  const getfileName = () => {
    const time = formatDateTime(new Date().toISOString());
    let fileName = time + '-Report-XML-Deliveries.csv';
    return fileName;
  };

  const [fileName] = useState(getfileName);

  const handleDownload = () => {
    setIsDownloading(true);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .post(
            API_URL + '/reports/xml-deliveries/pdf',
            {
              all_docs: data.all_docs,
              totalPages: data.totalPages,
              month: data.month,
              time: data.createdTime,
              totalVat: data.totalVat,
              totalAmount: data.totalAmount,
              submitting: data.submitting,
              waitSubmit: data.waitSubmit,
              incompleteSubmit: data.incompleteSubmit,
              completeSubmit: data.completeSubmit,
              companyName: data.companyName,
              companyTaxId: data.companyTaxId,
              companyAllAddress: data.companyAllAddress,
              headOffice: data.headOffice,
              branchOffice: data.branchOffice,
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId,
                'Content-Type': 'application/json',
              },
              responseType: 'blob',
            }
          )
          .then((response) => {
            console.log(response);

            const time = formatDateTime(data.createdTimeValue);
            let fileName = time + '-Report-XML-Deliveries.pdf';

            console.log('file name: ', fileName);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();

            setIsDownloading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const goBack = () => {
    history.push({
      pathname: '/reports/deliveries/xml',
      state: {},
    });
  };

  useEffect(() => {
    console.log('userEffect Call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        try {
          axios
            .post(
              API_URL + '/reports/xml-deliveries/pdf-preview',
              {
                all_docs: data.all_docs,
                totalPages: data.totalPages,
                month: data.month,
                time: data.createdTime,
                totalVat: data.totalVat,
                totalAmount: data.totalAmount,
                submitting: data.submitting,
                waitSubmit: data.waitSubmit,
                incompleteSubmit: data.incompleteSubmit,
                completeSubmit: data.completeSubmit,
                companyName: data.companyName,
                companyTaxId: data.companyTaxId,
                companyAllAddress: data.companyAllAddress,
                headOffice: data.headOffice,
                branchOffice: data.branchOffice,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId,
                  'Content-Type': 'application/json',
                },
                responseType: 'blob',
              }
            )
            .then((response) => {
              setUrl(window.URL.createObjectURL(response.data));

              setPercentComplete(100);
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (e) {
          console.log(e.response);
        }
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (url === '') {
      const id = setInterval(() => {
        setPercentComplete((intervalIncrement + percentComplete) % 1);
      }, intervalDelay);
      return () => {
        clearInterval(id);
      };
    }
  });

  return (
    <Stack
      vertical
      verticalAlign="start"
      style={{
        background: 'rgb(204,204,204)',
        width: '100%',
        minHeight: '100vh',
      }}
    >
      <br />
      {!isDownloading && (
        <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
          <DefaultButton text={t('xmlUploadConfirm.downloadPdf')} iconProps={{ iconName: 'PDF' }} onClick={handleDownload} />
          <CSVLink style={{ display: 'none' }} ref={csvLink} filename={fileName} data={getCSVData()} headers={headerCSV}>
            Download csv
          </CSVLink>
          <DefaultButton text={t('xmlUploadConfirm.downloadCsv')} iconProps={{ iconName: 'ExcelDocument' }} onClick={() => handleDownloadCSV()} />
          <DefaultButton text={t('xmlUploadConfirm.back')} onClick={goBack}></DefaultButton>
        </Stack>
      )}

      {isDownloading && <Spinner label={t('xmlUploadConfirm.loading')} labelPosition="right" style={centerItem} />}

      <br />
      {!url ? (
        <Stack horizontal horizontalAlign="center">
          <div
            style={{
              width: '780px',
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 20,
              marginLeft: '0px',
              marginRight: '0px',
              background: '#FFFFFF',
              boxShadow: theme.effects.elevation4,
            }}
          >
            <ProgressIndicator
              label={t('xmlUploadConfirm.processing')}
              description={t('xmlUploadConfirm.processingMessage')}
              percentComplete={percentComplete}
              style={centerItem}
            />
            <br />
          </div>
          <br />
          <br />
        </Stack>
      ) : (
        <Stack horizontal horizontalAlign="center">

            <PdfAllPages pdf={url} scale={1.2} />

        </Stack>
      )}

      <br />
      <br />
    </Stack>
  );
};

export default XmlUploadConfirm;
