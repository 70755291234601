

export const documentOptions = [
  "10-document-per-page",
  "20-document-per-page",
  "30-document-per-page",
  "40-document-per-page",
  "50-document-per-page",

  // Add more options as needed
];

export const getDocumentTypeText = (_selectedValue, language) => {
  if (language === "thai") {
    return thaiDocumentOptions.find(
      (documentoptions) => documentoptions.filter === _selectedValue
    ).title;
  }

  return englishDocumentOptions.find(
    (documentoptions) => documentoptions.filter === _selectedValue
  ).title;
};

export const documentOptionswithTextTh = [
  {
    id: "010",
    key: "10-document-per-page",
    title: "10 เอกสาร/หน้า",
    value: 10,
  },
  {
    id: "020",
    key: "20-document-per-page",
    title: "20 เอกสาร/หน้า",
    value: 20,
  },
  {
    id: "030",
    key: "30-document-per-page",
    title: "30 เอกสาร/หน้า",
    value: 30,
  },
  {
    id: "040",
    key: "40-document-per-page",
    title: "40 เอกสาร/หน้า",
    value: 40,
  },
  {
    id: "050",
    key: "50-document-per-page",
    title: "50 เอกสาร/หน้า",
    value: 50,
  },

  // Add more options as needed
];

export const documentOptionswithTextEn = [
  {
    id: "010",
    key: "10-document-per-page",
    title: "10 documents",
    value: 10,
  },
  {
    id: "020",
    key: "20-document-per-page",
    title: "20 documents",
    value: 20,
  },
  {
    id: "030",
    key: "30-document-per-page",
    title: "30 documents",
    value: 30,
  },
  {
    id: "040",
    key: "40-document-per-page",
    title: "40 documents",
    value: 40,
  },
  {
    id: "050",
    key: "50-document-per-page",
    title: "50 documents",
    value: 50,
  },

  // Add more options as needed
];
// Ad more options as needed

export const getDocumentOptions = (language) => {
  return language === "thai" ? thaiDocumentOptions : englishDocumentOptions;
};

export const englishDocumentOptions = [
  {
    id: "010",
    title: "10 เอกสาร/หน้า",
    filter: "10-document-per-page",
  },
  {
    id: "020",
    title: "10 เอกสาร/หน้า",
    filter: "20-document-per-page",
  },
  {
    id: "030",
    title: "30 เอกสาร/หน้า",
    filter: "30-document-per-page",
  },
  { id: "040", title: "40 เอกสาร/หน้า", filter: "40-document-per-page" },
  { id: "050", title: "50 เอกสาร/หน้า", filter: "50-document-per-page" },
];

export const thaiDocumentOptions = [
  {
    id: "010",
    title: "10 เอกสาร/หน้า",
    filter: "10-document-per-page",
  },
  {
    id: "020",
    title: "20 เอกสาร/หน้า",
    filter: "20-document-per-page",
  },
  {
    id: "030",
    title: "30 เอกสาร/หน้า",
    filter: "30-document-per-page",
  },
  { id: "040", title: "40 เอกสาร/หน้า", filter: "40-document-per-page" },
  { id: "050", title: "50 เอกสาร/หน้า", filter: "50-document-per-page" },
];
