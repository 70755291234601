import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';
import { useTranslation } from 'react-i18next';

import { DetailsListLayoutMode, ShimmeredDetailsList, SelectionMode, Stack, PrimaryButton, TextField } from '@fluentui/react';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

const API_URL = process.env.REACT_APP_API_URL;

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

const RdVat = () => {
  console.log('Home render!!!');

  const { t } = useTranslation();

  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];

  const documents = [];
  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const getNewDocuments = true;

  const pageData = {};
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const [taxId, setTaxId] = useState('');
  const [branchNumber, setBranchNumber] = useState('');
  const [isClick, setIsClick] = useState(false);

  const rdVatSelected = { key: 'taxId', text: 'เลขประจำตัวผู้เสียภาษี 13 หลัก\u00A0\u00A0' };

  const toThaiDateString = (isoDateTime) => {
    let date = '';
    date = new Date(isoDateTime);

    let year = date.getFullYear() + 543;
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let numOfDay = date.getDate().toString().padStart(2, '0');

    return `${numOfDay}/${month}/${year}`;
  };

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  useEffect(() => {
    console.log('userEffect getNewDocuments Call!');
  }, [getNewDocuments]);

  useEffect(() => {
    if (documents.length > 0) {
      const _docs = [];

      for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {}
      console.log('Docs data', _docs);

      setItems(_docs);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const columns = [
    {
      key: 'column1',
      name: t('rdVat.number'),
      fieldName: 'number',
      minWidth: 20,
      maxWidth: 20,
      data: 'number',
      onRender: (item) => {
        return <span>{item.number}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column2',
      name: t('rdVat.taxId'),
      fieldName: 'taxId',
      minWidth: 150,
      maxWidth: 180,
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.key}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column3',
      name: t('rdVat.branch'),
      fieldName: 'branch',
      minWidth: 70,
      maxWidth: 70,
      data: 'string',
      onRender: (item) => {
        return <span>{item.branch}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column4',
      name: t('rdVat.name'),
      fieldName: 'name',
      minWidth: 150,
      maxWidth: 250,
      isResizable: true,
      isMultiline: true,
      data: 'string',
      onRender: (item) => {
        return <span>{item.name}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column5',
      name: t('rdVat.address'),
      fieldName: 'address',
      minWidth: 250,
      maxWidth: 350,
      isResizable: true,
      isMultiline: true,
      data: 'string',
      onRender: (item) => {
        return <span>{item.address}</span>;
      },
    },
    {
      key: 'column6',
      name: t('rdVat.postcode'),
      fieldName: 'postcode',
      minWidth: 70,
      maxWidth: 80,
      isResizable: true,
      onColumnClick: (item) => {
        console.log('Sort vatTotal!');
      },
      data: 'string',
      onRender: (item) => {
        return <span style={{ display: 'block', textAlign: 'left' }}>{item.postcode}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column7',
      name: t('rdVat.registerDate'),
      fieldName: 'registerDate',
      minWidth: 70,
      maxWidth: 70,
      isResizable: true,
      onColumnClick: (item) => {
        console.log('Sort grandTotal!');
      },
      data: 'string',
      onRender: (item) => {
        return <span style={{ display: 'block', textAlign: 'left' }}>{item.registerDate}</span>;
      },
      isPadded: true,
    },
  ];

  const isCompactMode = false;

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log('rdVatSelected: ', rdVatSelected);
    console.log('taxId: ', taxId);

    setIsClick(true);
    setLoadDataComplete(false);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/sources/rd/vat/' + taxId + (branchNumber ? `?branch=${branchNumber}` : ''), {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log(response);

              let _docs = [];
              let _data = response.data;
              let _address = '';
              let _registerDate = '';
              let _split = [];

              if (_data && _data.NID.length > 0) {
                console.log('Rd Vat data: ', _data);
                console.log('NID: ', _data.NID[0]);
                for (let i = 0; i < _data.NID.length; i++) {
                  console.log('NID: ', _data);

                  _address = `อาคาร ${_data.BuildingName[i]}, ห้องเลขที่ ${_data.RoomNumber[i]}, ชั้นที่ ${_data.FloorNumber[i]}, หมู่บ้าน ${_data.VillageName[i]}, เลขที่ ${_data.HouseNumber[i]}, หมู่ที่ ${_data.MooNumber[i]}, ตรอก/ซอย ${_data.SoiName[i]}, ถนน ${_data.StreetName[i]}, ตำบล/แขวง ${_data.Thambol[i]}, อำเภอ/เขต ${_data.Amphur[i]}, จังหวัด ${_data.Province[i]}`;

                  if (_data.BusinessFirstDate[i] && _data.BusinessFirstDate[i].indexOf('/') !== -1) {
                    _split = _data.BusinessFirstDate[i].split('/');
                    _registerDate = _split[2] + '/' + _split[1] + '/' + String(Number(_split[0]) + 543);
                  } else if (_data.BusinessFirstDate[i] && _data.BusinessFirstDate[i].indexOf('-') !== -1) {
                    _split = _data.BusinessFirstDate[i].split('-');
                    _registerDate = _split[2] + '/' + _split[1] + '/' + String(Number(_split[0]) + 543);
                  } else {
                    _registerDate = _data.BusinessFirstDate[i];
                  }

                  _docs.push({
                    key: _data.NID[i],
                    number: i + 1,
                    branch: _data.BranchNumber[i] === 0 ? 'สำนักงานใหญ่' : _data.BranchNumber[i],
                    name: _data.TitleName[i] + ' ' + _data.Name[i],
                    address: _address,
                    postcode: _data.PostCode[i],
                    registerDate: _registerDate,
                  });
                }
                console.log('_docs: ', _docs);
              }
              setItems(_docs);
              setLoadDataComplete(true);
            },
            (error) => {
              console.log(error);
              setLoadDataComplete(true);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
        setLoadDataComplete(true);
      });
  };

  return (
    <div style={homeStyles}>
      <Stack>
        <center>
          <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>
            {t('rdVat.header')} {toThaiDateString(new Date())}
          </h2>
          <h4>{t('rdVat.subHeader')}</h4>
        </center>

        <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}></Stack>
      </Stack>

      <br />

      <form onSubmit={handleSubmit}>
        <Stack vertical>
          <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 15 }}>
            <TextField
              name="taxId"
              label={t('rdVat.taxId2')}
              required
              styles={{ root: { width: 310 } }}
              onChange={(e) => {
                setTaxId(e.target.value);
              }}
              value={taxId}
            />
            <TextField
              name="branchNumber"
              label={t('rdVat.branch')}
              styles={{ root: { width: 60 } }}
              onChange={(e) => {
                if (!isNaN(e.target.value)) {
                  setBranchNumber(e.target.value);
                }
              }}
              onBlur={(e) => {
                if (!e.target.value.trim()) {
                  setBranchNumber('');
                } else {
                  setBranchNumber(e.target.value.trim());
                }
              }}
              value={branchNumber}
            />
            <PrimaryButton
              iconProps={{ iconName: 'Search' }}
              name="search"
              text={t('rdVat.search')}
              style={{ marginTop: '29px' }}
              type="submit"
              value="search"
            />
          </Stack>
        </Stack>
      </form>

      {!isClick && (
        <Stack>
          <br />
          <br />
          <Stack horizontal horizontalAlign="center">
            <Stack
              vertical
              style={{
                width: '98%',
                maxWidth: '800px',
              }}
            >
              <p>{t('rdVat.footer1')} </p>
              <p>{t('rdVat.footer2')}</p>
            </Stack>
          </Stack>
        </Stack>
      )}

      <br />
      <br />

      {isClick && (
        <Stack horizontal horizontalAlign="center">
          <Stack
            vertical
            style={{
              width: '98%',
              maxWidth: '1350px',
            }}
          >
            <ShimmeredDetailsList
              items={items || []}
              enableShimmer={!loadDataComplete}
              compact={isCompactMode}
              columns={columns}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
            />
          </Stack>
        </Stack>
      )}

      <br />
      <center>
        {!(documents.length === 0 || documents.length < pageSize) && loadDataComplete && (
          <Pagination
            selectedPageIndex={currentPage - 1}
            pageCount={pageData.totalPages}
            onPageChange={onPageChange}
            format
            firstPageIconProps={{ iconName: 'DoubleChevronLeft' }}
            previousPageIconProps={{ iconName: 'ChevronLeft' }}
            nextPageIconProps={{ iconName: 'ChevronRight' }}
            lastPageIconProps={{ iconName: 'DoubleChevronRight' }}
          />
        )}

        <br />
        <br />
      </center>
    </div>
  );
};

export default RdVat;
