import React, { useEffect, useState } from 'react';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../../../authConfig';

import axios from 'axios';

import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { DefaultButton, Stack, mergeStyles, Spinner, FontIcon } from '@fluentui/react';

import PdfAllPages from './PdfAllPages';

import './ViewPdf.css';

const API_URL = process.env.REACT_APP_API_URL;

// Processing dialog style
const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

//Check icon style
const checkIconClass = mergeStyles({
  fontSize: 18,
  marginTop: '2px',
  color: 'green',
});

const ViewPdfInbox = () => {
  //comment
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();

  const [data] = useState(location.state);

  if (data && (data.item.key === '' || data.item.signature === '')) {
    history.push({
      pathname: '/inbox',
      state: {},
    });
  }

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const companyId = sessionStorage.getItem('companyId') || '';

  const [url, setUrl] = useState('');

  const [certificateName] = useState(data.item.signature);

  const [loadPdfComplete, setLoadPdfComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  if (data && (data.item.digitalSign === null || data.item.digitalSign === undefined || data.item.digitalSign === '')) {
    data.item.digitalSign = true;
  }

  const [digitalSign] = useState(data.item.digitalSign);

  const backPDF = () => {
    history.push({
      pathname: '/inbox',
      state: {},
    });
  };

  const openFile = (documentId) => {
    setIsLoading(true);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/inboxs/files/' + documentId, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId,
            },
            responseType: 'blob',
          })
          .then(
            (response) => {
              setUrl(window.URL.createObjectURL(response.data));
              setLoadPdfComplete(true);
              setIsLoading(false);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const download = () => {
    let fileName = data.item.fileName;

    let link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handlePrint = () => {
    let iframe = document.createElement('iframe'); //load content in an iframe to print later
    document.body.appendChild(iframe);

    iframe.style.display = 'none';
    iframe.src = url;
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
  };

  useEffect(() => {
    openFile(data.item.key);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack>
      {isLoading && (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="loading-center">
              <Spinner label={t('viewPdf.loading')} labelPosition="down" styles={spinnerStyles} />
            </div>
            <br />
          </Stack>
        </Stack>
      )}

      {loadPdfComplete && (
        <Stack>
          <Stack
            horizontal
            horizontalAlign="center"
            tokens={{ childrenGap: 25 }}
            style={{
              paddingTop: '10px',
              paddingBottom: '10px',
              background: '#4D4D4D',
              width: 'calc(100vw - 303px)',
              minWidth: '1113px',
            }}
          >
            <DefaultButton text={t('viewPdf.download')} onClick={download}></DefaultButton>
            <DefaultButton text={t('viewPdf.print')} onClick={() => handlePrint()} style={{ width: '110px' }}></DefaultButton>
            <DefaultButton text={t('viewPdf.back')} onClick={backPDF} style={{ width: '110px' }}></DefaultButton>
          </Stack>

          {digitalSign && certificateName && (
            <Stack
              horizontal
              horizontalAlign="center"
              style={{
                paddingTop: '5px',
                paddingBottom: '5px',
                background: '#C7E3F8',
              }}
            >
              <FontIcon iconName="PenWorkspace" className={checkIconClass} />
              &nbsp;
              <FontIcon iconName="SkypeCircleCheck" className={checkIconClass} />
              <span style={{ marginLeft: '8px' }}>
                {t('viewPdf.signBy')} {certificateName}
              </span>
            </Stack>
          )}

          <Stack horizontal horizontalAlign="center">
            <Stack
              vertical
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              <div className="all-page-container">{loadPdfComplete && <PdfAllPages pdf={url} />}</div>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default ViewPdfInbox;
