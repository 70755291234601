export const units = [
  { unitCode: '', unitNameTh: '-', unitNameEn: '-' },
  { unitCode: 'EA', unitNameTh: 'ชิ้น/อัน', unitNameEn: 'EA' },
  { unitCode: 'BX', unitNameTh: 'กล่อง', unitNameEn: 'BX' },
  { unitCode: 'NMP', unitNameTh: 'แพ็ค', unitNameEn: 'PACK' },
  { unitCode: 'AU', unitNameTh: 'หน่วย', unitNameEn: 'AU' },
  { unitCode: 'BAG', unitNameTh: 'ถุง', unitNameEn: 'BAG' },
  { unitCode: 'BKT', unitNameTh: 'ปี๊บ', unitNameEn: 'BKT' },
  { unitCode: 'BT', unitNameTh: 'ขวด', unitNameEn: 'BT' },
  { unitCode: 'CAN', unitNameTh: 'กระป๋อง', unitNameEn: 'CAN' },
  { unitCode: 'CELL', unitNameTh: 'เซลล์', unitNameEn: 'CELL' },
  { unitCode: 'DAY', unitNameTh: 'วัน', unitNameEn: 'DAY' },
  { unitCode: 'DR', unitNameTh: 'ถัง', unitNameEn: 'DR' },
  { unitCode: 'DZ', unitNameTh: 'โหล', unitNameEn: 'DZ' },
  { unitCode: 'GLL', unitNameTh: 'แกลลอน', unitNameEn: 'GLL' },
  { unitCode: 'JOB', unitNameTh: 'งาน', unitNameEn: 'JOB' },
  { unitCode: 'PCS', unitNameTh: 'ชิ้น', unitNameEn: 'PCS' },
  { unitCode: 'SET', unitNameTh: 'ชุด', unitNameEn: 'SET' },
  { unitCode: 'M', unitNameTh: 'เมตร', unitNameEn: 'M' },
  { unitCode: 'YD', unitNameTh: 'หลา', unitNameEn: 'YARD' },
  { unitCode: 'CM', unitNameTh: 'ซม.', unitNameEn: 'CM' },
  { unitCode: 'IN', unitNameTh: 'นิ้ว', unitNameEn: 'INCH' },
  { unitCode: 'G', unitNameTh: 'กรัม', unitNameEn: 'GRAM' },
  { unitCode: 'KG', unitNameTh: 'กก.', unitNameEn: 'KG' },
  { unitCode: 'LB', unitNameTh: 'ปอนด์', unitNameEn: 'POUND' },
  { unitCode: 'L', unitNameTh: 'ลิตร', unitNameEn: 'LITRE' },
  { unitCode: 'U12', unitNameTh: 'คิว', unitNameEn: 'CUBIC' },
  { unitCode: 'U29', unitNameTh: 'เส้น', unitNameEn: 'LINE' },
  { unitCode: 'CAR', unitNameTh: 'คัน', unitNameEn: 'CAR' },
  { unitCode: 'U19', unitNameTh: 'เที่ยว', unitNameEn: 'TRIP' },
  { unitCode: 'SHT', unitNameTh: 'แผ่น', unitNameEn: 'SHEET' },
  { unitCode: 'ROL', unitNameTh: 'ม้วน', unitNameEn: 'ROLL' },
  { unitCode: 'ROM', unitNameTh: 'ห้อง', unitNameEn: 'ROOM' },
//   { unitCode: 'U2', unitNameTh: 'ตัว', unitNameEn: 'U2' },
  //   { unitCode: 'AS', unitNameTh: 'คละชนิด', unitNameEn: 'AS' },
  //   { unitCode: 'AU', unitNameEn: 'AU' },
  //   { unitCode: 'SET', unitNameEn: 'SET' },
  //   { unitCode: 'CELL', unitNameEn: 'CELL' },
  //   { unitCode: 'EAEN', unitNameEn: 'EA' },
  //   { unitCode: 'PCS', unitNameEn: 'PCS' },
  //   { unitCode: 'JOB', unitNameEn: 'JOB' },
];

export const getUnitList = (language) => {
  //console.log(district);
  //console.log('ProvinceCode:', provinceCode);
  //console.log(district.length);

  let list = [];
  //list.push({ key: '', text: 'กรุณาเลือก' });
  for (let i = 0; i < units.length; i++) {
    if (language === 'en') {
      list.push({ key: units[i].unitCode, text: units[i].unitNameEn });
    } else {
      list.push({ key: units[i].unitCode, text: units[i].unitNameTh });
    }
  }

  return list;

  //let list = this.district.filter();
};

export const getUnit = (unitCode, language) => {
  //console.log(district);
  //console.log('ProvinceCode:', provinceCode);
  //console.log(district.length);

  let list = {};
  //list.push({ key: '', text: 'กรุณาเลือก' });
  for (let i = 0; i < units.length; i++) {
    if (units[i].unitCode === unitCode) {
      if (language === 'en') {
        list = { key: units[i].unitCode, text: units[i].unitNameEn };
      } else {
        list = { key: units[i].unitCode, text: units[i].unitNameTh };
      }
    }
  }

  return list;

  //let list = this.district.filter();
};

export const getUnitByName = (unitName, language) => {
  let list = {};

  for (let i = 0; i < units.length; i++) {
    if (language === 'en') {
      if (units[i].unitNameEn === unitName) {
        list = { key: units[i].unitCode, text: units[i].unitNameEn };
      }
    } else {
      if (units[i].unitNameTh === unitName) {
        list = { key: units[i].unitCode, text: units[i].unitNameTh };
      }
    }
  }

  return list;
};

export const getUnitByNameWithoutLanguage = (unitName) => {
  let list = {};

  for (let i = 0; i < units.length; i++) {
    if (units[i].unitNameEn === unitName || units[i].unitNameTh === unitName) {
      list = {
        key: units[i].unitCode,
        textEn: units[i].unitNameEn,
        textTh: units[i].unitNameTh,
      };
    }
  }

  return list;
};
