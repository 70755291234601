import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Nav, /* initializeIcons, */ PrimaryButton, Stack } from '@fluentui/react';
import { ContextualMenuItemType } from '@fluentui/react/lib/ContextualMenu';
import { withRouter } from 'react-router-dom';

// import axios from 'axios';

import { useMsal } from '@azure/msal-react';
// import { useMsal, useAccount } from '@azure/msal-react';
// import { silentRequest } from '../authConfig';
import { profileEditingRequest, passwordResetRequest } from '../authConfig';

// const API_URL = process.env.REACT_APP_API_URL;

const navigationStyles = {
  root: {
    height: '100%',
    width: '286px',
    boxSizing: 'border-box',
    paddingTop: '5%',
    paddingLeft: '5%',
    paddingRight: '3%',
    margin: 0,
    fontSize: '100',
  },
};

const Navigation = (props) => {
  // initializeIcons();

  const { instance } = useMsal();
//   const { instance, accounts } = useMsal();
//   const account = useAccount(accounts[0] || {});

  //   const [keyUrl, setKeyUrl] = useState('/');
  const [command, setCommand] = useState('');

  //   const [companySetting, setCompanySetting] = useState(true);

  const { t, i18n } = useTranslation();

  const { companyDetailData } = props;

  let links = [
    {
      name: t('navigation.folder'),
    //   isExpanded: true,
    //   collapseByDefault: false,

      links: [
        {
          name: t('navigation.sales'),
          url: '/etax/documents/all',
          key: '/etax/documents/all',
          iconProps: {
            iconName: 'DocumentSet',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },

        {
          name: t('navigation.accountReceivable'),
          url: '/etax/documents/accounts-receivable/all',
          key: '/etax/documents/accounts-receivable/all',
          iconProps: {
            iconName: 'DocumentSet',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },

        {
          name: t('navigation.purchasing'),
          url: '/expenses/all',
          key: '/expenses/all',
          iconProps: {
            iconName: 'DocumentSet',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
        {
          name: t('navigation.withholdingTax'),
          url: '/withholding-taxs/all',
          key: '/withholding-taxs/all',
          iconProps: {
            iconName: 'DocumentSet',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
      ],
    },
    /* 
    {
      name: t('navigation.send-receivedDocument'),
      isExpanded: true,

      links: [
        {
          name: t('navigation.inbox'),
          url: '/inbox',
          key: '/inbox',
          iconProps: {
            iconName: 'Inbox',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
        {
          name: t('navigation.sendDocument'),
          url: '/sent/upload',
          key: '/sent/upload',
          iconProps: {
            iconName: 'Generate',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
        {
          name: t('navigation.sentItems'),
          url: '/sent',
          key: '/sent',
          iconProps: {
            iconName: 'Send',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
      ],
    },
 */
    {
      name: t('navigation.organizationContacts'),
    //   isExpanded: false,
      collapseByDefault: true,
      links: [
        {
          name: t('navigation.company'),
          url: '/etax/companies/profiles',
          key: '/etax/companies/profiles',
          iconProps: {
            iconName: 'CityNext2',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
        {
          name: t('navigation.contacts'),
          url: '/contacts',
          key: '/contacts',
          iconProps: {
            iconName: 'ContactList',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
       
        // {
        //   name: t('navigation.contacts'),
        //   url: '/persons',
        //   key: '/persons',
        //   iconProps: {
        //     iconName: 'ContactList',
        //     styles: {
        //       root: {
        //         fontSize: 20,
        //         color: '#106ebe',
        //       },
        //     },
        //   },
        // },
         {
          name: t('navigation.requesterTaxInvoice'),
          url: '/request-forms/contacts-client-list',
          key: '/request-forms/contacts-client-list',
          iconProps: {
            iconName: 'ContactList',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
      ],
    },    
    {
      name: t('navigation.externalDataPaid'),
    //   isExpanded: false,
      collapseByDefault: true,
      links: [
        {
          name: 'MS Dynamics 365 BC',
          url: '/etax/sources/dynamics/business-central/connect',
          key: '/etax/sources/dynamics/business-central/connect',
          iconProps: {
            iconName: 'Dynamics365Logo',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
        {
          name: 'MS Dynamics 365 FO',
          url: '/etax/sources/dynamics/finance-operations/connect',
          key: '/etax/sources/dynamics/finance-operations/connect',
          iconProps: {
            iconName: 'Dynamics365Logo',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
        {
            name: 'MS Dynamics 365 Sales',
            url: '/etax/sources/dynamics/crm/connect',
            key: '/etax/sources/dynamics/crm/connect',
            iconProps: {
              iconName: 'Dynamics365Logo',
              styles: {
                root: {
                  fontSize: 20,
                  color: '#106ebe',
                },
              },
            },
        },
/* 
        {
          name: 'PayPal',
          url: '/etax/sources/paypal/connect',
          key: '/etax/sources/paypal/connect',
          iconProps: {
            iconName: 'CloudImportExport',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
 */
        {
          name: 'SAP Business One',
          url: '/etax/sources/sap/business-one/connect',
          key: '/etax/sources/sap/business-one/connect',
          iconProps: {
            iconName: 'CloudImportExport',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
/* 
        {
          name: 'Shipnity',
          url: '/etax/sources/shipnity/connect',
          key: '/etax/sources/shipnity/connect',
          iconProps: {
            iconName: 'CloudImportExport',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
        {
          name: 'Xero',
          url: '/etax/sources/xero/connect',
          key: '/etax/sources/xero/connect',
          iconProps: {
            iconName: 'CloudImportExport',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
 */
      ],
    },
    {
      name: t('navigation.externalData'),
    //   isExpanded: false,
      collapseByDefault: true,
      links: [
/* 
        {
          name: 'JD Central',
          url: '/etax/sources/jdcentral/connect',
          key: '/etax/sources/jdcentral/connect',
          iconProps: {
            iconName: 'CloudImportExport',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
         */
        {
          name: 'Lazada',
          url: '/etax/sources/lazada/connect',
          key: '/etax/sources/lazada/connect',
          iconProps: {
            iconName: 'CloudImportExport',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },

        {
          name: 'LINE SHOPPING',
          url: '/etax/sources/line-shopping/connect',
          key: '/etax/sources/line-shopping/connect',
          iconProps: {
            iconName: 'CloudImportExport',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },

        {
          name: 'PayPal',
          url: '/etax/sources/paypal/connect',
          key: '/etax/sources/paypal/connect',
          iconProps: {
            iconName: 'CloudImportExport',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
        
        {
          name: 'PEAK Account',
          url: '/etax/sources/peak/connect',
          key: '/etax/sources/peak/connect',
          iconProps: {
            iconName: 'CloudImportExport',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
        {
          name: 'QuickBooks',
          url: '/etax/sources/quickbooks/connect',
          key: '/etax/sources/quickbooks/connect',
          iconProps: {
            iconName: 'CloudImportExport',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
        {
            name: 'Shipnity',
            url: '/etax/sources/shipnity/connect',
            key: '/etax/sources/shipnity/connect',
            iconProps: {
              iconName: 'CloudImportExport',
              styles: {
                root: {
                  fontSize: 20,
                  color: '#106ebe',
                },
              },
            },
        },
        
        {
          name: 'Shopee',
          url: '/etax/sources/shopee/connect',
          key: '/etax/sources/shopee/connect',
          iconProps: {
            iconName: 'CloudImportExport',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
        /* 
        {
          name: 'Stripe',
          url: '/etax/sources/stripe/connect',
          key: '/etax/sources/stripe/connect',
          iconProps: {
            iconName: 'CloudImportExport',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
       */    
        {
          name: 'TikTok Shop',
          url: '/etax/sources/tiktok-shop/connect',
          key: '/etax/sources/tiktok-shop/connect',
          iconProps: {
            iconName: 'CloudImportExport',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
        {
          name: 'TRCloud',
          url: '/etax/sources/trcloud/connect',
          key: '/etax/sources/trcloud/connect',
          iconProps: {
            iconName: 'CloudImportExport',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
        {
          name: 'Woo Commerce',
          url: '/etax/sources/woo-commerce/connect',
          key: '/etax/sources/woo-commerce/connect',
          iconProps: {
            iconName: 'CloudImportExport',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
        {
            name: 'Xero',
            url: '/etax/sources/xero/connect',
            key: '/etax/sources/xero/connect',
            iconProps: {
              iconName: 'CloudImportExport',
              styles: {
                root: {
                  fontSize: 20,
                  color: '#106ebe',
                },
              },
            },
        },
        {
          name: 'Zoho',
          url: '/etax/sources/zoho/connect',
          key: '/etax/sources/zoho/connect',
          iconProps: {
            iconName: 'CloudImportExport',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
        {
          name: t('navigation.fileExcel'),
          url: '/etax/sources/excel',
          key: '/etax/sources/excel',
          iconProps: {
            iconName: 'ExcelDocument',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
      ],
    },
    {
      name: t('navigation.product'),
    //   isExpanded: false,
      collapseByDefault: true,
      links: [
        {
          name: t('navigation.productList'),
          url: '/products',
          key: '/products',
          iconProps: {
            iconName: 'ProductList',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
      ],
    },
    {
      name: t('navigation.submitEtax'),
    //   isExpanded: false,
      collapseByDefault: true,
      links: [
        {
          name: t('navigation.submitXml'),
          url: '/etax/xml/deliveries',
          key: '/etax/xml/deliveries',
          iconProps: {
            iconName: 'BulkUpload',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        }        
      ],
    },
    {
      name: t('navigation.reports'),
    //   isExpanded: false,
      collapseByDefault: true,
      links: [
        {
          name: t('navigation.salesReport'),
          url: '/reports/sell-summary',
          key: '/reports/sell-summary',
          iconProps: {
            iconName: 'DocumentSet',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
        {
          name: t('navigation.outputTaxReport'),
          url: '/reports/output-taxs',
          key: '/reports/output-taxs',
          iconProps: {
            iconName: 'DocumentSet',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
        {
          name: t('navigation.inputTaxReport'),
          url: '/reports/input-taxs',
          key: '/reports/input-taxs',
          iconProps: {
            iconName: 'DocumentSet',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },        
        {
          name: t('navigation.xmlDeliveryReport'),
          url: '/reports/deliveries/xml',
          key: '/reports/deliveries/xml',
          iconProps: {
            iconName: 'DocumentSet',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
        {
            name: t('navigation.emailDeliveries'),
            url: '/reports/deliveries/email',
            key: '/reports/deliveries/email',
            iconProps: {
              iconName: 'DocumentSet',
              styles: {
                root: {
                  fontSize: 20,
                  color: '#106ebe',
                },
              },
            },
          },
          {
            name: t('navigation.smsDeliveries'),
            url: '/reports/deliveries/sms',
            key: '/reports/deliveries/sms',
            iconProps: {
              iconName: 'DocumentSet',
              styles: {
                root: {
                  fontSize: 20,
                  color: '#106ebe',
                },
              },
            },
          },
      ],
    },    
    {
      name: t('navigation.fileRdPrep'),
    //   isExpanded: false,
      collapseByDefault: true,
      links: [
        {
          name: t('navigation.pp30'),
          url: '/rdprep/pp30',
          key: '/rdprep/pp30',
          iconProps: {
            iconName: 'DocumentSet',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },

        {
          name: t('navigation.pnd2'),
          url: '/rdprep/PND2',
          key: '/rdprep/PND2',
          iconProps: {
            iconName: 'DocumentSet',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
        {
          name: t('navigation.pnd3'),
          url: '/rdprep/PND3',
          key: '/rdprep/PND3',
          iconProps: {
            iconName: 'DocumentSet',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
        {
          name: t('navigation.pnd53'),
          url: '/rdprep/PND53',
          key: '/rdprep/PND53',
          iconProps: {
            iconName: 'DocumentSet',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
      ],
    },    
/* 
    {
      name: t('navigation.rdData'),
      isExpanded: false,
      collapseByDefault: true,
      links: [
        {
          name: t('navigation.vatRd'),
          url: '/rd/vat',
          key: '/rd/vat',
          iconProps: {
            iconName: 'CityNext2',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },

        {
          name: t('navigation.taxIdRd'),
          url: '/rd/taxId',
          key: '/rd/taxId',
          iconProps: {
            iconName: 'CityNext2',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
        {
          name: t('navigation.eTaxRd'),
          url: '/rd/etax',
          key: '/rd/etax',
          iconProps: {
            iconName: 'CityNext2',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
      ],
    },
 */
    {
      name: t('navigation.setting'),
    //   isExpanded: false,
      collapseByDefault: true,
      links: [
        {
          name: t('navigation.certificates'),
          url: '/etax/certificates',
          key: '/etax/certificates',
          iconProps: {
            iconName: 'Certificate',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
        {
          name: t('navigation.xmlDelivery'),
          url: '/etax/xml/deliver',
          key: '/etax/xml/deliver',
          iconProps: {
            iconName: 'BulkUpload',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
        {
          name: t('navigation.templates'),
          url: '/etax/templates/pdf',
          key: '/etax/templates/pdf',
          iconProps: {
            iconName: 'FileTemplate',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
        {
          name: t('navigation.emailSetting'),
          url: '/settings/emails',
          key: '/settings/emails',
          iconProps: {
            iconName: 'Mail',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
         {
          name: t('navigation.formRequestTaxInvoice'),
          url: '/request-forms/management-form',
          key: '/request-forms/management-form',
          iconProps: {
            iconName: 'DocumentManagement',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },
/* 
        {
            name: t('navigation.storages'),
            url: '/settings/storages',
            key: '/settings/storages',
            iconProps: {
              iconName: 'CloudImportExport',
              styles: {
                root: {
                  fontSize: 20,
                  color: '#106ebe',
                },
              },
            },
        },
         */
        {
          name: t('navigation.apiConnect'),
          url: '/etax/apikeys',
          key: '/etax/apikeys',
          iconProps: {
            iconName: 'ServerProcesses',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
        },        
        {
          name: t('navigation.userManual'),
          url: 'https://www.leceipt.com/docs/etax/%e0%b8%84%e0%b8%b9%e0%b9%88%e0%b8%a1%e0%b8%b7%e0%b8%ad-etax',
          key: 'user-manual',
          iconProps: {
            iconName: 'BookAnswers',
            styles: {
              root: {
                fontSize: 20,
                color: '#106ebe',
              },
            },
          },
          target: '_blank',
        },
      ],
    },
  ];
/* 
  let rdLinks =     
    {
        name: t('navigation.rdData'),
        // isExpanded: false,
        collapseByDefault: true,
        links: [
          {
            name: t('navigation.vatRd'),
            url: '/rd/vat',
            key: '/rd/vat',
            iconProps: {
              iconName: 'CityNext2',
              styles: {
                root: {
                  fontSize: 20,
                  color: '#106ebe',
                },
              },
            },
          },
  
          {
            name: t('navigation.taxIdRd'),
            url: '/rd/taxId',
            key: '/rd/taxId',
            iconProps: {
              iconName: 'CityNext2',
              styles: {
                root: {
                  fontSize: 20,
                  color: '#106ebe',
                },
              },
            },
          },
          {
            name: t('navigation.eTaxRd'),
            url: '/rd/etax',
            key: '/rd/etax',
            iconProps: {
              iconName: 'CityNext2',
              styles: {
                root: {
                  fontSize: 20,
                  color: '#106ebe',
                },
              },
            },
          },
        ],
      };
 */
  const [navLinks, setNavLinks] = useState(links);

  useEffect(() => {
    if (command === 'logout') {
      let confirmLogout = window.confirm('กรุณายืนยันการออกจากระบบ');

      if (confirmLogout) {
        instance.logout();
      } else {
        setCommand('');
      }
    } else if (command === 'user-profiles') {
      instance.loginRedirect(profileEditingRequest);
    } else if (command === 'password-reset') {
      instance.loginRedirect(passwordResetRequest);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [command]);

  //   useEffect(() => {
  //     console.log('Nav render useEffect!!');
  //     console.log(window.location.pathname);
  //     setKeyUrl(window.location.pathname);
  //   });

  useEffect(() => {
    
    console.log('companyDetailData: ', companyDetailData);

    // let _links = [];
/* 
    if (companyDetailData.billings?.type === 'subscription') {
       
        links.splice(7, 0, rdLinks);
    } 
 */
    setNavLinks(links);

    // console.log('_links: ', _links);
 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);
  

  const FabricNav = withRouter(({ history }) => (
    <div>
      <br />
      <Stack horizontalAlign="center">
        <PrimaryButton
          text={t('navigation.createDocument')}
          iconProps={{ iconName: 'Add' }}
          style={{
            width: '263px',
            height: '47px',
            fontSize: '20px',
            letterSpacing: '1.5px',
          }}
          menuProps={{
            items: [
              {
                key: 'section1',
                itemType: ContextualMenuItemType.Section,
                sectionProps: {
                  topDivider: true,
                  bottomDivider: true,
                  title: t('navigation.eTaxDocument'),

                  items: [
                    {
                      name: t('navigation.receipt-taxinvoice'),
                      url: '/etax/documents/receipts-taxinvoices',
                      key: '/etax/documents/receipts-taxinvoices',
                      iconProps: {
                        iconName: 'PageAdd',
                        styles: {
                          root: {
                            fontSize: 20,
                            color: '#106ebe',
                          },
                        },
                      },
                      onClick: (event, element) => {
                        history.push(element.url);
                      },
                    },
                    {
                      name: t('navigation.receipt-taxinvoice-abb'),
                      url: '/etax/documents/receipts-taxinvoices-abb',
                      key: '/etax/documents/receipts-taxinvoices-abb',
                      iconProps: {
                        iconName: 'PageAdd',
                        styles: {
                          root: {
                            fontSize: 20,
                            color: '#106ebe',
                          },
                        },
                      },
                      onClick: (event, element) => {
                        history.push(element.url);
                      },
                    },
                    {
                      name: t('navigation.receipt'),
                      url: '/etax/documents/receipts',
                      key: '/etax/documents/receipts',
                      iconProps: {
                        iconName: 'PageAdd',
                        styles: {
                          root: {
                            fontSize: 20,
                            color: '#106ebe',
                          },
                        },
                      },
                      onClick: (event, element) => {
                        history.push(element.url);
                      },
                    },
                    {
                      name: t('navigation.invoice-taxinvoice'),
                      url: '/etax/documents/invoices-taxinvoices',
                      key: '/etax/documents/invoices-taxinvoices',
                      iconProps: {
                        iconName: 'PageAdd',
                        styles: {
                          root: {
                            fontSize: 20,
                            color: '#106ebe',
                          },
                        },
                      },
                      onClick: (event, element) => {
                        history.push(element.url);
                      },
                    },
                    {
                      name: t('navigation.deliveryorder-taxinvoice'),
                      url: '/etax/documents/deliveryorders-taxinvoices',
                      key: '/etax/documents/deliveryorders-taxinvoices',
                      iconProps: {
                        iconName: 'PageAdd',
                        styles: {
                          root: {
                            fontSize: 20,
                            color: '#106ebe',
                          },
                        },
                      },
                      onClick: (event, element) => {
                        history.push(element.url);
                      },
                    },
                    {
                      name: t('navigation.taxinvoice'),
                      url: '/etax/documents/taxinvoices',
                      key: '/etax/documents/taxinvoices',
                      iconProps: {
                        iconName: 'PageAdd',
                        styles: {
                          root: {
                            fontSize: 20,
                            color: '#106ebe',
                          },
                        },
                      },
                      onClick: (event, element) => {
                        history.push(element.url);
                      },
                    },
                    {
                      name: t('navigation.debit-note'),
                      url: '/etax/documents/debit-notes',
                      key: '/etax/documents/debit-notes',
                      iconProps: {
                        iconName: 'PageAdd',
                        styles: {
                          root: {
                            fontSize: 20,
                            color: '#106ebe',
                          },
                        },
                      },
                      onClick: (event, element) => {
                        history.push(element.url);
                      },
                    },
                    {
                      name: t('navigation.credit-note'),
                      url: '/etax/documents/credit-notes',
                      key: '/etax/documents/credit-notes',
                      iconProps: {
                        iconName: 'PageAdd',
                        styles: {
                          root: {
                            fontSize: 20,
                            color: '#106ebe',
                          },
                        },
                      },
                      onClick: (event, element) => {
                        history.push(element.url);
                      },
                    },
                  ],
                },
              },
              {
                key: 'section2',
                itemType: ContextualMenuItemType.Section,
                sectionProps: {
                  title: t('navigation.businessDocument'),
                  items: [
                    {
                      name: t('navigation.quotation'),
                      url: '/documents/quotations',
                      key: '/documents/quotations',
                      iconProps: {
                        iconName: 'PageAdd',
                        styles: {
                          root: {
                            fontSize: 20,
                            color: '#106ebe',
                          },
                        },
                      },
                      onClick: (event, element) => {
                        history.push(element.url);
                      },
                    },
                    {
                      name: t('navigation.invoice'),
                      url: '/documents/invoices',
                      key: '/documents/invoices',
                      iconProps: {
                        iconName: 'PageAdd',
                        styles: {
                          root: {
                            fontSize: 20,
                            color: '#106ebe',
                          },
                        },
                      },
                      onClick: (event, element) => {
                        history.push(element.url);
                      },
                    },
                    {
                      name: t('navigation.billing-note'),
                      url: '/documents/billing-notes',
                      key: '/documents/billing-notes',
                      iconProps: {
                        iconName: 'PageAdd',
                        styles: {
                          root: {
                            fontSize: 20,
                            color: '#106ebe',
                          },
                        },
                      },
                      onClick: (event, element) => {
                        history.push(element.url);
                      },
                    },
                    {
                      name: t('navigation.deliveryorders'),
                      url: '/documents/deliveryorders',
                      key: '/documents/deliveryorders',
                      iconProps: {
                        iconName: 'PageAdd',
                        styles: {
                          root: {
                            fontSize: 20,
                            color: '#106ebe',
                          },
                        },
                      },
                      onClick: (event, element) => {
                        history.push(element.url);
                      },
                    },
                    {
                      name: t('navigation.withholdingTaxDocument'),
                      url: '/documents/withholding-taxs',
                      key: '/documents/withholding-taxs',
                      iconProps: {
                        iconName: 'PageAdd',
                        styles: {
                          root: {
                            fontSize: 20,
                            color: '#106ebe',
                          },
                        },
                      },
                      onClick: (event, element) => {
                        history.push(element.url);
                      },
                    },
                  ],
                },
              },
            ],
          }}
        />
      </Stack>
      <Nav
        onLinkClick={(event, element) => {
          event.preventDefault();

          if (element.key === 'logout') {
            setCommand(element.key);
          } else if (element.key === 'user-profiles') {
            setCommand(element.key);
          } else if (element.key === 'password-reset') {
            setCommand(element.key);
          } else if (element.key === 'user-manual') {
            setCommand(element.key);
            window.open(element.url);
          } else {
            // setKeyUrl(element.key);
            history.push(element.url);
          }
        }}
        groups={navLinks}
        styles={navigationStyles}
      />
    </div>
  ));

  return (
    <div>
      <FabricNav />
    </div>
  );
};

export default Navigation;
