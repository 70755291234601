import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import paginate from 'jw-paginate';

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  Selection,
  SelectionMode,
  Calendar,
  Icon,
  DateRangeType,
  Spinner,
  SpinnerSize,
  SearchBox,
  CommandBar,
  Stack,
  PrimaryButton,
  DefaultButton,
  TextField,
  ContextualMenu,
  CheckboxVisibility,
  Dialog,
  DialogFooter,
  DialogType,
  Checkbox,
  DatePicker,
  Text
} from '@fluentui/react';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

import { numberToTextEn, numberToTextTh } from '../../../helpers/BahtWord';

const API_URL = process.env.REACT_APP_API_URL;

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

const onFormatDate = (date, format) => {
  if (format === "CE") {
    return `${date.getDate()}`.padStart(2, "0") + "/" + `${date.getMonth() + 1}`.padStart(2, "0") + "/" + date.getFullYear();
  } else {
    return `${date.getDate()}`.padStart(2, "0") + "/" + `${date.getMonth() + 1}`.padStart(2, "0") + "/" + (date.getFullYear() + 543);
  }
};

const DynamicsBusinessCentral = () => {
  console.log('Home render!!!');

  const history = useHistory();

  const { t, i18n } = useTranslation();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [documents, setDocuments] = useState([]);
  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(false);
  const [pageData, setPageData] = useState({});
  const [pageSize, setPageSize] = useState(sessionStorage.getItem('ms-bc-pageSize') * 1 || 10);
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('ms-bc-currentPage') * 1 || 1);

  const [company, setCompany] = useState('');
  const [search, setSearch] = useState('');

  const percentVat = 7;
  const zeroVat = 0;
  const nonVat = -1;

  const percentVatText = '7%';
  const zeroVatText = '0%';
  // const nonVatText = '-'

  const dateType = { key: 'document', text: 'วันที่บนเอกสาร' };

  const [invoiceType, setInvoiceType] = useState(
    (sessionStorage.getItem('ms-bc-invoiceType-key') && {
      key: sessionStorage.getItem('ms-bc-invoiceType-key'),
      text: sessionStorage.getItem('ms-bc-invoiceType-text'),
    }) || {
      key: 'all',
      text: t('dynamicBc.allType'),
    }
  );

  const [allDocs, setAllDocs] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const isCompactMode = false;

  const [isCreateDocument, setIsCreateDocument] = useState(false);
  const [isCreateDocumentComplete, setIsCreateDocumentComplete] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        console.log('isCreateDocument: ', isCreateDocument);
        console.log('isCreateDocumentComplete: ', isCreateDocumentComplete);
        let _selectedItems = selection.getSelection();

        setSelectedItems(_selectedItems);
      },
    })
  );

  const [dialogConfirm, setDialogConfirm] = useState({
    isClick: false,
    header: '',
    message: '',
    onConfirm: null,
    onCancel: null,
  });

  const [createdBy, setCreatedBy] = useState('');

  const [dynamicsCompanies, setDynamicsCompanies] = useState([]);
  const [dynamicsCompany, setDynamicsCompany] = useState({ key: '', text: 'กำลังโหลด...' });

  const companyId = sessionStorage.getItem('companyId') || '';

  const [primarySort, setPrimarySort] = useState(sessionStorage.getItem('ms-bc-primarySort') || 'documentDate');
  const [isSortedDescendingDocumentDate, setIsSortedDescendingDocumentDate] = useState(() => {
    if (sessionStorage.getItem('ms-bc-sort-documentDate') === 'false') {
      return false;
    } else {
      return true;
    }
  });
  const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(() => {
    if (sessionStorage.getItem('ms-bc-sort-number')) {
      if (sessionStorage.getItem('ms-bc-sort-number') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingCustomerName, setIsSortedDescendingCustomerName] = useState(() => {
    if (sessionStorage.getItem('ms-bc-sort-customerName')) {
      if (sessionStorage.getItem('ms-bc-sort-customerName') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingVatTotal, setIsSortedDescendingVatTotal] = useState(() => {
    if (sessionStorage.getItem('ms-bc-sort-vatTotal')) {
      if (sessionStorage.getItem('ms-bc-sort-vatTotal') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingGrandTotal, setIsSortedDescendingGrandTotal] = useState(() => {
    if (sessionStorage.getItem('ms-bc-sort-grandTotal')) {
      if (sessionStorage.getItem('ms-bc-sort-grandTotal') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });

  const DayPickerStrings = {
    months: [
      t('dynamicBc.months01'),
      t('dynamicBc.months02'),
      t('dynamicBc.months03'),
      t('dynamicBc.months04'),
      t('dynamicBc.months05'),
      t('dynamicBc.months06'),
      t('dynamicBc.months07'),
      t('dynamicBc.months08'),
      t('dynamicBc.months09'),
      t('dynamicBc.months10'),
      t('dynamicBc.months11'),
      t('dynamicBc.months12'),
    ],

    shortMonths: [
      t('dynamicBc.shortMonths01'),
      t('dynamicBc.shortMonths02'),
      t('dynamicBc.shortMonths03'),
      t('dynamicBc.shortMonths04'),
      t('dynamicBc.shortMonths05'),
      t('dynamicBc.shortMonths06'),
      t('dynamicBc.shortMonths07'),
      t('dynamicBc.shortMonths08'),
      t('dynamicBc.shortMonths09'),
      t('dynamicBc.shortMonths10'),
      t('dynamicBc.shortMonths11'),
      t('dynamicBc.shortMonths12'),
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: t('dynamicBc.goToToday'),
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: t('dynamicBc.isRequiredErrorMessage'),

    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };

  // Processing dialog style
  const spinnerStyles = {
    circle: {
      height: 56,
      width: 56,
      borderWidth: 4,
    },
  };

  const getMonth = (goBack) => {
    let monthNames = [
      t('dynamicBc.months01'),
      t('dynamicBc.months02'),
      t('dynamicBc.months03'),
      t('dynamicBc.months04'),
      t('dynamicBc.months05'),
      t('dynamicBc.months06'),
      t('dynamicBc.months07'),
      t('dynamicBc.months08'),
      t('dynamicBc.months09'),
      t('dynamicBc.months10'),
      t('dynamicBc.months11'),
      t('dynamicBc.months12'),
    ];

    let d = new Date();

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + ' ' + year,
    };
  };

  const [queryMonth, setQueryMonth] = useState(
    (sessionStorage.getItem("ms-bc-queryMonth-key") && {
        key: sessionStorage.getItem("ms-bc-queryMonth-key"),
        text: sessionStorage.getItem("ms-bc-queryMonth-text"),
        ...(sessionStorage.getItem("ms-bc-queryMonth-type") ? { type: sessionStorage.getItem("ms-bc-queryMonth-type") } : {}),
    }) || { key: getMonth(0).year + "" + getMonth(0).key, text: t("dynamicBc.thisMonth") }
  );

  const [timeRangeDate, setTimeRangeDate] = useState(sessionStorage.getItem("ms-bc-queryMonth-type") ? true : false);
  const [fromDate, setFromDate] = useState(sessionStorage.getItem("ms-bc-fromDate") ? new Date(sessionStorage.getItem("ms-bc-fromDate")) : null);
  const [toDate, setToDate] = useState(sessionStorage.getItem("ms-bc-toDate") ? new Date(sessionStorage.getItem("ms-bc-toDate")) : null);
  const [timeRangeError, setTimeRangeError] = useState(false);

  const getFrom = (month, year) => {
    let _firstDay = new Date(year, month, 1);
    console.log('First day: ', formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getTo = (month, year) => {
    let _lastDay = new Date(year, month + 1, 1);
    console.log('Last day: ', formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const getFromDMY = (day, month, year) => {
    let _firstDay = new Date(year, month, day);
    console.log("First day: ", formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getToDMY = (day, month, year) => {
    let _lastDay = new Date(year, month, day + 1);
    console.log("Last day: ", formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const formatDate = (d) => {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const [from, setFrom] = useState(sessionStorage.getItem('ms-bc-from') || getFrom(getMonth(0).month, getMonth(0).year));
  const [to, setTo] = useState(sessionStorage.getItem('ms-bc-to') || getTo(getMonth(0).month, getMonth(0).year));

  const [date, setDate] = useState(sessionStorage.getItem('ms-bc-date') ? new Date(sessionStorage.getItem('ms-bc-date')) : new Date());

  let _pageDropdown = [];

  for (let i = 1; i <= 35; i++) {
    _pageDropdown.push({
      key: i,
      text: t('dynamicBc.page') + ' ' + i,
      iconProps: { iconName: 'Page' },
      onClick: () => {
        console.log('Selected page: ' + i);
        setCurrentPage(i);
      },
    });
  }

  const [pageDropdown, setPageDropdown] = useState(_pageDropdown);

  function getCompany_items() {
    let array = [];

    if (dynamicsCompanies !== undefined) {
      dynamicsCompanies.forEach((_company) => {
        array.push({
          key: _company.id,
          text: _company.name,
          iconProps: { iconName: 'CityNext2' },
          onClick: () => {
            setDynamicsCompany({
              key: _company.id,
              text: _company.name,
            });

            sessionStorage.setItem('ms-bc-company-key', _company.id);
            sessionStorage.setItem('ms-bc-company-text', _company.name);

            setLoadDataComplete(false);
            setItems([]);
            setGetNewDocuments(true);

            setSearch('');

            setCurrentPage(1);
          },
        });
      });
    }

    return array;
  }

  function getDocumentTypeText(key) {
    if (key === 'all') return t('dynamicBc.allType');
    if (key === 'Paid') return t('dynamicBc.paidType');
    if (key === 'Open') return t('dynamicBc.openType');
    if (key === 'Draft') return t('dynamicBc.draftType');
  }

  function getDateText(monthObject) {
    if (monthObject.type === 'dateRange') {
      if (i18n.language === 'th') {
        return onFormatDate(fromDate, 'BE') + ' - ' + onFormatDate(toDate, 'BE');
      }
      return onFormatDate(fromDate, 'CE') + ' - ' + onFormatDate(toDate, 'CE');
    }

    if (monthObject.text === 'เดือนนี้' || monthObject.text === 'This Month') return t('dynamicBc.thisMonth');

    let monthNames = [
      t('dynamicBc.months01'),
      t('dynamicBc.months02'),
      t('dynamicBc.months03'),
      t('dynamicBc.months04'),
      t('dynamicBc.months05'),
      t('dynamicBc.months06'),
      t('dynamicBc.months07'),
      t('dynamicBc.months08'),
      t('dynamicBc.months09'),
      t('dynamicBc.months10'),
      t('dynamicBc.months11'),
      t('dynamicBc.months12'),
    ];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    if (i18n.language === 'th') {
      _year = _year + 543;
    }

    return monthNames[_monthIndex] + ' ' + _year;
  }

  const commandBarItems = [
    {
      key: dynamicsCompany.key,
      text: dynamicsCompany.text,
      disabled: !loadDataComplete || isCreateDocument || search,
      iconProps: { iconName: 'CityNext2' },
      subMenuProps: {
        items: getCompany_items(),
      },
    },
    {
      key: invoiceType.key,
      text: getDocumentTypeText(invoiceType.key),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: [
          {
            key: 'all',
            text: t('dynamicBc.allType'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('เอกสารทุกประเภท');
              setInvoiceType({ key: 'all', text: t('dynamicBc.allType') });

              sessionStorage.setItem('ms-bc-invoiceType-key', 'all');
              sessionStorage.setItem('ms-bc-invoiceType-text', t('dynamicBc.allType'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');

              setPrimarySort('documentDate');
              setIsSortedDescendingDocumentDate(false);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);

              sessionStorage.setItem('ms-bc-primarySort', 'documentDate');
              sessionStorage.setItem('ms-bc-sort-documentDate', 'false');
              sessionStorage.setItem('ms-bc-sort-number', 'false');
              sessionStorage.setItem('ms-bc-sort-customerName', 'false');
              sessionStorage.setItem('ms-bc-sort-vatTotal', 'false');
              sessionStorage.setItem('ms-bc-sort-grandTotal', 'false');
            },
          },
          {
            key: 'Paid',
            text: t('dynamicBc.paidType'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('Paid');
              setInvoiceType({ key: 'Paid', text: t('dynamicBc.paidType') });

              sessionStorage.setItem('ms-bc-invoiceType-key', 'Paid');
              sessionStorage.setItem('ms-bc-invoiceType-text', t('dynamicBc.paidType'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');

              setPrimarySort('documentDate');
              setIsSortedDescendingDocumentDate(false);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);

              sessionStorage.setItem('ms-bc-primarySort', 'documentDate');
              sessionStorage.setItem('ms-bc-sort-documentDate', 'false');
              sessionStorage.setItem('ms-bc-sort-number', 'false');
              sessionStorage.setItem('ms-bc-sort-customerName', 'false');
              sessionStorage.setItem('ms-bc-sort-vatTotal', 'false');
              sessionStorage.setItem('ms-bc-sort-grandTotal', 'false');
            },
          },
          {
            key: 'Open',
            text: t('dynamicBc.openType'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('Open');
              setInvoiceType({ key: 'Open', text: t('dynamicBc.openType') });

              sessionStorage.setItem('ms-bc-invoiceType-key', 'Open');
              sessionStorage.setItem('ms-bc-invoiceType-text', t('dynamicBc.openType'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');

              setPrimarySort('documentDate');
              setIsSortedDescendingDocumentDate(false);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);

              sessionStorage.setItem('ms-bc-primarySort', 'documentDate');
              sessionStorage.setItem('ms-bc-sort-documentDate', 'false');
              sessionStorage.setItem('ms-bc-sort-number', 'false');
              sessionStorage.setItem('ms-bc-sort-customerName', 'false');
              sessionStorage.setItem('ms-bc-sort-vatTotal', 'false');
              sessionStorage.setItem('ms-bc-sort-grandTotal', 'false');
            },
          },
          {
            key: 'Draft',
            text: t('dynamicBc.draftType'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('Draft');
              setInvoiceType({ key: 'Draft', text: t('dynamicBc.draftType') });

              sessionStorage.setItem('ms-bc-invoiceType-key', 'Draft');
              sessionStorage.setItem('ms-bc-invoiceType-text', t('dynamicBc.draftType'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');

              setPrimarySort('documentDate');
              setIsSortedDescendingDocumentDate(false);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);

              sessionStorage.setItem('ms-bc-primarySort', 'documentDate');
              sessionStorage.setItem('ms-bc-sort-documentDate', 'false');
              sessionStorage.setItem('ms-bc-sort-number', 'false');
              sessionStorage.setItem('ms-bc-sort-customerName', 'false');
              sessionStorage.setItem('ms-bc-sort-vatTotal', 'false');
              sessionStorage.setItem('ms-bc-sort-grandTotal', 'false');
            },
          },
        ],
      },
    },
    {
      key: queryMonth.key,
      text: getDateText(queryMonth),
      iconProps: { iconName: 'Calendar' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: [
          {
            key: 'queryMonth',
          },
        ],
        onRenderMenuList: () => {
          return (
            <div style={{ width: 220 }}>
              <Stack styles={{ root: { padding: '12px 12px 0px 12px' } }}>
                <Checkbox
                  label={t('dynamicBc.timeRange')}
                  checked={timeRangeDate}
                  onChange={(e) => {
                    setTimeRangeDate((prev) => !prev);
                  }}
                />
              </Stack>
              {timeRangeDate ? (
                <Stack>
                <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                  <Stack styles={{ root: { width: '20%', justifyContent: 'center', alignItems: 'center' } }}>
                    <Text>{t('dynamicBc.fromRange')}</Text>
                  </Stack>
                  <Stack styles={{ root: { width: '80%', justifyContent: 'center' /* alignItems: 'center' */ } }}>
                    <DatePicker
                      styles={{ root: { width: '90%' } }}
                      placeholder={t('deliveryOrderTaxInvoice.pleaseSelect')}
                      strings={DayPickerStrings}
                      value={fromDate}
                      maxDate={toDate}
                      formatDate={onFormatDate}
                      onSelectDate={(_date) => {
                        setFromDate(_date);

                        setTimeRangeError(false);
                        if (Math.ceil(Math.abs(_date - toDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                      }}
                    />
                  </Stack>
                </Stack>
                <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                  <Stack styles={{ root: { width: '20%', justifyContent: 'center', alignItems: 'center' } }}>
                    <Text>{t('dynamicBc.toRange')}</Text>
                  </Stack>
                  <Stack styles={{ root: { width: '80%' /* alignItems: 'center' */ } }}>
                    <DatePicker
                      styles={{ root: { width: '90%' } }}
                      placeholder={t('deliveryOrderTaxInvoice.pleaseSelect')}
                      strings={DayPickerStrings}
                      value={toDate}
                      minDate={fromDate}
                      formatDate={onFormatDate}
                      onSelectDate={(_date) => {
                        setToDate(_date);

                        setTimeRangeError(false);
                        if (Math.ceil(Math.abs(_date - fromDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                      }}
                    />
                  </Stack>
                </Stack>
                {timeRangeError ? (
                  <Stack styles={{ root: { padding: '12px 0px 0px 12px' } }}>
                    <Text styles={{ root: { fontSize: 12, color: '#A4262C' } }}>{t('dynamicBc.timeRangeError')}</Text>
                  </Stack>
                ) : null}
                <Stack horizontal horizontalAlign="end" styles={{ root: { width: '92%', height: 52, padding: '10px 0px 10px 0px' } }}>
                  <PrimaryButton
                    text={t('dynamicBc.search')}
                    onClick={() => {
                      if (!timeRangeError && fromDate && toDate) {
                        setQueryMonth({
                          type: 'dateRange',
                          key: formatDate(fromDate) + ':' + formatDate(toDate),
                          text: formatDate(fromDate) + ' - ' + formatDate(toDate),
                        });

                        sessionStorage.setItem("ms-bc-queryMonth-type", "dateRange");
                        sessionStorage.setItem("ms-bc-queryMonth-key", formatDate(fromDate) + ":" + formatDate(toDate));
                        sessionStorage.setItem("ms-bc-queryMonth-text", formatDate(fromDate) + " - " + formatDate(toDate));

                        setFrom(getFromDMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                        setTo(getToDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                        sessionStorage.setItem("ms-bc-from", getFromDMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                        sessionStorage.setItem("ms-bc-to", getToDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                        sessionStorage.setItem("ms-bc-fromDate", fromDate.toISOString());
                        sessionStorage.setItem("ms-bc-toDate", toDate.toISOString());

                        setLoadDataComplete(false);
                        setItems([]);
                        setGetNewDocuments(true);

                        setCurrentPage(1);

                        setSearch('');

                        setPrimarySort('documentDate');
                        setIsSortedDescendingDocumentDate(true);
                        setIsSortedDescendingNumber(false);
                        setIsSortedDescendingCustomerName(false);
                        setIsSortedDescendingVatTotal(false);
                        setIsSortedDescendingGrandTotal(false);

                        sessionStorage.setItem('ms-bc-primarySort', 'documentDate');
                        sessionStorage.setItem('ms-bc-sort-documentDate', 'true');
                        sessionStorage.setItem('ms-bc-sort-number', 'false');
                        sessionStorage.setItem('ms-bc-sort-customerName', 'false');
                        sessionStorage.setItem('ms-bc-sort-vatTotal', 'false');
                        sessionStorage.setItem('ms-bc-sort-grandTotal', 'false');
                      }
                    }}
                    style={{ width: '125px', letterSpacing: '1px' }}
                  />
                </Stack>
              </Stack>
              ) : (
              <Calendar
                onSelectDate={(_date) => {
                  let monthNames = [
                    t('dynamicBc.months01'),
                    t('dynamicBc.months02'),
                    t('dynamicBc.months03'),
                    t('dynamicBc.months04'),
                    t('dynamicBc.months05'),
                    t('dynamicBc.months06'),
                    t('dynamicBc.months07'),
                    t('dynamicBc.months08'),
                    t('dynamicBc.months09'),
                    t('dynamicBc.months10'),
                    t('dynamicBc.months11'),
                    t('dynamicBc.months12'),
                  ];

                  setDate(_date);

                  sessionStorage.setItem('ms-bc-date', _date.toISOString());

                  let adjustDate = _date;

                  let year = adjustDate.getFullYear() + 543;

                  setQueryMonth({
                    key: adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'),
                    text: monthNames[adjustDate.getMonth()] + ' ' + year,
                  });

                  sessionStorage.setItem('ms-bc-queryMonth-key', adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'));
                  sessionStorage.setItem('ms-bc-queryMonth-text', monthNames[adjustDate.getMonth()] + ' ' + year);

                  setFrom(getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  setTo(getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  sessionStorage.setItem('ms-bc-from', getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  sessionStorage.setItem('ms-bc-to', getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  setLoadDataComplete(false);
                  setItems([]);
                  setGetNewDocuments(true);

                  setCurrentPage(1);

                  setSearch('');

                  setPrimarySort('documentDate');
                  setIsSortedDescendingDocumentDate(true);
                  setIsSortedDescendingNumber(false);
                  setIsSortedDescendingCustomerName(false);
                  setIsSortedDescendingVatTotal(false);
                  setIsSortedDescendingGrandTotal(false);

                  sessionStorage.setItem('ms-bc-primarySort', 'documentDate');
                  sessionStorage.setItem('ms-bc-sort-documentDate', 'true');
                  sessionStorage.setItem('ms-bc-sort-number', 'false');
                  sessionStorage.setItem('ms-bc-sort-customerName', 'false');
                  sessionStorage.setItem('ms-bc-sort-vatTotal', 'false');
                  sessionStorage.setItem('ms-bc-sort-grandTotal', 'false');
                }}
                isMonthPickerVisible={true}
                highlightSelectedMonth={true}
                isDayPickerVisible={false}
                dateRangeType={DateRangeType.Day}
                autoNavigateOnSelection={false}
                showGoToToday={false}
                value={date}
                strings={DayPickerStrings}
                showSixWeeksByDefault={true}
              />
              )}
            </div>
          );
        },
      },
    },
    {
      key: pageSize,
      text: pageSize + ' ' + t('dynamicBc.documentPerPage'),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'NumberedList' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: [
          {
            key: 10,
            text: '10 ' + t('dynamicBc.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('10 เอกสาร/หน้า');
              setPageSize(10);
              sessionStorage.setItem('ms-bc-pageSize', 10);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch('');
            },
          },
          {
            key: 20,
            text: '20 ' + t('dynamicBc.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('20 เอกสาร/หน้า');
              setPageSize(20);
              sessionStorage.setItem('ms-bc-pageSize', 20);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch('');
            },
          },
          {
            key: 30,
            text: '30 ' + t('dynamicBc.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('30 เอกสาร/หน้า');
              setPageSize(30);
              sessionStorage.setItem('ms-bc-pageSize', 30);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch('');
            },
          },
          {
            key: 40,
            text: '40 ' + t('dynamicBc.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('40 เอกสาร/หน้า');
              setPageSize(40);
              sessionStorage.setItem('ms-bc-pageSize', 40);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch('');
            },
          },
          {
            key: 50,
            text: '50 ' + t('dynamicBc.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('50 เอกสาร/หน้า');
              setPageSize(50);
              sessionStorage.setItem('ms-bc-pageSize', 50);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch('');
            },
          },
          {
            key: 100,
            text: '100 ' + t('dynamicBc.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('100 เอกสาร/หน้า');
              setPageSize(100);
              sessionStorage.setItem('ms-bc-pageSize', 100);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch('');
            },
          },
        ],
      },
    },
    {
      key: currentPage,
      text: t('dynamicBc.page') + ' ' + currentPage,
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: pageDropdown,
      },
    },
    {
      key: 'refresh',
      text: t('dynamicBc.refresh'),
      iconProps: { iconName: 'Refresh' },
      disabled: !loadDataComplete || isCreateDocument || search,
      onClick: () => {
        console.log('Refresh');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
      },
    },
  ];

  const overflowItems = [
    {
      key: 'disconnect',
      text: t('dynamicBc.disconnect'),
      onClick: () => {
        console.log('dynamics disconnect!');

        setDialogConfirm((prev) => ({
          ...prev,
          isClick: true,
          header: t('dynamicBc.disconnect'),
          message: t('dynamicBc.disconnectConfirm'),
          onConfirm: () => {
            disconnect(true);
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            }));
          },
          onCancel: () =>
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            })),
        }));
      },
      iconProps: { iconName: 'PlugDisconnected' },
    //   disabled: !loadDataComplete || isCreateDocument || search,
    },
  ];

  const createDocument = async (documentType, item) => {
    setIsLoading(true);

    let documentPath = '/etax/documents/receipts/confirm';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        documentPath = '/etax/documents/receipts-taxinvoices/confirm';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        documentPath = '/etax/documents/receipts-taxinvoices-abb/confirm';
        break;
      case 'RECEIPT':
        documentPath = '/etax/documents/receipts/confirm';
        break;
      case 'INVOICE-TAXINVOICE':
        documentPath = '/etax/documents/invoices-taxinvoices/confirm';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        documentPath = '/etax/documents/deliveryorders-taxinvoices/confirm';
        break;
      case 'TAXINVOICE':
        documentPath = '/etax/documents/taxinvoices/confirm';
        break;
      default:
        break;
    }

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        const [responseCustomer, responseInvoiceDetail] = await Promise.all([
          axios
            .get(API_URL + '/sources/dynamics/bc/customers', {
              headers: {
                'Authorization': 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId
              },
              params: {
                companyId: dynamicsCompany.key,
                customerId: item.customerId,
              },
            })
            .catch((error) => console.log(error)),

          axios
            .get(API_URL + '/sources/dynamics/bc/salesinvoicelines', {
              headers: {
                'Authorization': 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId
              },
              params: {
                companyId: dynamicsCompany.key,
                invoiceId: item.key,
              },
            })
            .catch((error) => console.log(error)),
        ]);

        const _customerInfo = responseCustomer.data;
        const invoiceLine = responseInvoiceDetail.data;

        let d = item.documentDate.split('-');

        let _date = new Date(d[2] + '/' + d[1] + '/' + d[0]);

        let _dateBE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + (_date.getFullYear() + 543);
        let _dateCE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + _date.getFullYear();

        let dueD = item.dueDate.split('-');

        let _dueDate = new Date(dueD[2] + '/' + dueD[1] + '/' + dueD[0]);

        let _dueDateBE =
          `${_dueDate.getDate()}`.padStart(2, '0') + '/' + `${_dueDate.getMonth() + 1}`.padStart(2, '0') + '/' + (_dueDate.getFullYear() + 543);
        let _dueDateCE =
          `${_dueDate.getDate()}`.padStart(2, '0') + '/' + `${_dueDate.getMonth() + 1}`.padStart(2, '0') + '/' + _dueDate.getFullYear();

        let _taxId = 'N/A';
        let _taxNumberType = 'OTHR';
        let _branchNumber = '';
        let _branchText = '';

        if (_customerInfo.taxRegistrationNumber) {
          _taxId = _customerInfo.taxRegistrationNumber;
          _taxNumberType = 'TXID';
          _branchNumber = '00000';
          _branchText = 'Head Office';
        }

        let _customer = {
          addressLineOne: item.customerAddress,
          addressLineTwo: '',
          branchNumber: _branchNumber,
          branchText: _branchText,
          email: item.customerEmail,
          name: item.customerName,
          phone: item.customerPhoneNumber,
          phoneCountryCode: '+66',
          postcode: item.customerPostcode ? item.customerPostcode : '00000',
          taxId: _taxId,
          taxNumberType: _taxNumberType,
          language: 'th',
          unstructure: true,
        };

        let _items = [];

        for (let i = 0; i < invoiceLine.length; i++) {
          let _percentVat = zeroVat;
          let _percentVatText = zeroVatText;
          if (invoiceLine[i].taxPercent > 0) {
            _percentVat = percentVat;
            _percentVatText = percentVatText;
          }

          _items.push({
            number: i + 1 + '',
            description: invoiceLine[i].description,
            quantity: invoiceLine[i].quantity,
            unitCode: '',
            unitName: '-',
            price: invoiceLine[i].unitPrice,
            discount: invoiceLine[i].discountAmount,
            total: invoiceLine[i].amountExcludingTax,
            percentVat: _percentVat,
            percentVatText: _percentVatText,
          });
        }

        let _vatPriceTotal = 0;
        let _zeroVatPriceTotal = 0;
        let _nonVatPriceTotal = 0;

        _items.forEach((item) => {
          if (item.percentVat === percentVat) {
            _vatPriceTotal = _vatPriceTotal + item.total;
          } else if (item.percentVat === zeroVat) {
            _zeroVatPriceTotal = _zeroVatPriceTotal + item.total;
          } else if (item.percentVat === nonVat) {
            _nonVatPriceTotal = _nonVatPriceTotal + item.total;
          }
        });

        history.push({
          pathname: documentPath,
          state: {
            number: item.number,
            reference: '',
            company: {
              ...company,
              includeVat: item.includeVat,
            },
            customer: _customer,
            date: _date,
            dateBE: _dateBE,
            dateCE: _dateCE,
            dueDate: _dueDate,
            dueDateBE: _dueDateBE,
            dueDateCE: _dueDateCE,
            items: _items,
            includeVat: item.includeVat,
            discount: item.discount * 1,
            amountTotal: item.amountTotal * 1,
            vatTotal: item.vatTotal * 1,
            grandTotal: item.grandTotal * 1,
            nonVatPriceTotal: _nonVatPriceTotal,
            zeroVatPriceTotal: _zeroVatPriceTotal,
            vatPriceTotal: _vatPriceTotal,
            percentVat: percentVat,
            receivedBy: item.receivedBy,
            reIssue: false,
            backUrl: '/etax/sources/dynamics/business-central',
            grandTotalTH: numberToTextTh(item.grandTotal * 1),
            grandTotalEN: numberToTextEn(item.grandTotal * 1),
          },
        });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  useEffect(() => {
    console.log('userEffect initial call!');

    setIsLoading(true);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              'Authorization': 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId,
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data[0]) {
                if (companyId && response.data.find(_company => _company.id === companyId)) {
                  const _company = response.data.find(_company => _company.id === companyId)
                
                  if (!_company.includeVat) {
                    _company.includeVat = true;
                  }
  
                  setCompany(_company);
                } else {
                  if (!response.data[0].includeVat) {
                    response.data[0].includeVat = true;
                  }
  
                  setCompany(response.data[0]);
                }

                
              } else {
                history.push({
                  pathname: '/etax/companies/profiles',
                  state: {},
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );

        axios
          .get(API_URL + '/sources/dynamics/bc/companies', {
            headers: {
              'Authorization': 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId
            },
          })
          .then(
            (response) => {
              console.log('Dynamics companies: ', response);

              if (response.data && response.data.length > 0) {
                console.log('Dynaics companies: ', response.data);

                setDynamicsCompanies(response.data);

                setDynamicsCompany({
                  key: response.data[0].id,
                  text: response.data[0].name,
                });

                setGetNewDocuments(true);
              } else {
                disconnect(true);
              }

              setIsLoading(false);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getNewDocuments) {
      if (search) {
        const searchInvoices = async () => {
          instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then(async (tokenResponse) => {
              await axios
                .get(API_URL + '/sources/dynamics/bc/salesinvoices/search', {
                  headers: {
                    'Authorization': 'Bearer ' + tokenResponse.accessToken,
                    'cid': companyId
                  },
                  params: {
                    companyId: dynamicsCompany.key,
                    search: search,
                  },
                })
                .then(async (response) => {
                  if (response.data.length) {
                    const _number = response.data[0].number;

                    await axios
                      .get(API_URL + `/documents?number=${_number}`, {
                        headers: {
                          'Authorization': 'Bearer ' + tokenResponse.accessToken,
                          'Content-Type': 'application/json',
                          'cid': companyId
                        },
                      })
                      .then((responseAllDocs) => {
                        let invoice_list = response.data;
                        let total_items = response.data.length;

                        if (primarySort === 'documentDate') {
                          if (isSortedDescendingDocumentDate) {
                            invoice_list.sort(function (a, b) {
                              var aa = a.invoiceDate,
                                bb = b.invoiceDate;
                              return aa < bb ? 1 : aa > bb ? -1 : 0;
                            });
                          } else {
                            invoice_list.sort(function (a, b) {
                              var aa = a.invoiceDate,
                                bb = b.invoiceDate;
                              return aa < bb ? -1 : aa > bb ? 1 : 0;
                            });
                          }
                        } else if (primarySort === 'number') {
                          if (isSortedDescendingNumber) {
                            invoice_list.sort((a, b) => {
                              if (b.number < a.number) {
                                return -1;
                              }
                              if (b.number > a.number) {
                                return 1;
                              }
                              return 0;
                            });
                          } else {
                            invoice_list.sort((a, b) => {
                              if (a.number < b.number) {
                                return -1;
                              }
                              if (a.number > b.number) {
                                return 1;
                              }
                              return 0;
                            });
                          }
                        } else if (primarySort === 'customerName') {
                          if (isSortedDescendingCustomerName) {
                            invoice_list.sort((a, b) => {
                              if (b.customerName < a.customerName) {
                                return -1;
                              }
                              if (b.customerName > a.customerName) {
                                return 1;
                              }
                              return 0;
                            });
                          } else {
                            invoice_list.sort((a, b) => {
                              if (a.customerName < b.customerName) {
                                return -1;
                              }
                              if (a.customerName > b.customerName) {
                                return 1;
                              }
                              return 0;
                            });
                          }
                        } else if (primarySort === 'vatTotal') {
                          if (isSortedDescendingVatTotal) {
                            invoice_list.sort(function (a, b) {
                              return b.totalTaxAmount - a.totalTaxAmount;
                            });
                          } else {
                            invoice_list.sort(function (a, b) {
                              return a.totalTaxAmount - b.totalTaxAmount;
                            });
                          }
                        } else if (primarySort === 'grandTotal') {
                          if (isSortedDescendingGrandTotal) {
                            invoice_list.sort(function (a, b) {
                              return b.totalAmountIncludingTax - a.totalAmountIncludingTax;
                            });
                          } else {
                            invoice_list.sort(function (a, b) {
                              return a.totalAmountIncludingTax - b.totalAmountIncludingTax;
                            });
                          }
                        }

                        let _pageDropdown = [];

                        for (let i = 1; i <= Math.ceil(total_items / pageSize); i++) {
                          _pageDropdown.push({
                            key: i,
                            text: 'หน้า ' + i,
                            iconProps: { iconName: 'Page' },
                            onClick: () => {
                              console.log('Selected page: ' + i);
                              setCurrentPage(i);
                            },
                          });
                        }

                        setPageDropdown(_pageDropdown);

                        if (currentPage > Math.ceil(total_items / pageSize)) {
                          setCurrentPage(1);
                        }

                        setPageData(paginate(total_items, 1, pageSize, 10));
                        setDocuments(invoice_list);

                        const _docs = [];

                        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, invoice_list.length); i++) {
                          let _documentDate = invoice_list[i].invoiceDate.split('-').reverse().join('-');
                          let _dueDate = invoice_list[i].dueDate.split('-').reverse().join('-');

                          let _address = '';

                          if (invoice_list[i].billToAddressLine1) {
                            if (invoice_list[i].billToAddressLine1) {
                              _address = _address + invoice_list[i].billToAddressLine1;
                            }

                            if (invoice_list[i].billToAddressLine2) {
                              _address = _address + ' ' + invoice_list[i].billToAddressLine2;
                            }

                            if (invoice_list[i].billToCity) {
                              _address = _address + ' ' + invoice_list[i].billToCity;
                            }

                            if (invoice_list[i].billToState) {
                              _address = _address + ' ' + invoice_list[i].billToState;
                            }

                            if (invoice_list[i].billToCountry) {
                              _address = _address + ' ' + invoice_list[i].billToCountry;
                            }
                          }

                          _docs.push({
                            key: invoice_list[i].id,
                            documentDate: _documentDate,
                            dueDate: _dueDate,
                            number: invoice_list[i].number,
                            customerId: invoice_list[i].customerId,
                            customerName: invoice_list[i].customerName,
                            customerAddress: _address,
                            customerEmail: invoice_list[i].email,
                            customerPhoneNumber: invoice_list[i].phoneNumber,
                            amountTotal: invoice_list[i].totalAmountExcludingTax,
                            vatTotal: invoice_list[i].totalTaxAmount,
                            grandTotal: invoice_list[i].totalAmountIncludingTax,
                            customerPostcode: invoice_list[i].billToPostCode,
                            discount: invoice_list[i].discountAmount,
                            includeVat: invoice_list[i].pricesIncludeTax,
                            receivedBy: invoice_list[i].salesperson,
                            etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.number === invoice_list[i].number),
                          });
                        }

                        setItems(_docs);

                        setLoadDataComplete(true);
                        setGetNewDocuments(false);
                        setIsCreateDocumentComplete(false);
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  } else {
                    setItems([]);

                    setLoadDataComplete(true);
                    setGetNewDocuments(false);
                    setIsCreateDocumentComplete(false);
                  }
                });
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
            //   instance.acquireTokenRedirect(silentRequest);
            });
        };

        searchInvoices();
      } else {
        const listInvoices = async () => {
          instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then(async (tokenResponse) => {
              const [response, responseAllDocs] = await Promise.all([
                axios
                  .get(API_URL + '/sources/dynamics/bc/salesinvoices', {
                    headers: {
                      'Authorization': 'Bearer ' + tokenResponse.accessToken,
                      'cid': companyId
                    },
                    params: {
                      companyId: dynamicsCompany.key,
                      status: invoiceType.key,
                      from: from,
                      to: to,
                    },
                  })
                  .catch((error) => console.log(error)),

                axios
                  .get(API_URL + '/etax/documents/ALL/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                    headers: {
                      'Authorization': 'Bearer ' + tokenResponse.accessToken,
                      'cid': companyId
                    },
                  })
                  .catch((error) => console.log(error)),
              ]);

              setAllDocs(responseAllDocs.data);

              let invoice_list = response.data;
              let total_items = response.data.length;

              console.log('invoice_list', invoice_list);

              if (primarySort === 'documentDate') {
                if (isSortedDescendingDocumentDate) {
                  invoice_list.sort(function (a, b) {
                    var aa = a.invoiceDate,
                      bb = b.invoiceDate;
                    return aa < bb ? 1 : aa > bb ? -1 : 0;
                  });
                } else {
                  invoice_list.sort(function (a, b) {
                    var aa = a.invoiceDate,
                      bb = b.invoiceDate;
                    return aa < bb ? -1 : aa > bb ? 1 : 0;
                  });
                }
              } else if (primarySort === 'number') {
                if (isSortedDescendingNumber) {
                  invoice_list.sort((a, b) => {
                    if (b.number < a.number) {
                      return -1;
                    }
                    if (b.number > a.number) {
                      return 1;
                    }
                    return 0;
                  });
                } else {
                  invoice_list.sort((a, b) => {
                    if (a.number < b.number) {
                      return -1;
                    }
                    if (a.number > b.number) {
                      return 1;
                    }
                    return 0;
                  });
                }
              } else if (primarySort === 'customerName') {
                if (isSortedDescendingCustomerName) {
                  invoice_list.sort((a, b) => {
                    if (b.customerName < a.customerName) {
                      return -1;
                    }
                    if (b.customerName > a.customerName) {
                      return 1;
                    }
                    return 0;
                  });
                } else {
                  invoice_list.sort((a, b) => {
                    if (a.customerName < b.customerName) {
                      return -1;
                    }
                    if (a.customerName > b.customerName) {
                      return 1;
                    }
                    return 0;
                  });
                }
              } else if (primarySort === 'vatTotal') {
                if (isSortedDescendingVatTotal) {
                  invoice_list.sort(function (a, b) {
                    return b.totalTaxAmount - a.totalTaxAmount;
                  });
                } else {
                  invoice_list.sort(function (a, b) {
                    return a.totalTaxAmount - b.totalTaxAmount;
                  });
                }
              } else if (primarySort === 'grandTotal') {
                if (isSortedDescendingGrandTotal) {
                  invoice_list.sort(function (a, b) {
                    return b.totalAmountIncludingTax - a.totalAmountIncludingTax;
                  });
                } else {
                  invoice_list.sort(function (a, b) {
                    return a.totalAmountIncludingTax - b.totalAmountIncludingTax;
                  });
                }
              }

              let _pageDropdown = [];

              for (let i = 1; i <= Math.ceil(total_items / pageSize); i++) {
                _pageDropdown.push({
                  key: i,
                  text: 'หน้า ' + i,
                  iconProps: { iconName: 'Page' },
                  onClick: () => {
                    console.log('Selected page: ' + i);
                    setCurrentPage(i);
                  },
                });
              }

              setPageDropdown(_pageDropdown);

              if (currentPage > Math.ceil(total_items / pageSize)) {
                setCurrentPage(1);
              }

              setPageData(paginate(total_items, 1, pageSize, 10));
              setDocuments(invoice_list);

              const _docs = [];

              for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, invoice_list.length); i++) {
                let _documentDate = invoice_list[i].invoiceDate.split('-').reverse().join('-');
                let _dueDate = invoice_list[i].dueDate.split('-').reverse().join('-');

                let _address = '';

                if (invoice_list[i].billToAddressLine1) {
                  if (invoice_list[i].billToAddressLine1) {
                    _address = _address + invoice_list[i].billToAddressLine1;
                  }

                  if (invoice_list[i].billToAddressLine2) {
                    _address = _address + ' ' + invoice_list[i].billToAddressLine2;
                  }

                  if (invoice_list[i].billToCity) {
                    _address = _address + ' ' + invoice_list[i].billToCity;
                  }

                  if (invoice_list[i].billToState) {
                    _address = _address + ' ' + invoice_list[i].billToState;
                  }

                  if (invoice_list[i].billToCountry) {
                    _address = _address + ' ' + invoice_list[i].billToCountry;
                  }
                }

                _docs.push({
                  key: invoice_list[i].id,
                  documentDate: _documentDate,
                  dueDate: _dueDate,
                  number: invoice_list[i].number,
                  customerId: invoice_list[i].customerId,
                  customerName: invoice_list[i].customerName,
                  customerAddress: _address,
                  customerEmail: invoice_list[i].email,
                  customerPhoneNumber: invoice_list[i].phoneNumber,
                  amountTotal: invoice_list[i].totalAmountExcludingTax,
                  vatTotal: invoice_list[i].totalTaxAmount,
                  grandTotal: invoice_list[i].totalAmountIncludingTax,
                  customerPostcode: invoice_list[i].billToPostCode,
                  discount: invoice_list[i].discountAmount,
                  includeVat: invoice_list[i].pricesIncludeTax,
                  receivedBy: invoice_list[i].salesperson,
                  etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.number === invoice_list[i].number),
                });
              }

              setItems(_docs);

              setLoadDataComplete(true);
              setGetNewDocuments(false);
              setIsCreateDocumentComplete(false);
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
            //   instance.acquireTokenRedirect(silentRequest);
            });
        };

        listInvoices();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments]);

  useEffect(() => {
    if (documents.length > 0) {
      const _docs = [];

      for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
        let _documentDate = documents[i].invoiceDate.split('-').reverse().join('-');
        let _dueDate = documents[i].dueDate.split('-').reverse().join('-');

        let _address = '';

        if (documents[i].billToAddressLine1) {
          if (documents[i].billToAddressLine1) {
            _address = _address + documents[i].billToAddressLine1;
          }

          if (documents[i].billToAddressLine2) {
            _address = _address + ' ' + documents[i].billToAddressLine2;
          }

          if (documents[i].billToCity) {
            _address = _address + ' ' + documents[i].billToCity;
          }

          if (documents[i].billToState) {
            _address = _address + ' ' + documents[i].billToState;
          }

          if (documents[i].billToCountry) {
            _address = _address + ' ' + documents[i].billToCountry;
          }
        }

        _docs.push({
          key: documents[i].id,
          documentDate: _documentDate,
          dueDate: _dueDate,
          number: documents[i].number,
          customerId: documents[i].customerId,
          customerName: documents[i].customerName,
          customerAddress: _address,
          customerEmail: documents[i].email,
          customerPhoneNumber: documents[i].phoneNumber,
          amountTotal: documents[i].totalAmountExcludingTax,
          vatTotal: documents[i].totalTaxAmount,
          grandTotal: documents[i].totalAmountIncludingTax,
          customerPostcode: documents[i].billToPostCode,
          discount: documents[i].discountAmount,
          includeVat: documents[i].pricesIncludeTax,
          receivedBy: documents[i].salesperson,
          etaxCreated: allDocs.some((_allDocs) => _allDocs.data.number === documents[i].number),
        });
      }

      setItems(_docs);
    }

    sessionStorage.setItem('ms-bc-currentPage', currentPage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    console.log('isCreateDocumentComplete useEffect: ', isCreateDocumentComplete);

    if (loadDataComplete && !isCreateDocument) {
      let _items = selection.getItems();
      console.log('_items: ', _items);

      let _selectedItems = selection.getSelection();

      if (isCreateDocumentComplete) {
        setIsCreateDocumentComplete(false);

        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status !== '') {
            _items[i].status = '';
          }
        }
      } else {
        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status !== '') {
            _items[i].status = '';
          }
        }

        for (let i = 0; i < _selectedItems.length; i++) {
          console.log('select item key: ', _selectedItems[i].key);

          for (let j = 0; j < _items.length; j++) {
            if (_items[j].key === _selectedItems[i].key) {
              console.log('set pending item key: ', _items[j].key);
              _items[j].status = 'pending';
              console.log('_items: ', _items);
            }
          }
        }
      }

      setItems(_items);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  const columns = [
    {
      key: 'column1',
      name: t('dynamicBc.documentDate'),
      fieldName: 'documentDate',
      minWidth: 70,
      maxWidth: 125,
      isResizable: true,
      isSorted: primarySort === 'documentDate' ? true : false,
      isSortedDescending: isSortedDescendingDocumentDate,
      isSortedAscending: !isSortedDescendingDocumentDate,
      onColumnClick: () => {
        console.log('Sort document createTime!');
        setPrimarySort('documentDate');
        setIsSortedDescendingDocumentDate(!isSortedDescendingDocumentDate);

        sessionStorage.setItem('ms-bc-primarySort', 'documentDate');
        sessionStorage.setItem('ms-bc-sort-documentDate', !isSortedDescendingDocumentDate === false ? 'false' : 'true');

        sessionStorage.removeItem('ms-bc-sort-createdTime');
        sessionStorage.removeItem('ms-bc-sort-number');
        sessionStorage.removeItem('ms-bc-sort-customerName');
        sessionStorage.removeItem('ms-bc-sort-vatTotal');
        sessionStorage.removeItem('ms-bc-sort-grandTotal');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'number',
      onRender: (item) => {
        return <span>{item.documentDate}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column2',
      name: t('dynamicBc.number'),
      fieldName: 'number',
      minWidth: 100,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'number' ? true : false,
      isSortedDescending: isSortedDescendingNumber,
      isSortedAscending: !isSortedDescendingNumber,
      onColumnClick: () => {
        console.log('Sort document number!');
        setPrimarySort('number');
        setIsSortedDescendingNumber(!isSortedDescendingNumber);

        sessionStorage.setItem('ms-bc-primarySort', 'number');
        sessionStorage.setItem('ms-bc-sort-number', !isSortedDescendingNumber === false ? 'false' : 'true');

        sessionStorage.removeItem('ms-bc-sort-createdTime');
        sessionStorage.removeItem('ms-bc-sort-documentDate');
        sessionStorage.removeItem('ms-bc-sort-customerName');
        sessionStorage.removeItem('ms-bc-sort-vatTotal');
        sessionStorage.removeItem('ms-bc-sort-grandTotal');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.number}</span>;
      },
      isPadded: true,
    },

    {
      key: 'column3',
      name: t('dynamicBc.customerName'),
      fieldName: 'customerName',
      minWidth: 80,
      maxWidth: 220,
      isResizable: true,
      isSorted: primarySort === 'customerName' ? true : false,
      isSortedDescending: isSortedDescendingCustomerName,
      isSortedAscending: !isSortedDescendingCustomerName,
      onColumnClick: () => {
        console.log('Sort document customerName!');
        setPrimarySort('customerName');
        setIsSortedDescendingCustomerName(!isSortedDescendingCustomerName);

        sessionStorage.setItem('ms-bc-primarySort', 'customerName');
        sessionStorage.setItem('ms-bc-sort-customerName', !isSortedDescendingCustomerName === false ? 'false' : 'true');

        sessionStorage.removeItem('ms-bc-sort-createdTime');
        sessionStorage.removeItem('ms-bc-sort-documentDate');
        sessionStorage.removeItem('ms-bc-sort-number');
        sessionStorage.removeItem('ms-bc-sort-vatTotal');
        sessionStorage.removeItem('ms-bc-sort-grandTotal');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerName}</span>;
      },
      isPadded: true,
    },

    {
      key: 'column4',
      name: t('dynamicBc.customerAddress'),
      fieldName: 'name',
      minWidth: 100,
      maxWidth: 270,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: '',
      isCollapsible: true,
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerAddress}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column5',
      name: t('dynamicBc.vatTotal'),
      fieldName: 'vatTotal',
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      isSorted: primarySort === 'vatTotal' ? true : false,
      isSortedDescending: isSortedDescendingVatTotal,
      isSortedAscending: !isSortedDescendingVatTotal,
      onColumnClick: () => {
        console.log('Sort document vatTotal!');
        setPrimarySort('vatTotal');
        setIsSortedDescendingVatTotal(!isSortedDescendingVatTotal);

        sessionStorage.setItem('ms-bc-primarySort', 'vatTotal');
        sessionStorage.setItem('ms-bc-sort-vatTotal', !isSortedDescendingVatTotal === false ? 'false' : 'true');

        sessionStorage.removeItem('ms-bc-sort-createdTime');
        sessionStorage.removeItem('ms-bc-sort-number');
        sessionStorage.removeItem('ms-bc-sort-customerName');
        sessionStorage.removeItem('ms-bc-sort-documentDate');
        sessionStorage.removeItem('ms-bc-sort-grandTotal');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {item.vatTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column6',
      name: t('dynamicBc.grandTotal'),
      fieldName: 'grandTotal',
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      isSorted: primarySort === 'grandTotal' ? true : false,
      isSortedDescending: isSortedDescendingGrandTotal,
      isSortedAscending: !isSortedDescendingGrandTotal,
      onColumnClick: () => {
        console.log('Sort document grandTotal!');
        setPrimarySort('grandTotal');
        setIsSortedDescendingGrandTotal(!isSortedDescendingGrandTotal);

        sessionStorage.setItem('ms-bc-primarySort', 'grandTotal');
        sessionStorage.setItem('ms-bc-sort-grandTotal', !isSortedDescendingGrandTotal === false ? 'false' : 'true');

        sessionStorage.removeItem('ms-bc-sort-createdTime');
        sessionStorage.removeItem('ms-bc-sort-customerName');
        sessionStorage.removeItem('ms-bc-sort-number');
        sessionStorage.removeItem('ms-bc-sort-vatTotal');
        sessionStorage.removeItem('ms-bc-sort-documentDate');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {item.grandTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column7',
      name: 'Leceipt',
      fieldName: 'etaxCreated',
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        if (item.etaxCreated) {
          return (
            <div style={{ textAlign: 'center' }}>
              <Icon iconName="SkypeCircleCheck" style={{ fontSize: '20px', color: 'green' }} />
            </div>
          );
        }
      },
    },
    {
      key: 'column8',
      name: '',
      fieldName: 'command',
      minWidth: 120,
      maxWidth: 120,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        if (isCreateDocument || isCreateDocumentComplete) {
          if (item.status === 'pending') {
            return <span style={{ color: '#106EBE' }}>{t('dynamicBc.pending')}</span>;
          } else if (item.status === 'processing') {
            return <Spinner label={t('dynamicBc.processing')} labelPosition="right" style={{ marginLeft: '0px' }} />;
          } else if (item.status === 'successful') {
            return <span style={{ color: 'green' }}>{t('dynamicBc.success')}</span>;
          } else if (item.status === 'fail') {
            return <span style={{ color: 'red' }}>{t('dynamicBc.fail')}</span>;
          }
        } else {
          return (
            <span data-selection-disabled={true}>
              <DefaultButton
                text={t('dynamicBc.create')}
                iconProps={{ iconName: 'Add', style: { color: '#106ebe' } }}
                menuProps={{
                  items: [
                    {
                      key: 'RECEIPT-TAXINVOICE',
                      text: t('dynamicBc.receipt-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('RECEIPT-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'RECEIPT-TAXINVOICE-ABB',
                      text: t('dynamicBc.receipt-taxinvoice-abb'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('RECEIPT-TAXINVOICE-ABB', item);
                      },
                    },
                    {
                      key: 'RECEIPT',
                      text: t('dynamicBc.receipt'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('RECEIPT', item);
                      },
                    },
                    {
                      key: 'INVOICE-TAXINVOICE',
                      text: t('dynamicBc.invoice-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('INVOICE-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'DELIVERYORDER-TAXINVOICE',
                      text: t('dynamicBc.deliveryorder-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('DELIVERYORDER-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'TAXINVOICE',
                      text: t('dynamicBc.taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('TAXINVOICE', item);
                      },
                    },
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
            </span>
          );
        }
      },
    },
  ];

  const disconnect = (_confirm) => {
    let confirm = _confirm;

    if (confirm) {
      setIsLoading(true);

      instance.acquireTokenSilent({ ...silentRequest, account: account }).then(
        (tokenResponse) => {
          axios
            .patch(
              API_URL + '/sources',
              {
                source: 'dynamics-bc',
                replace: '/disabled',
                value: true,
              },
              {
                headers: {
                  'Authorization': 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId
                },
              }
            )
            .then((response) => {
              console.log(response);

              history.push({
                pathname: '/etax/sources/dynamics/business-central/connect',
                state: {},
              });

              setIsLoading(false);
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
              instance.acquireTokenRedirect(silentRequest);
            });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  const createSelectedDocuments = async (documentType) => {
    let _selectionItems = selection.getItems();

    let postUrl = '';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/receipts-taxinvoices/pdf-xml';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        postUrl = API_URL + '/etax/documents/receipts-taxinvoices-abb/pdf';
        break;
      case 'RECEIPT':
        postUrl = API_URL + '/etax/documents/receipts/pdf-xml';
        break;
      case 'INVOICE-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/invoices-taxinvoices/pdf-xml';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/deliveryorders-taxinvoices/pdf-xml';
        break;
      case 'TAXINVOICE':
        postUrl = API_URL + '/etax/documents/taxinvoices/pdf-xml';
        break;
      default:
        break;
    }

    if (postUrl) {
      await instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          for (let i = 0; i < selectedItems.length; i++) {
            console.log('create selected document: ', selectedItems[i]);

            for (let j = 0; j < _selectionItems.length; j++) {
              if (_selectionItems[j].key === selectedItems[i].key) {
                _selectionItems[j].status = 'processing';

                setItems([..._selectionItems]);

                const [responseCustomer, responseInvoiceDetail] = await Promise.all([
                  axios
                    .get(API_URL + '/sources/dynamics/bc/customers', {
                      headers: {
                        'Authorization': 'Bearer ' + tokenResponse.accessToken,
                        'cid': companyId
                      },
                      params: {
                        companyId: dynamicsCompany.key,
                        customerId: selectedItems[i].customerId,
                      },
                    })
                    .catch((error) => console.log(error)),

                  axios
                    .get(API_URL + '/sources/dynamics/bc/salesinvoicelines', {
                      headers: {
                        'Authorization': 'Bearer ' + tokenResponse.accessToken,
                        'cid': companyId
                      },
                      params: {
                        companyId: dynamicsCompany.key,
                        invoiceId: selectedItems[i].key,
                      },
                    })
                    .catch((error) => console.log(error)),
                ]);

                const _customerInfo = responseCustomer.data;
                const invoiceLine = responseInvoiceDetail.data;

                let d = selectedItems[i].documentDate.split('-');

                let _date = new Date(d[2] + '/' + d[1] + '/' + d[0]);

                let _dateBE =
                  `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + (_date.getFullYear() + 543);
                let _dateCE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + _date.getFullYear();

                let dueD = selectedItems[i].dueDate.split('-');

                let _dueDate = new Date(dueD[2] + '/' + dueD[1] + '/' + dueD[0]);

                let _dueDateBE =
                  `${_dueDate.getDate()}`.padStart(2, '0') +
                  '/' +
                  `${_dueDate.getMonth() + 1}`.padStart(2, '0') +
                  '/' +
                  (_dueDate.getFullYear() + 543);
                let _dueDateCE =
                  `${_dueDate.getDate()}`.padStart(2, '0') + '/' + `${_dueDate.getMonth() + 1}`.padStart(2, '0') + '/' + _dueDate.getFullYear();

                let _taxId = 'N/A';
                let _taxNumberType = 'OTHR';
                let _branchNumber = '';
                let _branchText = '';

                if (_customerInfo.taxRegistrationNumber) {
                  _taxId = _customerInfo.taxRegistrationNumber;
                  _taxNumberType = 'TXID';
                  _branchNumber = '00000';
                  _branchText = 'Head Office';
                }

                let _customer = {
                  addressLineOne: selectedItems[i].customerAddress,
                  addressLineTwo: '',
                  branchNumber: _branchNumber,
                  branchText: _branchText,
                  email: selectedItems[i].customerEmail,
                  name: selectedItems[i].customerName,
                  phone: selectedItems[i].customerPhoneNumber,
                  phoneCountryCode: '+66',
                  postcode: selectedItems[i].customerPostcode ? selectedItems[i].customerPostcode : '00000',
                  taxId: _taxId,
                  taxNumberType: _taxNumberType,
                  language: 'th',
                  unstructure: true,
                };

                let _items = [];

                for (let i = 0; i < invoiceLine.length; i++) {
                  let _percentVat = zeroVat;
                  let _percentVatText = zeroVatText;
                  if (invoiceLine[i].taxPercent > 0) {
                    _percentVat = percentVat;
                    _percentVatText = percentVatText;
                  }

                  _items.push({
                    number: i + 1 + '',
                    description: invoiceLine[i].description,
                    quantity: invoiceLine[i].quantity,
                    unitCode: '',
                    unitName: '-',
                    price: invoiceLine[i].unitPrice,
                    discount: invoiceLine[i].discountAmount,
                    total: invoiceLine[i].amountExcludingTax,
                    percentVat: _percentVat,
                    percentVatText: _percentVatText,
                  });
                }

                let _vatPriceTotal = 0;
                let _zeroVatPriceTotal = 0;
                let _nonVatPriceTotal = 0;

                _items.forEach((item) => {
                  if (item.percentVat === percentVat) {
                    _vatPriceTotal = _vatPriceTotal + item.total;
                  } else if (item.percentVat === zeroVat) {
                    _zeroVatPriceTotal = _zeroVatPriceTotal + item.total;
                  } else if (item.percentVat === nonVat) {
                    _nonVatPriceTotal = _nonVatPriceTotal + item.total;
                  }
                });

                const promise_PDF_XML = await new Promise(async (resolve) => {
                  await axios
                    .post(
                      postUrl,
                      {
                        number: selectedItems[i].number,
                        reference: '',
                        company: {
                          ...company,
                          includeVat: selectedItems[i].includeVat,
                        },
                        customer: _customer,
                        date: _date,
                        dateBE: _dateBE,
                        dateCE: _dateCE,
                        dueDate: _dueDate,
                        dueDateBE: _dueDateBE,
                        dueDateCE: _dueDateCE,
                        items: _items,
                        includeVat: selectedItems[i].includeVat,
                        discount: selectedItems[i].discount * 1,
                        amountTotal: selectedItems[i].amountTotal * 1,
                        vatTotal: selectedItems[i].vatTotal * 1,
                        grandTotal: selectedItems[i].grandTotal * 1,
                        nonVatPriceTotal: _nonVatPriceTotal,
                        zeroVatPriceTotal: _zeroVatPriceTotal,
                        vatPriceTotal: _vatPriceTotal,
                        percentVat: percentVat,
                        receivedBy: selectedItems[i].receivedBy,
                        reIssue: false,
                        grandTotalTH: numberToTextTh(selectedItems[i].grandTotal * 1),
                        grandTotalEN: numberToTextEn(selectedItems[i].grandTotal * 1),
                      },
                      {
                        headers: {
                          'Authorization': 'Bearer ' + tokenResponse.accessToken,
                          'cid': companyId
                        },
                      }
                    )
                    .then(
                      async (response) => {
                        console.log('Create document response: ', response);

                        let count = 0;

                        let interval = setInterval(async () => {
                          await axios
                            .get(`${API_URL}/etax/jobs/${response.data.id}`, {
                              headers: {
                                'Authorization': 'Bearer ' + tokenResponse.accessToken,
                                'Content-Type': 'application/json',
                                'cid': companyId
                              },
                            })
                            .then((jobsResponse) => {
                              count += 1;
                              console.log('Jobs response: ', j, jobsResponse.data.status, count);

                              if (jobsResponse.data.status === 'complete') {
                                _selectionItems[j].status = 'successful';
                                _selectionItems[j].etaxCreated = true;

                                setItems([..._selectionItems]);

                                clearInterval(interval);

                                resolve(true);
                              } else if (count >= 300) {
                                _selectionItems[j].status = 'fail';

                                setItems([..._selectionItems]);

                                clearInterval(interval);

                                resolve(false);
                              }
                            });
                        }, 1000);
                      },
                      (error) => {
                        console.log('Error Create document response: ', error);
                        _selectionItems[j].status = 'fail';
                        setItems([..._selectionItems]);
                        resolve(false);
                      }
                    );
                }); //promise

                Promise.all([promise_PDF_XML]).then((value) => {
                  console.log(i, value);
                });
              } //if
            } //for j
          } //for i
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });

      setIsCreateDocumentComplete(true);
      setIsCreateDocument(false);
    }

    setIsCreateDocumentComplete(true);
    setIsCreateDocument(false);
  };

  return (
    <div style={homeStyles}>
      {!isLoading && (
        <div>
          <Stack>
            <center>
              <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('dynamicBc.header')}</h2>
              {!loadDataComplete && (
                <div>
                  <Spinner
                    label={t('dynamicBc.loading')}
                    labelPosition="right"
                    style={{
                      marginTop: '21px',
                      marginBottom: '2px',
                    }}
                  />
                  <br />
                </div>
              )}
            </center>
          </Stack>

          {(selectedItems.length > 0 || isCreateDocument || isCreateDocumentComplete) && (
            <Stack vertical tokens={{ childrenGap: '10px' }}>
              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                <TextField
                  name="createdBy"
                  label={t('dynamicBc.createdBy')}
                  description={t('dynamicBc.createdByDesc')}
                  styles={{ root: { width: 200 } }}
                  onChange={(e) => {
                    setCreatedBy(e.target.value);
                  }}
                  value={createdBy}
                  disabled={isCreateDocument}
                />
              </Stack>
              <br />
            </Stack>
          )}

          {selectedItems.length > 0 && !isCreateDocument && (
            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
              <PrimaryButton
                text={t('dynamicBc.createDocument')}
                iconProps={{ iconName: 'Add' }}
                menuProps={{
                  items: [
                    {
                      key: 'RECEIPT-TAXINVOICE',
                      text: t('dynamicBc.receipt-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('RECEIPT-TAXINVOICE');
                      },
                    },
                    {
                      key: 'RECEIPT-TAXINVOICE-ABB',
                      text: t('dynamicBc.receipt-taxinvoice-abb'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('RECEIPT-TAXINVOICE-ABB');
                      },
                    },
                    {
                      key: 'RECEIPT',
                      text: t('dynamicBc.receipt'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('RECEIPT');
                      },
                    },
                    {
                      key: 'INVOICE-TAXINVOICE',
                      text: t('dynamicBc.invoice-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('INVOICE-TAXINVOICE');
                      },
                    },
                    {
                      key: 'DELIVERYORDER-TAXINVOICE',
                      text: t('dynamicBc.deliveryorder-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('DELIVERYORDER-TAXINVOICE');
                      },
                    },
                    {
                      key: 'TAXINVOICE',
                      text: t('dynamicBc.taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('TAXINVOICE');
                      },
                    },
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
              <DefaultButton
                text={t('dynamicBc.cancel')}
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  let _items = selection.getItems();
                  let _selectedItems = selection.getSelection();
                  console.log('selection: ', selection.getSelection());

                  for (let i = 0; i < _selectedItems.length; i++) {
                    console.log('select item key: ', _selectedItems[i].key);
                    _selectedItems[i].status = '';

                    for (let j = 0; j < _items.length; j++) {
                      if (_items[j].key === _selectedItems[i].key) {
                        console.log('item key: ', _items[j].key);
                        _items[j].status = '';
                        _selectedItems[i].status = '';
                      }
                    }
                  }

                  selection.setAllSelected(false);

                  setItems(_items);
                  console.log('selection: ', selection.getSelection());
                }}
              />
              <br />
            </Stack>
          )}

          {isCreateDocument && (
            <div>
              <Spinner label={t('dynamicBc.signProcessing')} size={SpinnerSize.large} />
            </div>
          )}

          {isCreateDocumentComplete && (
            <center>
              <PrimaryButton
                text={t('dynamicBc.createdDocuments')}
                iconProps={{ iconName: 'Documentation' }}
                onClick={async () => {
                  console.log(selectedItems);
                  history.push({
                    pathname: '/etax/documents/all',
                    state: {},
                  });
                }}
              />
            </center>
          )}
          <br />
          <Stack horizontal horizontalAlign="center">
            <Stack
              vertical
              style={{
                width: '98%',
                minWidth: '800px',
                maxWidth: '1350px',
              }}
            >
              <Stack 
                horizontal 
                styles={{
                  root: {
                    backgroundColor: 'white',
                  },
                }}
                horizontalAlign="space-between"
              >
                <Stack
                  styles={{
                    root: {
                      width: 'calc(100vw - 700px)',
                      minWidth: '700px',
                      maxWidth: '900px',
                    },
                  }}
                >
                  <CommandBar items={commandBarItems} overflowItems={overflowItems} />
                </Stack>
                <Stack
                  horizontal
                  styles={{
                    root: {
                      height: 44,
                    },
                  }}
                  tokens={{ childrenGap: '10px' }}
                >
                  <SearchBox
                    className="ms-borderColor-themePrimary"
                    styles={{
                      root: {
                        marginTop: 6,
                        width: 270,
                        fontSize: 13,
                        fontWeight: 0,
                      },
                    }}
                    disabled={!loadDataComplete}
                    placeholder={t('dynamicBc.searchBoxPlaceholder')}
                    onChange={(e) => {
                      setSearch(e ? e.target.value : '');
                    }}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setLoadDataComplete(false);
                        setDocuments([]);
                        setItems([]);
                        setGetNewDocuments(true);

                        setCurrentPage(1);
                      }
                    }}
                    onClear={() => {
                      setLoadDataComplete(false);
                      setDocuments([]);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                    onSearch={() => {
                      setLoadDataComplete(false);
                      setDocuments([]);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                    value={search}
                  />
                  <PrimaryButton
                    disabled={!loadDataComplete || !search}
                    styles={{
                      root: {
                        marginTop: 6,
                        marginRight: 18,
                        width: 90,
                      },
                    }}
                    text={t('dynamicBc.search')}
                    onClick={() => {
                      setLoadDataComplete(false);
                      setDocuments([]);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                  />
                </Stack>
              </Stack>
              <ShimmeredDetailsList
                items={items || []}
                enableShimmer={!loadDataComplete}
                compact={isCompactMode}
                columns={columns}
                selection={selection}
                selectionMode={!loadDataComplete || isCreateDocument ? SelectionMode.none : SelectionMode.multiple}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                checkboxVisibility={CheckboxVisibility.always}
                selectionPreservedOnEmptyClick={true}
              />
            </Stack>
          </Stack>
          <br />
          <center>
            {!(currentPage === 1 && items.length < pageSize) && !(!loadDataComplete || isCreateDocument) && (
              <Pagination
                selectedPageIndex={currentPage - 1}
                pageCount={pageData.totalPages}
                onPageChange={onPageChange}
                format
                firstPageIconProps={{
                  iconName: 'DoubleChevronLeft',
                }}
                previousPageIconProps={{
                  iconName: 'ChevronLeft',
                }}
                nextPageIconProps={{
                  iconName: 'ChevronRight',
                }}
                lastPageIconProps={{
                  iconName: 'DoubleChevronRight',
                }}
              />
            )}

            <br />
            <br />
          </center>
        </div>
      )}

      {isLoading && (
        <div>
          <br />
          <br />
          <br />
          <br />
          <Stack>
            <Stack horizontal horizontalAlign="center">
              <div>
                <Spinner label={t('dynamicBc.loading')} labelPosition="down" styles={spinnerStyles} />
              </div>
            </Stack>
          </Stack>
          <br />
        </div>
      )}
      <Dialog
        hidden={!dialogConfirm.isClick}
        onDismiss={dialogConfirm.onCancel}
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: dialogConfirm.header,
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 }, subText: { textAlign: 'center' } },
          subText: dialogConfirm.message,
        }}
      >
        <DialogFooter styles={{ actionsRight: { textAlign: 'center' } }}>
          <PrimaryButton onClick={dialogConfirm.onConfirm} text={t('documents.confirm')} />
          <DefaultButton onClick={dialogConfirm.onCancel} text={t('documents.cancel')} />
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default DynamicsBusinessCentral;
