import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ThemeSelector.css"; // Import CSS module
import { theme } from "./ThemeColorsSetting.js";
import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../authConfig";
import {
  Button,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Skeleton,
  SkeletonItem,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
const API_URL = process.env.REACT_APP_REQUEST_FORM_API_URL;

const ThemeSelector = ({
  ThemeForm,
  setThemeForm,
  EditConfirm,
  formId,
  loading,
  setLoading,
  EditUnsuccess,
  setDismissTimeout,
  inProgress
}) => {
  const savedPalette = localStorage.getItem("selectedPalette");
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const saveThemeData = async (themeData) => {
     inProgress()
    setDismissTimeout(40000)
    try {
       let tokenResponse;
          try {
            tokenResponse = await instance.acquireTokenSilent({
            ...silentRequest,
            account: account,
            });
          } catch (error) {
            console.error("Error acquiring token: ", error);
            instance.acquireTokenRedirect(silentRequest);
            return; 
          }

      const response = await axios.patch(
        `${API_URL}/forms/${formId}`,
        {
          replace: "/settingThemeId",
          value: themeData,
        },
        {
          headers: {
            Authorization: "Bearer " + tokenResponse.accessToken,
          },
        }
      );

      if (response.status === 200) {
        setDismissTimeout(0)
        EditConfirm();
        console.log("save Theme Success", response);
        return response.data;
      } else {
        throw new Error("Failed to save data");
      }
    } catch (error) {
      console.error("Error saving theme data:", error);
      setDismissTimeout(0)
      EditUnsuccess();
      throw error; // Throw the error to handle it in the caller function
    }
  };

  const { t } = useTranslation();
  const defaultPalette = {
    "id": "001",
    "name": "Standard",
    "backgroundColor": "#96c6fa",
    "primaryColor": "#0c3b5e",
    "secondaryColor": "#ebf3fc",
    "accentColor1": "#0f6cbd",
  };
  const initialPalette = savedPalette
    ? JSON.parse(savedPalette)
    : defaultPalette;

  const [selectedPalette, setSelectedPalette] = useState(initialPalette);

  // const [palettes, setPalettes] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState(null);

  const handleConfirmChangeTheme = async () => {
    setLoading(true);
    try {
      await saveThemeData(selectedTheme, selectedPalette);
    } catch (error) {
      console.error("Failed to save theme data:", error);
    } finally {
      setLoading(false);
    }
  };
  const themeGetter = (id) => {
    return theme.find((theme) => theme.id === id);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
       let tokenResponse;
          try {
            tokenResponse = await instance.acquireTokenSilent({
            ...silentRequest,
            account: account,
            });
          } catch (error) {
            console.error("Error acquiring token: ", error);
            instance.acquireTokenRedirect(silentRequest);
            return; 
          }

        const response = await axios.get(`${API_URL}/forms/${formId}`, {
          headers: {
            Authorization: "Bearer " + tokenResponse.accessToken,
          },
        });
        // setPalettes(response.data.theme);

        const themecurrent = themeGetter(response.data.setting.themeId);
        applyPaletteOriginal(response.data.setting.themeId, themecurrent);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();

    // setPalettes(theme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applyPaletteOriginal = (themeId, palette) => {
    const { backgroundColor, primaryColor, secondaryColor, accentColor1 } =
      palette;

    setSelectedTheme(themeId);
    document.documentElement.style.setProperty(
      "--background-color",
      backgroundColor
    );
    document.documentElement.style.setProperty("--primary-color", primaryColor);
    document.documentElement.style.setProperty(
      "--secondary-color",
      secondaryColor
    );
    document.documentElement.style.setProperty(
      "--accent-color-1",
      accentColor1
    );

    setSelectedPalette(palette);
    // localStorage.setItem("selectedPalette", JSON.stringify(palette));
  };

  // useEffect(() => {
  //   applyPaletteOriginal(null, initialPalette);
  // }, []); // Empty dependency array to run only once when the component mounts

  return (
    <div className="wrap">
      <p style={{ color: "grey" }}>
        {selectedTheme
          ? t(`requestFormThemeColor.${selectedTheme}`)
          : t(`requestFormThemeColor.selectForForm`)}
      </p>
      {loading ? (
        <div id="palettes" className="palette-container">
          {[...Array(16)].map((_, index) => (
            <Skeleton key={index} className="palette-loading">
              {/* <div className={styles.paletteInnerWrap}>
                  <SkeletonItem className={styles.palette-color} />
                  <SkeletonItem className={styles.palette-color} />
                  <SkeletonItem className={styles.palette-color} />
                  <SkeletonItem className={styles.palette-color} />
                </div> */}
              <SkeletonItem
                key={index}
                className="palette-colorLoading"
              ></SkeletonItem>
            </Skeleton>
          ))}
        </div>
      ) : (
        <div id="palettes" className="palette-container">
          {theme.map((palette, index) => (
            <div
              key={index}
              className="palette"
              style={{ backgroundColor: palette.backgroundColor }}
              onClick={(event) => applyPaletteOriginal(palette.id, palette)}
            >
              <div className="palette-Inner-wrap">
                <div
                  // className={`${styles.palette-color} ${styles.backgroundColor}`}
                  className="palette-color"
                  style={{ backgroundColor: palette.backgroundColor }}
                ></div>
                <div
                  // className={`${styles.palette-color} ${styles.primaryColor}`}
                  className="palette-color"
                  style={{ backgroundColor: palette.primaryColor }}
                ></div>
                <div
                  // className={`${styles.palette-color} ${styles.secondaryColor}`}
                  className="palette-color"
                  style={{ backgroundColor: palette.secondaryColor }}
                ></div>
                <div
                  // className={`${styles.palette-color} ${styles.accentColor}`}
                  className="palette-color"
                  style={{ backgroundColor: palette.accentColor1 }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      )}
      <div>
        <Dialog modalType="modal">
          <DialogTrigger disableButtonEnhancement>
            <Button
              appearance="primary"
              style={{ marginRight: "10px" }}
              disabled={loading}
            >
              {t("requestFormAdminFormEditor.saveTheme")}
            </Button>
          </DialogTrigger>
          <DialogSurface>
            <DialogBody>
              <DialogTitle>
                {t("requestFormAdminFormEditor.confirmTheme")}
              </DialogTitle>
              <DialogContent>
                {t("requestFormAdminFormEditor.confirmToSave")}
              </DialogContent>
              <DialogActions>
                <DialogTrigger disableButtonEnhancement>
                  <Button
                    onClick={handleConfirmChangeTheme}
                    appearance="primary"
                    className="button-dialog"
                  >
                    {t("requestFormAdminFormEditor.confirm")}
                  </Button>
                </DialogTrigger>
                <DialogTrigger disableButtonEnhancement>
                  <Button appearance="secondary" className="button-dialog">
                    {t("requestFormAdminFormEditor.cancel")}
                  </Button>
                </DialogTrigger>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      </div>
    </div>
  );
};

export default ThemeSelector;
