import { Button, Checkbox, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Divider, Field, Input, Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger, Spinner, Subtitle2Stronger, Title2, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ChevronDownRegular,
  ChevronUpRegular,
  AddRegular,
  SaveRegular,
  MoreVerticalRegular,
  DeleteRegular
  } from "@fluentui/react-icons";
import styled, { keyframes } from 'styled-components';

const homeStyles = {
    border: 0,
    margin: 0,
    width: '100%',
    minWidth: 'calc(100vw - 310px)',
    background: '#FAF9F8',
    position: 'relative',
  };

const stylesTable = {
    tableContainer: {
      overflowX: 'auto',
      width: 1100,
      // transition: 'height 0.3s ease'
    },
    table: {
      borderCollapse: 'collapse',
      width: 'max-content',
      backgroundColor: "white",
      border: '1px solid #ccc',
    },
    th: {
      position: 'sticky',
      left: 0,
      zIndex: 2,
      border: '1px solid #dddddd',
      boxShadow: '6px 0px 6px -2px rgba(0, 0, 0, 0.1)',
      fontSize: '16px',
      paddingRight: 10
    },
    thBorder: {
      content: "''",
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '1px', // Adjust the border width as needed
      backgroundColor: '#dddddd', // Adjust the border color as needed
      zIndex: 1, // Place the border above the content
      boxShadow: '6px 0px 6px -2px rgba(0, 0, 0, 0.1)'
    },
    cell: {
      border: '1px solid #dddddd',
      // width: 120
    },
  }

  const useStyles = makeStyles({
    root: {
      // Stack the label above the field with a gap
      display: "grid",
      // gridTemplateRows: "repeat(1fr)",
      justifyItems: "start",
      ...shorthands.gap("2px"),
      width: "100%"
      // maxWidth: "400px",
    },
    flex: {
      display: "flex", 
      justifyContent: "end", 
      alignItems: "center",
      ...shorthands.gap("20px"),
    },
    flexLeft: {
      display: "flex", 
      justifyContent: "start", 
      alignItems: "center",
      ...shorthands.gap("5px"),
    },
    contentSpacing: {
      display: "flex", 
      alignItems: "center",
      ...shorthands.gap("5px"),
    },
    stack: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      width: 'auto',
      height: 'auto',
      boxSizing: 'border-box',
      '> *': {
        textOverflow: 'ellipsis',
      },
      '> :not(:first-child)': {
        marginTop: '0px',
      },
      '> *:not(.ms-StackItem)': {
        flexShrink: 1,
      },
    },
  });

//   const slideDown = keyframes`
//   from {
//     transform: translateY(-100%);
//   }
//   to {
//     transform: translateY(0);
//   }
// `;

// const AnimatedRow = styled.tr`
//   border: 1px solid black;
//   padding: 8px;
//   animation: ${slideDown} 0.5s ease;
// `;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const AnimatedRow = styled.tr`
  border: 1px solid black;
  padding: 8px;
  animation: ${fadeIn} 0.5s ease;
`;

function arrayContainsAllValues(rolePermission, groupName, permissionArrayInGroup) {
  for (const obj of permissionArrayInGroup) {
      if (!rolePermission[groupName][obj.key]) {
          return false;
      }
  }
  return true;
}

function setAllPermissionsToTrue(permissionObject) {
  for (const section in permissionObject) {
    for (const subSection in permissionObject[section]) {
      // console.log("permissionObject[section][subSection]", permissionObject[section][subSection])
      permissionObject[section][subSection] = true;
    }
  }
  return permissionObject
}

const defaultPermission = {
  folder: {
    sales: false,
    accountReceivable: false,
    purchasing: false,
    withholdingTax: false,
  },
  organizationContacts: {
    company: false,
    contacts: false,
  },
  externalDataPaid: {
    dynamicBc: false,
    dynamicFo: false,
    dynamicSales: false,
    sapB1: false,
  },
  externalData: {
    lazada: false,
    lineShopping: false,
    paypal: false,
    peak: false,
    quickbooks: false,
    shipnity: false,
    shopee: false,
    tiktokShop: false,
    trcloud: false,
    wooCommerce: false,
    xero: false,
    zoho: false,
    fileExcel: false,
  },
  product: {
    productList: false,
  },
  reports: {
    salesReport: false,
    outputTaxReport: false,
    inputTaxReport: false,
    xmlDeliveryReport: false,
    emailDeliveries: false,
    smsDeliveries: false,
  },
  fileRdPrep: {
    pp30: false,
    pnd2: false,
    pnd3: false,
    pnd53: false,
  },
  setting: {
    certificates: false,
    xmlDelivery: false,
    templates: false,
    emailSetting: false,
    apiConnect: false,
  },
  rdData: {
    vatRd: false,
    taxIdRd: false,
    eTaxRd: false,
  },
}

const permissionGroups = [
  {
    groupName: 'folder',
    groupTextTH: "แฟ้มเอกสาร",
    groupTextEN: "Folders",
    permissions: [
      { key: 'sales', textTH: 'รายรับ', textEN: "Sales" },
      { key: 'accountReceivable', textTH: 'ลูกหนี้การค้า', textEN: "Account Receivable" },
      { key: 'purchasing', textTH: 'รายจ่าย', textEN: "Purchasing" },
      { key: 'withholdingTax', textTH: 'หัก ณ ที่จ่าย (50 ทวิ)', textEN: "Withholding Tax" },
    ],
  },
  {
    groupName: 'organizationContacts',
    groupTextTH: "ข้อมูลภายใน",
    groupTextEN: "Organization",
    permissions: [
      { key: 'company', textTH: 'บริษัท', textEN: "Company" },
      { key: 'contacts', textTH: 'ผู้ติดต่อ', textEN: "Contacts" },
    ],
  },
  {
    groupName: 'externalDataPaid',
    groupTextTH: "ข้อมูลภายนอก ERP",
    groupTextEN: "External Data ERP",
    permissions: [
      { key: 'dynamicBc', textTH: 'MS Dynamics 365 BC', textEN: "MS Dynamics 365 BC" },
      { key: 'dynamicFo', textTH: 'MS Dynamics 365 FO', textEN: "MS Dynamics 365 FO" },
      { key: 'dynamicSales', textTH: 'MS Dynamics 365 Sales', textEN: "MS Dynamics 365 Sales" },
      { key: 'sapB1', textTH: 'SAP Business One', textEN: "SAP Business One" },
    ],
  },
  {
    groupName: 'externalData',
    groupTextTH: "ข้อมูลภายนอก อื่นๆ",
    groupTextEN: "External Data",
    permissions: [
      { key: 'lazada', textTH: 'Lazada', textEN: "Lazada" },
      { key: 'lineShopping', textTH: 'LINE SHOPPING', textEN: "LINE SHOPPING" },
      { key: 'paypal', textTH: 'PayPal', textEN: "PayPal" },
      { key: 'peak', textTH: 'PEAK Account', textEN: "PEAK Account" },
      { key: 'quickbooks', textTH: 'QuickBooks', textEN: "QuickBooks" },
      { key: 'shipnity', textTH: 'Shipnity', textEN: "Shipnity" },
      { key: 'shopee', textTH: 'Shopee', textEN: "Shopee" },
      { key: 'tiktokShop', textTH: 'TikTok Shop', textEN: "TikTok Shop" },
      { key: 'trcloud', textTH: 'TRCloud', textEN: "TRCloud" },
      { key: 'wooCommerce', textTH: 'Woo Commerce', textEN: "Woo Commerce" },
      { key: 'xero', textTH: 'Xero', textEN: "Xero" },
      { key: 'zoho', textTH: 'Zoho', textEN: "Zoho" },
      { key: 'fileExcel', textTH: 'ไฟล์ Excel', textEN: "Import Excel" },
    ],
  },
  {
    groupName: 'product',
    groupTextTH: "สินค้า",
    groupTextEN: "Products",
    permissions: [
      { key: 'productList', textTH: 'รายการสินค้า', textEN: "Product List" },
    ],
  },
  {
    groupName: 'reports',
    groupTextTH: "รายงาน",
    groupTextEN: "Reports",
    permissions: [
      { key: 'salesReport', textTH: 'รายงานยอดขาย', textEN: "Sales Report" },
      { key: 'outputTaxReport', textTH: 'รายงานภาษีขาย', textEN: "Output Tax Report" },
      { key: 'inputTaxReport', textTH: 'รายงานภาษีซื้อ', textEN: "Input Tax Report" },
      { key: 'xmlDeliveryReport', textTH: 'รายงานการนำส่ง XML', textEN: "XML Delivery Report" },
      { key: 'emailDeliveries', textTH: 'รายงานการนำส่ง อีเมล', textEN: "History Email Log" },
      { key: 'smsDeliveries', textTH: 'รายงานการนำส่ง SMS', textEN: "History SMS Log" },
    ],
  },
  {
    groupName: 'fileRdPrep',
    groupTextTH: "ไฟล์ RDPrep",
    groupTextEN: "Export RDPrep",
    permissions: [
      { key: 'pp30', textTH: 'ภ.พ.30', textEN: "PP.30" },
      { key: 'pnd2', textTH: 'ภ.ง.ด.2', textEN: "PND.2" },
      { key: 'pnd3', textTH: 'ภ.ง.ด.3', textEN: "PND.3" },
      { key: 'pnd53', textTH: 'ภ.ง.ด.53', textEN: "PND.53" },

    ],
  },
  {
    groupName: 'setting',
    groupTextTH: "ตั้งค่า",
    groupTextEN: "Settings",
    permissions: [
      { key: 'certificates', textTH: 'ใบรับรองอิเล็กทรอนิกส์', textEN: "Certificates" },
      { key: 'xmlDelivery', textTH: 'การนำส่ง XML (อัตโนมัติ)', textEN: "XML Delivery" },
      { key: 'templates', textTH: 'รูปแบบเอกสาร', textEN: "Templates" },
      { key: 'emailSetting', textTH: 'อีเมลที่ใช้ส่งเอกสาร', textEN: "Email" },
      { key: 'apiConnect', textTH: 'การเชื่อมต่อ API', textEN: "API Key" },

    ],
  },
  {
    groupName: 'rdData',
    groupTextTH: "ข้อมูลกรมสรรพากร",
    groupTextEN: "RD Data",
    permissions: [
      { key: 'vatRd', textTH: 'ข้อมูลผู้ประกอบการ VAT', textEN: "VAT" },
      { key: 'taxIdRd', textTH: 'ข้อมูลเลขประจำตัวผู้เสียภาษี', textEN: "Tax ID" },
      { key: 'eTaxRd', textTH: 'ข้อมูลผู้ประกอบการ e-Tax', textEN: "e-Tax Invoice & e-Receipt" },

    ],
  },
]

const Roles = () => {
  const { t, i18n } = useTranslation();

  const styles = useStyles();

  const [roles, setRoles] = useState([
    { id: 'administrator', name: 'Administrator', permissions: setAllPermissionsToTrue(structuredClone(defaultPermission)), isAdmin: true, isChange: false },
    // { id: 'manager', name: 'Manager', permissions: structuredClone(defaultPermission), isChange: false },
    // { id: 'user', name: 'User', permissions: structuredClone(defaultPermission), isChange: false },
    // { id: 'user1', name: 'User1', permissions: structuredClone(defaultPermission), isChange: false },
    // { id: 'User2', name: 'User2', permissions: structuredClone(defaultPermission), isChange: false },
    // { id: 'User3', name: 'User2', permissions: structuredClone(defaultPermission), isChange: false },
    // { id: 'User4', name: 'User2', permissions: structuredClone(defaultPermission), isChange: false },
    // { id: 'User5', name: 'User2', permissions: structuredClone(defaultPermission), isChange: false },
    // { id: 'User6', name: 'User2', permissions: structuredClone(defaultPermission), isChange: false },
    // { id: 'User7', name: 'User2', permissions: structuredClone(defaultPermission), isChange: false },
  ]);

  // console.log(setAllPermissionsToTrue(structuredClone(defaultPermission)))

  const [groupCollapsed, setGroupCollapsed] = useState({});

  const [dialog, setDialog] = useState({
    open: false,
    loading: false,
    headers: '',
    value: '',
    errorMessage: "",
    onSubmit: null,
    onCancel: null,
    content: function () {
      return null
    },
    action: function () {
      return null
    },
  })

  const handlePermissionChange = (groupName, permissionKey, roleIndex, checked) => {
    setRoles(prevRoles => {
      const updatedRoles = [...prevRoles];

      updatedRoles[roleIndex].permissions[groupName][permissionKey] = checked

      updatedRoles[roleIndex].isChange = true

      return updatedRoles;
    });
  };

  // useEffect(() => {
  //   console.log("groupCollapsed", groupCollapsed)
  // }, [groupCollapsed])
  
  const handleToggleGroupCollapse = (groupName) => {
    setGroupCollapsed(prevGroupCollapsed => ({
      ...prevGroupCollapsed,
      [groupName]: !prevGroupCollapsed[groupName],
    }));
  };

  const handleToggleGroupSelection = (groupName, roleIndex, checked) => {
    setRoles(prevRoles => {
      const updatedRoles = [...prevRoles];

      const _uploadRolePermission = updatedRoles[roleIndex].permissions[groupName]

      Object.keys(_uploadRolePermission).forEach(permission => _uploadRolePermission[permission] = !checked)

      updatedRoles[roleIndex].isChange = true

      return updatedRoles;
    })
  };

  const handleAddRole = () => {
    setDialog(prev => 
      ({
        ...prev, 
        open: true,
        headers: t('roles.addRole'),
        errorMessage: "",
        content: function () {
          return this.loading ? 
          (<Spinner labelPosition='below' label={t('roles.creating')} /> ) 
          : 
          (<Field
            label={t('roles.name')}
            validationMessage={this.errorMessage}
          >
            <Input value={this.value} onChange={(e, data) => {
              setDialog(prev => ({...prev, value: data.value, errorMessage: ''}))
            }} />
          </Field>)
        },
        action: function () {
          return <>
            <Button appearance="primary" disabled={!this.value} onClick={() => this.onSubmit(this.value)}>{t('roles.add')}</Button>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary" onClick={() => 
                this.onCancel()
                }>{t('roles.cancel')}</Button>
            </DialogTrigger>
          </>
        },
        onSubmit: (value) => {
          if (roles.some(_role => _role.name === value)) return setDialog(prev => ({...prev, errorMessage: t('roles.errorMessage')}))

          setRoles(prevRoles => [
            ...prevRoles,
            { id: value, name: value, permissions: structuredClone(defaultPermission), isChange: false },
          ])

          setDialog({
            open: false,
            loading: false,
            headers: '',
            value: '',
            errorMessage: '',
            onSubmit: null,
            onCancel: null,
            content: function () {
              return null
            },
            action: function () {
              return null
            },
          })

          return;
        },
        onCancel: () => setDialog({
          open: false,
          loading: false,
          headers: '',
          value: '',
          errorMessage: '',
          onSubmit: null,
          onCancel: null,
          content: function () {
            return null
          },
          action: function () {
            return null
          },
        }),
      }))
  }

  const handleDeleteRole = (role) => {
    setDialog(prev => 
      ({...prev, 
        open: true,
        headers: ``,
        value: '',
        errorMessage: '',
        content: function () {
          return <div className={styles.stack} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <DeleteRegular fontSize={32} />
            <Subtitle2Stronger style={{ paddingTop: 6 }}>{t('storages.deleteConfirmMessage')}</Subtitle2Stronger>
          </div>
        },
        action: function () {
          return <>
            <Button appearance="primary" onClick={() => this.onSubmit(role)}>{t('roles.confirm')}</Button>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary" onClick={() => 
                this.onCancel()
                }>{t('roles.cancel')}</Button>
            </DialogTrigger>
          </>
        },
        onSubmit: (role) => {
          setRoles(prevRoles => {
            const updatedRoles = [...prevRoles].filter(_prevRole => _prevRole.id !== role.id)

            return updatedRoles            
          })

          setDialog({
            open: false,
            loading: false,
            headers: '',
            value: '',
            errorMessage: '',
            onSubmit: null,
            onCancel: null,
            content: function () {
              return null
            },
            action: function () {
              return null
            },
          })
        },
        onCancel: () => setDialog({
          open: false,
          loading: false,
          headers: '',
          value: '',
          errorMessage: '',
          onSubmit: null,
          onCancel: null,
          content: function () {
            return null
          },
          action: function () {
            return null
          },
        }),
      }))
  }

  const handleSubmit = () => {
    console.log("roles change", roles.filter(_role => _role.isChange))

    
  }

  const ActionCell = ({ role }) => {
    // const [hovered, setHovered] = useState(false)

    return (
      <div 
        style={{ 
          display: "flex", 
          justifyContent: role.isAdmin ? "center" : "space-between", 
          alignItems: "center", 
          fontSize: '16px', 
          padding: role.isAdmin ? "5px 10px" :  "5px 5px 5px 10px",
          // width: 130,
          height: 32,
          // position: "relative"
        }}
        // onMouseEnter={() => setHovered(true)}
        // onMouseLeave={() => setHovered(false)} 
      >
        {/* <div style={{ textAlign: "center", width: 120, whiteSpace: 'nowrap',  overflow: 'hidden', display: 'inline-block', textOverflow: "ellipsis" }}> */}
          {role.name}
        {/* </div> */}
        {/* {hovered && (
          // <div style={{
          //   position: "absolute",
          //   zIndex: 2,
          //   right: 0,
          //   backgroundColor: "white"
          // }}>
          
          // </div>
        )} */}
        {!role.isAdmin && (
          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <MenuButton style={{ paddingRight: 0}} appearance="transparent" icon={<MoreVerticalRegular />} />
            </MenuTrigger>
            <MenuPopover>
              <MenuList>
                {/* <MenuItem onClick={() => handleEditUser(row)}>แก้ไข</MenuItem> */}
                <MenuItem onClick={() => handleDeleteRole(role)}>{t('roles.delete')}</MenuItem>
              </MenuList>
            </MenuPopover>
          </Menu>

        )}
      </div>
    )
  }

    return <div style={homeStyles}>
      <div style={{ padding: 30}}>
        <div style={{ display: "flex", justifyContent: "space-between"}}>
          <Title2>{t('roles.header')}</Title2>
          <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "center", alignItems: "center" }}>
            {roles.some(role => role.isChange) ? (
              <Button appearance="primary" icon={<SaveRegular /> } onClick={handleSubmit}>{t('roles.save')}</Button>
            ) : null}
            <Button 
              appearance="primary" 
              icon={<AddRegular /> }
              onClick={() => handleAddRole()}
            >
              {t('roles.addRole')}
            </Button>
          </div>
        </div>
        <br />
        <Divider />
        <br />
        <div style={stylesTable.tableContainer}>
          <table style={stylesTable.table}>
            <thead>
              <tr>
                <th style={{backgroundColor: "white", ...stylesTable.th}}>
                  <div>
                    <div style={stylesTable.thBorder}></div>
                  </div>
                </th>
                {roles.map((role, index) => (
                  <th style={stylesTable.cell} key={index}>
                    {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: '16px', padding: 5}}>
                      {role.name}

                    </div> */}
                    <ActionCell role={role} />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
            {permissionGroups.map(group => (
                <React.Fragment key={group.groupName}>
                  <tr>
                    <td style={{ width: 200, backgroundColor: tokens.colorBrandBackgroundInvertedPressed , ...stylesTable.th}}>
                      <div >
                        <strong>
                          <Button icon={groupCollapsed[group.groupName] ? <ChevronDownRegular /> : <ChevronUpRegular /> } appearance="transparent" onClick={() => handleToggleGroupCollapse(group.groupName)} />
                          {i18n.language === "th" ? group.groupTextTH : group.groupTextEN}
                        </strong>
                        <div style={stylesTable.thBorder}></div>
                      </div>
                    </td>
                    {roles.map((role, roleIndex) => (
                      <td key={roleIndex} style={{ width: 100, backgroundColor: tokens.colorBrandBackgroundInvertedPressed, ...stylesTable.cell }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
                          <Checkbox
                            checked={arrayContainsAllValues(role.permissions, group.groupName, group.permissions)}
                            onChange={() => {
                              if (role.isAdmin) return;
                              return handleToggleGroupSelection(group.groupName, roleIndex, arrayContainsAllValues(role.permissions, group.groupName, group.permissions))
                            }}
                          />
                        </div>
                      </td>
                    ))}
                  </tr>
                  {!groupCollapsed[group.groupName] && (
                    group.permissions.map(permission => (
                      <AnimatedRow key={permission.key}>
                        <td style={{ 
                            backgroundColor: "white",
                            height: 32, 
                            ...stylesTable.th,
                          }}>
                          <div >
                            <div style={{ paddingLeft: 32}}>
                              {i18n.language === "th" ? permission.textTH : permission.textEN}

                            </div>
                            <div style={stylesTable.thBorder}></div>
                          </div>
                        </td>
                        {roles.map((role, roleIndex) => (
                          <td style={stylesTable.cell} key={roleIndex}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
                              <Checkbox
                                checked={role.permissions[group.groupName][permission.key]}
                                onChange={() => {
                                  if (role.isAdmin) return;
                                  return handlePermissionChange(group.groupName, permission.key, roleIndex,
                                    !role.permissions[group.groupName][permission.key]
                                  )
                                }}
                              />

                            </div>
                          </td>
                        ))}
                      </AnimatedRow>
                    ))
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Dialog open={dialog.open}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{dialog.headers}</DialogTitle>
            <DialogContent>
              {dialog.content()}
            </DialogContent>
            <DialogActions>
              {dialog.action()}
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
}

export default Roles;