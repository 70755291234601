import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import axios from 'axios';
import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../authConfig';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  TextField,
  PrimaryButton,
  Dropdown,
  Stack,
  getTheme,
  Shimmer,
  ShimmerElementType,
  Icon,
  Spinner,
  MessageBar,
  MessageBarType,
  Toggle,
  IconButton,
  DefaultButton,
} from '@fluentui/react';

import { getProvinceList, getDistrictList, getSubDistrictList, getProvince, getDistrict, getSubDistrict } from '../../providers/AreaData';
import { getCountryList, getCountry } from '../../providers/CountryData';
import { getAccountTypeList, getBankList } from '../../providers/BankData';

const API_URL = process.env.REACT_APP_API_URL;

const theme = getTheme();

const stackTokens = { childrenGap: 10 };

const dropdownStyles = {
  dropdown: { width: 450 },
};

const cardStyle = {
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 20,
  paddingBottom: 30,
  background: '#ffffff',
  marginLeft: '0',
  marginTop: '10px',
  boxShadow: theme.effects.elevation4,
};

const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const dropdownLabelOptions = [
  { key: 'customer', text: 'Customer' },
  { key: 'hot', text: 'Hot Lead' },
  { key: 'warm', text: 'Warm Lead' },
  { key: 'cold', text: 'Cold Lead' },
];

const shimmerRowHeight32 = [{ type: ShimmerElementType.line, height: 32, width: 410 }];

const shimmerRowHeight37 = [{ type: ShimmerElementType.line, height: 37, width: 410 }];

const shimmerRowHeight62 = [{ type: ShimmerElementType.line, height: 62, width: 410 }];

const centerItem = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const AddContacts = () => {
  console.log('Re render');

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const { t, i18n } = useTranslation();

  const location = useLocation();
  const [data] = useState(location.state);

  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactNickName, setContactNickName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [contactPosition, setContactPosition] = useState('');
  const [contactDepartment, setContactDepartment] = useState('');
  const [buildingNumber, setBuildingNumber] = useState('');
  const [address, setAddress] = useState('');
  const [streetPrefix, setStreetPrefix] = useState('');
  const [streetName, setStreetName] = useState('');
  const [provincePrefix, setProvincePrefix] = useState('');
  const [province, setProvince] = useState({ key: '', text: '' });
  const [taxNumberType, setTaxNumberType] = useState('TXID');

  const [structure, setStructure] = useState(true);
  const [lineOne, setLineOne] = useState('');
  const [lineTwo, setLineTwo] = useState('');
  const [countryCode, setCountryCode] = useState('TH');
  const [countryName, setCountryName] = useState('ไทย');

  const [contactList, setContactList] = useState([]);

  const [districtPrefix, setDistrictPrefix] = useState('');
  const [district, setDistrict] = useState({ key: '', text: '' });
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictPrefix, setSubDistrictPrefix] = useState('');
  const [subDistrict, setSubDistrict] = useState({ key: '', text: '' });
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [postcode, setPostCode] = useState('');
  const [note, setNote] = useState('');

  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [fax, setFax] = useState('');
  const [taxId, setTaxId] = useState('');
  const [branch, setBranch] = useState('00000');
  const [branchNumber, setBranchNumber] = useState('00000');
  const [showBranchNumber, setShowBranchNumber] = useState(false);
  const [branchText, setBranchText] = useState('(สำนักงานใหญ่)');
  const [branchDisplay, setBranchDisplay] = useState([
    { key: '00000', text: 'สำนักงานใหญ่' },
    { key: 'branchNumber', text: 'สาขาเลขที่' },
  ]);

  const [paymentNote, setPaymentNote] = useState('');
  const [showMessageBarSuccess, setShowMessageBarSuccess] = useState(false);
  const [showMessageBarError, setShowMessageBarError] = useState(false);
  const [showMessageBarRepeatTaxId, setShowMessageBarRepeatTaxId] = useState(false);
  const [showMessageBarRepeatOTHR, setShowMessageBarRepeatOTHR] = useState(false);

  const [showMessageBarSearchError, setShowMessageBarSearchError] = useState(false)
  const [showMessageBarSearchNotFound, setShowMessageBarSearchNotFound] = useState(false)
   
  const [language, setLanguage] = useState(sessionStorage.getItem('address-language') || 'th');

  const provinceList = getProvinceList(language);
  const countryList = getCountryList(language);

  const bankList = getBankList(language)
  const accountTypeList = getAccountTypeList(language)
  
  const [accountName, setAccountName] = useState('');
  const [banks, setBanks] = useState([
    {
      bankCode: '',
      bankName: '',
      branch: '',
      typeCode: '',
      typeName: '',
      accountNumber: '',
    },
  ]);

  const [isLoadComplete, setIsLoadComplete] = useState(false);
  const [isSearchCompleate, setIsSearchCompleate] = useState(true);
  const [buttonClick, setButtonClick] = useState(false);

  //Error Message
  const [provinceErrMsg, setProvinceErrMsg] = useState('');
  const [districtErrMsg, setDistrictErrMsg] = useState('');
  const [subDistrictErrMsg, setSubDistrictErrMsg] = useState('');
  const DropdownErrMsg = t('addContact.dropdownErrMsg');

  const phoneRegEx = /(^0[1-9][0-9]{1,13}$|^$)/g;
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const [label, setLabel] = useState({ key: '', text: '' });

  const companyId = sessionStorage.getItem('companyId') || '';

  const history = useHistory();

  let taxIdRef = useRef(null);
  let branchNumberRef = useRef(null);
  let phoneRef = useRef(null);
  let provinceRef = useRef(null);
  let districtRef = useRef(null);
  let subDistrictRef = useRef(null);

  const handleTaxType = (selectedOption) => {
    console.log('Change tax type:', selectedOption.key);

    setTaxNumberType(selectedOption.key);

    if (selectedOption.key === 'TXID') {
      setBranchNumber('00000');
      if (language === 'en') return setBranchText('Head office');
      return setBranchText('สำนักงานใหญ่');
    } else {
      setBranchText('');
      setBranchNumber('');
    }

    if (selectedOption.key === 'OTHR') {
      setTaxId('N/A');
    } else {
      setTaxId('');
    }
  };

  const handleStructure = (checked) => {
    console.log('Address structure:', checked);

    setStructure(checked);
    if (checked) {
      setBuildingNumber('');
      setAddress('');
      setStreetPrefix('');
      setStreetName('');
      setProvincePrefix('');
      setProvince({ key: '', text: '' });
      setDistrictPrefix('');
      setDistrict({ key: '', text: '' });
      setSubDistrict('');
      setSubDistrict({ key: '', text: '' });

      setCountryCode('TH');
      setCountryName('ไทย');
    } else {
      setLineOne('');
      setLineTwo('');
    }
  };

  useEffect(() => {
    console.log('userEffect retrieve contact data!');

    async function getContact() {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          if (data) {
            axios
              .get(API_URL + '/contacts', {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId
                },
              })
              .then(
                (response) => {
                  console.log(response);
                  console.log(data);

                  setContactList(response.data);
                  let contact = response.data.filter((contact) => contact.id === data.id);

                  if (contact[0]) {
                    console.log('Company data: ', contact);

                    setId(contact[0].id);
                    setName(contact[0].name);

                    let _language = '';

                    if (contact[0].language != null) {
                      setLanguage(contact[0].language);
                      _language = contact[0].language;
                    } else {
                      _language = language;
                    }

                    if (contact[0].addressLineOne) {
                      setStructure(false);
                      setLineOne(contact[0].addressLineOne);
                      setLineTwo(contact[0].addressLineTwo);

                      setCountryCode(contact[0].countryCode);
                      setCountryName(contact[0].countryName);
                    }

                    if (contact[0].buildingNumber) {
                      setStructure(true);
                      setBuildingNumber(contact[0].buildingNumber);
                      setAddress(contact[0].address);
                      setStreetPrefix(contact[0].streetPrefix);
                      setStreetName(contact[0].streetName);
                      setProvincePrefix(contact[0].provincePrefix);
                      setProvince({
                        key: contact[0].provinceCode,
                        text: contact[0].provinceName,
                      });

                      setDistrictList(getDistrictList(contact[0].provinceCode, _language));
                      setDistrictPrefix(contact[0].districtPrefix);
                      setDistrict({
                        key: contact[0].districtCode,
                        text: contact[0].districtName,
                      });

                      setSubDistrictList(getSubDistrictList(contact[0].districtCode, _language));
                      setSubDistrictPrefix(contact[0].subDistrictPrefix);
                      setSubDistrict({
                        key: contact[0].subDistrictCode,
                        text: contact[0].subDistrictName,
                      });
                    }

                    setPostCode(contact[0].postcode);
                    setPhone(contact[0].phone);
                    setEmail(contact[0].email);
                    setTaxNumberType(contact[0].taxNumberType);
                    setTaxId(contact[0].taxId);
                    setBranchNumber(contact[0].branchNumber);
                    setBranchText(contact[0].branchText);

                    setWebsite(contact[0].website);
                    setFax(contact[0].fax);
                    setNote(contact[0].note);

                    setContactName(contact[0].contactName);
                    setContactDepartment(contact[0].contactDepartment);
                    setContactEmail(contact[0].contactEmail);
                    setContactNickName(contact[0].contactNickName);
                    setContactPhone(contact[0].contactPhone);
                    setContactPosition(contact[0].contactPosition);

                    if (
                      contact[0].branchNumber === '00000' &&
                      !(contact[0].branchText.includes('สาขา') || contact[0].branchText.includes('Branch'))
                    ) {
                      setBranch('00000');
                      setShowBranchNumber(false);
                    } else if (
                      contact[0].branchNumber.length === 5 &&
                      (contact[0].branchText.includes('สาขา') || contact[0].branchText.includes('Branch'))
                    ) {
                      setBranch('branchNumber');
                      setShowBranchNumber(true);
                    }

                    setAccountName(contact[0].payment.accountName);
                    setBanks(contact[0].payment.banks);
                    setPaymentNote(contact[0].payment.note);
                  }

                  setIsLoadComplete(true);
                },
                (error) => {
                  console.log(error);
                }
              );
          } else {
            axios
              .get(API_URL + '/contacts', {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId
                },
              })
              .then((response) => {
                setContactList(response.data);
                setIsLoadComplete(true);
              });
          }
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }

    getContact();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('Language Effect: ', language);

    if (language != null) {
      setProvince(getProvince(province.key, language));
      setDistrict(getDistrict(district.key, language));
      setDistrictList(getDistrictList(province.key, language));
      setSubDistrict(getSubDistrict(subDistrict.key, language));
      setSubDistrictList(getSubDistrictList(district.key, language));
      setCountryName(getCountry(countryCode, language).text);

      handleBranch(branchNumber, language);
    }

    if (language === 'en') {
      setSubDistrictPrefix('');
      setDistrictPrefix('');
      setProvincePrefix('');
      setStreetPrefix('');
    } else {
      if (province.key === '10') {
        setSubDistrictPrefix('แขวง');
        setDistrictPrefix('เขต');
        setProvincePrefix('');
      } else if (province.key) {
        setSubDistrictPrefix('ต.');
        setDistrictPrefix('อ.');
        setProvincePrefix('จ.');
      } else {
        setSubDistrictPrefix('');
        setDistrictPrefix('');
        setProvincePrefix('');
      }
      if (streetName) {
        setStreetPrefix('ถ.');
      } else {
        setStreetPrefix('');
      }
    }

    banks.forEach((bank, index) => {
      banks[index]['bankName'] = bankList.find((_bank) => _bank.key === bank.bankCode)?.text || ''
    });

    banks.forEach((bank, index) => {
      banks[index]['typeName'] = accountTypeList.find((_accountType) => _accountType.key === bank.typeCode)?.text || ''
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  function handleSearch() {
    if (taxId.length !== 13) return;

    setIsSearchCompleate(false);
    setShowMessageBarSearchError(false)
    setShowMessageBarSearchNotFound(false)

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/sources/rd/vat/' + taxId + (branchNumber ? `?branch=${branchNumber}` : ''), {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'Content-Type': 'application/json',
            },
            timeout: 10000
          })
          .then((response) => {
            console.log('response from rd vat', response);
            if (!response.data.msgerr[0]) {
              setTaxNumberType('TXID');
              // if (response.data.BranchTitleName[0] === 'บริษัท') {
              //   setTaxNumberType('TXID');
              // } else {
              //   setTaxNumberType('NIDN');
              // }

              if (response.data.BranchNumber[0] === 0) {
                if (language === 'th') {
                  setBranchText('สำนักงานใหญ่');
                } else {
                  setBranchText('Head office');
                }
                setBranchNumber('00000');
              } else if (response.data.BranchNumber[0] !== '-') {
                if (language === 'th') {
                  setBranchText('สาขา ' + String(response.data.BranchNumber[0]).padStart(5, '0'));
                } else {
                  setBranchText('Branch ' + String(response.data.BranchNumber[0]).padStart(5, '0'));
                }
                setBranchNumber(String(response.data.BranchNumber[0]).padStart(5, '0'));
              }

              setName((response.data.BranchTitleName[0]).trim() + ' ' + (response.data.Name[0]).trim());
              setBuildingNumber((response.data.HouseNumber[0]).trim());

              let _address = '';

              if (response.data.BuildingName[0] !== '-') {
                _address = _address + (response.data.BuildingName[0]).trim() + ' ';
              }

              if (response.data.FloorNumber[0] !== '-') {
                _address = _address + 'ชั้น ' + (response.data.FloorNumber[0]).trim() + ' ';
              }

              if (response.data.RoomNumber[0] !== '-') {
                _address = _address + 'ห้อง ' + (response.data.RoomNumber[0]).trim() + ' ';
              }

              if (response.data.MooNumber[0] !== '-') {
                _address = _address + 'หมู่ ' + (response.data.MooNumber[0]).trim() + ' ';
              }

              if (response.data.SoiName[0] !== '-') {
                _address = _address + 'ซอย ' + (response.data.SoiName[0]).trim() + ' ';
              }

              if (response.data.VillageName[0] !== '-') {
                _address = _address + 'หมู่บ้าน ' + (response.data.VillageName[0]).trim() + ' ';
              }
              setAddress((_address).trim());

              setStreetPrefix(response.data.StreetName[0] !== '-' ? 'ถ.' : '');
              setStreetName(response.data.StreetName[0] !== '-' ? (response.data.StreetName[0]).trim() : '');

              // setProvincePrefix('จ.');

              provinceList.forEach((province) => {
                if (province.text === (response.data.Province[0]).trim()) {
                  setProvince({
                    key: province.key,
                    text: province.text,
                  });

                  if (province.key === '10') {
                    setSubDistrictPrefix('แขวง');
                    setDistrictPrefix('เขต');
                    setProvincePrefix('');
                  } else if (province.key) {
                    setSubDistrictPrefix('ต.');
                    setDistrictPrefix('อ.');
                    setProvincePrefix('จ.');
                  } else {
                    setSubDistrictPrefix('');
                    setDistrictPrefix('');
                    setProvincePrefix('');
                  }

                  let districtList = getDistrictList(province.key, language);

                  setDistrictList(districtList);
                  // setDistrictPrefix('อ.');
                  districtList.forEach((district) => {
                    if (district.text === (response.data.Amphur[0]).trim()) {
                      setDistrict({
                        key: district.key,
                        text: district.text,
                      });

                      let subDistrictList = getSubDistrictList(district.key, language);
                      setSubDistrictList(subDistrictList);
                      // setSubDistrictPrefix('ต.');
                      subDistrictList.forEach((subDistrict) => {
                        if (subDistrict.text === (response.data.Thambol[0]).trim()) {
                          setSubDistrict({
                            key: subDistrict.key,
                            text: subDistrict.text,
                          });
                        }
                      });
                    }
                  });
                }
              });

              setPostCode((response.data.PostCode[0]).trim());

              setIsSearchCompleate(true);
            } else {
              setShowMessageBarSearchNotFound(true)

              setIsSearchCompleate(true);
            }
          }).catch((err) => {
            setShowMessageBarSearchError(true)

            if (axios.isCancel(err)) {
              console.log('Request timed out');

              setIsSearchCompleate(true);
            } else {
              console.error('Error:', err);

              setIsSearchCompleate(true);
            }
          })
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  }

  function handleSubmit(event) {
    event.preventDefault();

    let isCorrect = true;

    let data = {};

    if (structure) {
      if (province.key === undefined || province.key === null || province.key === '') {
        isCorrect = false;
        setProvinceErrMsg(DropdownErrMsg);
      }
      if (district.key === undefined || district.key === null || district.key === '') {
        isCorrect = false;
        setDistrictErrMsg(DropdownErrMsg);
      }
      if (subDistrict.key === undefined || subDistrict.key === null || subDistrict.key === '') {
        isCorrect = false;
        setSubDistrictErrMsg(DropdownErrMsg);
      }

      if (province.key === undefined || province.key === null || province.key === '') {
        provinceRef.focus();
      } else if (district.key === undefined || district.key === null || district.key === '') {
        districtRef.focus();
      } else if (subDistrict.key === undefined || subDistrict.key === null || subDistrict.key === '') {
        subDistrictRef.focus();
      }

      if (taxNumberType === 'TXID') {
        if (taxId.length !== 13 || branchNumber.length !== 5) {
          isCorrect = false;

          if (taxId.length !== 13) {
            taxIdRef.focus();
          }

          if (branchNumber.length !== 5) {
            branchNumberRef.focus();
          }
        }
      } else if (taxNumberType === 'NIDN') {
        if (taxId.length !== 13) {
          taxIdRef.focus();
          isCorrect = false;
        }
      } else if (taxNumberType === 'CCPT') {
        if (taxId.length === 0) {
          taxIdRef.focus();
          isCorrect = false;
        }
      }

      if (postcode) {
        if (countryCode === 'TH') {
          if (isNaN(postcode)) isCorrect = false;
          if (postcode.length !== 5) isCorrect = false;
        }
      }

      if (phoneNumberError) {
        isCorrect = false;
        phoneRef.focus();
      }

      data = {
        address: address,
        buildingNumber: buildingNumber,
        streetPrefix: streetPrefix,
        streetName: streetName,
        subDistrictPrefix: subDistrictPrefix,
        subDistrictCode: subDistrict.key,
        subDistrictName: subDistrict.text,
        districtPrefix: districtPrefix,
        districtCode: district.key,
        districtName: district.text,
        provincePrefix: provincePrefix,
        provinceCode: province.key,
        provinceName: province.text,
        unstructure: false,
        postcode: postcode,
        countryCode: countryCode,
        countryName: countryName,
        name: name,
        taxId: taxId,
        taxNumberType: taxNumberType,
        branchNumber: branchNumber,
        branchText: branchText,
        email: email,
        phone: phone,
        phoneCountryCode: '+66',
        language: language,
        website: website,
        fax: fax,
        contactName: contactName,
        contactPhone: contactPhone,
        contactEmail: contactEmail,
        contactNickName: contactNickName,
        contactPosition: contactPosition,
        contactDepartment: contactDepartment,
        payment: {
          accountName: accountName,
          banks: banks,
          note: paymentNote,
        },
        buyer: false,
        seller: true,
        note: note,
        label: label,
      };
    }

    if (!structure) {
      if (postcode) {
        if (countryCode === 'TH') {
          if (isNaN(postcode)) isCorrect = false;
          if (postcode.length !== 5) isCorrect = false;
        }
      }

      if (phoneNumberError) {
        isCorrect = false;
        phoneRef.focus();
      }

      data = {
        addressLineOne: lineOne,
        addressLineTwo: lineTwo,
        unstructure: true,
        postcode: postcode,
        countryCode: countryCode,
        countryName: countryName,
        name: name,
        taxId: taxId,
        taxNumberType: taxNumberType,
        branchNumber: branchNumber,
        branchText: branchText,
        email: email,
        phone: phone,
        phoneCountryCode: '+66',
        language: language,
        website: website,
        fax: fax,
        contactName: contactName,
        contactPhone: contactPhone,
        contactEmail: contactEmail,
        contactNickName: contactNickName,
        contactPosition: contactPosition,
        contactDepartment: contactDepartment,
        payment: {
          accountName: accountName,
          banks: banks,
          note: paymentNote,
        },
        buyer: false,
        seller: true,
        note: note,
        label: label,
      };
    }

    setButtonClick(true);
    setShowMessageBarSuccess(false);
    setShowMessageBarError(false);
    setShowMessageBarRepeatTaxId(false);
    setShowMessageBarRepeatOTHR(false);

    console.log('data', data);
    // You should see email and password in console.
    // ..code to submit form to backend here...

    if (isCorrect) {
      console.log('pass');
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          if (id !== '') {
            axios
              .put(API_URL + '/contacts/' + id, data, {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'Content-Type': 'application/json',
                  'cid': companyId
                },
              })
              .then(
                (response) => {
                  console.log('response from existing id', response);
                  setButtonClick(false);
                  setShowMessageBarSuccess(true);
                  window.scrollTo(0, 0);
                },
                (error) => {
                  console.log(error);
                  setButtonClick(false);
                  setShowMessageBarError(true);
                  window.scrollTo(0, 0);
                }
              );
          } else {
            if (contactList.some((contact) => taxId === contact.taxId && taxNumberType !== 'OTHR')) {
              window.scrollTo(0, 0);
              setButtonClick(false);
              setShowMessageBarRepeatTaxId(true);
            } else if (contactList.some((contact) => taxNumberType === 'OTHR' && taxNumberType === contact.taxNumberType && name === contact.name)) {
              window.scrollTo(0, 0);
              setButtonClick(false);
              setShowMessageBarRepeatOTHR(true);
            } else {
              window.scrollTo(0, 0);

              axios
                .post(API_URL + '/contacts', data, {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    'Content-Type': 'application/json',
                    'cid': companyId
                  },
                })
                .then(
                  (response) => {
                    console.log(response);
                    setButtonClick(false);

                    history.push({
                      pathname: '/contacts',
                      state: { addComplete: true },
                    });
                  },
                  (error) => {
                    console.log(error);
                    setButtonClick(false);
                    setShowMessageBarError(true);
                    window.scrollTo(0, 0);
                  }
                );
            }
          }
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    } else {
      setButtonClick(false);
    }
  }

  const handleBranch = (branchNum, lang) => {
    console.log('Change branch:', branchNum);
    if (branchNum === '00000') {
      setShowBranchNumber(false);
      setBranch('00000');
      setBranchNumber('00000');

      if (lang === 'en') {
        console.log('Set brach text English!');
        setBranchText('Head office');
      } else {
        setBranchText('สำนักงานใหญ่');
      }
    } else {
      if (branchNum !== '') {
        setBranch('branchNumber');
        if (branchNum === 'branchNumber') {
          setBranchNumber('00000');

          if (lang === 'en') {
            setBranchText('Branch 00000');
          } else {
            setBranchText('สาขา 00000');
          }
        } else {
          setBranchNumber(branchNum);

          if (lang === 'en') {
            setBranchText('Branch ' + branchNum);
          } else {
            setBranchText('สาขา ' + branchNum);
          }
        }
        setShowBranchNumber(true);
      }
    }

    if (lang === 'en') {
      setBranchDisplay([
        { key: '00000', text: 'Head office' },
        { key: 'branchNumber', text: 'Branch' },
      ]);
    } else {
      setBranchDisplay([
        { key: '00000', text: 'สำนักงานใหญ่' },
        { key: 'branchNumber', text: 'สาขาเลขที่' },
      ]);
    }
  };

  const handleChangeBankName = (index, event, selectedOption) => {
    console.log('Change index:', index);
    console.log('Event name: ', event);
    console.log('Selected option: ', selectedOption);
    const values = [...banks];
    if (selectedOption) {
      values[index]['bankCode'] = selectedOption.key;
      values[index]['bankName'] = selectedOption.text;
    }
    console.log('Banks: ', values);

    setBanks(values);
  };

  const handleChangeBankType = (index, event, selectedOption) => {
    console.log('Change index:', index);
    console.log('Event name: ', event);
    console.log('Selected option: ', selectedOption);
    const values = [...banks];
    if (selectedOption) {
      values[index]['typeCode'] = selectedOption.key;
      values[index]['typeName'] = selectedOption.text;
    }
    console.log('Banks: ', values);

    setBanks(values);
  };

  const handleChangeBank = (index, event, selectedOption) => {
    console.log('Change index:', index);
    console.log('Event name: ', event);
    console.log('Selected option: ', selectedOption);
    const values = [...banks];

    values[index][event.target.name] = event.target.value;

    console.log('Banks: ', values);

    setBanks(values);
  };

  const handleAddBank = () => {
    setBanks([
      ...banks,
      {
        bankCode: '',
        bankName: '',
        branch: '',
        typeCode: '',
        typeName: '',
        accountNumber: '',
      },
    ]);
  };

  const handleRemoveBank = (index) => {
    console.log('Delete index:', index);
    const values = [...banks];
    values.splice(index, 1);
    setBanks(values);
  };

  const getErrorMessage = (value) => {
    return value && value !== '0' ? '' : t('addContact.getErrorMessage');
  };

  const getTaxIdErrorMessage = (value) => {
    if (!value) return t('addContact.getErrorMessage');
    if (taxNumberType === 'TXID' || taxNumberType === 'NIDN') {
      if (isNaN(value)) return t('addContact.getBranchNumberErrorMessage1');
      if (value.length !== 13) return t('addContact.taxIdError');
    }
    return '';
  };

  const getBranchNumberErrorMessage = (value) => {
    if (!value) return t('addContact.getErrorMessage');
    if (isNaN(value)) return t('addContact.getBranchNumberErrorMessage1');
    if (value.length !== 5) return t('addContact.getBranchNumberErrorMessage2');
    return '';
  };

  const getPhoneNumberErrorMessage = (value) => {
    setPhoneNumberError(false);
    if (value === null || value === undefined) {
      setPhoneNumberError(true);
      return t('addContact.getErrorMessage');
    } else if (phoneRegEx.test(value) !== true) {
      setPhoneNumberError(true);
      return t('addContact.getPhoneNumberErrorMessage');
    }
  };

  const getPostCodeErrorMessage = (value) => {
    if (countryCode === 'TH') {
      if (isNaN(value)) return t('addContact.getPostCodeErrorMessage1');
      if (!value || value.length !== 5) return t('addContact.getPostCodeErrorMessage2');
    }
    return '';
  };

  let postCodeRef = useRef(false);

  useLayoutEffect(() => {
    if (isLoadComplete) {
      if (postCodeRef.current) {
        postCodeRef.focus();
        postCodeRef.blur();
      } else {
        postCodeRef.current = true;
        postCodeRef.focus();
        postCodeRef.blur();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  useLayoutEffect(() => {
    if (i18n.language === "en") {
      setLanguage('en');
      sessionStorage.setItem('address-language', 'en');
    } else {
      setLanguage('th');
      sessionStorage.setItem('address-language', 'th');
    }

  }, [i18n.language]);

  return (
    <Stack style={{ background: '#FAF9F8' }}>
      {showMessageBarSuccess && (
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={false}
          onDismiss={() => setShowMessageBarSuccess(false)}
          dismissButtonAriaLabel="Close"
        >
          {t('addContact.updateSuccess')}
        </MessageBar>
      )}
      {showMessageBarError && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarError(false)}
          dismissButtonAriaLabel="Close"
        >
          {t('addContact.updateFail')}
        </MessageBar>
      )}
      {showMessageBarRepeatTaxId && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarRepeatTaxId(false)}
          dismissButtonAriaLabel="Close"
        >
          {t('addContact.alreadyHaveTaxId')}
        </MessageBar>
      )}
      {showMessageBarRepeatOTHR && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarRepeatOTHR(false)}
          dismissButtonAriaLabel="Close"
        >
          {t('addContact.alreadyHaveContact')}
        </MessageBar>
      )}
      {showMessageBarSearchError && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarSearchError(false)}
          dismissButtonAriaLabel="Close"
        >
          {t('company.searchFail')}
        </MessageBar>
      )}
      {showMessageBarSearchNotFound && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarSearchNotFound(false)}
          dismissButtonAriaLabel="Close"
        >
          {t('company.searchNotFound')}
        </MessageBar>
      )}
      {buttonClick && data == null ? (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="certificates-loading-center">
              <Spinner label={t('addContact.processing')} labelPosition="down" styles={spinnerStyles} />
            </div>
            <br />
          </Stack>
        </Stack>
      ) : (
        <form onSubmit={handleSubmit}>
          <Stack horizontal horizontalAlign="space-between">
            <h2 style={{ marginLeft: '0', marginTop: '27px' }}>
              <Icon iconName="Contact" style={{ color: '#106ebe' }} /> {t('addContact.header')}
            </h2>
            <Toggle
              styles={{ root: { marginTop: 17 } }}
              inlineLabel
              label={t('addContact.english')}
              onText={t('addContact.on')}
              offText={t('addContact.off')}
              checked={language === 'en' ? true : false}
              onChange={() => {
                if (language === 'en') {
                  setLanguage('th');
                } else {
                  setLanguage('en');
                }
              }}
            />
          </Stack>
          <div style={{ marginLeft: '0' }}>{t('addContact.headerDescription')}</div>
          {isLoadComplete ? (
            <div style={cardStyle}>
              <strong>{t('addContact.companyInfo')}</strong>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 10 } }}>
                <Dropdown
                  placeholder={t('addContact.pleaseSelect')}
                  label={t('addContact.taxNumberType')}
                  options={[
                    { key: 'TXID', text: t('addContact.txid') },
                    { key: 'NIDN', text: t('addContact.nidn') },
                    { key: 'CCPT', text: t('addContact.ccpt') },
                  ]}
                  selectedKey={taxNumberType}
                  styles={{ root: { width: 170 } }}
                  onChange={(e, selectedOption) => {
                    handleTaxType(selectedOption);
                  }}
                  required
                />

                {taxNumberType !== 'OTHR' && (
                  <TextField
                    componentRef={(input) => (taxIdRef = input)}
                    name="taxId"
                    label={t('addContact.taxId')}
                    styles={{ root: { width: 120 } }}
                    value={taxId}
                    onChange={(e) => {
                      if (taxNumberType === 'TXID' || taxNumberType === 'NIDN') {
                        if (!isNaN(e.target.value)) {
                          setTaxId(e.target.value);
                        }
                      } else {
                        setTaxId(e.target.value);
                      }
                    }}
                    onBlur={(e) => {
                      if (!e.target.value.trim()) {
                        setTaxId('');
                      } else {
                        setTaxId(e.target.value.trim());
                      }
                    }}
                    onGetErrorMessage={getTaxIdErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                )}
                {taxNumberType === 'TXID' && (
                  <>
                    <Dropdown
                      placeholder={t('addContact.pleaseSelect')}
                      label={t('addContact.branchDisplay')}
                      options={branchDisplay}
                      selectedKey={branch}
                      styles={{ root: { width: 120 } }}
                      onChange={(e, selectedOption) => handleBranch(selectedOption.key, language)}
                    />
                    {showBranchNumber && (
                      <TextField
                        componentRef={(input) => (branchNumberRef = input)}
                        name="branchNumber"
                        label={t('addContact.branchNumber')}
                        description={t('addContact.branchNumberDesc')}
                        styles={{ root: { width: 60 } }}
                        value={branchNumber}
                        required={showBranchNumber}
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            setBranchNumber(e.target.value);

                            if (language === 'en') {
                              setBranchText('Branch ' + e.target.value);
                            } else {
                              setBranchText('สาขา ' + e.target.value);
                            }
                          }
                        }}
                        onBlur={(e) => {
                          if (!e.target.value.trim()) {
                            setBranchNumber('');
                          } else {
                            setBranchNumber(e.target.value.trim());

                            if (language === 'en') {
                              setBranchText('Branch ' + e.target.value.trim());
                            } else {
                              setBranchText('สาขา ' + e.target.value.trim());
                            }
                          }
                        }}
                        onGetErrorMessage={getBranchNumberErrorMessage}
                        validateOnLoad={false}
                        validateOnFocusOut
                      />
                    )}
                  </>
                )}
                <PrimaryButton
                  styles={{ root: { marginTop: 28.4 } }}
                  text={t('addContact.search')}
                  disabled={!isSearchCompleate || !structure}
                  iconProps={{ iconName: 'Search' }}
                  onClick={() => handleSearch()}
                />
              </Stack>
              {isSearchCompleate ? (
                <Stack horizontal tokens={stackTokens}>
                  <Stack>
                    {(taxNumberType === 'TXID' || taxNumberType === 'OTHR' || taxNumberType === 'CCPT') && (
                      <TextField
                        name="name"
                        label={t('addContact.companyName')}
                        styles={{ root: { width: 400 } }}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onGetErrorMessage={getErrorMessage}
                        validateOnLoad={false}
                        validateOnFocusOut
                        required
                      />
                    )}
                    {taxNumberType === 'NIDN' && (
                      <TextField
                        name="name"
                        label={t('addContact.name')}
                        styles={{ root: { width: 400 } }}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onGetErrorMessage={getErrorMessage}
                        validateOnLoad={false}
                        validateOnFocusOut
                        required
                      />
                    )}
                  </Stack>
                  <Dropdown
                    name="label"
                    label={t('addPerson.label')}
                    options={dropdownLabelOptions}
                    selectedKey={label.key}
                    onChange={(e, selectedOption) => {
                      setLabel(selectedOption);
                    }}
                    styles={{ root: { width: 200 } }}
                  />
                </Stack>
              ) : (
                <Stack styles={{ root: { marginTop: 28.4 } }}>
                  <Shimmer shimmerElements={shimmerRowHeight32} width="617px" />
                </Stack>
              )}
            </div>
          ) : (
            <div style={cardStyle}>
              <strong>{t('addContact.companyInfo')}</strong>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 38.4 } }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="170px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="120px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="120px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="100px" />
              </Stack>
              <Stack styles={{ root: { marginTop: 28.4 } }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="617px" />
              </Stack>
            </div>
          )}

          <br />
          {isLoadComplete && isSearchCompleate ? (
            <div style={cardStyle}>
              <strong>{t('addContact.addressHeader')}</strong>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 10 } }}>
                {/* <Dropdown
                  name="structure"
                  placeholder="กรุณาเลือก"
                  label="โครงสร้างที่อยู่"
                  options={[
                    { key: true, text: 'แบบมีโครงสร้าง' },
                    { key: false, text: 'แบบไม่มีโครงสร้าง' },
                  ]}
                  selectedKey={structure}
                  styles={{ root: { width: 150 } }}
                  onChange={(e, selectedOption) => {
                    handleStructure(selectedOption.key);
                  }}
                /> */}
                <Toggle
                  inlineLabel
                  label={t('addContact.addressStructure')}
                  onText={t('addContact.on')}
                  offText={t('addContact.off')}
                  checked={structure}
                  onChange={() => {
                    handleStructure(!structure);
                  }}
                />
                {/* <Dropdown
                      name="language"
                      placeholder="กรุณาเลือก"
                      label="แสดงชื่อจังหวัด อำเภอฯ"
                      options={[
                        { key: 'th', text: 'ภาษาไทย' },
                        { key: 'en', text: 'ภาษาอังกฤษ' },
                      ]}
                      selectedKey={language}
                      styles={{ root: { width: 150 } }}
                      onChange={(e, selectedOption) => {
                        setLanguage(selectedOption.key);
                      }}
                    /> */}
              </Stack>
              {structure && (
                <>
                  <Stack horizontal tokens={stackTokens}>
                    <TextField
                      name="buildingNumber"
                      label={t('addContact.buildingNumber')}
                      styles={{ root: { width: 150 } }}
                      value={buildingNumber}
                      onChange={(e) => setBuildingNumber(e.target.value)}
                      onGetErrorMessage={getErrorMessage}
                      validateOnLoad={false}
                      validateOnFocusOut
                      required
                    />
                    <TextField
                      name="address"
                      label={t('addContact.address')}
                      styles={{ root: { width: 450 } }}
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Stack>
                  <Stack horizontal tokens={stackTokens}>
                    <Dropdown
                      name="streetPrefix"
                      placeholder={t('addContact.pleaseSelect')}
                      label={t('addContact.streetPrefix')}
                      options={[
                        { key: 'ถ.', text: 'ถ.' },
                        { key: 'ถนน', text: 'ถนน' },
                        { key: '', text: t('addContact.notDisplay') },
                      ]}
                      selectedKey={streetPrefix}
                      styles={{ root: { width: 150 } }}
                      onChange={(e, selectedOption) => {
                        setStreetPrefix(selectedOption.key);
                      }}
                    />
                    <TextField
                      name="streetName"
                      label={t('addContact.streetName')}
                      styles={{ root: { width: 450 } }}
                      value={streetName}
                      onChange={(e) => setStreetName(e.target.value)}
                    />
                  </Stack>
                  {/* <Stack horizontal tokens={stackTokens}>
                    <Dropdown
                      name="language"
                      placeholder="กรุณาเลือก"
                      label="แสดงชื่อจังหวัด อำเภอฯ"
                      options={[
                        { key: 'th', text: 'ภาษาไทย' },
                        { key: 'en', text: 'ภาษาอังกฤษ' },
                      ]}
                      selectedKey={language}
                      styles={{ root: { width: 150 } }}
                      onChange={(e, selectedOption) => {
                        setLanguage(selectedOption.key);
                      }}
                    />
                  </Stack> */}
                  <Stack horizontal tokens={stackTokens}>
                    <Dropdown
                      name="provincePrefix"
                      placeholder={t('addContact.pleaseSelect')}
                      label={t('addContact.provincePrefix')}
                      options={[
                        { key: 'จ.', text: 'จ.' },
                        { key: 'จังหวัด', text: 'จังหวัด' },
                        { key: '', text: t('addContact.notDisplay') },
                      ]}
                      selectedKey={provincePrefix}
                      styles={{ root: { width: 150 } }}
                      onChange={(e, selectedOption) => {
                        setProvincePrefix(selectedOption.key);
                      }}
                    />
                    <Dropdown
                      componentRef={(input) => (provinceRef = input)}
                      placeholder={t('addContact.pleaseSelect')}
                      label={t('addContact.province')}
                      options={provinceList}
                      selectedKey={province.key}
                      styles={dropdownStyles}
                      onChange={(e, selectedOption) => {
                        console.log(selectedOption);
                        setProvince(selectedOption);
                        setDistrict({ key: '', text: '' });
                        setDistrictList(getDistrictList(selectedOption.key, language));
                        setSubDistrict({ key: '', text: '' });
                        console.log('Province changed', districtList);

                        setProvinceErrMsg('');
                        setDistrictErrMsg('');
                        setSubDistrictErrMsg('');
                      }}
                      onBlur={() => {
                        console.log(province.key);
                        if (province.key === '') {
                          setProvinceErrMsg(DropdownErrMsg);
                        } else {
                          setProvinceErrMsg('');
                        }
                      }}
                      errorMessage={provinceErrMsg}
                      required
                    />
                  </Stack>

                  <Stack horizontal tokens={stackTokens}>
                    <Dropdown
                      name="districtPrefix"
                      placeholder={t('addContact.pleaseSelect')}
                      label={t('addContact.districtPrefix')}
                      options={[
                        { key: 'อ.', text: 'อ.' },
                        { key: 'อำเภอ', text: 'อำเภอ' },
                        { key: 'เขต', text: 'เขต' },
                        { key: '', text: t('addContact.notDisplay') },
                      ]}
                      selectedKey={districtPrefix}
                      styles={{ root: { width: 150 } }}
                      onChange={(e, selectedOption) => {
                        setDistrictPrefix(selectedOption.key);
                      }}
                    />
                    <Dropdown
                      componentRef={(input) => (districtRef = input)}
                      placeholder={t('addContact.pleaseSelect')}
                      label={t('addContact.district')}
                      options={districtList}
                      selectedKey={district.key}
                      styles={dropdownStyles}
                      onChange={(e, selectedOption) => {
                        setDistrict(selectedOption);
                        setSubDistrict({ key: '', text: '' });
                        setSubDistrictList(getSubDistrictList(selectedOption.key, language));
                        console.log('District changed', subDistrictList);

                        setDistrictErrMsg('');
                        setSubDistrictErrMsg('');
                      }}
                      onBlur={(e) => {
                        console.log(district.key);
                        if (province.key !== '' && district.key === '') {
                          setDistrictErrMsg(DropdownErrMsg);
                        } else {
                          setDistrictErrMsg('');
                        }
                      }}
                      errorMessage={districtErrMsg}
                      required
                    />
                  </Stack>

                  <Stack horizontal tokens={stackTokens}>
                    <Dropdown
                      name="subDistrictPrefix"
                      placeholder={t('addContact.pleaseSelect')}
                      label={t('addContact.subDistrictPrefix')}
                      options={[
                        { key: 'ต.', text: 'ต.' },
                        { key: 'ตำบล', text: 'ตำบล' },
                        { key: 'แขวง', text: 'แขวง' },
                        { key: '', text: t('addContact.notDisplay') },
                      ]}
                      selectedKey={subDistrictPrefix}
                      styles={{ root: { width: 150 } }}
                      onChange={(e, selectedOption) => {
                        setSubDistrictPrefix(selectedOption.key);
                      }}
                    />
                    <Dropdown
                      componentRef={(input) => (subDistrictRef = input)}
                      placeholder={t('addContact.pleaseSelect')}
                      label={t('addContact.subDistrict')}
                      options={subDistrictList}
                      selectedKey={subDistrict.key}
                      styles={dropdownStyles}
                      onChange={(e, selectedOption) => {
                        setSubDistrict(selectedOption);

                        setSubDistrictErrMsg('');
                      }}
                      onBlur={(e) => {
                        console.log(subDistrict.key);
                        if (district.key !== '' && subDistrict.key === '') {
                          setSubDistrictErrMsg(DropdownErrMsg);
                        } else {
                          setSubDistrictErrMsg('');
                        }
                      }}
                      errorMessage={subDistrictErrMsg}
                      required
                    />
                  </Stack>
                </>
              )}

              {!structure && (
                <>
                  <Stack horizontal tokens={stackTokens}>
                    <TextField
                      name="lineOne"
                      label={t('addContact.lineOne')}
                      styles={{ root: { width: 610 } }}
                      onChange={(e) => {
                        setLineOne(e.target.value);
                      }}
                      onBlur={(e) => {
                        if (!e.target.value.trim()) {
                          setLineOne('');
                        } else {
                          setLineOne(e.target.value.trim());
                        }
                      }}
                      value={lineOne}
                      onGetErrorMessage={getErrorMessage}
                      validateOnLoad={false}
                      validateOnFocusOut
                      required
                    />
                  </Stack>
                  <Stack horizontal tokens={stackTokens}>
                    <TextField
                      name="lineTwo"
                      label={t('addContact.lineTwo')}
                      styles={{ root: { width: 610 } }}
                      onChange={(e) => {
                        setLineTwo(e.target.value);
                      }}
                      onBlur={(e) => {
                        if (!e.target.value.trim()) {
                          setLineTwo('');
                        } else {
                          setLineTwo(e.target.value.trim());
                        }
                      }}
                      value={lineTwo}
                    />
                  </Stack>
                </>
              )}
              <Stack horizontal tokens={stackTokens}>
                <TextField
                  name="postcode"
                  label={t('addContact.postcode')}
                  componentRef={(input) => (postCodeRef = input)}
                  styles={{ root: { width: 150 } }}
                  onChange={(e) => {
                    setPostCode(e.target.value);
                  }}
                  onBlur={(e) => {
                    if (!e.target.value.trim()) {
                      setPostCode('');
                    } else {
                      setPostCode(e.target.value.trim());
                    }
                  }}
                  value={postcode}
                  onGetErrorMessage={getPostCodeErrorMessage}
                  validateOnLoad={false}
                  validateOnFocusOut
                  required
                />
              </Stack>
              {!structure && (
                <>
                  <Stack horizontal tokens={stackTokens}>
                    <Dropdown
                      name="countryCode"
                      placeholder={t('addContact.pleaseSelect')}
                      label={t('addContact.country')}
                      options={countryList}
                      selectedKey={countryCode}
                      styles={{ root: { width: 150 } }}
                      onChange={(e, selectedOption) => {
                        setCountryCode(selectedOption.key);
                        setCountryName(selectedOption.text);
                      }}
                    />
                  </Stack>
                </>
              )}
            </div>
          ) : (
            <div style={cardStyle}>
              <strong>{t('addContact.addressHeader')}</strong>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 10 } }}>
                <Shimmer shimmerElements={shimmerRowHeight37} width="350px" />
              </Stack>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 28.4 } }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="150px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="450px" />
              </Stack>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 28.4 } }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="150px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="450px" />
              </Stack>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 28.4 } }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="150px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="450px" />
              </Stack>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 28.4 } }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="150px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="450px" />
              </Stack>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 28.4 } }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="150px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="450px" />
              </Stack>
              <Shimmer styles={{ root: { marginTop: 28.4 } }} shimmerElements={shimmerRowHeight32} width="150px" />
            </div>
          )}

          <br />

          {isLoadComplete ? (
            <div style={cardStyle}>
              <strong>{t('addContact.contactHeader')}</strong>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 10 } }}>
                <TextField
                  name="email"
                  label={t('addContact.email')}
                  styles={{ root: { width: 300 } }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  componentRef={(input) => (phoneRef = input)}
                  name="phone"
                  label={t('addContact.phone')}
                  styles={{ root: { width: 300 } }}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  onGetErrorMessage={getPhoneNumberErrorMessage}
                />
              </Stack>
              <Stack horizontal tokens={stackTokens}>
                <TextField
                  name="website"
                  label={t('addContact.website')}
                  styles={{ root: { width: 300 } }}
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                />
                <TextField
                  name="faxNumber"
                  label={t('addContact.fax')}
                  styles={{ root: { width: 300 } }}
                  value={fax}
                  onChange={(e) => setFax(e.target.value)}
                />
              </Stack>
              <TextField
                name="note"
                label={t('addContact.note')}
                multiline
                autoAdjustHeight
                styles={{ root: { width: 610 } }}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
          ) : (
            <div style={cardStyle}>
              <strong>{t('addContact.contactHeader')}</strong>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 38.4 } }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
              </Stack>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 28.4 } }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
              </Stack>
              <Shimmer styles={{ root: { marginTop: 28.4 } }} shimmerElements={shimmerRowHeight62} width="610px" />
            </div>
          )}

          <br />

          {isLoadComplete ? (
            <div style={cardStyle}>
              <strong>{t('addContact.contactHeader2')}</strong>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 10 } }}>
                <TextField
                  name="name"
                  label={t('addContact.contactName')}
                  styles={{ root: { width: 300 } }}
                  value={contactName}
                  onChange={(e) => setContactName(e.target.value)}
                />
                <TextField
                  name="contactNickName"
                  label={t('addContact.contactNickName')}
                  styles={{ root: { width: 300 } }}
                  value={contactNickName}
                  onChange={(e) => setContactNickName(e.target.value)}
                />
              </Stack>
              <Stack horizontal tokens={stackTokens}>
                <TextField
                  name="contactEmail"
                  label={t('addContact.contactEmail')}
                  styles={{ root: { width: 300 } }}
                  value={contactEmail}
                  onChange={(e) => setContactEmail(e.target.value)}
                />
                <TextField
                  name="contactPhone"
                  label={t('addContact.contactPhone')}
                  styles={{ root: { width: 300 } }}
                  value={contactPhone}
                  onChange={(e) => setContactPhone(e.target.value)}
                />
              </Stack>
              <Stack horizontal tokens={stackTokens}>
                <TextField
                  name="contactDepartment"
                  label={t('addContact.contactDepartment')}
                  styles={{ root: { width: 300 } }}
                  value={contactDepartment}
                  onChange={(e) => setContactDepartment(e.target.value)}
                />
                <TextField
                  name="contactPosition"
                  label={t('addContact.contactPosition')}
                  styles={{ root: { width: 300 } }}
                  value={contactPosition}
                  onChange={(e) => setContactPosition(e.target.value)}
                />
              </Stack>
            </div>
          ) : (
            <div style={cardStyle}>
              <strong>{t('addContact.contactHeader2')}</strong>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 38.4 } }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
              </Stack>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 28.4 } }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
              </Stack>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 28.4 } }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
              </Stack>
            </div>
          )}

          <br />

          {isLoadComplete ? (
            <div style={cardStyle}>
              <strong>{t('addContact.paymentHeader')}</strong>
              <Stack vertical styles={{ root: { marginTop: 10 } }}>
                <TextField
                  name="accountName"
                  label={t('addContact.accountName')}
                  styles={{ root: { width: 285, marginRight: 15 } }}
                  onChange={(e) => {
                    setAccountName(e.target.value);
                  }}
                  value={accountName}
                />
                {banks.map((bank, index) => {
                  return (
                    <div key={index}>
                      <Stack horizontal>
                        <br />
                        <Dropdown
                          placeholder={t('addContact.pleaseSelect')}
                          label={t('addContact.bankName')}
                          options={bankList}
                          styles={{ root: { width: 150, marginRight: 15 } }}
                          selectedKey={banks[index]['bankCode']}
                          onChange={(event, selectedOption) => {
                            console.log(selectedOption);
                            handleChangeBankName(index, event, selectedOption);
                          }}
                        />
                        <TextField
                          name="branch"
                          label={t('addContact.bankBranch')}
                          styles={{ root: { width: 120, marginRight: 15 } }}
                          onChange={(event) => {
                            handleChangeBank(index, event);
                          }}
                          value={banks[index]['branch']}
                        />
                        <Dropdown
                          placeholder={t('addContact.pleaseSelect')}
                          label={t('addContact.accountType')}
                          options={accountTypeList}
                          styles={{ root: { width: 120, marginRight: 15 } }}
                          selectedKey={banks[index]['typeCode']}
                          onChange={(event, selectedOption) => {
                            console.log(selectedOption);
                            handleChangeBankType(index, event, selectedOption);
                          }}
                        />
                        <TextField
                          name="accountNumber"
                          label={t('addContact.accountNumber')}
                          styles={{ root: { width: 150 } }}
                          onChange={(event) => {
                            handleChangeBank(index, event);
                          }}
                          value={banks[index]['accountNumber']}
                        />
                        <IconButton
                          iconProps={{ iconName: 'Delete' }}
                          title={t('addContact.delete')}
                          style={{ marginTop: 30 }}
                          onClick={() => handleRemoveBank(index)}
                        />
                      </Stack>
                    </div>
                  );
                })}
                <Stack horizontal>
                  <br />
                </Stack>
                <Stack horizontal horizontalAlign="center">
                  <br />
                  <PrimaryButton iconProps={{ iconName: 'Add' }} name="addBank" text={t('addContact.addBank')} onClick={() => handleAddBank()} />
                  <br />
                </Stack>
                <Stack horizontal>
                  <br />
                </Stack>
                <TextField
                  name="paymentNote"
                  label={t('addContact.paymentNote')}
                  styles={{ root: { width: 610 } }}
                  onChange={(e) => {
                    setPaymentNote(e.target.value);
                  }}
                  value={paymentNote}
                />
              </Stack>
            </div>
          ) : (
            <div style={cardStyle}>
              <strong>{t('addContact.paymentHeader')}</strong>
              <Stack vertical styles={{ root: { marginTop: 10 } }}>
                <Shimmer styles={{ root: { marginTop: 28.4 } }} shimmerElements={shimmerRowHeight32} width="285px" />
                <Stack horizontal>
                  <Shimmer styles={{ root: { marginTop: 28.4, marginRight: 15 } }} shimmerElements={shimmerRowHeight32} width="150px" />
                  <Shimmer styles={{ root: { marginTop: 28.4, marginRight: 15 } }} shimmerElements={shimmerRowHeight32} width="120px" />
                  <Shimmer styles={{ root: { marginTop: 28.4, marginRight: 15 } }} shimmerElements={shimmerRowHeight32} width="120px" />
                  <Shimmer styles={{ root: { marginTop: 28.4, marginRight: 5 } }} shimmerElements={shimmerRowHeight32} width="150px" />
                  <Shimmer styles={{ root: { marginTop: 28.4 } }} shimmerElements={shimmerRowHeight32} width="16px" />
                </Stack>
                <Stack horizontal horizontalAlign="center">
                  <br />
                  <Shimmer styles={{ root: { marginTop: 20 } }} shimmerElements={shimmerRowHeight32} width="140px" />
                  <br />
                </Stack>
                <Stack>
                  <Shimmer styles={{ root: { marginTop: 58.4 } }} shimmerElements={shimmerRowHeight32} width="610px" />
                </Stack>
              </Stack>
            </div>
          )}

          <br />
          <br />
          {!buttonClick && (
            <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
              <PrimaryButton text={t('addContact.save')} iconProps={{ iconName: 'Save' }} type="submit" value="next" />
              <DefaultButton text={t('addContact.back')} onClick={() => history.push('/contacts')} />
            </Stack>
          )}
          {buttonClick && <Spinner label={t('addContact.loading')} labelPosition="right" />}
          <br />
          <br />
        </form>
      )}
    </Stack>
  );
};

export default AddContacts;
