import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Stack, Icon, PrimaryButton, TextField, FocusTrapZone, Spinner, Dropdown, DefaultButton } from '@fluentui/react';

const API_URL = process.env.REACT_APP_API_URL;

// const msClientId = "91f9f531-4187-4603-bb50-bbe13e18bc7c"
const redirectUriMSOneDrive = "http://localhost:3000/settings/storages"
const callBackUrlMSOneDrive = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=91f9f531-4187-4603-bb50-bbe13e18bc7c&response_type=code&redirect_uri=${redirectUriMSOneDrive}&response_mode=query&scope=https://graph.microsoft.com/files.readwrite https://graph.microsoft.com/User.Read offline_access`

const redirectUriGoogleDrive = "http://localhost:3000/settings/storages"
const callBackUrlGoogleDrive = `https://accounts.google.com/o/oauth2/auth?client_id=276206940953-38dotjr1e7fe2mabt4sd1f098tq2lj23.apps.googleusercontent.com&response_type=code&redirect_uri=${redirectUriGoogleDrive}&response_mode=query&scope=https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.file`

// Processing dialog style
const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const centerItem = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const StoragesConnect = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const { t } = useTranslation();

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);

  const [status, setStatus] = useState('');

  const [accessKeyId, setAccessKeyId] = useState('');
  const [secretAccessKey, setSecretAccessKey] = useState('');

  const [storage, setStorage] = useState(sessionStorage.getItem('storage-key') || 'amazon');
  const [region, setRegion] = useState(sessionStorage.getItem('storage-region') || 'ap-southeast-1');
  const [endpoint, setEndpoint] = useState(sessionStorage.getItem('storage-endpoint') || 's3.ap-southeast-1.amazonaws.com');

  const [buckets, setBuckets] = useState([])
  const [selectedBucket, setSelectedBucket] = useState(null)

  // const [backup, setBackup] = useState('true')

  const companyId = sessionStorage.getItem('companyId') || '';

  const endpointAmazonS3 = [
    { key: "s3.ap-southeast-1.amazonaws.com", text: "ap-southeast-1" },
    { key: "s3.ap-southeast-2.amazonaws.com", text: "ap-southeast-2" },
    { key: "s3.ap-southeast-3.amazonaws.com", text: "ap-southeast-3" },
    { key: "s3.ap-southeast-4.amazonaws.com", text: "ap-southeast-4" },
    { key: "s3.ap-northeast-1.amazonaws.com", text: "ap-northeast-1" },
    { key: "s3.ap-northeast-2.amazonaws.com", text: "ap-northeast-2" },
    { key: "s3.ap-northeast-3.amazonaws.com", text: "ap-northeast-3" },
    { key: "s3.ap-south-1.amazonaws.com", text: "ap-south-1" },
    { key: "s3.ap-south-2.amazonaws.com", text: "ap-south-2" },
    { key: "s3.ap-east-1.amazonaws.com", text: "ap-east-1" },
    { key: "s3.us-east-2.amazonaws.com", text: "us-east-2" },
    { key: "s3.us-east-1.amazonaws.com", text: "us-east-1" },
    { key: "s3.us-west-1.amazonaws.com", text: "us-west-1" },
    { key: "s3.us-west-2.amazonaws.com", text: "us-west-2" },
    { key: "s3.us-gov-east-1.amazonaws.com", text: "us-gov-east-1" },
    { key: "s3.us-gov-west-1.amazonaws.com", text: "us-gov-west-1" },
    { key: "s3.af-south-1.amazonaws.com", text: "af-south-1" },
    { key: "s3.ca-central-1.amazonaws.com", text: "ca-central-1" },
    { key: "s3.cn-north-1.amazonaws.com", text: "cn-north-1" },
    { key: "s3.cn-northwest-1.amazonaws.com", text: "cn-northwest-1" },
    { key: "s3.eu-central-1.amazonaws.com", text: "eu-central-1" },
    { key: "s3.eu-west-1.amazonaws.com", text: "eu-west-1" },
    { key: "s3.eu-west-2.amazonaws.com", text: "eu-west-2" },
    { key: "s3.eu-south-1.amazonaws.com", text: "eu-south-1" },
    { key: "s3.eu-west-3.amazonaws.com", text: "eu-west-3" },
    { key: "s3.eu-north-1.amazonaws.com", text: "eu-north-1" },
    { key: "s3.eu-south-2.amazonaws.com", text: "eu-south-2" },
    { key: "s3.eu-central-2.amazonaws.com", text: "eu-central-2" },
    { key: "s3.sa-east-1.amazonaws.com", text: "sa-east-1" },
    { key: "s3.me-south-1.amazonaws.com", text: "me-south-1" },
    { key: "s3.me-central-1.amazonaws.com", text: "me-central-1" }
  ];

  const endpointWasabiS3 = [
    { key: "s3.ap-southeast-1.wasabisys.com", text: "ap-southeast-1" },
    { key: "s3.ap-southeast-2.wasabisys.com", text: "ap-southeast-2" },
    { key: "s3.ap-northeast-1.wasabisys.com", text: "ap-northeast-1" },
    { key: "s3.ap-northeast-2.wasabisys.com", text: "ap-northeast-2" },
    { key: "s3.us-east-1.wasabisys.com", text: "us-east-1" },
    { key: "s3.us-east-2.wasabisys.com", text: "us-east-2" },
    { key: "s3.us-central-1.wasabisys.com", text: "us-central-1" },
    { key: "s3.us-west-1.wasabisys.com", text: "us-west-1" },
    { key: "s3.ca-central-1.wasabisys.com", text: "ca-central-1" },
    { key: "s3.eu-central-1.wasabisys.com", text: "eu-central-1" },
    { key: "s3.eu-central-2.wasabisys.com", text: "eu-central-2" },
    { key: "s3.eu-west-1.wasabisys.com", text: "eu-west-1" },
    { key: "s3.eu-west-2.wasabisys.com", text: "eu-west-2" }    
  ];

  // const [endpointWasabiS3, setEndpointWasabiS3] = useState([]);
  // const [endpointStorjS3, setEndpointStorjS3] = useState([]);
  const [optionsEndpointS3, setOptionsEndpointS3] = useState(() => {
    if (sessionStorage.getItem('storage-key') === 'amazon') {
      return endpointAmazonS3
    } else if (sessionStorage.getItem('storage-key') === 'wasabi') {
      return endpointWasabiS3
    } else if (sessionStorage.getItem('storage-key') === 'storj') {
      return [{key: "gateway.storjshare.io", text: "storjshare-gateway"}]
    } else {
      return endpointAmazonS3
    }
    
  });


  // useEffect(() => { 
  //   // setAccessKeyId('YHEIIS1VW7E80CRQOVQY')
  //   // setSecretAccessKey('eDCmEzNmSLy8Nb2hXL4tBFm2YBe4bHtlfqeJyrNz')
  //   // setEndpoint('s3.ap-southeast-1.wasabisys.com');
  //   // setStorage('wasabi')

  //   // setAccessKeyId('6YI3LXDC756TKFTB09QL')
  //   // setSecretAccessKey('fno3BBrM7frperhysTN34goiLLl137g7ZaSQ3rYB')
  //   // setStorage('wasabi')

  //   // setAccessKeyId('AKIATJSVGSYV65D7XJGE')
  //   // setSecretAccessKey('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')
  //   // setStorage('amazon')
  //   // setEndpoint('s3.ap-southeast-1.amazonaws.com');
  //   // setRegion('ap-southeast-2')

  //   // setAccessKeyId('jxr7uir6nxqcwiow226b6vf3drxa')
  //   // setSecretAccessKey('jytnnngkjtsthnek7fy2ahod5sqx2a6c6fnzjlt7wembwfwf7apcm')
  //   // setAccessKeyId('jw3ltrqsbqkgowk3tjsd3cumqzzq')
  //   // setSecretAccessKey('jzyn4qfxxbla6mu2yvzqk3t3vauoshhvo4qwkimhfqcq52nnc2prg')
  //   // setEndpoint('gateway.storjshare.io');
  //   // setStorage('storj')
  // }, [])

  useEffect(() => {
    console.log('userEffect retrieve users data!');

    const query = new URLSearchParams(window.location.search);
    const code = query.get('code');

    console.log('code: ', code);

    if (code) {
      // setIsLoading(true);

      if (storage === 'oneDrive') {
        instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .post(
              'http://localhost:7071/api/OneDrive_getAccessToken',
              {
                code: code,
                redirect_uri: redirectUriMSOneDrive
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId
                },
              }
            )
            .then(
              (response) => {
                console.log('OneDrive_getAccessToken response', response);

                if (response.data) {
                  // if (response.data.canLogin) {
                  //   history.push({
                  //     pathname: '/storages/one-drive',
                  //     state: {},
                  //   });
                  // } else {
                  //   setStatus('fail');
                  // }

                  axios
                    .post('http://localhost:7071/api/Storages', {...response.data, type: 'oneDrive'}, {
                      headers: {
                        // Authorization: 'Bearer ' + tokenResponse.accessToken,
                        // 'cid': companyId
                      },
                    }).then(res => {
                      console.log("response storage", res)
                      
                      history.push({
                        pathname: '/storages/one-drive',
                        state: { 
                          id: response.data.owner.user.id,
                        },
                      });
                    })

                }

              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });

      } else if (storage === 'googleDrive') {
        instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .post(
              'http://localhost:7071/api/GoogleDrive_getAccessToken',
              {
                code: code,
                redirect_uri: redirectUriGoogleDrive
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId
                },
              }
            )
            .then(
              (response) => {
                console.log('GoogleDrive_getAccessToken response', response);

                if (response.data) {
                  axios
                    .post('http://localhost:7071/api/Storages', {...response.data, type: 'googleDrive'}, {
                      headers: {
                        // Authorization: 'Bearer ' + tokenResponse.accessToken,
                        // 'cid': companyId
                      },
                    }).then(res => {

                      history.push({
                        pathname: '/storages/google-drive',
                        state: { 
                          id: response.data.user.emailAddress,
                          // name: response.data.name,
                          // owner: response.data.owner,
                          // type: 'googleDrive',
                          // user: response.data.user,
                          // accessToken: response.data.access_token
                        },
                      });

                    })



                }
                // if (response.data) {
                //   if (response.data.canLogin) {
                //     history.push({
                //       pathname: '/storages/one-drive',
                //       state: {},
                //     });
                //   } else {
                //     setStatus('fail');
                //   }
                // }

              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });

      }
    } else {
      // instance
      //   .acquireTokenSilent({ ...silentRequest, account: account })
      //   .then((tokenResponse) => {
      //     axios
      //       .get(API_URL + '/sources/lazada', {
      //         headers: {
      //           Authorization: 'Bearer ' + tokenResponse.accessToken,
      //           'cid': companyId
      //       },
      //       })
      //       .then(
      //         (response) => {
      //           console.log('Lazada sources: ', response);

      //           if (response.data) {
      //             if (!response.data.disabled && response.data.canLogin) {
      //               history.push({
      //                 pathname: '/etax/sources/lazada',
      //                 state: {},
      //               });
      //             }
      //           }

      //           setIsLoading(false);
      //         },
      //         (error) => {
      //           console.log(error);
      //         }
      //       );
      //   })
      //   .catch((error) => {
      //     //Acquire token silent failure, and send an interactive request
      //     console.log(error);
      //     instance.acquireTokenRedirect(silentRequest);
      //   });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
      axios
        .post(
          //   'http://localhost:7071/api/AWS_connect',
          // 'http://localhost:7071/api/AwsConnectionsCheck',
          API_URL + '/storages/s3/connections/check',
          {
            accessKeyId: accessKeyId,
            secretAccessKey: secretAccessKey,
            // storage: storage,
            region: region,
            endpoint: endpoint
          },
          {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              cid: companyId
            },
          }
        )
        .then((response) => {

          console.log('response', response);

          if (response.data.success) {
            if (response.data.Buckets.length) {
              setStatus('buckets')
              setBuckets(response.data.Buckets)
              setSelectedBucket(response.data.Buckets[0].Name)
            }
            // setStatus("success");
          } else {
            setStatus("fail");
          }

          setIsLoading(false);
        });
    }).catch((error) => {
      //Acquire token silent failure, and send an interactive request
      console.log(error);
      instance.acquireTokenRedirect(silentRequest);
    });
  };

  const handleSelectBucket = async (e, bucket) => {
    e.preventDefault();

    setIsLoading(true);

    instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
      axios
        .get(
          // 'http://localhost:7071/api/AWS_getObjectInBucket',
          // 'http://localhost:7071/api/AWS_uploadObject',
          'http://localhost:7071/api/AWS_listObjects',
          // 'http://localhost:7071/api/AwsObjectsList',
          {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              cid: companyId
            },
            params: {
              accessKeyId: accessKeyId,
              secretAccessKey: secretAccessKey,
              storage: storage,
              region: region,
              bucket: bucket,

              // ContinuationToken: moreContent.ContinuationToken,
              // StartAfter: moreContent.StartAfter
            }
          }
        )
        .then((response) => {
          
          console.log('response', response);

          // const {
          //   $metadata,
          //   Contents,
          //   Delimiter,
          //   IsTruncated,
          //   KeyCount,
          //   MaxKeys,
          //   Name,
          //   NextContinuationToken,
          //   Prefix
          // } = response.data

          // setMoreContent(prev => ({...prev, IsTruncated: IsTruncated, ContinuationToken: NextContinuationToken}))

          if (response.data.success) {
            // if (response.data.Buckets.length) {
            //   setStatus('buckets')
            //   setBuckets(response.data.Buckets)
            // }
            setStatus("success");

            axios
              .post('http://localhost:7071/api/Storages', {
                type: 's3',
                accessKeyId: accessKeyId,
                secretAccessKey: secretAccessKey,
                storage: storage,
                region: region,
                bucket: bucket
              }, {
                headers: {
                  // Authorization: 'Bearer ' + tokenResponse.accessToken,
                  // 'cid': companyId
                },
              }).then(res => {
                history.push({
                  pathname: '/storages',
                  state: {
                    id: bucket
                  },
                });
                
              })

          } else {
            setStatus("fail");
          }

          setIsLoading(false);
        });
         

      // axios
      //   .post(
      //     API_URL + '/sources/storages',
      //     {
      //       accessKeyId: accessKeyId,
      //       secretAccessKey: secretAccessKey,
      //       backup: backup,
      //       bucket: bucket,
      //       endpoint: endpoint,
      //       provider: storage,
      //       region: region
      //     },
      //     {
      //       headers: {
      //         Authorization: 'Bearer ' + tokenResponse.accessToken,
      //         cid: companyId
      //       },
      //     }
      //   )
      //   .then(
      //     (response) => {
      //       console.log('Storage source create response: ', response);

      //       history.push({
      //         pathname: '/storages',
      //         state: {},
      //       });

      //       // setIsLoading(false);

      //     },
      //     (error) => {
      //       console.log(error);

      //       setIsLoading(false);
      //     }
      //   );

    })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  useEffect(() => {
    setIsLoading(false);

    console.log('companyId: ', companyId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack>
      {!isLoading && (
        <Stack horizontalAlign="center">
          <br />

          <h1 style={{ marginLeft: '0px', marginTop: '27px' }}>
            <Icon iconName="CloudImportExport" style={{ color: '#106ebe' }} /> 
            {storage === 'googleDrive' && (' ' + t('storagesConnect.headerGoogleDrive')) } 
            {storage === 'oneDrive' && (' ' + t('storagesConnect.headerOneDrive')) }
            {(storage !== 'googleDrive' && storage !== 'oneDrive') && (' ' + t('storagesConnect.header')) }
          </h1>

          <h3>
          {t('storagesConnect.subHeader')}
          </h3>

          {!(status === 'buckets' && !isLoading) ? (
            <>
              <br />
            </>
          ) : null}

        </Stack>
      )}
      {status === '' && !isLoading && (
        <FocusTrapZone disabled={true}>
          <Stack horizontalAlign="center">
            <Stack
              style={{
                border: '1px solid rgb(134, 134, 134)',
                width: '350px',
                backgroundColor: '#FFFFFF',
              }}
            >
              <br />
              <Stack horizontal horizontalAlign="center">
                {(storage === 'amazon') &&<img src="/images/amazon-s3-logo.png" width="280px" style={{ margin: 20 }} alt="awsS3logo" />}
                {(storage === 'wasabi') &&<img src="/images/wasabi-logo.png" width="280px" style={{ margin: 20 }} alt="wasabi-logo" />}
                {(storage === 'storj') &&<img src="/images/storj-logo.png" width="280px" style={{ margin: 20 }} alt="storj-logo" />}
                {(storage === 'oneDrive') &&<img src="/images/OneDrive-Logo-1024x656.png" width="280px" style={{ margin: 20 }} alt="onedrive-logo" />}
                {(storage === 'googleDrive') &&<img src="/images/Google-Drive-Logo.png" width="280px" style={{ margin: 20 }} alt="googledrive-logo" />}
              </Stack>
              <form onSubmit={handleSubmit}>
                <Stack horizontal horizontalAlign="center">
                  <Dropdown
                    style={{ width: '280px' }}
                    label='Storage'
                    options={[
                      { key: "amazon", text: "Amazon" },
                      { key: "wasabi", text: "Wasabi" },
                      { key: "storj", text: "Storj" },
                      { key: "oneDrive", text: "Microsoft One Drive" },
                      { key: "googleDrive", text: "Google Drive" },
                    ]}
                    selectedKey={storage}
                    onChange={(e, option) => {
                      setStorage(option.key);

                      sessionStorage.setItem('storage-key', option.key)

                      switch (option.key) {
                        case 'amazon': 
                          setOptionsEndpointS3(endpointAmazonS3); 
                          setEndpoint('s3.ap-southeast-1.amazonaws.com');

                          sessionStorage.setItem('storage-endpoint', 's3.ap-southeast-1.amazonaws.com')
                          break;
                        case 'wasabi':
                          setOptionsEndpointS3(endpointWasabiS3); 
                          setEndpoint('s3.ap-southeast-1.wasabisys.com');

                          sessionStorage.setItem('storage-endpoint', 's3.ap-southeast-1.wasabisys.com')
                          break;
                        case 'storj':
                          setOptionsEndpointS3([{key: "gateway.storjshare.io", text: "storjshare-gateway"}]);
                          setEndpoint('gateway.storjshare.io');

                          sessionStorage.setItem('storage-endpoint', 'gateway.storjshare.io')
                          break;
                        default:
                          break;
                      }
                    
                    }}
                  />
                </Stack>
                {storage === 'googleDrive' && (
                  <>
                    <br />
                    <br />
                    <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}>
                      <PrimaryButton
                        style={{
                          fontSize: '20px',
                          height: '50px',
                          width: '280px',
                        }}
                        // type="submit"
                        href={callBackUrlGoogleDrive}
                      >
                        {t('storagesConnect.connect')}
                      </PrimaryButton>
                    </Stack>
                  </>
                )}
                {storage === 'oneDrive' && (
                  <>
                    <br />
                    <br />
                    <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}>
                      <PrimaryButton
                        style={{
                          fontSize: '20px',
                          height: '50px',
                          width: '280px',
                        }}
                        // type="submit"
                        href={callBackUrlMSOneDrive}
                      >
                        {t('storagesConnect.connect')}
                      </PrimaryButton>
                    </Stack>
                  </>
                )}
                {(storage !== 'oneDrive' && storage !== 'googleDrive') && (
                  <>
                    <Stack horizontal horizontalAlign="center">
                      <Dropdown
                        style={{ width: '280px' }}
                        label='Region'
                        options={optionsEndpointS3}
                        selectedKey={endpoint}
                        onChange={(e, option) => {
                          setRegion(option.text);
                          setEndpoint(option.key);

                          sessionStorage.setItem('storage-endpoint', option.key)
                          sessionStorage.setItem('storage-region', option.text)
                        }}
                      />
                    </Stack>                
                    <Stack horizontal horizontalAlign="center">
                      <TextField
                        label="Access Key Id"
                        required
                        onChange={(e) => {
                          setAccessKeyId(e.target.value);
                        }}
                        value={accessKeyId}
                        style={{ width: '280px' }}
                      />
                    </Stack>
                    <Stack horizontal horizontalAlign="center">
                      <TextField
                        label="Secret Access Key"
                        required
                        onChange={(e) => {
                          setSecretAccessKey(e.target.value);
                        }}
                        value={secretAccessKey}
                        style={{ width: '280px' }}
                      />
                    </Stack>
                    {/* <Stack style={{ margin: '35px 35px 10px 35px' }}>
                      <Separator>{t('storagesConnect.backupLong')}</Separator>
                    </Stack>
                    <Stack horizontal horizontalAlign="center">
                      <Dropdown
                        style={{ width: '280px' }}
                        label='Backup to Cloud Storage'
                        required
                        options={[
                          { key: "true", text: t('storagesConnect.backupTrue') },
                          { key: "false", text: t('storagesConnect.backupFalse') },
                        ]}
                        selectedKey={backup}
                        onChange={(e, option) => setBackup(option.key)}
                      />
                    </Stack> */}
                    <br />
                    <br />
                    <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}>
                      <PrimaryButton
                        style={{
                          fontSize: '20px',
                          height: '50px',
                          width: '280px',
                        }}
                        type="submit"
                      >
                        {t('storagesConnect.connect')}
                      </PrimaryButton>
                    </Stack>
                  </>
                )}
                
                
              </form>
              <br />
              <br />
            </Stack>
          </Stack>
        </FocusTrapZone>
      )}
      {status === 'buckets' && !isLoading && (
        <Stack>
          {/* <h2>{t('storagesConnect.selectBucket')}</h2> */}
          <Dropdown
            id="selectBucket"
            placeholder={t('storagesConnect.pleaseSelect')}
            label={t('storagesConnect.selectBucket')}
            options={buckets.map((_bucket, index) => {
              return { key: _bucket.Name, text: _bucket.Name }
            })}
            // styles={{
            //   root: {
            //     width: '20%',
            //   },
            // }}
            selectedKey={selectedBucket}
            onChange={(e, selectedOption) => {
              setSelectedBucket(selectedOption.key)
            }}
          />
          {/* {buckets.map((_bucket, index) => (
            <React.Fragment key={`bucket-${index}`}>
              <Stack horizontal horizontalAlign="start">
                <DefaultButton
                  style={{ fontSize: '20px', height: '70px', width: '400px', textAlign: 'left' }}
                  onClick={(e) => handleSelectBucket(e, _bucket.Name)}
                >
                  {_bucket.Name}
                </DefaultButton>
              </Stack>
              <br />
            </React.Fragment>
          ))} */}
          <br />
          <br />
          <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
            <PrimaryButton text={t('storagesConnect.connect')} style={{ width: '125px', letterSpacing: '1px' }} onClick={(e) => handleSelectBucket(e, selectedBucket)} />
            <DefaultButton text={t('storagesConnect.cancel')} onClick={() => setStatus('')} />
          </Stack>
          {/* <Stack horizontal horizontalAlign="start">
            <DefaultButton
              style={{ fontSize: '20px', height: '50px' }}
              onClick={() => setStatus('')}
            >
              {t('storagesConnect.back')}
            </DefaultButton>
          </Stack> */}
        </Stack>
      )}
      {isLoading && (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="certificates-loading-center">
              {false ? (
                <Spinner label={t('storagesConnect.processing')} labelPosition="down" styles={spinnerStyles} />
              ) : (
                <Spinner label={t('storagesConnect.loading')} labelPosition="down" styles={spinnerStyles} />
              )}
            </div>
            <br />
          </Stack>
        </Stack>
      )}

      {status === "success" && (
        <Stack horizontal horizontalAlign="center">
          <Stack style={{ border: "1px solid rgb(134, 134, 134)", width: "300px", backgroundColor: "#FFFFFF" }}>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1 style={{ margin: "0px", color: "#0078D4" }}>{t("storagesConnect.successMessage")}</h1>
            </Stack>
            <br />
            <Stack horizontal horizontalAlign="center">
              <img height="120" src="/images/success03.gif" alt="success03gif" />
            </Stack>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton
                style={{ fontSize: "20px", height: "50px" }}
                onClick={() => {
                  setStatus("connect");
                }}
              >
                {t("storagesConnect.details")}
              </PrimaryButton>
            </Stack>
            <br />
            <br />
          </Stack>
        </Stack>
      )}

      {status === 'fail' && (
        <Stack horizontal horizontalAlign="center">
          <Stack
            style={{
              border: '1px solid rgb(134, 134, 134)',
              width: '300px',
              backgroundColor: '#FFFFFF',
            }}
          >
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1 style={{ margin: '0px', color: '#F25139' }}>{t('storagesConnect.failMessage')}</h1>
            </Stack>
            <br />
            <Stack horizontal horizontalAlign="center">
              <img height="120" src="/images/fail01.gif" alt="fail01gif" />
            </Stack>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton
                style={{ fontSize: '20px', height: '50px' }}
                onClick={() => {
                  setStatus('');
                }}
              >
                {t('storagesConnect.failButton')}
              </PrimaryButton>
            </Stack>
            <br />
            <br />
          </Stack>
        </Stack>
      )}

      <br />
      <br />
      {/* {status === '' && !isLoading && (
        <center>
          <br />
          <br />
          <span style={{ color: 'gray' }}>{t('sapB1Connect.footer1')}</span>
          <br />
          <br />
          <span style={{ color: 'gray', fontWeight: 'bold' }}>{t('sapB1Connect.footer2')}</span>
        </center>
      )} */}
    </Stack>
  );
};

export default StoragesConnect;