import './excel.css'
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { parse } from 'papaparse';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { validateField } from './components/validate';

import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';
import { getUnitByNameWithoutLanguage } from '../../../providers/UnitData';
import qrCode from 'qrcode';
import generatePayload from 'promptpay-qr';

import { Stack, Spinner, Link, Dialog, Icon, DefaultButton, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import { getCountryCodeWithoutLanguage } from '../../../providers/CountryData';

var moment = require('moment'); // require
moment().format();

const xlsx = require('xlsx');

const API_URL = process.env.REACT_APP_API_URL;

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== '') return false;
  }
  return true;
}

const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

function checkDateFormat(str) {
  if (isNaN(str)) {
    let datesplit = str.split('/');

    if (datesplit[0].length === 1) {
      datesplit[0] = '0' + datesplit[0];
    }

    if (datesplit[1].length === 1) {
      datesplit[1] = '0' + datesplit[1];
    }

    if (
      datesplit[0].length === 2 &&
      !isNaN(datesplit[0]) &&
      datesplit[1].length === 2 &&
      !isNaN(datesplit[1]) &&
      datesplit[2].length === 4 &&
      !isNaN(datesplit[2])
    )
      return true;
  }
  return false;
}

function checkExcelDateFormat(number) {
  return new Date(Math.round((number - 25569) * 86400 * 1000));
}

function getDateFromNewDateFormat(number, columnName) {
  const _dateFormatString = String(number).trim();

  const _yearFormat = _dateFormatString.substring(0, 4);
  const _monthFormat = _dateFormatString.substring(4, 6);
  const _dateFormat = _dateFormatString.substring(6, 8);

  const _dateObject = new Date(`${_yearFormat}-${_monthFormat}-${_dateFormat}`);

  const _year = _dateObject.getFullYear();
  const _month = String(_dateObject.getMonth() + 1).padStart(2, '0');
  const _date = String(_dateObject.getDate()).padStart(2, '0');

  if (columnName === 'dateBE') {
    let _dateBE = '';
    if (_year < 2100 && _year + 543 > 2563) {
      _dateBE = _date + '/' + _month + '/' + (_year + 543);
    } else {
      _dateBE = _date + '/' + _month + '/' + _year;
    }

    return _dateBE;
  } else if (columnName === 'dateCE') {
    let _dateCE = '';
    if (_year < 2100 && _year + 543 > 2563) {
      _dateCE = _date + '/' + _month + '/' + _year;
    } else {
      _dateCE = _date + '/' + _month + '/' + (_year - 543);
    }

    return _dateCE;
  } else if (columnName === 'date') {
    const dateMoment = moment(_dateObject).subtract(14, 'h');
    const dateSplit = dateMoment.format().split('+');

    return dateSplit[0] + '.000Z';
  }
}

function mathRound(number) {
  return Math.round(number * 100) / 100
}

const ExcelContext = React.createContext();

const newHeaders = [
  'order',
  'documentType',
  'number',
  'dateBE',
  'itemsNumber',
  'itemsDescription',
  'itemsQuantity',
  'itemsPrice',
  'customerName',
  'customerAddressLineOne',
  'customerPostcode',
  'customerTaxNumberType',
  'customerTaxId',
];

function trimData(data) {
  data.forEach((row) => {
    Object.keys(row).forEach((key) => {
      if (typeof row[key] === 'string') {
        row[key] = row[key].trim();
      }
    });
  });
  return data;
}

function trimObjectKeysInArray(arr) {
  if (!Array.isArray(arr)) {
      return [];
  }

  return arr.map(obj => {
      if (typeof obj !== 'object' || obj === null) {
          return obj;
      }

      const trimmedObj = {};
      for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
              const trimmedKey = key.trim();
              trimmedObj[trimmedKey] = obj[key];
          }
      }
      return trimmedObj;
  });
}

function checkRequireColumns(excelColumns) {
  const matchColumnArray = []

  excelColumns.forEach(key => {
    if (newHeaders.some(_key => _key === key)) {
      matchColumnArray.push(key)
     }
  })

  return matchColumnArray.sort().join(',') === newHeaders.sort().join(',')
}

export default function Excel() {
  const history = useHistory();

  const { t } = useTranslation();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: '.csv, .xlsx, .xls',
    multiple: false,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  });

  const [test, setTest] = useState(false);

  const [acceptedFilesList, setAcceptedFilesList] = useState([]);
  const [rowDetails, setRowDetails] = useState([]);

  const [passItems, setPassItems] = React.useState([]);
  const [failItems, setFailItems] = React.useState([]);

  const [percentVat] = useState(7);
  const [zeroVat] = useState(0);
  const [nonVat] = useState(-1);

  const [percentVatText] = useState('7%');
  const [zeroVatText] = useState('0%');
  const [nonVatText] = useState('-');

  const [disabled, setDisabled] = React.useState(false);

  const [complete, setComplete] = React.useState(false);

  const [inComplete, setInComplete] = React.useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [dialogAlert, setDialogAlert] = useState({
    isClick: false,
    message: '',
  });

  const companyId = sessionStorage.getItem('companyId') || '';

  const setDefault = () => {
    setTest(true);

    setFailItems([]);
    setPassItems([]);
    setDisabled(false);
    setComplete(false);
    setInComplete(false);
    setAcceptedFilesList([]);
    setRowDetails([]);
  };

  useEffect(() => {
    let newList = [...acceptedFilesList, ...acceptedFiles];
    setAcceptedFilesList(newList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  useEffect(() => {
    if (rowDetails[0] !== undefined) {
      let pass = [];

      rowDetails.forEach((document, index) => {
        let newObj = document;
        newObj.key = index + 1;
        newObj.date = document.date;
        newObj.referNo = document.number;
        newObj.receiptType = document['documentType'];
        newObj.companyName = document['company']['name'];
        newObj.customerName = document['customer']['name'];
        newObj.taxId = document['customer']['taxId'];
        newObj.vat = document.vatTotal.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
        newObj.total = document.grandTotal.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
        newObj.amount = document.amountTotal.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
        newObj.status = '';

        pass.push(newObj);
      });

      openFile('pass', pass);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowDetails]);

  const openFile = (event, item) => {
    history.push({
      pathname: event === 'pass' ? '/etax/sources/excel/success' : '/etax/sources/excel/fail',
      state: {
        item: item,
      },
    });
  };

  const openFileFail = (item, mergeArray) => {
    history.push({
      pathname: '/etax/sources/excel/fail',
      state: {
        item: item,
        mergeArray: mergeArray,
      },
    });
  };

  useEffect(() => {
    acceptedFilesList.map(async (file) => {
      if (file.name.includes('.csv')) {
        const text = await file.text();
        const result = parse(text, { header: true });

        let _filterData = result.data.filter((obj) => !isEmpty(obj));

        let _dataWithoutEmptyKey = [];
        _filterData.forEach((obj) => {
          delete obj[''];
          _dataWithoutEmptyKey.push(obj);
        });

        const dataWithoutEmptyKey = trimObjectKeysInArray(_dataWithoutEmptyKey)
        
        if (!dataWithoutEmptyKey[0] || !checkRequireColumns(Object.keys(dataWithoutEmptyKey[0]))) {
          setDialogAlert((prev) => ({
            ...prev,
            isClick: true,
            message: t('excelDropzone.dropzoneAlertCSV'),
          }));

          return setAcceptedFilesList([]);
        }

        if (dataWithoutEmptyKey.some((row) => isNaN(row.order))) {
          let index = dataWithoutEmptyKey.findIndex((row) => isNaN(row.order));

          setDialogAlert((prev) => ({
            ...prev,
            isClick: true,
            message: `${t('excelDropzone.dropzoneAlertOrderInvalid1')}${index + 2}${t('excelDropzone.dropzoneAlertOrderInvalid2')}`,
          }));

          setAcceptedFilesList([]);
        } else {
          let mergeArray = merge(dataWithoutEmptyKey);

          if (mergeArray.length > 501) {
            setDialogAlert((prev) => ({
              ...prev,
              isClick: true,
              message: t('excelDropzone.dropzoneAlert500'),
            }));

            setAcceptedFilesList([]);
          } else {
            try {
              let failItems = [];
              mergeArray.forEach((row) => {
                if (validateField(row).length > 0)
                  failItems.push({
                    order: row.order,
                    number: row.number,
                    dateBE: row.dateBE,
                    error: validateField(row),
                  });
              });

              if (failItems.length > 0) {
                setAcceptedFilesList([]);
                // openFile('fail', failItems);
                openFileFail(failItems, mergeArray);
              } else {
                setIsLoading(true);

                instance
                  .acquireTokenSilent({ ...silentRequest, account: account })
                  .then(async (tokenResponse) => {
                    await axios
                      .get(API_URL + '/etax/companies', {
                        headers: {
                          Authorization: 'Bearer ' + tokenResponse.accessToken,
                          'cid': companyId,
                        },
                      })
                      .then(
                        async (response) => {
                          if (response.data[0]) {
                            if (companyId && response.data.find(_company => _company.id === companyId)) {
                              const _company = response.data.find(_company => _company.id === companyId)
                            
                              let array = await toObj(mergeArray, _company);
                              let newArray = [...rowDetails, ...array];
                              setRowDetails(newArray);
                            } else {
                              let array = await toObj(mergeArray, response.data[0]);
                              let newArray = [...rowDetails, ...array];
                              setRowDetails(newArray);
                            }
                            
                          } else {
                            history.push({
                              pathname: '/etax/companies/profiles',
                              state: {},
                            });
                          }
                        },
                        (error) => {
                          console.log(error);
                        }
                      );
                  })
                  .catch((error) => {
                    //Acquire token silent failure, and send an interactive request
                    console.log(error);
                    instance.acquireTokenRedirect(silentRequest);
                  });
              }
            } catch (err) {
              setDialogAlert((prev) => ({
                ...prev,
                isClick: true,
                message: t('excelDropzone.dropzoneAlertOther'),
              }));

              setAcceptedFilesList([]);
            }
          }
        }
      } else {
        readExcel(file);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFilesList]);

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const workbook = xlsx.read(bufferArray, { type: 'buffer' });

        const worksheetName = workbook.SheetNames[0];

        const worksheet = workbook.Sheets[worksheetName];

        const columnsArray = xlsx.utils.sheet_to_json(worksheet, {
          header: 1,
        })[0];

        if (!columnsArray || !checkRequireColumns(columnsArray)) {
          setDialogAlert((prev) => ({
            ...prev,
            isClick: true,
            message: t('excelDropzone.dropzoneAlertExcel'),
          }));

          return setAcceptedFilesList([]);
        }

        // const data = xlsx.utils.sheet_to_json(worksheet);
        const data = trimObjectKeysInArray(xlsx.utils.sheet_to_json(worksheet));

        if (data.length === 0) {
          setDialogAlert((prev) => ({
            ...prev,
            isClick: true,
            message: `${t('excelDropzone.dropzoneAlertNoData')}`,
          }));

          setAcceptedFilesList([]);
        } else if (data.some((row) => row.order === undefined || row.order === null || row.order === '' || !row.order)) {
          let index = data.findIndex((row) => row.order === undefined || row.order === null || row.order === '' || !row.order);

          setDialogAlert((prev) => ({
            ...prev,
            isClick: true,
            message: `${t('excelDropzone.dropzoneAlertNoOrder')}${index + 2}`,
          }));

          setAcceptedFilesList([]);
        } else if (data.some((row) => isNaN(row.order))) {
          let index = data.findIndex((row) => isNaN(row.order));

          setDialogAlert((prev) => ({
            ...prev,
            isClick: true,
            message: `${t('excelDropzone.dropzoneAlertOrderInvalid1')}${index + 2}${t('excelDropzone.dropzoneAlertOrderInvalid2')}`,
          }));

          setAcceptedFilesList([]);
        } else {
          let mergeArray = merge(trimData(data));

          if (mergeArray.length > 501) {
            setDialogAlert((prev) => ({
              ...prev,
              isClick: true,
              message: t('excelDropzone.dropzoneAlert500'),
            }));

            setAcceptedFilesList([]);
          } else {
            let failItems = [];
            mergeArray.forEach((row) => {
              if (validateField(row).length > 0)
                failItems.push({
                  order: row.order,
                  number: row.number,
                  dateBE: row.dateBE,
                  error: validateField(row),
                });
            });

            if (failItems.length > 0) {
              setAcceptedFilesList([]);
              openFileFail(failItems, mergeArray);
              // openFile('fail', failItems);
            } else {
              resolve(mergeArray);
            }
          }
        }
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then(async (mergeArray) => {
      setIsLoading(true);

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          await axios
            .get(API_URL + '/etax/companies', {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId,
              },
            })
            .then(
              async (response) => {
                if (response.data[0]) {
                  if (companyId && response.data.find(_company => _company.id === companyId)) {
                    const _company = response.data.find(_company => _company.id === companyId)
                    
                    let array = await toObj(mergeArray, _company);
                    let newArray = [...rowDetails, ...array];
                    setRowDetails(newArray);
                  } else {
                    let array = await toObj(mergeArray, response.data[0]);
                    let newArray = [...rowDetails, ...array];
                    setRowDetails(newArray);

                  }
                } else {
                  history.push({
                    pathname: '/etax/companies/profiles',
                    state: {},
                  });
                }
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    });
  };

  const merge = (data) => {
    let mergeArray = [];

    for (let i = 0; i < data.length; i++) {
      if (mergeArray[mergeArray.length - 1] !== undefined && mergeArray[mergeArray.length - 1].order === Number(data[i].order)) {
        let _percentVat = 7;
        if (data[i].itemsPercentVat === 0 || data[i].itemsPercentVat === '0') {
          _percentVat = 0;
        } else if (data[i].itemsPercentVat === -1 || data[i].itemsPercentVat === '-1') {
          _percentVat = -1;
        }

        mergeArray[mergeArray.length - 1].items.push({
          itemsNumber: mergeArray[mergeArray.length - 1].items.length + 1,
          itemsDescription: data[i].itemsDescription,
          itemsQuantity: Number(data[i].itemsQuantity) || 0,
          itemsPrice: Number(data[i].itemsPrice) || 0,
          itemsPercentVat: _percentVat,
          itemsUnitName: data[i].itemsUnitName || '',
          itemsDiscount: Number(data[i].itemsDiscount) || 0,
          itemsSKU: data[i].itemsSKU || '',
          ...(['BILLING-NOTE', 'ใบวางบิล', 'INVOICE', 'ใบแจ้งหนี้', 'QUOTATION', 'ใบเสนอราคา'].includes(mergeArray[mergeArray.length - 1].documentType)
          ? { itemsPercentWht: Number(data[i].itemsPercentWht) || 0 }
          : {}),
        });
      } else {
        let newDataObject = { ...data[i] };

        newDataObject.order = Number(data[i].order);
        if (data[i].includeVat === 'TRUE' || data[i].includeVat === 'true' || data[i].includeVat === true) {
          newDataObject.includeVat = true;
        } else if (data[i].includeVat === 'FALSE' || data[i].includeVat === 'false' || data[i].includeVat === false) {
          newDataObject.includeVat = false;
        }

        if (data[i].reIssue === 'TRUE' || data[i].reIssue === 'true' || data[i].reIssue === true) {
          newDataObject.reIssue = true;
        } else if (data[i].reIssue === 'FALSE' || data[i].reIssue === 'false' || data[i].reIssue === false) {
          newDataObject.reIssue = false;
        }

        if (
          data[i].documentType === 'DEBIT-NOTE' ||
          data[i].documentType === 'ใบเพิ่มหนี้' ||
          data[i].documentType === 'CREDIT-NOTE' ||
          data[i].documentType === 'ใบลดหนี้'
        ) {
          newDataObject.referAmountTotal = Number(data[i].referAmountTotal);
        }

        let _percentVat = 7;
        if (data[i].itemsPercentVat === 0 || data[i].itemsPercentVat === '0') {
          _percentVat = 0;
        } else if (data[i].itemsPercentVat === -1 || data[i].itemsPercentVat === '-1') {
          _percentVat = -1;
        }

        newDataObject.items = [
          {
            itemsNumber: 1,
            itemsDescription: data[i].itemsDescription,
            itemsQuantity: Number(data[i].itemsQuantity) || 0,
            itemsPrice: Number(data[i].itemsPrice) || 0,
            itemsPercentVat: _percentVat,
            itemsUnitName: data[i].itemsUnitName || '',
            itemsDiscount: Number(data[i].itemsDiscount) || 0,
            itemsSKU: data[i].itemsSKU || '',
            ...(['BILLING-NOTE', 'ใบวางบิล', 'INVOICE', 'ใบแจ้งหนี้', 'QUOTATION', 'ใบเสนอราคา'].includes(newDataObject.documentType)
            ? { itemsPercentWht: Number(data[i].itemsPercentWht) || 0 }
            : {}),
          },
        ];

        mergeArray.push(newDataObject);
      }
    }

    return mergeArray;
  };

  const toObj = async (data, companyData) => {
    let newArray = [];

    for (let i = 0; i < data.length; i++) {
      let document = data[i];
      let _row = document.order;
      let _documentType = document.documentType;

      let _language = 'th';
      if (document.language === 'en') {
        _language = 'en';
      }

      let _taxNumberType = document.customerTaxNumberType?.toUpperCase() || 'OTHR';
      let _taxId = String(document.customerTaxId).trim() || 'N/A';
      let _branchNumber = '';
      let _branchText = '';

      if (_taxNumberType === 'TXID') {
        _branchNumber = document.customerBranchNumber ? String(document.customerBranchNumber).trim().padStart(5, '0') : '00000';

        if (_branchNumber !== '00000') {
          if (_language === 'th') {
            _branchText = 'สาขา ' + _branchNumber;
          } else {
            _branchText = 'Branch ' + _branchNumber;
          }
        } else {
          if (_language === 'th') {
            _branchText = 'สำนักงานใหญ่';
          } else {
            _branchText = 'Head Office';
          }
        }
      } else if (_taxNumberType === 'OTHR') {
        _taxId = 'N/A';
      } else if (!['TXID', 'NIDN', 'CCPT', 'OTHR'].includes(_taxNumberType)) {
        _taxNumberType = 'OTHR';
        _taxId = 'N/A';
      }

      if (document.customerTaxId) {
        if (_taxId === '0000000000000' || Number(_taxId) === 0) {
          _taxNumberType = 'OTHR';
          _taxId = 'N/A';
          _branchNumber = '';
          _branchText = '';
        }
      }

      let _customer = {
        name: null,
        addressLineOne: null,
        addressLineTwo: null,
        countryCode: null,
        countryName: null,
        postcode: null,
        branchNumber: null,
        branchText: null,
        taxNumberType: null,
        taxId: null,
        phoneCountryCode: '+66',
        phone: null,
        email: null,
        language: _language,
        unstructure: true,
      };

      if (!(_documentType === 'RECEIPT-TAXINVOICE-ABB' || _documentType === 'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ')) {
        _customer = {
          name: document.customerName || '',
          addressLineOne: String(document.customerAddressLineOne).replaceAll("\r\n", " ") || '',
          addressLineTwo: document.customerAddressLineTwo ? String(document.customerAddressLineTwo).replaceAll("\r\n", " ") : "",
          countryCode: getCountryCodeWithoutLanguage(document.customerCountryName).key || 'TH',
          countryName: document.customerCountryName || 'ไทย',
          postcode: String(document.customerPostcode).trim() || '',
          branchNumber: _branchNumber,
          branchText: _branchText,
          taxNumberType: _taxNumberType,
          taxId: _taxId,
          phoneCountryCode: '+66',
          phone: document.customerPhone || '',
          email: document.customerEmail || '',
          language: _language,
          unstructure: true,
        };
      }

      let _date = '';
      let _dateCE = '';
      let _dateBE = '';

      if (document.dateBE && !isNaN(document.dateBE)) {
        if (String(document.dateBE).trim().length === 8) {
          _dateBE = getDateFromNewDateFormat(document.dateBE, 'dateBE');
          _dateCE = getDateFromNewDateFormat(document.dateBE, 'dateCE');
          _date = getDateFromNewDateFormat(document.dateBE, 'date');
        } else {
          const _dateObject = checkExcelDateFormat(document.dateBE);

          if (_dateObject.getFullYear() < 2100 && _dateObject.getFullYear() + 543 > 2563) {
            _dateBE =
              String(_dateObject.getDate()).padStart(2, '0') +
              '/' +
              String(_dateObject.getMonth() + 1).padStart(2, '0') +
              '/' +
              (_dateObject.getFullYear() + 543);
            _dateCE =
              String(_dateObject.getDate()).padStart(2, '0') +
              '/' +
              String(_dateObject.getMonth() + 1).padStart(2, '0') +
              '/' +
              _dateObject.getFullYear();
          } else {
            _dateBE =
              String(_dateObject.getDate()).padStart(2, '0') +
              '/' +
              String(_dateObject.getMonth() + 1).padStart(2, '0') +
              '/' +
              _dateObject.getFullYear();
            _dateCE =
              String(_dateObject.getDate()).padStart(2, '0') +
              '/' +
              String(_dateObject.getMonth() + 1).padStart(2, '0') +
              '/' +
              (_dateObject.getFullYear() - 543);
          }

          let value = _dateBE;

          let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
          let dateParts = date.format().split('+');

          _date = dateParts[0] + '.000Z';
        }
      } else if (document.dateBE && checkDateFormat(document.dateBE)) {
        let dateBEParts = document.dateBE.split('/');

        if (dateBEParts[0].length === 1) {
          dateBEParts[0] = '0' + dateBEParts[0];
        }

        if (dateBEParts[1].length === 1) {
          dateBEParts[1] = '0' + dateBEParts[1];
        }

        if (Number(dateBEParts[2]) < 2100 && Number(dateBEParts[2]) + 543 > 2563) {
          _dateBE = dateBEParts[0] + '/' + dateBEParts[1] + '/' + (Number(dateBEParts[2]) + 543);
          _dateCE = dateBEParts[0] + '/' + dateBEParts[1] + '/' + dateBEParts[2];
        } else {
          _dateBE = dateBEParts[0] + '/' + dateBEParts[1] + '/' + dateBEParts[2];
          _dateCE = dateBEParts[0] + '/' + dateBEParts[1] + '/' + (Number(dateBEParts[2]) - 543);
        }

        let value = _dateBE;

        let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
        let dateParts = date.format().split('+');

        _date = dateParts[0] + '.000Z';
      }

      let _items = [];

      let _includeVat = false;
      if (document.includeVat === 'TRUE' || document.includeVat === 'true' || document.includeVat === true) {
        _includeVat = document.includeVat;
      }

      let _nonVatPrice = 0;
      let _zeroVatPrice = 0;
      let _vatPrice = 0;

      let _nonVatPriceTotal = 0;
      let _zeroVatPriceTotal = 0;
      let _vatPriceTotal = 0;

      let _amountTotal = 0;
      let _vatTotal = 0;
      let _grandTotal = 0;
      let _discount = Number(document.discount) || 0;

      let _previousAmountTotal = Number(document.referAmountTotal);
      let _diffAmountTotal = 0;
      if (_documentType === 'DEBIT-NOTE' || _documentType === 'ใบเพิ่มหนี้') {
        if (document.calculationMethod === 2) {
          // actual price
          let newZeroVatPrice = 0;
          let newVatPrice = 0;

          let newVatTotal = 0;
          for (let j = 0; j < document.items.length; j++) {
            let item = document.items[j];
            if (item.itemsPrice >= 0) {
              let _unitCode = '';

              if (item.itemsUnitName) {
                if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                  _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                } else {
                  _unitCode = '_wrongUnit';
                }
              }

              let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

              // if (item.itemsPercentVat === nonVat) {
              //   _percentVatText = nonVatText;
              //   _nonVatPrice = _nonVatPrice + _itemTotal;
              //   _amountTotal = _amountTotal + _itemTotal;

              //   newNonVatPrice += _itemTotal;
              // } else if (item.itemsPercentVat === zeroVat) {
              //   _percentVatText = zeroVatText;
              //   _zeroVatPrice = _zeroVatPrice + _itemTotal;
              //   _amountTotal = _amountTotal + _itemTotal;

              //   newZeroVatPrice += _itemTotal;
              // } else {
              //   _vatPrice = _vatPrice + _itemTotal;
              //   _amountTotal = _amountTotal + _itemTotal;

              //   newVatPrice += _itemTotal;
              // }

              _vatPrice = _vatPrice + _itemTotal;
              _amountTotal = _amountTotal + _itemTotal;

              newVatPrice += _itemTotal;

              _items.push({
                number: _items.length + 1,
                description: item.itemsDescription,
                quantity: item.itemsQuantity,
                unitCode: _unitCode,
                unitName: item.itemsUnitName || '-',
                price: item.itemsPrice,
                discount: item.itemsDiscount || 0,
                percentVat: percentVat,
                percentVatText: percentVatText,
                total: _itemTotal,
                sku: item.itemsSKU ? item.itemsSKU : ''
              });
            } else {
              _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
            }
          }

          _amountTotal = Math.round((_amountTotal - _discount) * 100) / 100;
          if (_vatPrice !== 0) {
            _vatPrice = Math.round((_vatPrice - _discount - _previousAmountTotal) * 100) / 100;
          } else if (_zeroVatPrice !== 0) {
            _zeroVatPrice = Math.round((_zeroVatPrice - _discount) * 100) / 100;
          } else {
            _nonVatPrice = Math.round((_nonVatPrice - _discount) * 100) / 100;
          }

          if (_includeVat) {
            newVatTotal = (newVatPrice * percentVat) / (100 + percentVat) + (newZeroVatPrice * zeroVat) / (100 + zeroVat);
            newVatTotal = Math.round(newVatTotal * 100) / 100;

            let _sumAmountTotalIncludeVat = _amountTotal;
            _sumAmountTotalIncludeVat = Math.round(_sumAmountTotalIncludeVat * 100) / 100;

            _amountTotal = _amountTotal - newVatTotal;
            _amountTotal = Math.round(_amountTotal * 100) / 100;

            let _previousAmountTotalIncludeVat = (_previousAmountTotal * 7) / 100 + _previousAmountTotal;
            _previousAmountTotalIncludeVat = Math.round(_previousAmountTotalIncludeVat * 100) / 100;

            _grandTotal = _sumAmountTotalIncludeVat - _previousAmountTotalIncludeVat;
            _grandTotal = Math.round(_grandTotal * 100) / 100;

            _diffAmountTotal = _amountTotal - _previousAmountTotal;
            _diffAmountTotal = Math.round(_diffAmountTotal * 100) / 100;

            _vatTotal = _grandTotal - _diffAmountTotal;
            _vatTotal = Math.round(_vatTotal * 100) / 100;

            _nonVatPriceTotal = 0;
            _zeroVatPriceTotal = 0;
            _vatPriceTotal = _grandTotal;

            // _nonVatPriceTotal = _nonVatPrice;
            // _zeroVatPriceTotal = _zeroVatPrice - Math.round(((_zeroVatPrice * zeroVat) / (100 + zeroVat)) * 100) / 100;
            // _vatPriceTotal = _vatPrice - Math.round(((_vatPrice * percentVat) / (100 + percentVat)) * 100) / 100;

            // _grandTotal = Math.round((_amountTotal - _previousAmountTotal) * 100) / 100;
            // _vatTotal = Math.round(((_grandTotal * percentVat) / (100 + percentVat)) * 100) / 100;
            // _diffAmountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
          } else {
            _nonVatPriceTotal = _nonVatPrice;
            _zeroVatPriceTotal = _zeroVatPrice;
            _vatPriceTotal = _vatPrice;

            _vatTotal = (_vatPrice * percentVat) / 100;
            _vatTotal = Math.round(_vatTotal * 100) / 100;

            _diffAmountTotal = _amountTotal - _previousAmountTotal;
            _diffAmountTotal = Math.round(_diffAmountTotal * 100) / 100;

            _grandTotal = _diffAmountTotal + _vatTotal;
            _grandTotal = Math.round(_grandTotal * 100) / 100;

            // _diffAmountTotal = Math.round((_amountTotal - _previousAmountTotal) * 100) / 100;
            // _vatTotal = Math.round(((_diffAmountTotal * percentVat) / 100) * 100) / 100;
            // _grandTotal = Math.round((_diffAmountTotal + _vatTotal) * 100) / 100;
          }
        } else {
          if (_includeVat) {
            for (let j = 0; j < document.items.length; j++) {
              let item = document.items[j];
              if (item.itemsPrice >= 0) {
                let _percentVatText = percentVatText;
                let _unitCode = '';

                if (item.itemsUnitName) {
                  if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                    _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                  } else {
                    _unitCode = '_wrongUnit';
                  }
                }

                let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

                if (item.itemsPercentVat === nonVat) {
                  _percentVatText = nonVatText;
                  _nonVatPrice = _nonVatPrice + _itemTotal;
                  _grandTotal = _grandTotal + _itemTotal;
                } else if (item.itemsPercentVat === zeroVat) {
                  _percentVatText = zeroVatText;
                  _zeroVatPrice = _zeroVatPrice + _itemTotal;
                  _grandTotal = _grandTotal + _itemTotal;
                } else {
                  _vatPrice = _vatPrice + _itemTotal;
                  _grandTotal = _grandTotal + _itemTotal;
                }

                _items.push({
                  number: _items.length + 1,
                  description: item.itemsDescription,
                  quantity: item.itemsQuantity,
                  unitCode: _unitCode,
                  unitName: item.itemsUnitName || '-',
                  price: item.itemsPrice,
                  discount: item.itemsDiscount || 0,
                  percentVat: item.itemsPercentVat,
                  percentVatText: _percentVatText,
                  total: _itemTotal,
                  sku: item.itemsSKU ? item.itemsSKU : ''
                });
              } else {
                _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
              }
            }

            _grandTotal = Math.round((_grandTotal - _discount) * 100) / 100;
            if (_vatPrice !== 0) {
              _vatPrice = Math.round((_vatPrice - _discount) * 100) / 100;
            } else if (_zeroVatPrice !== 0) {
              _zeroVatPrice = Math.round((_zeroVatPrice - _discount) * 100) / 100;
            } else {
              _nonVatPrice = Math.round((_nonVatPrice - _discount) * 100) / 100;
            }

            _nonVatPriceTotal = _nonVatPrice;
            _zeroVatPriceTotal = _zeroVatPrice - Math.round(((_zeroVatPrice * zeroVat) / (100 + zeroVat)) * 100) / 100;
            _vatPriceTotal = _vatPrice - Math.round(((_vatPrice * percentVat) / (100 + percentVat)) * 100) / 100;

            const vatPriceTotal = _vatPrice - Math.round(((_vatPrice * percentVat) / (100 + percentVat)) * 100) / 100;

            _vatTotal = (vatPriceTotal * percentVat) / 100;
            _vatTotal = Math.round(_vatTotal * 100) / 100;

            const _sumGrandBeforeVat = _grandTotal - _vatTotal;

            _amountTotal = _sumGrandBeforeVat + Number(_previousAmountTotal);
            _amountTotal = Math.round(_amountTotal * 100) / 100;

            _diffAmountTotal = _amountTotal - Number(_previousAmountTotal);
            _diffAmountTotal = Math.round(_diffAmountTotal * 100) / 100;

            // _vatTotal = Math.round(((_grandTotal * percentVat) / (100 + percentVat)) * 100) / 100;
            // _diffAmountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
            // _amountTotal = Math.round((_diffAmountTotal + _previousAmountTotal) * 100) / 100;
          } else {
            for (let j = 0; j < document.items.length; j++) {
              let item = document.items[j];
              if (item.itemsPrice >= 0) {
                let _percentVatText = percentVatText;
                let _unitCode = '';

                if (item.itemsUnitName) {
                  if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                    _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                  } else {
                    _unitCode = '_wrongUnit';
                  }
                }

                let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

                if (item.itemsPercentVat === nonVat) {
                  _percentVatText = nonVatText;
                  _nonVatPrice = _nonVatPrice + _itemTotal;
                  _diffAmountTotal = _diffAmountTotal + _itemTotal;
                } else if (item.itemsPercentVat === zeroVat) {
                  _percentVatText = zeroVatText;
                  _zeroVatPrice = _zeroVatPrice + _itemTotal;
                  _diffAmountTotal = _diffAmountTotal + _itemTotal;
                } else {
                  _vatPrice = _vatPrice + _itemTotal;
                  _diffAmountTotal = _diffAmountTotal + _itemTotal;
                }

                _items.push({
                  number: _items.length + 1,
                  description: item.itemsDescription,
                  quantity: item.itemsQuantity,
                  unitCode: _unitCode,
                  unitName: item.itemsUnitName || '-',
                  price: item.itemsPrice,
                  discount: item.itemsDiscount || 0,
                  percentVat: item.itemsPercentVat,
                  percentVatText: _percentVatText,
                  total: _itemTotal,
                  sku: item.itemsSKU ? item.itemsSKU : ''
                });
              } else {
                _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
              }
            }

            _diffAmountTotal = Math.round((_diffAmountTotal - _discount) * 100) / 100;
            if (_vatPrice !== 0) {
              _vatPrice = Math.round((_vatPrice - _discount) * 100) / 100;
            } else if (_zeroVatPrice !== 0) {
              _zeroVatPrice = Math.round((_zeroVatPrice - _discount) * 100) / 100;
            } else {
              _nonVatPrice = Math.round((_nonVatPrice - _discount) * 100) / 100;
            }

            _nonVatPriceTotal = _nonVatPrice;
            _zeroVatPriceTotal = _zeroVatPrice;
            _vatPriceTotal = _vatPrice;

            _vatTotal = (_vatPrice * percentVat) / 100;
            _vatTotal = Math.round(_vatTotal * 100) / 100;

            _amountTotal = _diffAmountTotal + Number(_previousAmountTotal);
            _amountTotal = Math.round(_amountTotal * 100) / 100;

            _grandTotal = _diffAmountTotal + _vatTotal;
            _grandTotal = Math.round(_grandTotal * 100) / 100;

            // _amountTotal = Math.round((_diffAmountTotal + _previousAmountTotal) * 100) / 100;
            // _vatTotal = Math.round(((_diffAmountTotal * percentVat) / 100) * 100) / 100;
            // _grandTotal = Math.round((_diffAmountTotal + _vatTotal) * 100) / 100;
          }
        }
      } else if (_documentType === 'CREDIT-NOTE' || _documentType === 'ใบลดหนี้') {
        if (document.calculationMethod === 2) {
          // actual price
          let newZeroVatPrice = 0;
          let newVatPrice = 0;

          let newVatTotal = 0;

          for (let j = 0; j < document.items.length; j++) {
            let item = document.items[j];
            if (item.itemsPrice >= 0) {
              let _unitCode = '';

              if (item.itemsUnitName) {
                if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                  _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                } else {
                  _unitCode = '_wrongUnit';
                }
              }

              let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

              // if (item.itemsPercentVat === nonVat) {
              //   _percentVatText = nonVatText;
              //   _nonVatPrice = _nonVatPrice + _itemTotal;
              //   _amountTotal = _amountTotal + _itemTotal;

              //   newNonVatPrice += _itemTotal
              // } else if (item.itemsPercentVat === zeroVat) {
              //   _percentVatText = zeroVatText;
              //   _zeroVatPrice = _zeroVatPrice + _itemTotal;
              //   _amountTotal = _amountTotal + _itemTotal;

              //   newZeroVatPrice += _itemTotal
              // } else {
              //   _vatPrice = _vatPrice + _itemTotal;
              //   _amountTotal = _amountTotal + _itemTotal;

              //   newVatPrice += _itemTotal
              // }
              _vatPrice = _vatPrice + _itemTotal;
              _amountTotal = _amountTotal + _itemTotal;

              newVatPrice += _itemTotal;

              _items.push({
                number: _items.length + 1,
                description: item.itemsDescription,
                quantity: item.itemsQuantity,
                unitCode: _unitCode,
                unitName: item.itemsUnitName || '-',
                price: item.itemsPrice,
                discount: item.itemsDiscount || 0,
                percentVat: percentVat,
                percentVatText: percentVatText,
                total: _itemTotal,
                sku: item.itemsSKU ? item.itemsSKU : ''
              });
            } else {
              _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
            }
          }

          _amountTotal = Math.round((_amountTotal - _discount) * 100) / 100;
          if (_vatPrice !== 0) {
            _vatPrice = Math.round((_previousAmountTotal - _vatPrice - _discount) * 100) / 100;
          } else if (_zeroVatPrice !== 0) {
            _zeroVatPrice = Math.round((_zeroVatPrice - _discount) * 100) / 100;
          } else {
            _nonVatPrice = Math.round((_nonVatPrice - _discount) * 100) / 100;
          }

          if (_includeVat) {
            newVatTotal = (newVatPrice * percentVat) / (100 + percentVat) + (newZeroVatPrice * zeroVat) / (100 + zeroVat);
            newVatTotal = Math.round(newVatTotal * 100) / 100;

            let _sumAmountTotalIncludeVat = _amountTotal;
            _sumAmountTotalIncludeVat = Math.round(_sumAmountTotalIncludeVat * 100) / 100;

            _amountTotal = _amountTotal - newVatTotal;
            _amountTotal = Math.round(_amountTotal * 100) / 100;

            let _previousAmountTotalIncludeVat = (_previousAmountTotal * 7) / 100 + _previousAmountTotal;
            _previousAmountTotalIncludeVat = Math.round(_previousAmountTotalIncludeVat * 100) / 100;

            _grandTotal = _previousAmountTotalIncludeVat - _sumAmountTotalIncludeVat;
            _grandTotal = Math.round(_grandTotal * 100) / 100;

            _diffAmountTotal = _previousAmountTotal - _amountTotal;
            _diffAmountTotal = Math.round(_diffAmountTotal * 100) / 100;

            _vatTotal = _grandTotal - _diffAmountTotal;
            _vatTotal = Math.round(_vatTotal * 100) / 100;

            _nonVatPriceTotal = 0;
            _zeroVatPriceTotal = 0;
            _vatPriceTotal = _grandTotal;

            // _grandTotal = Math.round((_previousAmountTotal - _amountTotal) * 100) / 100;
            // _vatTotal = Math.round(((_grandTotal * percentVat) / (100 + percentVat)) * 100) / 100;
            // _diffAmountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
          } else {
            _nonVatPriceTotal = _nonVatPrice;
            _zeroVatPriceTotal = _zeroVatPrice;
            _vatPriceTotal = _vatPrice;

            _vatTotal = (_vatPrice * percentVat) / 100;
            _vatTotal = Math.round(_vatTotal * 100) / 100;

            _diffAmountTotal = _previousAmountTotal - _amountTotal;
            _diffAmountTotal = Math.round(_diffAmountTotal * 100) / 100;

            _grandTotal = _diffAmountTotal + _vatTotal;
            _grandTotal = Math.round(_grandTotal * 100) / 100;

            // _diffAmountTotal = Math.round((_previousAmountTotal - _amountTotal) * 100) / 100;
            // _vatTotal = Math.round(((_diffAmountTotal * percentVat) / 100) * 100) / 100;
            // _grandTotal = Math.round((_diffAmountTotal + _vatTotal) * 100) / 100;
          }
        } else {
          if (_includeVat) {
            for (let j = 0; j < document.items.length; j++) {
              let item = document.items[j];
              if (item.itemsPrice >= 0) {
                let _percentVatText = percentVatText;
                let _unitCode = '';

                if (item.itemsUnitName) {
                  if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                    _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                  } else {
                    _unitCode = '_wrongUnit';
                  }
                }

                let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

                if (item.itemsPercentVat === nonVat) {
                  _percentVatText = nonVatText;
                  _nonVatPrice = _nonVatPrice + _itemTotal;
                  _grandTotal = _grandTotal + _itemTotal;
                } else if (item.itemsPercentVat === zeroVat) {
                  _percentVatText = zeroVatText;
                  _zeroVatPrice = _zeroVatPrice + _itemTotal;
                  _grandTotal = _grandTotal + _itemTotal;
                } else {
                  _vatPrice = _vatPrice + _itemTotal;
                  _grandTotal = _grandTotal + _itemTotal;
                }

                _items.push({
                  number: _items.length + 1,
                  description: item.itemsDescription,
                  quantity: item.itemsQuantity,
                  unitCode: _unitCode,
                  unitName: item.itemsUnitName || '-',
                  price: item.itemsPrice,
                  discount: item.itemsDiscount || 0,
                  percentVat: item.itemsPercentVat,
                  percentVatText: _percentVatText,
                  total: _itemTotal,
                  sku: item.itemsSKU ? item.itemsSKU : ''
                });
              } else {
                _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
              }
            }

            _grandTotal = Math.round((_grandTotal - _discount) * 100) / 100;
            if (_vatPrice !== 0) {
              _vatPrice = Math.round((_vatPrice - _discount) * 100) / 100;
            } else if (_zeroVatPrice !== 0) {
              _zeroVatPrice = Math.round((_zeroVatPrice - _discount) * 100) / 100;
            } else {
              _nonVatPrice = Math.round((_nonVatPrice - _discount) * 100) / 100;
            }

            _nonVatPriceTotal = _nonVatPrice;
            _zeroVatPriceTotal = _zeroVatPrice - Math.round(((_zeroVatPrice * zeroVat) / (100 + zeroVat)) * 100) / 100;
            _vatPriceTotal = _vatPrice - Math.round(((_vatPrice * percentVat) / (100 + percentVat)) * 100) / 100;

            const vatPriceTotal = _vatPrice - Math.round(((_vatPrice * percentVat) / (100 + percentVat)) * 100) / 100;

            _vatTotal = (vatPriceTotal * percentVat) / 100;
            _vatTotal = Math.round(_vatTotal * 100) / 100;

            const _sumGrandBeforeVat = _grandTotal - _vatTotal;

            _amountTotal = _previousAmountTotal - _sumGrandBeforeVat;
            _amountTotal = Math.round(_amountTotal * 100) / 100;

            _diffAmountTotal = _previousAmountTotal - _amountTotal;
            _diffAmountTotal = Math.round(_diffAmountTotal * 100) / 100;

            // _vatTotal = Math.round(((_grandTotal * percentVat) / (100 + percentVat)) * 100) / 100;
            // _diffAmountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
            // _amountTotal = Math.round((_previousAmountTotal - _diffAmountTotal) * 100) / 100;
          } else {
            for (let j = 0; j < document.items.length; j++) {
              let item = document.items[j];
              if (item.itemsPrice >= 0) {
                let _percentVatText = percentVatText;
                let _unitCode = '';

                if (item.itemsUnitName) {
                  if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                    _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                  } else {
                    _unitCode = '_wrongUnit';
                  }
                }

                let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

                if (item.itemsPercentVat === nonVat) {
                  _percentVatText = nonVatText;
                  _nonVatPrice = _nonVatPrice + _itemTotal;
                  _diffAmountTotal = _diffAmountTotal + _itemTotal;
                } else if (item.itemsPercentVat === zeroVat) {
                  _percentVatText = zeroVatText;
                  _zeroVatPrice = _zeroVatPrice + _itemTotal;
                  _diffAmountTotal = _diffAmountTotal + _itemTotal;
                } else {
                  _vatPrice = _vatPrice + _itemTotal;
                  _diffAmountTotal = _diffAmountTotal + _itemTotal;
                }

                _items.push({
                  number: _items.length + 1,
                  description: item.itemsDescription,
                  quantity: item.itemsQuantity,
                  unitCode: _unitCode,
                  unitName: item.itemsUnitName || '-',
                  price: item.itemsPrice,
                  discount: item.itemsDiscount || 0,
                  percentVat: item.itemsPercentVat,
                  percentVatText: _percentVatText,
                  total: _itemTotal,
                  sku: item.itemsSKU ? item.itemsSKU : ''
                });
              } else {
                _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
              }
            }

            _diffAmountTotal = Math.round((_diffAmountTotal - _discount) * 100) / 100;
            if (_vatPrice !== 0) {
              _vatPrice = Math.round((_vatPrice - _discount) * 100) / 100;
            } else if (_zeroVatPrice !== 0) {
              _zeroVatPrice = Math.round((_zeroVatPrice - _discount) * 100) / 100;
            } else {
              _nonVatPrice = Math.round((_nonVatPrice - _discount) * 100) / 100;
            }

            _nonVatPriceTotal = _nonVatPrice;
            _zeroVatPriceTotal = _zeroVatPrice;
            _vatPriceTotal = _vatPrice;

            _vatTotal = (_vatPrice * percentVat) / 100;
            _vatTotal = Math.round(_vatTotal * 100) / 100;

            _amountTotal = _previousAmountTotal - _diffAmountTotal;
            _amountTotal = Math.round(_amountTotal * 100) / 100;

            _grandTotal = _diffAmountTotal + _vatTotal;
            _grandTotal = Math.round(_grandTotal * 100) / 100;

            // _amountTotal = Math.round((_previousAmountTotal - _diffAmountTotal) * 100) / 100;
            // _vatTotal = Math.round(((_diffAmountTotal * percentVat) / 100) * 100) / 100;
            // _grandTotal = Math.round((_diffAmountTotal + _vatTotal) * 100) / 100;
          }
        }
      } else if (
        _documentType === 'QUOTATION' ||
        _documentType === 'ใบเสนอราคา' ||
        _documentType === 'INVOICE' ||
        _documentType === 'ใบแจ้งหนี้' ||
        _documentType === 'BILLING-NOTE' ||
        _documentType === 'ใบวางบิล'
      ) {
        if (companyData.registerVat) {
          if (_includeVat) {
            for (let j = 0; j < document.items.length; j++) {
              let item = document.items[j];
              if (item.itemsPrice >= 0) {
                let _percentVatText = percentVatText;
                let _unitCode = '';

                if (item.itemsUnitName) {
                  if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                    _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                  } else {
                    _unitCode = '_wrongUnit';
                  }
                }

                let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

                if (item.itemsPercentVat === nonVat) {
                  _percentVatText = nonVatText;
                  _nonVatPrice += _itemTotal;
                  _grandTotal += _itemTotal;
                } else if (item.itemsPercentVat === zeroVat) {
                  _percentVatText = zeroVatText;
                  _zeroVatPrice += _itemTotal;
                  _grandTotal += _itemTotal;
                } else {
                  _vatPrice += _itemTotal;
                  _grandTotal += _itemTotal;
                }

                let _itemPercentVat = item.itemsPercentVat
                if (_itemPercentVat < 0) {
                  _itemPercentVat = 0;
                }

                let _itemVat = 0;
                let _itemTotalAfterVat = 0;
                let _itemTotalBeforeVat = 0;
                let _whtTotal = 0;

                _itemVat = Math.round(((_itemTotal * _itemPercentVat) / (100 + _itemPercentVat)) * 100) / 100;
                _itemTotalAfterVat = Math.round(_itemTotal * 100) / 100;
                _itemTotalBeforeVat = _itemTotalAfterVat - _itemVat;

                _whtTotal = _itemTotalBeforeVat * (Math.round((item.itemsPercentWht / 100) * 100) / 100);

                _items.push({
                  number: _items.length + 1,
                  description: item.itemsDescription,
                  quantity: item.itemsQuantity,
                  unitCode: _unitCode,
                  unitName: item.itemsUnitName || '-',
                  price: item.itemsPrice,
                  discount: item.itemsDiscount || 0,
                  percentVat: item.itemsPercentVat,
                  percentVatText: _percentVatText,
                  total: _itemTotal,
                  sku: item.itemsSKU ? item.itemsSKU : '',
                  percentWht: item.itemsPercentWht,
                  whtTotal: mathRound(_whtTotal),
                });
              } else {
                _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
              }
            }

            _grandTotal = Math.round((_grandTotal - _discount) * 100) / 100;
            if (_vatPrice !== 0) {
              _vatPrice = Math.round((_vatPrice - _discount) * 100) / 100;
            } else if (_zeroVatPrice !== 0) {
              _zeroVatPrice = Math.round((_zeroVatPrice - _discount) * 100) / 100;
            } else {
              _nonVatPrice = Math.round((_nonVatPrice - _discount) * 100) / 100;
            }

            _vatTotal =
              Math.round(((_vatPrice * percentVat) / (100 + percentVat)) * 100) / 100 +
              Math.round(((_zeroVatPrice * zeroVat) / (100 + zeroVat)) * 100) / 100;
            _amountTotal = _grandTotal - _vatTotal;

            _nonVatPriceTotal = _nonVatPrice;
            _zeroVatPriceTotal = _zeroVatPrice - Math.round(((_zeroVatPrice * zeroVat) / (100 + zeroVat)) * 100) / 100;
            _vatPriceTotal = _vatPrice - Math.round(((_vatPrice * percentVat) / (100 + percentVat)) * 100) / 100;
          } else {
            for (let j = 0; j < document.items.length; j++) {
              let item = document.items[j];
              if (item.itemsPrice >= 0) {
                let _percentVatText = percentVatText;
                let _unitCode = '';

                if (item.itemsUnitName) {
                  if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                    _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                  } else {
                    _unitCode = '_wrongUnit';
                  }
                }

                let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

                if (item.itemsPercentVat === nonVat) {
                  _percentVatText = nonVatText;
                  _nonVatPrice += _itemTotal;
                  _amountTotal += _itemTotal;
                } else if (item.itemsPercentVat === zeroVat) {
                  _percentVatText = zeroVatText;
                  _zeroVatPrice += _itemTotal;
                  _amountTotal += _itemTotal;
                } else {
                  _vatPrice += _itemTotal;
                  _amountTotal += _itemTotal;
                }

                let _itemPercentVat = item.itemsPercentVat
                if (_itemPercentVat < 0) {
                  _itemPercentVat = 0;
                }

                let _itemTotalBeforeVat = 0;
                let _whtTotal = 0;

                _itemTotalBeforeVat = Math.round(_itemTotal * 100) / 100;

                _whtTotal = _itemTotalBeforeVat * (Math.round((item.itemsPercentWht / 100) * 100) / 100);

                _items.push({
                  number: _items.length + 1,
                  description: item.itemsDescription,
                  quantity: item.itemsQuantity,
                  unitCode: _unitCode,
                  unitName: item.itemsUnitName || '-',
                  price: item.itemsPrice,
                  discount: item.itemsDiscount || 0,
                  percentVat: item.itemsPercentVat,
                  percentVatText: _percentVatText,
                  total: _itemTotal,
                  sku: item.itemsSKU ? item.itemsSKU : '',
                  percentWht: item.itemsPercentWht,
                  whtTotal: mathRound(_whtTotal),
                });
              } else {
                _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
              }
            }

            _amountTotal = Math.round((_amountTotal - _discount) * 100) / 100;
            if (_vatPrice !== 0) {
              _vatPrice = Math.round((_vatPrice - _discount) * 100) / 100;
            } else if (_zeroVatPrice !== 0) {
              _zeroVatPrice = Math.round((_zeroVatPrice - _discount) * 100) / 100;
            } else {
              _nonVatPrice = Math.round((_nonVatPrice - _discount) * 100) / 100;
            }

            _vatTotal = Math.round(((_vatPrice * percentVat) / 100) * 100) / 100 + Math.round(((_zeroVatPrice * zeroVat) / 100) * 100) / 100;
            _grandTotal = _amountTotal + _vatTotal;

            _nonVatPriceTotal = _nonVatPrice;
            _zeroVatPriceTotal = _zeroVatPrice;
            _vatPriceTotal = _vatPrice;
          }
        } else {
          for (let j = 0; j < document.items.length; j++) {
            let item = document.items[j];
            if (item.itemsPrice >= 0) {
              let _unitCode = '';

              if (item.itemsUnitName) {
                if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                  _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                } else {
                  _unitCode = '_wrongUnit';
                }
              }

              let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

              _grandTotal += _itemTotal;

              let _itemPercentVat = item.itemsPercentVat
              if (_itemPercentVat < 0) {
                _itemPercentVat = 0;
              }

              let _itemVat = 0;
              let _itemTotalAfterVat = 0;
              let _itemTotalBeforeVat = 0;
              let _whtTotal = 0;

              if (_includeVat) {
                _itemVat = Math.round(((_itemTotal * _itemPercentVat) / (100 + _itemPercentVat)) * 100) / 100;
                _itemTotalAfterVat = Math.round(_itemTotal * 100) / 100;
                _itemTotalBeforeVat = _itemTotalAfterVat - _itemVat;
              } else {
                _itemTotalBeforeVat = Math.round(_itemTotal * 100) / 100;
                _itemVat = Math.round(_itemTotalBeforeVat * _itemPercentVat * 100) / 100;
                _itemTotalAfterVat = _itemTotalBeforeVat + _itemVat;
              }

              _whtTotal = _itemTotalBeforeVat * (Math.round((item.itemsPercentWht / 100) * 100) / 100);

              _items.push({
                number: _items.length + 1,
                description: item.itemsDescription,
                quantity: item.itemsQuantity,
                unitCode: _unitCode,
                unitName: item.itemsUnitName || '-',
                price: item.itemsPrice,
                discount: item.itemsDiscount || 0,
                total: _itemTotal,
                sku: item.itemsSKU ? item.itemsSKU : '',
                percentWht: item.itemsPercentWht,
                whtTotal: mathRound(_whtTotal),
              });
            } else {
              _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
            }
          }

          _grandTotal = Math.round((_grandTotal - _discount) * 100) / 100;
          _amountTotal = _grandTotal;
        }
      } else if (
        _documentType === 'RECEIPT' ||
        _documentType === 'ใบเสร็จรับเงิน' ||
        _documentType === 'DELIVERYORDER' ||
        _documentType === 'ใบส่งของ'
      ) {
        if (companyData.registerVat) {
          if (_includeVat) {
            for (let j = 0; j < document.items.length; j++) {
              let item = document.items[j];
              if (item.itemsPrice >= 0) {
                let _percentVatText = percentVatText;
                let _unitCode = '';

                if (item.itemsUnitName) {
                  if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                    _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                  } else {
                    _unitCode = '_wrongUnit';
                  }
                }

                let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

                if (item.itemsPercentVat === nonVat) {
                  _percentVatText = nonVatText;
                  _nonVatPrice += _itemTotal;
                  _grandTotal += _itemTotal;
                } else if (item.itemsPercentVat === zeroVat) {
                  _percentVatText = zeroVatText;
                  _zeroVatPrice += _itemTotal;
                  _grandTotal += _itemTotal;
                } else {
                  _vatPrice += _itemTotal;
                  _grandTotal += _itemTotal;
                }

                _items.push({
                  number: _items.length + 1,
                  description: item.itemsDescription,
                  quantity: item.itemsQuantity,
                  unitCode: _unitCode,
                  unitName: item.itemsUnitName || '-',
                  price: item.itemsPrice,
                  discount: item.itemsDiscount || 0,
                  percentVat: item.itemsPercentVat,
                  percentVatText: _percentVatText,
                  total: _itemTotal,
                  sku: item.itemsSKU ? item.itemsSKU : ''
                });
              } else {
                _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
              }
            }

            _grandTotal = Math.round((_grandTotal - _discount) * 100) / 100;
            if (_vatPrice !== 0) {
              _vatPrice = Math.round((_vatPrice - _discount) * 100) / 100;
            } else if (_zeroVatPrice !== 0) {
              _zeroVatPrice = Math.round((_zeroVatPrice - _discount) * 100) / 100;
            } else {
              _nonVatPrice = Math.round((_nonVatPrice - _discount) * 100) / 100;
            }

            _vatTotal =
              Math.round(((_vatPrice * percentVat) / (100 + percentVat)) * 100) / 100 +
              Math.round(((_zeroVatPrice * zeroVat) / (100 + zeroVat)) * 100) / 100;
            _amountTotal = _grandTotal - _vatTotal;

            _nonVatPriceTotal = _nonVatPrice;
            _zeroVatPriceTotal = _zeroVatPrice - Math.round(((_zeroVatPrice * zeroVat) / (100 + zeroVat)) * 100) / 100;
            _vatPriceTotal = _vatPrice - Math.round(((_vatPrice * percentVat) / (100 + percentVat)) * 100) / 100;
          } else {
            for (let j = 0; j < document.items.length; j++) {
              let item = document.items[j];
              if (item.itemsPrice >= 0) {
                let _percentVatText = percentVatText;
                let _unitCode = '';

                if (item.itemsUnitName) {
                  if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                    _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                  } else {
                    _unitCode = '_wrongUnit';
                  }
                }

                let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

                if (item.itemsPercentVat === nonVat) {
                  _percentVatText = nonVatText;
                  _nonVatPrice += _itemTotal;
                  _amountTotal += _itemTotal;
                } else if (item.itemsPercentVat === zeroVat) {
                  _percentVatText = zeroVatText;
                  _zeroVatPrice += _itemTotal;
                  _amountTotal += _itemTotal;
                } else {
                  _vatPrice += _itemTotal;
                  _amountTotal += _itemTotal;
                }

                _items.push({
                  number: _items.length + 1,
                  description: item.itemsDescription,
                  quantity: item.itemsQuantity,
                  unitCode: _unitCode,
                  unitName: item.itemsUnitName || '-',
                  price: item.itemsPrice,
                  discount: item.itemsDiscount || 0,
                  percentVat: item.itemsPercentVat,
                  percentVatText: _percentVatText,
                  total: _itemTotal,
                  sku: item.itemsSKU ? item.itemsSKU : ''
                });
              } else {
                _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
              }
            }

            _amountTotal = Math.round((_amountTotal - _discount) * 100) / 100;
            if (_vatPrice !== 0) {
              _vatPrice = Math.round((_vatPrice - _discount) * 100) / 100;
            } else if (_zeroVatPrice !== 0) {
              _zeroVatPrice = Math.round((_zeroVatPrice - _discount) * 100) / 100;
            } else {
              _nonVatPrice = Math.round((_nonVatPrice - _discount) * 100) / 100;
            }

            _vatTotal = Math.round(((_vatPrice * percentVat) / 100) * 100) / 100 + Math.round(((_zeroVatPrice * zeroVat) / 100) * 100) / 100;
            _grandTotal = _amountTotal + _vatTotal;

            _nonVatPriceTotal = _nonVatPrice;
            _zeroVatPriceTotal = _zeroVatPrice;
            _vatPriceTotal = _vatPrice;
          }
        } else {
          for (let j = 0; j < document.items.length; j++) {
            let item = document.items[j];
            if (item.itemsPrice >= 0) {
              let _unitCode = '';

              if (item.itemsUnitName) {
                if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                  _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                } else {
                  _unitCode = '_wrongUnit';
                }
              }

              let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

              _grandTotal += _itemTotal;

              _items.push({
                number: _items.length + 1,
                description: item.itemsDescription,
                quantity: item.itemsQuantity,
                unitCode: _unitCode,
                unitName: item.itemsUnitName || '-',
                price: item.itemsPrice,
                discount: item.itemsDiscount || 0,
                total: _itemTotal,
                sku: item.itemsSKU ? item.itemsSKU : ''
              });
            } else {
              _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
            }
          }

          _grandTotal = Math.round((_grandTotal - _discount) * 100) / 100;
          _amountTotal = _grandTotal;
        }
      } else {
        if (_includeVat) {
          for (let j = 0; j < document.items.length; j++) {
            let item = document.items[j];
            if (item.itemsPrice >= 0) {
              let _percentVatText = percentVatText;
              let _unitCode = '';

              if (item.itemsUnitName) {
                if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                  _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                } else {
                  _unitCode = '_wrongUnit';
                }
              }

              let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

              if (item.itemsPercentVat === nonVat) {
                _percentVatText = nonVatText;
                _nonVatPrice += _itemTotal;
                _grandTotal += _itemTotal;
              } else if (item.itemsPercentVat === zeroVat) {
                _percentVatText = zeroVatText;
                _zeroVatPrice += _itemTotal;
                _grandTotal += _itemTotal;
              } else {
                _vatPrice += _itemTotal;
                _grandTotal += _itemTotal;
              }

              _items.push({
                number: _items.length + 1,
                description: item.itemsDescription,
                quantity: item.itemsQuantity,
                unitCode: _unitCode,
                unitName: item.itemsUnitName || '-',
                price: item.itemsPrice,
                discount: item.itemsDiscount || 0,
                percentVat: item.itemsPercentVat,
                percentVatText: _percentVatText,
                total: _itemTotal,
                sku: item.itemsSKU ? item.itemsSKU : ''
              });
            } else {
              _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
            }
          }

          _grandTotal = Math.round((_grandTotal - _discount) * 100) / 100;
          if (_vatPrice !== 0) {
            _vatPrice = Math.round((_vatPrice - _discount) * 100) / 100;
          } else if (_zeroVatPrice !== 0) {
            _zeroVatPrice = Math.round((_zeroVatPrice - _discount) * 100) / 100;
          } else {
            _nonVatPrice = Math.round((_nonVatPrice - _discount) * 100) / 100;
          }

          _vatTotal =
            Math.round(((_vatPrice * percentVat) / (100 + percentVat)) * 100) / 100 +
            Math.round(((_zeroVatPrice * zeroVat) / (100 + zeroVat)) * 100) / 100;
          _amountTotal = _grandTotal - _vatTotal;

          _nonVatPriceTotal = _nonVatPrice;
          _zeroVatPriceTotal = _zeroVatPrice - Math.round(((_zeroVatPrice * zeroVat) / (100 + zeroVat)) * 100) / 100;
          _vatPriceTotal = _vatPrice - Math.round(((_vatPrice * percentVat) / (100 + percentVat)) * 100) / 100;
        } else {
          for (let j = 0; j < document.items.length; j++) {
            let item = document.items[j];
            if (item.itemsPrice >= 0) {
              let _percentVatText = percentVatText;
              let _unitCode = '';

              if (item.itemsUnitName) {
                if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                  _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                } else {
                  _unitCode = '_wrongUnit';
                }
              }

              let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

              if (item.itemsPercentVat === nonVat) {
                _percentVatText = nonVatText;
                _nonVatPrice += _itemTotal;
                _amountTotal += _itemTotal;
              } else if (item.itemsPercentVat === zeroVat) {
                _percentVatText = zeroVatText;
                _zeroVatPrice += _itemTotal;
                _amountTotal += _itemTotal;
              } else {
                _vatPrice += _itemTotal;
                _amountTotal += _itemTotal;
              }

              _items.push({
                number: _items.length + 1,
                description: item.itemsDescription,
                quantity: item.itemsQuantity,
                unitCode: _unitCode,
                unitName: item.itemsUnitName || '-',
                price: item.itemsPrice,
                discount: item.itemsDiscount || 0,
                percentVat: item.itemsPercentVat,
                percentVatText: _percentVatText,
                total: _itemTotal,
                sku: item.itemsSKU ? item.itemsSKU : ''
              });
            } else {
              _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
            }
          }

          _amountTotal = Math.round((_amountTotal - _discount) * 100) / 100;
          if (_vatPrice !== 0) {
            _vatPrice = Math.round((_vatPrice - _discount) * 100) / 100;
          } else if (_zeroVatPrice !== 0) {
            _zeroVatPrice = Math.round((_zeroVatPrice - _discount) * 100) / 100;
          } else {
            _nonVatPrice = Math.round((_nonVatPrice - _discount) * 100) / 100;
          }

          _vatTotal = Math.round(((_vatPrice * percentVat) / 100) * 100) / 100 + Math.round(((_zeroVatPrice * zeroVat) / 100) * 100) / 100;
          _grandTotal = _amountTotal + _vatTotal;

          _nonVatPriceTotal = _nonVatPrice;
          _zeroVatPriceTotal = _zeroVatPrice;
          _vatPriceTotal = _vatPrice;
        }
      }

      let _company = { ...companyData };
      if (
        _documentType === 'QUOTATION' ||
        _documentType === 'ใบเสนอราคา' ||
        _documentType === 'INVOICE' ||
        _documentType === 'ใบแจ้งหนี้' ||
        _documentType === 'BILLING-NOTE' ||
        _documentType === 'ใบวางบิล' ||
        _documentType === 'DELIVERYORDER' ||
        _documentType === 'ใบส่งของ'
      ) {
        if (document.promptpay === 'TRUE' || document.promptpay === 'true' || document.promptpay === true) {
          console.log('_company', _company);
          _company.payment.promptpay = document.promptpay;
          let qr = await handleQr(_company.taxId, mathRound(_grandTotal));
          _company.payment.promptpayQr = qr;
        }
      }

      const _extraAttributes = {}

      Object.keys(document).forEach(key => {
        if (key.includes("attribute")) {
          _extraAttributes[key] = document[key]
        }
      })

      let obj = {
        row: _row,
        documentType: _documentType || '',
        company: _company,
        customer: _customer,
        date: _date,
        dateBE: _dateBE || '',
        dateCE: _dateCE,
        number: document.number || '',
        items: _items,
        includeVat: _includeVat,
        percentVat: percentVat,
        nonVatPriceTotal: _nonVatPriceTotal > 0 ? mathRound(_nonVatPriceTotal) : 0,
        zeroVatPriceTotal: _zeroVatPriceTotal > 0 ? mathRound(_zeroVatPriceTotal) : 0,
        vatPriceTotal: _vatPriceTotal > 0 ? mathRound(_vatPriceTotal) : 0,
        discount: mathRound(_discount) || 0,
        amountTotal: _amountTotal > 0 ? mathRound(_amountTotal) : 0,
        vatTotal: _vatTotal > 0 ? mathRound(_vatTotal) : 0,
        grandTotal: _grandTotal > 0 ? mathRound(_grandTotal) : 0,
        note: document.note || '',
        createdBy: document.createdBy || '',
        receivedBy: document.receivedBy || '',
        reference: document.reference || '',
        reIssue: document.reIssue || false,
        ...(['BILLING-NOTE', 'ใบวางบิล', 'INVOICE', 'ใบแจ้งหนี้', 'QUOTATION', 'ใบเสนอราคา'].includes(_documentType)
          ? { 
            whtTotal: mathRound(_items.reduce((accumulator, item) => accumulator + Number(item.whtTotal), 0)), 
            paidTotal: _grandTotal - _items.reduce((accumulator, item) => accumulator + Number(item.whtTotal), 0) >= 0 ? mathRound(_grandTotal - _items.reduce((accumulator, item) => accumulator + Number(item.whtTotal), 0)) : 0
          }
          : {}),
      };

      if (Object.keys(_extraAttributes).length) {
        obj.extraAttributes = _extraAttributes
      }

      if (
        _documentType === 'INVOICE-TAXINVOICE' ||
        _documentType === 'ใบแจ้งหนี้/ใบกำกับภาษี' ||
        _documentType === 'DELIVERYORDER-TAXINVOICE' ||
        _documentType === 'ใบส่งของ/ใบกำกับภาษี' ||
        _documentType === 'INVOICE' ||
        _documentType === 'ใบแจ้งหนี้' ||
        _documentType === 'BILLING-NOTE' ||
        _documentType === 'ใบวางบิล' ||
        _documentType === 'DELIVERYORDER' ||
        _documentType === 'ใบส่งของ'
      ) {
        let _dueDate = '';
        let _dueDateCE = '';
        let _dueDateBE = '';
        if (document.dueDateBE && !isNaN(document.dueDateBE)) {
          if (String(document.dueDateBE).trim().length === 8) {
            _dueDateBE = getDateFromNewDateFormat(document.dueDateBE, 'dateBE');
            _dueDateCE = getDateFromNewDateFormat(document.dueDateBE, 'dateCE');
            _dueDate = getDateFromNewDateFormat(document.dueDateBE, 'date');
          } else {
            const _dateObject = checkExcelDateFormat(document.dueDateBE);

            if (_dateObject.getFullYear() < 2100 && _dateObject.getFullYear() + 543 > 2563) {
              _dueDateBE =
                String(_dateObject.getDate()).padStart(2, '0') +
                '/' +
                String(_dateObject.getMonth() + 1).padStart(2, '0') +
                '/' +
                (_dateObject.getFullYear() + 543);
              _dueDateCE =
                String(_dateObject.getDate()).padStart(2, '0') +
                '/' +
                String(_dateObject.getMonth() + 1).padStart(2, '0') +
                '/' +
                _dateObject.getFullYear();
            } else {
              _dueDateBE =
                String(_dateObject.getDate()).padStart(2, '0') +
                '/' +
                String(_dateObject.getMonth() + 1).padStart(2, '0') +
                '/' +
                _dateObject.getFullYear();
              _dueDateCE =
                String(_dateObject.getDate()).padStart(2, '0') +
                '/' +
                String(_dateObject.getMonth() + 1).padStart(2, '0') +
                '/' +
                (_dateObject.getFullYear() - 543);
            }

            let value = _dueDateBE;

            let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
            let dateParts = date.format().split('+');

            _dueDate = dateParts[0] + '.000Z';
          }
        } else if (document.dueDateBE && checkDateFormat(document.dueDateBE)) {
          let dueDateBEParts = document.dueDateBE.split('/');

          if (dueDateBEParts[0].length === 1) {
            dueDateBEParts[0] = '0' + dueDateBEParts[0];
          }

          if (dueDateBEParts[1].length === 1) {
            dueDateBEParts[1] = '0' + dueDateBEParts[1];
          }

          if (Number(dueDateBEParts[2]) < 2100 && Number(dueDateBEParts[2]) + 543 > 2563) {
            _dueDateBE = dueDateBEParts[0] + '/' + dueDateBEParts[1] + '/' + (Number(dueDateBEParts[2]) + 543);
            _dueDateCE = dueDateBEParts[0] + '/' + dueDateBEParts[1] + '/' + dueDateBEParts[2];
          } else {
            _dueDateBE = dueDateBEParts[0] + '/' + dueDateBEParts[1] + '/' + dueDateBEParts[2];
            _dueDateCE = dueDateBEParts[0] + '/' + dueDateBEParts[1] + '/' + (Number(dueDateBEParts[2]) - 543);
          }

          let value = _dueDateBE;

          let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
          let dateParts = date.format().split('+');

          _dueDate = dateParts[0] + '.000Z';
        }

        obj.dueDate = _dueDate;
        obj.dueDateBE = _dueDateBE || '';
        obj.dueDateCE = _dueDateCE;
      }

      if (
        _documentType === 'RECEIPT-TAXINVOICE' ||
        _documentType === 'INVOICE-TAXINVOICE' ||
        _documentType === 'DELIVERYORDER-TAXINVOICE' ||
        _documentType === 'TAXINVOICE' ||
        _documentType === 'RECEIPT-TAXINVOICE-ABB' ||
        _documentType === 'RECEIPT' ||
        _documentType === 'ใบเสร็จรับเงิน/ใบกำกับภาษี' ||
        _documentType === 'ใบแจ้งหนี้/ใบกำกับภาษี' ||
        _documentType === 'ใบส่งของ/ใบกำกับภาษี' ||
        _documentType === 'ใบกำกับภาษี' ||
        _documentType === 'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ' ||
        _documentType === 'ใบเสร็จรับเงิน'
      ) {
        if (document.reIssue) {
          let _referDate = '';
          let _referDateCE = '';
          let _referDateBE = '';
          if (document.referDateBE && !isNaN(document.referDateBE)) {
            if (String(document.referDateBE).trim().length === 8) {
              _referDateBE = getDateFromNewDateFormat(document.referDateBE, 'dateBE');
              _referDateCE = getDateFromNewDateFormat(document.referDateBE, 'dateCE');
              _referDate = getDateFromNewDateFormat(document.referDateBE, 'date');
            } else {
              const _dateObject = checkExcelDateFormat(document.referDateBE);

              if (_dateObject.getFullYear() < 2100 && _dateObject.getFullYear() + 543 > 2563) {
                _referDateBE =
                  String(_dateObject.getDate()).padStart(2, '0') +
                  '/' +
                  String(_dateObject.getMonth() + 1).padStart(2, '0') +
                  '/' +
                  (_dateObject.getFullYear() + 543);
                _referDateCE =
                  String(_dateObject.getDate()).padStart(2, '0') +
                  '/' +
                  String(_dateObject.getMonth() + 1).padStart(2, '0') +
                  '/' +
                  _dateObject.getFullYear();
              } else {
                _referDateBE =
                  String(_dateObject.getDate()).padStart(2, '0') +
                  '/' +
                  String(_dateObject.getMonth() + 1).padStart(2, '0') +
                  '/' +
                  _dateObject.getFullYear();
                _referDateCE =
                  String(_dateObject.getDate()).padStart(2, '0') +
                  '/' +
                  String(_dateObject.getMonth() + 1).padStart(2, '0') +
                  '/' +
                  (_dateObject.getFullYear() - 543);
              }

              let value = _referDateBE;

              let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
              let dateParts = date.format().split('+');

              _referDate = dateParts[0] + '.000Z';
            }
          } else if (document.referDateBE && checkDateFormat(document.referDateBE)) {
            let referDateBEParts = document.referDateBE.split('/');

            if (referDateBEParts[0].length === 1) {
              referDateBEParts[0] = '0' + referDateBEParts[0];
            }

            if (referDateBEParts[1].length === 1) {
              referDateBEParts[1] = '0' + referDateBEParts[1];
            }

            if (Number(referDateBEParts[2]) < 2100 && Number(referDateBEParts[2]) + 543 > 2563) {
              _referDateBE = referDateBEParts[0] + '/' + referDateBEParts[1] + '/' + (Number(referDateBEParts[2]) + 543);
              _referDateCE = referDateBEParts[0] + '/' + referDateBEParts[1] + '/' + referDateBEParts[2];
            } else {
              _referDateBE = referDateBEParts[0] + '/' + referDateBEParts[1] + '/' + referDateBEParts[2];
              _referDateCE = referDateBEParts[0] + '/' + referDateBEParts[1] + '/' + (Number(referDateBEParts[2]) - 543);
            }

            let value = _referDateBE;

            let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
            let dateParts = date.format().split('+');

            _referDate = dateParts[0] + '.000Z';
          }

          let _reasonName = '';
          let _specificReason = document.referSpecificReason || '';

          if (
            _documentType === 'RECEIPT-TAXINVOICE' ||
            _documentType === 'INVOICE-TAXINVOICE' ||
            _documentType === 'DELIVERYORDER-TAXINVOICE' ||
            _documentType === 'TAXINVOICE' ||
            _documentType === 'RECEIPT-TAXINVOICE-ABB' ||
            _documentType === 'ใบเสร็จรับเงิน/ใบกำกับภาษี' ||
            _documentType === 'ใบแจ้งหนี้/ใบกำกับภาษี' ||
            _documentType === 'ใบส่งของ/ใบกำกับภาษี' ||
            _documentType === 'ใบกำกับภาษี' ||
            _documentType === 'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ'
          ) {
            if (document.referReasonCode === 'TIVC01') {
              if (_language === 'en') {
                _reasonName = 'Invalid name';
              } else {
                _reasonName = 'ชื่อผิด';
              }
            } else if (document.referReasonCode === 'TIVC02') {
              if (_language === 'en') {
                _reasonName = 'Invalid address';
              } else {
                _reasonName = 'ที่อยู่ผิด';
              }
            } else if (document.referReasonCode === 'TIVC99') {
              if (_language === 'en') {
                _reasonName = 'Other reason';
              } else {
                _reasonName = 'เหตุอื่น';
              }
            }
          } else if (_documentType === 'RECEIPT' || _documentType === 'ใบเสร็จรับเงิน') {
            if (document.referReasonCode === 'RCTC01') {
              if (_language === 'en') {
                _reasonName = 'Invalid name';
              } else {
                _reasonName = 'ชื่อผิด';
              }
            } else if (document.referReasonCode === 'RCTC02') {
              if (_language === 'en') {
                _reasonName = 'Invalid address';
              } else {
                _reasonName = 'ที่อยู่ผิด';
              }
            } else if (document.referReasonCode === 'RCTC03') {
              if (_language === 'en') {
                _reasonName = 'Product return/service cancellation (full refund)';
              } else {
                _reasonName = 'รับคืนสินค้า/ยกเลิกบริการ ทั้งจำนวน';
              }
              if (!isNaN(document.referSpecificReason)) {
                if (_language === 'en') {
                  _specificReason = `${document.referSpecificReason} Baht as a refund (full refund)`;
                } else {
                  _specificReason = `รับคืนสินค้า/ยกเลิกบริการ ทั้งจำนวน ${document.referSpecificReason} บาท`;
                }
              }
            } else if (document.referReasonCode === 'RCTC04') {
              if (_language === 'en') {
                _reasonName = 'Product return/service cancellation (partial refund)';
              } else {
                _reasonName = 'รับคืนสินค้า/ยกเลิกบริการ บางส่วน';
              }
              if (!isNaN(document.referSpecificReason)) {
                if (_language === 'en') {
                  _specificReason = `${document.referSpecificReason} Baht as a refund (partial refund)`;
                } else {
                  _specificReason = `รับคืนสินค้า/ยกเลิกบริการ บางส่วน ${document.referSpecificReason} บาท`;
                }
              }
            } else if (document.referReasonCode === 'RCTC99') {
              if (_language === 'en') {
                _reasonName = 'Other reason';
              } else {
                _reasonName = 'เหตุอื่น';
              }
            }
          }

          obj.refer = {
            number: document.referNumber || '',
            date: _referDate,
            dateBE: _referDateBE || '',
            dateCE: _referDateCE,
            reasonCode: document.referReasonCode || '',
            reasonName: _reasonName,
            specificReason: _specificReason,
          };
        }
      } else if (
        _documentType === 'DEBIT-NOTE' ||
        _documentType === 'CREDIT-NOTE' ||
        _documentType === 'ใบเพิ่มหนี้' ||
        _documentType === 'ใบลดหนี้'
      ) {
        let _typeName = '';
        if (document.referTypeCode === 'T03') {
          _typeName = 'ใบเสร็จรับเงิน/ใบกำกับภาษี';
        } else if (document.referTypeCode === 'T02') {
          _typeName = 'ใบแจ้งหนี้/ใบกำกับภาษี';
        } else if (document.referTypeCode === 'T04') {
          _typeName = 'ใบส่งของ/ใบกำกับภาษี';
        } else if (document.referTypeCode === '388') {
          _typeName = 'ใบกำกับภาษี';
        }

        let _referDate = '';
        let _referDateCE = '';
        let _referDateBE = '';
        if (document.referDateBE && !isNaN(document.referDateBE)) {
          if (String(document.referDateBE).trim().length === 8) {
            _referDateBE = getDateFromNewDateFormat(document.referDateBE, 'dateBE');
            _referDateCE = getDateFromNewDateFormat(document.referDateBE, 'dateCE');
            _referDate = getDateFromNewDateFormat(document.referDateBE, 'date');
          } else {
            const _dateObject = checkExcelDateFormat(document.referDateBE);

            if (_dateObject.getFullYear() < 2100 && _dateObject.getFullYear() + 543 > 2563) {
              _referDateBE =
                String(_dateObject.getDate()).padStart(2, '0') +
                '/' +
                String(_dateObject.getMonth() + 1).padStart(2, '0') +
                '/' +
                (_dateObject.getFullYear() + 543);
              _referDateCE =
                String(_dateObject.getDate()).padStart(2, '0') +
                '/' +
                String(_dateObject.getMonth() + 1).padStart(2, '0') +
                '/' +
                _dateObject.getFullYear();
            } else {
              _referDateBE =
                String(_dateObject.getDate()).padStart(2, '0') +
                '/' +
                String(_dateObject.getMonth() + 1).padStart(2, '0') +
                '/' +
                _dateObject.getFullYear();
              _referDateCE =
                String(_dateObject.getDate()).padStart(2, '0') +
                '/' +
                String(_dateObject.getMonth() + 1).padStart(2, '0') +
                '/' +
                (_dateObject.getFullYear() - 543);
            }

            let value = _referDateBE;

            let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
            let dateParts = date.format().split('+');

            _referDate = dateParts[0] + '.000Z';
          }
        } else if (document.referDateBE && checkDateFormat(document.referDateBE)) {
          let referDateBEParts = document.referDateBE.split('/');

          if (referDateBEParts[0].length === 1) {
            referDateBEParts[0] = '0' + referDateBEParts[0];
          }

          if (referDateBEParts[1].length === 1) {
            referDateBEParts[1] = '0' + referDateBEParts[1];
          }

          if (Number(referDateBEParts[2]) < 2100 && Number(referDateBEParts[2]) + 543 > 2563) {
            _referDateBE = referDateBEParts[0] + '/' + referDateBEParts[1] + '/' + (Number(referDateBEParts[2]) + 543);
            _referDateCE = referDateBEParts[0] + '/' + referDateBEParts[1] + '/' + referDateBEParts[2];
          } else {
            _referDateBE = referDateBEParts[0] + '/' + referDateBEParts[1] + '/' + referDateBEParts[2];
            _referDateCE = referDateBEParts[0] + '/' + referDateBEParts[1] + '/' + (Number(referDateBEParts[2]) - 543);
          }

          let value = _referDateBE;

          let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
          let dateParts = date.format().split('+');

          _referDate = dateParts[0] + '.000Z';
        }

        let _reasonName = '';

        if (document.referReasonCode === 'DBNG01') {
          if (_language === 'en') {
            _reasonName = `Increasing in product's price (higher than the agreed amount)`;
          } else {
            _reasonName = 'มีการเพิ่มราคาสินค้า';
          }
        } else if (document.referReasonCode === 'DBNG02') {
          if (_language === 'en') {
            _reasonName = `Invalid product's price calculation (lower than it should be)`;
          } else {
            _reasonName = 'คำนวณราคาสินค้าผิดพลาดต่ำกว่าที่เป็นจริง';
          }
        } else if (document.referReasonCode === 'DBNG99') {
          if (_language === 'en') {
            _reasonName = 'Other reason (sale case)';
          } else {
            _reasonName = 'เหตุอื่น (กรณีขายสินค้า)';
          }
        } else if (document.referReasonCode === 'DBNS01') {
          if (_language === 'en') {
            _reasonName = `Increasing in service's price (higher than the agreed amount)`;
          } else {
            _reasonName = 'มีการเพิ่มราคาค่าบริการ';
          }
        } else if (document.referReasonCode === 'DBNS02') {
          if (_language === 'en') {
            _reasonName = `Invalid service's price calculation (lower than it should be)`;
          } else {
            _reasonName = 'คำนวณราคาค่าบริการผิดพลาดต่ำกว่าที่เป็นจริง';
          }
        } else if (document.referReasonCode === 'DBNS99') {
          if (_language === 'en') {
            _reasonName = `Other reason (service case)`;
          } else {
            _reasonName = 'เหตุอื่น (กรณีบริการ)';
          }
        } else if (document.referReasonCode === 'CDNG01') {
          if (_language === 'en') {
            _reasonName = `Decreasing in product's price (lower than the agreed amount)`;
          } else {
            _reasonName = 'ลดราคาสินค้าที่ขาย';
          }
        } else if (document.referReasonCode === 'CDNG02') {
          if (_language === 'en') {
            _reasonName = 'Product defect';
          } else {
            _reasonName = 'สินค้าชำรุดเสียหาย';
          }
        } else if (document.referReasonCode === 'CDNG03') {
          if (_language === 'en') {
            _reasonName = `Invalid product's quantity`;
          } else {
            _reasonName = 'สินค้าขาดจำนวนตามที่ตกลงซื้อขาย';
          }
        } else if (document.referReasonCode === 'CDNG04') {
          if (_language === 'en') {
            _reasonName = `Invalid product's price calculation (higher than it should be)`;
          } else {
            _reasonName = 'คำนวณราคาผิดพลาดสูงกว่าที่เป็นจริง';
          }
        } else if (document.referReasonCode === 'CDNG05') {
          if (_language === 'en') {
            _reasonName = 'Product return (not as advertised)';
          } else {
            _reasonName = 'รับคืนสินค้า';
          }
        } else if (document.referReasonCode === 'CDNG99') {
          if (_language === 'en') {
            _reasonName = `Other reason (sale case)`;
          } else {
            _reasonName = 'เหตุอื่น (กรณีขายสินค้า)';
          }
        } else if (document.referReasonCode === 'CDNS01') {
          if (_language === 'en') {
            _reasonName = `Decreasing in service's price (lower than the agreed amount)`;
          } else {
            _reasonName = 'ลดราคาค่าบริการ';
          }
        } else if (document.referReasonCode === 'CDNS02') {
          if (_language === 'en') {
            _reasonName = `Invalid service's quantity`;
          } else {
            _reasonName = 'ค่าบริการขาดจำนวน';
          }
        } else if (document.referReasonCode === 'CDNS03') {
          if (_language === 'en') {
            _reasonName = `Invalid service's price calculation (higher than it should be)`;
          } else {
            _reasonName = 'คำนวณราคาค่าบริการผิดพลาดสูงกว่าที่เป็นจริง';
          }
        } else if (document.referReasonCode === 'CDNS04') {
          if (_language === 'en') {
            _reasonName = `Service cancellation`;
          } else {
            _reasonName = 'บอกเลิกสัญญาบริการ';
          }
        } else if (document.referReasonCode === 'CDNS99') {
          if (_language === 'en') {
            _reasonName = `Other reason (service case)`;
          } else {
            _reasonName = 'เหตุอื่น (กรณีบริการ)';
          }
        }

        obj.calculationMethod = document.calculationMethod || 1;

        obj.refer = {
          typeCode: document.referTypeCode || '',
          typeName: _typeName,
          number: document.referNumber || '',
          date: _referDate,
          dateBE: _referDateBE || '',
          dateCE: _referDateCE,
          reasonCode: document.referReasonCode || '',
          reasonName: _reasonName,
          specificReason: document.referSpecificReason || '',
          amountTotal: mathRound(_previousAmountTotal),
          diffAmountTotal: mathRound(_diffAmountTotal),
          items: [],
        };
      }

      newArray.push(obj);
    }

    return newArray;
  };

  const handleQr = async (companyTaxId, paidTotal) => {
    let _qrPayload = await generatePayload(companyTaxId, { amount: paidTotal });

    let _url = '';
    await qrCode
      .toDataURL(_qrPayload)
      .then((url) => {
        _url = url;
      })
      .catch((err) => {
        console.error(err);
      });

    return _url;
  };
/* 
  useEffect(() => {
    console.log('userEffect initial call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        //Check documents spending limit
        await axios
          .get(API_URL + '/subscriptions', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log('Subscriptions data: ', response);

              if (response.data) {
                if (response.data && response.data.documents) {
                  console.log('Subscription etax documents: ', response.data.documents);

                  let _subscriptionDocuments = response.data.documents;

                  if (_subscriptionDocuments.spent + 1 > _subscriptionDocuments.limit + _subscriptionDocuments.topup) {
                    history.push({
                      pathname: '/billings',
                      state: {},
                    });
                  }
                }
              }
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 */
  return (
    <ExcelContext.Provider
      value={{
        passItems: passItems,
        setPassItems: setPassItems,
        failItems: failItems,
        setFailItems: setFailItems,
        disabled,
        setDisabled,
        complete,
        setComplete,
        inComplete,
        setInComplete,
        acceptedFilesList: acceptedFilesList,
        setAcceptedFilesList: setAcceptedFilesList,
        rowDetails: rowDetails,
        setRowDetails: setRowDetails,
        setDefault,
        test,
        setTest,
      }}
    >
      <Stack>
        {isLoading ? (
          <Stack>
            <Stack horizontal horizontalAlign="center">
              <br />
              <br />
              <div className="certificates-loading-center">
                <Spinner label={t('excelDropzone.processing')} labelPosition="down" styles={spinnerStyles} />
              </div>
              <br />
            </Stack>
          </Stack>
        ) : (
          <>
            <div>
              <br />
              <Stack horizontal horizontalAlign="center">
                <h1>
                <Icon iconName="ExcelDocument" style={{ color: '#106ebe', marginRight: 10 }} />
                  {t('excelDropzone.header')}</h1>
              </Stack>
              <br />
              <div style={{ padding: 12, backgroundColor: "white" }}>
                <div {...getRootProps({ className: 'dropzone', style: {backgroundColor: "white"} })}>
                  <input {...getInputProps()} />
                  <div className="center">
                    {/* <button type="button" style={{ padding: '10px 20px', fontSize: '16px' }} onClick={open}>
                      {t('excelDropzone.upload')}
                    </button> */}
                    <PrimaryButton iconProps={{ iconName: 'Upload', styles: {root: { fontSize: 20}}}} onClick={open} text={t('excelDropzone.upload')} styles={{ root: { padding: '20px 10px', fontSize: '18px'}}} />
                    <p>{t('excelDropzone.uploadDescription')}</p>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <Stack vertical horizontalAlign="center">
              <Link styles={{ root: { fontSize: 18 } }} href="https://www.leceipt.com/docs/etax/การสร้าง-e-tax-จาก-excel" target="_blank" underline>
                <Icon iconName='BookAnswers' styles={{ root: { marginRight: 10 }}} />{t('excelDropzone.article')}
              </Link>
              <br />
              {/* <Link href="/excel-templates/Leceipt-Excel-Template.xlsx" target="_blank" underline>
                {t('excelDropzone.excelTemplate')}
              </Link> */}
              <br />
              <Link styles={{ root: { fontSize: 18 } }} href="/excel-templates/Leceipt-Excel-Template-new-etax.xlsx" target="_blank" underline>
                <Icon iconName='ExcelDocument' styles={{ root: { marginRight: 10 }}} />{t('excelDropzone.excelTemplateNew')}
              </Link>
              <br />
              <Link styles={{ root: { fontSize: 18 } }} href="/excel-templates/Leceipt-Excel-Template-new-credit-debit.xlsx" target="_blank" underline>
                <Icon iconName='ExcelDocument' styles={{ root: { marginRight: 10 }}} />{t('excelDropzone.excelTemplateNewCreditDebit')}
              </Link>
            </Stack>
          </>
        )}
      </Stack>
      <Dialog
        hidden={!dialogAlert.isClick}
        onDismiss={() =>
          setDialogAlert((prev) => ({
            ...prev,
            isClick: false,
          }))
        }
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: <Icon iconName="Error" />,
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24, fontSize: 81, paddingBottom: 0 }, subText: { textAlign: 'center' } },
          subText: dialogAlert.message,
        }}
      >
        <DialogFooter styles={{ actionsRight: { textAlign: 'center', marginRight: 0 } }}>
          <DefaultButton
            onClick={() =>
              setDialogAlert((prev) => ({
                ...prev,
                isClick: false,
              }))
            }
            text={t('documents.close')}
          />
        </DialogFooter>
      </Dialog>
    </ExcelContext.Provider>
  );
}
