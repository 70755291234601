import { useAccount, useMsal } from '@azure/msal-react';
import { silentRequest } from '../../../../authConfig';
import { Icon, Spinner, SpinnerSize, Stack, TextField, getTheme, mergeStyleSets } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useRef } from 'react';

const theme = getTheme();

const controlClass = mergeStyleSets({
  control: {
    margin: '0 0 15px 0',
    maxWidth: '300px',
  },
  hover: {
    selectors: {
      '&:hover': { background: theme.palette.neutralLight },
    },
  },
});

const contactListStyle = {
  background: 'white',
  border: '1px solid #eee',
  boxShadow: '0 2px 3px #ccc',
  boxSizing: 'border-box',
  maxHeight: 200,
  width: "100%",
  position: 'absolute',
  overflow: 'auto',
  zIndex: 2,
  top: '100%'
};

const contactOptionStyle = {
  padding: '0px 8px',
  paddingTop: '8px',
  height: 26,
  cursor: 'pointer',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

// const autoCompleteOptions = [
//   { key: 'apple', text: 'Apple' },
//   { key: 'banana', text: 'Banana' },
//   { key: 'cherry', text: 'Cherry' },
//   { key: 'date', text: 'Date' },
//   // Add more options as needed
// ];

const API_URL = process.env.REACT_APP_API_URL;

const AutocompleteTextFieldContacts = (props) => {
  const { autocompleteType, name, label, componentRef, autoComplete, styles, maxLength, 
    value, onGetErrorMessage, validateOnLoad, description, setState, onSelectOption } = props

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const companyId = sessionStorage.getItem('companyId') || '';

  const [isLoading, setIsLoading] = useState(false);

  const [showSuggestions, setShowSuggestions] = useState(false)
  const [dropdownList, setDropdownList] = useState([])

  const [autoCompleteOptions, setAutoCompleteOptions] = useState([])

  function handleAutoComplete(value) {
    if (!value) {
      setShowSuggestions(false);

      setDropdownList(autoCompleteOptions);
    } else {
      setShowSuggestions(true);

      const activeProducts = autoCompleteOptions
                                .filter(_contact => _contact.name.includes(value) || 
                                                    _contact.taxId.includes(value))

      setDropdownList(activeProducts);
    }
  }

  useEffect(() => {
    setIsLoading(true)

    instance
    .acquireTokenSilent({ ...silentRequest, account: account })
    .then((tokenResponse) => {
      axios
      .get(
        `${API_URL}/contacts`, 
        {
          headers: {
            Authorization: 'Bearer ' + tokenResponse.accessToken,
            'Content-Type': 'application/json',
            'cid': companyId
          },
          // cancelToken: source.token
        }
      ).then(response => {
        setAutoCompleteOptions(response.data)
        setDropdownList(response.data);
        setIsLoading(false)
      })
    }).catch((error) => {
      //Acquire token silent failure, and send an interactive request
      console.log(error);
      instance.acquireTokenRedirect(silentRequest);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  function handleSelect(e, suggestion) {
    onSelectOption(e, suggestion)

    setDropdownList(autoCompleteOptions)
  }

  const handleTextFieldBlur = () => {
    setShowSuggestions(false);
  };

  const iconStyles = {
    root: {
      cursor: 'pointer',
      color: showSuggestions ? 'blue' : 'black', // Example: Change color based on list visibility
      // Add more custom styles as needed
    },
  };

  const autocompleteRef = useRef(null);

  const handleOutsideClick = event => {
    // Close the list when clicking outside the AutocompleteTextField
    if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      // Detach the event listener when the component unmounts
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div style={{ position: 'relative' }} ref={autocompleteRef}>
      <TextField
        name={name}
        label={label}
        componentRef={componentRef}
        styles={styles}
        maxLength={maxLength}
        onChange={(e) => {
          setState(e.target.value);
          handleAutoComplete(e.target.value)
        }}
        autoComplete={autoComplete}
        value={value}
        description={description ? description : ''}
        onGetErrorMessage={onGetErrorMessage}
        validateOnLoad={validateOnLoad}
        validateOnFocusOut
        required
        onBlur={handleTextFieldBlur}
        suffix={
          <Icon
            iconName="ChevronDown"
            styles={iconStyles}
            onClick={() => setShowSuggestions(prev => !prev)}
            onBlur={handleTextFieldBlur}
          />
        }
      />
      {showSuggestions && dropdownList[0] !== undefined && (
        <div style={contactListStyle}>
          {isLoading ? (
            <Stack verticalAlign="center" styles={{ root: { height: 30.4 }}}>
              <Spinner size={SpinnerSize.medium} />
            </Stack>
          ) : (
            dropdownList.map((suggestion, index) => {
              return (
                <div
                  key={index}
                  id={suggestion.name}
                  className={controlClass.hover}
                  style={contactOptionStyle}
                  onMouseDown={(e) => {
                    setShowSuggestions(false);
                    handleSelect(e, suggestion)
                  }}
                >
                  {autocompleteType === "name" ? (
                    `${suggestion.name} ${suggestion.taxId}`

                  ) : (
                    `${suggestion.taxId} ${suggestion.name}`
                  )}
                </div>
              );
            })

          )}
        </div>
      )}
    </div>
  );
};

export default AutocompleteTextFieldContacts;
