import { Dialog, DialogType, DialogFooter, Stack, Checkbox, PrimaryButton, DefaultButton } from "@fluentui/react";
import { TextFieldEmail } from "./TextFieldEmail";
import { checkEmail } from "../CheckEmail";
import { useTranslation } from "react-i18next";

export default function DialogEmail({ sendEmail, copyEmail, setIsClickEmail,
    isClickEmail, fileNameEmail, emailList, isClickCopyEmail, copyEmailList, 
    setEmailList, setCopyEmailList, setCustomerEmail, setCopyEmail, 
    setIsClickCopyEmail, fileDisplayName, customerEmail, fileIdEmail }) {

    const { t } = useTranslation();

    function TextFieldEmailComponent(props) {
        return (
          <TextFieldEmail 
            selectedItems={[...emailList.map(_email => (
              { key: _email, text: _email }
            ))]}
            onItemsRemoved={(removeItems, removeItemIndex) => {
              const currentSelectedItemsCopy = [...emailList];
              currentSelectedItemsCopy.splice(removeItemIndex, 1);
              // this.setState({ currentSelectedItems: [...currentSelectedItemsCopy] });
              setEmailList([...currentSelectedItemsCopy])
    
              let multiEmail = '';
    
              currentSelectedItemsCopy.forEach((email, index) => {
                multiEmail = multiEmail + email;
    
                if (currentSelectedItemsCopy.length - 1 !== index) {
                  multiEmail = multiEmail + ';';
                }
              });
              setCustomerEmail(multiEmail);
            }}
            onBlur={(e) => {
              if (checkEmail(e.target.value) && !emailList.includes(e.target.value)) {
                  const currentSelectedItemsCopy = [...emailList, e.target.value];
    
                  setEmailList(currentSelectedItemsCopy)
    
                  let multiEmail = '';
    
                  currentSelectedItemsCopy.forEach((email, index) => {
                    multiEmail = multiEmail + email;
    
                    if (currentSelectedItemsCopy.length - 1 !== index) {
                      multiEmail = multiEmail + ';';
                    }
                  });
                  setCustomerEmail(multiEmail);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                  if (checkEmail(e.target.value) && !emailList.includes(e.target.value)) {
                      const currentSelectedItemsCopy = [...emailList, e.target.value];
    
                      setEmailList(currentSelectedItemsCopy)
    
                      let multiEmail = '';
    
                      currentSelectedItemsCopy.forEach((email, index) => {
                        multiEmail = multiEmail + email;
    
                        if (currentSelectedItemsCopy.length - 1 !== index) {
                          multiEmail = multiEmail + ';';
                        }
                      });
                      setCustomerEmail(multiEmail);
    
                  }
              }
            }}
          />
        );
      }

    function TextFieldCopyEmailComponent(props) {
        return (
          <TextFieldEmail 
            selectedItems={[...copyEmailList.map(_email => (
              { key: _email, text: _email }
            ))]}
            onItemsRemoved={(removeItems, removeItemIndex) => {
              const currentSelectedItemsCopy = [...copyEmailList];
              currentSelectedItemsCopy.splice(removeItemIndex, 1);
              // this.setState({ currentSelectedItems: [...currentSelectedItemsCopy] });
              setCopyEmailList([...currentSelectedItemsCopy])
    
              let multiEmail = '';
    
              currentSelectedItemsCopy.forEach((email, index) => {
                multiEmail = multiEmail + email;
    
                if (currentSelectedItemsCopy.length - 1 !== index) {
                  multiEmail = multiEmail + ';';
                }
              });
              setCopyEmail(multiEmail);
            }}
            onBlur={(e) => {
              if (checkEmail(e.target.value) && !copyEmailList.includes(e.target.value)) {
                  const currentSelectedItemsCopy = [...copyEmailList, e.target.value];
    
                  setCopyEmailList(currentSelectedItemsCopy)
    
                  let multiEmail = '';
    
                  currentSelectedItemsCopy.forEach((email, index) => {
                    multiEmail = multiEmail + email;
    
                    if (currentSelectedItemsCopy.length - 1 !== index) {
                      multiEmail = multiEmail + ';';
                    }
                  });
                  setCopyEmail(multiEmail);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                  if (checkEmail(e.target.value) && !copyEmailList.includes(e.target.value)) {
                      const currentSelectedItemsCopy = [...copyEmailList, e.target.value];
    
                      setCopyEmailList(currentSelectedItemsCopy)
    
                      let multiEmail = '';
    
                      currentSelectedItemsCopy.forEach((email, index) => {
                        multiEmail = multiEmail + email;
    
                        if (currentSelectedItemsCopy.length - 1 !== index) {
                          multiEmail = multiEmail + ';';
                        }
                      });
                      setCopyEmail(multiEmail);
    
                  }
              }
            }}
          />
        );
      }
        
    return <Dialog
    hidden={!isClickEmail}
    dialogContentProps={{
      type: DialogType.largeHeader,
      title: t('documents.sendFileByEmail'),
      subText: fileNameEmail,
    }}
    minWidth={500}
  >
    <Stack vertical>
      <div>
        <label>{t('documents.sendToEmail')}</label>
      </div>
      <TextFieldEmailComponent />
      <br />
      <Checkbox
        label={t('documents.ccToEmail')}
        checked={isClickCopyEmail}
        onChange={(e) => {
          console.log('copy email checkbox: ', e.target.value);
          setIsClickCopyEmail((prev) => !prev);
        }}
      />
      {isClickCopyEmail && (
        <div style={{ marginTop: '8px' }}>
          <TextFieldCopyEmailComponent />
        </div>
      )}
      <br />
      <br />
    </Stack>
    <DialogFooter>
      <PrimaryButton
        onClick={() => {
          // console.log("customerEmail", customerEmail)
          if (isClickCopyEmail) {
            sendEmail(fileDisplayName, fileIdEmail, customerEmail, copyEmail);
          } else {
            sendEmail(fileDisplayName, fileIdEmail, customerEmail, '');
          }
        }}
        text={t('documents.sendEmail')}
      />
      <DefaultButton onClick={() => setIsClickEmail(false)} text={t('documents.cancel')} />
    </DialogFooter>
  </Dialog>
}