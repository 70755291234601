import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { silentRequest } from '../../../authConfig';

import { useMsal, useAccount } from '@azure/msal-react';

import paginate from 'jw-paginate';

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  Selection,
  SelectionMode,
  Icon,
  Calendar,
  DateRangeType,
  Spinner,
  SpinnerSize,
  CommandBar,
  Stack,
  SearchBox,
  PrimaryButton,
  DefaultButton,
  TextField,
  ContextualMenu,
  Toggle,
  CheckboxVisibility,
  Dialog,
  DialogType,
  DialogFooter,
  Checkbox,
  Text,
  DatePicker,
  TooltipHost,
} from '@fluentui/react';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

import axios from 'axios';
import { sendFeedback } from '../../../helpers/fetchData/feedback/Feedback';

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const onFormatDate = (date, format) => {
  if (format === 'CE') {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear();
  } else {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + (date.getFullYear() + 543);
  }
};

function getPostcode(str) {
  if (!str) return "00000"
  if (isNaN(str)) return "00000"
  if (str.length < 5) return str.padEnd(5, "0")
  return str.substring(0, 5);
}

function mathRound(number) {
  return Math.round(number * 100) / 100
}

function LineShopping() {
  const API_URL = process.env.REACT_APP_API_URL;

  // const location = useLocation();
  // const { apiKey } = location.state;

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const companyId = sessionStorage.getItem('companyId') || '';

  const { t, i18n } = useTranslation();

  const [company, setCompany] = useState('');
  const [items, setItems] = useState([]);

  const percentVat = 7;
  const zeroVat = 0;
  // const nonVat = -1;

  const percentVatText = '7%';
  const zeroVatText = '0%';
  // const nonVatText = '-';

  const [totalDocuments, setTotalDocuments] = useState(0);

  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(false);
  const [pageData, setPageData] = useState({});
  const [pageSize, setPageSize] = useState(sessionStorage.getItem('lineShopping-pageSize') * 1 || 10);
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('lineShopping-currentPage') * 1 || 1);

  const dateType = { key: 'document', text: 'วันที่บนเอกสาร' };

  //CommandBars Items
  const [orderType, setOrderType] = useState(
    (sessionStorage.getItem('lineShopping-orderType-key') && {
      key: sessionStorage.getItem('lineShopping-orderType-key'),
      text: sessionStorage.getItem('lineShopping-orderType-text'),
    }) || {
      key: 'all',
      text: t('lineShopping.allType'),
    }
  );

  const [paymentMethodType, setPaymentMethodType] = useState((sessionStorage.getItem('lineShopping-paymentMethodType-key') && {
    key: sessionStorage.getItem('lineShopping-paymentMethodType-key'),
    text: sessionStorage.getItem('lineShopping-paymentMethodType-text'),
  }) || {
    key: 'allPaymentMethodType',
    text: t('lineShopping.allPaymentMethodType'),
  })

  const [paymentStatus, setPaymentStatus] = useState((sessionStorage.getItem('lineShopping-paymentStatus-key') && {
    key: sessionStorage.getItem('lineShopping-paymentStatus-key'),
    text: sessionStorage.getItem('lineShopping-paymentStatus-text'),
  }) || {
    key: 'allPaymentStatus',
    text: t('lineShopping.allPaymentStatus'),
  })

  const [shipmentStatus, setShipmentStatus] = useState((sessionStorage.getItem('lineShopping-shipmentStatus-key') && {
    key: sessionStorage.getItem('lineShopping-shipmentStatus-key'),
    text: sessionStorage.getItem('lineShopping-shipmentStatus-text'),
  }) || {
    key: 'allShipmentStatus',
    text: t('lineShopping.allShipmentStatus'),
  })

  const [search, setSearch] = useState('');

  const [includeVat, setIncludeVat] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const [registerVat, setRegisterVat] = useState(true);

  const isCompactMode = false;

  const [isCreateDocument, setIsCreateDocument] = useState(false);
  const [isCreateDocumentComplete, setIsCreateDocumentComplete] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        let _selectedItems = selection.getSelection();

        setSelectedItems(_selectedItems);
      },
    })
  );


  const [dialogConfirm, setDialogConfirm] = useState({
    isClick: false,
    header: '',
    message: '',
    onConfirm: null,
    onCancel: null,
  });

  const [createdBy, setCreatedBy] = useState("");

  const [prefixNumber, setPrefixNumber] = useState('');
  const [countingNumber, setCountingNumber] = useState('');

  const [primarySort, setPrimarySort] = useState(sessionStorage.getItem('lineShopping-primarySort') || 'updatedTime');
  const [isSortedDescendingCreatedTime, setIsSortedDescendingCreatedTime] = useState(() => {
    if (sessionStorage.getItem("lineShopping-sort-createdTime") === "false") {
      return false;
    } else {
        return true;
    }
  });
  
  const [isSortedDescendingUpdatedTime, setIsSortedDescendingUpdatedTime] = useState(() => {
    if (sessionStorage.getItem('lineShopping-sort-updatedTime')) {
      if (sessionStorage.getItem('lineShopping-sort-updatedTime') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });

  const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(() => {
    if (sessionStorage.getItem('lineShopping-sort-number')) {
      if (sessionStorage.getItem('lineShopping-sort-number') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingCustomerName, setIsSortedDescendingCustomerName] = useState(() => {
    if (sessionStorage.getItem('lineShopping-sort-customerName')) {
      if (sessionStorage.getItem('lineShopping-sort-customerName') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingAmountTotal, setIsSortedDescendingAmountTotal] = useState(() => {
    if (sessionStorage.getItem('lineShopping-sort-amountTotal')) {
      if (sessionStorage.getItem('lineShopping-sort-amountTotal') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });

  const [isSortedDescendingPaymentStatus, setIsSortedDescendingPaymentStatus] = useState(() => {
    if (sessionStorage.getItem('lineShopping-sort-paymentStatus')) {
      if (sessionStorage.getItem('lineShopping-sort-paymentStatus') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });

  const [isSortedDescendingPaymentMethod, setIsSortedDescendingPaymentMethod] = useState(() => {
    if (sessionStorage.getItem('lineShopping-sort-paymentMethod')) {
      if (sessionStorage.getItem('lineShopping-sort-paymentMethod') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });

  const [isSortedDescendingShipmentStatus, setIsSortedDescendingShipmentStatus] = useState(() => {
    if (sessionStorage.getItem('lineShopping-sort-shipmentStatus')) {
      if (sessionStorage.getItem('lineShopping-sort-shipmentStatus') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });

  const [isSortedDescendingEtaxCreated, setIsSortedDescendingEtaxCreated] = useState(() => {
    if (sessionStorage.getItem("lineShopping-sort-etaxCreated")) {
        if (sessionStorage.getItem("lineShopping-sort-etaxCreated") === "true") {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
});

  const [showShippingFee, setShowShippingFee] = useState(true);

  const DayPickerStrings = {
    months: [
      t('lineShopping.months01'),
      t('lineShopping.months02'),
      t('lineShopping.months03'),
      t('lineShopping.months04'),
      t('lineShopping.months05'),
      t('lineShopping.months06'),
      t('lineShopping.months07'),
      t('lineShopping.months08'),
      t('lineShopping.months09'),
      t('lineShopping.months10'),
      t('lineShopping.months11'),
      t('lineShopping.months12'),
    ],

    shortMonths: [
      t('lineShopping.shortMonths01'),
      t('lineShopping.shortMonths02'),
      t('lineShopping.shortMonths03'),
      t('lineShopping.shortMonths04'),
      t('lineShopping.shortMonths05'),
      t('lineShopping.shortMonths06'),
      t('lineShopping.shortMonths07'),
      t('lineShopping.shortMonths08'),
      t('lineShopping.shortMonths09'),
      t('lineShopping.shortMonths10'),
      t('lineShopping.shortMonths11'),
      t('lineShopping.shortMonths12'),
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: t('lineShopping.goToToday'),
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: t('lineShopping.isRequiredErrorMessage'),

    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };

  const createSelectedDocuments = async (documentType) => {
    let _items = selection.getItems();

    let postUrl = '';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/receipts-taxinvoices/pdf-xml';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        postUrl = API_URL + '/etax/documents/receipts-taxinvoices-abb/pdf';
        break;
      case 'RECEIPT':
        postUrl = API_URL + '/etax/documents/receipts/pdf-xml';
        break;
      case 'INVOICE-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/invoices-taxinvoices/pdf-xml';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/deliveryorders-taxinvoices/pdf-xml';
        break;
      case 'TAXINVOICE':
        postUrl = API_URL + '/etax/documents/taxinvoices/pdf-xml';
        break;
      default:
        break;
    }

    if (postUrl) {
      let _countingNumberLength = countingNumber.length;
      let _countingNumber = Number(countingNumber);

      await instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          for (let i = 0; i < selectedItems.length; i++) {
            console.log('create selected document: ', selectedItems[i]);

            for (let j = 0; j < _items.length; j++) {
              if (_items[j].key === selectedItems[i].key) {
                _items[j].status = 'processing';
                setItems([..._items]);

                const [response] = await Promise.all([
                  axios
                    // .get('http://localhost:7071/api/Line_getOrderDetail', {
                    .get(API_URL + '/sources/line-shopping/orders/' + selectedItems[i].key, {
                      headers: {
                        Authorization: 'Bearer ' + tokenResponse.accessToken,
                        cid: companyId
                    },
                      /* 
                                            params: {
                                                orderNo: selectedItems[i].key,
                                                // apiKey: apiKey,
                                            },
                                             */
                    })
                    .catch((error) => console.log(error)),
                ]);

                const { orderItems, paymentMethod } = response.data;

                const _orderItems = [];

                orderItems.forEach((_itemInOrder, index) => {
                  const { 
                    price, 
                    discountedPrice, 
                    name, 
                    quantity, 
                    sku,
                    variants
                  } = _itemInOrder;

                  let _description = name

                  if (sku) {
                    _description = _description + ' ' + sku
                  }

                  if (variants?.length) {
                    variants.forEach(_variant => _description = _description + ' ' + _variant.value)
                  }

                  if (registerVat) {
                    _orderItems.push({
                      number: index + 1 + '',
                      description: _description,
                      quantity: quantity,
                      unitCode: '',
                      unitName: '-',
                      price: price,
                      discount: price - discountedPrice,
                      percentVat: percentVat,
                      percentVatText: percentVatText,
                      total: discountedPrice * quantity,
                    });
                  } else {
                    _orderItems.push({
                      number: index + 1 + '',
                      description: _description,
                      quantity: quantity,
                      unitCode: '',
                      unitName: '-',
                      price: price,
                      discount: price - discountedPrice,
                      percentVat: zeroVat,
                      percentVatText: zeroVatText,
                      total: discountedPrice * quantity,
                    });
                  }
                });

                if (showShippingFee) {
                  if (registerVat) {
                    _orderItems.push({
                      number: _orderItems.length + 1 +'',
                      description: 'ค่าส่ง',
                      quantity: 1,
                      unitCode: '',
                      unitName: '-',
                      price: selectedItems[i].shippingFee,
                      discount: 0,
                      percentVat: percentVat,
                      percentVatText: percentVatText,
                      total: selectedItems[i].shippingFee,
                    });
                  } else {
                    _orderItems.push({
                      number: _orderItems.length + 1 + '',
                      description: 'ค่าส่ง',
                      quantity: 1,
                      unitCode: '',
                      unitName: '-',
                      price: selectedItems[i].shippingFee,
                      discount: 0,
                      percentVat: zeroVat,
                      percentVatText: zeroVatText,
                      total: selectedItems[i].shippingFee,
                    });
                  }
                }

                let _discount = selectedItems[i].discount;
                let _amountTotal = 0;
                let _vatTotal = 0;
                let _grandTotal = 0;

                if (includeVat) {
                  _grandTotal = _orderItems.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                  _vatTotal = Math.round(((_grandTotal * percentVat) / (100 + percentVat)) * 100) / 100;
                  _amountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
                } else {
                  _amountTotal = _orderItems.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                  _vatTotal = Math.round(((_grandTotal * percentVat) / (100 + percentVat)) * 100) / 100;
                  _grandTotal = Math.round((_amountTotal + _vatTotal) * 100) / 100;
                }

                let _taxNumberType = 'OTHR';
                let _taxId = 'N/A';
                let _branchNumber = '';
                let _branchText = '';

                const _customer = {
                  addressLineOne: selectedItems[i].customerAddress,
                  addressLineTwo: '',
                  branchNumber: _branchNumber,
                  branchText: _branchText,
                  email: selectedItems[i].customerEmail,
                  name: selectedItems[i].customerName,
                  phone: selectedItems[i].customerPhone,
                  phoneCountryCode: '+66',
                  postcode: selectedItems[i].customerPostcode,
                  taxId: _taxId,
                  taxNumberType: _taxNumberType,
                  language: 'th',
                  unstructure: true,
                };

                let _note = ""
                if (selectedItems[i].note) {
                  _note = _note + selectedItems[i].note
                }

                if (paymentMethod) {
                  if (selectedItems[i].note) {
                    _note = _note + " "
                  }
                  if (paymentMethod === "BANK") {
                    _note = _note + "BANK"
                  } else if (paymentMethod === "COD") {
                    _note = _note + "COD"
                  } else if (paymentMethod === "PAYMENT_LS_RLP") {
                    _note = _note + "CREDIT/DEBIT CARD"
                  } else if (paymentMethod === "PAYMENT_LS_MOBILE_BANKING_KPLUS") {
                    _note = _note + "KPLUS"
                  } else if (paymentMethod === "PAYMENT_LS_MOBILE_BANKING_SCB") {
                    _note = _note + "SCB"
                  }
                }

                let _date = selectedItems[i].updatedDate
                let _dateBE = selectedItems[i].updatedDateBE
                let _dateCE = selectedItems[i].updatedDateCE

                if (paymentMethod === "BANK") {
                  _date = selectedItems[i].date
                  _dateBE = selectedItems[i].dateBE
                  _dateCE = selectedItems[i].dateCE
                }

                let _vatPriceTotal = 0;
                let _zeroVatPriceTotal = 0;
                let _nonVatPriceTotal = 0;

                _orderItems.forEach((item) => {
                  if (item.percentVat === percentVat) {
                    _vatPriceTotal = _vatPriceTotal + item.total;
                  } else if (item.percentVat === zeroVat) {
                    _zeroVatPriceTotal = _zeroVatPriceTotal + item.total;
                  }
                });

                if (_vatPriceTotal > 0) {
                  _vatPriceTotal = _amountTotal;
                } else if (_zeroVatPriceTotal > 0) {
                  _zeroVatPriceTotal = _amountTotal;
                }

                const _extraAttributes = {}

                try {
                  const responseSettlementDetail = await axios.get(API_URL + '/sources/line-shopping/settlements/' + selectedItems[i].key, {
                                                          headers: {
                                                            Authorization: 'Bearer ' + tokenResponse.accessToken,
                                                            cid: companyId
                                                          }
                                                        })
        
                  console.log("responseSettlementDetail", responseSettlementDetail);
        
                  const { orderIncomeDetail, settlementDetail } = responseSettlementDetail.data
        
                  const _paidDate = selectedItems[i].paidAt ? new Date(selectedItems[i].paidAt) : null
        
                  let _settlementDate = ""
                  if (settlementDetail?.settlementDate) {
                    const settlementDateSplit = settlementDetail?.settlementDate.split("-")
                    _settlementDate = `${settlementDateSplit[2]}/${settlementDateSplit[1]}/${Number(settlementDateSplit[0]) + 543}` 
                  }

                  ///////////////////////////////////////////////////////////////

                  // attribute001 เอาไว้แสดงว่ามี extraAttributes หรือไม่ ถ้ามีจะเป็น true ถ้าไม่จะเป็น false
                  // attribute002 วันที่ผู้ซื้อชำระเงิน (Paid Date)
                  // attribute003 ยอดรวมที่ชำระโดยลูกค้า
                  // attribute004 ค่าธรรมเนียมการชำระเงิน 3%
                  // attribute005 ค่าธรรมเนียมการใช้บริการ
                  // attribute006 ภาษีมูลค่าเพิ่ม
                  // attribute007 ยอดเงินที่ได้รับ
                  // attribute008 ค่าธรรมเนียมที่หักทั้งหมด (ค่าธรรมเนียมการชำระเงิน 3% + ค่าธรรมเนียมการใช้บริการ + ภาษีมูลค่าเพิ่ม)
                  // attribute009 วันที่ Line จ่ายเงินให้ผู้ขาย (วันที่รับเงิน)

                  ///////////////////////////////////////////////////////////////

                  _extraAttributes.attribute001 = true
                  _extraAttributes.attribute002 = _paidDate ? `${_paidDate.getDate()}`.padStart(2, '0') + '/' + `${_paidDate.getMonth() + 1}`.padStart(2, '0') + '/' + (_paidDate.getFullYear() + 543) : ""
                  _extraAttributes.attribute003 = orderIncomeDetail.totalPayByBuyer
                  _extraAttributes.attribute004 = orderIncomeDetail.transactionFee
                  _extraAttributes.attribute005 = orderIncomeDetail.serviceFee
                  _extraAttributes.attribute006 = orderIncomeDetail.vatAmount
                  _extraAttributes.attribute007 = orderIncomeDetail.orderIncome
                  _extraAttributes.attribute008 = mathRound(orderIncomeDetail.transactionFee + orderIncomeDetail.serviceFee + orderIncomeDetail.vatAmount)
                  _extraAttributes.attribute009 = _settlementDate

                } catch (error) {
                  console.log("error settlements", error);
        
                  _extraAttributes.attribute001 = false
                  _extraAttributes.attribute002 = ""
                  _extraAttributes.attribute003 = ""
                  _extraAttributes.attribute004 = ""
                  _extraAttributes.attribute005 = ""
                  _extraAttributes.attribute006 = ""
                  _extraAttributes.attribute007 = ""
                  _extraAttributes.attribute008 = ""
                  _extraAttributes.attribute009 = ""
                }

                let _number = prefixNumber + padLeadingZeros(Number(_countingNumber), _countingNumberLength);
                _countingNumber = padLeadingZeros(Number(_countingNumber) + 1, _countingNumberLength);
                setCountingNumber(_countingNumber);

                const promise_PDF_XML = await new Promise(async (resolve) => {
                  await axios
                    .post(
                      postUrl,
                      {
                        amountTotal: _amountTotal,
                        company: {
                          ...company,
                          includeVat: includeVat,
                        },
                        reference: selectedItems[i].key,
                        customer: _customer,
                        date: _date,
                        dateBE: _dateBE,
                        dateCE: _dateCE,
                        discount: _discount,
                        grandTotal: _grandTotal,
                        nonVatPriceTotal: _nonVatPriceTotal,
                        zeroVatPriceTotal: _zeroVatPriceTotal,
                        vatPriceTotal: _vatPriceTotal,
                        items: _orderItems,
                        percentVat: registerVat ? percentVat : 0,
                        vatTotal: _vatTotal,
                        reIssue: false,
                        includeVat: includeVat,
                        number: _number,
                        note: _note,
                        receivedBy: createdBy,
                        createdBy: createdBy,
                        extraAttributes: _extraAttributes
                      },
                      {
                        headers: {
                          Authorization: 'Bearer ' + tokenResponse.accessToken,
                          cid: companyId
                        },
                      }
                    )
                    .then(
                      async (response) => {
                        console.log('Create document response: ', response);

                        let count = 0;

                        let interval = await setInterval(async () => {
                          await axios
                            .get(`${API_URL}/etax/jobs/${response.data.id}`, {
                              headers: {
                                Authorization: 'Bearer ' + tokenResponse.accessToken,
                                'Content-Type': 'application/json',
                                cid: companyId
                            },
                            })
                            .then((jobsResponse) => {
                              count += 1;
                              console.log('Jobs response: ', j, jobsResponse.data.status, count);

                              if (jobsResponse.data.status === 'complete') {
                                _items[j].status = 'successful';
                                _items[j].etaxCreated = true;

                                setItems([..._items]);

                                clearInterval(interval);

                                resolve(true);
                              } else if (count >= 300) {
                                _items[j].status = 'fail';

                                setItems([..._items]);

                                clearInterval(interval);

                                resolve(false);
                              }
                            });
                        }, 1000);
                      },
                      (error) => {
                        console.log('Error Create document response: ', error);
                        _items[j].status = 'fail';
                        setItems([..._items]);
                        resolve(false);
                      }
                    );
                }); //promise

                Promise.all([promise_PDF_XML]).then((value) => {
                  console.log(i, value);
                });
              } //if
            } //for j
          } //for i
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }

    setIsCreateDocumentComplete(true);
    setIsCreateDocument(false);
  };

  const createDocument = async (documentType, item) => {
    setIsLoading(true);
    
    console.log('item: ', item);

    let documentPath = '/etax/documents/receipts';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        documentPath = '/etax/documents/receipts-taxinvoices';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        documentPath = '/etax/documents/receipts-taxinvoices-abb';
        break;
      case 'RECEIPT':
        documentPath = '/etax/documents/receipts';
        break;
      case 'INVOICE-TAXINVOICE':
        documentPath = '/etax/documents/invoices-taxinvoices';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        documentPath = '/etax/documents/deliveryorders-taxinvoices';
        break;
      case 'TAXINVOICE':
        documentPath = '/etax/documents/taxinvoices';
        break;
      default:
        break;
    }

    await instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        await axios
          //   .get('http://localhost:7071/api/Line_getOrderDetail', {
          .get(API_URL + '/sources/line-shopping/orders/' + item.key, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              cid: companyId
            },
            /* 
                        params: {
                            orderNo: item.key,
                            //   apiKey: apiKey,
                        },
                         */
          })
          .then(async function (response) {
            const { orderItems, paymentMethod } = response.data;

            console.log("response.data", response.data)

            const _items = [];

            orderItems.forEach((_item, index) => {
              const { 
                price, 
                discountedPrice, 
                name, 
                quantity, 
                sku,
                variants
              } = _item;

              let _description = name

              if (sku) {
                _description = _description + ' ' + sku
              }

              if (variants?.length) {
                variants.forEach(_variant => _description = _description + ' ' + _variant.value)
              }

              if (registerVat) {
                _items.push({
                  number: index + 1 + '',
                  description: _description,
                  quantity: quantity,
                  unitCode: '',
                  unitName: '-',
                  price: price,
                  discount: price - discountedPrice,
                  percentVat: percentVat,
                  percentVatText: percentVatText,
                  total: discountedPrice * quantity,
                });
              } else {
                _items.push({
                  number: index + 1 + '',
                  description: _description,
                  quantity: quantity,
                  unitCode: '',
                  unitName: '-',
                  price: price,
                  discount: price - discountedPrice,
                  percentVat: zeroVat,
                  percentVatText: zeroVatText,
                  total: discountedPrice * quantity,
                });
              }
            });

            if (showShippingFee) {
              if (registerVat) {
                _items.push({
                  number: _items.length + 1 + '',
                  description: 'ค่าส่ง',
                  quantity: 1,
                  unitCode: '',
                  unitName: '-',
                  price: item.shippingFee,
                  discount: 0,
                  percentVat: percentVat,
                  percentVatText: percentVatText,
                  total: item.shippingFee,
                });
              } else {
                _items.push({
                  number: _items.length + 1 + '',
                  description: 'ค่าส่ง',
                  quantity: 1,
                  unitCode: '',
                  unitName: '-',
                  price: item.shippingFee,
                  discount: 0,
                  percentVat: zeroVat,
                  percentVatText: zeroVatText,
                  total: item.shippingFee,
                });
              }
            }

            let _discount = item.discount;
            let _amountTotal = 0;
            let _vatTotal = 0;
            let _grandTotal = 0;

            if (includeVat) {
              _grandTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
              _vatTotal = Math.round(((_grandTotal * percentVat) / (100 + percentVat)) * 100) / 100;
              _amountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
            } else {
              _amountTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
              _vatTotal = Math.round(((_grandTotal * percentVat) / (100 + percentVat)) * 100) / 100;
              _grandTotal = Math.round((_amountTotal + _vatTotal) * 100) / 100;
            }

            let _taxNumberType = 'OTHR';
            let _taxId = 'N/A';
            let _branchNumber = '';
            let _branchText = '';

            const _customer = {
              addressLineOne: item.customerAddress,
              addressLineTwo: '',
              branchNumber: _branchNumber,
              branchText: _branchText,
              email: item.customerEmail,
              name: item.customerName,
              phone: item.customerPhone,
              phoneCountryCode: '+66',
              postcode: item.customerPostcode,
              taxId: _taxId,
              taxNumberType: _taxNumberType,
              language: 'th',
              unstructure: true,
            };

            let _note = ""
            if (item.note) {
              _note = _note + item.note
            }

            if (paymentMethod) {
              if (item.note) {
                _note = _note + " "
              }
              if (paymentMethod === "BANK") {
                _note = _note + "BANK"
              } else if (paymentMethod === "COD") {
                _note = _note + "COD"
              } else if (paymentMethod === "PAYMENT_LS_RLP") {
                _note = _note + "CREDIT/DEBIT CARD"
              } else if (paymentMethod === "PAYMENT_LS_MOBILE_BANKING_KPLUS") {
                _note = _note + "KPLUS"
              } else if (paymentMethod === "PAYMENT_LS_MOBILE_BANKING_SCB") {
                _note = _note + "SCB"
              }
            }

            let _date = item.updatedDate
            let _dateBE = item.updatedDateBE
            let _dateCE = item.updatedDateCE
            if (paymentMethod === "BANK") {
              _date = item.date
              _dateBE = item.dateBE
              _dateCE = item.dateCE
            }

            const _extraAttributes = {}

            try {
              const responseSettlementDetail = await axios.get(API_URL + '/sources/line-shopping/settlements/' + item.key, {
                                                      headers: {
                                                        Authorization: 'Bearer ' + tokenResponse.accessToken,
                                                        cid: companyId
                                                      }
                                                    })

              console.log("responseSettlementDetail", responseSettlementDetail);

              const { orderIncomeDetail, settlementDetail } = responseSettlementDetail.data

              const _paidDate = item.paidAt ? new Date(item.paidAt) : null

              let _settlementDate = ""
              if (settlementDetail?.settlementDate) {
                const settlementDateSplit = settlementDetail?.settlementDate.split("-")
                _settlementDate = `${settlementDateSplit[2]}/${settlementDateSplit[1]}/${Number(settlementDateSplit[0]) + 543}` 
              }

              ///////////////////////////////////////////////////////////////

              // attribute001 เอาไว้แสดงว่ามี extraAttributes หรือไม่ ถ้ามีจะเป็น true ถ้าไม่จะเป็น false
              // attribute002 วันที่ผู้ซื้อชำระเงิน (Paid Date)
              // attribute003 ยอดรวมที่ชำระโดยลูกค้า
              // attribute004 ค่าธรรมเนียมการชำระเงิน 3%
              // attribute005 ค่าธรรมเนียมการใช้บริการ
              // attribute006 ภาษีมูลค่าเพิ่ม
              // attribute007 ยอดเงินที่ได้รับ
              // attribute008 ค่าธรรมเนียมที่หักทั้งหมด (ค่าธรรมเนียมการชำระเงิน 3% + ค่าธรรมเนียมการใช้บริการ + ภาษีมูลค่าเพิ่ม)
              // attribute009 วันที่ Line จ่ายเงินให้ผู้ขาย (วันที่รับเงิน)

              ///////////////////////////////////////////////////////////////

              _extraAttributes.attribute001 = true
              _extraAttributes.attribute002 = _paidDate ? `${_paidDate.getDate()}`.padStart(2, '0') + '/' + `${_paidDate.getMonth() + 1}`.padStart(2, '0') + '/' + (_paidDate.getFullYear() + 543) : ""
              _extraAttributes.attribute003 = orderIncomeDetail.totalPayByBuyer
              _extraAttributes.attribute004 = orderIncomeDetail.transactionFee
              _extraAttributes.attribute005 = orderIncomeDetail.serviceFee
              _extraAttributes.attribute006 = orderIncomeDetail.vatAmount
              _extraAttributes.attribute007 = orderIncomeDetail.orderIncome
              _extraAttributes.attribute008 = mathRound(orderIncomeDetail.transactionFee + orderIncomeDetail.serviceFee + orderIncomeDetail.vatAmount)
              _extraAttributes.attribute009 = _settlementDate

            } catch (error) {
              console.log("error settlements", error);

              _extraAttributes.attribute001 = false
              _extraAttributes.attribute002 = ""
              _extraAttributes.attribute003 = ""
              _extraAttributes.attribute004 = ""
              _extraAttributes.attribute005 = ""
              _extraAttributes.attribute006 = ""
              _extraAttributes.attribute007 = ""
              _extraAttributes.attribute008 = ""
              _extraAttributes.attribute009 = ""
            }

            history.push({
              pathname: documentPath,
              state: {
                command: 'EDIT',
                amountTotal: _amountTotal,
                company: {
                  ...company,
                  includeVat: includeVat,
                },
                reference: item.key,
                customer: _customer,
                date: _date,
                dateBE: _dateBE,
                dateCE: _dateCE,
                grandTotal: _grandTotal,
                items: _items,
                number: '',
                percentVat: registerVat ? percentVat : 0,
                receivedBy: 'LINE SHOPPING',
                vatTotal: _vatTotal,
                reIssue: false,
                discount: _discount,
                backUrl: '/etax/sources/line-shopping',
                includeVat: includeVat,
                note: _note,
                extraAttributes: _extraAttributes
              },
            });
          },
          (error) => {
            console.log(error);
          }
        );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const columns = [
    {
      key: 'column1',
      name: t('lineShopping.updatedDate'),
      fieldName: 'updatedTime',
      minWidth: 75,
      maxWidth: 75,
      isResizable: true,
      isSorted: primarySort === 'updatedTime' ? true : false,
      isSortedDescending: isSortedDescendingUpdatedTime,
      isSortedAscending: !isSortedDescendingUpdatedTime,
      onColumnClick: (item) => {
        console.log('Sort document createTime!');
        setPrimarySort('updatedTime');
        setIsSortedDescendingUpdatedTime(!isSortedDescendingUpdatedTime);

        sessionStorage.setItem('lineShopping-primarySort', 'updatedTime');
        sessionStorage.setItem('lineShopping-sort-updatedTime', !isSortedDescendingUpdatedTime === false ? 'false' : 'true');

        sessionStorage.removeItem('lineShopping-sort-number');
        sessionStorage.removeItem('lineShopping-sort-customerName');
        sessionStorage.removeItem('lineShopping-sort-amountTotal');

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);

        setCurrentPage(1);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <TooltipHost content={item.updatedDateBE}>{item.updatedDateBE}</TooltipHost>
      },
      // isPadded: true,
    },
    {
      key: 'column2',
      name: t('lineShopping.createdTime'),
      fieldName: 'createdTime',
      minWidth: 75,
      maxWidth: 75,
      isResizable: true,
      isSorted: primarySort === 'createdTime' ? true : false,
      isSortedDescending: isSortedDescendingCreatedTime,
      isSortedAscending: !isSortedDescendingCreatedTime,
      onColumnClick: (item) => {
        console.log('Sort document createTime!');
        setPrimarySort('createdTime');
        setIsSortedDescendingCreatedTime(!isSortedDescendingCreatedTime);

        sessionStorage.setItem('lineShopping-primarySort', 'createdTime');
        sessionStorage.setItem('lineShopping-sort-createdTime', !isSortedDescendingCreatedTime === false ? 'false' : 'true');

        sessionStorage.removeItem('lineShopping-sort-number');
        sessionStorage.removeItem('lineShopping-sort-customerName');
        sessionStorage.removeItem('lineShopping-sort-amountTotal');

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);

        setCurrentPage(1);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <TooltipHost content={item.dateBE}>{item.dateBE}</TooltipHost>
      },
      // isPadded: true,
    },
    {
      key: 'column3',
      name: t('lineShopping.orderId'),
      fieldName: 'number',
      minWidth: 130,
      maxWidth: 130,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'number' ? true : false,
      isSortedDescending: isSortedDescendingNumber,
      isSortedAscending: !isSortedDescendingNumber,
      onColumnClick: () => {
        console.log('Sort document number!');
        setPrimarySort('number');
        setIsSortedDescendingNumber(!isSortedDescendingNumber);

        sessionStorage.setItem('lineShopping-primarySort', 'number');
        sessionStorage.setItem('lineShopping-sort-number', !isSortedDescendingNumber === false ? 'false' : 'true');

        sessionStorage.removeItem('lineShopping-sort-createdTime');
        sessionStorage.removeItem('lineShopping-sort-customerName');
        sessionStorage.removeItem('lineShopping-sort-amountTotal');

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);

        setCurrentPage(1);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <TooltipHost content={item.key}>{item.key}</TooltipHost>
      },
      // isPadded: true,
    },
    {
      key: 'column4',
      name: t('lineShopping.customerName'),
      fieldName: 'customerName',
      minWidth: 80,
      maxWidth: 135,
      isResizable: true,
      isSorted: primarySort === 'customerName' ? true : false,
      isSortedDescending: isSortedDescendingCustomerName,
      isSortedAscending: !isSortedDescendingCustomerName,
      onColumnClick: () => {
        console.log('Sort document customerName!');
        setPrimarySort('customerName');
        setIsSortedDescendingCustomerName(!isSortedDescendingCustomerName);

        handleSort('customerName', !isSortedDescendingCustomerName);

        sessionStorage.setItem('lineShopping-primarySort', 'customerName');
        sessionStorage.setItem('lineShopping-sort-customerName', !isSortedDescendingCustomerName === false ? 'false' : 'true');

        sessionStorage.removeItem('lineShopping-sort-createdTime');
        sessionStorage.removeItem('lineShopping-sort-number');
        sessionStorage.removeItem('lineShopping-sort-amountTotal');

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <TooltipHost content={item.customerName}>{item.customerName}</TooltipHost>
      },
      // isPadded: true,
    },
    // {
    //   key: 'column5',
    //   name: t('lineShopping.customerAddress'),
    //   fieldName: 'customerAddress',
    //   minWidth: 80,
    //   maxWidth: 270,
    //   isRowHeader: true,
    //   isResizable: true,
    //   isSorted: false,
    //   onColumnClick: '',
    //   data: 'string',
    //   onRender: (item) => {
    //     return <TooltipHost content={item.customerAddress}>{item.customerAddress}</TooltipHost>
    //   },
    //   // isPadded: true,
    // },
    // {
    //   key: 'column6',
    //   name: t('lineShopping.phone'),
    //   fieldName: 'customerPhone',
    //   minWidth: 80,
    //   maxWidth: 80,
    //   isRowHeader: true,
    //   isResizable: true,
    //   isSorted: false,
    //   isCollapsible: true,
    //   data: 'string',
    //   onRender: (item) => {
    //     return <TooltipHost content={item.customerPhone}>{item.customerPhone}</TooltipHost>
    //   },
    //   // isPadded: true,
    // },
    {
      key: 'column7',
      name: t('lineShopping.total'),
      fieldName: 'amountTotal',
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
      isSorted: primarySort === 'amountTotal' ? true : false,
      isSortedDescending: isSortedDescendingAmountTotal,
      isSortedAscending: !isSortedDescendingAmountTotal,
      onColumnClick: () => {
        console.log('Sort document amountTotal!');
        setPrimarySort('amountTotal');
        setIsSortedDescendingAmountTotal(!isSortedDescendingAmountTotal);

        handleSort('amountTotal', !isSortedDescendingAmountTotal);

        sessionStorage.setItem('lineShopping-primarySort', 'amountTotal');
        sessionStorage.setItem('lineShopping-sort-amountTotal', !isSortedDescendingAmountTotal === false ? 'false' : 'true');

        sessionStorage.removeItem('lineShopping-sort-number');
        sessionStorage.removeItem('lineShopping-sort-customerName');
        sessionStorage.removeItem('lineShopping-sort-createdTime');

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <TooltipHost 
            content={
              item.amountTotal.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            }
          >
            <span style={{ display: 'block', textAlign: 'right' }}>
              {item.amountTotal.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </span>

          </TooltipHost>
        );
      },
      // isPadded: true,
    },
    {
      key: 'column8',
      name: t('lineShopping.paymentMethod'),
      fieldName: 'paymentMethod',
      minWidth: 50,
      maxWidth: 130,
      isResizable: true,
      isSorted: primarySort === 'paymentMethod' ? true : false,
      isSortedDescending: isSortedDescendingPaymentMethod,
      isSortedAscending: !isSortedDescendingPaymentMethod,
      onColumnClick: () => {
        console.log('Sort document paymentMethod!');
        setPrimarySort('paymentMethod');
        setIsSortedDescendingPaymentMethod(!isSortedDescendingPaymentMethod);

        handleSort('paymentMethod', !isSortedDescendingPaymentMethod);

        sessionStorage.setItem('lineShopping-primarySort', 'paymentMethod');
        sessionStorage.setItem('lineShopping-sort-paymentMethod', !isSortedDescendingPaymentMethod === false ? 'false' : 'true');

        sessionStorage.removeItem('lineShopping-sort-customerName');
        sessionStorage.removeItem('lineShopping-sort-number');
        sessionStorage.removeItem('lineShopping-sort-vatTotal');
        sessionStorage.removeItem('lineShopping-sort-createdTime');

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <TooltipHost content={item.paymentMethod}>{item.paymentMethod}</TooltipHost>
      },
      // isPadded: true,
    },
    {
      key: 'column9',
      name: t('lineShopping.paymentStatus'),
      fieldName: 'paymentStatus',
      minWidth: 50,
      maxWidth: 130,
      isResizable: true,
      isSorted: primarySort === 'paymentStatus' ? true : false,
      isSortedDescending: isSortedDescendingPaymentStatus,
      isSortedAscending: !isSortedDescendingPaymentStatus,
      onColumnClick: () => {
        console.log('Sort document paymentStatus!');
        setPrimarySort('paymentStatus');
        setIsSortedDescendingPaymentStatus(!isSortedDescendingPaymentStatus);

        handleSort('paymentStatus', !isSortedDescendingPaymentStatus);

        sessionStorage.setItem('lineShopping-primarySort', 'paymentStatus');
        sessionStorage.setItem('lineShopping-sort-paymentStatus', !isSortedDescendingPaymentStatus === false ? 'false' : 'true');

        sessionStorage.removeItem('lineShopping-sort-customerName');
        sessionStorage.removeItem('lineShopping-sort-number');
        sessionStorage.removeItem('lineShopping-sort-vatTotal');
        sessionStorage.removeItem('lineShopping-sort-createdTime');

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <TooltipHost content={getPaymentStatusText(item.paymentStatusValue)}>{getPaymentStatusText(item.paymentStatusValue)}</TooltipHost>
      },
      // isPadded: true,
    },
    {
      key: 'column10',
      name: t('lineShopping.shipmentStatus'),
      fieldName: 'shipmentStatus',
      minWidth: 80,
      maxWidth: 130,
      isResizable: true,
      isSorted: primarySort === 'shipmentStatus' ? true : false,
      isSortedDescending: isSortedDescendingShipmentStatus,
      isSortedAscending: !isSortedDescendingShipmentStatus,
      onColumnClick: () => {
        console.log('Sort document shipmentStatus!');
        setPrimarySort('shipmentStatus');
        setIsSortedDescendingShipmentStatus(!isSortedDescendingShipmentStatus);

        handleSort('shipmentStatus', !isSortedDescendingShipmentStatus);

        sessionStorage.setItem('lineShopping-primarySort', 'shipmentStatus');
        sessionStorage.setItem('lineShopping-sort-shipmentStatus', !isSortedDescendingShipmentStatus === false ? 'false' : 'true');

        sessionStorage.removeItem('lineShopping-sort-customerName');
        sessionStorage.removeItem('lineShopping-sort-number');
        sessionStorage.removeItem('lineShopping-sort-vatTotal');
        sessionStorage.removeItem('lineShopping-sort-createdTime');

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <TooltipHost content={getShipmentStatusText(item.shipmentStatusValue)}>{getShipmentStatusText(item.shipmentStatusValue)}</TooltipHost>
      },
      // isPadded: true,
    },
    {
      key: 'column11',
      name: 'Leceipt',
      fieldName: 'etaxCreated',
      minWidth: 50,
      maxWidth: 50,
      isSorted: primarySort === 'etaxCreated' ? true : false,
      isSortedDescending: isSortedDescendingEtaxCreated,
      isSortedAscending: !isSortedDescendingEtaxCreated,
      onColumnClick: () => {
        console.log('Sort document etaxCreated!');
        setPrimarySort('etaxCreated');
        setIsSortedDescendingEtaxCreated(!isSortedDescendingEtaxCreated);

        handleSort('etaxCreated', !isSortedDescendingEtaxCreated);

        sessionStorage.setItem('lineShopping-primarySort', 'etaxCreated');
        sessionStorage.setItem('lineShopping-sort-etaxCreated', !isSortedDescendingEtaxCreated === false ? 'false' : 'true');

        sessionStorage.removeItem('lineShopping-sort-customerName');
        sessionStorage.removeItem('lineShopping-sort-number');
        sessionStorage.removeItem('lineShopping-sort-vatTotal');
        sessionStorage.removeItem('lineShopping-sort-createdTime');

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        if (item.etaxCreated) {
          return (
            <div style={{ textAlign: 'center' }}>
              <Icon iconName="SkypeCircleCheck" style={{ fontSize: '20px', color: 'green' }} />
            </div>
          );
        }
      },
    },
    {
      key: 'column12',
      name: '',
      fieldName: 'command',
      minWidth: 120,
      maxWidth: 120,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        if (isCreateDocument || isCreateDocumentComplete) {
          if (item.status === 'pending') {
            return <span style={{ color: '#106EBE' }}>{t('lineShopping.pending')}</span>;
          } else if (item.status === 'processing') {
            return <Spinner label={t('lineShopping.processing')} labelPosition="right" style={{ marginLeft: '-35px' }} />;
          } else if (item.status === 'successful') {
            return <span style={{ color: 'green' }}>{t('lineShopping.success')}</span>;
          } else if (item.status === 'fail') {
            return <span style={{ color: 'red' }}>{t('lineShopping.fail')}</span>;
          }
        } else {
          return (
            <span data-selection-disabled={true}>
              <DefaultButton
                text={t('lineShopping.create')}
                iconProps={{ iconName: 'Add', style: { color: '#106ebe' } }}
                menuProps={{
                  items: [
                    {
                      key: 'RECEIPT-TAXINVOICE',
                      text: t('lineShopping.receipt-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('RECEIPT-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'RECEIPT-TAXINVOICE-ABB',
                      text: t('lineShopping.receipt-taxinvoice-abb'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('RECEIPT-TAXINVOICE-ABB', item);
                      },
                    },
                    {
                      key: 'RECEIPT',
                      text: t('lineShopping.receipt'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('RECEIPT', item);
                      },
                    },
                    {
                      key: 'INVOICE-TAXINVOICE',
                      text: t('lineShopping.invoice-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('INVOICE-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'DELIVERYORDER-TAXINVOICE',
                      text: t('lineShopping.deliveryorder-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('DELIVERYORDER-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'TAXINVOICE',
                      text: t('lineShopping.taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('TAXINVOICE', item);
                      },
                    },
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
            </span>
          );
        }
      },
    },
  ];

  const getMonth = (goBack) => {
    let monthNames = [
      t('lineShopping.months01'),
      t('lineShopping.months02'),
      t('lineShopping.months03'),
      t('lineShopping.months04'),
      t('lineShopping.months05'),
      t('lineShopping.months06'),
      t('lineShopping.months07'),
      t('lineShopping.months08'),
      t('lineShopping.months09'),
      t('lineShopping.months10'),
      t('lineShopping.months11'),
      t('lineShopping.months12'),
    ];

    let d = new Date();

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + ' ' + year,
    };
  };

  const [queryMonth, setQueryMonth] = useState(
    (sessionStorage.getItem('lineShopping-queryMonth-key') && {
      key: sessionStorage.getItem('lineShopping-queryMonth-key'),
      text: sessionStorage.getItem('lineShopping-queryMonth-text'),
      ...(sessionStorage.getItem('lineShopping-queryMonth-type') ? { type: sessionStorage.getItem('lineShopping-queryMonth-type') } : {}),
    }) || { key: getMonth(0).year + '' + getMonth(0).key, text: t('lineShopping.thisMonth') }
  );

  const [timeRangeDate, setTimeRangeDate] = useState(sessionStorage.getItem('lineShopping-queryMonth-type') ? true : false);
  const [fromDate, setFromDate] = useState(
    sessionStorage.getItem('lineShopping-fromDate') ? new Date(sessionStorage.getItem('lineShopping-fromDate')) : new Date()
  );
  const [toDate, setToDate] = useState(
    sessionStorage.getItem('lineShopping-toDate') ? new Date(sessionStorage.getItem('lineShopping-toDate')) : new Date()
  );
  const [timeRangeError, setTimeRangeError] = useState(false);

  const getFrom = (month, year) => {
    let _firstDay = new Date(year, month, 1);

    return formatDate(_firstDay);
  };

  const getTo = (month, year) => {
    let _lastDay = new Date(year, month + 1, 1);

    return formatDate(_lastDay);
  };

  const getFrom4 = (day, month, year) => {
    let _firstDay = new Date(year, month, day);

    return formatDate(_firstDay);
  };

  const getTo4 = (day, month, year) => {
    let _lastDay = new Date(year, month, day + 1);

    return formatDate(_lastDay);
  };

  const getFrom2 = (day, month, year) => {
    let _firstDay = new Date(year, month, day)

    return _firstDay.toISOString();
  };

  const getTo2 = (day, month, year) => {
    let _lastDay = new Date(year, month, day + 1);

    return _lastDay.toISOString();
  };

  const getFrom3 = (month, year) => {
    let _firstDay = new Date(year, month, 1);

    return _firstDay.toISOString();
  };

  const getTo3 = (month, year) => {
    let _lastDay = new Date(year, month + 1, 1);

    return _lastDay.toISOString();
  };

  const formatDate = (d) => {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const [date, setDate] = useState(sessionStorage.getItem('lineShopping-date') ? new Date(sessionStorage.getItem('lineShopping-date')) : new Date());

  const [from, setFrom] = useState(sessionStorage.getItem('lineShopping-from') || getFrom(getMonth(0).month, getMonth(0).year));
  const [to, setTo] = useState(sessionStorage.getItem('lineShopping-to') || getTo(getMonth(0).month, getMonth(0).year));

  const [from2, setFrom2] = useState(sessionStorage.getItem('lineShopping-from2') || getFrom3(getMonth(0).month, getMonth(0).year));
  const [to2, setTo2] = useState(sessionStorage.getItem('lineShopping-to2') || getTo3(getMonth(0).month, getMonth(0).year));

  const [pageDropdown, setPageDropdown] = useState([]);

  const history = useHistory();

  function getDocumentTypeText(key) {
    if (key === 'all') return t('lineShopping.allType');
    if (key === 'FINALIZED') return t('lineShopping.finalized');
    if (key === 'COMPLETED') return t('lineShopping.completed');
    if (key === 'EXPIRED') return t('lineShopping.expired');
    if (key === 'CANCELED') return t('lineShopping.canceled');

    if (key === 'allPaymentMethodType') return t('lineShopping.allPaymentMethodType');
    if (key === 'BANK') return t('lineShopping.bank');
    if (key === 'COD') return t('lineShopping.cod');
    if (key === 'PAYMENT_LS_RLP') return t('lineShopping.creditAndDebitCard');
    if (key === 'PAYMENT_LS_MOBILE_BANKING_KPLUS') return t('lineShopping.kPlus');
    if (key === 'PAYMENT_LS_MOBILE_BANKING_SCB') return t('lineShopping.scb');
  }

  function getPaymentStatusText(key) {
    if (key === 'allPaymentStatus') return t('lineShopping.allPaymentStatus');
    if (key === 'PAID') return t('lineShopping.paidPaymentStatus');
    if (key === 'PENDING') return t('lineShopping.pendingPaymentStatus');
    if (key === 'NO_PAYMENT') return t('lineShopping.noPaymentPaymentStatus');
    if (key === 'REFUND') return t('lineShopping.refundPaymentStatus');
    if (key === 'PENDING_REFUND') return t('lineShopping.pendingRefundPaymentStatus');
    if (key === 'FAILED_REFUND') return t('lineShopping.failedRefundPaymentStatus');
    if (key === 'FAILED_AFTER_PAID') return t('lineShopping.failedAfterPaidPaymentStatus');
    if (key === 'REJECTED_REFUND') return t('lineShopping.rejectedRefundPaymentStatus');
  }

  function getShipmentStatusText(key) {
    if (key === 'allShipmentStatus') return t('lineShopping.allShipmentStatus');
    if (key === 'SHIPPED_ALL') return t('lineShopping.shippedAllShipmentStatus');
    if (key === 'ON_DELIVERY') return t('lineShopping.onDeliveryShipmentStatus');
    if (key === 'PENDING') return t('lineShopping.pendingShipmentStatus');
    if (key === 'SHIPMENT_READY') return t('lineShopping.shipmentReadyShipmentStatus');
    if (key === 'NO_SHIPMENT') return t('lineShopping.noShipmentShipmentStatus');
  }

  function getDateText(monthObject) {
    if (monthObject.type === 'dateRange') {
      if (i18n.language === 'th') {
        return onFormatDate(fromDate, 'BE') + ' - ' + onFormatDate(toDate, 'BE');
      }
      return onFormatDate(fromDate, 'CE') + ' - ' + onFormatDate(toDate, 'CE');
    }

    if (monthObject.text === 'เดือนนี้' || monthObject.text === 'This Month') return t('lineShopping.thisMonth');

    let monthNames = [
      t('lineShopping.months01'),
      t('lineShopping.months02'),
      t('lineShopping.months03'),
      t('lineShopping.months04'),
      t('lineShopping.months05'),
      t('lineShopping.months06'),
      t('lineShopping.months07'),
      t('lineShopping.months08'),
      t('lineShopping.months09'),
      t('lineShopping.months10'),
      t('lineShopping.months11'),
      t('lineShopping.months12'),
    ];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    if (i18n.language === 'th') {
      _year = _year + 543;
    }

    return monthNames[_monthIndex] + ' ' + _year;
  }

  const commandBarItems = [
    {
      key: queryMonth.key,
      text: getDateText(queryMonth),
      iconProps: { iconName: 'Calendar' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: [
          {
            key: 'queryMonth',
          },
        ],
        onRenderMenuList: () => {
          return (
            <div style={{ width: 220 }}>
              <Stack styles={{ root: { padding: '12px 12px 0px 12px' } }}>
                <Checkbox
                  label={t('sellSummary.timeRange')}
                  checked={timeRangeDate}
                  onChange={(e) => {
                    setTimeRangeDate((prev) => !prev);
                  }}
                />
              </Stack>
              {timeRangeDate ? (
                <Stack>
                  <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                    <Stack styles={{ root: { width: '20%', justifyContent: 'center', alignItems: 'center' } }}>
                      <Text>{t('sellSummary.fromRange')}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '80%', justifyContent: 'center' /* alignItems: 'center' */ } }}>
                      <DatePicker
                        styles={{ root: { width: '90%' } }}
                        placeholder={t('deliveryOrderTaxInvoice.pleaseSelect')}
                        strings={DayPickerStrings}
                        value={fromDate}
                        maxDate={toDate}
                        formatDate={onFormatDate}
                        onSelectDate={(_date) => {
                          setFromDate(_date);

                          setTimeRangeError(false);
                          if (Math.ceil(Math.abs(_date - toDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                        }}
                      />
                    </Stack>
                  </Stack>
                  <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                    <Stack styles={{ root: { width: '20%', justifyContent: 'center', alignItems: 'center' } }}>
                      <Text>{t('sellSummary.toRange')}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '80%' /* alignItems: 'center' */ } }}>
                      <DatePicker
                        styles={{ root: { width: '90%' } }}
                        placeholder={t('deliveryOrderTaxInvoice.pleaseSelect')}
                        strings={DayPickerStrings}
                        value={toDate}
                        minDate={fromDate}
                        formatDate={onFormatDate}
                        onSelectDate={(_date) => {
                          setToDate(_date);

                          setTimeRangeError(false);
                          if (Math.ceil(Math.abs(_date - fromDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                        }}
                      />
                    </Stack>
                  </Stack>
                  {timeRangeError ? (
                    <Stack styles={{ root: { padding: '12px 0px 0px 12px' } }}>
                      <Text styles={{ root: { fontSize: 12, color: '#A4262C' } }}>{t('sellSummary.timeRangeError')}</Text>
                    </Stack>
                  ) : null}
                  <Stack horizontal horizontalAlign="end" styles={{ root: { width: '92%', height: 52, padding: '10px 0px 10px 0px' } }}>
                    <PrimaryButton
                      text={t('sellSummary.search')}
                      onClick={() => {
                        if (!timeRangeError && fromDate && toDate) {
                          setQueryMonth({
                            type: 'dateRange',
                            key: formatDate(fromDate) + ':' + formatDate(toDate),
                            text: formatDate(fromDate) + ' - ' + formatDate(toDate),
                          });

                          sessionStorage.setItem('lineShopping-queryMonth-type', 'dateRange');
                          sessionStorage.setItem('lineShopping-queryMonth-key', formatDate(fromDate) + ':' + formatDate(toDate));
                          sessionStorage.setItem('lineShopping-queryMonth-text', formatDate(fromDate) + ' - ' + formatDate(toDate));

                          setFrom(getFrom4(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                          setTo(getTo4(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                          sessionStorage.setItem('lineShopping-from', getFrom4(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                          sessionStorage.setItem('lineShopping-to', getTo4(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                          setFrom2(getFrom2(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                          setTo2(getTo2(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                          sessionStorage.setItem('lineShopping-from2', getFrom2(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                          sessionStorage.setItem('lineShopping-to2', getTo2(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                          sessionStorage.setItem('lineShopping-fromDate', fromDate.toISOString());
                          sessionStorage.setItem('lineShopping-toDate', toDate.toISOString());

                          setSearch('');

                          setLoadDataComplete(false);
                          setItems([]);
                          setGetNewDocuments(true);

                          setTotalDocuments(0);

                          setCurrentPage(1);
                        }
                      }}
                      style={{ width: '125px', letterSpacing: '1px' }}
                    />
                  </Stack>
                </Stack>
              ) : (
                <Calendar
                  onSelectDate={(_date) => {
                    let monthNames = [
                      t('lineShopping.months01'),
                      t('lineShopping.months02'),
                      t('lineShopping.months03'),
                      t('lineShopping.months04'),
                      t('lineShopping.months05'),
                      t('lineShopping.months06'),
                      t('lineShopping.months07'),
                      t('lineShopping.months08'),
                      t('lineShopping.months09'),
                      t('lineShopping.months10'),
                      t('lineShopping.months11'),
                      t('lineShopping.months12'),
                    ];

                    setDate(_date);

                    sessionStorage.setItem('lineShopping-date', _date.toISOString());

                    let adjustDate = _date;

                    let year = adjustDate.getFullYear() + 543;

                    setQueryMonth({
                      key: adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'),
                      text: monthNames[adjustDate.getMonth()] + ' ' + year,
                    });

                    sessionStorage.setItem('lineShopping-queryMonth-type', '');
                    sessionStorage.setItem(
                      'lineShopping-queryMonth-key',
                      adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0')
                    );
                    sessionStorage.setItem('lineShopping-queryMonth-text', monthNames[adjustDate.getMonth()] + ' ' + year);

                    setFrom(getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                    setTo(getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                    sessionStorage.setItem('lineShopping-from', getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                    sessionStorage.setItem('lineShopping-to', getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                    setFrom2(getFrom3(adjustDate.getMonth(), adjustDate.getFullYear()));
                    setTo2(getTo3(adjustDate.getMonth(), adjustDate.getFullYear()));

                    sessionStorage.setItem('lineShopping-from2', getFrom3(adjustDate.getMonth(), adjustDate.getFullYear()));
                    sessionStorage.setItem('lineShopping-to2', getTo3(adjustDate.getMonth(), adjustDate.getFullYear()));

                    setSearch('');

                    setLoadDataComplete(false);
                    setItems([]);
                    setGetNewDocuments(true);

                    setTotalDocuments(0);

                    setCurrentPage(1);
                  }}
                  isMonthPickerVisible={true}
                  highlightSelectedMonth={true}
                  isDayPickerVisible={false}
                  dateRangeType={DateRangeType.Day}
                  autoNavigateOnSelection={false}
                  showGoToToday={false}
                  value={date}
                  strings={DayPickerStrings}
                  showSixWeeksByDefault={true}
                />
              )}
            </div>
          );
        },
      },
    },
    {
      key: orderType.key,
      text: getDocumentTypeText(orderType.key),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'ProductList' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: [
          {
            key: 'all',
            text: t('lineShopping.allType'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('ออร์เดอร์ทั้งหมด');
              setOrderType({
                key: 'all',
                text: t('lineShopping.allType'),
              });

              sessionStorage.setItem('lineShopping-orderType-key', 'all');
              sessionStorage.setItem('lineShopping-orderType-text', t('lineShopping.allType'));

              setLoadDataComplete(false);
              setItems([]);

              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);

              setSearch('');

              setIsCreateDocumentComplete(false);
              setSelectedItems([]);
            },
          },
          {
            key: 'COMPLETED',
            text: t('lineShopping.completed'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('อยู่ระหว่างการจัดส่ง');
              setOrderType({
                key: 'COMPLETED',
                text: t('lineShopping.completed'),
              });

              sessionStorage.setItem('lineShopping-orderType-key', 'COMPLETED');
              sessionStorage.setItem('lineShopping-orderType-text', t('lineShopping.completed'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);

              setSearch('');

              setIsCreateDocumentComplete(false);
              setSelectedItems([]);
            },
          },
          {
            key: 'FINALIZED',
            text: t('lineShopping.finalized'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('จัดส่งสำเร็จ');
              setOrderType({
                key: 'FINALIZED',
                text: t('lineShopping.finalized'),
              });

              sessionStorage.setItem('lineShopping-orderType-key', 'FINALIZED');
              sessionStorage.setItem('lineShopping-orderType-text', t('lineShopping.finalized'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);

              setSearch('');

              setIsCreateDocumentComplete(false);
              setSelectedItems([]);
            },
          },
          {
            key: 'EXPIRED',
            text: t('lineShopping.expired'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('พร้อมส่ง');
              setOrderType({
                key: 'EXPIRED',
                text: t('lineShopping.expired'),
              });

              sessionStorage.setItem('lineShopping-orderType-key', 'EXPIRED');
              sessionStorage.setItem('lineShopping-orderType-text', t('lineShopping.expired'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);

              setSearch('');

              setIsCreateDocumentComplete(false);
              setSelectedItems([]);
            },
          },
          {
            key: 'CANCELED',
            text: t('lineShopping.canceled'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('รอดำเนินการ');
              setOrderType({
                key: 'CANCELED',
                text: t('lineShopping.canceled'),
              });

              sessionStorage.setItem('lineShopping-orderType-key', 'CANCELED');
              sessionStorage.setItem('lineShopping-orderType-text', t('lineShopping.canceled'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);

              setSearch('');

              setIsCreateDocumentComplete(false);
              setSelectedItems([]);
            },
          },
        ],
      },
    },
    {
      key: paymentStatus.key,
      text: getPaymentStatusText(paymentStatus.key),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'AllCurrency' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: [
          {
            key: 'allPaymentStatus',
            text: t('lineShopping.allPaymentStatus'),
            iconProps: { iconName: 'AllCurrency' },
            onClick: () => {
              console.log('allPaymentStatus');
              setPaymentStatus({
                key: 'allPaymentStatus',
                text: t('lineShopping.allPaymentStatus'),
              });

              sessionStorage.setItem('lineShopping-paymentStatus-key', 'allPaymentStatus');
              sessionStorage.setItem('lineShopping-paymentStatus-text', t('lineShopping.allPaymentStatus'));

              setLoadDataComplete(false);
              setItems([]);

              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);

              setSearch('');

              setIsCreateDocumentComplete(false);
              setSelectedItems([]);
            },
          },
          {
            key: 'PAID',
            text: t('lineShopping.paidPaymentStatus'),
            iconProps: { iconName: 'AllCurrency' },
            onClick: () => {
              console.log('paidPaymentStatus');
              setPaymentStatus({
                key: 'PAID',
                text: t('lineShopping.paidPaymentStatus'),
              });

              sessionStorage.setItem('lineShopping-paymentStatus-key', 'PAID');
              sessionStorage.setItem('lineShopping-paymentStatus-text', t('lineShopping.paidPaymentStatus'));

              setLoadDataComplete(false);
              setItems([]);

              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);

              setSearch('');

              setIsCreateDocumentComplete(false);
              setSelectedItems([]);
            },
          },
          {
            key: 'PENDING',
            text: t('lineShopping.pendingPaymentStatus'),
            iconProps: { iconName: 'AllCurrency' },
            onClick: () => {
              console.log('pendingPaymentStatus');
              setPaymentStatus({
                key: 'PENDING',
                text: t('lineShopping.pendingPaymentStatus'),
              });

              sessionStorage.setItem('lineShopping-paymentStatus-key', 'PENDING');
              sessionStorage.setItem('lineShopping-paymentStatus-text', t('lineShopping.pendingPaymentStatus'));

              setLoadDataComplete(false);
              setItems([]);

              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);

              setSearch('');

              setIsCreateDocumentComplete(false);
              setSelectedItems([]);
            },
          },
          {
            key: 'NO_PAYMENT',
            text: t('lineShopping.noPaymentPaymentStatus'),
            iconProps: { iconName: 'AllCurrency' },
            onClick: () => {
              console.log('noPaymentPaymentStatus');
              setPaymentStatus({
                key: 'NO_PAYMENT',
                text: t('lineShopping.noPaymentPaymentStatus'),
              });

              sessionStorage.setItem('lineShopping-paymentStatus-key', 'NO_PAYMENT');
              sessionStorage.setItem('lineShopping-paymentStatus-text', t('lineShopping.noPaymentPaymentStatus'));

              setLoadDataComplete(false);
              setItems([]);

              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);

              setSearch('');

              setIsCreateDocumentComplete(false);
              setSelectedItems([]);
            },
          },
          {
            key: 'REFUND',
            text: t('lineShopping.refundPaymentStatus'),
            iconProps: { iconName: 'AllCurrency' },
            onClick: () => {
              console.log('refundPaymentStatus');
              setPaymentStatus({
                key: 'REFUND',
                text: t('lineShopping.refundPaymentStatus'),
              });

              sessionStorage.setItem('lineShopping-paymentStatus-key', 'REFUND');
              sessionStorage.setItem('lineShopping-paymentStatus-text', t('lineShopping.refundPaymentStatus'));

              setLoadDataComplete(false);
              setItems([]);

              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);

              setSearch('');

              setIsCreateDocumentComplete(false);
              setSelectedItems([]);
            },
          },
          {
            key: 'PENDING_REFUND',
            text: t('lineShopping.pendingRefundPaymentStatus'),
            iconProps: { iconName: 'AllCurrency' },
            onClick: () => {
              console.log('pendingRefundPaymentStatus');
              setPaymentStatus({
                key: 'PENDING_REFUND',
                text: t('lineShopping.pendingRefundPaymentStatus'),
              });

              sessionStorage.setItem('lineShopping-paymentStatus-key', 'PENDING_REFUND');
              sessionStorage.setItem('lineShopping-paymentStatus-text', t('lineShopping.pendingRefundPaymentStatus'));

              setLoadDataComplete(false);
              setItems([]);

              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);

              setSearch('');

              setIsCreateDocumentComplete(false);
              setSelectedItems([]);
            },
          },
          {
            key: 'FAILED_REFUND',
            text: t('lineShopping.failedRefundPaymentStatus'),
            iconProps: { iconName: 'AllCurrency' },
            onClick: () => {
              console.log('failedRefundPaymentStatus');
              setPaymentStatus({
                key: 'FAILED_REFUND',
                text: t('lineShopping.failedRefundPaymentStatus'),
              });

              sessionStorage.setItem('lineShopping-paymentStatus-key', 'FAILED_REFUND');
              sessionStorage.setItem('lineShopping-paymentStatus-text', t('lineShopping.failedRefundPaymentStatus'));

              setLoadDataComplete(false);
              setItems([]);

              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);

              setSearch('');

              setIsCreateDocumentComplete(false);
              setSelectedItems([]);
            },
          },
          {
            key: 'FAILED_AFTER_PAID',
            text: t('lineShopping.failedAfterPaidPaymentStatus'),
            iconProps: { iconName: 'AllCurrency' },
            onClick: () => {
              console.log('failedAfterPaidPaymentStatus');
              setPaymentStatus({
                key: 'FAILED_AFTER_PAID',
                text: t('lineShopping.failedAfterPaidPaymentStatus'),
              });

              sessionStorage.setItem('lineShopping-paymentStatus-key', 'FAILED_AFTER_PAID');
              sessionStorage.setItem('lineShopping-paymentStatus-text', t('lineShopping.failedAfterPaidPaymentStatus'));

              setLoadDataComplete(false);
              setItems([]);

              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);

              setSearch('');

              setIsCreateDocumentComplete(false);
              setSelectedItems([]);
            },
          },
          {
            key: 'REJECTED_REFUND',
            text: t('lineShopping.rejectedRefundPaymentStatus'),
            iconProps: { iconName: 'AllCurrency' },
            onClick: () => {
              console.log('rejectedRefundPaymentStatus');
              setPaymentStatus({
                key: 'REJECTED_REFUND',
                text: t('lineShopping.rejectedRefundPaymentStatus'),
              });

              sessionStorage.setItem('lineShopping-paymentStatus-key', 'REJECTED_REFUND');
              sessionStorage.setItem('lineShopping-paymentStatus-text', t('lineShopping.rejectedRefundPaymentStatus'));

              setLoadDataComplete(false);
              setItems([]);

              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);

              setSearch('');

              setIsCreateDocumentComplete(false);
              setSelectedItems([]);
            },
          }
        ],
      },
    },
    {
      key: paymentMethodType.key,
      text: getDocumentTypeText(paymentMethodType.key),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'PaymentCard' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: [
          {
            key: 'allPaymentMethodType',
            text: t('lineShopping.allPaymentMethodType'),
            iconProps: { iconName: 'PaymentCard' },
            onClick: () => {
              console.log('allPaymentMethodType');
              setPaymentMethodType({
                key: 'allPaymentMethodType',
                text: t('lineShopping.allPaymentMethodType'),
              });

              sessionStorage.setItem('lineShopping-paymentMethodType-key', 'allPaymentMethodType');
              sessionStorage.setItem('lineShopping-paymentMethodType-text', t('lineShopping.allPaymentMethodType'));

              setLoadDataComplete(false);
              setItems([]);

              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);

              setSearch('');

              setIsCreateDocumentComplete(false);
              setSelectedItems([]);
            },
          },
          {
            key: 'BANK',
            text: t('lineShopping.bank'),
            iconProps: { iconName: 'PaymentCard' },
            onClick: () => {
              console.log('bank');
              setPaymentMethodType({
                key: 'BANK',
                text: t('lineShopping.bank'),
              });

              sessionStorage.setItem('lineShopping-paymentMethodType-key', 'BANK');
              sessionStorage.setItem('lineShopping-paymentMethodType-text', t('lineShopping.bank'));

              setLoadDataComplete(false);
              setItems([]);

              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);

              setSearch('');

              setIsCreateDocumentComplete(false);
              setSelectedItems([]);
            },
          },
          {
            key: 'COD',
            text: t('lineShopping.cod'),
            iconProps: { iconName: 'PaymentCard' },
            onClick: () => {
              console.log('cod');
              setPaymentMethodType({
                key: 'COD',
                text: t('lineShopping.cod'),
              });

              sessionStorage.setItem('lineShopping-paymentMethodType-key', 'COD');
              sessionStorage.setItem('lineShopping-paymentMethodType-text', t('lineShopping.cod'));

              setLoadDataComplete(false);
              setItems([]);

              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);

              setSearch('');

              setIsCreateDocumentComplete(false);
              setSelectedItems([]);
            },
          },
          {
            key: 'PAYMENT_LS_RLP',
            text: t('lineShopping.creditAndDebitCard'),
            iconProps: { iconName: 'PaymentCard' },
            onClick: () => {
              console.log('creditAndDebitCard');
              setPaymentMethodType({
                key: 'PAYMENT_LS_RLP',
                text: t('lineShopping.creditAndDebitCard'),
              });

              sessionStorage.setItem('lineShopping-paymentMethodType-key', 'PAYMENT_LS_RLP');
              sessionStorage.setItem('lineShopping-paymentMethodType-text', t('lineShopping.creditAndDebitCard'));

              setLoadDataComplete(false);
              setItems([]);

              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);

              setSearch('');

              setIsCreateDocumentComplete(false);
              setSelectedItems([]);
            },
          },
          {
            key: 'PAYMENT_LS_MOBILE_BANKING_KPLUS',
            text: t('lineShopping.kPlus'),
            iconProps: { iconName: 'PaymentCard' },
            onClick: () => {
              console.log('kPlus');
              setPaymentMethodType({
                key: 'PAYMENT_LS_MOBILE_BANKING_KPLUS',
                text: t('lineShopping.kPlus'),
              });

              sessionStorage.setItem('lineShopping-paymentMethodType-key', 'PAYMENT_LS_MOBILE_BANKING_KPLUS');
              sessionStorage.setItem('lineShopping-paymentMethodType-text', t('lineShopping.kPlus'));

              setLoadDataComplete(false);
              setItems([]);

              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);

              setSearch('');

              setIsCreateDocumentComplete(false);
              setSelectedItems([]);
            },
          },
          {
            key: 'PAYMENT_LS_MOBILE_BANKING_SCB',
            text: t('lineShopping.scb'),
            iconProps: { iconName: 'PaymentCard' },
            onClick: () => {
              console.log('scb');
              setPaymentMethodType({
                key: 'PAYMENT_LS_MOBILE_BANKING_SCB',
                text: t('lineShopping.scb'),
              });

              sessionStorage.setItem('lineShopping-paymentMethodType-key', 'PAYMENT_LS_MOBILE_BANKING_SCB');
              sessionStorage.setItem('lineShopping-paymentMethodType-text', t('lineShopping.scb'));

              setLoadDataComplete(false);
              setItems([]);

              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);

              setSearch('');

              setIsCreateDocumentComplete(false);
              setSelectedItems([]);
            },
          }
        ],
      },
    },
    {
      key: shipmentStatus.key,
      text: getShipmentStatusText(shipmentStatus.key),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'DeliveryTruck' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: [
          {
            key: 'allShipmentStatus',
            text: t('lineShopping.allShipmentStatus'),
            iconProps: { iconName: 'DeliveryTruck' },
            onClick: () => {
              console.log('allShipmentStatus');
              setShipmentStatus({
                key: 'allShipmentStatus',
                text: t('lineShopping.allShipmentStatus'),
              });

              sessionStorage.setItem('lineShopping-shipmentStatus-key', 'allShipmentStatus');
              sessionStorage.setItem('lineShopping-shipmentStatus-text', t('lineShopping.allShipmentStatus'));

              setLoadDataComplete(false);
              setItems([]);

              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);

              setSearch('');

              setIsCreateDocumentComplete(false);
              setSelectedItems([]);
            },
          },
          {
            key: 'SHIPPED_ALL',
            text: t('lineShopping.shippedAllShipmentStatus'),
            iconProps: { iconName: 'DeliveryTruck' },
            onClick: () => {
              console.log('shippedAllShipmentStatus');
              setShipmentStatus({
                key: 'SHIPPED_ALL',
                text: t('lineShopping.shippedAllShipmentStatus'),
              });

              sessionStorage.setItem('lineShopping-shipmentStatus-key', 'SHIPPED_ALL');
              sessionStorage.setItem('lineShopping-shipmentStatus-text', t('lineShopping.shippedAllShipmentStatus'));

              setLoadDataComplete(false);
              setItems([]);

              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);

              setSearch('');

              setIsCreateDocumentComplete(false);
              setSelectedItems([]);
            },
          },
          {
            key: 'ON_DELIVERY',
            text: t('lineShopping.onDeliveryShipmentStatus'),
            iconProps: { iconName: 'DeliveryTruck' },
            onClick: () => {
              console.log('onDeliveryShipmentStatus');
              setShipmentStatus({
                key: 'ON_DELIVERY',
                text: t('lineShopping.onDeliveryShipmentStatus'),
              });

              sessionStorage.setItem('lineShopping-shipmentStatus-key', 'ON_DELIVERY');
              sessionStorage.setItem('lineShopping-shipmentStatus-text', t('lineShopping.onDeliveryShipmentStatus'));

              setLoadDataComplete(false);
              setItems([]);

              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);

              setSearch('');

              setIsCreateDocumentComplete(false);
              setSelectedItems([]);
            },
          },
          {
            key: 'PENDING',
            text: t('lineShopping.pendingShipmentStatus'),
            iconProps: { iconName: 'DeliveryTruck' },
            onClick: () => {
              console.log('pendingShipmentStatus');
              setShipmentStatus({
                key: 'PENDING',
                text: t('lineShopping.pendingShipmentStatus'),
              });

              sessionStorage.setItem('lineShopping-shipmentStatus-key', 'PENDING');
              sessionStorage.setItem('lineShopping-shipmentStatus-text', t('lineShopping.pendingShipmentStatus'));

              setLoadDataComplete(false);
              setItems([]);

              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);

              setSearch('');

              setIsCreateDocumentComplete(false);
              setSelectedItems([]);
            },
          },
          {
            key: 'SHIPMENT_READY',
            text: t('lineShopping.shipmentReadyShipmentStatus'),
            iconProps: { iconName: 'DeliveryTruck' },
            onClick: () => {
              console.log('shipmentReadyShipmentStatus');
              setShipmentStatus({
                key: 'SHIPMENT_READY',
                text: t('lineShopping.shipmentReadyShipmentStatus'),
              });

              sessionStorage.setItem('lineShopping-shipmentStatus-key', 'SHIPMENT_READY');
              sessionStorage.setItem('lineShopping-shipmentStatus-text', t('lineShopping.shipmentReadyShipmentStatus'));

              setLoadDataComplete(false);
              setItems([]);

              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);

              setSearch('');

              setIsCreateDocumentComplete(false);
              setSelectedItems([]);
            },
          },
          {
            key: 'NO_SHIPMENT',
            text: t('lineShopping.noShipmentShipmentStatus'),
            iconProps: { iconName: 'DeliveryTruck' },
            onClick: () => {
              console.log('noShipmentShipmentStatus');
              setShipmentStatus({
                key: 'NO_SHIPMENT',
                text: t('lineShopping.noShipmentShipmentStatus'),
              });

              sessionStorage.setItem('lineShopping-shipmentStatus-key', 'NO_SHIPMENT');
              sessionStorage.setItem('lineShopping-shipmentStatus-text', t('lineShopping.noShipmentShipmentStatus'));

              setLoadDataComplete(false);
              setItems([]);

              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);

              setSearch('');

              setIsCreateDocumentComplete(false);
              setSelectedItems([]);
            },
          },
        ],
      },
    },
    {
      key: 'pageSize ' + pageSize,
      text: pageSize + ' ' + t('lineShopping.documentPerPage'),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'NumberedList' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: [
          {
            key: 'pageSize ' + 10,
            text: '10 ' + t('lineShopping.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('10 ออเดอร์/หน้า');
              setPageSize(10);
              sessionStorage.setItem('lineShopping-pageSize', 10);

              setSearch('');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);
            },
          },
          {
            key: 'pageSize ' + 20,
            text: '20 ' + t('lineShopping.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('20 ออเดอร์/หน้า');
              setPageSize(20);
              sessionStorage.setItem('lineShopping-pageSize', 20);

              setSearch('');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);
            },
          },
          {
            key: 'pageSize ' + 30,
            text: '30 ' + t('lineShopping.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('30 ออเดอร์/หน้า');
              setPageSize(30);
              sessionStorage.setItem('lineShopping-pageSize', 30);

              setSearch('');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);
            },
          },
          {
            key: 'pageSize ' + 40,
            text: '40 ' + t('lineShopping.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('40 ออเดอร์/หน้า');
              setPageSize(40);
              sessionStorage.setItem('lineShopping-pageSize', 40);

              setSearch('');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);
            },
          },
          {
            key: 'pageSize ' + 50,
            text: '50 ' + t('lineShopping.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('50 ออเดอร์/หน้า');
              setPageSize(50);
              sessionStorage.setItem('lineShopping-pageSize', 50);

              setSearch('');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);
            },
          },
          {
            key: 'pageSize ' + 100,
            text: '100 ' + t('lineShopping.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('100 ออเดอร์/หน้า');
              setPageSize(100);
              sessionStorage.setItem('lineShopping-pageSize', 100);

              setSearch('');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);

              setCurrentPage(1);
            },
          },
        ],
      },
    },
    {
      key: currentPage,
      text: t('lineShopping.page') + ' ' + currentPage,
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: pageDropdown,
      },
    },
    {
      key: 'selectNotCreatedItem',
      text: t('lineShopping.selectNotCreatedItem'),
      iconProps: { iconName: 'MultiSelect' },
      disabled: !loadDataComplete || isCreateDocument,
      onClick: () => {
        console.log('selectNotCreatedItem');

        let _selectedItems = selection.getItems().filter(_item => !_item.etaxCreated)
        // console.log("selection", selection)
        // console.log("selection", selection.getItems())
        // console.log("_selectedItems", _selectedItems)
        setSelectedItems(_selectedItems);
        selection.getItems().forEach(_item => {
          if (!_item.etaxCreated) {
            selection.setKeySelected(_item.key, true)
          } else {
            selection.setKeySelected(_item.key, false)
          }
        })
      },
    },
    {
      key: 'refresh',
      text: t('lineShopping.refresh'),
      iconProps: { iconName: 'Refresh' },
      disabled: !loadDataComplete || isCreateDocument || search,
      onClick: () => {
        console.log('Refresh');

        setSearch('');

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);

        setCurrentPage(1);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
    },
  ];

  const overflowItems = [
    {
      key: 'disconnect',
      text: t('lineShopping.disconnect'),
      onClick: () => {
        console.log('Line Shopping disconnect!');
        setDialogConfirm((prev) => ({
          ...prev,
          isClick: true,
          header: t('lineShopping.disconnect'),
          message: t('lineShopping.disconnectConfirm'),
          onConfirm: () => {
            disconnect(true);
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            }));
          },
          onCancel: () =>
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            })),
        }));
      },
      iconProps: { iconName: 'PlugDisconnected' },
    },
  ];

  const padLeadingZeros = (num, size) => {
    var s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
  };

  const disconnect = (_confirm) => {
    let confirm = _confirm;

    if (confirm) {
      setIsLoading(true);

      instance.acquireTokenSilent({ ...silentRequest, account: account }).then(
        (tokenResponse) => {
          axios
            .patch(
              API_URL + '/sources',
              {
                source: 'line-shopping',
                replace: '/disabled',
                value: true,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  cid: companyId
                },
              }
            )
            .then((response) => {
              console.log('disconnect: ', response);

              history.push({
                pathname: '/etax/sources/line-shopping/connect',
                state: {},
              });

              setIsLoading(false);
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
              instance.acquireTokenRedirect(silentRequest);
            });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  useEffect(() => {
    console.log('userEffect initial call!');

    setIsLoading(true);

    if (localStorage.getItem('showShippingFee') === 'no') {
      setShowShippingFee(false);
    } else {
      setShowShippingFee(true);
    }

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              cid: companyId
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data[0]) {
                if (companyId && response.data.find(_company => _company.id === companyId)) {
                  const _company = response.data.find(_company => _company.id === companyId)
              
                  setCompany(_company);

                  if (_company.registerVat) {
                      setIncludeVat(true);
                      setRegisterVat(true);
                  } else {
                      setIncludeVat(false);
                      setRegisterVat(false);
                  }
              } else {
                  setCompany(response.data[0]);

                  if (response.data[0].registerVat) {
                      setIncludeVat(true);
                      setRegisterVat(true);
                  } else {
                      setIncludeVat(false);
                      setRegisterVat(false);
                  }

              }


                setGetNewDocuments(true);
              } else {
                history.push({
                  pathname: '/etax/companies/profiles',
                  state: {},
                });
              }

              setIsLoading(false);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getNewDocuments) {
      let _sortBy = 'UPDATED_AT';
      let _orderBy = 'ASC';
      if (isSortedDescendingUpdatedTime) {
        _orderBy = 'DESC';
      }
      
      if (primarySort === 'number') {
        _sortBy = 'ORDER_NO';
        if (isSortedDescendingNumber) {
          _orderBy = 'DESC';
        } else {
          _orderBy = 'ASC';
        }
      } else if (primarySort === 'createdTime') {
        _sortBy = 'CHECKED_OUT_AT';
        // if (isSortedDescendingNumber) {
        //   _orderBy = 'DESC';
        // } else {
        //   _orderBy = 'ASC';
        // }

        if (isSortedDescendingCreatedTime) {
          _orderBy = 'DESC';
        } else {
          _orderBy = 'ASC';
        }
      }

      

      if (search) {
        console.log('handleSearch Running!!!');

        // const decodeSearch = decodeURIComponent(search)

        instance
          .acquireTokenSilent({ ...silentRequest, account: account })
          .then(async (tokenResponse) => {
            const [response, responseAllDocs] = await Promise.all([
              axios
                // .get("http://localhost:7071/api/Line_searchOrders", {
                .get(API_URL + '/sources/line-shopping/orders', {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    cid: companyId
                  },
                  params: {
                    search: search,
                    // apiKey: 'MjkzZTA1MzAtZjYxOS00MWQxLWI4ODEtYzk2ZmI5MWZmNGYy',
                  },
                })
                .catch((error) => console.log(error)),

              axios
                .get(API_URL + '/etax/documents/ALL/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    cid: companyId
                  },
                })
                .catch((error) => console.log(error)),
            ]);

            console.log("handle search", response.data)

            const orders = response.data ? [response.data] : []
            const totalRow = response.data ? 1 : 0

            console.log("orders", orders)

            let _pageDropdown = [];

            for (let i = 1; i <= Math.ceil(totalRow / pageSize); i++) {
              _pageDropdown.push({
                key: i,
                text: t('lineShopping.page') + ' ' + i,
                iconProps: { iconName: 'Page' },
                onClick: () => {
                  console.log('Selected page: ' + i);
                  setCurrentPage(i);
                },
              });
            }

            setPageDropdown(_pageDropdown);

            if (currentPage > Math.ceil(totalRow / pageSize)) {
              setCurrentPage(1);
            }

            setTotalDocuments(totalRow);
            setPageData(paginate(totalRow, currentPage, pageSize, 10));

            const _docs = [];
            orders.forEach((order) => {
              const {
                orderNumber,
                discountAmount,
                remarkBuyer,
                shipmentPrice,
                shippingAddress,
                subtotalPrice,
                totalPrice,
                checkoutAt,
                lastUpdatedAt,
                paymentMethod,
                paymentStatus,
                shipmentStatus,
                paidAt
              } = order;

              const { recipientName, address, province, postalCode, phoneNumber, email, district, subDistrict } = shippingAddress;

              let _subDistrictPrefix = 'ต.';
              let _districtPrefix = 'อ.';
              let _provincePrefix = 'จ.';
              if (province.includes('กรุงเทพ')) {
                _subDistrictPrefix = 'แขวง ';
                _districtPrefix = 'เขต ';
                _provincePrefix = ' ';
              }

              const _customerAddress = `${address} ${_subDistrictPrefix + subDistrict} ${_districtPrefix + district} ${_provincePrefix + province}`;
              const _date = new Date(checkoutAt);
              const _updatedDate = new Date(lastUpdatedAt);

              let _paymentMethod = ""
              if (paymentMethod) {
                if (paymentMethod === "BANK") {
                  _paymentMethod = "BANK"
                } else if (paymentMethod === "COD") {
                  _paymentMethod = "COD"
                } else if (paymentMethod === "PAYMENT_LS_RLP") {
                  _paymentMethod = "CREDIT/DEBIT CARD"
                } else if (paymentMethod === "PAYMENT_LS_MOBILE_BANKING_KPLUS") {
                  _paymentMethod = "KPLUS"
                } else if (paymentMethod === "PAYMENT_LS_MOBILE_BANKING_SCB") {
                  _paymentMethod = "SCB"
                }
              }

              _docs.push({
                key: orderNumber,
                date: _date,
                dateBE:
                  `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + (_date.getFullYear() + 543),
                dateCE: `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + _date.getFullYear(),
                updatedDate: _updatedDate,
                updatedDateBE:
                  `${_updatedDate.getDate()}`.padStart(2, '0') + '/' + `${_updatedDate.getMonth() + 1}`.padStart(2, '0') + '/' + (_updatedDate.getFullYear() + 543),
                updatedDateCE: `${_updatedDate.getDate()}`.padStart(2, '0') + '/' + `${_updatedDate.getMonth() + 1}`.padStart(2, '0') + '/' + _updatedDate.getFullYear(),
                customerName: recipientName,
                customerAddress: _customerAddress,
                customerPostcode: getPostcode(postalCode),
                customerPhone: phoneNumber,
                customerEmail: email,
                amountTotal: showShippingFee ? totalPrice : subtotalPrice,
                shippingFee: showShippingFee ? shipmentPrice : 0,
                discount: discountAmount,
                note: remarkBuyer,
                etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.reference === orderNumber),
                paymentMethod: _paymentMethod,
                paymentMethodValue: paymentMethod,
                paymentStatusValue: paymentStatus,
                shipmentStatusValue: shipmentStatus,
                paidAt: paidAt
              });
            });

            setItems(_docs);
            setLoadDataComplete(true);
            setGetNewDocuments(false);
            setIsCreateDocumentComplete(false);

          })
          .catch((error) => {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            // instance.acquireTokenRedirect(silentRequest);
            // disconnect(true);
            sendFeedback("Line Shopping", "search", error, account.username, {
              search: search,
            })

            setItems([]);
            setLoadDataComplete(true);
            setGetNewDocuments(false);
            setIsCreateDocumentComplete(false);
          });
      } else {
        console.log('getNewOrder Running!!!');

        instance
          .acquireTokenSilent({ ...silentRequest, account: account })
          .then(async (tokenResponse) => {
            const [response, responseAllDocs] = await Promise.all([
              axios
                // .get('http://localhost:7071/api/Line_getOrders', {
                .get(API_URL + '/sources/line-shopping/orders', {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    cid: companyId
                  },
                  params: {
                    orderStatus: orderType.key,
                    sortBy: _sortBy,
                    orderBy: _orderBy,
                    page: currentPage,
                    perPage: pageSize,
                    startAt: from2,
                    endAt: to2,
                    paymentMethod: paymentMethodType.key,
                    paymentStatus: paymentStatus.key,
                    shipmentStatus: shipmentStatus.key,
                    // apiKey: 'MjkzZTA1MzAtZjYxOS00MWQxLWI4ODEtYzk2ZmI5MWZmNGYy',
                  },
                })
                .catch((error) => console.log(error)),

              axios
                .get(API_URL + '/etax/documents/ALL/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    cid: companyId
                  },
                })
                .catch((error) => console.log(error)),
            ]);

            console.log("response", response)

            console.log("responseAllDocs", responseAllDocs)
            // const { data: orders, totalRow } = response.data;

            const orders = response.data?.data || []
            const totalRow = response.data?.totalRow || 0

            console.log("orders", orders);

            let _pageDropdown = [];

            for (let i = 1; i <= Math.ceil(totalRow / pageSize); i++) {
              _pageDropdown.push({
                key: i,
                text: t('lineShopping.page') + ' ' + i,
                iconProps: { iconName: 'Page' },
                onClick: () => {
                  console.log('Selected page: ' + i);
                  setCurrentPage(i);
                },
              });
            }

            setPageDropdown(_pageDropdown);

            if (currentPage > Math.ceil(totalRow / pageSize)) {
              setCurrentPage(1);
            }

            setTotalDocuments(totalRow);
            setPageData(paginate(totalRow, currentPage, pageSize, 10));

            const _docs = [];
            orders.forEach((order) => {
              const {
                orderNumber,
                discountAmount,
                remarkBuyer,
                shipmentPrice,
                shippingAddress,
                subtotalPrice,
                totalPrice,
                checkoutAt,
                lastUpdatedAt,
                paymentMethod,
                paymentStatus,
                shipmentStatus,
                paidAt
              } = order;

              const { recipientName, address, province, postalCode, phoneNumber, email, district, subDistrict } = shippingAddress;

              let _subDistrictPrefix = 'ต.';
              let _districtPrefix = 'อ.';
              let _provincePrefix = 'จ.';
              if (province.includes('กรุงเทพ')) {
                _subDistrictPrefix = 'แขวง ';
                _districtPrefix = 'เขต ';
                _provincePrefix = ' ';
              }

              const _customerAddress = `${address} ${_subDistrictPrefix + subDistrict} ${_districtPrefix + district} ${_provincePrefix + province}`;

              const _date = new Date(checkoutAt);
              const _updatedDate = new Date(lastUpdatedAt);

              let _paymentMethod = ""
              if (paymentMethod) {
                if (paymentMethod === "BANK") {
                  _paymentMethod = "BANK"
                } else if (paymentMethod === "COD") {
                  _paymentMethod = "COD"
                } else if (paymentMethod === "PAYMENT_LS_RLP") {
                  _paymentMethod = "CREDIT/DEBIT CARD"
                } else if (paymentMethod === "PAYMENT_LS_MOBILE_BANKING_KPLUS") {
                  _paymentMethod = "KPLUS"
                } else if (paymentMethod === "PAYMENT_LS_MOBILE_BANKING_SCB") {
                  _paymentMethod = "SCB"
                }
              }

              _docs.push({
                key: orderNumber,
                date: _date,
                dateBE:
                  `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + (_date.getFullYear() + 543),
                dateCE: `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + _date.getFullYear(),
                updatedDate: _updatedDate,
                updatedDateBE:
                  `${_updatedDate.getDate()}`.padStart(2, '0') + '/' + `${_updatedDate.getMonth() + 1}`.padStart(2, '0') + '/' + (_updatedDate.getFullYear() + 543),
                updatedDateCE: `${_updatedDate.getDate()}`.padStart(2, '0') + '/' + `${_updatedDate.getMonth() + 1}`.padStart(2, '0') + '/' + _updatedDate.getFullYear(),
                customerName: recipientName,
                customerAddress: _customerAddress,
                customerPostcode: getPostcode(postalCode),
                customerPhone: phoneNumber,
                customerEmail: email,
                amountTotal: showShippingFee ? totalPrice : subtotalPrice,
                shippingFee: showShippingFee ? shipmentPrice : 0,
                discount: discountAmount,
                note: remarkBuyer,
                etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.reference === orderNumber),
                paymentMethod: _paymentMethod,
                paymentMethodValue: paymentMethod,
                paymentStatusValue: paymentStatus,
                shipmentStatusValue: shipmentStatus,
                paidAt: paidAt
              });
            });

            if (_docs.length > 0) {
              if (primarySort === 'customerName') {
                if (isSortedDescendingCustomerName) {
                  _docs.sort((a, b) => {
                    if (b.customerName < a.customerName) {
                      return -1;
                    }
                    if (b.customerName > a.customerName) {
                      return 1;
                    }
                    return 0;
                  });
                } else {
                  _docs.sort((a, b) => {
                    if (a.customerName < b.customerName) {
                      return -1;
                    }
                    if (a.customerName > b.customerName) {
                      return 1;
                    }
                    return 0;
                  });
                }
              } else if (primarySort === 'amountTotal') {
                if (isSortedDescendingAmountTotal) {
                  _docs.sort(function (a, b) {
                    return b.amountTotal - a.amountTotal;
                  });
                } else {
                  _docs.sort(function (a, b) {
                    return a.amountTotal - b.amountTotal;
                  });
                }
              } else if (primarySort === 'paymentMethod') {
                if (isSortedDescendingPaymentMethod) {
                  _docs.sort((a, b) => {
                    if (b.paymentMethodValue < a.paymentMethodValue) {
                      return -1;
                    }
                    if (b.paymentMethodValue > a.paymentMethodValue) {
                      return 1;
                    }
                    return 0;
                  });
                } else {
                  _docs.sort((a, b) => {
                    if (a.paymentMethodValue < b.paymentMethodValue) {
                      return -1;
                    }
                    if (a.paymentMethodValue > b.paymentMethodValue) {
                      return 1;
                    }
                    return 0;
                  });
                }
              } else if (primarySort === 'paymentStatus') {
                if (isSortedDescendingPaymentStatus) {
                  _docs.sort((a, b) => {
                    if (b.paymentStatusValue < a.paymentStatusValue) {
                      return -1;
                    }
                    if (b.paymentStatusValue > a.paymentStatusValue) {
                      return 1;
                    }
                    return 0;
                  });
                } else {
                  _docs.sort((a, b) => {
                    if (a.paymentStatusValue < b.paymentStatusValue) {
                      return -1;
                    }
                    if (a.paymentStatusValue > b.paymentStatusValue) {
                      return 1;
                    }
                    return 0;
                  });
                }
              } else if (primarySort === 'shipmentStatus') {
                if (isSortedDescendingShipmentStatus) {
                  _docs.sort((a, b) => {
                    if (b.shipmentStatusValue < a.shipmentStatusValue) {
                      return -1;
                    }
                    if (b.shipmentStatusValue > a.shipmentStatusValue) {
                      return 1;
                    }
                    return 0;
                  });
                } else {
                  _docs.sort((a, b) => {
                    if (a.shipmentStatusValue < b.shipmentStatusValue) {
                      return -1;
                    }
                    if (a.shipmentStatusValue > b.shipmentStatusValue) {
                      return 1;
                    }
                    return 0;
                  });
                }
              } else if (primarySort === "etaxCreated") {
                if (isSortedDescendingEtaxCreated) {
                  _docs.sort(function(x, y) {
                      return (x.etaxCreated === y.etaxCreated) ? 0 : x.etaxCreated ? -1 : 1;
                  })
        
                } else {
                  _docs.sort(function(x, y) {
                      return (x.etaxCreated === y.etaxCreated) ? 0 : x.etaxCreated ? 1 : -1;
                  })
        
                }
              }
            }

            setItems(_docs);
            setLoadDataComplete(true);
            setGetNewDocuments(false);
            setIsCreateDocumentComplete(false);

          })
          .catch((error) => {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            // instance.acquireTokenRedirect(silentRequest);
            // disconnect(true);
            sendFeedback("Line Shopping", "getNewDocument", error, account.username, {
              orderStatus: orderType.key,
              sortBy: _sortBy,
              orderBy: _orderBy,
              page: currentPage,
              perPage: pageSize,
              startAt: from2,
              endAt: to2,
              paymentMethod: paymentMethodType.key,
              paymentStatus: paymentStatus.key,
              shipmentStatus: shipmentStatus.key
            })

            setItems([]);
            setLoadDataComplete(true);
            setGetNewDocuments(false);
            setIsCreateDocumentComplete(false);
          });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments]);

  useEffect(() => {
    sessionStorage.setItem('lineShopping-currentPage', currentPage);

    if (company && company.name) {
      setLoadDataComplete(false);

      setItems([]);
      setGetNewDocuments(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    console.log('isCreateDocumentComplete useEffect: ', isCreateDocumentComplete);

    if (loadDataComplete && !isCreateDocument) {
      let _items = selection.getItems();

      let _selectedItems = selection.getSelection();

      if (isCreateDocumentComplete) {
        setIsCreateDocumentComplete(false);

        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status !== '') {
            _items[i].status = '';
          }
        }
      } else {
        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status !== '') {
            _items[i].status = '';
          }
        }

        for (let i = 0; i < _selectedItems.length; i++) {

          for (let j = 0; j < _items.length; j++) {
            if (_items[j].key === _selectedItems[i].key) {
              _items[j].status = 'pending';
            }
          }
        }
      }

      setItems(_items);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const handleSort = (primary, isSorted) => {
    if (items.length > 0) {
      let _items = selection.getItems();
      let _selectedItems = selection.getSelection();

      for (let i = 0; i < _selectedItems.length; i++) {
        _selectedItems[i].status = '';

        for (let j = 0; j < _items.length; j++) {
          if (_items[j].key === _selectedItems[i].key) {
            _items[j].status = '';
            _selectedItems[i].status = '';
          }
        }
      }

      selection.setAllSelected(false);

      if (primary === 'customerName') {
        if (isSorted) {
          _items.sort((a, b) => {
            if (b.customerName < a.customerName) {
              return -1;
            }
            if (b.customerName > a.customerName) {
              return 1;
            }
            return 0;
          });
        } else {
          _items.sort((a, b) => {
            if (a.customerName < b.customerName) {
              return -1;
            }
            if (a.customerName > b.customerName) {
              return 1;
            }
            return 0;
          });
        }

        setItems(_items);
      } else if (primary === 'amountTotal') {
        if (isSorted) {
          _items.sort(function (a, b) {
            return b.amountTotal - a.amountTotal;
          });
        } else {
          _items.sort(function (a, b) {
            return a.amountTotal - b.amountTotal;
          });
        }

        setItems(_items);
      } else if (primary === 'paymentMethod') {
        if (isSorted) {
          _items.sort((a, b) => {
            if (b.paymentMethodValue < a.paymentMethodValue) {
              return -1;
            }
            if (b.paymentMethodValue > a.paymentMethodValue) {
              return 1;
            }
            return 0;
          });
        } else {
          _items.sort((a, b) => {
            if (a.paymentMethodValue < b.paymentMethodValue) {
              return -1;
            }
            if (a.paymentMethodValue > b.paymentMethodValue) {
              return 1;
            }
            return 0;
          });
        }

        setItems(_items);
      } else if (primary === 'paymentStatus') {
        if (isSorted) {
          _items.sort((a, b) => {
            if (b.paymentStatusValue < a.paymentStatusValue) {
              return -1;
            }
            if (b.paymentStatusValue > a.paymentStatusValue) {
              return 1;
            }
            return 0;
          });
        } else {
          _items.sort((a, b) => {
            if (a.paymentStatusValue < b.paymentStatusValue) {
              return -1;
            }
            if (a.paymentStatusValue > b.paymentStatusValue) {
              return 1;
            }
            return 0;
          });
        }

        setItems(_items);
      } else if (primary === 'shipmentStatus') {
        if (isSorted) {
          _items.sort((a, b) => {
            if (b.shipmentStatusValue < a.shipmentStatusValue) {
              return -1;
            }
            if (b.shipmentStatusValue > a.shipmentStatusValue) {
              return 1;
            }
            return 0;
          });
        } else {
          _items.sort((a, b) => {
            if (a.shipmentStatusValue < b.shipmentStatusValue) {
              return -1;
            }
            if (a.shipmentStatusValue > b.shipmentStatusValue) {
              return 1;
            }
            return 0;
          });
        }

        setItems(_items);
      } else if (primary === "etaxCreated") {
        if (isSorted) {
          _items.sort(function(x, y) {
              return (x.etaxCreated === y.etaxCreated) ? 0 : x.etaxCreated ? -1 : 1;
          })

          setItems(_items);
        } else {
          _items.sort(function(x, y) {
              return (x.etaxCreated === y.etaxCreated) ? 0 : x.etaxCreated ? 1 : -1;
          })

          setItems(_items);
        }
      }
    }
  };

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  return (
    <div style={homeStyles}>
      {!isLoading ? (
        <div>
          <Stack>
            <center>
              <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('lineShopping.header')}</h2>
              {!loadDataComplete ? (
                <div>
                  <Spinner
                    label={t('lineShopping.loading')}
                    labelPosition="right"
                    style={{
                      marginTop: '21px',
                      marginBottom: '2px',
                    }}
                  />

                  <br />
                </div>
              ) : (
                !search && (
                  <h4>
                    {queryMonth.text !== 'เดือนนี้' && queryMonth.text !== 'This Month' ? t('lineShopping.month') : ''}
                    {getDateText(queryMonth)} ({totalDocuments.toLocaleString(undefined)} {t('lineShopping.orderUnit')})
                  </h4>
                )
              )}
              <Stack horizontal horizontalAlign="center">
                <Toggle
                  label={t('lineShopping.displayShipCost')}
                  inlineLabel
                  onText={t('lineShopping.on')}
                  offText={t('lineShopping.off')}
                  checked={showShippingFee}
                  disabled={!loadDataComplete || isCreateDocument}
                  onChange={() => {
                    setShowShippingFee(!showShippingFee);

                    if (!showShippingFee) {
                      localStorage.setItem('showShippingFee', 'yes');
                    } else {
                      localStorage.setItem('showShippingFee', 'no');
                    }

                    setLoadDataComplete(false);
                    setItems([]);
                    setGetNewDocuments(true);

                    setTotalDocuments(0);
                  }}
                />
              </Stack>
            </center>
          </Stack>

          {(selectedItems.length > 0 || isCreateDocument || isCreateDocumentComplete) && (
            <Stack vertical tokens={{ childrenGap: '10px' }}>
              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                <h3>{t('lineShopping.subHeader')}</h3>
              </Stack>
              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                <TextField
                  name="prefixNumber"
                  label={t('lineShopping.prefixNumber')}
                  description={t('lineShopping.prefixNumberDescription')}
                  styles={{ root: { width: 200 } }}
                  onChange={(e) => {
                    setPrefixNumber(e.target.value);
                  }}
                  value={prefixNumber}
                  disabled={isCreateDocument}
                />
                <TextField
                  name="countingNumber"
                  label={t('lineShopping.countingNumber')}
                  description={countingNumber.length === 0 ? '' : t('lineShopping.countingNumberDesciption')}
                  styles={{ root: { width: 150 } }}
                  onChange={(e) => {
                    if (!/\D/.test(e.target.value)) {
                      setCountingNumber(e.target.value);
                    }
                  }}
                  value={countingNumber}
                  errorMessage={countingNumber.length === 0 ? t('lineShopping.countingNumberErrorMessage') : ''}
                  disabled={isCreateDocument}
                  required
                />
              </Stack>
              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                <TextField
                    name="createdBy"
                    label={t('lineShopping.createdBy')}
                    description={t('lineShopping.createdByDesc')}
                    styles={{ root: { width: 200 } }}
                    onChange={(e) => {
                        setCreatedBy(e.target.value);
                    }}
                    value={createdBy}
                    disabled={isCreateDocument}
                />
              </Stack>
              {countingNumber.length > 0 ? (
                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                  {!isCreateDocument ? (
                    <h4>
                      {t('lineShopping.startDocumentNumber')} {prefixNumber}
                      {countingNumber}
                    </h4>
                  ) : (
                    <h4>
                      {t('lineShopping.endDocumentNumber')} {prefixNumber}
                      {countingNumber}
                    </h4>
                  )}
                </Stack>
              ) : (
                <br />
              )}
            </Stack>
          )}

          {selectedItems.length > 0 && !isCreateDocument && (
            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
              <PrimaryButton
                text={t('lineShopping.createDocument')}
                iconProps={{ iconName: 'Add' }}
                menuProps={{
                  items: [
                    {
                      key: 'RECEIPT-TAXINVOICE',
                      text: t('lineShopping.receipt-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        if (countingNumber.length > 0) {
                          setIsCreateDocument(true);
                          await createSelectedDocuments('RECEIPT-TAXINVOICE');
                        }
                      },
                    },
                    {
                      key: 'RECEIPT-TAXINVOICE-ABB',
                      text: t('lineShopping.receipt-taxinvoice-abb'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        if (countingNumber.length > 0) {
                          setIsCreateDocument(true);
                          await createSelectedDocuments('RECEIPT-TAXINVOICE-ABB');
                        }
                      },
                    },
                    {
                      key: 'RECEIPT',
                      text: t('lineShopping.receipt'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        if (countingNumber.length > 0) {
                          setIsCreateDocument(true);
                          await createSelectedDocuments('RECEIPT');
                        }
                      },
                    },
                    {
                      key: 'INVOICE-TAXINVOICE',
                      text: t('lineShopping.invoice-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        if (countingNumber.length > 0) {
                          setIsCreateDocument(true);
                          await createSelectedDocuments('INVOICE-TAXINVOICE');
                        }
                      },
                    },
                    {
                      key: 'DELIVERYORDER-TAXINVOICE',
                      text: t('lineShopping.deliveryorder-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        if (countingNumber.length > 0) {
                          setIsCreateDocument(true);
                          await createSelectedDocuments('DELIVERYORDER-TAXINVOICE');
                        }
                      },
                    },
                    {
                      key: 'TAXINVOICE',
                      text: t('lineShopping.taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        if (countingNumber.length > 0) {
                          setIsCreateDocument(true);
                          await createSelectedDocuments('TAXINVOICE');
                        }
                      },
                    },
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
              <DefaultButton
                text={t('lineShopping.cancel')}
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  let _items = selection.getItems();
                  let _selectedItems = selection.getSelection();
                  console.log('selection: ', selection.getSelection());

                  for (let i = 0; i < _selectedItems.length; i++) {
                    console.log('select item key: ', _selectedItems[i].key);
                    _selectedItems[i].status = '';

                    for (let j = 0; j < _items.length; j++) {
                      if (_items[j].key === _selectedItems[i].key) {
                        console.log('item key: ', _items[j].key);
                        _items[j].status = '';
                        _selectedItems[i].status = '';
                      }
                    }
                  }

                  selection.setAllSelected(false);

                  setItems(_items);
                  console.log('selection: ', selection.getSelection());
                }}
              />
              <br />
            </Stack>
          )}

          {isCreateDocument && (
            <div>
              <Spinner label={t('lineShopping.signProcessing')} size={SpinnerSize.large} />
            </div>
          )}

          {isCreateDocumentComplete && (
            <center>
              <PrimaryButton
                text={t('lineShopping.createdDocuments')}
                iconProps={{ iconName: 'Documentation' }}
                onClick={async () => {
                  console.log(selectedItems);
                  history.push({
                    pathname: '/etax/documents/all',
                    state: {},
                  });
                }}
              />
            </center>
          )}
          <br />
          <Stack horizontal horizontalAlign="center">
            <Stack
              vertical
              style={{
                width: '98%',
                minWidth: '800px',
                maxWidth: '1350px',
              }}
            >
              <Stack 
                horizontal 
                styles={{
                  root: {
                    backgroundColor: 'white',
                  },
                }}
                horizontalAlign="space-between"
              >
                <Stack
                  styles={{
                    root: {
                      width: 'calc(100vw - 700px)',
                      minWidth: '800px',
                      maxWidth: '950px',
                    },
                  }}
                >
                  <CommandBar items={commandBarItems} overflowItems={overflowItems} />
                </Stack>
                <Stack
                  horizontal
                  styles={{
                    root: {
                      height: 44,
                    },
                  }}
                  tokens={{ childrenGap: '10px' }}
                >
                  <SearchBox
                    className="ms-borderColor-themePrimary"
                    styles={{
                      root: {
                        marginTop: 6,
                        marginLeft: 6,
                        width: 200,
                        fontSize: 13,
                        fontWeight: 0,
                      },
                    }}
                    disabled={!loadDataComplete || isCreateDocument}
                    placeholder={t('lineShopping.searchBoxPlaceholder')}
                    onChange={(e) => setSearch(e ? e.target.value.trim() : '')}
                    onClear={() => {
                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);

                      setTotalDocuments(0);
                    }}
                    onSearch={() => {
                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);

                      setTotalDocuments(0);
                    }}
                    value={search}
                  />
                  <PrimaryButton
                    disabled={!loadDataComplete || isCreateDocument || !search}
                    styles={{
                      root: {
                        marginTop: 6,
                        marginRight: 8,
                        width: 90,
                      },
                    }}
                    text={t('lineShopping.search')}
                    onClick={() => {
                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);

                      setTotalDocuments(0);
                    }}
                  />
                </Stack>
              </Stack>
              <ShimmeredDetailsList
                items={items || []}
                enableShimmer={!loadDataComplete}
                compact={isCompactMode}
                columns={columns}
                selection={selection}
                selectionMode={!loadDataComplete || isCreateDocument ? SelectionMode.none : SelectionMode.multiple}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                checkboxVisibility={CheckboxVisibility.always}
                selectionPreservedOnEmptyClick={true}
                
              />
            </Stack>
          </Stack>
          <br />
          <center>
            {!(items.length === 0 || (currentPage === 1 && items.length < pageSize)) && !(!loadDataComplete || isCreateDocument) && (
              <Pagination
                selectedPageIndex={currentPage - 1}
                pageCount={pageData.totalPages}
                onPageChange={onPageChange}
                format
                firstPageIconProps={{
                  iconName: 'DoubleChevronLeft',
                }}
                previousPageIconProps={{
                  iconName: 'ChevronLeft',
                }}
                nextPageIconProps={{ iconName: 'ChevronRight' }}
                lastPageIconProps={{
                  iconName: 'DoubleChevronRight',
                }}
              />
            )}

            <br />
            <br />
          </center>
        </div>
      ) : (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="certificates-loading-center">
              <Spinner label={t('lineShopping.loading')} labelPosition="down" styles={spinnerStyles} />
            </div>
            <br />
          </Stack>
        </Stack>
      )}
      <Dialog
        hidden={!dialogConfirm.isClick}
        onDismiss={dialogConfirm.onCancel}
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: dialogConfirm.header,
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 }, subText: { textAlign: 'center' } },
          subText: dialogConfirm.message,
        }}
      >
        <DialogFooter styles={{ actionsRight: { textAlign: 'center' } }}>
          <PrimaryButton onClick={dialogConfirm.onConfirm} text={t('documents.confirm')} />
          <DefaultButton onClick={dialogConfirm.onCancel} text={t('documents.cancel')} />
        </DialogFooter>
      </Dialog>
    </div>
  );
}

export default LineShopping;
