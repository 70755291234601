import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import paginate from 'jw-paginate';

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  Selection,
  SelectionMode,
  Icon,
  Calendar,
  DateRangeType,
  Spinner,
  SpinnerSize,
  SearchBox,
  CommandBar,
  Stack,
  PrimaryButton,
  DefaultButton,
  TextField,
  ContextualMenu,
  CheckboxVisibility,
  Dialog,
  DialogType,
  DialogFooter,
} from '@fluentui/react';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

const API_URL = process.env.REACT_APP_API_URL;

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

const Paypal = (props) => {
  console.log('Home render!!!');

  const history = useHistory();

  const { t, i18n } = useTranslation();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(true);
  const [pageData, setPageData] = useState({});
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('paypal-currentPage') * 1 || 1);

  //CommandBars Items
  const [documentType, setDocumentType] = useState(
    (sessionStorage.getItem('paypal-documentType-key') && {
      key: sessionStorage.getItem('paypal-documentType-key'),
      text: sessionStorage.getItem('paypal-documentType-text'),
    }) || {
      key: 'PAID',
      text: 'PAID',
    }
  );

  const [company, setCompany] = useState('');
  const [search, setSearch] = useState('');

  const percentVat = 7;
  const zeroVat = 0;
  // const nonVat = -1;

  const percentVatText = '7%';
  const zeroVatText = '0%';
  // const nonVatText = '-'

  const dateType = { key: 'document', text: 'วันที่บนเอกสาร' };

  const [includeVat, setIncludeVat] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const [registerVat, setRegisterVat] = useState(true);

  const isCompactMode = false;

  const [isCreateDocument, setIsCreateDocument] = useState(false);
  const [isCreateDocumentComplete, setIsCreateDocumentComplete] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        console.log('isCreateDocument: ', isCreateDocument);
        console.log('isCreateDocumentComplete: ', isCreateDocumentComplete);
        let _selectedItems = selection.getSelection();

        setSelectedItems(_selectedItems);
      },
    })
  );

  const [dialogConfirm, setDialogConfirm] = useState({
    isClick: false,
    header: '',
    message: '',
    onConfirm: null,
    onCancel: null,
  });

  const [createdBy, setCreatedBy] = useState('');

  const companyId = sessionStorage.getItem('companyId') || '';

  const [primarySort, setPrimarySort] = useState(sessionStorage.getItem('paypal-primarySort') || 'createdTime');
  const [isSortedDescendingCreatedTime, setIsSortedDescendingCreatedTime] = useState(() => {
    if (sessionStorage.getItem('paypal-sort-createdTime') === 'false') {
      return false;
    } else {
      return true;
    }
  });

  const [isSortedDescendingDocumentDate, setIsSortedDescendingDocumentDate] = useState(() => {
    if (sessionStorage.getItem('paypal-sort-documentDate') && sessionStorage.getItem('paypal-sort-documentDate') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(() => {
    if (sessionStorage.getItem('paypal-sort-number')) {
      if (sessionStorage.getItem('paypal-sort-number') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingCustomerName, setIsSortedDescendingCustomerName] = useState(() => {
    if (sessionStorage.getItem('paypal-sort-customerName')) {
      if (sessionStorage.getItem('paypal-sort-customerName') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingGrandTotal, setIsSortedDescendingGrandTotal] = useState(() => {
    if (sessionStorage.getItem('paypal-sort-grandTotal')) {
      if (sessionStorage.getItem('paypal-sort-grandTotal') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });

  const DayPickerStrings = {
    months: [
      t('paypal.months01'),
      t('paypal.months02'),
      t('paypal.months03'),
      t('paypal.months04'),
      t('paypal.months05'),
      t('paypal.months06'),
      t('paypal.months07'),
      t('paypal.months08'),
      t('paypal.months09'),
      t('paypal.months10'),
      t('paypal.months11'),
      t('paypal.months12'),
    ],

    shortMonths: [
      t('paypal.shortMonths01'),
      t('paypal.shortMonths02'),
      t('paypal.shortMonths03'),
      t('paypal.shortMonths04'),
      t('paypal.shortMonths05'),
      t('paypal.shortMonths06'),
      t('paypal.shortMonths07'),
      t('paypal.shortMonths08'),
      t('paypal.shortMonths09'),
      t('paypal.shortMonths10'),
      t('paypal.shortMonths11'),
      t('paypal.shortMonths12'),
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: t('paypal.goToToday'),
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: t('paypal.isRequiredErrorMessage'),

    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };

  // Processing dialog style
  const spinnerStyles = {
    circle: {
      height: 56,
      width: 56,
      borderWidth: 4,
    },
  };

  const getMonth = (goBack) => {
    let monthNames = [
      t('paypal.months01'),
      t('paypal.months02'),
      t('paypal.months03'),
      t('paypal.months04'),
      t('paypal.months05'),
      t('paypal.months06'),
      t('paypal.months07'),
      t('paypal.months08'),
      t('paypal.months09'),
      t('paypal.months10'),
      t('paypal.months11'),
      t('paypal.months12'),
    ];

    let d = new Date();

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + ' ' + year,
    };
  };

  const [queryMonth, setQueryMonth] = useState(
    (sessionStorage.getItem('paypal-queryMonth-key') && {
      key: sessionStorage.getItem('paypal-queryMonth-key'),
      text: sessionStorage.getItem('paypal-queryMonth-text'),
    }) || { key: getMonth(0).year + '' + getMonth(0).key, text: t('paypal.thisMonth') }
  );

  const getFrom = (month, year) => {
    let _firstDay = new Date(year, month, 1);
    console.log('First day: ', formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getTo = (month, year) => {
    let _lastDay = new Date(year, month + 1, 1);
    console.log('Last day: ', formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const formatDate = (d) => {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const [from, setFrom] = useState(sessionStorage.getItem('paypal-from') || getFrom(getMonth(0).month, getMonth(0).year));
  const [to, setTo] = useState(sessionStorage.getItem('paypal-to') || getTo(getMonth(0).month, getMonth(0).year));

  const [date, setDate] = useState(sessionStorage.getItem('paypal-date') ? new Date(sessionStorage.getItem('paypal-date')) : new Date());

  let _pageDropdown = [];

  for (let i = 1; i <= 35; i++) {
    _pageDropdown.push({
      key: i,
      text: t('paypal.page') + ' ' + i,
      iconProps: { iconName: 'Page' },
      onClick: () => {
        console.log('Selected page: ' + i);
        setCurrentPage(i);
      },
    });
  }

  const [pageDropdown, setPageDropdown] = useState(_pageDropdown);

  function getDateText(monthObject) {
    if (monthObject.text === 'เดือนนี้' || monthObject.text === 'This Month') return t('paypal.thisMonth');

    let monthNames = [
      t('paypal.months01'),
      t('paypal.months02'),
      t('paypal.months03'),
      t('paypal.months04'),
      t('paypal.months05'),
      t('paypal.months06'),
      t('paypal.months07'),
      t('paypal.months08'),
      t('paypal.months09'),
      t('paypal.months10'),
      t('paypal.months11'),
      t('paypal.months12'),
    ];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    if (i18n.language === 'th') {
      _year = _year + 543;
    }

    return monthNames[_monthIndex] + ' ' + _year;
  }

  const commandBarItems = [
    {
      key: documentType.key,
      text: documentType.text,
      iconProps: { iconName: 'ProductList' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: [
          {
            key: 'PAID',
            text: 'PAID',
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('PAID');
              setDocumentType({
                key: 'PAID',
                text: 'PAID',
              });

              sessionStorage.setItem('paypal-documentType-key', 'PAID');
              sessionStorage.setItem('paypal-documentType-text', 'PAID');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 'PARTIALLY_PAID',
            text: 'PARTIALLY_PAID',
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('PARTIALLY_PAID');
              setDocumentType({
                key: 'PARTIALLY_PAID',
                text: 'PARTIALLY_PAID',
              });

              sessionStorage.setItem('paypal-documentType-key', 'PARTIALLY_PAID');
              sessionStorage.setItem('paypal-documentType-text', 'PARTIALLY_PAID');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 'MARKED_AS_PAID',
            text: 'MARKED_AS_PAID',
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('MARKED_AS_PAID');
              setDocumentType({
                key: 'MARKED_AS_PAID',
                text: 'MARKED_AS_PAID',
              });

              sessionStorage.setItem('paypal-documentType-key', 'MARKED_AS_PAID');
              sessionStorage.setItem('paypal-documentType-text', 'MARKED_AS_PAID');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 'REFUNDED',
            text: 'REFUNDED',
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('REFUNDED');
              setDocumentType({
                key: 'REFUNDED',
                text: 'REFUNDED',
              });

              sessionStorage.setItem('paypal-documentType-key', 'REFUNDED');
              sessionStorage.setItem('paypal-documentType-text', 'REFUNDED');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 'PARTIALLY_REFUNDED',
            text: 'PARTIALLY_REFUNDED',
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('PARTIALLY_REFUNDED');
              setDocumentType({
                key: 'PARTIALLY_REFUNDED',
                text: 'PARTIALLY_REFUNDED',
              });

              sessionStorage.setItem('paypal-documentType-key', 'PARTIALLY_REFUNDED');
              sessionStorage.setItem('paypal-documentType-text', 'PARTIALLY_REFUNDED');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 'MARKED_AS_REFUNDED',
            text: 'MARKED_AS_REFUNDED',
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('MARKED_AS_REFUNDED');
              setDocumentType({
                key: 'MARKED_AS_REFUNDED',
                text: 'MARKED_AS_REFUNDED',
              });

              sessionStorage.setItem('paypal-documentType-key', 'MARKED_AS_REFUNDED');
              sessionStorage.setItem('paypal-documentType-text', 'MARKED_AS_REFUNDED');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 'PAYMENT_PENDING',
            text: 'PAYMENT_PENDING',
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('PAYMENT_PENDING');
              setDocumentType({
                key: 'PAYMENT_PENDING',
                text: 'PAYMENT_PENDING',
              });

              sessionStorage.setItem('paypal-documentType-key', 'PAYMENT_PENDING');
              sessionStorage.setItem('paypal-documentType-text', 'PAYMENT_PENDING');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 'UNPAID',
            text: 'UNPAID',
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('UNPAID');
              setDocumentType({
                key: 'UNPAID',
                text: 'UNPAID',
              });

              sessionStorage.setItem('paypal-documentType-key', 'UNPAID');
              sessionStorage.setItem('paypal-documentType-text', 'UNPAID');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 'CANCELLED',
            text: 'CANCELLED',
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('CANCELLED');
              setDocumentType({
                key: 'CANCELLED',
                text: 'CANCELLED',
              });

              sessionStorage.setItem('paypal-documentType-key', 'CANCELLED');
              sessionStorage.setItem('paypal-documentType-text', 'CANCELLED');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 'SENT',
            text: 'SENT',
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('SENT');
              setDocumentType({
                key: 'SENT',
                text: 'SENT',
              });

              sessionStorage.setItem('paypal-documentType-key', 'SENT');
              sessionStorage.setItem('paypal-documentType-text', 'SENT');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 'SCHEDULED',
            text: 'SCHEDULED',
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('SCHEDULED');
              setDocumentType({
                key: 'SCHEDULED',
                text: 'SCHEDULED',
              });

              sessionStorage.setItem('paypal-documentType-key', 'SCHEDULED');
              sessionStorage.setItem('paypal-documentType-text', 'SCHEDULED');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 'DRAFT',
            text: 'DRAFT',
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('DRAFT');
              setDocumentType({
                key: 'DRAFT',
                text: 'DRAFT',
              });

              sessionStorage.setItem('paypal-documentType-key', 'DRAFT');
              sessionStorage.setItem('paypal-documentType-text', 'DRAFT');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
        ],
      },
    },
    {
      key: queryMonth.key,
      text: getDateText(queryMonth),
      iconProps: { iconName: 'Calendar' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: [
          {
            key: 'queryMonth',
          },
        ],
        onRenderMenuList: () => {
          return (
            <div style={{ width: 220 }}>
              <Calendar
                onSelectDate={(_date) => {
                  let monthNames = [
                    t('paypal.months01'),
                    t('paypal.months02'),
                    t('paypal.months03'),
                    t('paypal.months04'),
                    t('paypal.months05'),
                    t('paypal.months06'),
                    t('paypal.months07'),
                    t('paypal.months08'),
                    t('paypal.months09'),
                    t('paypal.months10'),
                    t('paypal.months11'),
                    t('paypal.months12'),
                  ];

                  setDate(_date);

                  sessionStorage.setItem('paypal-date', _date.toISOString());

                  let adjustDate = _date;

                  let year = adjustDate.getFullYear() + 543;

                  setQueryMonth({
                    key: adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'),
                    text: monthNames[adjustDate.getMonth()] + ' ' + year,
                  });

                  sessionStorage.setItem('paypal-queryMonth-key', adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'));
                  sessionStorage.setItem('paypal-queryMonth-text', monthNames[adjustDate.getMonth()] + ' ' + year);

                  setFrom(getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  setTo(getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  sessionStorage.setItem('paypal-from', getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  sessionStorage.setItem('paypal-to', getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  setLoadDataComplete(false);
                  setItems([]);
                  setGetNewDocuments(true);
                }}
                isMonthPickerVisible={true}
                highlightSelectedMonth={true}
                isDayPickerVisible={false}
                dateRangeType={DateRangeType.Day}
                autoNavigateOnSelection={false}
                showGoToToday={false}
                value={date}
                strings={DayPickerStrings}
                showSixWeeksByDefault={true}
              />
            </div>
          );
        },
      },
    },
    {
      key: currentPage,
      text: t('paypal.page') + ' ' + currentPage,
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: pageDropdown,
      },
    },
    {
      key: 'refresh',
      text: t('paypal.refresh'),
      iconProps: { iconName: 'Refresh' },
      disabled: !loadDataComplete || isCreateDocument || search,
      onClick: () => {
        console.log('Refresh');

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);
      },
    },
  ];

  const overflowItems = [
    {
      key: 'disconnect',
      text: t('paypal.disconnect'),
      onClick: () => {
        console.log('paypal disconnect!');
        setDialogConfirm((prev) => ({
          ...prev,
          isClick: true,
          header: t('paypal.disconnect'),
          message: t('paypal.disconnectConfirm'),
          onConfirm: () => {
            disconnect(true);
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            }));
          },
          onCancel: () =>
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            })),
        }));
      },
      iconProps: { iconName: 'PlugDisconnected' },
      disabled: !loadDataComplete || isCreateDocument || search,
    },
  ];

  const toThaiDateString = (isoDateTime) => {
    let date = '';
    date = new Date(isoDateTime);

    let year = date.getFullYear() + 543;
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let numOfDay = date.getDate().toString().padStart(2, '0');

    let hour = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');
    let second = date.getSeconds().toString().padStart(2, '0');

    return `${numOfDay}/${month}/${year} ${hour}:${minutes}:${second} น.`;
  };

  const createDocument = (documentType, item) => {
    let documentPath = '/etax/documents/receipts';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        documentPath = '/etax/documents/receipts-taxinvoices';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        documentPath = '/etax/documents/receipts-taxinvoices-abb';
        break;
      case 'RECEIPT':
        documentPath = '/etax/documents/receipts';
        break;
      case 'INVOICE-TAXINVOICE':
        documentPath = '/etax/documents/invoices-taxinvoices';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        documentPath = '/etax/documents/deliveryorders-taxinvoices';
        break;
      case 'TAXINVOICE':
        documentPath = '/etax/documents/taxinvoices';
        break;
      default:
        break;
    }

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/sources/paypal/invoices/' + item.key, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId
            },
          })
          .then((response_detail) => {
            console.log('response_detail', response_detail);
            let invoiceData = response_detail.data;

            let d = item.documentDate.split('-');

            let _date = new Date(d[2] + '/' + d[1] + '/' + d[0]);

            let _dateBE =
              `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + (_date.getFullYear() + 543);
            let _dateCE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + _date.getFullYear();

            let _taxId = 'N/A';
            let _taxNumberType = 'OTHR';
            let _branchNumber = '';
            let _branchText = '';

            let _address =
              invoiceData.primary_recipients[0].billing_info.address.address_line_1 +
              ' ' +
              invoiceData.primary_recipients[0].billing_info.address.address_line_2 +
              ' ' +
              invoiceData.primary_recipients[0].billing_info.address.admin_area_2 +
              ' ' +
              invoiceData.primary_recipients[0].billing_info.address.admin_area_1;

            let _postcode = invoiceData.primary_recipients[0].billing_info.address.postal_code;

            let _customer = {
              addressLineOne: _address,
              addressLineTwo: '',
              branchNumber: _branchNumber,
              branchText: _branchText,
              email: invoiceData.primary_recipients[0].billing_info.email_address,
              name: invoiceData.primary_recipients[0].billing_info.business_name,
              phone: invoiceData.primary_recipients[0].billing_info.phones[0].national_number,
              phoneCountryCode: '+66',
              postcode: _postcode ? _postcode : '00000',
              taxId: _taxId,
              taxNumberType: _taxNumberType,
              language: 'th',
              unstructure: true,
            };

            let _items = [];

            for (let i = 0; i < invoiceData.items.length; i++) {
              let _quantity = invoiceData.items[i].quantity * 1;
              let _price = Math.round(invoiceData.items[i].unit_amount.value * 100) / 100;
              let _total = Math.round(_price * _quantity * 100) / 100;

              _items.push({
                number: i + 1 + '',
                description: invoiceData.items[i].name,
                quantity: invoiceData.items[i].quantity * 1,
                unitCode: '',
                unitName: '-',
                price: _price,
                discount: 0,
                total: _total,
                percentVat: invoiceData.items[i].tax ? (invoiceData.items[i].tax.amount.value * 1 > 0 ? percentVat : zeroVat) : zeroVat,
                percentVatText: invoiceData.items[i].tax
                  ? invoiceData.items[i].tax.amount.value * 1 > 0
                    ? percentVatText
                    : zeroVatText
                  : zeroVatText,
              });
            }

            invoiceData.amount.breakdown.shipping &&
              _items.push({
                number: invoiceData.items.length + 1 + '',
                description: 'ค่าขนส่ง',
                quantity: 1,
                unitCode: '',
                unitName: '-',
                price: invoiceData.amount.breakdown.shipping.amount.value * 1,
                discount: 0,
                percentVat: invoiceData.amount.breakdown.shipping.tax
                  ? invoiceData.amount.breakdown.shipping.tax.amount.value * 1 > 0
                    ? percentVat
                    : zeroVat
                  : zeroVat,
                percentVatText: invoiceData.amount.breakdown.shipping.tax
                  ? invoiceData.amount.breakdown.shipping.tax.amount.value * 1 > 0
                    ? percentVatText
                    : zeroVatText
                  : zeroVatText,
                total: invoiceData.amount.breakdown.shipping.amount.value * 1,
              });

            let _discount = invoiceData.amount.breakdown.discount
              ? Math.abs(
                  Number(invoiceData.amount.breakdown.discount.invoice_discount.amount.value) +
                    Number(invoiceData.amount.breakdown.discount.item_discount.value)
                )
              : 0;

            history.push({
              pathname: documentPath,
              state: {
                command: 'EDIT',
                amountTotal: invoiceData.amount.breakdown.item_total.value * 1,
                company: {
                  ...company,
                  includeVat: includeVat,
                },
                customer: _customer,
                date: _date,
                dateBE: _dateBE,
                dateCE: _dateCE,
                discount: _discount * 1,
                grandTotal: invoiceData.amount.value * 1,
                items: _items,
                reference: item.key,
                note: '',
                number: item.number,
                percentVat: percentVat,
                receivedBy: '',
                vatTotal: invoiceData.amount.breakdown.tax_total.value * 1,
                reIssue: false,
                backUrl: '/etax/sources/paypal',
                includeVat: invoiceData.configuration.tax_inclusive,
              },
            });
          });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  useEffect(() => {
    console.log('userEffect initial company call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data[0]) {
                if (companyId && response.data.find(_company => _company.id === companyId)) {
                  const _company = response.data.find(_company => _company.id === companyId)
              
                  setCompany(_company);

                  if (_company.registerVat) {
                      setIncludeVat(true);
                      setRegisterVat(true);
                  } else {
                      setIncludeVat(false);
                      setRegisterVat(false);
                  }
                } else {
                    setCompany(response.data[0]);

                    if (response.data[0].registerVat) {
                        setIncludeVat(true);
                        setRegisterVat(true);
                    } else {
                        setIncludeVat(false);
                        setRegisterVat(false);
                    }

                }
              } else {
                history.push({
                  pathname: '/etax/companies/profiles',
                  state: {},
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getNewDocuments) {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          if (search) {
            console.log('search Running!!!');
            console.log('search:', search, true);

            axios
              .get(API_URL + '/sources/paypal/invoices', {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId
                },
                params: {
                  invoice_number: search,
                },
              })
              .then(async (response) => {
                if (response.data.items.length) {
                  const _number = search;

                  await axios
                    .get(API_URL + `/documents?number=${_number}`, {
                      headers: {
                        Authorization: 'Bearer ' + tokenResponse.accessToken,
                        'Content-Type': 'application/json',
                        'cid': companyId
                    },
                    })
                    .then((responseAllDocs) => {
                      let invoice_list = response.data.items || [];
                      let total_items = response.data.total_items;

                      let _pageDropdown = [];

                      for (let i = 1; i <= Math.ceil(total_items / pageSize); i++) {
                        _pageDropdown.push({
                          key: i,
                          text: 'หน้า ' + i,
                          iconProps: { iconName: 'Page' },
                          onClick: () => {
                            console.log('Selected page: ' + i);
                            setCurrentPage(i);
                          },
                        });
                      }

                      setPageDropdown(_pageDropdown);

                      if (currentPage > Math.ceil(total_items / pageSize)) {
                        setCurrentPage(1);
                      }

                      setPageData(paginate(total_items, 1, pageSize, 10));

                      const _docs = [];

                      for (let i = 0; i < invoice_list.length; i++) {
                        let _documentDate = invoice_list[i].detail.invoice_date.split('-').reverse().join('-');
                        let _createdTime = toThaiDateString(invoice_list[i].detail.metadata.create_time);
                        let _createdTimestamp = invoice_list[i].detail.metadata.create_time;

                        _docs.push({
                          key: invoice_list[i].id,
                          documentDate: _documentDate,
                          createdTime: _createdTime,
                          createdTimestamp: _createdTimestamp,
                          number: invoice_list[i].detail.invoice_number,
                          customerName: invoice_list[i].primary_recipients[0].billing_info.business_name,
                          customerEmail: invoice_list[i].primary_recipients[0].billing_info.email_address,
                          amount: invoice_list[i].amount.value,
                          etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.number === invoice_list[i].detail.invoice_number),
                        });
                      }

                      if (_docs.length > 0) {
                        if (primarySort === 'documentDate') {
                          if (!isSortedDescendingDocumentDate) {
                            _docs.sort(function (a, b) {
                              var aa = a.documentDate.split('-').reverse().join(),
                                bb = b.documentDate.split('-').reverse().join();
                              return aa < bb ? -1 : aa > bb ? 1 : 0;
                            });
                          } else {
                            _docs.sort(function (a, b) {
                              var aa = a.documentDate.split('-').reverse().join(),
                                bb = b.documentDate.split('-').reverse().join();
                              return aa < bb ? 1 : aa > bb ? -1 : 0;
                            });
                          }
                        } else if (primarySort === 'number') {
                          if (isSortedDescendingNumber) {
                            _docs.sort((a, b) => {
                              return b.number - a.number;
                            });
                          } else {
                            _docs.sort((a, b) => {
                              return a.number - b.number;
                            });
                          }
                        } else if (primarySort === 'customerName') {
                          if (isSortedDescendingCustomerName) {
                            _docs.sort((a, b) => {
                              if (b.customerName < a.customerName) {
                                return -1;
                              }
                              if (b.customerName > a.customerName) {
                                return 1;
                              }
                              return 0;
                            });
                          } else {
                            _docs.sort((a, b) => {
                              if (a.customerName < b.customerName) {
                                return -1;
                              }
                              if (a.customerName > b.customerName) {
                                return 1;
                              }
                              return 0;
                            });
                          }
                        } else if (primarySort === 'grandTotal') {
                          if (isSortedDescendingGrandTotal) {
                            _docs.sort(function (a, b) {
                              return b.amount - a.amount;
                            });
                          } else {
                            _docs.sort(function (a, b) {
                              return a.amount - b.amount;
                            });
                          }
                        }
                      }

                      setItems(_docs);

                      setLoadDataComplete(true);
                      setGetNewDocuments(false);
                      setIsCreateDocumentComplete(false);
                    });
                } else {
                  setItems([]);

                  setLoadDataComplete(true);
                  setGetNewDocuments(false);
                  setIsCreateDocumentComplete(false);
                }
              })
              .catch((error) => {
                setItems([]);

                setLoadDataComplete(true);
                setGetNewDocuments(false);
                setIsCreateDocumentComplete(false);
              });
          } else {
            console.log('getNewOrder Running!!!');

            const [response, responseAllDocs] = await Promise.all([
              axios
                .get(API_URL + '/sources/paypal/invoices', {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    'cid': companyId
                  },
                  params: {
                    page: currentPage,
                    page_size: pageSize,
                    from: from,
                    to: to,
                    status: documentType.key,
                  },
                })
                .catch((error) => console.log(error)),

              axios
                .get(API_URL + '/etax/documents/ALL/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    'cid': companyId
                  },
                })
                .catch((error) => console.log(error)),
            ]);

            console.log('Paypal list invoice: ', response);
            console.log('responseAllDocs', responseAllDocs);
            let invoice_list = response.data.items || [];
            let total_items = response.data.total_items;

            let _pageDropdown = [];

            for (let i = 1; i <= Math.ceil(total_items / pageSize); i++) {
              _pageDropdown.push({
                key: i,
                text: 'หน้า ' + i,
                iconProps: { iconName: 'Page' },
                onClick: () => {
                  console.log('Selected page: ' + i);
                  setCurrentPage(i);
                },
              });
            }

            setPageDropdown(_pageDropdown);

            if (currentPage > Math.ceil(total_items / pageSize)) {
              setCurrentPage(1);
            }

            setPageData(paginate(total_items, 1, pageSize, 10));

            const _docs = [];

            for (let i = 0; i < invoice_list.length; i++) {
              let _documentDate = invoice_list[i].detail.invoice_date.split('-').reverse().join('-');
              let _createdTime = toThaiDateString(invoice_list[i].detail.metadata.create_time);
              let _createdTimestamp = invoice_list[i].detail.metadata.create_time;

              _docs.push({
                key: invoice_list[i].id,
                documentDate: _documentDate,
                createdTime: _createdTime,
                createdTimestamp: _createdTimestamp,
                number: invoice_list[i].detail.invoice_number,
                customerName: invoice_list[i].primary_recipients[0].billing_info.business_name,
                customerEmail: invoice_list[i].primary_recipients[0].billing_info.email_address,
                amount: invoice_list[i].amount.value,
                etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.number === invoice_list[i].detail.invoice_number),
              });
            }

            if (_docs.length > 0) {
              if (primarySort === 'documentDate') {
                if (!isSortedDescendingDocumentDate) {
                  _docs.sort(function (a, b) {
                    var aa = a.documentDate.split('-').reverse().join(),
                      bb = b.documentDate.split('-').reverse().join();
                    return aa < bb ? -1 : aa > bb ? 1 : 0;
                  });
                } else {
                  _docs.sort(function (a, b) {
                    var aa = a.documentDate.split('-').reverse().join(),
                      bb = b.documentDate.split('-').reverse().join();
                    return aa < bb ? 1 : aa > bb ? -1 : 0;
                  });
                }
              } else if (primarySort === 'number') {
                if (isSortedDescendingNumber) {
                  _docs.sort((a, b) => {
                    return b.number - a.number;
                  });
                } else {
                  _docs.sort((a, b) => {
                    return a.number - b.number;
                  });
                }
              } else if (primarySort === 'customerName') {
                if (isSortedDescendingCustomerName) {
                  _docs.sort((a, b) => {
                    if (b.customerName < a.customerName) {
                      return -1;
                    }
                    if (b.customerName > a.customerName) {
                      return 1;
                    }
                    return 0;
                  });
                } else {
                  _docs.sort((a, b) => {
                    if (a.customerName < b.customerName) {
                      return -1;
                    }
                    if (a.customerName > b.customerName) {
                      return 1;
                    }
                    return 0;
                  });
                }
              } else if (primarySort === 'grandTotal') {
                if (isSortedDescendingGrandTotal) {
                  _docs.sort(function (a, b) {
                    return b.amount - a.amount;
                  });
                } else {
                  _docs.sort(function (a, b) {
                    return a.amount - b.amount;
                  });
                }
              }
            }

            setItems(_docs);

            setLoadDataComplete(true);
            setGetNewDocuments(false);
            setIsCreateDocumentComplete(false);
          }
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          //   instance.acquireTokenRedirect(silentRequest);
          disconnect(true);

          //   setLoadDataComplete(true);
          //   setGetNewDocuments(false);
          //   setIsCreateDocumentComplete(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments]);

  useEffect(() => {
    if (company && company.name) {
      setLoadDataComplete(false);

      setItems([]);
      setGetNewDocuments(true);
    }

    sessionStorage.setItem('paypal-currentPage', currentPage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    console.log('isCreateDocumentComplete useEffect: ', isCreateDocumentComplete);

    if (loadDataComplete && !isCreateDocument) {
      let _items = selection.getItems();
      console.log('_items: ', _items);

      let _selectedItems = selection.getSelection();

      if (isCreateDocumentComplete) {
        setIsCreateDocumentComplete(false);

        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status !== '') {
            _items[i].status = '';
          }
        }
      } else {
        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status !== '') {
            _items[i].status = '';
          }
        }

        for (let i = 0; i < _selectedItems.length; i++) {
          console.log('select item key: ', _selectedItems[i].key);

          for (let j = 0; j < _items.length; j++) {
            if (_items[j].key === _selectedItems[i].key) {
              console.log('set pending item key: ', _items[j].key);
              _items[j].status = 'pending';
              console.log('_items: ', _items);
            }
          }
        }
      }

      setItems(_items);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  const columns = [
    {
      key: 'column1',
      name: t('paypal.createdTime'),
      fieldName: 'createdTime',
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isSorted: primarySort === 'createdTime' ? true : false,
      isSortedDescending: isSortedDescendingCreatedTime,
      isSortedAscending: !isSortedDescendingCreatedTime,
      onColumnClick: () => {
        console.log('Sort document createTime!');
        setPrimarySort('createdTime');
        setIsSortedDescendingCreatedTime(!isSortedDescendingCreatedTime);

        sessionStorage.setItem('paypal-primarySort', 'createdTime');
        sessionStorage.setItem('paypal-sort-createdTime', !isSortedDescendingCreatedTime === false ? 'false' : 'true');

        sessionStorage.removeItem('paypal-sort-documentDate');
        sessionStorage.removeItem('paypal-sort-number');
        sessionStorage.removeItem('paypal-sort-customerName');
        sessionStorage.removeItem('paypal-sort-vatTotal');
        sessionStorage.removeItem('paypal-sort-grandTotal');

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'number',
      onRender: (item) => {
        return <span>{item.createdTime}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column2',
      name: t('paypal.number'),
      fieldName: 'number',
      minWidth: 50,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'number' ? true : false,
      isSortedDescending: isSortedDescendingNumber,
      isSortedAscending: !isSortedDescendingNumber,
      onColumnClick: () => {
        console.log('Sort document number!');
        setPrimarySort('number');
        setIsSortedDescendingNumber(!isSortedDescendingNumber);

        sessionStorage.setItem('paypal-primarySort', 'number');
        sessionStorage.setItem('paypal-sort-number', !isSortedDescendingNumber === false ? 'false' : 'true');

        sessionStorage.removeItem('paypal-sort-createdTime');
        sessionStorage.removeItem('paypal-sort-documentDate');
        sessionStorage.removeItem('paypal-sort-customerName');
        sessionStorage.removeItem('paypal-sort-vatTotal');
        sessionStorage.removeItem('paypal-sort-grandTotal');

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.number}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column3',
      name: t('paypal.documentDate'),
      fieldName: 'documentDate',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isSorted: primarySort === 'documentDate' ? true : false,
      isSortedDescending: isSortedDescendingDocumentDate,
      isSortedAscending: !isSortedDescendingDocumentDate,
      onColumnClick: () => {
        console.log('Sort document createTime!');
        setPrimarySort('documentDate');
        setIsSortedDescendingDocumentDate(!isSortedDescendingDocumentDate);

        sessionStorage.setItem('paypal-primarySort', 'documentDate');
        sessionStorage.setItem('paypal-sort-documentDate', !isSortedDescendingDocumentDate === false ? 'false' : 'true');

        sessionStorage.removeItem('paypal-sort-createdTime');
        sessionStorage.removeItem('paypal-sort-number');
        sessionStorage.removeItem('paypal-sort-customerName');
        sessionStorage.removeItem('paypal-sort-vatTotal');
        sessionStorage.removeItem('paypal-sort-grandTotal');

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'number',
      onRender: (item) => {
        return <span>{item.documentDate}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column4',
      name: t('paypal.customerName'),
      fieldName: 'customerName',
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      isSorted: primarySort === 'customerName' ? true : false,
      isSortedDescending: isSortedDescendingCustomerName,
      isSortedAscending: !isSortedDescendingCustomerName,
      onColumnClick: () => {
        console.log('Sort document customerName!');
        setPrimarySort('customerName');
        setIsSortedDescendingCustomerName(!isSortedDescendingCustomerName);

        sessionStorage.setItem('paypal-primarySort', 'customerName');
        sessionStorage.setItem('paypal-sort-customerName', !isSortedDescendingCustomerName === false ? 'false' : 'true');

        sessionStorage.removeItem('paypal-sort-createdTime');
        sessionStorage.removeItem('paypal-sort-documentDate');
        sessionStorage.removeItem('paypal-sort-number');
        sessionStorage.removeItem('paypal-sort-vatTotal');
        sessionStorage.removeItem('paypal-sort-grandTotal');

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerName}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column5',
      name: t('paypal.customerEmail'),
      fieldName: 'customerEmail',
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerEmail}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column6',
      name: t('paypal.grandTotal'),
      fieldName: 'grandTotal',
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isSorted: primarySort === 'grandTotal' ? true : false,
      isSortedDescending: isSortedDescendingGrandTotal,
      isSortedAscending: !isSortedDescendingGrandTotal,
      onColumnClick: () => {
        console.log('Sort document grandTotal!');
        setPrimarySort('grandTotal');
        setIsSortedDescendingGrandTotal(!isSortedDescendingGrandTotal);

        sessionStorage.setItem('paypal-primarySort', 'grandTotal');
        sessionStorage.setItem('paypal-sort-grandTotal', !isSortedDescendingGrandTotal === false ? 'false' : 'true');

        sessionStorage.removeItem('paypal-sort-createdTime');
        sessionStorage.removeItem('paypal-sort-customerName');
        sessionStorage.removeItem('paypal-sort-number');
        sessionStorage.removeItem('paypal-sort-vatTotal');
        sessionStorage.removeItem('paypal-sort-documentDate');

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <span style={{ display: 'block', textAlign: 'right' }}>{item.amount}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column9',
      name: 'Leceipt',
      fieldName: 'etaxCreated',
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        if (item.etaxCreated) {
          return (
            <div style={{ textAlign: 'center' }}>
              <Icon iconName="SkypeCircleCheck" style={{ fontSize: '20px', color: 'green' }} />
            </div>
          );
        }
      },
    },
    {
      key: 'column10',
      name: '',
      fieldName: 'command',
      minWidth: 120,
      maxWidth: 120,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        if (isCreateDocument || isCreateDocumentComplete) {
          if (item.status === 'pending') {
            return <span style={{ color: '#106EBE' }}>{t('paypal.pending')}</span>;
          } else if (item.status === 'processing') {
            return <Spinner label={t('paypal.processing')} labelPosition="right" style={{ marginLeft: '0px' }} />;
          } else if (item.status === 'successful') {
            return <span style={{ color: 'green' }}>{t('paypal.success')}</span>;
          } else if (item.status === 'fail') {
            return <span style={{ color: 'red' }}>{t('paypal.fail')}</span>;
          }
        } else {
          return (
            <span data-selection-disabled={true}>
              <DefaultButton
                text={t('paypal.create')}
                iconProps={{ iconName: 'Add', style: { color: '#106ebe' } }}
                menuProps={{
                  items: [
                    {
                      key: 'RECEIPT-TAXINVOICE',
                      text: t('paypal.receipt-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('RECEIPT-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'RECEIPT-TAXINVOICE-ABB',
                      text: t('paypal.receipt-taxinvoice-abb'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('RECEIPT-TAXINVOICE-ABB', item);
                      },
                    },
                    {
                      key: 'RECEIPT',
                      text: t('paypal.receipt'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('RECEIPT', item);
                      },
                    },
                    {
                      key: 'INVOICE-TAXINVOICE',
                      text: t('paypal.invoice-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('INVOICE-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'DELIVERYORDER-TAXINVOICE',
                      text: t('paypal.deliveryorder-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('DELIVERYORDER-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'TAXINVOICE',
                      text: t('paypal.taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('TAXINVOICE', item);
                      },
                    },
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
            </span>
          );
        }
      },
    },
  ];

  const disconnect = (_confirm) => {
    let confirm = _confirm;

    if (confirm) {
      setIsLoading(true);

      instance.acquireTokenSilent({ ...silentRequest, account: account }).then(
        (tokenResponse) => {
          axios
            .patch(
              API_URL + '/sources',
              {
                source: 'paypal',
                replace: '/disabled',
                value: true,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId
                },
              }
            )
            .then((response) => {
              console.log(response);

              history.push({
                pathname: '/etax/sources/paypal/connect',
                state: {},
              });

              setIsLoading(false);
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
              instance.acquireTokenRedirect(silentRequest);
            });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  const createSelectedDocuments = async (documentType) => {
    let _selectionItems = selection.getItems();

    let postUrl = '';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/receipts-taxinvoices/pdf-xml';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        postUrl = API_URL + '/etax/documents/receipts-taxinvoices-abb/pdf';
        break;
      case 'RECEIPT':
        postUrl = API_URL + '/etax/documents/receipts/pdf-xml';
        break;
      case 'INVOICE-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/invoices-taxinvoices/pdf-xml';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/deliveryorders-taxinvoices/pdf-xml';
        break;
      case 'TAXINVOICE':
        postUrl = API_URL + '/etax/documents/taxinvoices/pdf-xml';
        break;
      default:
        break;
    }

    if (postUrl) {
      await instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          for (let i = 0; i < selectedItems.length; i++) {
            console.log('create selected document: ', selectedItems[i]);

            for (let j = 0; j < _selectionItems.length; j++) {
              if (_selectionItems[j].key === selectedItems[i].key) {
                console.log('item key: ', _selectionItems[j].key);
                _selectionItems[j].status = 'processing';

                console.log('items: ', _selectionItems);
                setItems([..._selectionItems]);

                const [response_detail] = await Promise.all([
                  axios
                    .get(API_URL + '/sources/paypal/invoices/' + selectedItems[i].key, {
                      headers: {
                        Authorization: 'Bearer ' + tokenResponse.accessToken,
                        'cid': companyId
                    },
                    })
                    .catch((error) => console.log(error)),
                ]);

                console.log('response_detail', response_detail);
                let invoiceData = response_detail.data;

                let d = selectedItems[i].documentDate.split('-');

                let _date = new Date(d[2] + '/' + d[1] + '/' + d[0]);

                let _dateBE =
                  `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + (_date.getFullYear() + 543);
                let _dateCE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + _date.getFullYear();

                let _taxId = 'N/A';
                let _taxNumberType = 'OTHR';
                let _branchNumber = '';
                let _branchText = '';

                let _address =
                  invoiceData.primary_recipients[0].billing_info.address.address_line_1 +
                  ' ' +
                  invoiceData.primary_recipients[0].billing_info.address.address_line_2 +
                  ' ' +
                  invoiceData.primary_recipients[0].billing_info.address.admin_area_2 +
                  ' ' +
                  invoiceData.primary_recipients[0].billing_info.address.admin_area_1;

                let _postcode = invoiceData.primary_recipients[0].billing_info.address.postal_code;

                let _customer = {
                  addressLineOne: _address,
                  addressLineTwo: '',
                  branchNumber: _branchNumber,
                  branchText: _branchText,
                  email: invoiceData.primary_recipients[0].billing_info.email_address,
                  name: invoiceData.primary_recipients[0].billing_info.business_name,
                  phone: invoiceData.primary_recipients[0].billing_info.phones[0].national_number,
                  phoneCountryCode: '+66',
                  postcode: _postcode ? _postcode : '00000',
                  taxId: _taxId,
                  taxNumberType: _taxNumberType,
                  language: 'th',
                  unstructure: true,
                };

                let _items = [];

                for (let i = 0; i < invoiceData.items.length; i++) {
                  let _quantity = invoiceData.items[i].quantity * 1;
                  let _price = Math.round(invoiceData.items[i].unit_amount.value * 100) / 100;
                  let _total = Math.round(_price * _quantity * 100) / 100;

                  _items.push({
                    number: i + 1 + '',
                    description: invoiceData.items[i].name,
                    quantity: invoiceData.items[i].quantity * 1,
                    unitCode: '',
                    unitName: '-',
                    price: _price,
                    discount: 0,
                    total: _total,
                    percentVat: invoiceData.items[i].tax ? (invoiceData.items[i].tax.amount.value * 1 > 0 ? percentVat : zeroVat) : zeroVat,
                    percentVatText: invoiceData.items[i].tax
                      ? invoiceData.items[i].tax.amount.value * 1 > 0
                        ? percentVatText
                        : zeroVatText
                      : zeroVatText,
                  });
                }

                invoiceData.amount.breakdown.shipping &&
                  _items.push({
                    number: invoiceData.items.length + 1 + '',
                    description: 'ค่าขนส่ง',
                    quantity: 1,
                    unitCode: '',
                    unitName: '-',
                    price: invoiceData.amount.breakdown.shipping.amount.value * 1,
                    discount: 0,
                    percentVat: invoiceData.amount.breakdown.shipping.tax
                      ? invoiceData.amount.breakdown.shipping.tax.amount.value * 1 > 0
                        ? percentVat
                        : zeroVat
                      : zeroVat,
                    percentVatText: invoiceData.amount.breakdown.shipping.tax
                      ? invoiceData.amount.breakdown.shipping.tax.amount.value * 1 > 0
                        ? percentVatText
                        : zeroVatText
                      : zeroVatText,
                    total: invoiceData.amount.breakdown.shipping.amount.value * 1,
                  });

                let _discount = invoiceData.amount.breakdown.discount
                  ? Math.abs(
                      Number(invoiceData.amount.breakdown.discount.invoice_discount.amount.value) +
                        Number(invoiceData.amount.breakdown.discount.item_discount.value)
                    )
                  : 0;

                let _vatPriceTotal = 0;
                let _zeroVatPriceTotal = 0;
                let _nonVatPriceTotal = 0;

                _items.forEach((item) => {
                  if (item.percentVat === percentVat) {
                    _vatPriceTotal = _vatPriceTotal + item.total;
                  } else if (item.percentVat === zeroVat) {
                    _zeroVatPriceTotal = _zeroVatPriceTotal + item.total;
                  }
                });

                const promise_PDF_XML = await new Promise(async (resolve) => {
                  await axios
                    .post(
                      postUrl,
                      {
                        amountTotal: invoiceData.amount.breakdown.item_total.value * 1,
                        company: {
                          ...company,
                          includeVat: includeVat,
                        },
                        customer: _customer,
                        date: _date,
                        dateBE: _dateBE,
                        dateCE: _dateCE,
                        discount: _discount * 1,
                        grandTotal: invoiceData.amount.value * 1,
                        nonVatPriceTotal: _nonVatPriceTotal,
                        zeroVatPriceTotal: _zeroVatPriceTotal,
                        vatPriceTotal: _vatPriceTotal,
                        items: _items,
                        percentVat: registerVat ? percentVat : 0,
                        receivedBy: createdBy,
                        createdBy: createdBy,
                        vatTotal: invoiceData.amount.breakdown.tax_total.value * 1,
                        reference: selectedItems[i].key,
                        reIssue: false,
                        includeVat: invoiceData.configuration.tax_inclusive,
                        number: selectedItems[i].number,
                        note: '',
                      },
                      {
                        headers: {
                          Authorization: 'Bearer ' + tokenResponse.accessToken,
                          'cid': companyId
                        },
                      }
                    )
                    .then(
                      async (response) => {
                        console.log('Create document response: ', response);

                        let count = 0;

                        let interval = await setInterval(async () => {
                          await axios
                            .get(`${API_URL}/etax/jobs/${response.data.id}`, {
                              headers: {
                                Authorization: 'Bearer ' + tokenResponse.accessToken,
                                'Content-Type': 'application/json',
                                'cid': companyId
                            },
                            })
                            .then((jobsResponse) => {
                              count += 1;
                              console.log('Jobs response: ', j, jobsResponse.data.status, count);

                              if (jobsResponse.data.status === 'complete') {
                                _selectionItems[j].status = 'successful';
                                _selectionItems[j].etaxCreated = true;

                                setItems([..._selectionItems]);

                                clearInterval(interval);

                                resolve(true);
                              } else if (count >= 300) {
                                _selectionItems[j].status = 'fail';

                                setItems([..._selectionItems]);

                                clearInterval(interval);

                                resolve(false);
                              }
                            });
                        }, 1000);
                      },
                      (error) => {
                        console.log('Error Create document response: ', error);
                        _selectionItems[j].status = 'fail';
                        setItems([..._selectionItems]);
                        resolve(false);
                      }
                    );
                }); //promise

                Promise.all([promise_PDF_XML]).then((value) => {
                  console.log(i, value);
                });
              } //if
            } //for j
          } //for i
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });

      setIsCreateDocumentComplete(true);
      setIsCreateDocument(false);
    }

    setIsCreateDocumentComplete(true);
    setIsCreateDocument(false);
  };

  return (
    <div style={homeStyles}>
      {!isLoading && (
        <div>
          <Stack>
            <center>
              <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('paypal.header')}</h2>
              {!loadDataComplete && (
                <div>
                  <Spinner
                    label={t('paypal.loading')}
                    labelPosition="right"
                    style={{
                      marginTop: '21px',
                      marginBottom: '2px',
                    }}
                  />
                  <br />
                </div>
              )}
            </center>
          </Stack>

          {(selectedItems.length > 0 || isCreateDocument || isCreateDocumentComplete) && (
            <Stack vertical tokens={{ childrenGap: '10px' }}>
              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                <TextField
                  name="createdBy"
                  label={t('paypal.createdBy')}
                  description={t('paypal.createdByDesc')}
                  styles={{ root: { width: 200 } }}
                  onChange={(e) => {
                    setCreatedBy(e.target.value);
                  }}
                  value={createdBy}
                  disabled={isCreateDocument}
                />
              </Stack>
              <br />
            </Stack>
          )}

          {selectedItems.length > 0 && !isCreateDocument && (
            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
              <PrimaryButton
                text={t('paypal.createDocument')}
                iconProps={{ iconName: 'Add' }}
                menuProps={{
                  items: [
                    {
                      key: 'RECEIPT-TAXINVOICE',
                      text: t('paypal.receipt-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async (event) => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('RECEIPT-TAXINVOICE');
                      },
                    },
                    {
                      key: 'RECEIPT-TAXINVOICE-ABB',
                      text: t('paypal.receipt-taxinvoice-abb'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async (event) => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('RECEIPT-TAXINVOICE-ABB');
                      },
                    },
                    {
                      key: 'RECEIPT',
                      text: t('paypal.receipt'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async (event) => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('RECEIPT');
                      },
                    },
                    {
                      key: 'INVOICE-TAXINVOICE',
                      text: t('paypal.invoice-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async (event) => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('INVOICE-TAXINVOICE');
                      },
                    },
                    {
                      key: 'DELIVERYORDER-TAXINVOICE',
                      text: t('paypal.deliveryorder-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async (event) => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('DELIVERYORDER-TAXINVOICE');
                      },
                    },
                    {
                      key: 'TAXINVOICE',
                      text: t('paypal.taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async (event) => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('TAXINVOICE');
                      },
                    },
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
              <DefaultButton
                text={t('paypal.cancel')}
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  let _items = selection.getItems();
                  let _selectedItems = selection.getSelection();
                  console.log('selection: ', selection.getSelection());

                  for (let i = 0; i < _selectedItems.length; i++) {
                    console.log('select item key: ', _selectedItems[i].key);
                    _selectedItems[i].status = '';

                    for (let j = 0; j < _items.length; j++) {
                      if (_items[j].key === _selectedItems[i].key) {
                        console.log('item key: ', _items[j].key);
                        _items[j].status = '';
                        _selectedItems[i].status = '';
                      }
                    }
                  }

                  selection.setAllSelected(false);

                  setItems(_items);
                  console.log('selection: ', selection.getSelection());
                }}
              />
              <br />
            </Stack>
          )}

          {isCreateDocument && (
            <div>
              <Spinner label={t('paypal.signProcessing')} size={SpinnerSize.large} />
            </div>
          )}

          {isCreateDocumentComplete && (
            <center>
              <PrimaryButton
                text={t('paypal.createdDocuments')}
                iconProps={{ iconName: 'Documentation' }}
                onClick={async () => {
                  console.log(selectedItems);
                  history.push({
                    pathname: '/etax/documents/all',
                    state: {},
                  });
                }}
              />
            </center>
          )}

          <br />

          <Stack horizontal horizontalAlign="center">
            <Stack
              vertical
              style={{
                width: '98%',
                minWidth: '800px',
                maxWidth: '1350px',
              }}
            >
              <Stack 
                horizontal 
                styles={{
                  root: {
                    backgroundColor: 'white',
                  },
                }}
                horizontalAlign="space-between"
              >
                <Stack
                  styles={{
                    root: {
                      width: 'calc(100vw - 700px)',
                      minWidth: '700px',
                      maxWidth: '900px',
                    },
                  }}
                >
                  <CommandBar items={commandBarItems} overflowItems={overflowItems} />
                </Stack>
                <Stack
                  horizontal
                  styles={{
                    root: {
                      height: 44,
                    },
                  }}
                  tokens={{ childrenGap: '10px' }}
                >
                  <SearchBox
                    className="ms-borderColor-themePrimary"
                    styles={{
                      root: {
                        marginTop: 6,
                        width: 270,
                        fontSize: 13,
                        fontWeight: 0,
                      },
                    }}
                    disabled={!loadDataComplete}
                    placeholder={t('paypal.searchBoxPlaceholder')}
                    onChange={(e) => {
                      setSearch(e ? e.target.value : '');
                    }}
                    onClear={() => {
                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                    onSearch={() => {
                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                    value={search}
                  />
                  <PrimaryButton
                    disabled={!loadDataComplete || !search}
                    styles={{
                      root: {
                        marginTop: 6,
                        marginRight: 18,
                        width: 90,
                      },
                    }}
                    text={t('paypal.search')}
                    onClick={() => {
                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                  />
                </Stack>
              </Stack>
              <ShimmeredDetailsList
                items={items || []}
                enableShimmer={!loadDataComplete}
                compact={isCompactMode}
                columns={columns}
                selection={selection}
                selectionMode={!loadDataComplete || isCreateDocument ? SelectionMode.none : SelectionMode.multiple}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                checkboxVisibility={CheckboxVisibility.always}
                selectionPreservedOnEmptyClick={true}
              />
            </Stack>
          </Stack>
          <br />
          <center>
            {!(currentPage === 1 && items.length < pageSize) && !(!loadDataComplete || isCreateDocument) && (
              <Pagination
                selectedPageIndex={currentPage - 1}
                pageCount={pageData.totalPages}
                onPageChange={onPageChange}
                format
                firstPageIconProps={{
                  iconName: 'DoubleChevronLeft',
                }}
                previousPageIconProps={{
                  iconName: 'ChevronLeft',
                }}
                nextPageIconProps={{
                  iconName: 'ChevronRight',
                }}
                lastPageIconProps={{
                  iconName: 'DoubleChevronRight',
                }}
              />
            )}

            <br />
            <br />
          </center>
        </div>
      )}

      {isLoading && (
        <div>
          <Stack>
            <center>
              <h2
                style={{
                  marginLeft: '0px',
                  marginTop: '25px',
                  marginBottom: '50px',
                }}
              >
                {t('paypal.footer')}
              </h2>
            </center>
            <Stack horizontal horizontalAlign="center">
              <br />
              <br />
              <div>
                <Spinner label={t('paypal.processing2')} labelPosition="down" styles={spinnerStyles} />
              </div>
              <br />
            </Stack>
          </Stack>
        </div>
      )}
      <Dialog
        hidden={!dialogConfirm.isClick}
        onDismiss={dialogConfirm.onCancel}
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: dialogConfirm.header,
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 }, subText: { textAlign: 'center' } },
          subText: dialogConfirm.message,
        }}
      >
        <DialogFooter styles={{ actionsRight: { textAlign: 'center' } }}>
          <PrimaryButton onClick={dialogConfirm.onConfirm} text={t('documents.confirm')} />
          <DefaultButton onClick={dialogConfirm.onCancel} text={t('documents.cancel')} />
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default Paypal;
