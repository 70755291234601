import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../authConfig";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./ProductStyles.css";

import {
  TextField,
  PrimaryButton,
  Dropdown,
  Stack,
  getTheme,
  Icon,
  Spinner,
  MessageBar,
  MessageBarType,
  Toggle,
  IconButton,
  DefaultButton,
  Image,
  Text,
  Separator,
  ImageFit,
} from "@fluentui/react";

import { getUnitList } from "../../providers/UnitData";
import { categoryList } from "../../providers/CategoryData";

const API_URL = process.env.REACT_APP_API_URL;
const REACT_APP_IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const theme = getTheme();

const cardStyle = {
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 20,
  paddingBottom: 30,
  background: "#ffffff",
  marginLeft: "0",
  marginTop: "10px",
  boxShadow: theme.effects.elevation4,
  width: 990,
};

const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const centerItem = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const _categoryList = categoryList

const ImageCard = ({ _img, index, onDeleteImage, onImageChange, imageLabel }) => {
  const [hovered, setHovered] = useState(false)
  const [canChange, setCanChange] = useState(true)

  function remove(e) {
    setCanChange(false)
    onDeleteImage(e, index)
    
    setTimeout(() => {
      setCanChange(true)
    }, 1000);
  }

  return <div 
          
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          >
  <label style={{ height: 100, width: 100, border: "1px dotted black", display: "flex", justifyContent: "center", alignItems: "center", position: 'relative'}}>
    {_img ? (
      <>
        <>
          <Image imageFit={ImageFit.contain} src={_img.includes('blob:') ? _img : REACT_APP_IMAGE_URL + '/products/' + _img} width={100} height={100} className="container" />
          <input type="file" accept="image/*" style={{ display: "none"}} onChange={e => onImageChange(e, index)} />
        </>
        {hovered && (
          <IconButton styles={{ root: { position: 'absolute', right: 0, top: 0 } }} iconProps={{ iconName: 'Cancel' }} onClick={remove} />
          
        )}
      </>
    ) : (
      <>
        <Icon iconName="Add" styles={{ root: { fontSize: 30 }}} />
        <input type="file" accept="image/*" style={{ display: "none"}} disabled={!canChange} onChange={e => onImageChange(e, index)} />
      </>
    )}
  </label>
  <div style={{ height: 30, width: 100, display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#F6F6F6", border: "1px solid #F6F6F6"}}>
    {imageLabel}
  </div>
</div>
}

const AddProducts = () => {
  console.log("Re render");

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const history = useHistory();

  const { t, i18n } = useTranslation();

  const location = useLocation();

  const data = location.state

  const percentVat = 7;
  const zeroVat = 0;
  const nonVat = -1;

  const percentVatText = "7%";
  const zeroVatText = "0%";
  const nonVatText = "-";

  const buttonClick = false

  const companyId = sessionStorage.getItem("companyId") || "";

  // const [allProduct, setAllProduct] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showMessageBarSuccess, setShowMessageBarSuccess] = useState(false);

  const [product, setProduct] = useState({
    id: "",
    name: "",
    status: "active",
    description: "",
    brand: "",
    category: {
      code: "",
      name: "",
      rdCode: ""
    },
    images: [],
    createTime: ""
  });

  const [errorMessageVariant, setErrorMessageVariant] = useState([{
    price: "", available: "", sellerSku: ""}])

  const unitList = getUnitList("th");

  const [variantEnable, setVariantEnable] = useState(false);
  const [variations, setVariations] = useState([]);
  const [skus, setSkus] = useState([{
    id: "",
    sellerSku: "",
    shopSku: "",
    barcode: "",
    variation: [],
    price: "",
    reserved: "",
    available: "",
    unitCode: "",
    unitName: "-",
    discount: "",
    percentVat: percentVat,
    percentVatText: percentVatText,
    status: "active",
    image: ""
  }]);

  const [enableBatchEdit, setEnableBatchEdit] = useState(false)
  const [batchEditValue, setBatchEditValue] = useState({
    option1: "all",
    option2: "all",
    price: "",
    discount: "",
    available: "",
    sellerSku: "",
    unitCode: "",
    unitName: "-",
    percentVat: percentVat,
    percentVatText: percentVatText
  })

  const dropdownRef = React.createRef();
  const onSetFocus = () => {
    dropdownRef.current.focus(true)
  }

  const [showCategoryError, setShowCategoryError] = useState(false)
  const backUrl = '/products'

  const [showError, setShowError] = useState(false)

  function handleEnableVariant(e, checked) {
    setVariantEnable(checked)
    setVariations([
      {
        name: "",
        values: [""],
      },
    ])
    setVariantImages([""])
    setVariantImagesData([""])
  }

  function handleEnableVariant2() {
    const newVariantOption = [...variations]
    newVariantOption.push({
      name: "",
      values: [""],
    })

    setVariations(newVariantOption)
  }

  function handleVariant(e, index) {
    const { value, id } = e.target

    if (id === "name") {
      const newVariantOption = [...variations]
      newVariantOption[index][id] = value

      setVariations(newVariantOption)
    }
  }

  function handleDeleteVariant(e, index) {
    const newVariantOption = [...variations]
    newVariantOption.splice(index, 1);

    if (!newVariantOption.length) {
      setVariantEnable(false)
      setEnableBatchEdit(false)
    }
    setVariations(newVariantOption)
    variantsArrayChange(newVariantOption)

    if (newVariantOption.length) {
      const _newImageVariantData = []
      const _newImageVariant = []
      newVariantOption[0].values.forEach(_variant => {
        _newImageVariantData.push("")
        _newImageVariant.push("")
      })
  
      setVariantImages(_newImageVariant)
      setVariantImagesData(_newImageVariantData)
    }
  }

  function handleVariantValue(e, index, indexOfValue) {
    const { value } = e.target

    const newVariantOption = [...variations]
    if (indexOfValue !== null || indexOfValue !== undefined) {
      newVariantOption[index].values[indexOfValue] = value
      if (newVariantOption[index].values[indexOfValue + 1] === undefined) {
        newVariantOption[index].values.push("")
      }
    }
    
    if (index === 0 && variantImagesData.length !== newVariantOption[index].values.length - 1) {
      const _newImageVariant = [...variantImagesData]
      while (_newImageVariant.length !== newVariantOption[index].values.length - 1) {
        _newImageVariant.push("")
      }
      setVariantImagesData(_newImageVariant)
    }

    setVariations(newVariantOption)
    variantsArrayChange(newVariantOption)

  }

  function variantsArrayChange(newVariantOptions) {
    if (newVariantOptions.length === 2) {
      const newValue = [];
      
      const _mainVariantValues = newVariantOptions[0].values.filter(_value => _value)
      const _subVariantValues = newVariantOptions[1].values.filter(_value => _value)

      _mainVariantValues.forEach((_mainVariantValue) => {
        _subVariantValues.forEach((_subVariantValue) => {
              newValue.push({
                id: "",
                sellerSku: "",
                shopSku: "",
                barcode: "",
                variation: [
                  {
                    name: newVariantOptions[0].name,
                    value: _mainVariantValue,
                  },
                  {
                    name: newVariantOptions[1].name,
                    value: _subVariantValue,
                  },
                ], 
                price: "",
                reserved: "",
                available: "",
                unitCode: "",
                unitName: "-",
                discount: "",
                percentVat: percentVat,
                percentVatText: percentVatText,
                image: "",
                status: "active",
              })

            }
          );

        
      });

      const oldVariant = [...skus]

      newValue.forEach((_newVariant, index) => {
        oldVariant.forEach(((_oldVariant, index) => {
          const { variation: optionsNewVariant } = _newVariant
          const { variation: optionsOldVariant } = _oldVariant

          if (optionsOldVariant.length === 2 && optionsNewVariant[0].name === optionsOldVariant[0].name &&
              optionsNewVariant[1].name === optionsOldVariant[1].name &&
              optionsNewVariant[0].value === optionsOldVariant[0].value &&
              optionsNewVariant[1].value === optionsOldVariant[1].value) {
                Object.keys(_newVariant).forEach(key => {
                  _newVariant[key] = _oldVariant[key]
                })
              }

        }))
      })

      setSkus(newValue);

      const newErrorMessageVariant = []
      newValue.forEach(_newValue => newErrorMessageVariant.push({
        price: "", available: "", sellerSku: ""
      }))
      setErrorMessageVariant(newErrorMessageVariant)

      return;
    }

    if (newVariantOptions.length === 1 && newVariantOptions[0].values[0]) {
      const newValue = [];

      const _mainVariantValues = newVariantOptions[0].values.filter(_value => _value)

      _mainVariantValues.forEach((_mainVariantValue) => {
        newValue.push({
          id: "",
          sellerSku: "",
          shopSku: "",
          barcode: "",
          variation: [
            {
              name: newVariantOptions[0].name,
              value: _mainVariantValue,
            },
          ],
          price: "",
          reserved: "",
          available: "",
          unitCode: "",
          unitName: "-",
          discount: "",
          percentVat: percentVat,
          percentVatText: percentVatText,
          image: "",
          status: "active",
        })
      });

      const oldVariant = [...skus]

      newValue.forEach((_newVariant, index) => {
        oldVariant.forEach(((_oldVariant, index) => {
          const { variation: optionsNewVariant } = _newVariant
          const { variation: optionsOldVariant } = _oldVariant

          // console.log("optionsNewVariant", optionsNewVariant)
          // console.log("optionsOldVariant", optionsOldVariant)

          if (optionsOldVariant.length && optionsNewVariant[0].name === optionsOldVariant[0].name &&
              optionsNewVariant[0].value === optionsOldVariant[0].value) {
                Object.keys(_newVariant).forEach(key => {
                  _newVariant[key] = _oldVariant[key]
                })
              }

        }))
      })


      setSkus(newValue);
      console.log("newValue 1", newValue)

      const newErrorMessageVariant = []
      newValue.forEach(_newValue => newErrorMessageVariant.push({
        price: "", available: "", sellerSku: ""
      }))
      setErrorMessageVariant(newErrorMessageVariant)

      return;
    }


    if (newVariantOptions.length === 0 || (newVariantOptions.length === 1 && !newVariantOptions[0].values[0]) ) {
      const newValue = [];
      newValue.push({
        id: "",
        sellerSku: "",
        shopSku: "",
        barcode: "",
        variation: [],
        price: "",
        reserved: "",
        available: "",
        unitCode: "",
        unitName: "-",
        discount: "",
        percentVat: percentVat,
        percentVatText: percentVatText,
        image: "",
        status: "active",
      })

      setSkus(newValue);

      const newErrorMessageVariant = []
      newValue.forEach(_newValue => newErrorMessageVariant.push({
        price: "", available: "", sellerSku: ""
      }))
      setErrorMessageVariant(newErrorMessageVariant)
    }
  }
  

  function handleChangeVariant(e, index, selectedOption) {
    const {
      target: { value, id },
    } = e;

    const _newVariants = [...skus]

    if (id === "unitCode") {
      _newVariants[index]["unitCode"] = selectedOption.key
      _newVariants[index]["unitName"] = selectedOption.text
    } else if (id === "percentVat") {
      _newVariants[index]["percentVat"] = selectedOption.key
      _newVariants[index]["percentVatText"] = selectedOption.text
    } else {
      _newVariants[index][id] = value

      if (errorMessageVariant[index][id]) {
        const newErrorMessageVariant = [...errorMessageVariant]
        newErrorMessageVariant[index][id] = ""
        setErrorMessageVariant(newErrorMessageVariant)
      }
    }

    setSkus(_newVariants)
  }

  function handleDeleteVariantValue(e, index, indexOfValue) {
    const newVariantOption = [...variations]
    if (newVariantOption[index].values[indexOfValue]) {
      newVariantOption[index].values.splice(indexOfValue, 1);
    }

    setVariations(newVariantOption)
    variantsArrayChange(newVariantOption)

    if (index === 0) {
      const _newImageVariant = [...variantImages]
      const _newImageVariantData = [...variantImagesData]

      _newImageVariant.splice(indexOfValue, 1);
      _newImageVariantData.splice(indexOfValue, 1);
  
      setVariantImages(_newImageVariant)
      setVariantImagesData(_newImageVariantData)
    }
  }

  function handleBlurDeleteVariantValue(e, index, indexOfValue) {
    const newVariantOption = [...variations]
    newVariantOption[index].values.splice(indexOfValue, 1);
    

    setVariations(newVariantOption)
    variantsArrayChange(newVariantOption)

    if (index === 0) {
      const _newImageVariant = [...variantImages]
      const _newImageVariantData = [...variantImagesData]
      
      _newImageVariant.splice(indexOfValue, 1);
      _newImageVariantData.splice(indexOfValue, 1);
  
      setVariantImages(_newImageVariant)
      setVariantImagesData(_newImageVariantData)
    }
  }

  function handleDeleteSkus(e, index) {
    const newSkus = [...skus]
    if (newSkus.length > 1) {
      newSkus.splice(index, 1)
  
      setSkus(newSkus)
    }
  }

  function batchEdit() {
    const newVariant = [...skus]

    newVariant.forEach((_variant => {
      if (_variant.variation.length === 1) {
        if (batchEditValue.option1 === _variant.variation[0].value || batchEditValue.option1 === "all") {
          _variant.price = batchEditValue.price
          _variant.available = batchEditValue.available
          _variant.sellerSku = batchEditValue.sellerSku
          _variant.unitCode = batchEditValue.unitCode
          _variant.unitName = batchEditValue.unitName
          _variant.discount = batchEditValue.discount
          _variant.percentVat = batchEditValue.percentVat
          _variant.percentVatText = batchEditValue.percentVatText
        }
      } else if (_variant.variation.length === 2) {
        if ((batchEditValue.option1 === _variant.variation[0].value || batchEditValue.option1 === "all") && (batchEditValue.option2 === _variant.variation[1].value || batchEditValue.option2 === "all")) {
          _variant.price = batchEditValue.price
          _variant.available = batchEditValue.available
          _variant.sellerSku = batchEditValue.sellerSku
          _variant.unitCode = batchEditValue.unitCode
          _variant.unitName = batchEditValue.unitName
          _variant.discount = batchEditValue.discount
          _variant.percentVat = batchEditValue.percentVat
          _variant.percentVatText = batchEditValue.percentVatText
        }
      }

    }))

    setSkus(newVariant)
    setBatchEditValue({
      option1: "all",
      option2: "all",
      price: "",
      available: "",
      sellerSku: "",
      unitCode: "",
      unitName: "-",
      discount: "",
      percentVat: percentVat,
      percentVatText: percentVatText
    })
  }

  async function checkSkus() {
    const promises = [];

    skus.forEach(_sku => {
      if (_sku.sellerSku !== "") {
        if (data && data.command === "Edit") {
          const { productId } = data

          promises.push(new Promise(async function (resolve, reject) {
            instance
              .acquireTokenSilent({ ...silentRequest, account: account })
              .then((tokenResponse) => {
  
                axios.get(`${API_URL}/products?search=${_sku.sellerSku}`, {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    'Content-Type': 'application/json',
                    'cid': companyId
                  },
                }).then(response => {
                  const { count, products } = response.data.data
                  console.log("response", response)
  
                  if (count !== 0) {
                    products.forEach(_product => {
                      const { skus: productVariants } = _product.data
                      const { id } = _product
  
                      productVariants.forEach(_variant => {
                        if (_sku.sellerSku === _variant.sellerSku && id !== productId) {
                          const duplicateSkuIndex = skus.findIndex(_currentVariant => _currentVariant.sellerSku === _variant.sellerSku && id !== productId)
                          
                          const newErrorMessageVariant = [...errorMessageVariant]
                          newErrorMessageVariant[duplicateSkuIndex].sellerSku = t("addProduct.usedSku")
                          setErrorMessageVariant(newErrorMessageVariant)
  
                          resolve(true)
                        }
                      })
                    }) 

                    resolve(false)
                  } else {
                    resolve(false)
                  }

                })
              
              })
              .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);

                setShowError(true)
                reject("error")
                // instance.acquireTokenRedirect(silentRequest);
              });
          }))
        } else {
          promises.push(new Promise(async function (resolve, reject) {
            instance
              .acquireTokenSilent({ ...silentRequest, account: account })
              .then((tokenResponse) => {
  
                axios.get(`${API_URL}/products?search=${_sku.sellerSku}`, {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    'Content-Type': 'application/json',
                    'cid': companyId
                  },
                }).then(response => {
                  const { count, products } = response.data.data
                  console.log("response 2", response)
  
                  if (count !== 0) {
                    products.forEach(_product => {
                      const { skus: productVariants } = _product.data
  
                      productVariants.forEach(_variant => {
                        if (_sku.sellerSku === _variant.sellerSku) {
                          const duplicateSkuIndex = skus.findIndex(_currentVariant => _currentVariant.sellerSku === _variant.sellerSku)
                          
                          const newErrorMessageVariant = [...errorMessageVariant]
                          newErrorMessageVariant[duplicateSkuIndex].sellerSku = t("addProduct.usedSku")
                          setErrorMessageVariant(newErrorMessageVariant)
  
                          resolve(true)
                        }
                      })
                    }) 

                    resolve(false)
                  } else {
                    resolve(false)
                  }
                })
              
              })
              .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);

                setShowError(true)
                // instance.acquireTokenRedirect(silentRequest);
                reject("error")
              });
          }))
        }
        
      }
    })
    
    let result = false
    await Promise.all(promises).then(async (res) => {
      console.log(res, 'res')

      if (res.some(_value => _value)) result = true

    }).catch(err => {
      console.log(err);

      result = true
    })

    return result
  }

  async function handleSubmit(event, status) {
    event.preventDefault();

    setShowMessageBarSuccess(false);
    setShowCategoryError(false)

    const newErrorMessageVariant = []
    skus.forEach(_newValue => newErrorMessageVariant.push({
      price: "", available: "", sellerSku: ""
    }))
    setErrorMessageVariant(newErrorMessageVariant)

    let isCorrect = true;

    if (!product.category.code) {
      setShowCategoryError(true)
      onSetFocus()
      isCorrect = false
    }

    const skusNumberArray = skus.map(function (doc) {
      return doc.sellerSku
    });

    const isDuplicate = skusNumberArray.some(function (number, idx) {
      return skusNumberArray.indexOf(number) !== idx;
    });

    if (isDuplicate) {
      const _duplicatedNumbers = skusNumberArray.filter((item, index) => index !== skusNumberArray.indexOf(item));
      const uniq = [...new Set(_duplicatedNumbers)];

      skus.forEach((_currentVariant, index) => {
        const newErrorMessageVariant = [...errorMessageVariant]
  
        if (uniq.includes(_currentVariant.sellerSku)) {
          newErrorMessageVariant[index].sellerSku = t("addProduct.duplicatedSku")
          setErrorMessageVariant(newErrorMessageVariant)
        }
      })
      isCorrect = false
    }

    if (!skus.length) {
      isCorrect = false
    }

    skus.forEach((_currentVariant, index) => {
      const newErrorMessageVariant = [...errorMessageVariant]

      if (!_currentVariant.price) {
        newErrorMessageVariant[index].price = t("addProduct.pleaseFillInformation")
        setErrorMessageVariant(newErrorMessageVariant)

        isCorrect = false
      }

      if (!_currentVariant.available) {
        newErrorMessageVariant[index].available = t("addProduct.pleaseFillInformation")
        setErrorMessageVariant(newErrorMessageVariant)

        isCorrect = false
      }

      if (!_currentVariant.sellerSku) {
        newErrorMessageVariant[index].sellerSku = t("addProduct.pleaseFillInformation")
        setErrorMessageVariant(newErrorMessageVariant)

        isCorrect = false
      }
    })

    const duplicateSku = await checkSkus()

    console.log("isCorrect", isCorrect)
    console.log("duplicateSku", duplicateSku)
    
    if (isCorrect && !duplicateSku) {
      setIsLoading(true)

      let productImage = await uploadProductImage(imagesData) || []
      let skusImage = await uploadVariantImage(variantImagesData) || []

      if (data && data.command === "Edit") {
        const { productId } = data

        const body = {
          id: productId,
          name: product.name,
          status: status ? status : product.status,
          description: product.description,
          brand: product.brand,
          category: product.category,
          images: productImage,
          variations: variations.map(_variant => ({..._variant, values: _variant.values.filter(_option => _option)})),
          skus: skus.map(_variant => {
            if (variations.length) {
              const variantImageIndex = variations[0].values.findIndex(_variantValue => _variantValue === _variant.variation[0].value)
              return {..._variant, 
                available: Number(_variant.available), 
                price: Number(_variant.price), 
                reserved: Number(_variant.reserved), 
                discount: Number(_variant.discount),
                image: skusImage[variantImageIndex] || ''
              }
            } else {
              return {..._variant, available: Number(_variant.available), price: Number(_variant.price), reserved: Number(_variant.reserved), discount: Number(_variant.discount)}
            }
          }),
        }

        instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .patch(
              API_URL + "/products/" + productId,
              {
                replace: "/data",
                value: body,
              },
              {
                headers: {
                  Authorization: "Bearer " + tokenResponse.accessToken,
                  "Content-Type": "application/json",
                  "cid": companyId
                },
              }
            ).then(response => {
              console.log(response)

              setIsLoading(false)
              setShowMessageBarSuccess(true);
              window.scrollTo(0, 0);
            })
        }).catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);

          setShowError(true)
          // instance.acquireTokenRedirect(silentRequest);
        });
      } else {
        const body = {
          id: "",
          name: product.name,
          status: status ? status : product.status,
          description: product.description,
          brand: product.brand,
          category: product.category,
          images:  productImage,
          variations: variations.map(_variant => ({..._variant, values: _variant.values.filter(_option => _option)})),
          skus: skus.map(_variant => {
            if (variations.length) {
              const variantImageIndex = variations[0].values.findIndex(_variantValue => _variantValue === _variant.variation[0].value)
              return {..._variant, 
                available: Number(_variant.available), 
                price: Number(_variant.price), 
                reserved: Number(_variant.reserved), 
                discount: Number(_variant.discount),
                image: skusImage[variantImageIndex] || ''
              }
            } else {
              return {..._variant, available: Number(_variant.available), price: Number(_variant.price), reserved: Number(_variant.reserved), discount: Number(_variant.discount)}
            }
          }),
        }

        console.log("body", body)

        instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .post(
              API_URL + "/products",
              body,
              {
                headers: {
                  Authorization: "Bearer " + tokenResponse.accessToken,
                  "Content-Type": "application/json",
                  "cid": companyId
                },
              }
            ).then(response => {
              console.log(response)

              setIsLoading(false)
              // setShowMessageBarSuccess(true);
              window.scrollTo(0, 0);

              history.push({
                pathname: '/products',
                state: { addComplete: true },
              });
            })
        }).catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);

          setShowError(true)
          // instance.acquireTokenRedirect(silentRequest);
        });
      }
    }
  }

  const handleCancel = (e) => {
    history.push({
      pathname: backUrl,
      state: {},
    });
  };

  async function uploadProductImage(imagesData) {
    console.log("imagesData", imagesData)

    let productImage = []

    await instance
    .acquireTokenSilent({ ...silentRequest, account: account })
    .then(async (tokenResponse) => {
      let promises = [];

      imagesData.forEach(_img => {
        if (typeof _img === "object") {
          let formData = new FormData();
          formData.append('file', _img);
  
          promises.push(
            axios.post(API_URL + '/products/images', formData, {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'Content-Type': 'multipart/form-data',
              },
            })
          );
        } else {
          promises.push(_img)
        }

      })

      await Promise.all(promises).then(function (results) {
        results.forEach(_response => {
          if (_response.data) {
            productImage.push(_response.data.fileName)
          } else {
            productImage.push(_response)
          }
        })
      });
    }).catch((error) => {
      //Acquire token silent failure, and send an interactive request
      console.log(error);
      // instance.acquireTokenRedirect(silentRequest);
    });
 
    console.log("productImage", productImage)
    return productImage
  }

  async function uploadVariantImage(variantImagesData) {
    console.log("variantImagesData", variantImagesData)

    let skusImage = []
    
    await instance
    .acquireTokenSilent({ ...silentRequest, account: account })
    .then(async (tokenResponse) => {
      let promises = [];

      variantImagesData.forEach((_img, index) => {
        if (_img) {
          if (typeof (_img) === "object") {
            let formData = new FormData();
            formData.append('file', _img);
  
            promises.push(
              axios.post(API_URL + '/products/images', formData, {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'Content-Type': 'multipart/form-data',
                },
              })
            );

          } else {
            promises.push(_img);
          }
        } else {
          promises.push("");
        }
      })

      await Promise.all(promises).then(function (results) {
        results.forEach(_response => {
          if (_response === true) {
            skusImage.push("")
          } else {
            if (_response.data) {
              skusImage.push(_response.data.fileName)
            } else {
              skusImage.push(_response)
            }
          }
        })
      });
    }).catch((error) => {
      //Acquire token silent failure, and send an interactive request
      console.log(error);
      // instance.acquireTokenRedirect(silentRequest);
    });

    return skusImage
  }

  function handleProductChange(e, newValue) {
    if (e.target.id === "category") {
      setShowCategoryError(false)
      setProduct((prev) => ({ ...prev, category: { code: newValue.key, name: newValue.text, rdCode: "" } }));
    } else {
      setProduct((prev) => ({ ...prev, [e.target.id]: newValue }));
    }
  }

  

  const uploadImgButton = useRef();

  const [images, setImages] = useState([]);
  const [imagesData, setImagesData] = useState([])

  const [variantImages, setVariantImages] = useState([])
  const [variantImagesData, setVariantImagesData] = useState([])

  const onImageChange = (event, index) => {
    if (event.target.files && event.target.files[0]) {

      
      if (URL.createObjectURL(event.target.files[0]).includes('blob:')) {
        console.log(URL.createObjectURL(event.target.files[0]))

      }
      // if (!images.length) {
      //   setImage(URL.createObjectURL(event.target.files[0]));
      // }
      const _images = [...images];
      const _imagesData = [...imagesData]
      
      if (index === 'new') {
        _images.push(URL.createObjectURL(event.target.files[0]));
        _imagesData.push(event.target.files[0])
  
        setImages(_images);
        setImagesData(_imagesData)
      } else {
        _images[index] = URL.createObjectURL(event.target.files[0])
        _imagesData[index] = event.target.files[0]
  
        setImages(_images);
        setImagesData(_imagesData)
      }
    }
  };

  const handleDeleteImage = (event, index) => {
    const _images = [...images];
    const _imagesData = [...imagesData]

    _images.splice(index, 1);
    _imagesData.splice(index, 1);

    setImages(_images);
    setImagesData(_imagesData)
  }

  const handleDeleteVariantImage = (event, index) => {
    const _images = [...variantImages];
    const _imagesData = [...variantImagesData]

    console.log(_images)
    console.log(_imagesData)

    _images[index] = ""
    _imagesData[index] = ""

    setVariantImages(_images);
    setVariantImagesData(_imagesData)
  }

  const onVariantImageChange = (event, index) => {
    if (event.target.files && event.target.files[0]) {

      const _images = [...variantImages];
      const _imagesData = [...variantImagesData]
      
      _images[index] = URL.createObjectURL(event.target.files[0])
      _imagesData[index] = event.target.files[0]

      setVariantImages(_images);
      setVariantImagesData(_imagesData)
      
    }
  };

  useEffect(() => {
    console.log("data", companyId)
    if (data) {
      const { productId } = data

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .get(
              API_URL + "/products",
              {
                headers: {
                  Authorization: "Bearer " + tokenResponse.accessToken,
                  "Content-Type": "application/json",
                  "cid": companyId
                },
              }
            ).then(response => {
              console.log(response)

              const { products } = response.data.data

              // setAllProduct(products)

              const _findProduct = products.find(_product => _product.id === productId)

              console.log("_findProduct", _findProduct)

              const { 
                id,
                name,
                status,
                description,
                brand,
                category,
                images,
                variations,
                skus,
                createTime,
              } = _findProduct.data

              setProduct({
                id: id,
                name: name,
                status: status,
                description: description,
                brand: brand,
                category: category,
                images: images,
                createTime: createTime
              })

              setImages(images)
              setImagesData(images)
              
              setVariations(variations)
              if (variations.length) {
                setVariantEnable(true)
              }
              setSkus(skus)

              if (variations.length) {
                const _variantImg = []
  
                const removeDuplicateArray = skus.filter(
                  (_sku, index, _skusList) =>
                    index === _skusList.findIndex((_sk) => _sk.variation[0].value === _sku.variation[0].value)
                )

                removeDuplicateArray.forEach(_sku => {
                  if (_sku.image) {
                    _variantImg.push(_sku.image)
                  } else {
                    _variantImg.push("")
                  }
                })

                setVariantImages(_variantImg)
                setVariantImagesData(_variantImg)
              }



              const newErrorMessageVariant = []
              skus.forEach(_newValue => newErrorMessageVariant.push({
                price: "", available: "", sellerSku: ""
              }))
              setErrorMessageVariant(newErrorMessageVariant)
            })
        }).catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          // instance.acquireTokenRedirect(silentRequest);
        });
    } else {
      instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(
            API_URL + "/products",
            {
              headers: {
                Authorization: "Bearer " + tokenResponse.accessToken,
                "Content-Type": "application/json",
                "cid": companyId
              },
            }
          ).then(response => {
            console.log(response)

            // const { products } = response.data.data

            // setAllProduct(products)
          })
      }).catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Stack style={{ background: "#FAF9F8" }}>
      {showMessageBarSuccess && (
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={false}
          onDismiss={() => setShowMessageBarSuccess(false)}
          dismissButtonAriaLabel="Close"
        >
          {t("addContact.updateSuccess")}
        </MessageBar>
      )}

      {showError && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowError(false)}
          dismissButtonAriaLabel={t('contact.close')}
          style={{
            fontSize: 14,
          }}
        >
          {t('addProduct.tryAgain')}
        </MessageBar>
      )}
      {buttonClick ? (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="certificates-loading-center">
              <Spinner label={t("addContact.processing")} labelPosition="down" styles={spinnerStyles} />
            </div>
            <br />
          </Stack>
        </Stack>
      ) : (
        <form onSubmit={handleSubmit}>
          <input ref={uploadImgButton} type="file" hidden onChange={onImageChange} />
          <Stack horizontal horizontalAlign="space-between">
            <h2 style={{ marginLeft: "0", marginTop: "27px" }}>
              <Icon iconName="Product" style={{ color: '#106ebe' }} /> {t("addProduct.header")}
            </h2>
          </Stack>
          <div style={cardStyle}>
            <h3>{t("addProduct.basicInformation")}</h3>
            <TextField id="name" label={t("addProduct.productName")} value={product.name} onChange={handleProductChange} required />
            <Stack horizontal tokens={{ childrenGap: 15 }}>
              <Dropdown
                componentRef={dropdownRef}
                id="category"
                label={t("addProduct.category")}
                required
                options={_categoryList.map(_category => ({ key: _category.categoryCode, text: i18n.language === "th" ? _category.categoryNameTh : _category.categoryNameEn}))}
                selectedKey={product.category.code}
                styles={{ root: { width: "100%" }, dropdownItems: { height: 300, overflowY: 'auto' } }}
                onChange={(e, selectedOption) => handleProductChange(e, selectedOption)}
                errorMessage={showCategoryError ? t("addProduct.pleaseFillInformation") : ""}
              />
            </Stack>
            <TextField
              id="description"
              label={t("addProduct.description")}
              // styles={{ root: { width: "50%" } }}
              multiline
              value={product.description}
              onChange={handleProductChange}
            />
          </div>
          <div style={cardStyle}>
            <h3>{t("addProduct.productAttributes")}</h3>
            <Stack horizontal tokens={{ childrenGap: 12}}>
              {images.map((_img, index) => (
                <ImageCard key={"image" + index} _img={_img} index={index} onDeleteImage={handleDeleteImage} onImageChange={onImageChange} imageLabel={`รูป (${index + 1}/8)`} />
              ))}
              {images.length !== 8 ? (
                <div>
                  <label /* onClick={e => handleImageClick(e, images.length)} */  style={{ height: 100, width: 100, border: "1px dotted black", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Icon iconName="Add" styles={{ root: { fontSize: 30 }}} />
                    <input type="file" accept="image/*" style={{ display: "none"}} onChange={e => onImageChange(e, "new")} />
                  </label>
                  <div style={{ height: 30, width: 100, display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#F6F6F6", border: "1px solid #F6F6F6"}}>
                    รูป ({images.length + 1}/8)
                  </div>
                  {/* { images.map((_img, index) => (
                    <Image src={_img} width={100} height={100} />
                  ))} */}
                </div>

              ) : null}
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 15 }}>
              <TextField
                id="brand"
                label={t("addProduct.brand")}
                styles={{ root: { width: "100%" } }}
                // multiline
                value={product.brand}
                onChange={handleProductChange}
              />
            </Stack>
          </div>
           <div style={cardStyle}>
            <h3>{t("addProduct.salesInformation")}</h3>
            <Stack tokens={{ childrenGap: 15 }}>
              <Stack horizontal tokens={{ childrenGap: 15 }} styles={{ root: { paddingTop: 10, paddingBottom: 10}}}>
                {/* <PrimaryButton text="เปิดใช้งานตัวเลือกสินค้า" onClick={handleEnableVariant} /> */}
                <Toggle
                  label={<div>{t("addProduct.enableVariations")}</div>}
                  inlineLabel
                  onText={t("addProduct.on")}
                  offText={t("addProduct.off")}
                  checked={variantEnable}
                  onChange={handleEnableVariant}
                />
              </Stack>
              {variantEnable ? (
                <>
                  {variations.map((_variant, index) => (
                    <React.Fragment key={"_variant" + index}>
                      <Stack styles={{ root: { padding: 10, backgroundColor: "#F6F6F6"}}}>
                        <Stack horizontal horizontalAlign="space-between">
                          <label style={{ padding: "5px 0px", fontSize: 14, fontWeight: 600, color: "rgb(50, 49, 48)" }}>
                            {t("addProduct.variationName")}
                          </label>
                          <IconButton iconProps={{ iconName: "Cancel"}} onClick={e => handleDeleteVariant(e, index)} />
                        </Stack>
                        <TextField 
                          id="name" 
                          // label={"ตัวเลือก " + (index + 1)} 
                          value={_variant.name} 
                          onChange={(e, value) => handleVariant(e, index)}
                          required 
                        />
                        <label style={{ padding: "5px 0px", fontSize: 14, fontWeight: 600, color: "rgb(50, 49, 48)", marginTop: 24 }}>
                          {t("addProduct.option")}
                        </label>
                        <Stack tokens={{ childrenGap: 6}}>
                          {_variant.values.map((_value, indexOfValue) => (
                            <React.Fragment key={"_variantValue" + indexOfValue}>
                              <TextField 
                                id="variantValue" 
                                value={_value} 
                                onChange={(e, value) => handleVariantValue(e, index, indexOfValue)} 
                                onBlur={(e) => {
                                  if (!_value && (indexOfValue !== (_variant.values.length - 1))) handleBlurDeleteVariantValue(e, index, indexOfValue)
                                }}  
                                onRenderSuffix={(props) => (<Icon iconName="Delete" onClick={(e) => handleDeleteVariantValue(e, index, indexOfValue)} />)} 
                                required={indexOfValue === 0}
                              />
                            </React.Fragment>
                          ))}
                        </Stack>
                      </Stack>
                      {index === 0 ? (
                        <Stack horizontal tokens={{ childrenGap: 15}}>
                          {_variant.values.map((_value, indexOfValue) => (
                            _value ? (
                              <ImageCard key={"image" + _value} _img={variantImages[indexOfValue]} index={indexOfValue} onDeleteImage={handleDeleteVariantImage} onImageChange={onVariantImageChange} imageLabel={_value} />
                            ) : null
                          ))}

                        </Stack>
                      ) : null}
                  </React.Fragment>
                  ))}
                 {variations.length === 1 && (
                  <Stack horizontal tokens={{ childrenGap: 15 }} styles={{ root: { padding: 10}}}>
                    <PrimaryButton text={t("addProduct.addVariation")} onClick={handleEnableVariant2} />
                    </Stack>
                 )}
                  
                </>
              ) : (
                null
              )}
              
              <Stack>
                {variations.length ? (
                  <>
                  <Stack horizontal horizontalAlign="space-between">
                    <label style={{ padding: "5px 0px", fontSize: 14, fontWeight: 600, color: "rgb(50, 49, 48)" }}>
                      {t("addProduct.variationList")}
                    </label>
                    <DefaultButton text="Batch Edit" menuIconProps={{ iconName: enableBatchEdit ? "ChevronUp" : "ChevronDown" }} onClick={() => setEnableBatchEdit(prev => !prev)} />
                  </Stack>
                      {enableBatchEdit ? (
                        variations.length === 1 ? (

                        <Stack horizontal>
                            <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <Dropdown 
                                  options={[{key: "all", text: "All"}, ...variations[0].values.filter(_option => _option !== "").map(_option => ({ key: _option, text: _option}))]} 
                                  selectedKey={batchEditValue.option1} 
                                  onChange={(e, selectedOption) => setBatchEditValue(prev => ({...prev, option1: selectedOption.key }))}
                                />
                              </Stack>
                            </Stack>
                            <Stack styles={{ root: { width: "20%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <TextField placeholder={t("addProduct.price")} type="number" value={batchEditValue.price} onChange={(e, value) => setBatchEditValue(prev => ({...prev, price: value }))} />
                              </Stack>
                            </Stack>
                            <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <TextField placeholder={t("addProduct.discount")} type="number" value={batchEditValue.discount} onChange={(e, value) => setBatchEditValue(prev => ({...prev, discount: value }))} />
                              </Stack>
                            </Stack>
                            <Stack styles={{ root: { width: "15%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <TextField placeholder={t("addProduct.quantity")} type="number" value={batchEditValue.available} onChange={(e, value) => setBatchEditValue(prev => ({...prev, available: value }))}/>
                              </Stack>
                            </Stack>
                            <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <Dropdown
                                  id="unitCode"
                                  options={unitList}
                                  selectedKey={batchEditValue.unitCode}
                                  onChange={(e, selectedOption) => setBatchEditValue(prev => ({...prev, unitCode: selectedOption.key, unitName: selectedOption.text }))}
                                />
                              </Stack>
                            </Stack>
                            <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <Dropdown
                                  id="percentVat"
                                  options={[
                                    {
                                      key: nonVat,
                                      text: nonVatText,
                                      id: "percentVat",
                                    },
                                    {
                                      key: zeroVat,
                                      text: zeroVatText,
                                      id: "percentVat",
                                    },
                                    {
                                      key: percentVat,
                                      text: percentVatText,
                                      id: "percentVat",
                                    },
                                  ]}
                                  selectedKey={batchEditValue.percentVat}
                                  onChange={(e, selectedOption) => setBatchEditValue(prev => ({...prev, percentVat: selectedOption.key, percentVatText: selectedOption.text }))}
                                />
                              </Stack>
                            </Stack>
                            <Stack styles={{ root: { width: "15%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <TextField id="sellerSku" placeholder="SKU" value={batchEditValue.sellerSku} onChange={(e, value) => setBatchEditValue(prev => ({...prev, sellerSku: value }))}/>
                              </Stack>
                            </Stack>
                            <Stack styles={{ root: { width: "10%", padding: "12px 5px"}}}>
                              <PrimaryButton  text={t("addProduct.apply")} onClick={() => batchEdit()} />
                            </Stack>
                        </Stack>
                        ) : (
                          <Stack horizontal>
                            <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <Dropdown 
                                  options={[{key: "all", text: "All"}, ...variations[0].values.filter(_option => _option !== "").map(_option => ({ key: _option, text: _option}))]} 
                                  selectedKey={batchEditValue.option1}  
                                  onChange={(e, selectedOption) => setBatchEditValue(prev => ({...prev, option1: selectedOption.key }))}
                                />
                              </Stack>
                            </Stack>
                            <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <Dropdown 
                                  options={[{key: "all", text: "All"}, ...variations[1].values.filter(_option => _option !== "").map(_option => ({ key: _option, text: _option}))]} 
                                  selectedKey={batchEditValue.option2}  
                                  onChange={(e, selectedOption) => setBatchEditValue(prev => ({...prev, option2: selectedOption.key }))}
                                  />
                              </Stack>
                            </Stack>
                            <Stack styles={{ root: { width: "15%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <TextField placeholder={t("addProduct.price")} type="number" value={batchEditValue.price} onChange={(e, value) => setBatchEditValue(prev => ({...prev, price: value }))} />
                              </Stack>
                            </Stack>
                            <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <TextField placeholder={t("addProduct.discount")} type="number" value={batchEditValue.discount} onChange={(e, value) => setBatchEditValue(prev => ({...prev, discount: value }))} />
                              </Stack>
                            </Stack>
                            <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <TextField placeholder={t("addProduct.quantity")} type="number" value={batchEditValue.available} onChange={(e, value) => setBatchEditValue(prev => ({...prev, available: value }))}/>
                              </Stack>
                            </Stack>
                            <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <Dropdown
                                  id="unitCode"
                                  options={unitList}
                                  selectedKey={batchEditValue.unitCode}
                                  onChange={(e, selectedOption) => setBatchEditValue(prev => ({...prev, unitCode: selectedOption.key, unitName: selectedOption.text }))}
                                />
                              </Stack>
                            </Stack>
                            <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <Dropdown
                                  id="percentVat"
                                  options={[
                                    {
                                      key: nonVat,
                                      text: nonVatText,
                                      id: "percentVat",
                                    },
                                    {
                                      key: zeroVat,
                                      text: zeroVatText,
                                      id: "percentVat",
                                    },
                                    {
                                      key: percentVat,
                                      text: percentVatText,
                                      id: "percentVat",
                                    },
                                  ]}
                                  selectedKey={batchEditValue.percentVat}
                                  onChange={(e, selectedOption) => setBatchEditValue(prev => ({...prev, percentVat: selectedOption.key, percentVatText: selectedOption.text }))}
                                />
                              </Stack>
                            </Stack>
                            <Stack styles={{ root: { width: "15%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <TextField id="sellerSku" placeholder="SKU" value={batchEditValue.sellerSku} onChange={(e, value) => setBatchEditValue(prev => ({...prev, sellerSku: value }))}/>
                              </Stack>
                            </Stack>
                            <Stack styles={{ root: { width: "10%", padding: "12px 5px"}}}>
                              <PrimaryButton  text={t("addProduct.apply")} onClick={() => batchEdit()} />
                            </Stack>
                        </Stack>
                        )
                      ) : null}
                    <br />
                  </>
                ) : (
                  <Stack tokens={{ childrenGap: 15 }} styles={{ root: { border: "0.8px solid rgb(105, 113, 140)", borderRadius: 4}}}>
                      <Stack>
                        <Stack horizontal styles={{ root: { backgroundColor: "rgb(246, 246, 251)", borderRadius: 4}}}>
                          <Stack styles={{ root: { width: "20%", padding: 12}}} horizontalAlign="center">
                            <label style={{ padding: "5px 0px", fontSize: 14, fontWeight: 600, color: "rgb(50, 49, 48)" }}>
                              {t("addProduct.price")}
                            </label>
                          </Stack>
                          <Stack styles={{ root: { width: "15%", padding: 12}}} horizontalAlign="center">
                            <label style={{ padding: "5px 0px", fontSize: 14, fontWeight: 600, color: "rgb(50, 49, 48)" }}>
                              {t("addProduct.discount")}
                            </label>
                          </Stack>
                          <Stack styles={{ root: { width: "15%", padding: 12}}} horizontalAlign="center">
                            <label style={{ padding: "5px 0px", fontSize: 14, fontWeight: 600, color: "rgb(50, 49, 48)" }}>
                              {t("addProduct.quantity")}
                            </label>
                          </Stack>
                          <Stack styles={{ root: { width: "15%", padding: 12}}} horizontalAlign="center">
                            <label style={{ padding: "5px 0px", fontSize: 14, fontWeight: 600, color: "rgb(50, 49, 48)" }}>
                              {t("addProduct.unit")}
                            </label>
                          </Stack>
                          <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                            <label style={{ padding: "5px 0px", fontSize: 14, fontWeight: 600, color: "rgb(50, 49, 48)" }}>
                              {t("addProduct.percentVat")}
                            </label>
                          </Stack>
                          <Stack styles={{ root: { width: "25%", padding: 12}}} horizontalAlign="center">
                            <label style={{ padding: "5px 0px", fontSize: 14, fontWeight: 600, color: "rgb(50, 49, 48)" }}>
                              {t("addProduct.sellerSku")}
                            </label>
                          </Stack>
                      </Stack>
                          <Stack horizontal>
                            <Stack styles={{ root: { width: "20%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <TextField 
                                  id="price" 
                                  type="number"
                                  value={skus[0].price}
                                  onChange={e => handleChangeVariant(e, 0)}
                                  errorMessage={errorMessageVariant.length && errorMessageVariant[0] ? errorMessageVariant[0].price : ""}
                                />
                              </Stack>
                            </Stack>
                            <Stack styles={{ root: { width: "15%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <TextField 
                                  id="discount" 
                                  type="number"
                                  value={skus[0].discount}
                                  onChange={e => handleChangeVariant(e, 0)}
                                />
                              </Stack>
                            </Stack>
                            <Stack styles={{ root: { width: "15%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <TextField 
                                  id="available" 
                                  type="number"
                                  value={skus[0].available} 
                                  onChange={e => handleChangeVariant(e, 0)}
                                  errorMessage={errorMessageVariant.length && errorMessageVariant[0] ? errorMessageVariant[0].available : ""}
                                />
                              </Stack>
                            </Stack>
                            <Stack styles={{ root: { width: "15%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <Dropdown
                                  id="unitCode"
                                  options={unitList}
                                  selectedKey={skus[0].unitCode}
                                  onChange={(e, selectedOption) => handleChangeVariant(e, 0, selectedOption)}
                                />
                              </Stack>
                            </Stack>
                            <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <Dropdown
                                  id="percentVat"
                                  options={[
                                    {
                                      key: nonVat,
                                      text: nonVatText,
                                      id: "percentVat",
                                    },
                                    {
                                      key: zeroVat,
                                      text: zeroVatText,
                                      id: "percentVat",
                                    },
                                    {
                                      key: percentVat,
                                      text: percentVatText,
                                      id: "percentVat",
                                    },
                                  ]}
                                  selectedKey={skus[0].percentVat}
                                  onChange={(e, selectedOption) => handleChangeVariant(e, 0, selectedOption)}
                                />
                              </Stack>
                            </Stack>
                            <Stack styles={{ root: { width: "25%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <TextField id="sellerSku" value={skus[0].sellerSku} onChange={e => handleChangeVariant(e, 0)} errorMessage={errorMessageVariant.length && errorMessageVariant[0] ? errorMessageVariant[0].sellerSku : ""} />
                              </Stack>
                            </Stack>
                        </Stack>
                      </Stack>

                  </Stack>
                ) }
                {variantEnable ? (
                  <Stack tokens={{ childrenGap: 15 }} styles={{ root: { border: "0.8px solid rgb(105, 113, 140)", borderRadius: 4}}}>
                    {variations.length === 1 && (
                      <Stack>
                        <Stack horizontal styles={{ root: { backgroundColor: "rgb(246, 246, 251)", borderRadius: 4}}}>
                          <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                            <label style={{ padding: "5px 0px", fontSize: 14, fontWeight: 600, color: "rgb(50, 49, 48)" }}>
                              {variations[0].name ? variations[0].name : t("addProduct.variation1")}
                            </label>
                          </Stack>
                          <Stack styles={{ root: { width: "20%", padding: 12}}} horizontalAlign="center">
                            <label style={{ padding: "5px 0px", fontSize: 14, fontWeight: 600, color: "rgb(50, 49, 48)" }}>
                            {t("addProduct.price")}
                            </label>
                          </Stack>
                          <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                            <label style={{ padding: "5px 0px", fontSize: 14, fontWeight: 600, color: "rgb(50, 49, 48)" }}>
                            {t("addProduct.discount")}
                            </label>
                          </Stack>
                          <Stack styles={{ root: { width: "15%", padding: 12}}} horizontalAlign="center">
                            <label style={{ padding: "5px 0px", fontSize: 14, fontWeight: 600, color: "rgb(50, 49, 48)" }}>
                            {t("addProduct.quantity")}
                            </label>
                          </Stack>
                          <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                            <label style={{ padding: "5px 0px", fontSize: 14, fontWeight: 600, color: "rgb(50, 49, 48)" }}>
                            {t("addProduct.unit")}
                            </label>
                          </Stack>
                          <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                            <label style={{ padding: "5px 0px", fontSize: 14, fontWeight: 600, color: "rgb(50, 49, 48)" }}>
                            {t("addProduct.percentVat")}
                            </label>
                          </Stack>
                          <Stack styles={{ root: { width: "20%", padding: 12}}} horizontalAlign="center">
                            <label style={{ padding: "5px 0px", fontSize: 14, fontWeight: 600, color: "rgb(50, 49, 48)" }}>
                            {t("addProduct.sellerSku")}
                            </label>
                          </Stack>
                      </Stack>
                      {skus.map((_variant, index) => (
                        <React.Fragment key={"variants" + index}>
                          {index !== 0 ? (
                            <Separator styles={{ root: { height: 1, padding: 0}}} />
                          ) : null}
                          <Stack horizontal>
                            <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                              {(_variant.variation[0] && _variant.variation[0].value)  ? <Text variant="large">{_variant.variation[0].value}</Text> : null}
                            </Stack>
                            <Stack styles={{ root: { width: "20%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <TextField 
                                  id="price" 
                                  type="number"
                                  value={_variant.price} 
                                  onChange={(e) => handleChangeVariant(e, index)}
                                  errorMessage={errorMessageVariant.length && errorMessageVariant[0] ? errorMessageVariant[0].price : ""}
                                />
                              </Stack>
                            </Stack>
                            <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <TextField 
                                  id="discount" 
                                  type="number"
                                  value={_variant.discount} 
                                  onChange={(e) => handleChangeVariant(e, index)}
                                />
                              </Stack>
                            </Stack>
                            <Stack styles={{ root: { width: "15%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <TextField 
                                  id="available" 
                                  type="number"
                                  value={_variant.available} 
                                  onChange={(e) => handleChangeVariant(e, index)}
                                  errorMessage={errorMessageVariant.length && errorMessageVariant[0] ? errorMessageVariant[0].available : ""}
                                />
                              </Stack>
                            </Stack>
                            <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <Dropdown
                                  id="unitCode"
                                  options={unitList}
                                  selectedKey={_variant.unitCode}
                                  onChange={(e, selectedOption) => handleChangeVariant(e, index, selectedOption)}
                                />
                              </Stack>
                            </Stack>
                            <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <Dropdown
                                  id="percentVat"
                                  options={[
                                    {
                                      key: nonVat,
                                      text: nonVatText,
                                      id: "percentVat",
                                    },
                                    {
                                      key: zeroVat,
                                      text: zeroVatText,
                                      id: "percentVat",
                                    },
                                    {
                                      key: percentVat,
                                      text: percentVatText,
                                      id: "percentVat",
                                    },
                                  ]}
                                  selectedKey={_variant.percentVat}
                                  onChange={(e, selectedOption) => handleChangeVariant(e, index, selectedOption)}
                                />
                              </Stack>
                            </Stack>
                            <Stack styles={{ root: { width: "20%", padding: 12}}} horizontalAlign="center">
                              <Stack styles={{ root: { width: "100%"}}}>
                                <TextField id="sellerSku" value={_variant.sellerSku} onChange={(e) => handleChangeVariant(e, index)} errorMessage={errorMessageVariant.length && errorMessageVariant[index] ? errorMessageVariant[index].sellerSku : ""} />
                              </Stack>
                            </Stack>
                            <Stack styles={{ root: { width: "5%", padding: "12px 5px"}}}>
                              <IconButton iconProps={{ iconName: "Delete"}} onClick={e => handleDeleteSkus(e, index)} />
                            </Stack>
                        </Stack>
                        </React.Fragment>
                          ))}
                      </Stack>
                    )}

                    {variations.length === 2 && (
                      <Stack>
                      <Stack horizontal styles={{ root: { backgroundColor: "rgb(246, 246, 251)", borderRadius: 4}}}>
                        <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                          <label style={{ padding: "5px 0px", fontSize: 14, fontWeight: 600, color: "rgb(50, 49, 48)" }}>
                          {variations[0].name ? variations[0].name : t("addProduct.variation1")}
                          </label>
                        </Stack>
                        <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                          <label style={{ padding: "5px 0px", fontSize: 14, fontWeight: 600, color: "rgb(50, 49, 48)" }}>
                          {variations[1].name ? variations[1].name : t("addProduct.variation2")}
                          </label>
                        </Stack>
                        <Stack styles={{ root: { width: "15%", padding: 12}}} horizontalAlign="center">
                          <label style={{ padding: "5px 0px", fontSize: 14, fontWeight: 600, color: "rgb(50, 49, 48)" }}>
                          {t("addProduct.price")}
                          </label>
                        </Stack>
                        <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                          <label style={{ padding: "5px 0px", fontSize: 14, fontWeight: 600, color: "rgb(50, 49, 48)" }}>
                          {t("addProduct.discount")}
                          </label>
                        </Stack>
                        <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                          <label style={{ padding: "5px 0px", fontSize: 14, fontWeight: 600, color: "rgb(50, 49, 48)" }}>
                          {t("addProduct.quantity")}
                          </label>
                        </Stack>
                        <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                          <label style={{ padding: "5px 0px", fontSize: 14, fontWeight: 600, color: "rgb(50, 49, 48)" }}>
                          {t("addProduct.unit")}
                          </label>
                        </Stack>
                        <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                          <label style={{ padding: "5px 0px", fontSize: 14, fontWeight: 600, color: "rgb(50, 49, 48)" }}>
                          {t("addProduct.percentVat")}
                          </label>
                        </Stack>
                        <Stack styles={{ root: { width: "20%", padding: 12}}} horizontalAlign="center">
                          <label style={{ padding: "5px 0px", fontSize: 14, fontWeight: 600, color: "rgb(50, 49, 48)" }}>
                          {t("addProduct.sellerSku")}
                          </label>
                        </Stack>
                    </Stack>
                    {skus.map((_variant, index) => (
                      <React.Fragment key={"variants" + index}>
                        {index !== 0 ? (
                          <Separator styles={{ root: { height: 1, padding: 0}}} />
                        ) : null}
                        <Stack horizontal>
                          <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                            {(_variant.variation[0] && _variant.variation[0].value)  ? <Text variant="large">{_variant.variation[0].value}</Text> : null}                          
                          </Stack>
                          <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                            {(_variant.variation[1] && _variant.variation[1].value)  ? <Text variant="large">{_variant.variation[1].value}</Text> : null}
                          </Stack>
                          <Stack styles={{ root: { width: "15%", padding: 12}}} horizontalAlign="center">
                            <Stack styles={{ root: { width: "100%"}}}>
                              <TextField 
                                id="price" 
                                type="number"
                                value={_variant.price} 
                                onChange={(e) => handleChangeVariant(e, index)}
                                errorMessage={errorMessageVariant.length && errorMessageVariant[0] ? errorMessageVariant[0].price : ""}
                              />
                            </Stack>
                          </Stack>
                          <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                            <Stack styles={{ root: { width: "100%"}}}>
                              <TextField 
                                id="discount" 
                                type="number"
                                value={_variant.discount} 
                                onChange={(e) => handleChangeVariant(e, index)}
                              />
                            </Stack>
                          </Stack>
                          <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                            <Stack styles={{ root: { width: "100%"}}}>
                              <TextField 
                                id="available" 
                                type="number"
                                value={_variant.available} 
                                onChange={(e) => handleChangeVariant(e, index)}
                                errorMessage={errorMessageVariant.length && errorMessageVariant[0] ? errorMessageVariant[0].available : ""}
                              />
                            </Stack>
                          </Stack>
                          <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                            <Stack styles={{ root: { width: "100%"}}}>
                              <Dropdown
                                id="unitCode"
                                options={unitList}
                                selectedKey={_variant.unitCode}
                                onChange={(e, selectedOption) => handleChangeVariant(e, index, selectedOption)}
                              />
                            </Stack>
                          </Stack>
                          <Stack styles={{ root: { width: "10%", padding: 12}}} horizontalAlign="center">
                            <Stack styles={{ root: { width: "100%"}}}>
                              <Dropdown
                                id="percentVat"
                                options={[
                                  {
                                    key: nonVat,
                                    text: nonVatText,
                                    id: "percentVat",
                                  },
                                  {
                                    key: zeroVat,
                                    text: zeroVatText,
                                    id: "percentVat",
                                  },
                                  {
                                    key: percentVat,
                                    text: percentVatText,
                                    id: "percentVat",
                                  },
                                ]}
                                selectedKey={_variant.percentVat}
                                onChange={(e, selectedOption) => handleChangeVariant(e, index, selectedOption)}
                              />
                            </Stack>
                          </Stack>
                          <Stack styles={{ root: { width: "20%", padding: 12}}} horizontalAlign="center">
                            <Stack styles={{ root: { width: "100%"}}}>
                              <TextField id="sellerSku" value={_variant.sellerSku} onChange={(e) => handleChangeVariant(e, index)} errorMessage={errorMessageVariant.length && errorMessageVariant[index] ? errorMessageVariant[index].sellerSku : ""} />
                            </Stack>
                          </Stack>
                          <Stack styles={{ root: { width: "5%", padding: "12px 5px"}}}>
                            <IconButton iconProps={{ iconName: "Delete"}} onClick={e => handleDeleteSkus(e, index)} />
                          </Stack>
                      </Stack>
                      </React.Fragment>
                        ))}
                    </Stack>
                    )}
                    
                  </Stack>

                ): null}
              </Stack>
            </Stack>
           </div>
          <br />
          <br />
          <Stack horizontal style={{display: "flex",
            alignItems: "center",
            justifyContent: "center",}} tokens={{ childrenGap: 30 }}>
              {isLoading ? (
                <Spinner label={t("deliveryOrderTaxInvoice.loading")} labelPosition="right" />

              ) : (
                <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
                  <PrimaryButton style={{ width: "125px", letterSpacing: "1px" }} iconProps={{ iconName: 'Save' }} text={t("addProduct.save")} type="submit" value="next" />
                  {/* <DefaultButton text={t("addProduct.SaveAsDraft")} onClick={e => handleSubmit(e, "draft")} /> */}
                  <DefaultButton text={t('addProduct.back')} onClick={handleCancel} />
                </Stack>

              )}
          </Stack>
          <br />
          <br />
        </form>
      )}
    </Stack>
  );
};

export default AddProducts;
