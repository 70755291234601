import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import axios from "axios";
import { useTranslation } from "react-i18next";
import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../../authConfig";

import { Prompt } from "react-router-dom";

import { TextField, PrimaryButton, Dropdown, Stack, getTheme, Icon, Toggle, Spinner, MessageBar, MessageBarType, Separator, DefaultButton, Link } from "@fluentui/react";

import { UnifiedPeoplePicker } from '@fluentui/react-experiments/lib/UnifiedPeoplePicker';

var moment = require("moment"); // require
moment().locale("th");

const API_URL = process.env.REACT_APP_API_URL;

const theme = getTheme();

const stackTokens = { childrenGap: 20 };

const homeStyles = {
    border: 0,
    margin: 0,
    width: "100%",
    minWidth: "calc(100vw - 310px)",
    background: "#FAF9F8",
    position: "relative",
};

const cardStyle = {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
    paddingBottom: 30,
    background: "#ffffff",
    marginLeft: "0",
    marginTop: "10px",
    boxShadow: theme.effects.elevation4,
    width: 590,
};

function checkEmail(text) {
    const pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/; 
  
    if (pattern.test(text)) return true
    return false
  }
// function getCurrentDate(numberObj) {
//     const { year, month, day } = numberObj

//     let _year = year ? moment().format(year) : ""
//     let _month = month ? moment().format(month) : ""
//     let _day = day ? moment().format(day) : ""

//     return _year + _month + _day !== '' ? moment().format(`${_year}${_month}${_day}`) : ''
// }

const LazadaAutomation = () => {
    console.log("Re render");

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const { t } = useTranslation();

    const history = useHistory();

    const [buttonClick, setButtonClick] = useState(false);
    const [showMessageBarSuccess, setShowMessageBarSuccess] = useState(false);
    const [showMessageBarError, setShowMessageBarError] = useState(false);

    const [automationEnable, setAutomationEnable] = useState(false);
    const [triggerStatus, setTriggerStatus] = useState("delivered");

    const [isChange, setIsChange] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [enableEmail, setEnableEmail] = useState(false);

    const orderStatusOptions = [
        { key: "delivered", text: t("lazada.delivered") },
        { key: "shipped", text: t("lazada.shipped") },
        { key: "ready_to_ship", text: t("lazada.readyToShip") },
        { key: "pending", text: t("lazada.pending") },
        { key: "unpaid", text: t("lazada.unpaidType") },
        { key: "canceled", text: t("lazada.cancel") },
        { key: "returned", text: t("lazada.returned") },
        { key: "shipped_back", text: t("lazada.shippedBack") },
    ];

    const yearFormatOptions = [
        { key: "YYYY", text: "YYYY" },
        { key: "YY", text: "YY" },
        { key: "", text: t("lazadaAutomation.none") },
    ];

    const monthFormatOptions = [
        { key: "MM", text: "MM" },
        { key: "", text: t("lazadaAutomation.none") },
    ];

    const dayFormatOptions = [
        { key: "DD", text: "DD" },
        { key: "", text: t("lazadaAutomation.none") },
    ];

    const [receiptTaxInvoice, setReceiptTaxInvoice] = useState({
        number: {
            prefix: "",
            year: "",
            month: "",
            day: "",
            suffix: "",
            originalSuffix: "",
            updatedTime: "",
        },
    });

    const [sendEmail, setSendEmail] = useState(false);
    const [showShippingFee, setShowShippingFee] = useState(true);

    const [onlyBillingAddress, setOnlyBillingAddress] = useState(false);

    const [sendEmailOnlyBillingAddress, setSendEmailOnlyBillingAddress] = useState(false);

    const [cc, setCC] = useState([]);

    const [isChangeNumberReceiptTaxinvoice, setIsChangeNumberReceiptTaxinvoice] = useState(false);

    const companyId = sessionStorage.getItem("companyId") || "";

    async function handleSubmit(event) {
        event.preventDefault();

        setButtonClick(true);

        const _receiptTaxInvoice = { ...receiptTaxInvoice };
        if (isChangeNumberReceiptTaxinvoice) {
            _receiptTaxInvoice.number.updatedTime = new Date().toISOString();
        }

        // console.log({
        //     enable: automationEnable,
        //     triggerStatus: triggerStatus,
        //     receiptTaxInvoice: _receiptTaxInvoice,
        //     sendEmail: sendEmail,
        //     showShippingFee: showShippingFee,
        //     onlyBillingAddress: onlyBillingAddress,
        //     sendEmailOnlyBillingAddress: sendEmailOnlyBillingAddress,
        //     cc: cc.join(';')
        // });

        await instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then(async (tokenResponse) => {
                axios
                    .patch(
                        API_URL + "/sources",
                        {
                            source: "lazada",
                            replace: "/automation",
                            value: {
                                enable: automationEnable,
                                triggerStatus: triggerStatus,
                                receiptTaxInvoice: _receiptTaxInvoice,
                                sendEmail: sendEmail,
                                showShippingFee: showShippingFee,
                                onlyBillingAddress: onlyBillingAddress,
                                sendEmailOnlyBillingAddress: sendEmailOnlyBillingAddress,
                                cc: cc.join(";"),
                            },
                        },
                        {
                            headers: {
                                Authorization: "Bearer " + tokenResponse.accessToken,
                                "cid": companyId
                        },
                        }
                    )
                    .then(
                        (response) => {
                            console.log("response", response);
                            setButtonClick(false);
                            setShowMessageBarSuccess(true);
                            setShowMessageBarError(false);

                            setIsChange(false);
                            window.scrollTo(0, 0);
                        },
                        (error) => {
                            console.log(error);
                            setButtonClick(false);
                            setShowMessageBarError(true);
                            window.scrollTo(0, 0);
                        }
                    );
            })
            .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
            });

        // setButtonClick(true);
        // setShowMessageBarSuccess(true);
        // setShowMessageBarError(false);

        // setIsChange(false);
    }

    useEffect(() => {
        console.log("userEffect initial call!");

        instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then((tokenResponse) => {
                axios
                    .get(API_URL + "/sources/lazada", {
                        headers: {
                            Authorization: "Bearer " + tokenResponse.accessToken,
                            "cid": companyId
                        },
                    })
                    .then(
                        (response) => {
                            console.log("response", response);

                            if (response.data?.automation) {
                                const {
                                    enable: _enable,
                                    triggerStatus: _triggerStatus,
                                    receiptTaxInvoice: _receiptTaxInvoice,
                                    sendEmail: _sendEmail,
                                    showShippingFee: _showShippingFee,
                                    onlyBillingAddress: _onlyBillingAddress,
                                    sendEmailOnlyBillingAddress: _sendEmailOnlyBillingAddress,
                                    cc: _cc,
                                } = response.data.automation;

                                setAutomationEnable(_enable);
                                setTriggerStatus(_triggerStatus);
                                setReceiptTaxInvoice(_receiptTaxInvoice);
                                setSendEmail(_sendEmail);
                                setShowShippingFee(_showShippingFee);
                                setOnlyBillingAddress(_onlyBillingAddress);
                                setSendEmailOnlyBillingAddress(_sendEmailOnlyBillingAddress);
                                if (_cc) {
                                    setCC(_cc.split(";"));
                                }
                            }

                            setIsLoading(false);
                        },
                        (error) => {
                            console.log(error);

                            setIsLoading(false);
                        }
                    );
            })
            .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function ExampleNumberRCT(props) {
        const { numberObj } = props;
        const result = numberObj.number.prefix || numberObj.number.year || numberObj.number.month || numberObj.number.day || numberObj.number.suffix;
        const year = numberObj.number.year ? moment().format(`${numberObj.number.year}`) : "";
        const month = numberObj.number.month ? moment().format(`${numberObj.number.month}`) : "";
        const day = numberObj.number.day ? moment().format(`${numberObj.number.day}`) : "";

        if (result)
            return (
                <Stack horizontal horizontalAlign="center" styles={{ root: { height: 55 } }}>
                    <h3>
                        {t("lazadaAutomation.example")} {numberObj.number.prefix + year + month + day + numberObj.number.suffix}
                    </h3>
                </Stack>
            );

        return <Stack horizontal horizontalAlign="center" styles={{ root: { height: 55 } }}></Stack>;
    }

    function CustomPrompt(props) {
        const { when } = props;

        return <Prompt when={when} message={() => t("lazadaAutomation.unSavedMessage")} />;
    }

    function CustomVariantTextField(props) {
        return (
                <div style={{ width: 436}}>
                    <UnifiedPeoplePicker
                        selectedItemsListProps={{
                            selectedItems: [...cc.map(_email => (
                                { key: _email, text: _email }
                            ))],
                            removeButtonAriaLabel: 'Remove',
                            onItemsRemoved: (removeItems, removeItemIndex) => {
                                const currentSelectedItemsCopy = [...cc];
                                currentSelectedItemsCopy.splice(removeItemIndex, 1);
                                // this.setState({ currentSelectedItems: [...currentSelectedItemsCopy] });
                                setCC([...currentSelectedItemsCopy])
        
                                setIsChange(true);
                                
                            },
                        }}
                        floatingSuggestionProps={{
                            isSuggestionsVisible: false
                        }}
                        inputProps={{
                            'aria-label': 'Add people',
                            onBlur: (e) => {
                                if (checkEmail(e.target.value) && !cc.includes(e.target.value)) {
                                    const currentSelectedItemsCopy = [...cc, e.target.value];
                    
                                    setCC(currentSelectedItemsCopy)
                
                                    setIsChange(true);
                                }
                                
                            }
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                if (checkEmail(e.target.value) && !cc.includes(e.target.value)) {
                                    const currentSelectedItemsCopy = [...cc, e.target.value];
                
                                    setCC(currentSelectedItemsCopy)

                                    setIsChange(true);
                                }
                            }
                        }} 
                        // eslint-disable-next-line react/jsx-no-bind
                        customClipboardType="recipients"
                    />

                </div>
        );
    }

    const handleCancel = (e) => {
        history.push({
            pathname: "/etax/sources/lazada",
            state: {},
        });
    };

    useEffect(() => {
        instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then((tokenResponse) => {
                axios
                    .get(API_URL + "/sources/emails", {
                        headers: {
                            Authorization: "Bearer " + tokenResponse.accessToken,
                            "cid": companyId
                        },
                    })
                    .then(
                        (response) => {
                            console.log("Email sources: ", response);

                            if (response.data) {
                                if (!response.data.disabled) {
                                    setEnableEmail(true);
                                }
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
            })
            .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
            });

            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div style={homeStyles}>
                <CustomPrompt when={isChange} />
                {showMessageBarSuccess && (
                    <MessageBar messageBarType={MessageBarType.success} isMultiline={false} onDismiss={() => setShowMessageBarSuccess(false)} dismissButtonAriaLabel="Close">
                        {t("company.updateSuccess")}
                    </MessageBar>
                )}
                {showMessageBarError && (
                    <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={() => setShowMessageBarError(false)} dismissButtonAriaLabel="Close">
                        {t("company.updateFail")}
                    </MessageBar>
                )}

                <Stack horizontal horizontalAlign="center">
                    <Stack style={{ background: "#FAF9F8" }}>
                        <form onSubmit={handleSubmit} onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                            }
                        }}>
                            <Stack horizontal horizontalAlign="space-between">
                                <Stack verticalAlign="center">
                                    <Stack horizontal>
                                        <h1>
                                            <Icon iconName="Robot" style={{ color: "#106ebe" }} /> Lazada Automation
                                        </h1>
                                    </Stack>
                                </Stack>
                                <Stack verticalAlign="center" styles={{ root: { marginTop: 7 } }}>
                                    <Toggle
                                        styles={{ root: { width: 80 } }}
                                        onText={t("company.on")}
                                        offText={t("company.off")}
                                        checked={automationEnable}
                                        onChange={(e, checked) => {
                                            setIsChange(true);
                                            setAutomationEnable(checked);
                                        }}
                                        disabled={isLoading}
                                    />
                                </Stack>
                            </Stack>
                            <div style={cardStyle}>
                                <Stack horizontal horizontalAlign="space-between">
                                    <Stack verticalAlign="center" styles={{ root: { marginTop: 10 } }}>
                                        <Stack horizontal>
                                            <h2>
                                                <Icon iconName="Sync" style={{ color: "#106ebe", marginTop: "3px", marginRight: "7px" }} />
                                            </h2>{" "}
                                            <h2>{t("lazadaAutomation.triggerStatus")}</h2>
                                        </Stack>
                                    </Stack>

                                    <Stack verticalAlign="center">
                                        <Dropdown
                                            label={t("lazadaAutomation.orderStatus")}
                                            styles={{ root: { width: 150, marginTop: -15 } }}
                                            options={orderStatusOptions}
                                            selectedKey={triggerStatus}
                                            onChange={(e, selectedOption) => {
                                                setIsChange(true);
                                                setTriggerStatus(selectedOption.key);
                                            }}
                                            disabled={!automationEnable}
                                        />
                                    </Stack>
                                </Stack>
                                <br />

                                <div>
                                    <Separator />
                                </div>
                                <br />

                                <Stack horizontal>
                                    <h2>
                                        <Icon iconName="TextDocument" style={{ color: "#106ebe", marginRight: "7px" }} />
                                    </h2>{" "}
                                    <h3>{t("lazadaAutomation.receiptTaxInvoice")}</h3>
                                </Stack>
                                <Stack horizontal horizontalAlign="space-between">
                                    <Stack verticalAlign="center" styles={{ root: { marginTop: 10 } }}>
                                        <h3>{t("lazadaAutomation.number")}</h3>
                                    </Stack>
                                    <Stack verticalAlign="center">
                                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                                            <TextField
                                                label={t("lazadaAutomation.prefix")}
                                                styles={{ root: { width: 120, marginTop: -15 } }}
                                                value={receiptTaxInvoice.number.prefix}
                                                onChange={(e, newValue) => {
                                                    setIsChange(true);
                                                    setIsChangeNumberReceiptTaxinvoice(true);
                                                    setReceiptTaxInvoice((prev) => ({ number: { ...prev.number, prefix: newValue } }));
                                                }}
                                                disabled={!automationEnable}
                                            />
                                            <Dropdown
                                                label={t("lazadaAutomation.year")}
                                                styles={{ root: { width: 75, marginTop: -15 } }}
                                                options={yearFormatOptions}
                                                selectedKey={receiptTaxInvoice.number.year}
                                                onChange={(e, selectedOption) => {
                                                    setIsChange(true);
                                                    setIsChangeNumberReceiptTaxinvoice(true);
                                                    setReceiptTaxInvoice((prev) => ({ number: { ...prev.number, year: selectedOption.key } }));
                                                }}
                                                disabled={!automationEnable}
                                            />
                                            <Dropdown
                                                label={t("lazadaAutomation.month")}
                                                styles={{ root: { width: 70, marginTop: -15 } }}
                                                options={monthFormatOptions}
                                                selectedKey={receiptTaxInvoice.number.month}
                                                onChange={(e, selectedOption) => {
                                                    setIsChange(true);
                                                    setIsChangeNumberReceiptTaxinvoice(true);
                                                    setReceiptTaxInvoice((prev) => ({ number: { ...prev.number, month: selectedOption.key } }));
                                                }}
                                                disabled={!automationEnable}
                                            />
                                            <Dropdown
                                                label={t("lazadaAutomation.day")}
                                                styles={{ root: { width: 70, marginTop: -15 } }}
                                                options={dayFormatOptions}
                                                selectedKey={receiptTaxInvoice.number.day}
                                                onChange={(e, selectedOption) => {
                                                    setIsChange(true);
                                                    setIsChangeNumberReceiptTaxinvoice(true);
                                                    setReceiptTaxInvoice((prev) => ({ number: { ...prev.number, day: selectedOption.key } }));
                                                }}
                                                disabled={!automationEnable}
                                            />
                                            <TextField
                                                label={t("lazadaAutomation.suffix")}
                                                styles={{ root: { width: 150, marginTop: -15 } }}
                                                value={receiptTaxInvoice.number.suffix}
                                                onChange={(e, newValue) => {
                                                    if (!/\D/.test(newValue)) {
                                                        setIsChange(true);
                                                        setIsChangeNumberReceiptTaxinvoice(true);
                                                        setReceiptTaxInvoice((prev) => ({ number: { ...prev.number, suffix: newValue, originalSuffix: newValue } }));
                                                    }
                                                }}
                                                required={automationEnable}
                                                disabled={!automationEnable}
                                            />
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <ExampleNumberRCT numberObj={receiptTaxInvoice} />

                                <br />

                                <div>
                                    <Separator />
                                </div>

                                <br />

                                <Stack horizontal horizontalAlign="space-between">
                                    <Stack vertical>
                                        <Stack horizontal>
                                            <h2>
                                                <Icon iconName="AutoFillTemplate" style={{ color: "#106ebe", marginRight: "7px" }} />
                                            </h2>{" "}
                                            <h3 style={{ marginTop: "18px" }}>{t("lazadaAutomation.createOnlyBillingAddress")}</h3>
                                        </Stack>
                                        <span>
                                            <b>{t("lazadaAutomation.on")}</b> {t("lazadaAutomation.onCreateOnlyBillingAddressNote")}
                                        </span>
                                        <br />
                                        <span>
                                            <b>{t("lazadaAutomation.off")}</b> {t("lazadaAutomation.offCreateOnlyBillingAddressNote")}
                                        </span>
                                    </Stack>
                                    <Stack verticalAlign="start" styles={{ root: { marginTop: 17 } }}>
                                        <Toggle
                                            styles={{ root: { width: 80 } }}
                                            onText={t("company.on")}
                                            offText={t("company.off")}
                                            checked={onlyBillingAddress}
                                            onChange={(e, checked) => {
                                                setIsChange(true);
                                                setOnlyBillingAddress(checked);
                                            }}
                                            disabled={!automationEnable}
                                        />
                                    </Stack>
                                </Stack>

                                <br />

                                <div>
                                    <Separator />
                                </div>

                                <br />

                                <Stack horizontal horizontalAlign="space-between">
                                    <Stack horizontal>
                                        <h2>
                                            <Icon iconName="DeliveryTruck" style={{ color: "#106ebe", marginRight: "7px" }} />
                                        </h2>{" "}
                                        <h3 style={{ marginTop: "17px" }}>{t("lazadaAutomation.showShippingFee")}</h3>
                                    </Stack>

                                    <Stack verticalAlign="center" styles={{ root: { marginTop: 7 } }}>
                                        <Toggle
                                            styles={{ root: { width: 80 } }}
                                            onText={t("company.on")}
                                            offText={t("company.off")}
                                            checked={showShippingFee}
                                            onChange={(e, checked) => {
                                                setIsChange(true);
                                                setShowShippingFee(checked);
                                            }}
                                            disabled={!automationEnable}
                                        />
                                    </Stack>
                                </Stack>

                                <br />

                                <div>
                                    <Separator />
                                </div>

                                <br />

                                <Stack horizontal horizontalAlign="space-between">
                                    <Stack vertical>
                                        <Stack horizontal>
                                            <h2>
                                                <Icon iconName="MailForward" style={{ color: "#106ebe", marginRight: "7px" }} />
                                            </h2>{" "}
                                            <h3 style={{ marginTop: "18px" }}>{t("lazadaAutomation.autoSendEmail")}</h3>
                                        </Stack>
                                        {enableEmail && (
                                            <Stack>
                                                <span>
                                                    <b>{t("lazadaAutomation.on")}</b> {t("lazadaAutomation.onAutoSendEmail")}
                                                </span>
                                                <br />
                                                <span>
                                                    <b>{t("lazadaAutomation.off")}</b> {t("lazadaAutomation.offAutoSendEmail")}
                                                </span>
                                                <br />
                                            </Stack>
                                        )}
                                    </Stack>

                                    <Stack verticalAlign="start" styles={{ root: { marginTop: 17 } }}>
                                        {enableEmail ? (
                                            <Toggle
                                                styles={{ root: { width: 80 } }}
                                                onText={t("company.on")}
                                                offText={t("company.off")}
                                                checked={sendEmail}
                                                onChange={(e, checked) => {
                                                    setIsChange(true);
                                                    setSendEmail(checked);
                                                    setSendEmailOnlyBillingAddress(checked);
                                                }}
                                                disabled={!automationEnable}
                                            />
                                        ) : (
                                            <Link
                                                styles={{ root: { marginTop: -7 } }}
                                                onClick={() => {
                                                    history.push({
                                                        pathname: "/settings/emails",
                                                        state: {},
                                                    });
                                                }}
                                                disabled={!automationEnable}
                                            >
                                                <span style={{ margin: "0px 10px" }}>{t("lazadaAutomation.emailSetting")}</span>
                                            </Link>
                                        )}
                                    </Stack>
                                </Stack>
                                {sendEmail ? (
                                    <>
                                        <Stack horizontal horizontalAlign="space-between">
                                            <Stack horizontal>
                                                <h2>
                                                    <Icon iconName="MailForward" style={{ color: "#106ebe", marginRight: "7px" }} />
                                                </h2>{" "}
                                                <h3 style={{ marginTop: "18px" }}>{t("lazadaAutomation.sendEmailOnlyBillingAddress")}</h3>
                                            </Stack>

                                            <Stack verticalAlign="center" styles={{ root: { marginTop: 7 } }}>
                                                <Toggle
                                                    styles={{ root: { width: 80 } }}
                                                    onText={t("company.on")}
                                                    offText={t("company.off")}
                                                    checked={sendEmailOnlyBillingAddress}
                                                    onChange={(e, checked) => {
                                                        setIsChange(true);
                                                        setSendEmailOnlyBillingAddress(checked);
                                                    }}
                                                    disabled={!automationEnable}
                                                />
                                            </Stack>
                                        </Stack>
                                        <Stack horizontal>
                                            <Stack styles={{ root: { width: "30%" } }}>
                                                <h3>{t("lazadaAutomation.cc")}</h3>
                                            </Stack>
                                            <CustomVariantTextField />
                                        </Stack>
                                    </>
                                ) : null}
                            </div>

                            <br />
                            <br />
                            <Separator>{t("company.saveSeparator")}</Separator>
                            <br />
                            <br />

                            <Stack horizontal horizontalAlign="center" tokens={stackTokens}>
                                {!buttonClick && <PrimaryButton name="save" text={t("company.save")} iconProps={{ iconName: "Save" }} type="submit" style={{ width: "125px", letterSpacing: "1px" }} />}
                                {!buttonClick && <DefaultButton name="back" text={t("company.back")} type="button" onClick={handleCancel} />}

                                {buttonClick && <Spinner label={t("company.saving")} labelPosition="right" />}
                            </Stack>
                        </form>
                    </Stack>
                </Stack>
            </div>
        </>
    );
};

export default LazadaAutomation;
