import React, { useEffect, useState} from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./styles/style.css";
import "./ThemeSelector.css"; // Import CSS module
import { useTranslation } from "react-i18next";
import { TextClearFormattingRegular,SaveRegular } from "@fluentui/react-icons";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import axios from "axios";
import {
  EditorState,
  ContentState,
  convertToRaw,
  // htmlToDraft,
} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  Button,
  Text,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  MenuButton,
  Field
} from "@fluentui/react-components";
import {
  Toaster,
  useToastController,
  ToastTitle,
  ToastTrigger,
  Toast,
  useId,
  Link,
  Divider,
  Switch,
  Spinner
} from "@fluentui/react-components";
import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../authConfig.js";

const API_URL_FORM = process.env.REACT_APP_REQUEST_FORM_API_URL;

const ManagementPolicyPrivacy = ({
 policyMessage,formId,isLoadComplete
}) => {

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [isEnabled, setIsEnabled] = useState(null);
  const [isComplete, setIsComplete]=useState(true)

useEffect(()=>{
  if (policyMessage ){
     setIsEnabled(policyMessage.useBothLanguage)
  }
 
},[policyMessage])


const toggleLanguagePrivacyPolicyState = async () => {
    try {
      // Make a POST request to the backend to toggle the form state
      setIsComplete(false);
      // const response = await axios.post(
      //   "http://localhost:7071/api/FormCloseOpen  "

       let tokenResponse;
          try {
            tokenResponse = await instance.acquireTokenSilent({
            ...silentRequest,
            account: account,
            });
          } catch (error) {
            console.error("Error acquiring token: ", error);
            instance.acquireTokenRedirect(silentRequest);
            return; 
          }

      const response = await axios.patch(
        `${API_URL_FORM}/forms/${formId}`,
        {
          "replace": "/enabledBothLanguage",
          "value": !isEnabled,
        },
        {
          headers: {
            Authorization: "Bearer " + tokenResponse.accessToken,
          },
        }
      );

      console.log("response", response);

      if (response.data.statusCode === 200) {
        console.log("Form Status changed successfully");
        // Optionally, you can reset the state or show a success message to the user
          setIsEnabled((prevState) => !prevState);
          setIsComplete(true);
        
      } else {
        throw new Error("Failed to Changed");
      }
    } catch (error) {
      console.error("Error toggling form state:", error);
    } finally {
    
    }
  };



const localization = {

  th : {
  locale: "th",
  translations: {
    "generic.add": "เพิ่ม",
    "generic.cancel": "ยกเลิก",

    // BlockType
    "components.controls.blocktype.h1": "หัวเรื่อง 1",
    "components.controls.blocktype.h2": "หัวเรื่อง 2",
    "components.controls.blocktype.h3": "หัวเรื่อง 3",
    "components.controls.blocktype.h4": "หัวเรื่อง 4",
    "components.controls.blocktype.h5": "หัวเรื่อง 5",
    "components.controls.blocktype.h6": "หัวเรื่อง 6",
    "components.controls.blocktype.blockquote": "อ้างอิง",
    "components.controls.blocktype.code": "โค้ด",
    "components.controls.blocktype.blocktype": "บล็อก",
    "components.controls.blocktype.normal": "ปกติ",

    // Color Picker
    "components.controls.colorpicker.colorpicker": "ตัวเลือกสี",
    "components.controls.colorpicker.text": "สีตัวอักษร",
    "components.controls.colorpicker.background": "สีพื้นหลัง",

    // Embedded
    "components.controls.embedded.embedded": "ฝัง",
    "components.controls.embedded.embeddedlink": "ลิงก์ฝัง",
    "components.controls.embedded.enterlink": "ใส่ลิงก์",

    // Emoji
    "components.controls.emoji.emoji": "อีโมจิ",

    // FontFamily
    "components.controls.fontfamily.fontfamily": "แบบอักษร",

    // FontSize
    "components.controls.fontsize.fontsize": "ขนาดตัวอักษร",

    // History
    "components.controls.history.history": "ประวัติ",
    "components.controls.history.undo": "ย้อนกลับ",
    "components.controls.history.redo": "ทำซ้ำ",

    // Image
    "components.controls.image.image": "รูปภาพ",
    "components.controls.image.fileUpload": "อัปโหลดไฟล์",
    "components.controls.image.byURL": "ตาม URL",
    "components.controls.image.dropFileText":
      "ลากไฟล์หรือคลิกเพื่ออัปโหลด",

    // Inline
    "components.controls.inline.bold": "ตัวหนา",
    "components.controls.inline.italic": "ตัวเอียง",
    "components.controls.inline.underline": "ขีดเส้นใต้",
    "components.controls.inline.strikethrough": "ขีดฆ่า",
    "components.controls.inline.monospace": "Mono space",
    "components.controls.inline.superscript": "ตัวยก",
    "components.controls.inline.subscript": "ตัวห้อย",

    // Link
    "components.controls.link.linkTitle": "ชื่อของลิงก์",
    "components.controls.link.linkTarget": "ลิงก์เป้าหมาย",
    "components.controls.link.linkTargetOption": "เปิดลิงก์ในหน้าต่างใหม่",
    "components.controls.link.link": "เพิ่มลิงก์",
    "components.controls.link.unlink": "ลบลิงก์",

    // List
    "components.controls.list.list": "รายการ",
    "components.controls.list.unordered": "สัญลักษณ์แสดงหัวข้อย่อย",
    "components.controls.list.ordered": "ลำดับตัวเลข",
    "components.controls.list.indent": "ย่อหน้า",
    "components.controls.list.outdent": "ลดการย่อหน้า",

    // Remove
    "components.controls.remove.remove": "ลบ",

    // TextAlign
    "components.controls.textalign.textalign": "การจัดตำแหน่งข้อความ",
    "components.controls.textalign.left": "ชิดซ้าย",
    "components.controls.textalign.center": "กึ่งกลาง",
    "components.controls.textalign.right": "ชิดขวา",
    "components.controls.textalign.justify": "จัดให้พอดี",
  },
},
en:{
  locale: "en",
  translations: { 
  "generic.add": "Add",
  "generic.cancel": "Cancel",
  "components.controls.blocktype.h1": "Header 1",
  "components.controls.blocktype.h2": "Header 2",
  "components.controls.blocktype.h3": "Header 3",
  "components.controls.blocktype.h4": "Header 4",
  "components.controls.blocktype.h5": "Header 5",
  "components.controls.blocktype.h6": "Header 6",
  "components.controls.blocktype.blockquote": "Block Quote",
  "components.controls.blocktype.code": "Code",
  "components.controls.blocktype.blocktype": "Block",
  "components.controls.blocktype.normal": "Normal",
  "components.controls.colorpicker.colorpicker": "Color picker",
  "components.controls.colorpicker.text": "Text",
  "components.controls.colorpicker.background": "Background Color",
  "components.controls.embedded.embedded": "Embedded",
  "components.controls.embedded.embeddedlink": "Embedded Link",
  "components.controls.embedded.enterlink": "Insert Link",
  "components.controls.emoji.emoji": "Emoji",
  "components.controls.fontfamily.fontfamily": "Font Family",
  "components.controls.fontsize.fontsize": "Font Size",
  "components.controls.history.history": "History",
  "components.controls.history.undo": "Undo",
  "components.controls.history.redo": "Redo",
  "components.controls.image.image": "Image",
  "components.controls.image.fileUpload": "File Upload",
  "components.controls.image.byURL": "By URL",
  "components.controls.image.dropFileText": "Drop File Text Or Click Upload",
  "components.controls.inline.bold": "Bold",
  "components.controls.inline.italic": "Italic",
  "components.controls.inline.underline": "Underline",
  "components.controls.inline.strikethrough": "Strike Through",
  "components.controls.inline.monospace": "Mono Space",
  "components.controls.inline.superscript": "Super Script",
  "components.controls.inline.subscript": "Sub Script",
  "components.controls.link.linkTitle": "Link Title",
  "components.controls.link.linkTarget": "Link Target",
  "components.controls.link.linkTargetOption": "Link Target Option",
  "components.controls.link.link": "Add Link",
  "components.controls.link.unlink": "Delete Link",
  "components.controls.list.list": "List",
  "components.controls.list.unordered": "Unordered",
  "components.controls.list.ordered": "Ordered",
  "components.controls.list.indent": "Indent",
  "components.controls.list.outdent": "Outdent",
  "components.controls.textalign.textalign": "Text Align",
  "components.controls.textalign.left": "Left",
  "components.controls.textalign.center": "Center",
  "components.controls.textalign.right": "Right",
  "components.controls.textalign.justify": "Justify"
  }
}
};


 const [open, setOpen] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const { t ,i18n} = useTranslation();

 const [locale, setLocale] = useState(i18n.language);

useEffect(() => {
    setLocale(i18n.language);  // Update locale based on i18n language
  }, [i18n.language]);

  const myBlockStyleFn = (contentBlock) => {
    const type = contentBlock.getType();
    if (type === "blockquote") {
      return "public-DraftStyleDefault";
    }
  };

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [contentState, setContentState] = useState("");
 const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);

  };

  const handleContentStateChange = (contentState) => {
    setContentState(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };


const [editorStateSecond, setEditorStateSecond] = useState(EditorState.createEmpty());
const [contentStateSecond, setContentStateSecond] = useState("");
 const handleEditorStateChangeSecond = (editorStateSecond) => {
    setEditorStateSecond(editorStateSecond);

  };

  const handleContentStateChangeSecond = (contentStateSecond) => {
    setContentStateSecond(draftToHtml(convertToRaw(editorStateSecond.getCurrentContent())));
  };

useEffect(()=>{
  if (policyMessage?.thaiMessage && policyMessage?.thaiMessage !== null){
    const html = policyMessage.thaiMessage
    const contentBlock = htmlToDraft(html);
    if (contentBlock){
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState)
      setEditorState(editorState);
      setContentState(policyMessage.thaiMessage)
    }
  }

   if (policyMessage?.englishMessage && policyMessage?.englishMessage !== null){
    const html = policyMessage.englishMessage
    const contentBlock = htmlToDraft(html);
    if (contentBlock){
      const contentStateSecond = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorStateSecond = EditorState.createWithContent(contentStateSecond)
      setEditorStateSecond(editorStateSecond);
      setContentStateSecond(policyMessage.englishMessage)
    }
  }

  if (policyMessage?.english === true){
    setIsEnabled(true)
  }else if (policyMessage?.english === false){
    setIsEnabled(false)
  }
},[policyMessage])





   const handleSavePolicyMessage = async () => {
    console.log("contentState from handle Save data", contentState);
    inProgress()
    setDismissTimeout(40000)
    try {
      let tokenResponse;
        try {
            tokenResponse = await instance.acquireTokenSilent({
            ...silentRequest,
            account: account,
            });
        } catch (error) {
            console.error("Error acquiring token: ", error);
            instance.acquireTokenRedirect(silentRequest);
            return; 
        }

      const response = await axios.patch(
        `${API_URL_FORM}/forms/${formId}`,
        {
          // Ensure the request payload is structured correctly

          "replace": "/privacyPolicyMessage",
          "value": contentState,
          "valueSecond":contentStateSecond
        },
        {
          headers: {
            Authorization: "Bearer " + tokenResponse.accessToken,
          },
        }
      );

      if (response.status === 200) {
        console.log("Data saved successfully");
        setDismissTimeout(0)
        EditConfirm();
        // Optionally, you can reset the state or show a success message to the user
    
      } else {
        throw new Error("Failed to save data");
      }
    } catch (error) {
      console.error("Error saving data:", error.message);
      EditUnsuccess();
      // Handle error: show error message to the user or retry saving
      setDismissTimeout(0)
    }
  };


  const handleResetPolicyMessage = async () => {
    console.log("contentState from handle Save data", contentState);
    inProgress()
    setDismissTimeout(40000)

    try {
      let tokenResponse;
        try {
            tokenResponse = await instance.acquireTokenSilent({
            ...silentRequest,
            account: account,
            });
        } catch (error) {
            console.error("Error acquiring token: ", error);
            instance.acquireTokenRedirect(silentRequest);
            return; 
        }

      const response = await axios.patch(
        `${API_URL_FORM}/forms/${formId}`,
        {
          // Ensure the request payload is structured correctly

          "replace": "/privacyPolicyMessage",
          "value": "",
          "valueSecond":""
        },
        {
          headers: {
            Authorization: "Bearer " + tokenResponse.accessToken,
          },
        }
      );

      if (response.status === 200) {
        console.log("Data saved successfully");
        setEditorState("")        
        setContentState("")
        setEditorStateSecond("")
        setContentStateSecond("")
        EditReset();
        setDismissTimeout(0)
        // Optionally, you can reset the state or show a success message to the user
      } else {
        throw new Error("Failed to save data");
       
      }
    } catch (error) {
      console.error("Error saving data:", error.message);
      EditUnsuccess();
      setDismissTimeout(0)
      // Handle error: show error message to the user or retry saving
    }
  };


  
 

  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
    const EditConfirm = () =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {t("requestFormAdminFormEditor.saveSuccessfully")}
        </ToastTitle>
      </Toast>,
      { intent: "success" }
    );



  const EditUnsuccess = () =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {t("requestFormAdminFormEditor.failToSave")}
        </ToastTitle>
      </Toast>,
      { intent: "error" }
    );

    const EditReset = () =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
           {t("requestFormAdminFormEditor.deletedDataPrivacyPolicy")}
        </ToastTitle>
      </Toast>,
      { intent: "success" }
    );

 const [timeout, setDismissTimeout] = React.useState(0);
  const inProgress = () =>
    dispatchToast(
      <Toast >
        <ToastTitle
          
          media={<Spinner size="tiny" />}
        >
         {t("requestFormAdminFormEditor.sendingData")}
        </ToastTitle>
      </Toast>,
    { timeout, intent: "info" }
    );



const containsAlphabet = (str) => /[a-zA-Z]/.test(str);

// Check conditions when English is enabled (checks second editor)
const filterString = contentStateSecond.replaceAll("&nbsp;","")
const filterStringThai = contentState.replaceAll("&nbsp;","")
const isButtonDisabledByEnglish = filterString.length < 28 || !containsAlphabet(filterString) 
const isButtonDisabled = filterStringThai.length < 28
const [bothFillNotPass, setBothFillNotPass] = useState(false);

useEffect(() => {
    if (isEnabled) {
        // When English mode is enabled, both editors need to pass the conditions
        if (isButtonDisabled || isButtonDisabledByEnglish) {
            setBothFillNotPass(true);  // Disable button
        } else {
            setBothFillNotPass(false); // Enable button
        }
    } else {
        // When English mode is not enabled, only check the first editor
        if (isButtonDisabled) {
            setBothFillNotPass(true);  // Disable button
        } else {
            setBothFillNotPass(false); // Enable button
        }
    }
}, [isButtonDisabled, isButtonDisabledByEnglish, isEnabled, contentState, contentStateSecond]);


  return (
    <>
   
                   
               

   <div   style={{display:"flex",flexDirection:"column"}}>
    <div className="column" style={{justifyContent:"center",}}>
      <Toaster toasterId={toasterId} />
        <div
                    style={{
                      width: "900px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignContent: "flex-start",
                      textAlign: "center",
                      height: "80px",
                    }}
                  >

                       <h2 style={{ color: "black" }}>
                     {t("requestFormAdminFormEditor.addingPrivacyPolicy")}
                    </h2>
                  </div>            
    </div>


{!isLoadComplete?  <div className="stack-background-for-loading">

                      <div
                        style={{
                          display: "flex",
                          width: "830px",
                          justifyContent: "center",
                        }}
                      >
                        <div style={{ marginRight: "10px", marginTop: "-3px" }}>
                          <Spinner size="extra-large" label={t("requestFormAdminFormEditor.loading")} ></Spinner>
                        </div>
                        
                      </div>
                    </div> :   <>
       <div  style={{  display: 'flex',
                flexDirection: 'column',
                width: '830px',
                padding: '2rem',
                paddingTop: '1rem',
                paddingBottom: '1rem',
                color: 'black',
                backgroundColor: 'rgb(255, 255, 255)',
                
                }}>
        <div style={{display:"flex"}}>

        <div style={{width:"20%",height:"100px"}}
        
        >
        <div style={{width:"100%",height:"100px"}}> <Text weight="bold"> {t("requestFormAdminFormEditor.termsOfService")}</Text>  </div>
             
             {/* <div style={{width:"100%",height:"250px"}}>  <Text weight="bold">เพิ่มคำอธิบาย</Text>  </div> */}
        </div>
        <div style={{width:"80%",height:"100px"}}>

        <div style={{width:"100%",height:"100px"}}> {t("requestFormAdminFormEditor.termsOfDescription")} </div>

         
        

        </div>
        </div>
        <Divider style={{marginTop:"2%"}}/>
    
        <div style={{display:"flex"}}>
            <div style={{width:"100%",minheight:"400px"}}> 
               <div style={{width:"100%",height:"50px",display:"flex",justifyContent:"center",alignItems:"center",position:"relative",marginTop:"2%"}}> <Text weight="bold" style={{fontSize:"20px"}}>{t("requestFormAdminFormEditor.privacyPolicy")} </Text>  
               
                    </div>
                   <h3>{t("requestFormAdminFormEditor.thaiLanguage")}</h3>
                 <Editor
                              editorState={editorState}
                              toolbarClassName="editor-toolbar"
                              wrapperClassName="editor-wrapper"
                              editorClassName="editorPrivacy"
                              onEditorStateChange={handleEditorStateChange}
                              // onEditorStateChange={this.onEditorStateChange}
                              onContentStateChange={handleContentStateChange}
                              // toolbar={toolbar}
                              customBlockRenderFunc={myBlockStyleFn}
                              toolbar={{
                                inline: { inDropdown: true },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                                link: { inDropdown: true },
                                history: { inDropdown: true },
                                
                                fontFamily: {
                                    options: [
                                      'Arial',          // Web-safe
                                      'Georgia',        // Web-safe
                                      'Tahoma',         // Web-safe
                                      'Times New Roman',// Web-safe
                                      'Verdana',        // Web-safe
                                      'Courier New',    // Web-safe
                                      'Trebuchet MS',   // Web-safe
                                      'Lucida Console', // Web-safe
                                      'Comic Sans MS',  // Web-safe
                                    ],
                                  },
                                // image: {
                                //   uploadCallback: uploadImageCallBack,
                                //   alt: { present: true, mandatory: true },
                                // },
                                fontSize: {
                                  options: [
                                    8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36,
                                  ],

                                  className: undefined,
                                  component: undefined,
                                  dropdownClassName: undefined,
                                  defaultFontSize: 14,
                                },
                              }}
                              // localization={localization}
                              localization={localization[locale]}
                              placeholder="กำหนดตัวอักษรขั้นต่ำ 20 ตัวอักษร"
                              // localization={{
                              //   locale: "th",
                              //   translations: th,
                              // }}
                              spellCheck
                              locale="th"
                              // ref={EditorTextRefFirst}
                              // toolbarCustomButtons={[<CustomComponent />]}
                              // onFocus={() => {
                              //   handleContentStateChange(contentInitialValue);
                              //   handleEditorStateChange(initialValue);
                              // }}
                            /> 

            <div style={{display:"flex",marginTop:"3%"}}  >
               <h3 style={{width:"120px"}}>{t("requestFormAdminFormEditor.englishLanguage")}</h3>
                 <div style={{ display: "flex",marginTop:"10px"}}>
                     {isComplete ?  
                     <>
                     <div>
                        <Switch
                          defaultChecked={isEnabled} // Set the initial checked state
                          checked={isEnabled? true : false}
                          onClick={() => {
                            toggleLanguagePrivacyPolicyState()
                            
                          }} // Call the toggleFormState function on click
                          style={{
                            color: "#16263D",
                            fontStyle: "#16263D",
                          }}
                      
                        />
                      </div>
                         <div style={{ marginTop: "5px" }}>
                        {isEnabled ? (
                          <Field
                            validationMessage={
                              <Text
                                style={{
                                  color: "green",
                                }}
                              >
                                 {t("requestFormAdminFormEditor.opendUseEnglish")}
                              </Text>
                            }
                            validationState="none"
                          ></Field>
                        ) : (
                          <Field
                            validationMessage={
                              <Text
                                style={{
                                  color: "black",
                                }}
                              >
                               {t("requestFormAdminFormEditor.openUseEnglish")}
                              </Text>
                            }
                            validationState="none"
                          ></Field>
                        )}
                      </div>
                      </>
                      :  
                      
                      <div  style={{width:"100%"}}>
                      <div
                        style={{
                          marginTop: "0px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        <div style={{ marginRight: "10px", marginTop: "5px" }}>
                          <Spinner size="tiny" label={t("requestFormAdminFormEditor.loading")}></Spinner>
                        </div>
                       
                      </div>
                    </div>
                    }
                  
                    </div>
                    </div> 
                            {isEnabled && 
                            <>
                            
                           <Editor
                              editorState={editorStateSecond}
                              toolbarClassName="editor-toolbar"
                              wrapperClassName="editor-wrapper"
                              editorClassName="editorPrivacy"
                              onEditorStateChange={handleEditorStateChangeSecond}
                              // onEditorStateChange={this.onEditorStateChange}
                              onContentStateChange={handleContentStateChangeSecond}
                              // toolbar={toolbar}
                              customBlockRenderFunc={myBlockStyleFn}
                              toolbar={{
                                inline: { inDropdown: true },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                                link: { inDropdown: true },
                                history: { inDropdown: true },
                                 fontFamily: {
                                  options: [
                                    'Arial',          // Web-safe
                                    'Georgia',        // Web-safe
                                    'Tahoma',         // Web-safe
                                    'Times New Roman',// Web-safe
                                    'Verdana',        // Web-safe
                                    'Courier New',    // Web-safe
                                    'Trebuchet MS',   // Web-safe
                                    'Lucida Console', // Web-safe
                                    'Comic Sans MS',  // Web-safe
                                  ],
                                },
                                // image: {
                                //   uploadCallback: uploadImageCallBack,
                                //   alt: { present: true, mandatory: true },
                                // },
                                fontSize: {
                                  options: [
                                    8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36,
                                  ],

                                  className: undefined,
                                  component: undefined,
                                  dropdownClassName: undefined,
                                  defaultFontSize: 96,
                                },
                              }}
                              
                              localization={localization[locale]}
                              placeholder="Please enter at least 20 characters."
                              // localization={{
                              //   locale: "es",
                              //   translations: es,
                              // }}
                              spellCheck
                              // ref={EditorTextRefFirst}
                              // toolbarCustomButtons={[<CustomComponent />]}
                              // onFocus={() => {
                              //   handleContentStateChange(contentInitialValue);
                              //   handleEditorStateChange(initialValue);
                              // }}
                            /> 
                            </>
                            }
                      
                
            </div>

        </div>

        
    
  
      
     </div>


     
    <div style={{width:"100%",height:"50px",display:"flex",marginTop:"2%"}}>
        
        <div style={{width:"50%",height:"50px",display:"flex",justifyContent:"flex-end",alignItems:"center"}}>
        <div style={{paddingLeft:"5%"}}>
            <MenuButton 
            onClick={()=>setOpenSave(true)} 
            style={{minWidth:"130px"}} 
              icon={<SaveRegular   />} 
               menuIcon={null}  
                disabled={
                bothFillNotPass}
                appearance="primary" >   {t("requestFormAdminFormEditor.save")}</MenuButton></div> 
          <div style={{paddingRight:"5%"}}   >
        <Dialog modalType="modal"  
            open={openSave}
            onOpenChange={(event, data) => {
         
             setOpenSave(data.open);
            }}>
             
              <DialogSurface className="fluentui-dialog">
                <DialogBody className="dialog-body">
                  <DialogTitle>
                    {" "}
                    {t("requestFormAdminFormEditor.confirmToSave")}
                  </DialogTitle>

                  <DialogContent>
                    {" "}
                    {t("requestFormAdminFormEditor.wouldYouLikeToSaveData")}
                  </DialogContent>
                  <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                      <Button
                        appearance="primary"
                        className="button-dialog"
                       onClick={()=>handleSavePolicyMessage()}
                      >
                        {t("requestFormAdminFormEditor.confirm")}
                      </Button>
                    </DialogTrigger>

                    <DialogTrigger disableButtonEnhancement>
                      <Button appearance="secondary" className="button-dialog">
                        {t("requestFormAdminFormEditor.cancel")}
                      </Button>
                    </DialogTrigger>
                  </DialogActions>
                </DialogBody>
              </DialogSurface>
            </Dialog>
            
        </div>  
            
        </div>


        <div style={{width:"50%",height:"50px",display:"flex",justifyContent:"flex",alignItems:"center"}}>
          
          <div style={{paddingLeft:"5%"}}><MenuButton onClick={()=>setOpen(true)}   icon={<TextClearFormattingRegular   />}   menuIcon={null} style={{Width:"150px"}} >{t("requestFormAdminFormEditor.resetData")}</MenuButton></div>  
        
        
            <Dialog
        modalType="modal"
        open={open}
        onOpenChange={(event, data) => {
          // it is the users responsibility to react accordingly to the open state change
          setOpen(data.open);
        }}
      >
        {/* <MenuItem icon={<DeleteRegular />}> */}

        <DialogSurface className="fluentui-dialog">
          <DialogBody className="dialog-body">
            <DialogTitle>
              {" "}
              {t("requestFormAdminFormEditor.resetBackToOrigin")}
            </DialogTitle>
            <DialogContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
               {t("requestFormAdminFormEditor.wouldYouLikeToDeleteAll")}
              </div>
              <br />
            </DialogContent>
            <DialogActions>
              <DialogTrigger>
                <Button
                 
                //   onClick={() => deleteDataById(idItemDelete)}
                  appearance="primary"
                  className="button-dialog"
                  onClick={()=>{
                   handleResetPolicyMessage()
                  }}
                >
                  {t("requestFormAdminFormEditor.confirm")}
                </Button>
              </DialogTrigger>

              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary" className="button-dialog">
                  {t("requestFormAdminFormEditor.cancel")}
                </Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
        </div>
    </div>



  </>
     }


 

    
       
    </div>

        </>
  );
};

export default ManagementPolicyPrivacy;
