function toNum(str) {
  for (let i = 0; i < str.length; i++) {
    if (str[i] === '0') {
      str.replace('0', '');
    } else {
      break;
    }
  }
  return Number(str);
}

function convertNumberToWords(x) {
  let num = toNum(x);
  let str = '';
  let digits = num.toString().length;
  switch (digits) {
    case 1:
      str = single(num);
      break;
    case 2:
      str = twoDig(num);
      break;
    case 3:
      str = threeDig(num);
      break;
    case 4:
      str = fourDig(num);
      break;
    case 5:
      str = fiveDig(num);
      break;
    case 6:
      str = sixDig(num);
      break;
    case 7:
      str = sevenDig(num);
      break;
    case 8:
      str = eightDig(num);
      break;
    case 9:
      str = nineDig(num);
      break;
    case 10:
      str = tenDig(num);
      break;
    case 11:
      str = elevenDig(num);
      break;
    case 12:
      str = twelveDig(num);
      break;
    default:
      str = bigDig(x);
      break;
  }
  return str;
}

function single(num) {
  let str = '';
  switch (num) {
    case 0:
      str = 'Zero';
      break;
    case 1:
      str = 'One';
      break;
    case 2:
      str = 'Two';
      break;
    case 3:
      str = 'Three';
      break;
    case 4:
      str = 'Four';
      break;
    case 5:
      str = 'Five';
      break;
    case 6:
      str = 'Six';
      break;
    case 7:
      str = 'Seven';
      break;
    case 8:
      str = 'Eight';
      break;
    case 9:
      str = 'Nine';
      break;
    default:
      break;
  }
  return str;
}

function twoDig(num) {
  let str = '';
  if (num.toString()[1] === '0') {
    switch (num) {
      case 10:
        str = 'Ten';
        break;
      case 20:
        str = 'Twenty';
        break;
      case 30:
        str = 'Thirty';
        break;
      case 40:
        str = 'Fourty';
        break;
      case 50:
        str = 'Fifty';
        break;
      case 60:
        str = 'Sixty';
        break;
      case 70:
        str = 'Seventy';
        break;
      case 80:
        str = 'Eighty';
        break;
      case 90:
        str = 'Ninety';
        break;
      default:
        break;
    }
  } else if (num.toString()[0] === '1') {
    switch (num) {
      case 11:
        str = 'Eleven';
        break;
      case 12:
        str = 'Twelve';
        break;
      case 13:
        str = 'Thirteen';
        break;
      case 14:
        str = 'Fourteen';
        break;
      case 15:
        str = 'Fifteen';
        break;
      case 16:
        str = 'sixteen';
        break;
      case 17:
        str = 'Seventeen';
        break;
      case 18:
        str = 'Eighteen';
        break;
      case 19:
        str = 'Nineteen';
        break;
      default:
        break;
    }
  } else {
    str = twoDig(Number(num.toString()[0] + '0')) + ' ' + single(num % 10);
  }
  return str;
}

function threeDig(num) {
  let str = '';
  if (num % 100 === 0) {
    str = single(Number(num.toString()[0])) + ' Hundred';
  } else if (num.toString()[1] === '0') {
    str = threeDig(Number(num.toString()[0]) + '00') + ' and ' + single(num % 10);
  } else {
    str = threeDig(Number(num.toString()[0]) + '00') + ' and ' + twoDig(num % 100);
  }
  return str;
}

function fourDig(num) {
  let str = '';
  if (num % 1000 === 0) {
    str = single(Number(num.toString()[0])) + ' Thousand';
  } else {
    str = fourDig(Number(num.toString()[0]) + '000') + ' and ' + convertNumberToWords(num.toString().slice(1));
  }
  return str;
}

function fiveDig(num) {
  let str = '';
  if (num % 1000 === 0) {
    str = twoDig(Number(num.toString()[0] + num.toString()[1])) + ' Thousand';
  } else {
    str = fiveDig(Number(num.toString()[0] + num.toString()[1] + '000')) + ' and ' + convertNumberToWords(num.toString().slice(2));
  }
  return str;
}

function sixDig(num) {
  let str = '';
  if (num % 100000 === 0) {
    str = threeDig(Number(num.toString()[0] + num.toString()[1] + num.toString()[2])) + ' Thousand';
  } else {
    str = sixDig(Number(num.toString()[0] + '00000')) + ' and ' + convertNumberToWords(num.toString().slice(1));
  }

  return str;
}

function sevenDig(num) {
  let str = '';
  if (num % 1000000 === 0) {
    str = single(Number(num.toString()[0])) + ' Million';
  } else {
    str = sevenDig(Number(num.toString()[0] + '000000')) + ' and ' + convertNumberToWords(num.toString().slice(1));
  }

  return str;
}

function eightDig(num) {
  let str = '';
  if (num % 1000000 === 0) {
    str = twoDig(Number(num.toString()[0] + num.toString()[1])) + ' Million';
  } else {
    str = eightDig(Number(num.toString()[0] + num.toString()[1]) + '000000') + ' and ' + convertNumberToWords(num.toString().slice(2));
  }

  return str;
}

function nineDig(num) {
  let str = '';
  if (num % 1000000 === 0) {
    str = threeDig(Number(num.toString()[0] + num.toString()[1] + num.toString()[2])) + ' Million';
  } else {
    str =
      nineDig(Number(num.toString()[0] + num.toString()[1] + num.toString()[2]) + '000000') + ' and ' + convertNumberToWords(num.toString().slice(3));
  }
  return str;
}

function tenDig(num) {
  let str = '';
  if (num % 1000000000 === 0) {
    str = single(Number(num.toString()[0])) + ' Billion';
  } else {
    str = tenDig(Number(num.toString()[0] + '000000000')) + ' and ' + convertNumberToWords(num.toString().slice(1));
  }
  return str;
}

function elevenDig(num) {
  let str = '';
  if (num % 1000000000 === 0) {
    str = twoDig(Number(num.toString()[0] + num.toString()[1])) + ' Billion';
  } else {
    str = elevenDig(Number(num.toString()[0] + num.toString()[1] + '000000000')) + ' and ' + convertNumberToWords(num.toString().slice(2));
  }
  return str;
}

function twelveDig(num) {
  let str = '';
  if (num % 1000000000 === 0) {
    str = threeDig(Number(num.toString()[0] + num.toString()[1] + num.toString()[2])) + ' Billion';
  } else {
    str =
      twelveDig(Number(num.toString()[0] + num.toString()[1] + num.toString()[2] + '000000000')) +
      ' and ' +
      convertNumberToWords(num.toString().slice(3));
  }
  return str;
}

function bigDig(str) {
  let str1 = str.slice(0, -12);
  let str2 = str.slice(-12);
  return convertNumberToWords(str1) + ' Trillion and ' + convertNumberToWords(str2);
}

export function numberToTextEn(n) {
  if (n != null && !isNaN(n)) {
    var _number = Math.abs(n);
    var nums = _number.toString().split('.');
    var whole = convertNumberToWords(nums[0]);

    let result = '';
    if (nums.length === 2) {
      var fraction = convertNumberToWords(String(nums[1]).padEnd(2, '0').slice(0, 2));
      if (nums[0] !== '0' && fraction !== 'zero') {
        result = whole + ' Baht and ' + fraction + ' Satang';
      } else if (nums[0] !== '0' && fraction === 'zero') {
        result = whole + ' Baht';
      } else {
        result = fraction + ' Satang';
      }
    } else {
      if (nums[0] !== '0') {
        result = whole + ' Baht';
      } else {
        result = 'Zero Baht';
      }
    }

    return n >= 0 ? result : 'Negative ' + result;
  }

  return '';
}

const bahtxtConst = {
  defaultResult: 'ศูนย์บาทถ้วน',
  singleUnitStrs: ['', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า'],
  placeNameStrs: ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน'],
};

/**
 * @param {number[]} nums
 * @returns {string}
 */
function bahtxtNum2Word(nums) {
  let result = '';
  const len = nums.length;
  const maxLen = 7;

  if (len > maxLen) {
    // more than million
    const overflowIndex = len - maxLen + 1;
    const overflowNums = nums.slice(0, overflowIndex);
    const remainingNumbs = nums.slice(overflowIndex);
    return bahtxtNum2Word(overflowNums) + 'ล้าน' + bahtxtNum2Word(remainingNumbs);
  } else {
    for (let i = 0; i < len; i++) {
      const digit = nums[i];
      if (digit > 0) {
        result += bahtxtConst.singleUnitStrs[digit] + bahtxtConst.placeNameStrs[len - i - 1];
      }
    }
  }

  return result;
}

/**
 * @todo improve performance
 * @param {string} str
 * @returns {string}
 */
function bahtxtGrammarFix(str) {
  let result = str;

  result = result.replace(/หนึ่งสิบ/g, 'สิบ');
  result = result.replace(/สองสิบ/g, 'ยี่สิบ');
  result = result.replace(/สิบหนึ่ง/g, 'สิบเอ็ด');

  return result;
}

/**
 * bahtxtCombine baht and satang
 * and also adding unit
 *
 * @param {string} baht
 * @param {string} satang
 * @returns {string}
 */
function bahtxtCombine(baht, satang) {
  let result = '';

  if (baht === '' && satang === '') {
    result = bahtxtConst.defaultResult;
  } else if (baht !== '' && satang === '') {
    result = baht + 'บาทถ้วน';
  } else if (baht === '' && satang !== '') {
    result = satang + 'สตางค์';
  } else {
    result = baht + 'บาท' + satang + 'สตางค์';
  }

  return result;
}

/**
 * Change number to Thai pronunciation string
 *
 * @param {number} num
 * @returns {string}
 */
export function numberToTextTh(num) {
  // no null
  if (!num) return bahtxtConst.defaultResult;
  // no boolean
  if (typeof num === 'boolean') return bahtxtConst.defaultResult;
  // must be number only
  if (isNaN(Number(num))) return bahtxtConst.defaultResult;
  // not less than Number.MIN_SAFE_INTEGER
  if (num < Number.MIN_SAFE_INTEGER) return bahtxtConst.defaultResult;
  // no more than Number.MAX_SAFE_INTEGER
  if (num > Number.MAX_SAFE_INTEGER) return bahtxtConst.defaultResult;

  // set
  const positiveNum = Math.abs(num);

  // split baht and satang e.g. 432.214567 >> 432, 21
  const bahtStr = Math.floor(positiveNum).toString();
  /** @type {string} */
  const satangStr = ((positiveNum % 1) * 100).toFixed(2).split('.')[0];

  /** @type {number[]} */
  const bahtArr = Array.from(bahtStr).map(Number);
  /** @type {number[]} */
  const satangArr = Array.from(satangStr).map(Number);

  // proceed
  let baht = bahtxtNum2Word(bahtArr);
  let satang = bahtxtNum2Word(satangArr);

  // grammar
  baht = bahtxtGrammarFix(baht);
  satang = bahtxtGrammarFix(satang);

  // combine
  const result = bahtxtCombine(baht, satang);

  return num >= 0 ? result : 'ลบ' + result;
}
