export const bankList = [
  { bankCode: 'KBANK', bankNameTh: 'ธนาคารกสิกรไทย', bankNameEn: 'Kasikorn Bank' },
  { bankCode: 'SCB', bankNameTh: 'ธนาคารไทยพาณิชย์', bankNameEn: 'Siam Commercial Bank' },
  { bankCode: 'KTB', bankNameTh: 'ธนาคารกรุงไทย', bankNameEn: 'Krung Thai Bank' },
  { bankCode: 'TTB', bankNameTh: 'ธนาคารทีทีบี', bankNameEn: 'TTB Bank' },
  { bankCode: 'BBL', bankNameTh: 'ธนาคารกรุงเทพ', bankNameEn: 'Bangkok Bank' },
  { bankCode: 'BAY', bankNameTh: 'ธนาคารกรุงศรีอยุธยา', bankNameEn: 'Bank of Ayudhya' },
  { bankCode: 'UOB', bankNameTh: 'ธนาคารยูโอบี', bankNameEn: 'United Overseas Bank' },
  { bankCode: 'GSB', bankNameTh: 'ธนาคารออมสิน', bankNameEn: 'Government Savings Bank' },
  { bankCode: 'BAAC', bankNameTh: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร', bankNameEn: 'Bank for Agriculture and Agricultural Cooperatives' },
]

export const accountTypeList = [
  { accountTypeCode: 'SAVING', accountTypeNameTh: 'ออมทรัพย์', accountTypeNameEn: 'Saving' },
  { accountTypeCode: 'CURRENT', accountTypeNameTh: 'กระแสรายวัน', accountTypeNameEn: 'Current' },
]

export const getBankList = (language) => {
  let list = [];

  for (let i = 0; i < bankList.length; i++) {
    if (language === 'en') {
      list.push({
        key: bankList[i].bankCode,
        text: bankList[i].bankNameEn,
      });
    } else {
      list.push({
        key: bankList[i].bankCode,
        text: bankList[i].bankNameTh,
      });
    }
  }

  return list;
};

export const getAccountTypeList = (language) => {
  let list = [];

  for (let i = 0; i < accountTypeList.length; i++) {
    if (language === 'en') {
      list.push({
        key: accountTypeList[i].accountTypeCode,
        text: accountTypeList[i].accountTypeNameEn,
      });
    } else {
      list.push({
        key: accountTypeList[i].accountTypeCode,
        text: accountTypeList[i].accountTypeNameTh,
      });
    }
  }

  return list;
};