import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  DatePicker,
  DayOfWeek,
  mergeStyleSets,
  PrimaryButton,
  DefaultButton,
  IconButton,
  Dropdown,
  Stack,
  getTheme,
  Shimmer,
  Toggle,
  Separator,
  Spinner,
  ComboBox,
} from '@fluentui/react';
import { useLocation, useHistory } from 'react-router-dom';

import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import { getProvinceList, getDistrictList, getSubDistrictList, getProvince, getDistrict, getSubDistrict } from '../../../providers/AreaData';

import qrCode from 'qrcode';
import generatePayload from 'promptpay-qr';
import { getUnit, getUnitList } from '../../../providers/UnitData';
import { getCountry, getCountryList } from '../../../providers/CountryData';
import { numberToTextEn, numberToTextTh } from '../../../helpers/BahtWord';
import { getPercentWhtList } from '../../../providers/PercentWhtData';
import AutocompleteTextFieldContacts from '../../../helpers/customComponents/autocompleteTextField/contact/AutocompleteTextField';
import AutocompleteTextField from '../../../helpers/customComponents/autocompleteTextField/product/AutocompleteTextField';
import { getAccountTypeList, getBankList } from '../../../providers/BankData';

const API_URL = process.env.REACT_APP_API_URL;

const theme = getTheme();

const onFormatDate = (date, format) => {
  if (format === 'CE') {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear();
  } else {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + (date.getFullYear() + 543);
  }
};

const controlClass = mergeStyleSets({
  control: {
    margin: '0 0 15px 0',
    maxWidth: '300px',
  },
  hover: {
    selectors: {
      '&:hover': { background: theme.palette.neutralLight },
    },
  },
});

const dropdownStyles = {
  dropdown: { width: 150 },
};

const formatDate = (d) => {
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

const BillingNote = () => {
  const location = useLocation();
  const history = useHistory();

  const { t, i18n } = useTranslation();

  const [data] = useState(location.state);

  if (data?.getAccessToken) {
    if (data.path === 'stripe') {
      sessionStorage.removeItem('stripe-backUrl');
    } else if (data.path === 'paypal') {
      sessionStorage.removeItem('paypal-backUrl');
    }
  }

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  console.log('Render');

  const [company, setCompany] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [companyPhone, setCompanyPhone] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [companyTaxId, setCompanyTaxId] = useState('');
  const [companyBranchText, setCompanyBranchText] = useState('');
  const [companyAllAddress, setCompanyAllAddress] = useState('');

  //Customer Detail
  const [name, setName] = useState('');
  const [buildingNumber, setBuildingNumber] = useState('');
  const [address, setAddress] = useState('');
  const [streetPrefix, setStreetPrefix] = useState('');
  const [streetName, setStreetName] = useState('');
  const [provincePrefix, setProvincePrefix] = useState('');
  const [province, setProvince] = useState({ key: '', text: '' });
  const [subDistrictPrefix, setSubDistrictPrefix] = useState('');
  const [subDistrict, setSubDistrict] = useState({ key: '', text: '' });
  const [subDistrictList, setSubDistrictList] = useState([]);
  const [districtPrefix, setDistrictPrefix] = useState('');
  const [district, setDistrict] = useState({ key: '', text: '' });
  const [districtList, setDistrictList] = useState([]);
  const [postcode, setPostCode] = useState('');
  const [phone, setPhone] = useState('');
  const [taxNumberType, setTaxNumberType] = useState('');
  const [taxId, setTaxId] = useState('');
  const [branchText, setBranchText] = useState('');
  const [branchDisplay, setBranchDisplay] = useState([
    { key: '00000', text: 'สำนักงานใหญ่' },
    { key: 'branchNumber', text: 'สาขาเลขที่' },
  ]);

  const [branch, setBranch] = useState('00000');
  const [branchNumber, setBranchNumber] = useState('00000');
  const [email, setEmail] = useState('');
  const [contactName, setContactName] = useState('');

  const [number, setNumber] = useState('');
  const [date, setDate] = useState(new Date().toISOString());
  const [dateBE, setDateBE] = useState(onFormatDate(new Date(), 'BE'));
  const [dateCE, setDateCE] = useState(onFormatDate(new Date(), 'CE'));
  const [note, setNote] = useState('');
  const [createdBy, setCreatedBy] = useState('');

  const [showTaxId, setShowTaxId] = useState(true);
  const [showBranch, setShowBranch] = useState(false);
  const [showBranchNumber, setShowBranchNumber] = useState(false);

  const [amountTotal, setAmountTotal] = useState(0);

  // VAT type
  const percentVat = 7;
  const zeroVat = 0;
  const nonVat = -1;

  const percentVatText = '7%';
  const zeroVatText = '0%';
  const nonVatText = '-';

  const [vatPriceTotal, setVatPriceTotal] = useState(0);
  const [zeroVatPriceTotal, setZeroVatPriceTotal] = useState(0);
  const [nonVatPriceTotal, setNonVatPriceTotal] = useState(0);

  const [discountDisable, setDiscountDisable] = useState(false);
  const [registerVat, setRegisterVat] = useState(false);

  const [vatTotal, setVatTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [grandTotalEN, setGrandTotalEN] = useState('');
  const [grandTotalTH, setGrandTotalTH] = useState('');

  const [whtTotal, setWhtTotal] = useState(0);

  const [language, setLanguage] = useState(sessionStorage.getItem('address-language') || 'th');
  const [structure, setStructure] = useState(true);
  const [lineOne, setLineOne] = useState('');
  const [lineTwo, setLineTwo] = useState('');

  const provinceList = getProvinceList(language);
  const unitList = getUnitList(language);
  const countryList = getCountryList(language);

  const [firstDayOfWeek] = React.useState(DayOfWeek.Sunday);

  const [items, setItems] = useState([]);

  const percentWhtList = getPercentWhtList();

  //Cancel and Replace Doc
  const [reIssue] = useState(false);

  //Payment
  const [payment, setPayment] = useState(false);
  const [paymentLanguage, setPaymentLanguage] = useState('th');

  const bankList = getBankList(language)
  const accountTypeList = getAccountTypeList(language)
  
  const [accountName, setAccountName] = useState('');
  const [banks, setBanks] = useState([
    {
      bankCode: '',
      bankName: '',
      branch: '',
      typeCode: '',
      typeName: '',
      accountNumber: '',
    },
  ]);
  const [paymentNote, setPaymentNote] = useState('');
  const [promptpay, setPromptpay] = useState(false);
  const [promptpayQr, setPromptpayQr] = useState(null);
  const [paymentChanged, setPaymentChanged] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState({ key: '', text: 'ไม่ระบุ' });
  // const [paymentGatewayLoading, setPaymentGatewayLoading] = useState(false);

  const [includeVat, setIncludeVat] = useState(false);
  const [discount, setDiscount] = useState(0);

  //Style
  const centerItem = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  //Error Message
  const [provinceErrMsg, setProvinceErrMsg] = useState('');
  const [districtErrMsg, setDistrictErrMsg] = useState('');
  const [subDistrictErrMsg, setSubDistrictErrMsg] = useState('');

  const [taxNumberTypeErrMsg, setTaxNumberTypeErrMsg] = useState('');

  const [contactSuggestion, setContactSuggestion] = useState();

  const [backUrl, setBackUrl] = useState('/etax/documents/all');

  const dateRef = useRef();

  const DropdownErrMsg = t('billingNote.dropdownErrMsg');

  const phoneRegEx = /(^0[1-9][0-9]{1,13}$|^$)/g;
  let phoneRef = useRef(null);

  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const [reference, setReference] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [dueDateBE, setDueDateBE] = useState('');
  const [dueDateCE, setDueDateCE] = useState('');
  const dueDateRef = useRef();

  const [countryCode, setCountryCode] = useState('TH');
  const [countryName, setCountryName] = useState('ไทย');

  const companyId = sessionStorage.getItem('companyId') || '';

  const DayPickerStrings = {
    months: [
      t('documents.months01'),
      t('documents.months02'),
      t('documents.months03'),
      t('documents.months04'),
      t('documents.months05'),
      t('documents.months06'),
      t('documents.months07'),
      t('documents.months08'),
      t('documents.months09'),
      t('documents.months10'),
      t('documents.months11'),
      t('documents.months12'),
    ],

    shortMonths: [
      t('documents.shortMonths01'),
      t('documents.shortMonths02'),
      t('documents.shortMonths03'),
      t('documents.shortMonths04'),
      t('documents.shortMonths05'),
      t('documents.shortMonths06'),
      t('documents.shortMonths07'),
      t('documents.shortMonths08'),
      t('documents.shortMonths09'),
      t('documents.shortMonths10'),
      t('documents.shortMonths11'),
      t('documents.shortMonths12'),
    ],

    days: [
      t('documents.days01'), 
      t('documents.days02'), 
      t('documents.days03'), 
      t('documents.days04'), 
      t('documents.days05'), 
      t('documents.days06'), 
      t('documents.days07')
    ],

    shortDays: [
      t('documents.shortDays01'), 
      t('documents.shortDays02'), 
      t('documents.shortDays03'), 
      t('documents.shortDays04'), 
      t('documents.shortDays05'), 
      t('documents.shortDays06'), 
      t('documents.shortDays07')
    ],

    goToToday: t('documents.goToToday'),
    prevMonthAriaLabel: t('documents.prevMonthAriaLabel'),
    nextMonthAriaLabel: t('documents.nextMonthAriaLabel'),
    prevYearAriaLabel: t('documents.prevYearAriaLabel'),
    nextYearAriaLabel: t('documents.nextYearAriaLabel'),
    closeButtonAriaLabel: t('documents.close'),
    monthPickerHeaderAriaLabel: t('documents.monthPickerHeaderAriaLabel'),
    yearPickerHeaderAriaLabel: t('documents.yearPickerHeaderAriaLabel'),

    // isRequiredErrorMessage: t('documents.isRequiredErrorMessage'),

    invalidInputErrorMessage: t('documents.invalidInputErrorMessage'),
  };

  useEffect(() => {
    console.log('userEffect initial call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        //Check documents spending limit
        axios
          .get(API_URL + '/companies-details', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              cid: companyId
            },
          })
          .then(
            (response) => {
              console.log('Companies details data: ', response);

              if (response.data) {
                if (
                  response.data &&
                  response.data.balances &&
                  response.data.balances.documents &&
                  response.data.balances.documents.credits !== undefined &&
                  response.data.balances.documents.credits !== null
                ) {
                  console.log('Credit documents: ', response.data.balances.documents.credits);

                  let _documents = response.data.balances.documents.credits;

                  if (_documents <= 0) {
                    history.push({
                      pathname: '/billings',
                      state: {},
                    });
                  }
                }
              }
            },
            (error) => {
              console.log(error);
            }
          );

        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data[0]) {
                if (companyId && response.data.find(_company => _company.id === companyId)) {
                  const _company = response.data.find(_company => _company.id === companyId)
                
                  if (_company.includeVat) {
                    setIncludeVat(_company.includeVat);
                  } else {
                    _company.includeVat = false;
                  }
  
                  setCompany(_company);
  
                  setCompanyName(_company.name);
                  setCompanyPhone(_company.phone);
                  setCompanyEmail(_company.email);
                  setCompanyTaxId(_company.taxId);
                  setCompanyBranchText(_company.branchText);
  
                  if (_company.registerVat) {
                    setRegisterVat(true);
                  }
  
                  if (_company.payment && _company.payment.accountName) {
                    setPayment(_company.payment.display);
                    setAccountName(_company.payment.accountName);
  
                    let _banks = [];
  
                    if (_company.payment.banks.length > 0) {
                      _company.payment.banks.forEach((bank, index) => {
                        _banks.push({
                          bankCode: bank.bankCode,
                          bankName: bank.bankName,
                          branch: bank.branch,
                          typeCode: bank.typeCode,
                          typeName: bank.typeName,
                          accountNumber: bank.accountNumber,
                        });
                      });
  
                      setBanks(_banks);
                    }
                    setPaymentNote(_company.payment.note);
  
                    setPromptpay(_company.payment.promptpay);
                  }
                } else {
                  if (response.data[0].includeVat) {
                    setIncludeVat(response.data[0].includeVat);
                  } else {
                    response.data[0].includeVat = false;
                  }
  
                  setCompany(response.data[0]);
  
                  setCompanyName(response.data[0].name);
                  setCompanyPhone(response.data[0].phone);
                  setCompanyEmail(response.data[0].email);
                  setCompanyTaxId(response.data[0].taxId);
                  setCompanyBranchText(response.data[0].branchText);
  
                  if (response.data[0].registerVat) {
                    setRegisterVat(true);
                  }
  
                  if (response.data[0].payment && response.data[0].payment.accountName) {
                    setPayment(response.data[0].payment.display);
                    setAccountName(response.data[0].payment.accountName);
  
                    let _banks = [];
  
                    if (response.data[0].payment.banks.length > 0) {
                      response.data[0].payment.banks.forEach((bank, index) => {
                        _banks.push({
                          bankCode: bank.bankCode,
                          bankName: bank.bankName,
                          branch: bank.branch,
                          typeCode: bank.typeCode,
                          typeName: bank.typeName,
                          accountNumber: bank.accountNumber,
                        });
                      });
  
                      setBanks(_banks);
                    }
                    setPaymentNote(response.data[0].payment.note);
  
                    setPromptpay(response.data[0].payment.promptpay);
                  }

                }

              } else {
                history.push({
                  pathname: '/etax/companies/profiles',
                  state: {},
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('Company state: ', company);

    if (company.taxId) {
      console.log('Company useEffect call!');
      console.log(company);
      console.log(company.id);

      setCompanyAllAddress(
        company.buildingNumber +
          ' ' +
          (!/^-*$/.test(company.address) && company.address.trim() && !/^-*$/.test(company.address.trim()) ? company.address : '') +
          ' ' +
          (!/^-*$/.test(company.streetName) && company.streetName.trim() && !/^-*$/.test(company.streetName.trim())
            ? company.streetPrefix + company.streetName
            : '') +
          ' ' +
          company.subDistrictPrefix +
          company.subDistrictName +
          ' ' +
          company.districtPrefix +
          company.districtName +
          ' ' +
          company.provincePrefix +
          company.provinceName +
          ' ' +
          company.postcode
      );
    }
  }, [company]);

  useEffect(() => {
    console.log('Language Effect: ', language);

    console.log('Province: ', province);

    if (language != null) {
      setProvince(getProvince(province.key, language));
      setDistrict(getDistrict(district.key, language));
      setDistrictList(getDistrictList(province.key, language));
      setSubDistrict(getSubDistrict(subDistrict.key, language));
      setSubDistrictList(getSubDistrictList(district.key, language));
      setCountryName(getCountry(countryCode, language).text);

      handleBranch(branchNumber, language);

      setRoadPrefix(streetName);
      setAreaPrefix(province.key);

      const values = [...items];

      values.forEach((value) => {
        value.unitCode = getUnit(value.unitCode, language).key;
        value.unitName = getUnit(value.unitCode, language).text;
      });

      setItems(values);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    console.log('Payment Language Effect: ', paymentLanguage);

    banks.forEach((bank, index) => {
      banks[index]['bankName'] = bankList.find((_bank) => _bank.key === bank.bankCode)?.text || ''
    });

    banks.forEach((bank, index) => {
      banks[index]['typeName'] = accountTypeList.find((_accountType) => _accountType.key === bank.typeCode)?.text || ''
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentLanguage]);

  useEffect(() => {
    console.log('useEffect data call!: ', data);

    if (data !== undefined && data.getAccessToken === undefined) {
      if (data.command === 'Contact') {
        setName(data.customer.name);

        setPostCode(data.customer.postcode);
        setPhone(data.customer.phone || "");
        setTaxNumberType(data.customer.taxNumberType);
        setTaxId(data.customer.taxId);
        setBranchText(data.customer.branchText);
        setBranchNumber(data.customer.branchNumber);

        let _language = '';

        if (data.customer.language != null) {
          setLanguage(data.customer.language);
          _language = data.customer.language;
        } else {
          _language = language;
        }

        if (data.customer.branchNumber === '00000' && !(data.customer.branchText.includes('สาขา') || data.customer.branchText.includes('Branch'))) {
          setBranch('00000');
          setShowBranchNumber(false);
        } else if (
          data.customer.branchNumber.length === 5 &&
          (data.customer.branchText.includes('สาขา') || data.customer.branchText.includes('Branch'))
        ) {
          setBranch('branchNumber');
          setShowBranchNumber(true);
        }

        setEmail(data.customer.email || "");
        setContactName(data.customer.contactName);
        setContactSuggestion(data.contactSuggestion);

        if (data.customer.taxNumberType === 'TXID') {
          setShowBranch(true);
        } else {
          setShowBranch(false);
        }

        if (data.customer.taxNumberType === 'OTHR') {
          setShowTaxId(false);
        } else {
          setShowTaxId(true);
        }

        setStructure(!data.customer.unstructure);

        if (data.customer.unstructure) {
          setLineOne(data.customer.addressLineOne);
          setLineTwo(data.customer.addressLineTwo);

          setCountryCode(data.customer.countryCode);
          setCountryName(data.customer.countryName);
        } else {
          setBuildingNumber(data.customer.buildingNumber);
          setAddress(data.customer.address);
          setStreetPrefix(data.customer.streetPrefix);
          setStreetName(data.customer.streetName);
          setProvincePrefix(data.customer.provincePrefix);
          setProvince({
            key: data.customer.provinceCode,
            text: data.customer.provinceName,
          });

          setSubDistrictList(getSubDistrictList(data.customer.districtCode, _language));
          setSubDistrictPrefix(data.customer.subDistrictPrefix);
          setSubDistrict({
            key: data.customer.subDistrictCode,
            text: data.customer.subDistrictName,
          });

          setDistrictList(getDistrictList(data.customer.provinceCode, _language));
          setDistrictPrefix(data.customer.districtPrefix);
          setDistrict({
            key: data.customer.districtCode,
            text: data.customer.districtName,
          });
        }

        setItems([
          {
            number: '',
            description: '',
            quantity: '',
            unitCode: '',
            unitName: '-',
            price: '',
            discount: 0,
            percentVat: percentVat,
            percentVatText: percentVatText,
            total: 0,
            percentWht: 0,
            whtTotal: 0,
            sku: ''
          },
        ]);

        setBackUrl(data.backUrl);
      } else {
        setNumber(data.number);

        if (data.date) {
          setDate(data.date);
          setDateBE(data.dateBE);
          setDateCE(data.dateCE);
        }

        //Company
        setCompany(data.company);
        setCompanyName(data.company.name);
        setCompanyBranchText(data.company.branchText);
        setCompanyPhone(data.company.phone);
        setCompanyEmail(data.company.email);
        setCompanyTaxId(data.company.taxId);

        //Customer
        setName(data.customer.name);

        setPostCode(data.customer.postcode);
        setPhone(data.customer.phone || "");
        setTaxNumberType(data.customer.taxNumberType);
        setTaxId(data.customer.taxId);
        setBranchText(data.customer.branchText);
        setBranchNumber(data.customer.branchNumber);

        setCountryCode(data.customer.countryCode || 'TH');
        setCountryName(data.customer.countryName || 'ไทย');

        if (data.customer.branchNumber === '00000' && !(data.customer.branchText.includes('สาขา') || data.customer.branchText.includes('Branch'))) {
          setBranch('00000');
          setShowBranchNumber(false);
        } else if (
          data.customer.branchNumber.length === 5 &&
          (data.customer.branchText.includes('สาขา') || data.customer.branchText.includes('Branch'))
        ) {
          setBranch('branchNumber');
          setShowBranchNumber(true);
        }

        setEmail(data.customer.email || "");
        setContactName(data.customer.contactName);

        if (data.contactSuggestion) {
          setContactSuggestion(data.contactSuggestion);
        } else {
          async function getContactSuggestion() {
            instance
              .acquireTokenSilent({
                ...silentRequest,
                account: account,
              })
              .then((tokenResponse) => {
                axios
                  .get(API_URL + '/contacts', {
                    headers: {
                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                      'cid': companyId
                    },
                  })
                  .then((response) => {
                    let suggestion = response.data.filter((contact) => contact.taxId === data.customer.taxId);

                    setContactSuggestion(suggestion[0]);
                  });
              })
              .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
              });
          }

          getContactSuggestion();
        }

        if (data.customer.taxNumberType === 'TXID') {
          setShowBranch(true);
        } else {
          setShowBranch(false);
        }

        if (data.customer.taxNumberType === 'OTHR') {
          setShowTaxId(false);
        } else {
          setShowTaxId(true);
        }

        setLanguage(data.customer.language);
        setStructure(!data.customer.unstructure);

        if (data.customer.unstructure) {
          setLineOne(data.customer.addressLineOne);
          setLineTwo(data.customer.addressLineTwo);
        } else {
          setBuildingNumber(data.customer.buildingNumber);
          setAddress(data.customer.address);
          setStreetPrefix(data.customer.streetPrefix);
          setStreetName(data.customer.streetName);
          setProvincePrefix(data.customer.provincePrefix);
          setProvince({
            key: data.customer.provinceCode,
            text: data.customer.provinceName,
          });

          setSubDistrictList(getSubDistrictList(data.customer.districtCode, data.customer.language));
          setSubDistrictPrefix(data.customer.subDistrictPrefix);
          setSubDistrict({
            key: data.customer.subDistrictCode,
            text: data.customer.subDistrictName,
          });

          setDistrictList(getDistrictList(data.customer.provinceCode, data.customer.language));
          setDistrictPrefix(data.customer.districtPrefix);
          setDistrict({
            key: data.customer.districtCode,
            text: data.customer.districtName,
          });
        }

        setDiscount(data.discount);

        let _items = [];
        let nonVatPrice = 0;
        let zeroVatPrice = 0;
        let vatPrice = 0;

        if (data.company.registerVat) {
          data.items.forEach((item, index) => {
            if (item.percentVat === nonVat) {
              nonVatPrice += item.total;
            } else if (item.percentVat === zeroVat) {
              zeroVatPrice += item.total;
            } else {
              vatPrice += item.total;
            }

            let _percentVat = '';
            let _percentVatText = '';

            if (item.percentVat === zeroVat) {
              _percentVat = item.percentVat;
              _percentVatText = zeroVatText;
            } else if (item.percentVat === nonVat) {
              _percentVat = item.percentVat;
              _percentVatText = nonVatText;
            } else {
              _percentVat = percentVat;
              _percentVatText = percentVatText;
            }

            let _percentWht = 0;
            let _whtTotal = 0;

            if (item.percentWht) {
              _percentWht = item.percentWht;
            }

            if (item.whtTotal) {
              _whtTotal = item.whtTotal;
            }

            _items.push({
              number: item.number,
              description: item.description,
              quantity: item.quantity,
              unitCode: item.unitCode,
              unitName: item.unitName,
              price: item.price,
              discount: item.discount,
              percentVat: _percentVat,
              percentVatText: _percentVatText,
              total: item.total,
              percentWht: _percentWht,
              whtTotal: _whtTotal,
              sku: item.sku ? item.sku : ''
            });
          });

          setVatPriceTotal(vatPrice);
          setZeroVatPriceTotal(zeroVatPrice);
          setNonVatPriceTotal(nonVatPrice);
        } else {
          data.items.forEach((item) => {
            let _percentWht = 0;
            let _whtTotal = 0;

            if (item.percentWht) {
              _percentWht = item.percentWht;
            }

            if (item.whtTotal) {
              _whtTotal = item.whtTotal;
            }

            _items.push({
              number: item.number,
              description: item.description,
              quantity: item.quantity,
              unitCode: item.unitCode,
              unitName: item.unitName,
              price: item.price,
              discount: item.discount,
              total: item.total,
              percentWht: _percentWht,
              whtTotal: _whtTotal,
              sku: item.sku ? item.sku : ''
            });
          });
        }
        setItems(_items);

        setAmountTotal(data.amountTotal);
        setVatTotal(data.vatTotal);
        setGrandTotal(data.grandTotal);

        setGrandTotalEN(data.grandTotalEN);
        setGrandTotalTH(data.grandTotalTH);

        setNote(data.note);
        setCreatedBy(data.createdBy);

        if (data.customer.language === 'en') {
          setBranchDisplay([
            { key: '00000', text: 'Head office' },
            { key: 'branchNumber', text: 'Branch' },
          ]);
        } else {
          setBranchDisplay([
            { key: '00000', text: 'สำนักงานใหญ่' },
            { key: 'branchNumber', text: 'สาขาเลขที่' },
          ]);
        }

        if (data.company?.payment?.display) {
          setPayment(data.company.payment.display);
          setAccountName(data.company.payment.accountName);
          setPaymentNote(data.company.payment.note);

          let _banks = [];

          data.company.payment.banks.forEach((bank, index) => {
            _banks.push({
              bankCode: bank.bankCode,
              bankName: bank.bankName,
              branch: bank.branch,
              typeCode: bank.typeCode,
              typeName: bank.typeName,
              accountNumber: bank.accountNumber,
            });
          });

          setBanks(_banks);

          setPromptpay(data.company.payment.promptpay);
          setPromptpayQr(data.company.payment.promptpayQr);

          setPaymentLanguage(data.company.payment.language);

          setPaymentChanged(data.paymentChanged);

          if (data.company.payment.paymentGateway) {
            setPaymentMethod({ key: data.company.payment.paymentGateway.key, text: data.company.payment.paymentGateway.text });
          }
        }

        if (data.backUrl) {
          setBackUrl(data.backUrl);
        }

        setIncludeVat(data.includeVat);

        setDueDate(data.dueDate);
        setDueDateBE(data.dueDateBE);
        setDueDateCE(data.dueDateCE);
        setReference(data.reference);
      }
    } else {
      setItems([
        {
          number: '',
          description: '',
          quantity: '',
          unitCode: '',
          unitName: '-',
          price: '',
          discount: 0,
          percentVat: percentVat,
          percentVatText: percentVatText,
          total: 0,
          percentWht: 0,
          whtTotal: 0,
          sku: ''
        },
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    console.log('useEffect items changed!: ', items);

    let vatTypeArray = [];

    items.forEach((item) => {
      if (!vatTypeArray.includes(item.percentVat)) {
        vatTypeArray.push(item.percentVat);
      }
      if (vatTypeArray.length > 1) {
        setDiscountDisable(true);
        setDiscount(0);
      } else {
        setDiscountDisable(false);
      }
    });

    calAmountTotal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    console.log('useEffect qr changed!');

    if (promptpay) {
      calAmountTotal();
    } else {
      setPromptpayQr(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promptpay]);

  useEffect(() => {
    console.log('useEffect items changed!: ', items);
    calAllLineTotal();
    calAmountTotal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includeVat]);

  useEffect(() => {
    console.log('useEffect items changed!: ', items);
    calAmountTotal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerVat]);

  useEffect(() => {
    console.log('useEffect special discount changed!: ', discount);
    calAmountTotal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discount]);

  const handleTaxType = (selectedOption) => {
    console.log('Change tax type:', selectedOption.key);

    setTaxNumberType(selectedOption.key);

    if (selectedOption.key === 'TXID') {
      setShowBranch(true);
      setBranchNumber('00000');

      if (language === 'en') {
        setBranchText('Head office');
      } else {
        setBranchText('สำนักงานใหญ่');
      }
    } else {
      setShowBranch(false);
      setBranchText('');
      setBranchNumber('');
    }

    if (selectedOption.key === 'OTHR') {
      setShowTaxId(false);
      setTaxId('N/A');
    } else {
      setTaxId('');
      setShowTaxId(true);
    }
  };

  const handleBranch = (branchNum, lang) => {
    console.log('Change branch:', branchNum);
    if (branchNum === '00000') {
      setShowBranchNumber(false);
      setBranch('00000');
      setBranchNumber('00000');

      if (lang === 'en') {
        setBranchText('Head office');
      } else {
        setBranchText('สำนักงานใหญ่');
      }
    } else {
      if (branchNum !== '') {
        setBranch('branchNumber');
        if (branchNum === 'branchNumber') {
          setBranchNumber('00000');

          if (lang === 'en') {
            setBranchText('Branch 00000');
          } else {
            setBranchText('สาขา 00000');
          }
        } else {
          setBranchNumber(branchNum);

          if (lang === 'en') {
            setBranchText('Branch ' + branchNum);
          } else {
            setBranchText('สาขา ' + branchNum);
          }
        }
        setShowBranchNumber(true);
      }
    }

    if (lang === 'en') {
      setBranchDisplay([
        { key: '00000', text: 'Head office' },
        { key: 'branchNumber', text: 'Branch' },
      ]);
    } else {
      setBranchDisplay([
        { key: '00000', text: 'สำนักงานใหญ่' },
        { key: 'branchNumber', text: 'สาขาเลขที่' },
      ]);
    }
  };

  const handleChangeItem = (index, event, selectedOption) => {
    console.log('Change index:', index);
    const values = [...items];
    if (selectedOption) {
      if (selectedOption.id === 'percentVat') {
        values[index]['percentVat'] = selectedOption.key;
        values[index]['percentVatText'] = selectedOption.text;
      } else {
        values[index]['unitCode'] = selectedOption.key;
        values[index]['unitName'] = selectedOption.text;
      }
    } else {
      if (event.target.name === 'quantity' || event.target.name === 'price' || event.target.name === 'discount') {
        if (Number(event.target.value) >= 9999999999.99) {
          values[index][event.target.name] = 9999999999.99;
        } else {
          values[index][event.target.name] = event.target.value;
        }
      } else {
        values[index][event.target.name] = event.target.value;

        if (event.target.name === 'description') {
          values[index]['sku'] = ''
        }
      }
    }

    setItems(values);
  };

  const handleChangeBankName = (index, event, selectedOption) => {
    console.log('Change index:', index);
    console.log('Event name: ', event);
    console.log('Selected option: ', selectedOption);
    const values = [...banks];
    if (selectedOption) {
      values[index]['bankCode'] = selectedOption.key;
      values[index]['bankName'] = selectedOption.text;
    }
    console.log('Banks: ', values);

    setBanks(values);
  };

  const handleChangeBankType = (index, event, selectedOption) => {
    console.log('Change index:', index);
    console.log('Event name: ', event);
    console.log('Selected option: ', selectedOption);
    const values = [...banks];
    if (selectedOption) {
      values[index]['typeCode'] = selectedOption.key;
      values[index]['typeName'] = selectedOption.text;
    }
    console.log('Banks: ', values);

    setBanks(values);
  };

  const handleChangeBank = (index, event, selectedOption) => {
    console.log('Change index:', index);
    console.log('Event name: ', event);
    console.log('Selected option: ', selectedOption);
    const values = [...banks];

    values[index][event.target.name] = event.target.value;

    console.log('Banks: ', values);

    setBanks(values);
  };

  const handleBlurItem = (index, event) => {
    const values = [...items];
    values[index][event.target.name] = Math.round(values[index][event.target.name] * 100) / 100;
    setItems(values);
    calLineTotal(index);
  };

  const handleAddItem = () => {
    setItems([
      ...items,
      {
        number: '',
        description: '',
        quantity: 0,
        unitCode: '',
        unitName: '-',
        price: 0,
        discount: 0,
        percentVat: percentVat,
        percentVatText: percentVatText,
        total: 0,
        percentWht: 0,
        whtTotal: 0,
        sku: ''
      },
    ]);
  };

  const handleAddBank = () => {
    setBanks([
      ...banks,
      {
        bankCode: '',
        bankName: '',
        branch: '',
        typeCode: '',
        typeName: '',
        accountNumber: '',
      },
    ]);
  };

  const handleRemoveItem = (index) => {
    console.log('Delete index:', index);
    const values = [...items];
    values.splice(index, 1);
    setItems(values);
  };

  const handleRemoveBank = (index) => {
    console.log('Delete index:', index);
    const values = [...banks];
    values.splice(index, 1);
    setBanks(values);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isCorrect = true;

    if (structure) {
      if (province.key === undefined || province.key === null || province.key === '') {
        isCorrect = false;
        setProvinceErrMsg(DropdownErrMsg);
      }

      if (district.key === undefined || district.key === null || district.key === '') {
        isCorrect = false;
        setDistrictErrMsg(DropdownErrMsg);
      }

      if (subDistrict.key === undefined || subDistrict.key === null || subDistrict.key === '') {
        isCorrect = false;
        setSubDistrictErrMsg(DropdownErrMsg);
      }
    }

    if (taxNumberType === undefined || taxNumberType === null || taxNumberType === '') {
      isCorrect = false;
      setTaxNumberTypeErrMsg(DropdownErrMsg);
    }

    if (date === undefined || date === null || date === '') {
      isCorrect = false;
      dateRef.current.focus();
    }

    let _branchNumber = branchNumber

    if (taxNumberType === 'TXID') {
      if (taxId.length !== 13) {
        isCorrect = false;

        refArray.current.taxIdRef.focus();
        refArray.current.taxIdRef.blur();
      }

      if (isNaN(taxId)) {
        isCorrect = false;

        refArray.current.taxIdRef.focus();
        refArray.current.taxIdRef.blur();
      }

      if (isNaN(branchNumber)) {
        if (branchText.toLowerCase() === "head office" || branchText === "สำนักงานใหญ่") {
          _branchNumber = "00000"
        } else {
          isCorrect = false;
        }
      } else {
        if (branchNumber.length !== 5) {
          if (branchText.toLowerCase() === "head office" || branchText === "สำนักงานใหญ่") {
            _branchNumber = "00000"
          } else {
            isCorrect = false;
          }
        }
      }
    } else if (taxNumberType === 'NIDN') {
      if (taxId.length !== 13) {
        isCorrect = false;
      }

      if (isNaN(taxId)) {
        isCorrect = false;

        refArray.current.taxIdRef.focus();
        refArray.current.taxIdRef.blur();
      }
    } else if (taxNumberType === 'CCPT') {
      if (taxId.length === 0) {
        isCorrect = false;
      }
    }

    if (taxId === '0000000000000' || Number(taxId) === 0) {
      isCorrect = false;
    }

    if (postcode) {
      if (countryCode === 'TH') {
        if (isNaN(postcode)) {
          isCorrect = false;
          postCodeRef.focus()
          postCodeRef.blur()
        }

        if (postcode.length !== 5) {
          isCorrect = false;
          postCodeRef.focus()
          postCodeRef.blur()
        }
      }
    }

    if (phoneNumberError) {
      isCorrect = false;
      phoneRef.focus();
    }

    items.forEach((item) => {
      if (item.total < 0) {
        isCorrect = false;
      }
    });

    if (number.includes(",") || number.includes(" ")) {
      isCorrect = false;
    }

    if (name.length > 140) {
      isCorrect = false;
    }

    if (structure) {
      if (buildingNumber.length > 16) {
        isCorrect = false;
      }

      if (address.length > 256) {
        isCorrect = false;
      }

      if (streetName.length > 70) {
        isCorrect = false;
      }
    } else {
      if (lineOne.length > 256) {
        isCorrect = false;
      }
  
      if (lineTwo.length > 256) {
        isCorrect = false;
      }
    }

    if (postcode.length > 16) {
      isCorrect = false;

      postCodeRef.focus()
      postCodeRef.blur()
    }

    if (taxId.length > 35) {
      isCorrect = false;
    }

    if (_branchNumber.length > 5) {
      isCorrect = false;
    }

    if (phone?.length > 35) {
      isCorrect = false;
    }

    if (email?.length > 256) {
      isCorrect = false;
    }

    if (number.length > 35) {
      isCorrect = false;
    }

    items.forEach((_item => {
      if (_item.number.length > 35) {
        isCorrect = false;
      }

      if (_item.description.length > 256) {
        isCorrect = false;
      }
    }))

    if (note.length > 500) {
      isCorrect = false;
    }

    if (!isCorrect) {
      window.scrollTo(0, 0);

      handleFocusAndBlurAll()
    }

    console.log(
      'Name:',
      name,
      '\nbuildingNumber:',
      buildingNumber,
      '\naddress:',
      address,
      '\nstreetName:',
      streetName,
      '\nprovincePrefix:',
      provincePrefix,
      '\nprovince:',
      province,
      '\ndistrictPrefix:',
      districtPrefix,
      '\ndistrict:',
      district,
      '\nsubDistrictPrefix:',
      subDistrictPrefix,
      '\nsubDistrict:',
      subDistrict,
      '\npostcode:',
      postcode,
      '\nphone:',
      phone,
      '\ntaxId:',
      taxId,
      '\nbranchNumber:',
      branchNumber,
      '\nemail:',
      email
    );

    console.log(
      'number:',
      number,
      '\ndate:',
      date,
      '\nnote:',
      note,
      '\namountTotal:',
      amountTotal,
      '\npercentVat:',
      percentVat,
      '\nvatTotal:',
      vatTotal,
      '\ngrandTotal:',
      grandTotal
    );
    console.log('Items', items);

    if (isCorrect) {
      let _customer = {};

      if (structure) {
        _customer = {
          address: address,
          branchNumber: _branchNumber,
          branchText: branchText,
          buildingNumber: buildingNumber,
          streetPrefix: streetPrefix,
          streetName: streetName,
          districtPrefix: districtPrefix,
          districtCode: district.key,
          districtName: district.text,
          email: email,
          name: name,
          phone: phone,
          phoneCountryCode: '+66',
          postcode: postcode,
          provincePrefix: provincePrefix,
          provinceCode: province.key,
          provinceName: province.text,
          subDistrictPrefix: subDistrictPrefix,
          subDistrictCode: subDistrict.key,
          subDistrictName: subDistrict.text,
          taxId: taxId,
          taxNumberType: taxNumberType,
          language: language,
          contactName: contactName,
          countryCode: countryCode,
          countryName: countryName,
          unstructure: false,
        };
      } else {
        _customer = {
          addressLineOne: lineOne,
          addressLineTwo: lineTwo,
          branchNumber: _branchNumber,
          branchText: branchText,
          email: email,
          name: name,
          phone: phone,
          phoneCountryCode: '+66',
          postcode: postcode,
          taxId: taxId,
          taxNumberType: taxNumberType,
          language: language,
          contactName: contactName,
          countryCode: countryCode,
          countryName: countryName,
          unstructure: true,
        };
      }

      let _banks = [];

      payment
        ? banks.forEach((bank) => {
            _banks.push({
              bankCode: bank.bankCode,
              bankName: bankList.find((_bank) => _bank.key === bank.bankCode)?.text || '',
              branch: bank.branch,
              typeCode: bank.typeCode,
              typeName: accountTypeList.find((_accountType) => _accountType.key === bank.typeCode)?.text || '',
              accountNumber: bank.accountNumber,
            });
          })
        : _banks.push({
            accountNumber: null,
            bankCode: null,
            bankName: null,
            branch: null,
            typeCode: null,
            typeName: null,
          });

      let _payment = {
        accountName: accountName,
        banks: _banks,
        note: paymentNote,
        promptpay: promptpay,
        promptpayQr: promptpayQr,
        display: payment,
        language: paymentLanguage,
      };

      company.payment = _payment;

      if (paymentMethod.key === 'paypal') {
        let _invoiceData = {};

        let _amount = {
          breakdown: {
            discount: {
              invoice_discount: {
                amount: {
                  currency_code: 'THB',
                  value: String(discount),
                },
              },
            },
            item_total: {
              currency_code: 'THB',
              value: String(amountTotal),
            },
          },
        };

        if (registerVat) {
          _amount = {
            breakdown: {
              discount: {
                invoice_discount: {
                  amount: {
                    currency_code: 'THB',
                    value: String(discount),
                  },
                },
              },
              item_total: {
                currency_code: 'THB',
                value: includeVat ? String(grandTotal) : String(amountTotal),
              },
              tax_total: {
                currency_code: 'THB',
                value: String(vatTotal),
              },
            },
          };
        }

        let _detail = {
          currency_code: 'THB',
          invoice_date: formatDate(new Date(date)),
          invoice_number: number,
          note: note,
        };

        let _configuration = {
          partial_payment: {
            allow_partial_payment: false,
          },
          allow_tip: true,
          tax_calculated_after_discount: true,
          tax_inclusive: includeVat,
        };

        let _invoicer = {
          name: {
            given_name: companyName,
          },
          address: {
            address_line_1:
              company.buildingNumber +
              ' ' +
              (!/^-*$/.test(company.address) && company.address.trim() && !/^-*$/.test(company.address.trim()) ? company.address : '') +
              ' ' +
              (!/^-*$/.test(company.streetName) && company.streetName.trim() && !/^-*$/.test(company.streetName.trim())
                ? company.streetPrefix + company.streetName
                : ''),
            address_line_2:
              company.subDistrictPrefix +
              company.subDistrictName +
              ' ' +
              company.districtPrefix +
              company.districtName +
              ' ' +
              company.provincePrefix +
              company.provinceName,
            postal_code: company.postcode,
            country_code: 'TH',
          },
          email_address: company.email,
        };

        let _primary_recipients = [
          {
            billing_info: {
              name: {
                given_name: name,
              },
              address: structure
                ? {
                    address_line_1:
                      buildingNumber +
                      ' ' +
                      (!/^-*$/.test(address) && address.trim() && !/^-*$/.test(address.trim()) ? address : '') +
                      ' ' +
                      (!/^-*$/.test(streetName) && streetName.trim() && !/^-*$/.test(streetName.trim()) ? streetPrefix + streetName : ''),
                    address_line_2:
                      subDistrictPrefix + subDistrict.text + ' ' + districtPrefix + district.text + ' ' + provincePrefix + province.text,
                    postal_code: postcode,
                    country_code: 'TH',
                  }
                : {
                    address_line_1: lineOne,
                    address_line_2: lineTwo,
                    postal_code: postcode,
                    country_code: 'TH',
                  },
              email_address: email,
            },
          },
        ];

        let _items = items.map((item) => {
          if (registerVat) {
            let _taxName = 'VAT';
            if (item.percentVat === 0) {
              _taxName = 'VAT';
            } else if (item.percentVat === -1) {
              _taxName = 'Exempt VAT';
            }

            return {
              name: item.description,
              quantity: item.quantity,
              unit_amount: {
                currency_code: 'THB',
                value: String(item.price),
              },
              discount: {
                amount: {
                  currency_code: 'THB',
                  value: String(item.discount * item.quantity),
                },
              },
              tax: {
                name: _taxName,
                percent: item.percentVat === 7 ? 7 : 0,
              },
            };
          } else {
            return {
              name: item.description,
              quantity: item.quantity,
              unit_amount: {
                currency_code: 'THB',
                value: String(item.price),
              },
              discount: {
                amount: {
                  currency_code: 'THB',
                  value: String(item.discount * item.quantity),
                },
              },
            };
          }
        });

        _invoiceData.amount = _amount;
        _invoiceData.detail = _detail;
        _invoiceData.configuration = _configuration;
        _invoiceData.invoicer = _invoicer;
        _invoiceData.primary_recipients = _primary_recipients;
        _invoiceData.items = _items;

        company.payment.paymentGateway = { key: paymentMethod.key, text: paymentMethod.text, data: _invoiceData };
      } else if (paymentMethod.key === 'stripe') {
        company.payment.paymentGateway = { key: paymentMethod.key, text: paymentMethod.text, data: { items: items } };
      }

      history.push({
        pathname: '/documents/billing-notes/confirm',
        state: {
          // location state
          amountTotal: amountTotal >= 0 ? amountTotal : 0,
          company: company,
          customer: _customer,
          date: date,
          dateBE: dateBE,
          dateCE: dateCE,
          discount: discount,
          grandTotal: grandTotal >= 0 ? grandTotal : 0,
          grandTotalTH: grandTotalTH,
          grandTotalEN: grandTotalEN,
          nonVatPriceTotal: nonVatPriceTotal >= 0 ? nonVatPriceTotal : 0,
          zeroVatPriceTotal: zeroVatPriceTotal >= 0 ? zeroVatPriceTotal : 0,
          vatPriceTotal: vatPriceTotal >= 0 ? vatPriceTotal : 0,
          items: items,
          note: note,
          number: number,
          percentVat: percentVat,
          createdBy: createdBy,
          vatTotal: vatTotal >= 0 ? vatTotal : 0,
          reIssue: reIssue,
          paymentChanged: paymentChanged,
          backUrl: backUrl,
          includeVat: includeVat,
          contactSuggestion: contactSuggestion,
          dueDate: dueDate,
          dueDateBE: dueDateBE,
          dueDateCE: dueDateCE,
          reference: reference,
          whtTotal: whtTotal,
          paidTotal: grandTotal - whtTotal >= 0 ? Math.round((grandTotal - whtTotal) * 100) / 100 : 0,
        },
      });
    }
  };

  const handleCancel = (e) => {
    history.push({
      pathname: backUrl,
      state: {},
    });
  };

  const calLineTotal = (index) => {
    const values = [...items];
    values[index]['total'] = Math.round(values[index]['quantity'] * (values[index]['price'] - values[index]['discount']) * 100) / 100;

    let _itemPercentVat = values[index]['percentVat'];
    if (_itemPercentVat < 0) {
      _itemPercentVat = 0;
    }

    let _itemVat = 0;
    let _itemTotalAfterVat = 0;
    let _itemTotalBeforeVat = 0;
    let _whtTotal = 0;

    if (includeVat) {
      _itemVat = Math.round(((values[index]['total'] * _itemPercentVat) / (100 + _itemPercentVat)) * 100) / 100;
      _itemTotalAfterVat = Math.round(values[index]['total'] * 100) / 100;
      _itemTotalBeforeVat = _itemTotalAfterVat - _itemVat;
    } else {
      _itemTotalBeforeVat = Math.round(values[index]['total'] * 100) / 100;
      _itemVat = Math.round(_itemTotalBeforeVat * _itemPercentVat * 100) / 100;
      _itemTotalAfterVat = _itemTotalBeforeVat + _itemVat;
    }

    _whtTotal = _itemTotalBeforeVat * (Math.round((values[index]['percentWht'] / 100) * 100) / 100);

    values[index]['whtTotal'] = Math.round(_whtTotal * 100) / 100;

    setItems(values);
    calAmountTotal();
  };

  const calAllLineTotal = (index) => {
    for (let i = 0; i < items.length; i++) {
      calLineTotal(i);
    }
  };

  const calAmountTotal = () => {
    let sumVatTotal = 0;
    let sumGrandTotal = 0;

    let sumAmountTotal = 0;
    let nonVatPrice = 0;
    let zeroVatPrice = 0;
    let vatPrice = 0;

    let sumWhtTotal = 0;

    let _zeroVatPriceTotal = 0
    let _vatPriceTotal = 0

    if (registerVat) {
      if (includeVat) {
        for (let i = 0; i < items.length; i++) {
          if (items[i].percentVat === nonVat) {
            nonVatPrice += items[i]['total'];
            sumGrandTotal += items[i]['total'];
            sumWhtTotal += items[i]['whtTotal'];
          } else if (items[i].percentVat === zeroVat) {
            zeroVatPrice += items[i]['total'];
            sumGrandTotal += items[i]['total'];
            sumWhtTotal += items[i]['whtTotal'];
          } else {
            vatPrice += items[i]['total'];
            sumGrandTotal += items[i]['total'];
            sumWhtTotal += items[i]['whtTotal'];
          }
        }

        if (!discountDisable) {
          sumGrandTotal -= discount;
          if (vatPrice !== 0) {
            vatPrice -= discount;
          } else if (zeroVatPrice !== 0) {
            zeroVatPrice -= discount;
          } else {
            nonVatPrice -= discount;
          }
        }

        sumGrandTotal = Math.round(sumGrandTotal * 100) / 100;
        vatPrice = Math.round(vatPrice * 100) / 100;
        nonVatPrice = Math.round(nonVatPrice * 100) / 100;
        zeroVatPrice = Math.round(zeroVatPrice * 100) / 100;
        sumWhtTotal = Math.round(sumWhtTotal * 100) / 100;

        sumVatTotal =
          Math.round(((vatPrice * percentVat) / (100 + percentVat)) * 100) / 100 +
          Math.round(((zeroVatPrice * zeroVat) / (100 + zeroVat)) * 100) / 100;
        sumAmountTotal = sumGrandTotal - sumVatTotal;

        sumVatTotal = Math.round(sumVatTotal * 100) / 100;
        sumAmountTotal = Math.round(sumAmountTotal * 100) / 100;
        
        _zeroVatPriceTotal = zeroVatPrice - Math.round(((zeroVatPrice * zeroVat) / (100 + zeroVat)) * 100) / 100
        _vatPriceTotal = vatPrice - Math.round(((vatPrice * percentVat) / (100 + percentVat)) * 100) / 100

        _zeroVatPriceTotal = Math.round(_zeroVatPriceTotal * 100) / 100;
        _vatPriceTotal = Math.round(_vatPriceTotal * 100) / 100;

        setNonVatPriceTotal(nonVatPrice);
        setZeroVatPriceTotal(_zeroVatPriceTotal);
        setVatPriceTotal(_vatPriceTotal);
      } else {
        for (let i = 0; i < items.length; i++) {
          if (items[i].percentVat === nonVat) {
            nonVatPrice += items[i]['total'];
            sumAmountTotal += items[i]['total'];
            sumWhtTotal += items[i]['whtTotal'];
          } else if (items[i].percentVat === zeroVat) {
            zeroVatPrice += items[i]['total'];
            sumAmountTotal += items[i]['total'];
            sumWhtTotal += items[i]['whtTotal'];
          } else {
            vatPrice += items[i]['total'];
            sumAmountTotal += items[i]['total'];
            sumWhtTotal += items[i]['whtTotal'];
          }
        }

        if (!discountDisable) {
          sumAmountTotal -= discount;
          if (vatPrice !== 0) {
            vatPrice -= discount;
          } else if (zeroVatPrice !== 0) {
            zeroVatPrice -= discount;
          } else {
            nonVatPrice -= discount;
          }
        }

        sumAmountTotal = Math.round(sumAmountTotal * 100) / 100;
        vatPrice = Math.round(vatPrice * 100) / 100;
        nonVatPrice = Math.round(nonVatPrice * 100) / 100;
        zeroVatPrice = Math.round(zeroVatPrice * 100) / 100;
        sumWhtTotal = Math.round(sumWhtTotal * 100) / 100;

        sumVatTotal = Math.round(((vatPrice * percentVat) / 100) * 100) / 100 + Math.round(((zeroVatPrice * zeroVat) / 100) * 100) / 100;
        sumGrandTotal = sumAmountTotal + sumVatTotal;

        sumVatTotal = Math.round(sumVatTotal * 100) / 100;
        sumGrandTotal = Math.round(sumGrandTotal * 100) / 100;

        setNonVatPriceTotal(nonVatPrice);
        setZeroVatPriceTotal(zeroVatPrice);
        setVatPriceTotal(vatPrice);
      }
    } else {
      for (let i = 0; i < items.length; i++) {
        sumAmountTotal += items[i]['total'];
        sumGrandTotal += items[i]['total'];
        sumWhtTotal += items[i]['whtTotal'];
      }

      sumGrandTotal -= discount;
      sumAmountTotal -= discount;

      sumGrandTotal = Math.round(sumGrandTotal * 100) / 100;
      sumAmountTotal = Math.round(sumAmountTotal * 100) / 100;
    }

    setAmountTotal(sumAmountTotal);
    setVatTotal(sumVatTotal);
    setGrandTotal(sumGrandTotal);
    setWhtTotal(sumWhtTotal);

    setGrandTotalEN(numberToTextEn(sumGrandTotal >= 0 ? sumGrandTotal : 0));
    setGrandTotalTH(numberToTextTh(sumGrandTotal >= 0 ? sumGrandTotal : 0));

    if (promptpay) {
      handleQr(sumGrandTotal >= 0 ? sumGrandTotal : 0);
    }
  };

  const handleQr = (paidTotal) => {
    let _qrPayload = generatePayload(companyTaxId, { amount: paidTotal });
    console.log('QR payload: ', _qrPayload);

    qrCode
      .toDataURL(_qrPayload)
      .then((url) => {
        console.log(url);
        setPromptpayQr(url);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleStructure = (checked) => {
    console.log('Address structure:', checked);

    setStructure(checked);
    if (checked) {
      setBuildingNumber('');
      setAddress('');
      setStreetPrefix('');
      setStreetName('');
      setProvincePrefix('');
      setProvince({ key: '', text: '' });
      setDistrictPrefix('');
      setDistrict({ key: '', text: '' });
      setSubDistrict('');
      setSubDistrict({ key: '', text: '' });

      setCountryCode('TH');
      setCountryName('ไทย');
    } else {
      setLineOne('');
      setLineTwo('');
    }
  };

  function handleSelectSuggestions(e, suggestion) {
    setContactSuggestion(suggestion);

    setName(suggestion.name);

    if (suggestion.addressLineOne) {
      setStructure(false);
      setLineOne(suggestion.addressLineOne);
      setLineTwo(suggestion.addressLineTwo);

      setCountryCode(suggestion.countryCode);
      setCountryName(suggestion.countryName);
    }

    if (suggestion.buildingNumber) {
      setStructure(true);

      setBuildingNumber(suggestion.buildingNumber);
      setAddress(suggestion.address);

      setStreetName(suggestion.streetName);

      if (suggestion.language != null) {
        setLanguage(suggestion.language);
      }

      setProvincePrefix(suggestion.provincePrefix);
      setProvince({
        key: suggestion.provinceCode,
        text: suggestion.provinceName,
      });

      setDistrictList(getDistrictList(suggestion.provinceCode, suggestion.language));
      setDistrictPrefix(suggestion.districtPrefix);
      setDistrict({
        key: suggestion.districtCode,
        text: suggestion.districtName,
      });

      setSubDistrictList(getSubDistrictList(suggestion.districtCode, suggestion.language));
      setSubDistrictPrefix(suggestion.subDistrictPrefix);
      setSubDistrict({
        key: suggestion.subDistrictCode,
        text: suggestion.subDistrictName,
      });

      if (suggestion.language === 'en') {
        setStreetPrefix('');
      } else {
        setStreetPrefix(suggestion.streetPrefix);
      }

      setAreaPrefix(suggestion.provinceCode);
    }

    setPostCode(suggestion.postcode);

    if (suggestion.taxNumberType === 'TXID') {
      setTaxNumberType(suggestion.taxNumberType);
      setShowTaxId(true);
      setShowBranch(true);

      setBranchNumber(suggestion.branchNumber);

      if (suggestion.language != null) {
        if (suggestion.branchNumber === '00000') {
          if (suggestion.language === 'en') {
            setBranchText('Head office');
          } else {
            setBranchText('สำนักงานใหญ่');
          }
        } else {
          if (suggestion.language === 'en') {
            setBranchText('Branch ' + suggestion.branchNumber);
          } else {
            setBranchText('สาขา ' + suggestion.branchNumber);
          }
        }
      } else {
        if (suggestion.branchNumber === '00000') {
          if (language === 'en') {
            setBranchText('Head office');
          } else {
            setBranchText('สำนักงานใหญ่');
          }
        } else {
          if (language === 'en') {
            setBranchText('Branch ' + suggestion.branchNumber);
          } else {
            setBranchText('สาขา ' + suggestion.branchNumber);
          }
        }
      }

      if (suggestion.branchNumber === '00000' && !(suggestion.branchText.includes('สาขา') || suggestion.branchText.includes('Branch'))) {
        setBranch('00000');
        setShowBranchNumber(false);
      } else if (suggestion.branchNumber.length === 5 && (suggestion.branchText.includes('สาขา') || suggestion.branchText.includes('Branch'))) {
        setBranch('branchNumber');
        setShowBranchNumber(true);
      }
    } else {
      if (suggestion.taxNumberType === 'OTHR') {
        setShowTaxId(false);
      } else {
        setShowTaxId(true);
      }
      setTaxNumberType(suggestion.taxNumberType);
      setShowBranch(false);
      setShowBranchNumber(false);
      setBranchText('');
      setBranchNumber('');
    }

    setTaxId(suggestion.taxId);

    setContactName(suggestion.contactName);
    setPhone(suggestion.phone || "");
    setEmail(suggestion.email || "");
  }

  const handlePercentWht = (index, event, selectedOption) => {
    console.log('Change index:', index);
    console.log('selectedOption:', selectedOption);
    console.log('event:', event);

    const values = [...items];
    if (selectedOption?.key !== undefined && selectedOption?.text !== undefined) {
      values[index]['percentWht'] = Math.round(selectedOption.key * 100) / 100;
    }
    calLineTotal(index);

    console.log('items value:', values);

    setItems(values);
  };

  const getErrorMessageWithMaxLength = (value, maxLength, required, extraError) => {
    if (extraError) return extraError
    if (value.length > maxLength) return t('billingNote.getErrorMessageExceedMaxLength') + maxLength + t('billingNote.getErrorMessageExceedMaxLength2');
    if (!required) return ""
    if (value && value !== '0') return ""
    return value && value !== '0' ? '' : t('billingNote.getErrorMessage');
  };

  const getErrorMessage = (value) => {
    return value && value !== '0' ? '' : t('billingNote.getErrorMessage');
  };

  const getPostCodeErrorMessage = (value) => {
    if (countryCode === 'TH') {
      if (isNaN(value)) return t('billingNote.getPostCodeErrorMessage1');
      if (!value || value.length !== 5) return t('billingNote.getPostCodeErrorMessage2');
    }
    return '';
  };

  let postCodeRef = useRef(false);

  useLayoutEffect(() => {
    if (postCodeRef.current) {
      postCodeRef.focus();
      postCodeRef.blur();
    } else {
      postCodeRef.current = true;
    }
  }, [countryCode]);

  let itemsNumberRef = useRef(items.slice(0, items.length));
  let itemsDescRef = useRef(items.slice(0, items.length));
  let itemsQtyRef = useRef(items.slice(0, items.length));
  let itemsPriceRef = useRef(items.slice(0, items.length));

  let refArray = useRef({
    customerName: '',
    lineOneRef: '',
    lineTwoRef: '',
    buildingNumberRef: '',
    addressRef: '',
    streetNameRef: '',
    taxIdRef: '',
    branchNumberRef: '',
    numberRef: '',
    emailRef: '',
    noteRef: '',
    referNumberRef: '',
    referSpecificReasonRef: '',
    previouseAmountRef: '',
  });

  useLayoutEffect(() => {
    itemsNumberRef.current.forEach((_ref) => {
      if (_ref?._errorMessage) {
        _ref.focus();
        _ref.blur();
      }
    });

    itemsDescRef.current.forEach((_ref) => {
      if (_ref?._errorMessage) {
        _ref.focus();
        _ref.blur();
      }
    });

    itemsQtyRef.current.forEach((_ref) => {
      if (_ref?._errorMessage) {
        _ref.focus();
        _ref.blur();
      }
    });

    itemsPriceRef.current.forEach((_ref) => {
      if (_ref?._errorMessage) {
        _ref.focus();
        _ref.blur();
      }
    });
  }, [i18n.language]);

  useLayoutEffect(() => {
    Object.keys(refArray.current).forEach((key) => {
      let _ref = refArray.current[key];
      if (_ref?._errorMessage) {
        _ref.focus();
        _ref.blur();
      }
    });

    if (phoneRef?._errorMessage) {
      phoneRef.focus();
      phoneRef.blur();
    }

    if (postCodeRef?._errorMessage) {
      postCodeRef.focus();
      postCodeRef.blur();
    }

    if (i18n.language === "en") {
      setLanguage('en');
      sessionStorage.setItem('address-language', 'en');
    } else {
      setLanguage('th');
      sessionStorage.setItem('address-language', 'th');
    }

  }, [i18n.language]);

  const handleFocusAndBlurAll = () => {
    itemsNumberRef.current.forEach((_ref) => {
      if (_ref) {
        _ref.focus();
        _ref.blur();
      }
    });

    itemsDescRef.current.forEach((_ref) => {
      if (_ref) {
        _ref.focus();
        _ref.blur();
      }
    });

    itemsQtyRef.current.forEach((_ref) => {
      if (_ref) {
        _ref.focus();
        _ref.blur();
      }
    });

    itemsPriceRef.current.forEach((_ref) => {
      if (_ref) {
        _ref.focus();
        _ref.blur();
      }
    });

    Object.keys(refArray.current).forEach((key) => {
      let _ref = refArray.current[key];
      if (_ref) {
        _ref.focus();
        _ref.blur();
      }
    });

    if (phoneRef) {
      phoneRef.focus();
      phoneRef.blur();
    }

    if (postCodeRef) {
      postCodeRef.focus();
      postCodeRef.blur();
    }
  }

  const getTaxIdErrorMessage = (value) => {
    if ((taxNumberType === 'TXID' || taxNumberType === 'NIDN')) {
      if (taxId.length !== 13) return t('billingNote.getTaxIdErrorMessage');
      if (isNaN(taxId)) return t('billingNote.getBranchNumberErrorMessage1');
    } else if (value === '' || value === null || value === undefined) {
      return t('billingNote.getErrorMessage');
    } else if (value === '0000000000000') {
      return t('documents.getErrorMessageTaxIdZeroOnly');
    } else {
      return '';
    }
  };

  const getBranchNumberErrorMessage = (value) => {
    if (branchNumber.length !== 5) {
      return t('billingNote.getBranchNumberErrorMessage2');
    } else if (isNaN(value)) {
      return t('billingNote.getBranchNumberErrorMessage1');
    } else if (value === '' || value === null || value === undefined) {
      return t('billingNote.getErrorMessage');
    } else {
      return '';
    }
  };

  const getPhoneNumberErrorMessage = (value, maxLength) => {
    setPhoneNumberError(false);
    if (value.length > maxLength) {
      setPhoneNumberError(true);
      return t('billingNote.getErrorMessageExceedMaxLength') + maxLength + t('billingNote.getErrorMessageExceedMaxLength2'); 
    } else if (value === null || value === undefined) {
      setPhoneNumberError(true);
      return t('billingNote.getErrorMessage');
    } else if (phoneRegEx.test(value) !== true) {
      setPhoneNumberError(true);
      return t('billingNote.getPhoneNumberErrorMessage');
    }
  };

  const setRoadPrefix = (_streetName) => {
    if (_streetName === '') {
      setStreetPrefix('');
    } else {
      if (language === 'th') {
        setStreetPrefix('ถ.');
      } else {
        setStreetPrefix('');
      }
    }
  };

  const setAreaPrefix = (_provinceCode) => {
    if (_provinceCode === '10') {
      if (language === 'th') {
        setSubDistrictPrefix('แขวง');
        setDistrictPrefix('เขต');
        setProvincePrefix('');
      } else {
        setSubDistrictPrefix('');
        setDistrictPrefix('');
        setProvincePrefix('');
      }
    } else {
      if (language === 'en') {
        setSubDistrictPrefix('');
        setDistrictPrefix('');
        setProvincePrefix('');
      } else {
        setSubDistrictPrefix('ต.');
        setDistrictPrefix('อ.');
        setProvincePrefix('จ.');
      }
    }
  };

  const updateCompany = (replace, value) => {
    console.log('userEffect updateCompany call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        //Check documents spending limit
        axios
          .patch(
            API_URL + '/etax/companies/' + company.id,
            {
              replace: replace,
              value: value,
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              },
            }
          )
          .then(
            (response) => {
              console.log('Company data: ', response);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  // function StripeComponent() {
  //   return (
  //     <>
  //       {payment && paymentMethod.key === 'stripe' && (
  //         <Stack vertical>
  //           {paymentGatewayLoading ? (
  //             <>
  //               <br />
  //               <Spinner label={t('billingNote.loading')} labelPosition="right" />
  //             </>
  //           ) : (
  //             <>
  //               {sessionStorage.getItem('stripe-accessToken') ? (
  //                 <Stack horizontal>
  //                   <p>{t('billingNote.stripeConnectSuccess')}</p>
  //                 </Stack>
  //               ) : (
  //                 <>
  //                   <p>{t('billingNote.stripeConnect')}</p>
  //                   <PrimaryButton
  //                     onClick={() => {
  //                       history.push({
  //                         pathname: '/etax/sources/stripe/connect',
  //                         state: {
  //                           backUrl: '/documents/billing-notes',
  //                         },
  //                       });
  //                     }}
  //                   >
  //                     {t('stripeConnect.connect')}
  //                   </PrimaryButton>
  //                 </>
  //               )}
  //             </>
  //           )}
  //         </Stack>
  //       )}
  //     </>
  //   );
  // }

  // function PayPalComponent() {
  //   return (
  //     <>
  //       {payment && paymentMethod.key === 'paypal' && (
  //         <Stack vertical>
  //           {paymentGatewayLoading ? (
  //             <>
  //               <br />
  //               <Spinner label={t('billingNote.loading')} labelPosition="right" />
  //             </>
  //           ) : (
  //             <>
  //               {paymentMethod.connected ? (
  //                 <Stack horizontal>
  //                   <p>{t('billingNote.paypalConnectSuccess')}</p>
  //                 </Stack>
  //               ) : (
  //                 <>
  //                   <p>{t('billingNote.paypalConnect')}</p>
  //                   <PrimaryButton
  //                     onClick={() => {
  //                       history.push({
  //                         pathname: '/etax/sources/paypal/connect',
  //                         state: {
  //                           backUrl: '/documents/billing-notes',
  //                         },
  //                       });
  //                     }}
  //                   >
  //                     {t('paypalConnect.connect')}
  //                   </PrimaryButton>
  //                 </>
  //               )}
  //             </>
  //           )}
  //         </Stack>
  //       )}
  //     </>
  //   );
  // }

  // async function checkConnectedPaymentGateway(key) {
  //   instance
  //     .acquireTokenSilent({ ...silentRequest, account: account })
  //     .then(async (tokenResponse) => {
  //       if (key === 'paypal') {
  //         await axios
  //           .get(API_URL + '/sources/paypal', {
  //             headers: {
  //               Authorization: 'Bearer ' + tokenResponse.accessToken,
  //             },
  //           })
  //           .then(
  //             (response) => {
  //               console.log('Paypal sources: ', response);

  //               if (response.data) {
  //                 if (!response.data.disabled && response.data.canLogin) {
  //                   setPaymentMethod((prev) => ({ ...prev, connected: true }));
  //                 }
  //               }
  //               setPaymentGatewayLoading(false);
  //             },
  //             (error) => {
  //               console.log(error);
  //             }
  //           );
  //       }
  //     })
  //     .catch((error) => {
  //       //Acquire token silent failure, and send an interactive request
  //       console.log(error);
  //       instance.acquireTokenRedirect(silentRequest);
  //     });
  // }

  return (
    <form onSubmit={handleSubmit} onKeyDown={(e) => {
      if (e.key === "Enter") {
        e.preventDefault();
      }
    }}>
      <Stack>
        <h2 style={{ marginLeft: '0', marginTop: '25px' }}>{t('billingNote.header')}</h2>
        <div
          style={{
            width: '1000px',
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 30,
            background: '#ffffff',
            marginLeft: '0px',
            marginTop: '0px',
            boxShadow: theme.effects.elevation4,
          }}
        >
          <Stack horizontal horizontalAlign="space-between" styles={{ root: { height: 110 } }}>
            {companyName ? (
              <div className="ms-Grid-col ms-lg7">
                <h4
                  style={{
                    marginBottom: 2,
                    marginTop: 5,
                    fontSize: '16px',
                  }}
                >
                  {companyName} ({companyBranchText})
                </h4>
                <span>{companyAllAddress}</span>
                <br />
                {t('billingNote.companyTel')} {companyPhone} {t('billingNote.companyEmail')} {companyEmail}
                <br />
                {t('billingNote.companyTaxId')} {companyTaxId}
              </div>
            ) : (
              <div className="ms-Grid-col ms-lg7">
                <Shimmer height="1%" width="50%" />
                <br />
                <Shimmer width="65%" />
                <br />
                <Shimmer width="65%" />
              </div>
            )}

            <div className="ms-Grid-col ms-sm12 ms-lg5" style={{ width: 385 }}>
              <center>
                <h1
                  style={{
                    color: '#0C479D',
                    marginBottom: 5,
                    marginTop: 5,
                  }}
                >
                  {t('billingNote.documentHeader')}
                </h1>
                <span
                  style={{
                    color: '#696969',
                    fontSize: '16px',
                    fontWeight: 'bold',
                  }}
                >
                  {t('billingNote.documentSubHeader')}
                </span>
                <h3>{t('billingNote.original')}</h3>
              </center>
            </div>
          </Stack>

          <Stack horizontal tokens={{ childrenGap: 15 }}>
            <Stack vertical>
              <Stack horizontal tokens={{ childrenGap: 15 }}>
                <AutocompleteTextFieldContacts 
                  autocompleteType="name"
                  name="name"
                  label={t('billingNote.customerName')}
                  componentRef={(input) => (refArray.current.customerName = input)}
                  autoComplete="true"
                  styles={{ root: { width: 310 }, suffix: { background: 'none' } }}
                  maxLength={140}
                  state={name}
                  setState={setName}
                  value={name}
                  onGetErrorMessage={(value) => getErrorMessageWithMaxLength(value, 140, true)}
                  validateOnLoad={false}
                  validateOnFocusOut
                  required
                  autoFocus
                  onSelectOption={handleSelectSuggestions}
                />
                {/* <div>
                  <TextField
                    name="name"
                    label={t('billingNote.customerName')}
                    componentRef={(input) => (refArray.current.customerName = input)}
                    autoComplete="true"
                    styles={{ root: { width: 310 } }}
                    maxLength={140}
                    onChange={(e) => {
                      setName(e.target.value);
                      if (e.target.value) {
                        setShowSuggestions(true);
                      } else {
                        setShowSuggestions(false);
                      }
                    }}
                    value={name}
                    onGetErrorMessage={getErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                    autoFocus
                    onBlur={() => {
                      setShowSuggestions(false);
                      setShowSuggestionsTaxId(false);
                    }}
                  />
                  {showSuggestions && suggestionList[0] !== undefined && (
                    <div style={contactListStyle}>
                      {suggestionList.map((suggestion, index) => {
                        return (
                          <div
                            key={index}
                            id={suggestion.name}
                            className={controlClass.hover}
                            style={contactOptionStyle}
                            onMouseDown={(e) => handleSelectSuggestions(e, suggestion)}
                          >
                            {suggestion.name} {suggestion.taxId}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div> */}
                <Dropdown
                  placeholder={t('billingNote.pleaseSelect')}
                  label={t('billingNote.country')}
                  options={countryList}
                  selectedKey={countryCode}
                  styles={{ root: { width: 190 } }}
                  disabled={structure}
                  onChange={(e, selectedOption) => {
                    setCountryCode(selectedOption.key);
                    setCountryName(selectedOption.text);
                  }}
                />
                <Toggle
                  label={t('billingNote.addressStructure')}
                  onText={t('billingNote.on')}
                  offText={t('billingNote.off')}
                  checked={structure}
                  onChange={() => {
                    handleStructure(!structure);
                  }}
                />
              </Stack>

              {!structure && (
                <Stack horizontal tokens={{ childrenGap: 15 }}>
                  <TextField
                    name="lineOne"
                    label={t('billingNote.lineOne')}
                    componentRef={(input) => (refArray.current.lineOneRef = input)}
                    styles={{ root: { width: 615 } }}
                    maxLength={256}
                    onChange={(e) => {
                      setLineOne(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (!e.target.value.trim()) {
                        setLineOne('');
                      } else {
                        setLineOne(e.target.value.trim());
                      }
                    }}
                    value={lineOne}
                    onGetErrorMessage={(value) => getErrorMessageWithMaxLength(value, 256, true)}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                </Stack>
              )}

              {structure && (
                <Stack horizontal tokens={{ childrenGap: 15 }}>
                  <TextField
                    name="buildingNumber"
                    label={t('billingNote.buildingNumber')}
                    componentRef={(input) => (refArray.current.buildingNumberRef = input)}
                    styles={{ root: { width: 100 } }}
                    maxLength={16}
                    onChange={(e) => {
                      setBuildingNumber(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (!e.target.value.trim()) {
                        setBuildingNumber('');
                      } else {
                        setBuildingNumber(e.target.value.trim());
                      }
                    }}
                    value={buildingNumber}
                    onGetErrorMessage={(value) => getErrorMessageWithMaxLength(value, 16, true)}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                  <TextField
                    name="address"
                    label={t('billingNote.address')}
                    componentRef={(input) => (refArray.current.addressRef = input)}
                    styles={{ root: { width: 350 } }}
                    maxLength={256}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (!e.target.value.trim()) {
                        setAddress('');
                      } else {
                        setAddress(e.target.value.trim());
                      }
                    }}
                    value={address}
                    onGetErrorMessage={(value) => getErrorMessageWithMaxLength(value, 256, false)}
                    validateOnLoad={false}
                    validateOnFocusOut
                  />
                  <TextField
                    name="streetName"
                    label={t('billingNote.streetName')}
                    componentRef={(input) => (refArray.current.streetNameRef = input)}
                    styles={{ root: { width: 135 } }}
                    maxLength={70}
                    onChange={(e) => {
                      setStreetName(e.target.value);

                      setRoadPrefix(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (!e.target.value.trim()) {
                        setStreetName('');

                        setRoadPrefix('');
                      } else {
                        setStreetName(e.target.value.trim());
                        setRoadPrefix(e.target.value.trim());
                      }
                    }}
                    value={streetName}
                    onGetErrorMessage={(value) => getErrorMessageWithMaxLength(value, 70, false)}
                    validateOnLoad={false}
                    validateOnFocusOut
                  />
                </Stack>
              )}

              <Stack horizontal tokens={{ childrenGap: 15 }}>
                {structure ? (
                  <Stack horizontal tokens={{ childrenGap: 15 }}>
                    <Dropdown
                      placeholder={t('billingNote.pleaseSelect')}
                      label={t('billingNote.province')}
                      options={provinceList}
                      styles={dropdownStyles}
                      selectedKey={province.key}
                      onChange={(e, selectedOption) => {
                        setProvince(selectedOption);
                        setDistrict({
                          key: '',
                          text: '',
                        });
                        setSubDistrict({
                          key: '',
                          text: '',
                        });
                        setDistrictList(getDistrictList(selectedOption.key, language));
                        setSubDistrictList([]);

                        setAreaPrefix(selectedOption.key);

                        console.log('Province changed', districtList);

                        setProvinceErrMsg('');
                        setDistrictErrMsg('');
                        setSubDistrictErrMsg('');
                      }}
                      onBlur={(e) => {
                        console.log(province.key);
                        if (province.key === '') {
                          setProvinceErrMsg(DropdownErrMsg);
                        } else {
                          setProvinceErrMsg('');
                        }
                      }}
                      errorMessage={provinceErrMsg}
                      required
                    />
                    <Dropdown
                      placeholder={t('billingNote.pleaseSelect')}
                      label={t('billingNote.district')}
                      options={districtList}
                      selectedKey={district.key}
                      styles={dropdownStyles}
                      onChange={(e, selectedOption) => {
                        setDistrict(selectedOption);
                        setSubDistrict({
                          key: '',
                          text: '',
                        });
                        setSubDistrictList(getSubDistrictList(selectedOption.key, language));
                        console.log('District changed', subDistrictList);

                        setDistrictErrMsg('');
                        setSubDistrictErrMsg('');
                      }}
                      onBlur={(e) => {
                        console.log(district.key);
                        if (province.key !== '' && district.key === '') {
                          setDistrictErrMsg(DropdownErrMsg);
                        } else {
                          setDistrictErrMsg('');
                        }
                      }}
                      errorMessage={districtErrMsg}
                      required
                    />
                    <Dropdown
                      placeholder={t('billingNote.pleaseSelect')}
                      label={t('billingNote.subDistrict')}
                      options={subDistrictList}
                      selectedKey={subDistrict.key}
                      styles={dropdownStyles}
                      onChange={(e, selectedOption) => {
                        setSubDistrict(selectedOption);

                        setSubDistrictErrMsg('');
                      }}
                      onBlur={(e) => {
                        console.log(subDistrict.key);
                        if (district.key !== '' && subDistrict.key === '') {
                          setSubDistrictErrMsg(DropdownErrMsg);
                        } else {
                          setSubDistrictErrMsg('');
                        }
                      }}
                      errorMessage={subDistrictErrMsg}
                      required
                    />
                  </Stack>
                ) : (
                  <TextField
                    name="lineTwo"
                    label={t('billingNote.lineTwo')}
                    componentRef={(input) => (refArray.current.lineTwoRef = input)}
                    styles={{ root: { width: 480 } }}
                    maxLength={256}
                    onChange={(e) => {
                      setLineTwo(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (!e.target.value.trim()) {
                        setLineTwo('');
                      } else {
                        setLineTwo(e.target.value.trim());
                      }
                    }}
                    value={lineTwo}
                    onGetErrorMessage={(value) => getErrorMessageWithMaxLength(value, 256, false)}
                    validateOnLoad={false}
                    validateOnFocusOut
                  />
                )}
                <TextField
                  name="postcode"
                  label={t('billingNote.postcode')}
                  componentRef={(input) => (postCodeRef = input)}
                  styles={{ root: { width: 120 } }}
                  maxLength={16}
                  onChange={(e) => {
                    setPostCode(e.target.value);
                  }}
                  onBlur={(e) => {
                    if (!e.target.value.trim()) {
                      setPostCode('');
                    } else {
                      setPostCode(e.target.value.trim());
                    }
                  }}
                  value={postcode}
                  onGetErrorMessage={getPostCodeErrorMessage}
                  validateOnLoad={false}
                  validateOnFocusOut
                  required
                />
                <Toggle
                  label={t('billingNote.english')}
                  onText={t('billingNote.on')}
                  offText={t('billingNote.off')}
                  checked={language === 'en' ? true : false}
                  onChange={() => {
                    if (language === 'en') {
                      setLanguage('th');
                      sessionStorage.setItem('address-language', 'th');
                    } else {
                      setLanguage('en');
                      sessionStorage.setItem('address-language', 'en');
                    }
                  }}
                />
              </Stack>
              <Stack horizontal tokens={{ childrenGap: 15 }}>
                <Dropdown
                  placeholder={t('billingNote.pleaseSelect')}
                  label={t('billingNote.taxNumberType')}
                  options={[
                    {
                      key: 'TXID',
                      text: t('billingNote.txid'),
                    },
                    {
                      key: 'NIDN',
                      text: t('billingNote.nidn'),
                    },
                    {
                      key: 'CCPT',
                      text: t('billingNote.ccpt'),
                    },
                    { key: 'OTHR', text: t('billingNote.othr') },
                  ]}
                  styles={{ root: { width: 180 } }}
                  selectedKey={taxNumberType}
                  onChange={(e, selectedOption) => {
                    handleTaxType(selectedOption);

                    setTaxNumberTypeErrMsg('');
                  }}
                  onBlur={(e) => {
                    console.log(taxNumberType);
                    if (taxNumberType === '' || taxNumberType === undefined) {
                      setTaxNumberTypeErrMsg(DropdownErrMsg);
                    } else {
                      setTaxNumberTypeErrMsg('');
                    }
                  }}
                  errorMessage={taxNumberTypeErrMsg}
                  required
                />
                {showTaxId && (
                  <TextField
                    name="taxId"
                    label={t('billingNote.number')}
                    componentRef={(input) => (refArray.current.taxIdRef = input)}
                    styles={{ root: { width: 120 } }}
                    autoComplete="true"
                    value={taxId}
                    maxLength={35}
                    onChange={(e) => {
                      setTaxId(e.target.value);
                    }}
                    onGetErrorMessage={getTaxIdErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                )}

                {showBranch && (
                  <Stack horizontal tokens={{ childrenGap: 15 }}>
                    <Dropdown
                      placeholder={t('billingNote.pleaseSelect')}
                      label={t('billingNote.branchDisplay')}
                      options={branchDisplay}
                      selectedKey={branch}
                      styles={{ root: { width: 160 } }}
                      onChange={(e, selectedOption) => handleBranch(selectedOption.key, language)}
                    />
                    {showBranchNumber && (
                      <TextField
                        name="branchNumber"
                        label={t('billingNote.number')}
                        description={t('billingNote.branchNumberDesc')}
                        componentRef={(input) => (refArray.current.branchNumberRef = input)}
                        styles={{
                          root: { width: 110 },
                        }}
                        value={branchNumber}
                        required={showBranchNumber}
                        maxLength={5}
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            setBranchNumber(e.target.value);

                            if (language === 'en') {
                              setBranchText('Branch ' + e.target.value);
                            } else {
                              setBranchText('สาขา ' + e.target.value);
                            }
                          }
                        }}
                        onBlur={(e) => {
                          if (!e.target.value.trim()) {
                            setBranchNumber('');
                          } else {
                            setBranchNumber(e.target.value.trim());

                            if (language === 'en') {
                              setBranchText('Branch ' + e.target.value.trim());
                            } else {
                              setBranchText('สาขา ' + e.target.value.trim());
                            }
                          }
                        }}
                        onGetErrorMessage={getBranchNumberErrorMessage}
                        validateOnLoad={false}
                        validateOnFocusOut
                      />
                    )}
                  </Stack>
                )}
              </Stack>

              <br />
              <Stack horizontal tokens={{ childrenGap: 15 }}>
                <TextField
                  name="contactName"
                  label={t('billingNote.contactName')}
                  styles={{ root: { width: 315 } }}
                  maxLength={140}
                  onChange={(e) => {
                    setContactName(e.target.value);
                  }}
                  value={contactName}
                />
              </Stack>

              <Stack horizontal tokens={{ childrenGap: 15 }}>
                <TextField
                  componentRef={(input) => (phoneRef = input)}
                  name="phone"
                  label={t('billingNote.tel')}
                  styles={{ root: { width: 200 } }}
                  onGetErrorMessage={(value) => getPhoneNumberErrorMessage(value, 35)}
                  maxLength={35}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  value={phone}
                  validateOnFocusOut
                />
                <TextField
                  componentRef={(input) => (refArray.current.emailRef = input)}
                  name="email"
                  label={t('billingNote.email')}
                  styles={{ root: { width: 300 } }}
                  maxLength={256}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  onGetErrorMessage={(value) => getErrorMessageWithMaxLength(value, 256, false)}
                  validateOnFocusOut
                />
                {registerVat && (
                  <Toggle
                    label={t('billingNote.priceIncludeVat')}
                    onText={t('billingNote.on')}
                    offText={t('billingNote.off')}
                    checked={includeVat}
                    onChange={() => {
                      setIncludeVat(!includeVat);

                      setCompany({
                        ...company,
                        includeVat: !includeVat,
                      });

                      updateCompany('includeVat', !includeVat);
                    }}
                    style={{ alignItems: 'right' }}
                  />
                )}
              </Stack>
            </Stack>

            <Stack vertical></Stack>

            <Stack vertical verticalAlign="space-between">
              <Stack>
                <Stack horizontal>
                  <TextField
                    name="number"
                    label={t('billingNote.number')}
                    componentRef={(input) => (refArray.current.numberRef = input)}
                    styles={{ root: { width: 150 } }}
                    maxLength={35}
                    onChange={(e) => {
                      setNumber(e.target.value);
                    }}
                    value={number}
                    onGetErrorMessage={(value) => 
                      getErrorMessageWithMaxLength(value, 35, true, 
                        value.includes(",") ? t('billingNote.getErrorMessageNoComma') : value.includes(" ") ? t('billingNote.getErrorMessageNoEmptySpace') : "")
                    }
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                </Stack>
                <Stack horizontal>
                  <DatePicker
                    name="date"
                    label={t('billingNote.date')}
                    placeholder={t('billingNote.pleaseSelectDate')}
                    allowTextInput={false}
                    className={controlClass.control}
                    firstDayOfWeek={firstDayOfWeek}
                    strings={DayPickerStrings}
                    formatDate={onFormatDate}
                    styles={{ root: { width: 150 } }}
                    onSelectDate={(date) => {
                      setDateBE(
                        `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + (date.getFullYear() + 543)
                      );
                      setDateCE(`${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear());

                      setDate(date.toISOString());
                    }}
                    value={new Date(date)}
                    isRequired={true}
                    componentRef={dateRef}
                  />
                </Stack>
              </Stack>
              <Stack>
                <Stack horizontal>
                  <DatePicker
                    name="dueDate"
                    label={t('billingNote.dueDate')}
                    placeholder={t('billingNote.pleaseSelectDate')}
                    allowTextInput={false}
                    className={controlClass.control}
                    firstDayOfWeek={firstDayOfWeek}
                    strings={DayPickerStrings}
                    formatDate={onFormatDate}
                    styles={{ root: { width: 150 } }}
                    onSelectDate={(dueDate) => {
                      setDueDateBE(
                        `${dueDate.getDate()}`.padStart(2, '0') +
                          '/' +
                          `${dueDate.getMonth() + 1}`.padStart(2, '0') +
                          '/' +
                          (dueDate.getFullYear() + 543)
                      );
                      setDueDateCE(
                        `${dueDate.getDate()}`.padStart(2, '0') + '/' + `${dueDate.getMonth() + 1}`.padStart(2, '0') + '/' + dueDate.getFullYear()
                      );

                      setDueDate(dueDate.toISOString());
                    }}
                    value={dueDate ? new Date(dueDate) : ''}
                    componentRef={dueDateRef}
                  />
                </Stack>
                <Stack horizontal style={{ marginTop: '-15px' }}>
                  <TextField
                    name="reference"
                    label={t('billingNote.reference')}
                    styles={{ root: { width: 150 } }}
                    maxLength={35}
                    onChange={(e) => {
                      setReference(e.target.value);
                    }}
                    value={reference}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>

          <div>
            <br />
            <Separator />
            <br />
          </div>

          <Stack horizontal tokens={{ childrenGap: 15 }}>
            {registerVat ? (
              <>
                <span align="right" style={{ width: '42px', fontSize: '16px' }}>
                  {t('billingNote.itemNumber')}
                </span>
                <span
                  align="center"
                  style={{
                    fontSize: '16px',
                    width: '276px',
                  }}
                >
                  {t('billingNote.item')}
                </span>
                <span align="right" style={{ width: '60px', fontSize: '16px' }}>
                  {t('billingNote.quantity')}
                </span>
                <span align="right" style={{ width: '68px', fontSize: '16px' }}>
                  {t('billingNote.unit')}
                </span>
                <span align="right" style={{ width: '87px', fontSize: '16px' }}>
                  {t('billingNote.unitPrice')}
                </span>
                <span align="right" style={{ width: '68px', fontSize: '16px' }}>
                  {t('billingNote.discount')}
                </span>
                <span align="right" style={{ width: '57px', fontSize: '16px' }}>
                  {t('billingNote.itemVat')}
                </span>
                <span align="right" style={{ width: '95px', fontSize: '16px' }}>
                  {includeVat ? <span>{t('billingNote.totalIncludeVat')}</span> : <span>{t('billingNote.totalBeforeVat')}</span>}
                </span>
                <span align="right" style={{ width: '78px', fontSize: '16px' }}>
                  {t('expenseNote.itemWhtTotal')}
                </span>
              </>
            ) : (
              <>
                <span align="right" style={{ width: '45px', fontSize: '16px' }}>
                  {t('billingNote.itemNumber')}
                </span>
                <span
                  align="center"
                  style={{
                    fontSize: '16px',
                    width: '313px',
                  }}
                >
                  {t('billingNote.item')}
                </span>
                <span align="right" style={{ width: '65px', fontSize: '16px' }}>
                  {t('billingNote.quantity')}
                </span>
                <span align="right" style={{ width: '75px', fontSize: '16px' }}>
                  {t('billingNote.unit')}
                </span>
                <span align="right" style={{ width: '92px', fontSize: '16px' }}>
                  {t('billingNote.unitPrice')}
                </span>
                <span align="right" style={{ width: '75px', fontSize: '16px' }}>
                  {t('billingNote.discount')}
                </span>
                <span align="right" style={{ width: '102px', fontSize: '16px' }}>
                  {t('billingNote.total')}
                </span>
                <span align="right" style={{ width: '78px', fontSize: '16px' }}>
                  {t('expenseNote.itemWhtTotal')}
                </span>
              </>
            )}
          </Stack>
          <br />

          {items.map((item, index) => {
            return (
              <div key={index}>
                <Stack horizontal tokens={{ childrenGap: 15 }}>
                  <TextField
                    name="number"
                    componentRef={(input) => (itemsNumberRef.current[index] = input)}
                    styles={{ root: { width: 50 } }}
                    value={items[index]['number']}
                    maxLength={35}
                    onChange={(event) => handleChangeItem(index, event)}
                    onGetErrorMessage={(value) => getErrorMessageWithMaxLength(value, 35, true)}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                  <div style={{ width: registerVat ? 325 : 340}}>
                    <AutocompleteTextField 
                      componentRef={(input) => (itemsDescRef.current[index] = input)}
                      styles={{
                        suffix: { background: 'none' }
                      }} 
                      name="description" 
                      onChangeFunction={handleChangeItem} 
                      value={items[index]['description']}
                      description={item.sku ? item.sku : ''}
                      items={items} 
                      setState={setItems} 
                      index={index}
                      maxLength={256}
                      onGetErrorMessage={(value) => getErrorMessageWithMaxLength(value, 256, true)}
                      validateOnLoad={false}
                      validateOnFocusOut
                    />
                  </div>
                  {/* <TextFieldWithDropDown 
                    name="description" 
                    styles={{
                      root: {
                        width: registerVat ? 325 : 340,
                      },
                    }} 
                    index={index} 
                    onChangeFunction={handleChangeItem} 
                    value={items[index]['description']}
                    description={item.sku ? item.sku : ''}
                    items={items} 
                    setState={setItems} 
                  /> */}
                  {/* <TextField
                    name="description"
                    styles={{
                      root: {
                        width: registerVat ? 325 : 340,
                      },
                    }}
                    componentRef={(input) => (itemsDescRef.current[index] = input)}
                    value={items[index]['description']}
                    maxLength={256}
                    onChange={(event) => handleChangeItem(index, event)}
                    onGetErrorMessage={getErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  /> */}
                  <TextField
                    name="quantity"
                    styles={{ root: { width: 70 } }}
                    componentRef={(input) => (itemsQtyRef.current[index] = input)}
                    type="number"
                    min="0"
                    step=".01"
                    max={9999999999.99}
                    value={items[index]['quantity']}
                    onChange={(event) => {
                      if (event.target.value >= 0) {
                        handleChangeItem(index, event);
                      }
                    }}
                    onBlur={(event) => handleBlurItem(index, event)}
                    onGetErrorMessage={getErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                  <Dropdown
                    name="unitCode"
                    options={unitList}
                    selectedKey={items[index]['unitCode']}
                    styles={{ root: { width: 80 } }}
                    onChange={(event, selectedOption) => {
                      console.log(selectedOption);
                      handleChangeItem(index, event, selectedOption);
                    }}
                  />
                  <TextField
                    name="price"
                    styles={{
                      root: { width: 100 },
                    }}
                    componentRef={(input) => (itemsPriceRef.current[index] = input)}
                    type="number"
                    min="0"
                    step=".01"
                    max={9999999999.99}
                    value={items[index]['price']}
                    onChange={(event) => {
                      if (event.target.value >= 0) {
                        handleChangeItem(index, event);
                      }
                    }}
                    onBlur={(event) => handleBlurItem(index, event)}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                  <TextField
                    name="discount"
                    styles={{
                      root: { width: 80 },
                    }}
                    type="number"
                    min="0"
                    step=".01"
                    max={9999999999.99}
                    value={items[index]['discount']}
                    onChange={(event) => {
                      if (event.target.value >= 0) {
                        handleChangeItem(index, event);
                      }
                    }}
                    onBlur={(event) => handleBlurItem(index, event)}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                  {registerVat && (
                    <Dropdown
                      name="percentVat"
                      options={[
                        {
                          key: nonVat,
                          text: nonVatText,
                          id: 'percentVat',
                        },
                        {
                          key: zeroVat,
                          text: zeroVatText,
                          id: 'percentVat',
                        },
                        {
                          key: percentVat,
                          text: percentVatText,
                          id: 'percentVat',
                        },
                      ]}
                      selectedKey={items[index]['percentVat']}
                      styles={{ root: { width: 65 } }}
                      onChange={(event, selectedOption) => {
                        console.log(selectedOption);
                        handleChangeItem(index, event, selectedOption);
                      }}
                    />
                  )}
                  <TextField
                    name="total"
                    styles={{ root: { width: 110 } }}
                    disabled
                    readOnly
                    value={items[index]['total'].toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                    onGetErrorMessage={() => {
                      if (items[index]['total'] < 0) {
                        return t('billingNote.totalErrorMessage1');
                      }
                    }}
                  />
                  <ComboBox
                    name="percentWht"
                    allowFreeform={true}
                    options={percentWhtList}
                    text={String(items[index]['whtTotal'])}
                    selectedKey={items[index]['percentWht']}
                    styles={{ root: { width: 80 } }}
                    onChange={(event, selectedOption) => {
                      console.log(selectedOption);
                      handlePercentWht(index, event, selectedOption);
                    }}
                  />
                  <IconButton iconProps={{ iconName: 'Delete' }} title={t('billingNote.delete')} onClick={() => handleRemoveItem(index)} />
                </Stack>

                <br />
              </div>
            );
          })}

          <Stack horizontal style={centerItem}>
            <PrimaryButton
              iconProps={{ iconName: 'Add' }}
              style={{ letterSpacing: '1px' }}
              name="addItem"
              text={t('billingNote.addItem')}
              onClick={() => handleAddItem()}
            />
          </Stack>
          <br />

          <Stack horizontal horizontalAlign="space-between">
            <Stack vertical>
              <Stack horizontal>
                <TextField
                  name="note"
                  label={t('billingNote.note')}
                  componentRef={(input) => (refArray.current.noteRef = input)}
                  styles={{ root: { width: 450 } }}
                  maxLength={500}
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                  value={note}
                  onGetErrorMessage={(value) => getErrorMessageWithMaxLength(value, 500, false)}
                  validateOnLoad={false}
                  validateOnFocusOut
                  multiline 
                  rows={2}
                />
              </Stack>
              <Stack horizontal>
                <br />
              </Stack>
              <Stack horizontal>
                <br />
                <TextField
                  name="createdBy"
                  label={t('billingNote.createdBy')}
                  styles={{ root: { width: 450 } }}
                  maxLength={140}
                  onChange={(e) => {
                    setCreatedBy(e.target.value);
                  }}
                  value={createdBy}
                />
              </Stack>
            </Stack>

            <Stack vertical>
              <br />
              <Stack horizontal>
                <span style={{ width: 178, fontSize: '18px' }}>{t('billingNote.discount')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                {
                  <TextField
                    name="specialDiscount"
                    styles={{
                      root: { width: 150 },
                    }}
                    type="number"
                    min={0}
                    step=".01"
                    max={9999999999.99}
                    value={discount}
                    onChange={(event) => {
                      if (Number(event.target.value) >= 9999999999.99) {
                        setDiscount(9999999999.99);
                      } else {
                        if (Number(event.target.value) <= 0) {
                          setDiscount(0);
                        } else {
                          setDiscount(event.target.value);
                        }
                      }
                    }}
                    onBlur={(event) => setDiscount(Math.round(event.target.value * 100) / 100)}
                    disabled={discountDisable}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                }
                <span style={{ fontSize: '18px' }}>&nbsp;&nbsp;{t('billingNote.thb')}</span>
              </Stack>
              <br />
              {!registerVat ? (
                <Stack horizontal>
                  <span style={{ width: 178, fontSize: '18px' }}>{t('billingNote.amountTotal')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  {
                    <TextField
                      styles={{ root: { width: 150 } }}
                      disabled
                      readOnly
                      value={
                        amountTotal >= 0
                          ? amountTotal.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })
                          : '0.00'
                      }
                    />
                  }
                  <span style={{ fontSize: '18px' }}>&nbsp;&nbsp;{t('billingNote.thb')}</span>
                </Stack>
              ) : includeVat ? (
                <Stack horizontal>
                  <span style={{ width: 178, fontSize: '18px' }}>{t('billingNote.amountTotalBeforeVat')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  {
                    <TextField
                      styles={{ root: { width: 150 } }}
                      disabled
                      readOnly
                      value={
                        amountTotal >= 0
                          ? amountTotal.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })
                          : '0.00'
                      }
                    />
                  }
                  <span style={{ fontSize: '18px' }}>&nbsp;&nbsp;{t('billingNote.thb')}</span>
                </Stack>
              ) : (
                <Stack horizontal>
                  <span style={{ width: 178, fontSize: '18px' }}>{t('billingNote.amountTotal')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  {
                    <TextField
                      styles={{ root: { width: 150 } }}
                      disabled
                      readOnly
                      value={
                        amountTotal >= 0
                          ? amountTotal.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })
                          : '0.00'
                      }
                    />
                  }
                  <span style={{ fontSize: '18px' }}>&nbsp;&nbsp;{t('billingNote.thb')}</span>
                </Stack>
              )}

              {registerVat && (
                <Stack>
                  <br />
                  <Stack horizontal>
                    <span
                      style={{
                        width: 178,
                        fontSize: '18px',
                      }}
                    >
                      {t('billingNote.vat')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    {
                      <TextField
                        styles={{
                          root: { width: 150 },
                        }}
                        disabled
                        readOnly
                        value={
                          vatTotal >= 0
                            ? vatTotal.toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              })
                            : '0.00'
                        }
                      />
                    }
                    <span style={{ fontSize: '18px' }}>&nbsp;&nbsp;{t('billingNote.thb')}</span>
                  </Stack>
                  <br />
                  <Stack horizontal>
                    <span
                      style={{
                        width: 178,
                        fontSize: '18px',
                      }}
                    >
                      {t('billingNote.grandTotal')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    {
                      <TextField
                        styles={{
                          root: { width: 150 },
                        }}
                        disabled
                        readOnly
                        value={
                          grandTotal >= 0
                            ? grandTotal.toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              })
                            : '0.00'
                        }
                      />
                    }
                    <span style={{ fontSize: '18px' }}>&nbsp;&nbsp;{t('billingNote.thb')}</span>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Stack>

          <br />

          <Stack horizontal horizontalAlign="space-between" styles={{ root: { margin: '36.8px 0px 0px 0px' } }}>
            <Stack vertical>
              <Stack horizontalAlign="start">
                <Stack horizontal>
                  <span style={{ width: 250, fontSize: '18px' }}>{t('expenseNote.whtTotal')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>{' '}
                  {
                    <TextField
                      styles={{ root: { width: 150 } }}
                      disabled
                      readOnly
                      value={whtTotal.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    />
                  }
                  &nbsp;&nbsp;&nbsp;&nbsp;{t('expenseNote.thb')}
                </Stack>
                <br />
                <Stack horizontal>
                  <span style={{ width: 250, fontSize: '18px' }}>{t('expenseNote.totalToPay')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>{' '}
                  {
                    <TextField
                      styles={{ root: { width: 150 } }}
                      disabled
                      readOnly
                      value={(grandTotal - whtTotal).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    />
                  }
                  &nbsp;&nbsp;&nbsp;&nbsp;{t('expenseNote.thb')}
                </Stack>
              </Stack>
            </Stack>
            {registerVat && (
              <Stack vertical>
                <Stack horizontal>
                  <span style={{ width: 178 }}>{t('billingNote.nonVatPriceTotal')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  {
                    <TextField
                      styles={{
                        root: { width: 150 },
                      }}
                      disabled
                      readOnly
                      value={
                        nonVatPriceTotal >= 0
                          ? nonVatPriceTotal.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })
                          : '0.00'
                      }
                    />
                  }
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;{t('billingNote.thb')}</span>
                </Stack>
                <br />
                <Stack horizontal>
                  <span style={{ width: 178 }}>{t('billingNote.zeroVatPriceTotal')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  {
                    <TextField
                      styles={{
                        root: { width: 150 },
                      }}
                      disabled
                      readOnly
                      value={
                        zeroVatPriceTotal >= 0
                          ? zeroVatPriceTotal.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })
                          : '0.00'
                      }
                    />
                  }
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;{t('billingNote.thb')}</span>
                </Stack>
                <br />
                <Stack horizontal>
                  <span style={{ width: 178 }}>{t('billingNote.vatPriceTotal')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  {
                    <TextField
                      styles={{
                        root: { width: 150 },
                      }}
                      disabled
                      readOnly
                      value={
                        vatPriceTotal >= 0
                          ? vatPriceTotal.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })
                          : '0.00'
                      }
                    />
                  }
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;{t('billingNote.thb')}</span>
                </Stack>
              </Stack>
            )}
          </Stack>

          <div>
            <br />
            <Separator />
            <br />
          </div>
          <Stack horizontal>
            <Stack vertical style={{ width: 800 }}>
              <Stack horizontal>
                <Toggle
                  label={t('billingNote.payment')}
                  onText={t('billingNote.on')}
                  offText={t('billingNote.off')}
                  checked={payment}
                  onChange={() => {
                    if (payment) {
                      setPromptpay(false);
                    }

                    setPayment(!payment);
                    setPaymentChanged(true);
                  }}
                />
              </Stack>

              {payment && (
                <Stack horizontal horizontalAlign="space-between">
                  <Stack vertical>
                    <Stack horizontal>
                      <br />
                      <TextField
                        name="accountName"
                        label={t('billingNote.accountName')}
                        styles={{
                          root: {
                            width: 285,
                            marginRight: 15,
                          },
                        }}
                        maxLength={140}
                        onChange={(e) => {
                          setAccountName(e.target.value);
                          setPaymentChanged(true);
                        }}
                        value={accountName}
                      />
                      <Toggle
                        label={t('billingNote.english')}
                        onText={t('billingNote.on')}
                        offText={t('billingNote.off')}
                        checked={paymentLanguage === 'en' ? true : false}
                        onChange={() => {
                          if (paymentLanguage === 'en') {
                            setPaymentLanguage('th');
                          } else {
                            setPaymentLanguage('en');
                          }
                        }}
                      />
                    </Stack>
                    {banks.map((bank, index) => {
                      return (
                        <div key={index}>
                          <Stack horizontal>
                            <br />
                            <Dropdown
                              placeholder={t('billingNote.pleaseSelect')}
                              label={t('billingNote.bankName')}
                              options={bankList}
                              styles={{
                                root: {
                                  width: 150,
                                  marginRight: 15,
                                },
                              }}
                              selectedKey={banks[index]['bankCode']}
                              onChange={(event, selectedOption) => {
                                console.log(selectedOption);
                                handleChangeBankName(index, event, selectedOption);
                              }}
                            />
                            <TextField
                              name="branch"
                              label={t('billingNote.bankBranch')}
                              styles={{
                                root: {
                                  width: 120,
                                  marginRight: 15,
                                },
                              }}
                              maxLength={140}
                              onChange={(event) => {
                                handleChangeBank(index, event);
                              }}
                              value={banks[index]['branch']}
                            />
                            <Dropdown
                              placeholder={t('billingNote.pleaseSelect')}
                              label={t('billingNote.accountType')}
                              options={accountTypeList}
                              styles={{
                                root: {
                                  width: 120,
                                  marginRight: 15,
                                },
                              }}
                              selectedKey={banks[index]['typeCode']}
                              onChange={(event, selectedOption) => {
                                console.log(selectedOption);
                                handleChangeBankType(index, event, selectedOption);
                              }}
                            />
                            <TextField
                              name="accountNumber"
                              label={t('billingNote.accountNumber')}
                              styles={{
                                root: {
                                  width: 150,
                                },
                              }}
                              maxLength={35}
                              onChange={(event) => {
                                handleChangeBank(index, event);
                              }}
                              value={banks[index]['accountNumber']}
                            />
                            <IconButton
                              iconProps={{
                                iconName: 'Delete',
                              }}
                              title={t('billingNote.delete')}
                              style={{
                                marginTop: 30,
                              }}
                              onClick={() => handleRemoveBank(index)}
                            />
                          </Stack>
                        </div>
                      );
                    })}
                    <Stack horizontal>
                      <br />
                    </Stack>
                    <Stack horizontal>
                      <br />
                      <PrimaryButton
                        iconProps={{ iconName: 'Add' }}
                        style={{ letterSpacing: '1px' }}
                        name="addBank"
                        text={t('billingNote.addBank')}
                        onClick={() => handleAddBank()}
                      />
                      <br />
                    </Stack>
                    <Stack horizontal>
                      <br />
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </Stack>

            <Stack vertical>
              {payment && (
                <Stack horizontal>
                  <Toggle
                    label={t('billingNote.promtpayQr')}
                    onText={t('billingNote.on')}
                    offText={t('billingNote.off')}
                    checked={promptpay}
                    onChange={() => {
                      setPromptpay(!promptpay);
                      setPaymentChanged(true);
                    }}
                  />
                </Stack>
              )}

              {payment && promptpay && (
                <Stack vertical>
                  <Stack horizontal>
                    <br />
                    {t('billingNote.taxNumber')} {company.taxId}
                  </Stack>
                  <Stack horizontal>
                    <br />
                    <img alt="" src={promptpayQr} style={{ marginLeft: -15 }} />
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Stack>
          <Stack horizontal>
            {payment && (
              <>
                <Stack vertical style={{ width: 800 }}>
                  <TextField
                    name="paymentNote"
                    label={t('billingNote.paymentNote')}
                    styles={{ root: { width: 420 } }}
                    maxLength={256}
                    onChange={(e) => {
                      setPaymentNote(e.target.value);
                      setPaymentChanged(true);
                    }}
                    value={paymentNote}
                  />
                </Stack>
                {/* 
                <Stack vertical>
                  <Dropdown
                    placeholder={t('billingNote.pleaseSelect')}
                    label={t('billingNote.paymentGateway')}
                    options={[
                      { key: '', text: t('billingNote.notSelect'), connected: false },
                      { key: 'stripe', text: 'Stripe', connected: false },
                      { key: 'paypal', text: 'PayPal', connected: false },
                    ]}
                    selectedKey={paymentMethod.key}
                    styles={dropdownStyles}
                    onChange={async (e, selectedOption) => {
                      setPaymentMethod(selectedOption);
                      setPaymentGatewayLoading(true);

                      await checkConnectedPaymentGateway(selectedOption.key);
                    }}
                  />
                  <StripeComponent />
                  <PayPalComponent />
                </Stack>
 */}
              </>
            )}
          </Stack>

          <br />
          <br />
          <>
            {companyName && (
              <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
                <PrimaryButton text={t('billingNote.next')} style={{ width: '125px', letterSpacing: '1px' }} type="submit" value="next" />
                <DefaultButton text={t('billingNote.cancel')} onClick={handleCancel} />
              </Stack>
            )}
            {!companyName && <Spinner label={t('billingNote.loading')} labelPosition="right" />}
          </>
          <br />
          <br />
        </div>

        <br />
        <br />
      </Stack>
    </form>
  );
};

export default BillingNote;
