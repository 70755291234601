import * as React from "react";
import { useState, useEffect } from "react";
import {
  makeStyles,
  shorthands,
  Text,
  MenuButton,
  MenuTrigger,
  MenuPopover,
  MenuList,
  Menu,
  MenuItemRadio,
} from "@fluentui/react-components";
// import "./SelectTemplateApp.css";

import {
  documentOptionswithTextEn,
  documentOptionswithTextTh,
} from "./PageNumber";

import { useTranslation } from "react-i18next";
import { TextNumberListLtrFilled } from "@fluentui/react-icons";

const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    minWidth: "50px",
  },
});

export const DropDownForContactsList = ({
  onClick,
  selectedValue,

  setSelectedOptions,

  setSelectedOptionsValuePerPage,

  isLoadComplete,
  isSearchComplete,

  checkedValuesDocument,

  onChangeDocuments,
}) => {
  const { t } = useTranslation();
  const languageCheck = t("requestFormEditContact.en");
  // const options = documentOptions;
  const options =
    languageCheck === "TH"
      ? documentOptionswithTextTh
      : documentOptionswithTextEn;

  const styles = useStyles();
  // const [selectedOptions, setSelectedOptions] = useState([options[0]]);

  const [dropdownOption, setDropdownOption] = useState([]);
  useEffect(() => {
    if (options.length > 0) {
      setDropdownOption(options);
      setSelectedOptions([options[0]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageCheck, options]);

  useEffect(() => {
    if (dropdownOption.length > 0) {
      // Convert selectedValue to an array index
      const index = selectedValue / 10 - 1;

      // Ensure the index is within the bounds of the dropdownOption array
      if (index >= 0 && index < dropdownOption.length) {
        setSelectedOptionsValuePerPage([dropdownOption[index]?.key]);
      } else {
        console.error("Index is out of bounds:", index);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue, dropdownOption]);

  return (
    <div className={styles.root}>
      <div style={{ display: "flex", minWidth: "50px" }}>
        {/* <Dropdown
          // defaultValue="30 เอกสาร/หน้า" // Set default value to the title property
          aria-labelledby={dropdownId}
          // value={getDocumentTypeText(selectedValue, languageCheck)}
          style={{ minWidth: "unset" }}
          selectedOptions={selectedOptionsValuePerPage}
          // onOptionSelect={onOptionSelect}
          placeholder={textPerPage}
          value={textPerPage}
          // selectedOptions={selectedOptionsValuePerPage}
          // defaultSelectedOptions={["30"]}
          disabled={!isLoadComplete || !isSearchComplete}
          // expandIcon={<TextNumberListLtrFilled />}
        >
          {options.map((option) => (
            <Option
              key={option.key}
              value={option.key}
              onClick={() => onClick(option.value)}
              text={option.title}
            >
              <TextNumberListLtrFilled />
              {option.title}
            </Option>
          ))}
        </Dropdown> */}

        <Menu
          checkedValues={checkedValuesDocument}
          onCheckedValueChange={onChangeDocuments}
          closeOnScroll={true}
          disabled={!isLoadComplete || !isSearchComplete}
        
        >
          <MenuTrigger >
            <MenuButton
              icon={<TextNumberListLtrFilled />}
              disabled={!isLoadComplete || !isSearchComplete}
              
            >
              {t("requestFormContactsList.documents")} {selectedValue}
            </MenuButton>
          </MenuTrigger>

          <MenuPopover>
            <MenuList>
              {options.map((option, index) => (
                <MenuItemRadio
                  key={index + 1}
                  value={index + 1}
                  onClick={() => onClick(option.value)}
                  // onClick={() => onClick(option.key)}
                  // text={
                  //   languageCheck === "TH"
                  //     ? `หน้า ${option.key}`
                  //     : `page ${option.key}`
                  // }

                  onSelect={selectedValue}
                  icon={<TextNumberListLtrFilled />}
                  name="documents"
                  disabled={!isLoadComplete || !isSearchComplete}
                >
                  <Text>
                    {" "}
                    {t("requestFormContactsList.documents")} {option.value}
                  </Text>
                </MenuItemRadio>
              ))}
            </MenuList>
          </MenuPopover>
        </Menu>
      </div>
    </div>
  );
};
