import React from "react";
import "./ThemeSelector.css"; // Import CSS module
import { useTranslation } from "react-i18next";

import { ProgressBar, Text } from "@fluentui/react-components";

const ExampleFormFirstPage = () => {
  const { t } = useTranslation();
  return (
    <div className="wrap-example">
      <div style={{ marginBottom: "10px" }}>
        <Text
          as="h1"
          block
          style={{ fontSize: "15px", textAlign: "center" }}
          className="topic-header"
        >
          {t("requestFormAdminFormEditor.fullRequest")} <br />
          {t("requestFormAdminFormEditor.fullRequest2")}
        </Text>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            width: 30,
            height: 30,
            borderRadius: "50%",
            border: "1px solid #000",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "5px",
            fontSize: "20px",
          }}
          className="circle-active-background"
        >
          <p>1</p>
        </div>
        <div
          style={{
            width: 30,
            height: 30,
            borderRadius: "50%",
            border: "1px solid #000",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "20px",
          }}
          className="circle-not-active-background"
        >
          <p>2</p>
        </div>
        <div
          style={{
            width: 30,
            height: 30,
            borderRadius: "50%",
            border: "1px solid #000",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "5px",
            fontSize: "20px",
          }}
          className="circle-not-active-background"
        >
          <p>3</p>
        </div>
      </div>
      {/* Step indicators */}
      {/* Progress bar */}
      <ProgressBar
        className="progressBar"
        shape="square"
        thickness="large"
        value={0.3}
      />{" "}
      <div
        style={{
          width: "100%",
          marginTop: "10px",
          height: "100px",
          marginBottom: "0.25rem",
          overflow: "hidden",
        }}
      >
        <Text className="accent-color-apply">
          {t("requestFormAdminFormEditor.firstStep")}
        </Text>
   

        <div className="card-style-example-second">
          <select style={{ width: "100%" }}></select>
          <input style={{ width: "95%" }}></input>
        </div>
      </div>
    </div>
  );
};

export default ExampleFormFirstPage;
