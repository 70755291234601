import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_SUPPORT_EMAIL_API_KEY;

export async function sendFeedback(from, functionName, error, username, params) {
    let email = username;

    let message = `<p>หัวข้อ: Catch Error from ${from}<p>
    <br />
    <p>function: ${functionName}<p>
    <br />
    <p>error: ${error.message}<p>
    <br />
    <p>params: ${params ? JSON.stringify(params) : "no params"}<p>`;

    
    const response = await axios.post(
        API_URL + '/external/emails/support',
        {
            subject: '[Leceipt-Feedback] ' + email,
            message: message,
        },
        {
        headers: {
            'Content-Type': 'application/json',
            'API-KEY': API_KEY,
        },
        }
    );

    return console.log(`response get feedback email from: ${from}, functionName: ${functionName}`, response)
      
}