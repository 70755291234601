import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Divider, Dropdown, Field, Input, Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger, Option, Select, Spinner, Subtitle2Stronger, Title2, makeStyles, shorthands, useId } from '@fluentui/react-components';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AddRegular,
  ChevronLeftRegular,
  ChevronRightRegular,
  DismissRegular,
  SearchRegular,
  MoreHorizontalRegular,
  DeleteRegular,
  EditRegular
  } from "@fluentui/react-icons";
import { checkEmail } from '../../../helpers/CheckEmail';

const homeStyles = {
    border: 0,
    margin: 0,
    width: '100%',
    minWidth: 'calc(100vw - 310px)',
    background: '#FAF9F8',
    position: 'relative',
  };

  const stylesTable = {
    tableContainer: {
      overflowX: 'auto',
      width: '100%',
    },
    table: {
      borderCollapse: 'collapse',
      width: '100%',
      backgroundColor: "white",
      border: '1px solid #ccc',
    },
    th: {
      position: 'sticky',
      left: 0,
      zIndex: 2,
      border: '1px solid #dddddd',
      boxShadow: '6px 0px 6px -2px rgba(0, 0, 0, 0.1)',
      fontSize: '16px',
      paddingRight: 10
    },
    thBorder: {
      content: "''",
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '1px', // Adjust the border width as needed
      backgroundColor: '#dddddd', // Adjust the border color as needed
      zIndex: 1, // Place the border above the content
      boxShadow: '6px 0px 6px -2px rgba(0, 0, 0, 0.1)'
    },
    cell: {
      // border: '1px solid #dddddd',
      padding: '5px',
      // textAlign: 'left',
    },
  }

  const useStyles = makeStyles({
    root: {
      // Stack the label above the field with a gap
      display: "grid",
      // gridTemplateRows: "repeat(1fr)",
      justifyItems: "start",
      ...shorthands.gap("2px"),
      width: "100%"
      // maxWidth: "400px",
    },
    flex: {
      display: "flex", 
      justifyContent: "end", 
      alignItems: "center",
      ...shorthands.gap("20px"),
    },
    flexLeft: {
      display: "flex", 
      justifyContent: "start", 
      alignItems: "center",
      ...shorthands.gap("5px"),
    },
    contentSpacing: {
      display: "flex", 
      alignItems: "center",
      ...shorthands.gap("5px"),
    },
    stack: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      width: 'auto',
      height: 'auto',
      boxSizing: 'border-box',
      '> *': {
        textOverflow: 'ellipsis',
      },
      '> :not(:first-child)': {
        marginTop: '0px',
      },
      '> *:not(.ms-StackItem)': {
        flexShrink: 1,
      },
    },
  });

const Users = () => {
  const { t } = useTranslation();

  const dropdownId = useId("dropdown-default");

  const options = [
    "Admin",
    "Manager",
    "User",
  ];

  const styles = useStyles();

  const [search, setSearch] = useState("")

  const [dialog, setDialog] = useState({
    open: false,
    loading: false,
    headers: '',
    value: '',
    emailErrorMessage: '',
    onSubmit: null,
    onCancel: null,
    content: function () {
      return null
    },
    action: function () {
      return null
    },
  })

  const [users, setUsers] = useState([
    { id: 1, name: 'John Doe', email: 'test@gmail.com', role: "user", dateAdded: new Date().toDateString(), selected: false },
    { id: 2, name: 'Jane Smith', email: 'test@gmail.com', role: "user", dateAdded: new Date().toDateString(), selected: false },
    { id: 3, name: 'John Doe', email: 'test@gmail.com', role: "user", dateAdded: new Date().toDateString(), selected: false },
    { id: 4, name: 'Jane Smith', email: 'test@gmail.com', role: "user", dateAdded: new Date().toDateString(), selected: false },
    { id: 5, name: 'John Doe', email: 'test@gmail.com', role: "user", dateAdded: new Date().toDateString(), selected: false },
    { id: 6, name: 'Jane Smith', email: 'test@gmail.com', role: "user", dateAdded: new Date().toDateString(), selected: false },
    // Add more rows as needed
  ]);

  const [items, setItems] = useState(users)

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const totalPages = Math.ceil(items.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = items.slice(startIndex, endIndex);

  useEffect(() => {
    if (search) {
      const _searchUsers = users.filter(_user => _user.name.includes(search) || _user.email.includes(search) || _user.role.includes(search))

      setItems(_searchUsers)
    } else {
      setItems(users)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    setItems(users)
  }, [users])
  
  

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to the first page when items per page changes
  };

  const handlePageDropdownChange = (event) => {
    const newPage = parseInt(event.target.value, 10);
    setCurrentPage(newPage);
  };

  const handleAddUser = () => {
    setDialog(prev => 
      ({
        ...prev, 
        open: true,
        headers: t("users.addUser"),
        value: { name: '', email: '', role: '' },
        content: function () {
          return this.loading ? 
          (<Spinner labelPosition='below' label={t('users.creating')} /> ) 
          : 
          (
            <>
              <Field
                label={t('users.name')}
              >
                <Input value={this.value.name} onChange={(e, data) => {
                  setDialog(prev => ({...prev, value: {...prev.value, name: data.value}}))
                }} />
              </Field>
              <Field
                label={t('users.email')}
                validationMessage={this.emailErrorMessage}
              >
                <Input value={this.value.email} onChange={(e, data) => {
                  setDialog(prev => ({...prev, value: {...prev.value, email: data.value}, emailErrorMessage: ''}))
                }} />
              </Field>
              <div className={styles.root}>
                <label id={dropdownId}>{t('users.role')}</label>
                <Dropdown
                  aria-labelledby={dropdownId}
                  placeholder={t('users.selectRole')}
                  style={{ width: "100%"}}
                >
                  {options.map((option) => (
                    <Option key={option} onClick={() => setDialog(prev => ({...prev, value: {...prev.value, role: option}})) }>
                      {option}
                    </Option>
                  ))}
                </Dropdown>
              </div>
            </>
          )
        },
        action: function () {
          return <>
            <Button appearance="primary" disabled={!(this.value.name && this.value.email && this.value.role)} onClick={() => this.onSubmit(this.value)}>{t('users.add')}</Button>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary" onClick={() => 
                this.onCancel()
                }>{t('users.cancel')}</Button>
            </DialogTrigger>
          </>
        },
        onSubmit: (value) => {
          if (checkEmail(value.email)) {
            setUsers(prevRoles => [
              ...prevRoles,
              { id: prevRoles.length + 1, name: value.name, email: value.email, role: value.role, dateAdded: new Date().toDateString(), selected: false },
            ])

            setDialog({
              open: false,
              loading: false,
              headers: '',
              value: '',
              emailErrorMessage: '',
              onSubmit: null,
              onCancel: null,
              content: function () {
                return null
              },
              action: function () {
                return null
              },
            })
          } else {
            setDialog(prev => ({...prev, emailErrorMessage: t('users.emailErrorMessage')}))
          }
          
        },
        onCancel: () => setDialog({
          open: false,
          loading: false,
          headers: '',
          value: '',
          emailErrorMessage: '',
          onSubmit: null,
          onCancel: null,
          content: function () {
            return null
          },
          action: function () {
            return null
          },
        }),
      }))
  }

  const handleEditUser = (user) => {
    setDialog(prev => 
      ({
        ...prev, 
        open: true,
        headers: t('users.editUser'),
        value: { ...user },
        content: function () {
          return this.loading ? 
          (<Spinner labelPosition='below' label={t('users.creating')} /> ) 
          : 
          (
            <>
              <Field
                label={t('users.name')}
              >
                <Input value={this.value.name} onChange={(e, data) => {
                  setDialog(prev => ({...prev, value: {...prev.value, name: data.value}}))
                }} />
              </Field>
              <Field
                label={t('users.email')}
                validationMessage={this.emailErrorMessage}
              >
                <Input value={this.value.email} onChange={(e, data) => {
                  setDialog(prev => ({...prev, value: {...prev.value, email: data.value}, emailErrorMessage: ''}))
                }} />
              </Field>
              <div className={styles.root}>
                <label id={dropdownId}>{t('users.role')}</label>
                <Dropdown
                  aria-labelledby={dropdownId}
                  placeholder={t('users.selectRole')}
                  style={{ width: "100%"}}
                  value={this.value.role}
                >
                  {options.map((option) => (
                    <Option key={option} onClick={() => setDialog(prev => ({...prev, value: {...prev.value, role: option}})) }>
                      {option}
                    </Option>
                  ))}
                </Dropdown>
              </div>
            </>
          )
        },
        action: function () {
          return <>
            <Button appearance="primary" disabled={!(this.value.name && this.value.email && this.value.role)} onClick={() => this.onSubmit(this.value)}>{t('users.confirm')}</Button>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary" onClick={() => 
                this.onCancel()
                }>{t('users.cancel')}</Button>
            </DialogTrigger>
          </>
        },
        onSubmit: (value) => {
          if (checkEmail(value.email)) {
            setUsers(prevUsers => {
              const updatedUsers = [...prevUsers]

              const userIndex = prevUsers.findIndex(_prevUser => _prevUser.id === value.id)

              updatedUsers[userIndex] = value
  
              return updatedUsers            
            })

            setDialog({
              open: false,
              loading: false,
              headers: '',
              value: '',
              emailErrorMessage: '',
              onSubmit: null,
              onCancel: null,
              content: function () {
                return null
              },
              action: function () {
                return null
              },
            })
          } else {
            setDialog(prev => ({...prev, emailErrorMessage: t('users.emailErrorMessage')}))
          }
          
        },
        onCancel: () => setDialog({
          open: false,
          loading: false,
          headers: '',
          value: '',
          emailErrorMessage: '',
          onSubmit: null,
          onCancel: null,
          content: function () {
            return null
          },
          action: function () {
            return null
          },
        }),
      }))
  }

  const handleDeleteUser = (user) => {
    setDialog(prev => 
      ({...prev, 
        open: true,
        headers: ``,
        value: '',
        content: function () {
          return <div className={styles.stack} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <DeleteRegular fontSize={32} />
            <Subtitle2Stronger style={{ paddingTop: 6 }}>{t('storages.deleteConfirmMessage')}</Subtitle2Stronger>
          </div>
        },
        action: function () {
          return <>
            <Button appearance="primary" onClick={() => this.onSubmit(user)}>{t('users.confirm')}</Button>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary" onClick={() => 
                this.onCancel()
                }>{t('users.cancel')}</Button>
            </DialogTrigger>
          </>
        },
        onSubmit: (_user) => {
          setUsers(prevUsers => {
            const updatedUsers = [...prevUsers].filter(_prevUser => _prevUser.id !== _user.id)

            return updatedUsers            
          })

          setDialog({
            open: false,
            loading: false,
            headers: '',
            value: '',
            emailErrorMessage: '',
            onSubmit: null,
            onCancel: null,
            content: function () {
              return null
            },
            action: function () {
              return null
            },
          })
        },
        onCancel: () => setDialog({
          open: false,
          loading: false,
          headers: '',
          value: '',
          emailErrorMessage: '',
          onSubmit: null,
          onCancel: null,
          content: function () {
            return null
          },
          action: function () {
            return null
          },
        }),
      }))
  }

    return <div style={homeStyles}>
      <div style={{ padding: 30}}>
        <div style={{ display: "flex", justifyContent: "space-between"}}>
          <Title2>{t("users.header")}</Title2>
          <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "center", alignItems: "center" }}>
            <Button 
              appearance="primary" 
              icon={<AddRegular /> }
              onClick={() => handleAddUser()}
            >
              {t("users.addUser")}
            </Button>
          </div>
        </div>
        <br />
        <Divider />
        <br />
        <div className={styles.flexLeft}>
          <Field onChange={e => setSearch(e.target.value)}>
            <Input contentBefore={<SearchRegular />} contentAfter={search ? <DismissRegular onClick={() => setSearch("")} /> : null } value={search} placeholder={t("users.search")} />
          </Field>
        </div>
        <br />
        <div style={stylesTable.tableContainer}>
          <table style={stylesTable.table}>
            <thead style={{ border: '1px solid #ccc'}}>
              <tr style={{ backgroundColor: "rgb(243, 242, 241)"}}>
                <th style={{ width: "5%", ...stylesTable.cell}}>
                </th>
                <th style={{ width: "30%", ...stylesTable.cell}}>
                  <div style={{ display: "flex", alignItems: "center"}}>
                    {t("users.name")}
                  </div>
                </th>
                <th style={{ width: "30%", ...stylesTable.cell}}>
                  <div style={{ display: "flex", alignItems: "center"}}>
                    {t("users.email")}
                  </div>
                </th>
                <th style={{ width: "15%", ...stylesTable.cell}}>
                  <div style={{ display: "flex", alignItems: "center"}}>
                    {t("users.role")}

                  </div>
                </th>
                <th style={{ width: "15%", ...stylesTable.cell}}>
                  <div style={{ display: "flex", alignItems: "center"}}>
                    {t("users.activeDate")}

                  </div>
                </th>
                <th style={{ width: "5%", ...stylesTable.cell}}>
                </th>
              </tr>
            </thead>
            <tbody>
            {currentItems.map((row, index) => (
              <tr key={row.id}>
                <td style={stylesTable.cell}>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
                    {index + 1}
                  </div>
                </td>
                <td style={stylesTable.cell}>{row.name}</td>
                <td style={stylesTable.cell}>{row.email}</td>
                <td style={stylesTable.cell}>{row.role}</td>
                <td style={stylesTable.cell}>{row.dateAdded}</td>
                <td style={stylesTable.cell}>
                  <Menu>
                    <MenuTrigger disableButtonEnhancement>
                      <MenuButton appearance="transparent" icon={<MoreHorizontalRegular />} />
                    </MenuTrigger>
                    <MenuPopover>
                      <MenuList>
                        <MenuItem icon={<EditRegular />} onClick={() => handleEditUser(row)}>{t("users.edit")}</MenuItem>
                        <MenuItem icon={<DeleteRegular />} onClick={() => handleDeleteUser(row)}>{t("users.delete")}</MenuItem>
                      </MenuList>
                    </MenuPopover>
                  </Menu>
                </td>
              </tr>
            ))}
            </tbody>
          </table>

        </div>
          <div className={styles.flex} style={{ marginTop: 10}}>
            <div className={styles.contentSpacing}>
              {t("users.itemsPerPage1")}

              <Select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
              </Select>

              {t("users.itemsPerPage2")}

            </div>
            <div className={styles.contentSpacing}>
              <Button icon={<ChevronLeftRegular />}  appearance="transparent" 
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {t("users.page")}

              <Select value={currentPage} onChange={handlePageDropdownChange}>
                {Array.from({ length: totalPages }, (_, index) => (
                  <option key={index} value={index + 1}>
                    {index + 1}
                  </option>
                ))}
              </Select>
              / {totalPages}
              <Button icon={<ChevronRightRegular />}  appearance="transparent" 
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
              />
            </div>
          </div>
      </div>
      <Dialog open={dialog.open}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{dialog.headers}</DialogTitle>
            <DialogContent>
              {dialog.content()}
            </DialogContent>
            <DialogActions>
              {dialog.action()}
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
}

export default Users;